<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <!--            <h4 class="card-title mb-4">End Of Month Report</h4>-->
            <RunEomCard />
            <!-- Supposed Form -->
            <div class="form-body">
              <AllEomTable />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>

import Layout from "../../../router/layouts/main";
import PageHeader from "../../../components/AppComponents/page-header";
import AllEomTable from "../../../components/Kleyton/Tables/Report/EOM/AllEomTable";
import RunEomCard from "../../../components/Kleyton/Cards/Report/EOM/RunEomCard";

export default {
  name: "AllEom",
  page: {
    title: "End Of Month",
    meta: [{ name: "End Of Month", content: window.__env.app.description }]
  },
  components: {
    RunEomCard,
    AllEomTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "End Of Month",

      items: [
        {
          text: "Report",
          href: "/"
        },
        {
          text: "End Of Month",
          active: true
        }
      ]
    };
  },
  methods: {}
};
</script>

<style scoped></style>
