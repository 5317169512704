<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->

    <form @submit.prevent="editBank">
      <div class="row">
        <div class="col-sm-12">
          <!--          Bank State-->
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.bankState"
              placeholder="Bank State"
              :hasPreIcon="false"
              :label="'BankState'"
              :class="{
                error: $v.createBankFormBody.bankState.$error
              }"
              @blur="$v.createBankFormBody.bankState.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.bankState.$error">
              <p
                v-if="!$v.createBankFormBody.bankState.required"
                class="form-error-message"
              >
                Bank State is required
              </p>
            </template>
          </div>

          <!--          transaction Limit-->
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.bankTransactionLimit"
              placeholder="Transaction Limit"
              :hasPreIcon="false"
              :label="'Transaction Limit'"
              :class="{
                error: $v.createBankFormBody.bankTransactionLimit.$error
              }"
              @blur="$v.createBankFormBody.bankTransactionLimit.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.bankTransactionLimit.$error">
              <p
                v-if="!$v.createBankFormBody.bankTransactionLimit.required"
                class="form-error-message"
              >
                Transaction Limit is required
              </p>
            </template>
          </div>

          <!--          Bank Name-->
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.bankName"
              placeholder="Bank Name"
              :hasPreIcon="false"
              :label="'Bank Name'"
              :class="{
                error: $v.createBankFormBody.bankName.$error
              }"
              @blur="$v.createBankFormBody.bankName.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.bankName.$error">
              <p
                v-if="!$v.createBankFormBody.bankName.required"
                class="form-error-message"
              >
                Bank Name is required
              </p>
            </template>
          </div>

          <!--          Bank Address-->
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.bankAddress"
              placeholder="Bank Address"
              :hasPreIcon="false"
              :label="'Bank Address'"
              :class="{
                error: $v.createBankFormBody.bankAddress.$error
              }"
              @blur="$v.createBankFormBody.bankAddress.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.bankAddress.$error">
              <p
                v-if="!$v.createBankFormBody.bankAddress.required"
                class="form-error-message"
              >
                Bank Address is required
              </p>
            </template>
          </div>

          <!--          Bank City-->
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.bankCity"
              placeholder="Bank City"
              :hasPreIcon="false"
              :label="'Bank City'"
              :class="{
                error: $v.createBankFormBody.bankCity.$error
              }"
              @blur="$v.createBankFormBody.bankCity.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.bankCity.$error">
              <p
                v-if="!$v.createBankFormBody.bankCity.required"
                class="form-error-message"
              >
                Bank City is required
              </p>
            </template>
          </div>

          <!--          Bank Country-->
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.bankCountry"
              placeholder="Bank Country"
              :hasPreIcon="false"
              :label="'Bank Country'"
              :class="{
                error: $v.createBankFormBody.bankCountry.$error
              }"
              @blur="$v.createBankFormBody.bankCountry.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.bankCountry.$error">
              <p
                v-if="!$v.createBankFormBody.bankCountry.required"
                class="form-error-message"
              >
                Bank Country is required
              </p>
            </template>
          </div>

          <!--          Bank Status-->
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.bankStatus"
              placeholder="Bank Status"
              :hasPreIcon="false"
              :label="'Bank Status'"
              :class="{
                error: $v.createBankFormBody.bankStatus.$error
              }"
              @blur="$v.createBankFormBody.bankStatus.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.bankStatus.$error">
              <p
                v-if="!$v.createBankFormBody.bankStatus.required"
                class="form-error-message"
              >
                Bank Staus is required
              </p>
            </template>
          </div>

          <!--          Bank NIBSS Code-->
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.bankNIBSSCode"
              placeholder="Bank NIBSS Code"
              :hasPreIcon="false"
              :label="'Bank NIBSS Code'"
              :class="{
                error: $v.createBankFormBody.bankNIBSSCode.$error
              }"
              @blur="$v.createBankFormBody.bankNIBSSCode.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.bankNIBSSCode.$error">
              <p
                v-if="!$v.createBankFormBody.bankNIBSSCode.required"
                class="form-error-message"
              >
                Bank NIBSS Code is required
              </p>
            </template>
          </div>
        </div>
      </div>

      <p class="text-right">
        <span v-if="bank.moduleLoading" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Update
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditBank",
  props: {
    editData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // createBankFormBody: this.editDataItem,

      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa"
      ]
    };
  },
  computed: {
    ...mapState(["bank"]),
    createBankFormBody() {
      return this.editData;
    }
  },

  validations: {
    createBankFormBody: {
      bankState: { required },
      bankTransactionLimit: { required },
      bankName: { required },
      bankAddress: { required },
      bankCity: { required },
      bankCountry: { required },
      bankStatus: { required },
      bankNIBSSCode: { required }
    }
  },
  methods: {
    returnEditData() {
      return this.editData;
    },
    editBank() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("bank/editBank", this.createBankFormBody, { root: true })
          .then(response => {
            this.$v.$reset();

            if (response.responseCode === "00") {
              this.$emit("close-edit-modal", response);
            }
          })
          .catch(() => {});
      }
    },
    createFreshCreateBankFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
