<template>
  <div class="form-group">
    <div class="mb-1 custom-control custom-switch">
      <input
        type="checkbox"
        autocomplete="off"
        :value="value"
        @input="updateValue"
        v-bind="$attrs"
        class="custom-control-input"
      />
      <label class="custom-control-label">
        <label v-if="label">{{ label }}</label>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    inputType: {
      type: String,
      default: "text"
    },
    value: [String, Number, Object],
    label: String
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style scoped>
.base-input-holder {
  background-color: #ffffff;
  padding: 13px 13px 11px 13px;
  transition: ease-in-out all 0.2s;
}
.error .base-input-holder {
  padding: 13px 13px 25px 13px !important;
}
</style>
