import BranchService from "../../services/oldServices/BranchService.js";

export const namespaced = true;

//utility
function objIsEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    moduleLoadingTwo: false,
    moduleLoadingThree: true,
    moduleLoadingFour: false,
    moduleLoadingFive: true,
    moduleLoadingSix: false,
    createBranchFormBody: {
      source: "web"
    },
    branchStatData: {},
    branches: [],
    allBranchCheckersQueue: [],
    rejectLoading: false
  };
};
export const state = getDefaultState();

export const getters = {
  // Checks if bank stats have already been fetched
  isBranchStatsFetched: state => {
    return !objIsEmpty(state.branchStatData);
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_LOADING_SIX(state, status) {
    state.moduleLoadingSix = status;
  },
  SET_REJECT_LOADING(state, payload) {
    state.rejectLoading = payload;
  },
  SET_BRANCH_STATS(state, payload) {
    state.branchStatData = payload;
  },
  SET_ALL_BRANCHES(state, payload) {
    state.branches = payload;
  },
  SET_ALL_BRANCH_CHECKERS_QUEUE(state, payload) {
    state.allBranchCheckersQueue = payload;
  }
};

export const actions = {
  setRejectLoading({ commit }, payload) {
    commit("SET_REJECT_LOADING", payload);
  },
  checkerAuthorizeBranch({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_SIX", true);

    return BranchService.checkerAuthorizeBranch(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_SIX", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success -> ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  allBranchCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FIVE", true);

    return BranchService.allBranchCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FIVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_BRANCH_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FIVE", false);

        throw error;
      });
  },

  // fetch Bank Stats Panel
  fetchBranchStatsPanelData({ commit, dispatch, getters, rootGetters }) {
    let isBranchStatsFetched = getters.isBranchStatsFetched;

    if (isBranchStatsFetched) {
      return false;
    } else {
      // start loader
      commit("SET_LOADING", true);

      // build request body
      const payload = {
        branchBankCode: rootGetters["user/getUserBankCode"],
        source: "CBA",
        readAll: "NO"
      };

      return BranchService.fetchBranchStatsData(payload)
        .then(response => {
          // stop loader
          commit("SET_LOADING", false);

          let responseData = response.data;
          if (responseData.responseCode === "00") {
            // filtering the data to conform to the stats standard
            // then set it to state
            const totalTransactions = responseData.totalTransactions,
              totalUsers = responseData.totalUsers,
              totalCustomers = responseData.totalCustomers,
              totalBranches = responseData.totalBranches;

            const statData = [
              {
                icon: "bx bx-purchase-tag-alt",
                title: "Total Transactions",
                value: totalTransactions
              },
              {
                icon: "bx bx-user",
                title: "Total Users",
                value: totalUsers
              },
              {
                icon: "bx bxs-business",
                title: "Total Customers",
                value: totalCustomers
              },
              {
                icon: "bx bx-git-branch",
                title: "Total Branches",
                value: totalBranches
              }
            ];

            commit("SET_BRANCH_STATS", statData);
          } else {
            // push Notification
            const notification = {
              type: "error",
              variant: "danger",
              message: `Error - ${responseData.responseMessage}`
            };
            dispatch("notification/add", notification, { root: true });
          }
        })
        .catch(error => {
          //stop loader
          commit("SET_LOADING", false);

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Something went wrong: Try Again`
          };
          dispatch("notification/add", notification, { root: true });

          throw error;
        });
    }
  },

  // create a new Branches
  createBranch({ commit, dispatch, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING_TWO", true);

    // append the Created by to the payload
    payload.branchCreatedBy = rootGetters["user/getUserId"];

    return BranchService.createBranch(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_TWO", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_TWO", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // get All Branches
  allBranches({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_THREE", true);

    return BranchService.allBranches(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("SET_ALL_BRANCHES", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // update branch Status
  updateBranchStatus({ commit, dispatch, rootGetters }, payload) {
    commit("SET_TEST", "test");

    // start loader
    commit("SET_LOADING_TWO", true);

    // append the Created by to the payload
    payload.branchStatusUpdatedBy = rootGetters["user/getUserId"];

    return BranchService.updateBranchStatus(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_TWO", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `Branch Status Updated Successfully`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_TWO", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },
  // create a new Bank
  editBranch({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FOUR", true);

    return BranchService.editBranch(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FOUR", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Branch Updated Successfully`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FOUR", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};

// ===
// Private helpers
// ===
