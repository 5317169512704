<template>
  <div>
    <div v-if="loader.componenteLoading">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.aeCode"
                placeholder="Accounting Entry Code"
                :hasPreIcon="false"
                :label="'Entry Code'"
                :class="{
                  error: $v.formBody.aeCode.$error
                }"
                @blur="$v.formBody.aeCode.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.aeCode.$error">
                <p
                  v-if="!$v.formBody.aeCode.required"
                  class="form-error-message"
                >
                  Accounting Entry Code is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.aeDescription"
                placeholder="Accounting Entry Description"
                :hasPreIcon="false"
                :label="'Entry Description'"
                :class="{
                  error: $v.formBody.aeDescription.$error
                }"
                @blur="$v.formBody.aeDescription.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.aeDescription.$error">
                <p
                  v-if="!$v.formBody.aeDescription.required"
                  class="form-error-message"
                >
                  Accounting Entry Description is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label>Charge Product</label>
              <multiselect
                v-model="formBody.aeChargeCode"
                :options="chargeProductUtilityOption"
                placeholder="Charge Product"
                @close="$v.formBody.aeChargeCode.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.formBody.aeChargeCode.$error">
              <p
                v-if="!$v.formBody.aeChargeCode.required"
                class="form-error-message"
              >
                Charge Product is required
              </p>
            </template>
          </div>
        </div>
        <p class="text-right">
          <span v-if="loader.actionButtonLoader" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create Entry
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "CreateAccountingEntryForm",
  data() {
    return {
      formBody: this.createFreshFormBodyObject()
    };
  },
  async created() {
    StoreUtils.dispatch(
      StoreUtils.actions.appUtilities.FETCH_ALL_TRANSACTION_CODE
    );
    await StoreUtils.dispatch(
      StoreUtils.actions.appUtilities.FETCH_ALL_CHARGE_PRODUCTS
    );
  },
  computed: {
    ...mapState(["accountingEntry", "loader"]),
    ...mapGetters({
      chargeProductUtilityOption:
        "appUtilities/getAllChargeProductUtilityOption",
      getChargeProductCodeFromOptionUtility:
        "appUtilities/getChargeProductCodeFromOptionUtility",

      transactionCodeUtilityOption:
        "appUtilities/getAllTransactionCodesUtilityOption",
      getTransactionCodeFromOptionUtility:
        "appUtilities/getTransactionCodeCodeFromOptionUtility"
    })
  },
  validations: {
    formBody: {
      aeCode: { required },
      aeDescription: { required },
      aeChargeCode: { required }
    }
  },
  methods: {
    submitForm() {
      this.formBody.aeChargeCode = this.getChargeProductCodeFromOptionUtility(
        this.formBody.aeChargeCode
      );

      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.dispatch(
          StoreUtils.actions.accountingEntry.CREATE,
          this.formBody
        )
          .then(() => {
            this.formBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
