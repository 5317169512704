<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(branchName)="data">
        <p class="name-field">
          <!--          <span class="bold">{{ data.item.branchName }}</span>-->
          <span>{{ data.item.branchName }}</span>
        </p>
      </template>

      <!-- A custom formatted column -->
      <template v-slot:cell(branchBankCode)="data">
        <p class="name-field">
          <span>{{ data.item.branchBankCode | firstCaseCapitalSpace }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(branchStatus)="data">
        <p class="name-field">
          <span
            v-if="data.item.branchStatus === 'ACTIVE'"
            class="badge badge-pill badge-soft-success font-size-12"
            >{{ data.item.branchStatus | firstCaseCapitalSpace }}</span
          >
          <span
            v-if="data.item.branchStatus === 'INACTIVE'"
            class="badge badge-pill badge-soft-danger font-size-12"
            >{{ data.item.branchStatus | firstCaseCapitalSpace }}</span
          >
          <span
            v-if="data.item.branchStatus === 'PENDING'"
            class="badge badge-pill badge-soft-warning font-size-12"
            >{{ data.item.branchStatus | firstCaseCapitalSpace }}</span
          >
        </p>
      </template>

      <template v-slot:cell(branchType)="data">
        <p class="name-field">
          <span class="badge badge-secondary">{{ data.item.branchType }}</span>
        </p>
      </template>

      <template v-slot:cell(branchCityState)="data">
        <p class="name-field">
          <span>{{ data.item.branchCity }}</span
          >/
          <span class="bold">{{ data.item.branchState }}</span>
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(action)="data">
        <p class="name-field">
          <b-dropdown
            class="card-drop"
            variant="white"
            right
            toggle-class="p-0"
          >
            <template v-slot:button-content>
              <i class="mdi mdi-dots-horizontal font-size-18" />
            </template>

            <b-dropdown-item @click="showEditForm(data.item)">
              <i class="fas fa-pencil-alt text-success mr-1" /> Edit
            </b-dropdown-item>

            <!--            Edit Table Modal-->
            <!--            <EditBankForm :dataItem="data.item" />-->

            <!--            <b-dropdown-item>-->
            <!--              <i class="fas fa-trash-alt text-danger mr-1"></i> Delete-->
            <!--            </b-dropdown-item>-->
          </b-dropdown>
        </p>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <vodal
      :show="editModalOpen"
      :width="500"
      :height="450"
      measure="px"
      animation="zoom"
      @hide="editModalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Edit Branch</h4>

          <EditBranchForm
            :editBranchFormBody="currentEditDataItem"
            @edit-successful="editSuccessful"
          />
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditBranchForm from "../../Forms/Branch/EditBranchForm";

export default {
  name: "AllBranchTable",
  components: {
    EditBranchForm
  },
  data() {
    return {
      editModalOpen: false,
      currentEditDataItem: {},
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        { key: "branchCode", label: "Branch Code", sortable: true },
        { key: "branchName", label: "Branch Name", sortable: true },
        { key: "branchBankCode", label: "Bank Code", sortable: true },
        { key: "branchManager", label: "Manager", sortable: true },
        { key: "branchType", label: "Type", sortable: true },
        { key: "branchCityState", label: "City", sortable: true },
        { key: "branchStatus", label: "Status", sortable: true },
        { key: "action", label: "Action", sortable: true }
      ],
      json_fields: {
        "Branch Bank Code": "branchBankCode",
        "Branch ID": "branchID",
        "Branch Location": "branchLocation",
        "Branch Category": "branchCategory",
        "Branch Manager": "branchManager",
        "Branch Address": "branchAddress",
        "Branch Name": "branchName",
        "Branch Region": "branchRegion",
        "Branch Updated At": "branchUpdatedAt",
        "Branch Country": "branchCountry",
        "Branch Code": "branchCode",
        "Branch Transaction Limit": "branchTransactionLimit",
        "Branch City": "branchCity",
        "Branch State": "branchState",
        "Branch Status": "branchStatus",
        "Branch Created At": "branchCreatedAt",
        "Branch Created By": "branchCreatedBy"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.branch.branches,
      isBusy: state => state.branch.moduleLoadingThree
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    fetchTableData() {
      const payload = {
        branchBankCode: this.$store.getters["user/getUserBankCode"],
        source: "CBA",
        readAll: "NO"
      };

      this.$store.dispatch("branch/allBranches", payload);
    },

    editSuccessful() {
      // hide the edit modal
      this.editModalOpen = false;

      //refresh the table
      this.fetchTableData();
    },
    showEditForm(_item) {
      this.currentEditDataItem = _item;

      //show the edit bank form
      this.editModalOpen = true;
    },
    returnItems() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;

        return initials;
      } else {
        return initials.substring(0, 2);
      }
    }
  },

  created() {
    this.fetchTableData();
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
