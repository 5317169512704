import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import FtService from "../../services/newServices/FtService";
import Utils from "../../utils/Utils";
import NotificationUtils from "../../utils/BaseUtils/NotificationUtils";

const ftService = new FtService();

const getDefaultState = () => {
  return {
    test: "",
    stage: 1,
    request: {},
    stageOneResponse: {},
    stageTwoResponse: {},
    stageThreeResponse: {},
    tillBalance: "0"
  };
};
export const state = getDefaultState();

export const getters = {
  getFtStage: state => {
    return state.stage;
  },
  getFtStageOneResponse: state => {
    return state.stageOneResponse;
  },
  getFtStageTwoResponse: state => {
    return state.stageTwoResponse;
  },
  getFtStageThreeResponse: state => {
    return state.stageThreeResponse;
  },
  getTillBalance: state => {
    return state.tillBalance;
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  TEST(state, payload) {
    state.test = payload;
  },
  INCREASE_FT_STAGE_BY_ONE(state) {
    state.stage += 1;
  },
  SET_FT_STAGE_ONE_RESPONSE(state, payload) {
    state.stageOneResponse = payload;
  },
  SET_FT_STAGE_TWO_RESPONSE(state, payload) {
    state.stageTwoResponse = payload;
  },
  SET_FT_STAGE_THREE_RESPONSE(state, payload) {
    state.stageThreeResponse = payload;
  },
  GO_BACK_TO_STAGE_ONE(state) {
    state.stage = 1;
  },
  GO_BACK_TO_STAGE_TWO(state) {
    state.stage = 2;
  },
  SET_TILL_BALANCE(state, payload) {
    state.tillBalance = payload;
  }
};
export const actions = {
  manualReversal(undefined, payload) {
    payload.bankCode = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BANK_CODE
    );
    payload.module = "BA";

    const successAction = () => {};

    return ftService.manualReversal(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  customerAccountEnquiry(undefined, payload) {
    let request = {
      accountBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      accountNumber: payload.accountNumber.trim()
    };

    const successAction = responseData => {
      delete responseData.responseCode;
      delete responseData.responseMessage;

      const transactionalNotificationModalBody = {
        title: "Customer Account Enquiry",
        iconType: NotificationUtils.iconTypes.BALANCE_ENQUIRY,
        transactionObject: responseData,
        closeAction: () => {
          StoreUtils.dispatch("ft/resetState");
        }
      };

      NotificationUtils.showTransactionalNotificationModal(
        transactionalNotificationModalBody
      );
    };

    return ftService.balanceEnquiryInternal(
      request,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchTillAccountBalance(context, accountNumber) {
    context.commit("TEST", "TEST");

    let request = {
      accountBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      accountNumber
    };

    const successAction = responseData => {
      StoreUtils.commit("ft/SET_TILL_BALANCE", responseData.accountBalance);
    };

    return ftService.balanceEnquiryInternal(
      request,
      successAction,
      LoaderUtils.types.NONE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  cashDeposit({ commit }, payload) {
    commit("TEST", "TEST");

    payload.trnMaker = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_ID
    );
    payload.trnChecker = "";
    payload.reference = `${Utils.generateUUIDRef()}-${StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_ID
    )}`;
    payload.debitAccountNumber =
      StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
      ).trim() !== ""
        ? StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
          ).trim()
        : StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_VAULT_ACCOUNT_NUMBER
          ).trim();
    payload.debitAccountBankCode = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BANK_CODE
    );
    payload.debitAccountBranchCode = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BRANCH_CODE
    );

    payload.creditAccountNumber = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    ).accountNumber.trim();
    payload.creditAccountName = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    )
      .accountName.toUpperCase()
      .trim();
    payload.creditAccountBank = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    ).accountBankName;
    payload.creditAccountBankCode = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    ).accountBankCode;
    payload.creditAccountSwitchCode = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    ).accountBankCode;
    payload.creditAccountPhone = "1234567890";
    payload.source = "CBA";
    payload.trnType = "DEPOSIT";
    payload.trnSwitch = "WITHDRAWAL";
    payload.trnSwitchService = "";

    const successAction = responseData => {
      const transactionalNotificationModalBody = {
        title: "Transfer Successful",
        iconType: NotificationUtils.iconTypes.TRANSACTION_SUCCESSFUL,
        transactionObject: responseData,
        closeAction: () => {
          StoreUtils.dispatch("ft/resetState");
        }
      };
      NotificationUtils.showTransactionalNotificationModal(
        transactionalNotificationModalBody
      );
    };

    return ftService.cashDeposit(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  cashWithdrawal({ commit }, payload) {
    commit("TEST", "TEST");
    payload.trnMaker = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_ID
    );
    payload.trnChecker = "";
    payload.trnType = "WITHDRAWAL";
    payload.reference = `${Utils.generateUUIDRef()}-${StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_ID
    )}`;
    payload.creditAccountNumber =
      StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
      ).trim() !== ""
        ? StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
          ).trim()
        : StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_VAULT_ACCOUNT_NUMBER
          ).trim();
    payload.creditAccountBankCode = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BANK_CODE
    );
    payload.creditAccountBranchCode = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BRANCH_CODE
    );
    payload.debitAccountNumber = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    ).accountNumber.trim();
    payload.debitAccountName = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    )
      .accountName.toUpperCase()
      .trim();
    payload.debitAccountBank = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    ).accountBankName;
    payload.debitAccountBankCode = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    ).accountBankCode;
    payload.debitAccountSwitchCode = StoreUtils.rootGetter(
      StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
    ).accountBankCode;
    payload.debitAccountPhone = "1234567890";
    payload.source = "CBA";
    payload.trnType = "WITHDRAWAL";
    payload.trnSwitch = "WITHDRAWAL";
    payload.trnSwitchService = "";

    const successAction = responseData => {
      const transactionalNotificationModalBody = {
        title: "Transfer Successful",
        iconType: NotificationUtils.iconTypes.TRANSACTION_SUCCESSFUL,
        transactionObject: responseData,
        closeAction: () => {
          StoreUtils.dispatch("ft/resetState");
        }
      };
      NotificationUtils.showTransactionalNotificationModal(
        transactionalNotificationModalBody
      );
    };

    return ftService.cashWithdrawal(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fundTransferInternal({ commit }, payload) {
    commit("TEST", "TEST");
    let request = {
      debitAccountNumber: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
      ).accountNumber.trim(),
      debitAccountName: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
      ).accountName.trim(),
      debitAccountBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
      ).accountBankCode,
      debitAccountBranchCode: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
      ).accountBranchCode,
      creditAccountNumber: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_TWO_RESPONSE
      ).accountNumber.trim(),
      creditAccountName: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_TWO_RESPONSE
      )
        .accountName.toUpperCase()
        .trim(),
      creditAccountBank: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_TWO_RESPONSE
      ).accountBankName,
      creditAccountSwitchCode: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_TWO_RESPONSE
      ).accountBankCode,
      creditAccountPhone: "1234567890",
      ftProductCode: payload.ftProductCode,
      reference: `${Utils.generateUUIDRef()}-${StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_ID
      )}`,
      source: "CBA",
      trnAmount: payload.trnAmount,
      trnNarration: payload.trnNarration,
      trnMaker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
      trnChecker: "SYSTEM",
      trnType: "INTERNAL",
      trnSwitch: "INTERNAL",
      trnSwitchService: "INTERNAL",
      //added the extra payload
      postNow: "NO",
    };

    const successAction = responseData => {
      const transactionalNotificationModalBody = {
        title: "Transfer Successful",
        iconType: NotificationUtils.iconTypes.TRANSACTION_SUCCESSFUL,
        transactionObject: responseData,
        closeAction: () => {
          StoreUtils.dispatch("ft/resetState");
        }
      };
      NotificationUtils.showTransactionalNotificationModal(
        transactionalNotificationModalBody
      );
    };

    return ftService.internalFundsTransfer(
      request,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fundTransferExternal({ commit }, payload) {
    commit("TEST", "TEST");
    let request = {
      creditAccountBank: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_TWO_RESPONSE
      ).accountBankName,
      creditAccountName: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_TWO_RESPONSE
      ).accountName.trim(),
      creditAccountNumber: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_TWO_RESPONSE
      ).accountNumber.trim(),
      creditAccountPhone: "0123456789",
      creditAccountSwitchCode: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_TWO_RESPONSE
      ).bankCode,
      debitAccountNumber: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
      ).accountNumber.trim(),
      debitAccountName: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
      ).accountName.trim(),
      debitAccountBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.ft.GET_FT_STAGE_ONE_RESPONSE
      ).accountBankCode,
      ftProductCode: payload.ftProductCode,
      reference: `${Utils.generateUUIDRef()}-${StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_ID
      )}`,
      source: "CBA",
      trnAmount: payload.trnAmount,
      trnNarration: payload.trnNarration
    };

    const successAction = responseData => {
      const transactionalNotificationModalBody = {
        title: "Transfer Successful",
        iconType: NotificationUtils.iconTypes.TRANSACTION_SUCCESSFUL,
        transactionObject: responseData,
        closeAction: () => {
          StoreUtils.commit("ft/GO_BACK_TO_STAGE_ONE");
          StoreUtils.dispatch("ft/resetState");
        }
      };
      NotificationUtils.showTransactionalNotificationModal(
        transactionalNotificationModalBody
      );
    };

    return ftService.externalFundsTransfer(
      request,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  balanceEnquiryInternal({ commit }, payload) {
    commit("TEST", "TEST");
    let request = {
      accountBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      accountNumber: payload.accountNumber.trim()
    };

    const successAction = responseData => {
      StoreUtils.dispatch("ft/setFtStageResponse", responseData);
    };

    return ftService.balanceEnquiryInternal(
      request,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  nameEnquiryInternal({ commit }, payload) {
    commit("TEST", "TEST");
    let request = {
      accountBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      accountNumber: payload.accountNumber.trim(),
      serviceBank: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      username: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID)
    };

    const successAction = responseData => {
      StoreUtils.dispatch("ft/setFtStageResponse", responseData);
    };

    return ftService.nameEnquiryInternal(
      request,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  nameEnquiryExternal({ commit }, payload) {
    commit("TEST", "TEST");
    let request = {
      accountBankCode: payload.accountBankCode,
      accountNumber: payload.accountNumber.trim(),
      serviceBank: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      username: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID)
    };

    const successAction = responseData => {
      responseData.accountBankName = StoreUtils.rootGetter(
        "appUtilities/getExternalBankNameFromCodeUtility",
        responseData.bankCode
      );
      StoreUtils.dispatch("ft/setFtStageResponse", responseData);
    };

    return ftService.nameEnquiryExternal(
      request,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setFtStageResponse({ commit }, payload) {
    switch (StoreUtils.rootGetter(StoreUtils.getters.ft.GET_FT_STAGE)) {
      case 1:
        commit("SET_FT_STAGE_ONE_RESPONSE", payload);
        StoreUtils.dispatch("ft/increaseFtStageByOne");
        break;
      case 2:
        commit("SET_FT_STAGE_TWO_RESPONSE", payload);
        StoreUtils.dispatch("ft/increaseFtStageByOne");
        break;
      default:
        commit("SET_FT_STAGE_THREE_RESPONSE", payload);
        StoreUtils.dispatch("ft/increaseFtStageByOne");
    }
  },

  increaseFtStageByOne({ commit }) {
    commit("INCREASE_FT_STAGE_BY_ONE");
  },
  goBackToStageTwo({ commit }) {
    commit("GO_BACK_TO_STAGE_TWO", 2);
  },
  goBackToStageOne({ commit }) {
    commit("GO_BACK_TO_STAGE_ONE", 1);
  },
  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
