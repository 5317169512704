<template>
  <div>
    <!--        control-->
    <!--        control-->
    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
      <template v-slot:button-content>
        <i class="mdi mdi-dots-horizontal font-size-18" />
      </template>

      <b-dropdown-item @click="openModal">
        <i class="fas fa-plus text-success mr-1" /> Add Sub Account
      </b-dropdown-item>
      <b-dropdown-item @click="resetPin()">
        <i class="fas fa-minus text-success mr-1" /> Reset Pin
      </b-dropdown-item>
      <b-dropdown-item @click="openAltModal">
        <i class="fas fa-cloud-upload-alt text-success mr-1" /> Manage Documents
      </b-dropdown-item>
      <b-dropdown-item @click="openCreateAltModal">
        <i class="fas fa-cloud-upload-alt text-success mr-1" /> Create Document
        Type
      </b-dropdown-item>
    </b-dropdown>

    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="400"
      :height="440"
      measure="px"
      animation="zoom"
      @hide="modalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Add Sub Account</h4>
          <AddSubAccountForm
            :currentCustomer="currentCustomer"
            @close-add-sub-account-modal="closeModal()"
          />
        </div>
      </div>
    </vodal>

    <!--        actual modal-->
    <vodal
      :show="altModalOpen"
      :width="600"
      :height="600"
      measure="px"
      animation="zoom"
      @hide="closeAltModal"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Manage Documents</h4>

          <ManageCustomerAccountDocumentCard
            :current-customer="currentCustomer"
          />
        </div>
      </div>
    </vodal>

    <vodal
      :show="createAltModal"
      :width="600"
      :height="600"
      measure="px"
      animation="zoom"
      @hide="closeCreateAltModal"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Create Document Type</h4>

          <ManageCustomerAccountDocumentCard
            :current-customer="currentCustomer"
          />
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import AddSubAccountForm from "@/components/Kleyton/Forms/Customer/AddSubAccountForm";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import ManageCustomerAccountDocumentCard from "@/components/Kleyton/Cards/customer/ManageCustomerAccountDocumentCard";
import NotificationUtils from "../../../../utils/BaseUtils/NotificationUtils";
export default {
  name: "AllCustomerTableActionModal",
  components: {
    ManageCustomerAccountDocumentCard,
    AddSubAccountForm
  },
  data() {
    return {
      modalOpen: false,
      altModalOpen: false,
      createAltModal: false
    };
  },
  props: {
    currentCustomer: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentCustomerAccountDocuments() {
      return StoreUtils.rootGetters(
        "customer/getCurrentCustomerAccountDocuments"
      );
    }
  },
  methods: {

    resetPin() {
      let notificationModalBody = {
        type: "warning", //error, success, warning
        mode: "yes-no", // default, yes-no
        title: "Confirmation",
        message: "Are you sure you want to Reset this customer's Pin?",
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "No",
        yesAction: () => {
          StoreUtils.dispatch("bankUser/resetUserPin", {
            username: this.currentCustomer.customerPhoneNumber
          });
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Yes"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    },

    submit() {},
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    openCreateAltModal(){
      this.createAltModal = true;
    },
    closeCreateAltModal(){
      this.createAltModal = false
    },
    openAltModal() {
      this.altModalOpen = true;

      StoreUtils.dispatch("customer/fetchCustomerAccountDocuments", {
        documentOwner: this.currentCustomer.customerId,
        documentGroup: this.currentCustomer.customerType
      });
    },
    closeAltModal() {
      this.altModalOpen = false;
      StoreUtils.commit("customer/SET_CURRENT_CUSTOMER_ACCOUNT_DOCUMENTS", []);
    }
  },
  created() {
  }
};
</script>

<style scoped></style>
