import axios from "axios";
import NProgress from "nprogress";
import store from "../../store/store";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";

const apiClient = axios.create({
  baseURL: window.__env.api.baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 50000
});

apiClient.interceptors.request.use(config => {
  const token = store.getters["user/getUserTokenFromLocalStorage"];
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});
apiClient.interceptors.response.use(response => {
  NProgress.done();
  if (response.data.responseCode === "92") {
    RouterUtils.changeRouteTo("login");
  }

  return response;
});

export default {
  checkerAuthorizeReport(requestBody) {
    return apiClient.post("/reportChecker", JSON.stringify(requestBody));
  },
  allReportsCheckersQueue(requestBody) {
    return apiClient.post("/reportChecker", JSON.stringify(requestBody));
  },
  allReports(requestBody) {
    return apiClient.post("/reportRead", JSON.stringify(requestBody));
  },

  readSingleReport(requestBody) {
    return apiClient.post("/reportReadSingle", JSON.stringify(requestBody));
  },
  readColumn(requestBody) {
    return apiClient.post("/reportReadColumn", JSON.stringify(requestBody));
  },

  singleReport(requestBody) {
    return apiClient.post("/reportReadSingle", JSON.stringify(requestBody));
  },

  createReport(requestBody) {
    return apiClient.post("/reportCreate", JSON.stringify(requestBody));
  },

  queryReport(requestBody) {
    return apiClient.post("/reportReadSingle", JSON.stringify(requestBody));
  },

  readTableList(requestBody) {
    return apiClient.post("/readTables", JSON.stringify(requestBody));
  }
};
