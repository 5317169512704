<template>
  <div class="">
    <!--    <ProcessingLoader v-if="!loader.uploadLoader" />-->
    <BaseComponentLoader v-if="loader.uploadLoader" />
    <div class="manage-doc">
      <div
        v-for="(document, index) in currentCustomerAccountDocuments"
        :key="index"
        class="customer-document"
      >
        <div v-if="document.documentStatus === 'NONE'" class="doc-card">
          <UploadCustomerDocumentForm
            :current-doc="document"
            :current-customer="currentCustomer"
          />
        </div>
        <div v-else class="doc-card">
          <div class="top">
            <!--              <div class="overlay"></div>-->
            <span
              v-if="document.documentUrl.includes('.pdf')"
              class="real-doc-card"
            >
              <img
                src="https://assets-base.s3.amazonaws.com/img/powerbank/is-pdf.png"
                alt=""
              />
            </span>
            <viewer
              v-else
              :title="false"
              :options="imageViewerOptions"
              :images="[document.documentUrl]"
              class="viewer"
            >
              <img :src="document.documentUrl" alt="" />
            </viewer>

            <div class="controls">
              <a
                class="hvr-grow"
                title="Delete"
                @click="deleteCustomerAccountDocument(document)"
              >
                <svg
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0441 3.90045L2.78564 3.90046"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.24365 7.24365V11.7013"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5869 7.24365V11.7013"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.9301 3.90045V14.4873C13.9301 14.6351 13.8713 14.7768 13.7669 14.8813C13.6624 14.9858 13.5206 15.0445 13.3728 15.0445H4.45759C4.30981 15.0445 4.16809 14.9858 4.06359 14.8813C3.9591 14.7768 3.90039 14.6351 3.90039 14.4873V3.90045"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.7014 3.90044V2.78604C11.7014 2.49048 11.584 2.20702 11.375 1.99803C11.166 1.78904 10.8826 1.67163 10.587 1.67163H7.2438C6.94824 1.67163 6.66479 1.78904 6.4558 1.99803C6.24681 2.20702 6.12939 2.49048 6.12939 2.78604V3.90044"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <a
                :href="document.documentUrl"
                target="_blank"
                class="hvr-grow"
                title="Download"
              >
                <svg
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.51904 7.66229L9.44436 10.5868L12.3697 7.66229"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.44434 2.78601V10.5848"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.5739 10.5869V14.4873C15.5739 14.6351 15.5152 14.7768 15.4107 14.8813C15.3062 14.9858 15.1645 15.0445 15.0167 15.0445H3.87263C3.72485 15.0445 3.58313 14.9858 3.47863 14.8813C3.37413 14.7768 3.31543 14.6351 3.31543 14.4873V10.5869"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <a
                class="hvr-grow"
                title="Approve"
                v-if="document.documentStatus !== 'APPROVED'"
                @click="approveCustomerAccountDocument(document)"
              >
                <svg
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0384 7.24365L7.95224 11.1441L5.90918 9.19386"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.546 2.78601H3.40193C3.09419 2.78601 2.84473 3.03548 2.84473 3.34321V14.4873C2.84473 14.795 3.09419 15.0445 3.40193 15.0445H14.546C14.8537 15.0445 15.1032 14.795 15.1032 14.4873V3.34321C15.1032 3.03548 14.8537 2.78601 14.546 2.78601Z"
                    stroke="white"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div class="base">
            <p class="title">{{ document.documentTypeName }}</p>
            <p class="sub">{{ document.documentStatus }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import UploadCustomerDocumentForm from "@/components/Kleyton/Forms/Customer/manageDocument/UploadCustomerDocumentForm";
// import ProcessingLoader from "@/components/AppComponents/ProcessingLoader";
import { mapState } from "vuex";
import BaseComponentLoader from "@/components/BaseComponents/BaseComponentLoader";

export default {
  name: "ManageCustomerAccountDocumentCard",
  components: { BaseComponentLoader, UploadCustomerDocumentForm },
  props: {
    currentCustomer: {
      type: Object,
      required: true
    },
    imageViewerOptions: {
      navbar: false,
      title: false,
      toolbar: false,
      tooltip: false
    }
  },
  computed: {
    ...mapState(["loader"]),
    currentCustomerAccountDocuments() {
      return StoreUtils.rootGetters(
        "customer/getCurrentCustomerAccountDocuments"
      );
    }
  },
  methods: {
    deleteCustomerAccountDocument(currentDocument) {
      StoreUtils.dispatch("customer/deleteCustomerAccountDocument", {
        documentType: currentDocument.documentType,
        documentUrl: currentDocument.documentUrl,
        documentOwner: this.currentCustomer.customerId,
        documentOwnerName: this.currentCustomer.customerName,
        documentGroup: this.currentCustomer.customerType
      });
    },
    approveCustomerAccountDocument(currentDocument) {
      StoreUtils.dispatch("customer/approveCustomerAccountDocument", {
        documentType: currentDocument.documentType,
        documentUrl: currentDocument.documentUrl,
        documentOwner: this.currentCustomer.customerId,
        documentOwnerName: this.currentCustomer.customerName,
        documentGroup: this.currentCustomer.customerType
      });
    }
  }
};
</script>

<style scoped>
.manage-documents-card {
}
.manage-documents-card .customer-document {
}
</style>
