import AWS from "aws-sdk";
import Utils from "../../utils/Utils";
const albumBucketName = "lumbex-vue-s3-file-uploads";
const bucketRegion = "us-west-2";
const IdentityPoolId = "us-west-2:169e15d7-e800-4cd9-a1c4-57f3bfaa6609";

const getDefaultState = () => {
  return {
    uploadBankLogoResponse: {},
    currentCustomerPictureResponse: {},
    currentCustomerMandateResponse: {},
    currentCustomerPhoneNumber: "",
    uploadBankLogoFinalPath: "",
    currentCustomerPictureFinalPath: "",
    currentCustomerMandateFinalPath: "",
    deleteUploadBankLogoResponse: {},
    deleteCustomerPictureResponse: {},
    deleteCustomerMandateResponse: {}
  };
};

export const state = getDefaultState();

export const getters = {
  getUploadBankLogoResponse(state) {
    return state.uploadBankLogoResponse;
  },
  getCurrentCustomerPictureResponse(state) {
    return state.currentCustomerPictureResponse;
  },
  getCurrentCustomerMandateResponse(state) {
    return state.currentCustomerMandateResponse;
  },
  getCurrentCustomerPhoneNumber(state) {
    return state.currentCustomerPhoneNumber;
  },
  getUploadBankLogoFinalPath(state) {
    return state.uploadBankLogoFinalPath;
  },
  getCurrentCustomerPictureFinalPath(state) {
    return state.currentCustomerPictureFinalPath;
  },
  getCurrentCustomerMandateFinalPath(state) {
    return state.currentCustomerMandateFinalPath;
  }
};

export const mutations = {
  SET_UPLOAD_BANK_LOGO_RESPONSE(state, payload) {
    state.uploadBankLogoResponse = payload;
  },
  SET_CURRENT_CUSTOMER_PICTURE(state, payload) {
    state.currentCustomerPictureResponse = payload;
  },
  SET_CURRENT_CUSTOMER_MANDATE(state, payload) {
    state.currentCustomerMandateResponse = payload;
  },
  SET_CURRENT_CUSTOMER_PHONE_NUMBER(state, payload) {
    state.currentCustomerPhoneNumber = payload;
  },
  SET_UPLOAD_BANK_LOGO_FINAL_PATH(state, payload) {
    state.uploadBankLogoFinalPath = payload;
  },
  SET_CURRENT_CUSTOMER_PICTURE_FINAL_PATH(state, payload) {
    state.currentCustomerPictureFinalPath = payload;
  },
  SET_CURRENT_CUSTOMER_MANDATE_FINAL_PATH(state, payload) {
    state.currentCustomerMandateFinalPath = payload;
  },
  SET_DELETE_UPLOAD_BANK_LOGO_RESPONSE(state, payload) {
    state.deleteUploadBankLogoResponse = payload;
  },
  SET_DELETE_CUSTOMER_PICTURE_RESPONSE(state, payload) {
    state.deleteCustomerPictureResponse = payload;
  },
  SET_DELETE_CUSTOMER_MANDATE_RESPONSE(state, payload) {
    state.deleteCustomerMandateResponse = payload;
  }
};

export const actions = {
  async uploadBankLogoToStagingArea({ commit }, payload) {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });

    let albumPhotosKey = encodeURIComponent(payload.appAlbumName) + "/";

    let photoKey = `${albumPhotosKey}${
      payload.fileName
    }_${Utils.generateNineRandomNumbers()}.png`;

    let upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: payload.file,
        ACL: "public-read"
      }
    });

    let promise = upload.promise();

    await promise.then(
      async function(data) {
        await commit(
          "SET_UPLOAD_BANK_LOGO_FINAL_PATH",
          `https://lumbex-vue-s3-file-uploads.s3-us-west-2.amazonaws.com/BankLogo/${payload.fileName}.png`
        );
        await commit("SET_UPLOAD_BANK_LOGO_RESPONSE", data);
        return data;
      },
      function(err) {
        return err;
      }
    );
  },

  async uploadCustomerPictureToStagingArea({ commit }, payload) {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });

    let albumPhotosKey = encodeURIComponent(payload.appAlbumName) + "/";

    let photoKey = `${albumPhotosKey}${
      payload.fileName
    }_${Utils.generateNineRandomNumbers()}.png`;

    let upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: payload.file,
        ACL: "public-read"
      }
    });

    let promise = upload.promise();

    await promise.then(
      async function(data) {
        await commit(
          "SET_CURRENT_CUSTOMER_PICTURE_FINAL_PATH",
          `https://lumbex-vue-s3-file-uploads.s3-us-west-2.amazonaws.com/CustomerPicture/${payload.fileName}.png`
        );
        await commit("SET_CURRENT_CUSTOMER_PICTURE", data);
        return data;
      },
      function(err) {
        return err;
      }
    );
  },

  async uploadCustomerMandateToStagingArea({ commit }, payload) {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });

    let albumPhotosKey = encodeURIComponent(payload.appAlbumName) + "/";

    let photoKey = `${albumPhotosKey}${
      payload.fileName
    }_${Utils.generateNineRandomNumbers()}.png`;

    let upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: payload.file,
        ACL: "public-read"
      }
    });

    let promise = upload.promise();
    // promise();

    await promise.then(
      async function(data) {
        await commit(
          "SET_CURRENT_CUSTOMER_MANDATE_FINAL_PATH",
          `https://lumbex-vue-s3-file-uploads.s3-us-west-2.amazonaws.com/CustomerMandate/${payload.fileName}.png`
        );
        await commit("SET_CURRENT_CUSTOMER_MANDATE", data);
        return data;
      },
      function(err) {
        return err;
      }
    );
  },

  setCurrentCustomerPhoneNumber({ commit }, payload) {
    commit("SET_CURRENT_CUSTOMER_PHONE_NUMBER", payload);
  },

  moveBankLogoToFinalLocation({ getters, rootGetters }) {
    let uploadBankLogoResponse = getters.getUploadBankLogoResponse;
    let currentBankCode = rootGetters["user/getUserBankCode"];

    //copy file from staging area to the final destination
    // create a new s3 object
    let s3 = new AWS.S3();
    let BUCKET_NAME = "lumbex-vue-s3-file-uploads";
    let OLD_KEY = uploadBankLogoResponse.key;

    let newAlbumPhotosKey = encodeURIComponent("BankLogo") + "/";
    let newPhotoKey = `${newAlbumPhotosKey}${currentBankCode}.png`;

    // Copy the object to a new location
    s3.copyObject({
      Bucket: BUCKET_NAME,
      CopySource: `${BUCKET_NAME}/${OLD_KEY}`,
      Key: newPhotoKey
    })
      .promise()
      .then(() =>
        // Delete the old object
        s3
          .deleteObject({
            Bucket: BUCKET_NAME,
            Key: OLD_KEY
          })
          .promise()
      )
      // Error handling is left up to reader
      .catch(e => console.error(e));
  },

  moveCustomerPictureToFinalLocation({ getters }) {
    let currentCustomerPictureResponse =
      getters.getCurrentCustomerPictureResponse;
    let currentCustomerPhoneNumber = getters.getCurrentCustomerPhoneNumber;

    //copy file from staging area to the final destination

    // create a new s3 object
    let s3 = new AWS.S3();
    let BUCKET_NAME = "lumbex-vue-s3-file-uploads";
    let OLD_KEY = currentCustomerPictureResponse.key;

    // let oldAlbumPhotosKey = encodeURIComponent("ST") + "/";
    // let newPhotoKey = `${newAlbumPhotosKey}${currentCustomerPhoneNumber}.png`;

    let newAlbumPhotosKey = encodeURIComponent("CustomerPicture") + "/";
    let newPhotoKey = `${newAlbumPhotosKey}${currentCustomerPhoneNumber}.png`;

    // let NEW_KEY = `CustomerPicture/${currentCustomerPhoneNumber}.png`;

    // Copy the object to a new location
    s3.copyObject({
      Bucket: BUCKET_NAME,
      CopySource: `${BUCKET_NAME}/${OLD_KEY}`,
      Key: newPhotoKey
    })
      .promise()
      .then(() =>
        // Delete the old object
        s3
          .deleteObject({
            Bucket: BUCKET_NAME,
            Key: OLD_KEY
          })
          .promise()
      )
      // Error handling is left up to reader
      .catch(e => console.error(e));
  },

  moveCustomerMandateToFinalLocation({ getters }) {
    let currentCustomerMandateResponse =
      getters.getCurrentCustomerMandateResponse;
    let currentCustomerPhoneNumber = getters.getCurrentCustomerPhoneNumber;

    //copy file from staging area to the final destination

    // create a new s3 object
    let s3 = new AWS.S3();
    let BUCKET_NAME = "lumbex-vue-s3-file-uploads";
    let OLD_KEY = currentCustomerMandateResponse.key;
    let NEW_KEY = `CustomerMandate/${currentCustomerPhoneNumber}.png`;

    // Copy the object to a new location
    s3.copyObject({
      Bucket: BUCKET_NAME,
      CopySource: `${BUCKET_NAME}/${OLD_KEY}`,
      Key: NEW_KEY
    })
      .promise()
      .then(() =>
        // Delete the old object
        s3
          .deleteObject({
            Bucket: BUCKET_NAME,
            Key: OLD_KEY
          })
          .promise()
      )
      // Error handling is left up to reader
      .catch(e => console.error(e));
  },

  deleteBankLogoFromStagingArea({ getters, commit }) {
    let uploadBankLogoResponse = getters.getUploadBankLogoResponse;

    //copy file from staging area to the final destination
    // create a new s3 object
    const s3 = new AWS.S3();
    const BUCKET_NAME = "lumbex-vue-s3-file-uploads";
    const OLD_KEY = uploadBankLogoResponse.Key;

    // Delete the old object
    s3.deleteObject({
      Bucket: BUCKET_NAME,
      Key: OLD_KEY
    })
      .promise()
      .then(response => {
        commit("SET_DELETE_CUSTOMER_PICTURE_RESPONSE", response);
      });
  },

  deleteCustomerPictureFromStagingArea({ getters, commit }) {
    const currentCustomerPictureResponse =
      getters.getCurrentCustomerPictureResponse;

    //copy file from staging area to the final destination

    // create a new s3 object
    const s3 = new AWS.S3();
    const BUCKET_NAME = "lumbex-vue-s3-file-uploads";
    const OLD_KEY = currentCustomerPictureResponse.Key;

    // Delete the old object
    s3.deleteObject({
      Bucket: BUCKET_NAME,
      Key: OLD_KEY
    })
      .promise()
      .then(response => {
        commit("SET_DELETE_CUSTOMER_PICTURE_RESPONSE", response);
      });
  },

  deleteCustomerMandateFromStagingArea({ getters, commit }) {
    const currentCustomerMandateResponse =
      getters.getCurrentCustomerMandateResponse;

    //copy file from staging area to the final destination

    // create a new s3 object
    const s3 = new AWS.S3();
    const BUCKET_NAME = "lumbex-vue-s3-file-uploads";
    const OLD_KEY = currentCustomerMandateResponse.Key;

    // Delete the old object
    s3.deleteObject({
      Bucket: BUCKET_NAME,
      Key: OLD_KEY
    })
      .promise()
      .then(response => {
        commit("SET_DELETE_CUSTOMER_MANDATE_RESPONSE", response);
      });
  }
};
