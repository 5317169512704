// import client from "./BaseService";
// import BaseService from "./BaseService";
//
// export default {
//   callUploadBase64Api(payload) {
//     // console.log("Service Payload ==>>", payload)
//     console.log("callUploadBase64", payload);
//     // return BaseService.s3ApiClient.post("uploadimage2s3", payload);
//     return BaseService.prototype.s3ApiClient.post("uploadimage2s3", payload);
//   },
// };

import BaseService from "./BaseService";

// Create an instance of BaseService
const baseServiceInstance = new BaseService();

export default {
  callUploadBase64Api(payload) {
    //console.log("callUploadBase64", payload);
    // Use the instance to access s3ApiClient
    return baseServiceInstance.s3ApiClient.post("uploadimage2s3", payload);
  },
};

