// create a new Bank
import TransactionCodeService from "../../services/oldServices/TransactionCodeService";

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    moduleLoadingTwo: true,
    moduleLoadingThree: true,
    moduleLoadingFour: true,
    moduleLoadingFive: false,
    allTransactionCodes: [],
    transactionCodesCheckersQueue: []
  };
};
export const state = getDefaultState();

export const getters = {
  // returns the current user.
  getTransactionsCodes(state) {
    return state.allTransactionCodes.map(code => {
      return code.trnCode;
    });
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_TRANSACTION_CODES(state, payload) {
    state.allTransactionCodes = payload;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_ALL_TRANSACTIONS_CHECKERS_QUEUE(state, payload) {
    state.transactionCodesCheckersQueue = payload;
  }
};
export const actions = {
  checkerAuthorizeTransactionCode({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FIVE", true);

    return TransactionCodeService.checkerAuthorizeTransactionCode(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FIVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FIVE", false);

        throw error;
      });
  },

  allTransactionCodesCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FOUR", true);

    return TransactionCodeService.allTransactionCodesCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FOUR", false);

        const responseData = response.data;



        if (responseData.responseCode === "00") {
          commit("SET_ALL_TRANSACTIONS_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FOUR", false);

        throw error;
      });
  },

  createTransactionCode({ commit, dispatch, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING", true);

    payload.trnMaker = rootGetters["user/getUserId"];
    payload.trnChecker = "SYSTEM";
    payload.trnBankCode = rootGetters["user/getUserBankCode"];

    return TransactionCodeService.createTransactionCode(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },
  // list all tellers
  allTransactionCodes({ commit, dispatch, rootGetters }) {
    // start loader
    commit("SET_LOADING_TWO", true);

    let payload = {
      trnBankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };

    return TransactionCodeService.allTransactionCodes(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_TWO", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_TRANSACTION_CODES", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_TWO", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
