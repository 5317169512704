import axios from "axios";
import NProgress from "nprogress";
import store from "../../store/store";

import RouterUtils from "../../utils/BaseUtils/RouterUtils";

const apiClient = axios.create({
  baseURL: window.__env.api.baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 10000
});

apiClient.interceptors.request.use(config => {
  const token = store.getters["user/getUserTokenFromLocalStorage"];
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});
apiClient.interceptors.response.use(response => {
  NProgress.done();
  if (response.data.responseCode === "92") {
    RouterUtils.changeRouteTo("login");
  }
  return response;
});

export default {
  checkerAuthorizeBankUser(payload) {
    return apiClient.post("/userChecker", JSON.stringify(payload));
  },
  allBankUsersCheckersQueue(payload) {
    return apiClient.post("/userChecker", JSON.stringify(payload));
  },
  createUser(payload) {
    return apiClient.post("/userCreate", JSON.stringify(payload));
  },
  allUsers() {
    let payload = {
      userBankCode: store.getters["user/getUserBankCode"],
      source: "CBA",
      readAll: "YES"
    };
    return apiClient.post("/userRead", JSON.stringify(payload));
  },
  getUserByUsername(payload) {
    payload.userBankCode = store.getters["user/getUserBankCode"];
    payload.userCountryCode = store.getters["user/getUserCountryCode"];
    payload.source = "WEB";

    return apiClient.post("/userReadSingle", JSON.stringify(payload));
  }
};
