import axios from "axios";
import NProgress from "nprogress";
import store from "../../store/store";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";

const apiClient = axios.create({
  baseURL: window.__env.api.baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 10000
});

apiClient.interceptors.request.use(config => {
  const token = store.getters["user/getUserTokenFromLocalStorage"];
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});
apiClient.interceptors.response.use(response => {
  NProgress.done();
  if (response.data.responseCode === "92") {
    RouterUtils.changeRouteTo("login");
  }
  return response;
});

export default {
  checkerAuthorizeTill(requestBody) {
    return apiClient.post("/tillChecker", JSON.stringify(requestBody));
  },
  allTillCheckersQueue(requestBody) {
    return apiClient.post("/tillChecker", JSON.stringify(requestBody));
  },
  allTills(requestBody) {
    return apiClient.post("/tillRead", JSON.stringify(requestBody));
  },
  createTill(requestBody) {
    return apiClient.post("/tillCreate", JSON.stringify(requestBody));
  }
};
