<template>
  <div class="app-loading">
    <div class="app-actual-loader">
      <p>
        <span>
          <div class="the-loader loadingio-spinner-pulse-qs21jf25o9r">
            <div class="ldio-1e8oyijxsr6i">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoading"
};
</script>

<style scoped>
@keyframes ldio-1e8oyijxsr6i-1 {
  0% {
    top: 36px;
    height: 128px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes ldio-1e8oyijxsr6i-2 {
  0% {
    top: 41.99999999999999px;
    height: 116.00000000000001px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes ldio-1e8oyijxsr6i-3 {
  0% {
    top: 48px;
    height: 104px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
.ldio-1e8oyijxsr6i div {
  position: absolute;
  width: 30px;
}
.ldio-1e8oyijxsr6i div:nth-child(1) {
  left: 35px;
  /*background: #e15b64;*/
  background: #6479ec;
  animation: ldio-1e8oyijxsr6i-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.2s;
}
.ldio-1e8oyijxsr6i div:nth-child(2) {
  left: 85px;
  /*background: #f8b26a;*/
  background: #5369e9;
  animation: ldio-1e8oyijxsr6i-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.1s;
}
.ldio-1e8oyijxsr6i div:nth-child(3) {
  left: 135px;
  /*background: #abbd81;*/
  background: #6479ec;
  animation: ldio-1e8oyijxsr6i-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: undefineds;
}

.loadingio-spinner-pulse-qs21jf25o9r {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-1e8oyijxsr6i {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-1e8oyijxsr6i div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>
