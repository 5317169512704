import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import EomService from "../../services/newServices/EomService";
const eomService = new EomService();

const getDefaultState = () => {
  return {
    test: "",
    allEom: [],
    currentEom: {},
    currentEomItems: [],
  };
};
export const state = getDefaultState();

export const getters = {
  getCurrentEom: state => {
    return state.currentEom;
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  TEST(state, payload) {
    state.test = payload;
  },
  SET_ALL_EOM(state, payload) {
    state.allEom = payload;
  },
  SET_CURRENT_EOM(state, payload) {
    state.currentEom = payload;
  },
  SET_CURRENT_EOM_ITEMS(state, payload) {
    state.currentEomItems = payload;
  }
};
export const actions = {
  setCurrentEomItems({ commit }, payload) {
    commit("SET_CURRENT_EOM_ITEMS", payload);
  },
  setCurrentEom({ commit }, payload) {
    commit("SET_CURRENT_EOM", payload);
  },
  fetchOneEom({ commit }, eomId) {
    commit("TEST", "TEST");
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      eomId
    };

    const successAction = responseData => {
      StoreUtils.dispatch("eom/setCurrentEom", responseData);
      StoreUtils.dispatch("eom/setCurrentEomItems", responseData.data);
    };

    return eomService.fetchOneEom(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  runEom() {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      maker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID)
    };

    const successAction = responseData => {
      StoreUtils.dispatch("eom/fetchAllEom", responseData.data);
    };

    return eomService.runEom(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setAllEom({ commit }, payload) {
    commit("SET_ALL_EOM", payload);
  },
  fetchAllEom() {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.dispatch("eom/setAllEom", responseData.data);
    };

    return eomService.fetchAllEom(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
