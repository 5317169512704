<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div v-if="appUtilities.moduleLoadingNine">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="bookLoan">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Loan Product</label>
                <multiselect
                    v-model="bookLoanFormBody.loanProductCode"
                    :options="loanProductOption"
                    placeholder="Loan Product"
                    @close="$v.bookLoanFormBody.loanProductCode.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.bookLoanFormBody.loanProductCode.$error">
                <p
                    v-if="!$v.bookLoanFormBody.loanProductCode.required"
                    class="form-error-message"
                >
                  Loan Product is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                  :inputType="'text'"
                  v-model="bookLoanFormBody.loanAccountNumber"
                  placeholder="Loan Account Number"
                  :hasPreIcon="false"
                  label="Loan Account Number"
                  :class="{
                  error: $v.bookLoanFormBody.loanAccountNumber.$error
                }"
                  @blur="$v.bookLoanFormBody.loanAccountNumber.$touch()"
              >
              </BaseInput>

              <template v-if="$v.bookLoanFormBody.loanAccountNumber.$error">
                <p
                    v-if="!$v.bookLoanFormBody.loanAccountNumber.required"
                    class="form-error-message"
                >
                  Loan Account Number is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <BaseInput
                  :inputType="'amount'"
                  v-model="bookLoanFormBody.loanAmount"
                  placeholder="Amount"
                  :hasPreIcon="false"
                  :label="'Loan Amount'"
                  :class="{
                  error: $v.bookLoanFormBody.loanAmount.$error
                }"
                  @blur="$v.bookLoanFormBody.loanAmount.$touch()"
              >
              </BaseInput>

              <template v-if="$v.bookLoanFormBody.loanAmount.$error">
                <p
                    v-if="!$v.bookLoanFormBody.loanAmount.required"
                    class="form-error-message"
                >
                  Amount is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <BaseInput
                  :inputType="'text'"
                  v-model="bookLoanFormBody.loanTenure"
                  placeholder="Loan Tenure"
                  :hasPreIcon="false"
                  :label="'Loan Tenure' + selectedLoanProductTenureUnit"
                  :class="{
                  error: $v.bookLoanFormBody.loanTenure.$error
                }"
                  @blur="$v.bookLoanFormBody.loanTenure.$touch()"
              >
              </BaseInput>

              <template v-if="$v.bookLoanFormBody.loanTenure.$error">
                <p
                    v-if="!$v.bookLoanFormBody.loanTenure.required"
                    class="form-error-message"
                >
                  Loan Tenure is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                  :inputType="'text'"
                  v-model="bookLoanFormBody.loanInterestRate"
                  placeholder="Loan Interest Rate"
                  :hasPreIcon="false"
                  :label="'Loan Interest Rate'"
                  :class="{
                  error: $v.bookLoanFormBody.loanInterestRate.$error
                }"
                  @blur="$v.bookLoanFormBody.loanInterestRate.$touch()"
              >
              </BaseInput>

              <template v-if="$v.bookLoanFormBody.loanInterestRate.$error">
                <p
                    v-if="!$v.bookLoanFormBody.loanInterestRate.required"
                    class="form-error-message"
                >
                  Loan Interest Rate is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Loan Status</label>
                <multiselect
                    v-model="bookLoanFormBody.loanStatus"
                    :options="loanStatusOption"
                    placeholder="Loan Status"
                    @close="$v.bookLoanFormBody.loanStatus.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.bookLoanFormBody.loanStatus.$error">
                <p
                    v-if="!$v.bookLoanFormBody.loanStatus.required"
                    class="form-error-message"
                >
                  Loan Status is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <p class="text-right">
          <span v-if="loan.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Book Loan
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  name: "BookLoan",
  components: {
    Multiselect
  },
  data() {
    return {
      bookLoanFormBody: this.createFreshBookLoanFormBodyObject(),
      loanStatusOption: ["ACTIVE", "PENDING"]
    };
  },
  computed: {
    ...mapState(["loan", "appUtilities"]),
    ...mapGetters({
      loanProductOption: "appUtilities/getLoanProductUtilityOption",
      getLoanProductFromOptionUtility:
          "appUtilities/getLoanProductFromOptionUtility",
      getLoanProductTenureFromOptionUtility:
          "appUtilities/getLoanProductTenureFromOptionUtility"
    }),
    selectedLoanProductTenureUnit() {
      return "";
      // if (!this.bookLoanFormBody.loanProductCode) {
      //   return " (Days)";
      // }
      // Days/weeks/Months
      // return this.getLoanProductTenureFromOptionUtility(
      //   this.bookLoanFormBody.loanProductCode
      // );
    }
  },
  validations: {
    bookLoanFormBody: {
      loanProductCode: { required },
      loanAccountNumber: { required },
      loanAmount: { required },
      loanTenure: { required },
      loanInterestRate: { required },
      loanStatus: { required }
    }
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllLoanProducts", null, {
      root: true
    });
  },
  methods: {
    bookLoan() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.bookLoanFormBody.loanBankCode = this.$store.getters[
            "user/getUserBankCode"
            ];
        this.bookLoanFormBody.loanMaker = this.$store.getters["user/getUserId"];
        this.bookLoanFormBody.loanProductCode = this.getLoanProductFromOptionUtility(
            this.bookLoanFormBody.loanProductCode
        );

        this.$store
            .dispatch("loan/bookLoan", this.bookLoanFormBody, {
              root: true
            })
            .then(() => {
              this.bookLoanFormBody = {
                source: "web"
              };
              this.$v.$reset();
            })
            .catch(() => {});
      }
    },
    createFreshBookLoanFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
