import LoanService from "../../services/oldServices/LoanService.js";
import Utils from "../../utils/Utils";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import NewLoanService from "../../services/newServices/LoanService";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";
const newLoanService = new NewLoanService();

export const namespaced = true;

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    moduleLoadingTwo: false,
    moduleLoadingThree: true,
    moduleLoadingFour: false,
    moduleLoadingFive: false,
    createReportFormBody: {
      source: "web"
    },
    loanStatData: {},
    allLoans: [],
    currentLoan: {},
    currentLoanAmortizationSchedule: []
  };
};
export const state = getDefaultState();

export const getters = {
  // Checks if bank stats have already been fetched
  isReportStatsFetched: state => {
    return !Utils.objIsEmpty(state.reportStatData);
  },
  getCurrentLoan: state => {
    return state.currentLoan;
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_LOAN_STATS(state, payload) {
    state.loanStatData = payload;
  },
  SET_ALL_LOANS(state, payload) {
    state.allLoans = payload;
  },
  SET_CURRENT_LOAN_AMORTIZATION_SCHEDULE(state, payload) {
    state.currentLoanAmortizationSchedule = payload;
  },
  SET_CURRENT_LOAN(state, payload) {
    state.currentLoan = payload;
  }
};

export const actions = {
  viewLoanSchedule({ commit }, loan) {
    commit("SET_CURRENT_LOAN", loan);
    RouterUtils.changeRouteTo(
      RouterUtils.routes.loan.LOAN_AMORTIZATION_SCHEDULE
    );
  },
  setCurrentLoanAmortizationSchedule({ commit }, payload) {
    commit("SET_CURRENT_LOAN_AMORTIZATION_SCHEDULE", payload);
  },
  fetchCurrentLoanSchedule({ commit, getters }) {
    commit("SET_TEST", "TEST");

    let payload = {
      loanId: getters.getCurrentLoan.loanId.toString(),
      loanBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.dispatch(
        "loan/setCurrentLoanAmortizationSchedule",
        responseData.data
      );
    };

    return newLoanService.viewLoanSchedule(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setModuleLoadingFive({ commit }, payload) {
    commit("SET_LOADING_FIVE", payload);
  },
  changeLoanStatus({ commit, dispatch }, payload) {
    commit("SET_LOADING_FOUR", true);

    return LoanService.changeLoanStatus(payload)
      .then(response => {
        commit("SET_LOADING_FOUR", false);
        commit("SET_LOADING_FIVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          const notification = {
            type: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        commit("SET_LOADING_FOUR", false);
        commit("SET_LOADING_FIVE", false);
        throw error;
      });
  },
  allLoans({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_THREE", true);

    return LoanService.allLoans(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_LOANS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  bookLoan({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING", true);

    return LoanService.bookLoan(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};

// ===
// Private helpers
// ===
