<template>
  <div>
    <div v-if="appUtilities.moduleLoadingSix">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-12 col-sm-4">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerFirstName"
                placeholder="First Name"
                :hasPreIcon="false"
                :label="'First Name'"
                :class="{
                  error: $v.formBody.customerFirstName.$error
                }"
                @blur="$v.formBody.customerFirstName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerFirstName.$error">
                <p
                  v-if="!$v.formBody.customerFirstName.required"
                  class="form-error-message"
                >
                  First Name is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerMiddleName"
                placeholder="Middle Name"
                :hasPreIcon="false"
                :label="'Middle Name'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerLastName"
                placeholder="Last Name"
                :hasPreIcon="false"
                :label="'Last Name'"
                :class="{
                  error: $v.formBody.customerLastName.$error
                }"
                @blur="$v.formBody.customerLastName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerLastName.$error">
                <p
                  v-if="!$v.formBody.customerLastName.required"
                  class="form-error-message"
                >
                  Last Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerBvn"
                placeholder="Customer Bvn"
                :hasPreIcon="false"
                :label="'Customer Bvn'"
                :class="{ error: $v.formBody.customerBvn.$error }"
                @blur="$v.formBody.customerBvn.$touch()"
              >
              </BaseInput>
              <template v-if="$v.formBody.customerBvn.$error">
                <p
                  v-if="!$v.formBody.customerBvn.required"
                  class="form-error-message"
                >
                  Bvn is required
                </p>
                <p
                  v-if="!$v.formBody.customerBvn.numeric"
                  class="form-error-message"
                >
                  Bvn can only be Numbers
                </p>
                <p
                  v-if="!$v.formBody.customerBvn.minLength"
                  class="form-error-message"
                >
                  Bvn must be 11 numbers
                </p>
                <p
                  v-if="!$v.formBody.customerBvn.maxLength"
                  class="form-error-message"
                >
                  Bvn must be 11 numbers
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Date of Birth</label>
                <date-picker
                  v-model="formBody.customerDob"
                  placeholder="Date of Birth"
                  :first-day-of-week="1"
                  lang="en"
                  confirm
                ></date-picker>
              </div>
              <template v-if="$v.formBody.customerDob.$error">
                <p
                  v-if="!$v.formBody.customerDob.required"
                  class="form-error-message"
                >
                  Date of Birth is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'email'"
                v-model="formBody.customerEmail"
                placeholder="Email"
                :hasPreIcon="false"
                :label="'Email'"
                :class="{
                  error: $v.formBody.customerEmail.$error
                }"
                @blur="$v.formBody.customerEmail.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerEmail.$error">
                <p
                  v-if="!$v.formBody.customerEmail.required"
                  class="form-error-message"
                >
                  Email is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Currency</label>
                <multiselect
                  v-model="formBody.accountCurrency"
                  :options="accountCurrencyOptions"
                  @close="$v.formBody.accountCurrency.$touch()"
                  placeholder="Account Currency"
                ></multiselect>
              </div>
              <!--                          error-->
              <template v-if="$v.formBody.accountCurrency.$error">
                <p
                  v-if="!$v.formBody.accountCurrency.required"
                  class="form-error-message"
                >
                  Account Currency is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button @click="goBack" class="btn btn-secondary mr-1">
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span>
                <button type="submit" class="btn btn-primary mr-1">
                  NEXT
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  maxLength,
  minLength,
  numeric,
  required
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import moment from "moment";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "PersonalAccountInfoForm",
  data() {
    return {
      customerCompanyTypeOptions: ["FINTECH", "OTHERS"],
      formBody: this.createFreshFormBodyObject()
    };
  },
  components: {
    Multiselect,
    DatePicker
  },
  computed: {
    ...mapState(["appUtilities"]),
    ...mapGetters({
      accountCurrencyOptions: "appUtilities/getCurrencyUtilityOption"
    }),
    currentFormBody() {
      return StoreUtils.rootGetter("form/getFormBody");
    }
  },
  validations: {
    formBody: {
      customerFirstName: { required },
      customerLastName: { required },
      customerBvn: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11)
      },
      customerDob: { required },
      customerEmail: { required },
      accountCurrency: { required }
    }
  },

  created() {},

  methods: {
    goBack() {
      StoreUtils.commit("form/DECREASE_FORM_STAGE_BY_ONE");
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit("form/BUILD_FORM_BODY", this.formBody);
        StoreUtils.commit("form/SET_FORM_STAGE_TO", 3);
      }
    },
    createFreshFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped></style>
