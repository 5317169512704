var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.appUtilities.moduleLoadingNine)?_c('div',[_c('BaseComponentLoader')],1):_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.bookLoan.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Loan Product")]),_c('multiselect',{attrs:{"options":_vm.loanProductOption,"placeholder":"Loan Product"},on:{"close":function($event){return _vm.$v.bookLoanFormBody.loanProductCode.$touch()}},model:{value:(_vm.bookLoanFormBody.loanProductCode),callback:function ($$v) {_vm.$set(_vm.bookLoanFormBody, "loanProductCode", $$v)},expression:"bookLoanFormBody.loanProductCode"}})],1),(_vm.$v.bookLoanFormBody.loanProductCode.$error)?[(!_vm.$v.bookLoanFormBody.loanProductCode.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Loan Product is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.bookLoanFormBody.loanAccountNumber.$error
              },attrs:{"inputType":'text',"placeholder":"Loan Account Number","hasPreIcon":false,"label":"Loan Account Number"},on:{"blur":function($event){return _vm.$v.bookLoanFormBody.loanAccountNumber.$touch()}},model:{value:(_vm.bookLoanFormBody.loanAccountNumber),callback:function ($$v) {_vm.$set(_vm.bookLoanFormBody, "loanAccountNumber", $$v)},expression:"bookLoanFormBody.loanAccountNumber"}}),(_vm.$v.bookLoanFormBody.loanAccountNumber.$error)?[(!_vm.$v.bookLoanFormBody.loanAccountNumber.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Loan Account Number is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.bookLoanFormBody.loanAmount.$error
              },attrs:{"inputType":'amount',"placeholder":"Amount","hasPreIcon":false,"label":'Loan Amount'},on:{"blur":function($event){return _vm.$v.bookLoanFormBody.loanAmount.$touch()}},model:{value:(_vm.bookLoanFormBody.loanAmount),callback:function ($$v) {_vm.$set(_vm.bookLoanFormBody, "loanAmount", $$v)},expression:"bookLoanFormBody.loanAmount"}}),(_vm.$v.bookLoanFormBody.loanAmount.$error)?[(!_vm.$v.bookLoanFormBody.loanAmount.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Amount is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.bookLoanFormBody.loanTenure.$error
              },attrs:{"inputType":'text',"placeholder":"Loan Tenure","hasPreIcon":false,"label":'Loan Tenure' + _vm.selectedLoanProductTenureUnit},on:{"blur":function($event){return _vm.$v.bookLoanFormBody.loanTenure.$touch()}},model:{value:(_vm.bookLoanFormBody.loanTenure),callback:function ($$v) {_vm.$set(_vm.bookLoanFormBody, "loanTenure", $$v)},expression:"bookLoanFormBody.loanTenure"}}),(_vm.$v.bookLoanFormBody.loanTenure.$error)?[(!_vm.$v.bookLoanFormBody.loanTenure.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Loan Tenure is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.bookLoanFormBody.loanInterestRate.$error
              },attrs:{"inputType":'text',"placeholder":"Loan Interest Rate","hasPreIcon":false,"label":'Loan Interest Rate'},on:{"blur":function($event){return _vm.$v.bookLoanFormBody.loanInterestRate.$touch()}},model:{value:(_vm.bookLoanFormBody.loanInterestRate),callback:function ($$v) {_vm.$set(_vm.bookLoanFormBody, "loanInterestRate", $$v)},expression:"bookLoanFormBody.loanInterestRate"}}),(_vm.$v.bookLoanFormBody.loanInterestRate.$error)?[(!_vm.$v.bookLoanFormBody.loanInterestRate.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Loan Interest Rate is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Loan Status")]),_c('multiselect',{attrs:{"options":_vm.loanStatusOption,"placeholder":"Loan Status"},on:{"close":function($event){return _vm.$v.bookLoanFormBody.loanStatus.$touch()}},model:{value:(_vm.bookLoanFormBody.loanStatus),callback:function ($$v) {_vm.$set(_vm.bookLoanFormBody, "loanStatus", $$v)},expression:"bookLoanFormBody.loanStatus"}})],1),(_vm.$v.bookLoanFormBody.loanStatus.$error)?[(!_vm.$v.bookLoanFormBody.loanStatus.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Loan Status is required ")]):_vm._e()]:_vm._e()],2)])]),_c('p',{staticClass:"text-right"},[(_vm.loan.moduleLoading)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Book Loan ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }