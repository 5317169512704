<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
<!--            <h4 class="card-title mb-4">End Of Day Report</h4>-->
            <RunEodCard />
            <!-- Supposed Form -->
            <div class="form-body">
              <AllEodTable />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../../router/layouts/main";
import PageHeader from "../../../components/AppComponents/page-header";
import AllEodTable from "../../../components/Kleyton/Tables/Report/EOD/AllEodTable";
import RunEodCard from "../../../components/Kleyton/Cards/Report/EOD/RunEodCard";

export default {
  name: "AllEod",
  page: {
    title: "End Of Day",
    meta: [{ name: "End Of Day", content: window.__env.app.description }]
  },
  components: {
    RunEodCard,
    AllEodTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "End Of Day",

      items: [
        {
          text: "Report",
          href: "/"
        },
        {
          text: "End Of Day",
          active: true
        }
      ]
    };
  },
  methods: {}
};
</script>

<style scoped></style>
