import { render, staticRenderFns } from "./AllLoansTable.vue?vue&type=template&id=573ce813&scoped=true&"
import script from "./AllLoansTable.vue?vue&type=script&lang=js&"
export * from "./AllLoansTable.vue?vue&type=script&lang=js&"
import style0 from "./AllLoansTable.vue?vue&type=style&index=0&id=573ce813&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573ce813",
  null
  
)

export default component.exports