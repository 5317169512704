<template>
  <div>
    <div v-if="appUtilities.moduleLoadingSeven">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-6">
            <CustomerAccountCard
              :account="ft.stageOneResponse"
              destination="from"
            />
          </div>
          <div class="col-6">
            <CustomerAccountCard :account="tillAccount" destination="to" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'amount'"
                v-model="formBody.trnAmount"
                placeholder="Amount"
                :hasPreIcon="false"
                :label="'Amount'"
                :class="{
                  error: $v.formBody.trnAmount.$error
                }"
                @blur="$v.formBody.trnAmount.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.trnAmount.$error">
                <p
                  v-if="!$v.formBody.trnAmount.required"
                  class="form-error-message"
                >
                  Amount is required
                </p>

                <p
                  v-if="!$v.formBody.accountNumber.numeric"
                  class="form-error-message"
                >
                  Amount has to be a Number
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>FT Product</label>
                <multiselect
                  v-model="formBody.ftProductCode"
                  :options="ftProductCodeUtilityOption"
                  @close="$v.formBody.ftProductCode.$touch()"
                  placeholder="FT Product"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.ftProductCode.$error">
                <p
                  v-if="!$v.formBody.ftProductCode.required"
                  class="form-error-message"
                >
                  FT Product is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.trnNarration"
                placeholder="Narration"
                :hasPreIcon="false"
                :label="'Narration'"
                :class="{
                  error: $v.formBody.trnNarration.$error
                }"
                @blur="$v.formBody.trnNarration.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.trnNarration.$error">
                <p
                  v-if="!$v.formBody.trnNarration.required"
                  class="form-error-message"
                >
                  Narration is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button
                  @click="goBackToStageOne"
                  class="btn btn-secondary mr-1"
                >
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Transfer
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import { required } from "vuelidate/lib/validators";
import CustomerAccountCard from "../../../Cards/FT/CustomerAccountCard";

export default {
  name: "CwFinalForm",
  components: { CustomerAccountCard },
  data() {
    return {
      formBody: this.freshFormBodyObject()
    };
  },
  computed: {
    ...mapState(["ft", "loader", "appUtilities"]),
    ...mapGetters({
      getBankNameFromIdUtility: "appUtilities/getBankNameFromCodeUtility",
      ftProductCodeUtilityOption: "appUtilities/getFtProductCodeUtilityOption",
      getTransactionCodeUtilityOption:
        "appUtilities/getTransactionCodeUtilityOption"
    }),
    tillAccount() {
      return {
        accountBankName: this.getBankNameFromIdUtility(
          StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_BANK_CODE)
        ),
        accountNumber:
          StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
          ) !== "" &&
          StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
          ) != null
            ? StoreUtils.rootGetter(
                StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
              )
            : StoreUtils.rootGetter(
                StoreUtils.getters.user.GET_USER_VAULT_ACCOUNT_NUMBER
              ),
        accountName:
          StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
          ) !== "" &&
          StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_TILL_ACCOUNT_NUMBER
          ) != null
            ? "Till Account"
            : "Valt Account",
        accountBalance: this.ft.tillBalance
      };
    }
  },
  validations: {
    formBody: {
      ftProductCode: { required },
      trnAmount: { required },
      trnNarration: { required }
    }
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllFundsTransferProduct", null, {
      root: true
    });
  },
  mounted() {
    StoreUtils.dispatch("ft/fetchTillAccountBalance", this.tillAccountNumber);
  },
  methods: {
    goBackToStageOne() {
      StoreUtils.dispatch(StoreUtils.actions.ft.GO_BACK_TO_STAGE_ONE);
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.dispatch(
          StoreUtils.actions.ft.CASH_WITHDRAWAL,
          this.formBody
        );
      }
    },

    freshFormBodyObject() {
      return {
        source: "WEB"
      };
    }
  }
};
</script>

<style scoped></style>
