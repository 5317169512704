// create a new Bank
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import SwitchTransactionService from "../../services/newServices/SwitchTransactionService";
const switchTransactionService = new SwitchTransactionService();

const getDefaultState = () => {
  return {
    test: "",
    nipInwards: []
  };
};
export const state = getDefaultState();

export const getters = {};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  TEST(state, payload) {
    state.test = payload;
  },
  SET_NIP_INWARDS(state, payload) {
    state.nipInwards = payload;
  }
};
export const actions = {
  fetchDecisionBox() {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      readAll: "NO",
      readType: "decisionBox"
    };

    const successAction = responseData => {
      StoreUtils.commit("switchTransaction/SET_NIP_INWARDS", responseData.data);
    };

    return switchTransactionService.fetchAllNipInwards(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchNipInwards() {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      readAll: "NO"
    };

    const successAction = responseData => {
      StoreUtils.commit("switchTransaction/SET_NIP_INWARDS", responseData.data);
    };

    return switchTransactionService.fetchAllNipInwards(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
