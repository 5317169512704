// import Starter from "../../views/Starter";
import CreateTeller from "../../views/Teller/CreateTeller";
import AllTellers from "../../views/Teller/AllTellers";
import CreateCustomer from "../../views/Customer/CreateCustomer";
import AllCustomers from "../../views/Customer/AllCustomers";
import AllGlLevel1 from "../../views/GlLevel1/AllGlLevel1";
import CreateGlLevel1 from "../../views/GlLevel1/CreateGlLevel1";
import CreateGlLevel2 from "../../views/GlLevel2/CreateGlLevel2";
import AllGlLevel2 from "../../views/GlLevel2/AllGlLevel2";
// import CreateJournal from "../../views/Journal/CreateJournal";
// import AllJournals from "../../views/Journal/AllJournals";
import CreateUser from "../../views/User/CreateUser";
import AllUsers from "../../views/User/AllUsers";
import CreateQueryView from "../../views/Report/CreateQueryView";
import CreateInternalAccount from "../../views/internalAccount/CreateInternalAccount";
import AllInternalAccount from "../../views/internalAccount/AllInternalAccount";
import CreateTill from "../../views/Till/CreateTill";
import AllTill from "../../views/Till/AllTill";
import CreateVault from "../../views/Vault/CreateVault";
import AllVault from "../../views/Vault/AllVault";
import ReadAccounts from "../../views/Customer/ReadAccounts";
import CreatePrivilege from "../../views/Privilege/CreatePrivilege";
import AllPrivileges from "../../views/Privilege/AllPrivileges";
import TillTransferToVault from "../../views/CashTransactions/TillTransferToVault";

export default [
    {
    path: "/user/create",
    name: "user-create-user",
    meta: {
      authRequired: false
    },
    component: CreateUser
  },
  {
    path: "/query/create",
    name: "query-create-query",
    meta: {
      authRequired: false
    },
    component: CreateQueryView
  },

  {
    path: "/user/all",
    name: "user-all-users",
    meta: {
      authRequired: false
    },
    component: AllUsers
  },

  ///// Teller
  {
    path: "/teller/create",
    name: "teller-create-teller",
    meta: {
      authRequired: false
    },
    component: CreateTeller
  },

  {
    path: "/teller/all",
    name: "teller-all-teller",
    meta: {
      authRequired: false
    },
    component: AllTellers
  },

  {
    path: "/till/create",
    name: "till-create-till",
    meta: {
      authRequired: false
    },
    component: CreateTill
  },

  {
    path: "/till/all",
    name: "till-view-till",
    meta: {
      authRequired: false
    },
    component: AllTill
  },

  {
    path: "/account/all",
    name: "accounts-all-accounts",
    meta: {
      authRequired: false
    },
    component: ReadAccounts
  },

  {
    path: "/vault/create",
    name: "vault-create-vault",
    meta: {
      authRequired: false
    },
    component: CreateVault
  },

  {
    path: "/vault/all",
    name: "vault-view-vault",
    meta: {
      authRequired: false
    },
    component: AllVault
  },

  {
    path: "/customer/create",
    name: "customer-create-customer",
    meta: {
      authRequired: false
    },
    component: CreateCustomer
  },
  {
    path: "/customer/all",
    name: "customer-all-customers",
    meta: {
      authRequired: false
    },
    component: AllCustomers
  },
  {
    path: "/glLevel1/create",
    name: "gllevel1-create-glLevel1",
    meta: {
      authRequired: false
    },
    component: CreateGlLevel1
  },
  {
    path: "/glLevel1/all",
    name: "gllevel1-all-glLevel1",
    meta: {
      authRequired: false
    },
    component: AllGlLevel1
  },
  {
    path: "/glLevel2/create",
    name: "glLevel2-create-glLevel2",
    meta: {
      authRequired: false
    },
    component: CreateGlLevel2
  },
  {
    path: "/glLevel2/all",
    name: "glLevel2-all-glLevel2",
    meta: {
      authRequired: false
    },
    component: AllGlLevel2
  },
  // {
  //   path: "/journal/create",
  //   name: "journal-create-journal",
  //   meta: {
  //     authRequired: false
  //   },
  //   component: CreateJournal
  // },
  // {
  //   path: "/journal/all",
  //   name: "journal-all-journal",
  //   meta: {
  //     authRequired: false
  //   },
  //   component: AllJournals
  // },

  {
    path: "/internalAccount/create",
    name: "bank-create-internal-account",
    meta: {
      authRequired: false
    },
    component: CreateInternalAccount
  },
  {
    path: "/internalAccount/all",
    name: "bank-internal-account",
    meta: {
      authRequired: false
    },
    component: AllInternalAccount
  },
  {
    path: "/privilege/create",
    name: "role-create-privilege",
    meta: {
      authRequired: false
    },
    component: CreatePrivilege
  },
  {
    path: "/privilege/all",
    name: "role-all-privileges",
    meta: {
      authRequired: false
    },
    component: AllPrivileges
  },
  {
    path: "/cashtransactions/tilltovault",
    name: "till-transfer-to-vault",
    meta: {
      authRequired: false
    },
    component: TillTransferToVault
  }
  ];
