var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.appUtilities.moduleLoadingFive)?_c('div'):_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.vaultTransferToCBN()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Credit Account Number")]),_c('multiselect',{attrs:{"options":_vm.internalAccountsUtilityOption,"placeholder":"Credit Account Number"},on:{"input":function($event){return _vm.$v.cashTransactionFormBody.creditAccountNumber.$touch()}},model:{value:(_vm.cashTransactionFormBody.creditAccountNumber),callback:function ($$v) {_vm.$set(_vm.cashTransactionFormBody, "creditAccountNumber", $$v)},expression:"cashTransactionFormBody.creditAccountNumber"}})],1),(_vm.$v.cashTransactionFormBody.creditAccountNumber.$error)?[(
                  !_vm.$v.cashTransactionFormBody.creditAccountNumber.required
                )?_c('p',{staticClass:"form-error-message"},[_vm._v(" Credit Account Number is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Debit Account Number")]),_c('multiselect',{attrs:{"options":_vm.internalAccountsUtilityOption,"placeholder":"Debit Account Number"},on:{"input":function($event){return _vm.$v.cashTransactionFormBody.debitAccountNumber.$touch()}},model:{value:(_vm.cashTransactionFormBody.debitAccountNumber),callback:function ($$v) {_vm.$set(_vm.cashTransactionFormBody, "debitAccountNumber", $$v)},expression:"cashTransactionFormBody.debitAccountNumber"}})],1),(_vm.$v.cashTransactionFormBody.debitAccountNumber.$error)?[(!_vm.$v.cashTransactionFormBody.debitAccountNumber.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Debit Account Number is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("FT Product")]),_c('multiselect',{attrs:{"options":_vm.ftProductCodeUtilityOption,"placeholder":"FT Product"},on:{"close":function($event){return _vm.$v.cashTransactionFormBody.ftProductCode.$touch()}},model:{value:(_vm.cashTransactionFormBody.ftProductCode),callback:function ($$v) {_vm.$set(_vm.cashTransactionFormBody, "ftProductCode", $$v)},expression:"cashTransactionFormBody.ftProductCode"}})],1),(_vm.$v.cashTransactionFormBody.ftProductCode.$error)?[(!_vm.$v.cashTransactionFormBody.ftProductCode.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" FT Product is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.cashTransactionFormBody.trnAmount.$error
              },attrs:{"inputType":'text',"placeholder":"Amount","hasPreIcon":false,"label":'Amount'},on:{"input":_vm.formatAmount,"blur":function($event){return _vm.$v.cashTransactionFormBody.trnAmount.$touch()}},model:{value:(_vm.cashTransactionFormBody.trnAmount),callback:function ($$v) {_vm.$set(_vm.cashTransactionFormBody, "trnAmount", $$v)},expression:"cashTransactionFormBody.trnAmount"}}),(_vm.$v.cashTransactionFormBody.trnAmount.$error)?[(!_vm.$v.cashTransactionFormBody.trnAmount.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Amount is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.cashTransactionFormBody.trnNarration.$error
              },attrs:{"inputType":'text',"placeholder":"Narration","hasPreIcon":false,"label":'Narration'},on:{"blur":function($event){return _vm.$v.cashTransactionFormBody.trnNarration.$touch()}},model:{value:(_vm.cashTransactionFormBody.trnNarration),callback:function ($$v) {_vm.$set(_vm.cashTransactionFormBody, "trnNarration", $$v)},expression:"cashTransactionFormBody.trnNarration"}}),(_vm.$v.cashTransactionFormBody.trnNarration.$error)?[(!_vm.$v.cashTransactionFormBody.trnNarration.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Narration is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{},attrs:{"inputType":'date',"placeholder":"Narration","hasPreIcon":false,"label":'Transaction Date'},model:{value:(_vm.cashTransactionFormBody.trnDate),callback:function ($$v) {_vm.$set(_vm.cashTransactionFormBody, "trnDate", $$v)},expression:"cashTransactionFormBody.trnDate"}})],1)])]),_c('p',{staticClass:"text-left"},[(_vm.cashTransaction.moduleLoading)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Transfer ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }