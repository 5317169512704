<template>
  <div>
    <!--        control-->
    <a
      v-if="queryBy === 'customer-id'"
      @click="viewMoreCustomerInfo(currentCustomer.customerId)"
      class="view-customer-info-btn"
      v-b-tooltip.hover.right
      title="View More Info"
    >
      <i class="bx bx-info-circle"></i>
    </a>

    <span
      v-if="queryBy === 'account-number'"
      @click="fetchCustomerInfoByAccountNUmber(currentCustomer.accountNumber)"
      class="d-flex mr-3 avatar-title bg-primary rounded-circle font-size-16 real-avatar pointer"
      v-b-tooltip.hover.bottom
      title="View Customers Info"
      >{{ currentCustomer.accountName | getInitials }}</span
    >
    <!--    control END-->

    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="450"
      :height="500"
      measure="px"
      animation="zoom"
      @hide="closeModal()"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <!--          <h4 class="card-title mb-4">Customer Info</h4>-->
          <div
            v-if="customer.moduleLoadingFive || loader.tableActionButtonLoader"
          >
            <BaseComponentLoader />
          </div>
          <div v-else>
            <div v-if="showEdit" class="view-more-customer-info">
              <div class="">
                <div class="">
                  <h4 class="card-title mb-4">Edit Customer Info</h4>
                  <div class="py-2">
                    <EditCustomerForm
                      :current-customer="currentCustomer"
                      @editSuccess="closeModal()"
                    />
                  </div>
                  <br />
                  <br />
                  <a @click="closeEditCustomer()" class="sample-file-download">
                    <span class="fas fa-arrow-left"></span>
                    Back
                  </a>
                </div>
              </div>
            </div>

            <div v-else class="view-more-customer-info">
              <div class="view-more-customer-info-wrap">
                <div class="view-more-top">
                  <div class="row">
                    <div class="col-sm-12">
                      <p class="customer-bio">
                        <span class="customer-avatar">
                          <viewer
                            :title="false"
                            :options="imageViewerOptions"
                            :images="[
                              customer.currentCustomerMoreInfo.customerPicture,
                            ]"
                          >
                            <img
                              :src="
                                customer.currentCustomerMoreInfo.customerPicture
                              "
                              alt=""
                            />
                          </viewer>
                        </span>

                        <span class="customer-info">
                          <span class="name">{{
                            customer.currentCustomerMoreInfo.customerName
                              | firstCaseCapitalSpace
                          }}</span>

                          <span class="age">
                            {{
                              calculateAge(
                                customer.currentCustomerMoreInfo.customerDob
                              )
                            }}
                            years
                          </span>
                          <span
                            class="status badge badge-pill font-size-12 badge-soft-success"
                            :class="{
                              'badge-soft-success':
                                `${customer.currentCustomerMoreInfo.customerStatus}` ===
                                'ACTIVE',
                              'badge-soft-warning':
                                `${customer.currentCustomerMoreInfo.customerStatus}` ===
                                'PENDING',
                              'badge-soft-danger':
                                `${customer.currentCustomerMoreInfo.customerStatus}` ===
                                'INACTIVE',
                            }"
                            >{{
                              customer.currentCustomerMoreInfo.customerStatus
                            }}</span
                          >
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="view-more-mid">
                  <div class="accounts-carousel">
                    <carousel
                      :items="1"
                      :center="true"
                      :autoplay="true"
                      :dots="true"
                      :nav="false"
                    >
                      <div
                        v-for="account in customer.currentCustomerMoreInfo
                          .accounts"
                        :key="account.accountId"
                        class="account-slide"
                      >
                        <b-card bg-variant="primary" class="text-white-50">
                          <p class="account-balance mt-0 mb-4 text-white">
                            <i
                              v-if="account.accountCurrency === 'NGN'"
                              class="currency"
                              >₦</i
                            >
                            <i
                              v-if="account.accountCurrency === 'GBP'"
                              class="currency"
                              >£</i
                            >
                            <i
                              v-if="account.accountCurrency === 'USD'"
                              class="currency"
                              >$</i
                            >
                            {{ account.accountBalance | newAmountFormatter }}
                          </p>
                          <p class="account-number card-text">
                            {{ account.accountNumber }}
                          </p>
                          <p class="account-type card-text">
                            <span
                              class="badge badge-soft-primary badge-pill font-size-12"
                            >
                              {{
                                account.accountStatus | firstCaseCapitalSpace
                              }}
                            </span>
                          </p>
                        </b-card>
                      </div>
                    </carousel>
                  </div>
                </div>

                <div class="view-more-base">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">BVN :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{ customer.currentCustomerMoreInfo.customerBvn }}
                            </p>
                          </td>
                        </tr>

                        <tr
                          v-if="
                            this.customer.upgradeLogsReadByCustomerId.data !=
                            null
                          "
                        >
                          <td>
                            <p class="view-more-base-tag">NIN :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.upgradeLogsReadByCustomerId.data.idCardNumber
                              }}
                            </p>
                          </td>
                        </tr>

                        <tr v-else>
                          <td>
                            <p class="view-more-base-tag">NIN :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value"></p>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <p class="view-more-base-tag">Phone :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo
                                  .customerPhoneNumber
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Email :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo.customerEmail
                                  | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Dob :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo.customerDob
                                  | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Address :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo.customerAddress
                                  | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">State :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo.customerState
                                  | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Country :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo.customerCountry
                                  | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Branch Code :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo
                                  .customerBranchCode | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Account Officer :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo
                                  .customerAccountOfficer | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Allow SMS :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo
                                  .customerAllowSms | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Allow Email :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo
                                  .customerAllowEmail | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">
                              Allow Push Notification :
                            </p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{
                                customer.currentCustomerMoreInfo
                                  .customerAllowPushNotification | toLowercase
                              }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p class="view-more-base-tag">Signature :</p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              <span>
                                <viewer
                                  :title="false"
                                  :options="imageViewerOptions"
                                  :images="[
                                    customer.currentCustomerMoreInfo
                                      .customerMandate,
                                  ]"
                                >
                                  <img
                                    :src="
                                      customer.currentCustomerMoreInfo
                                        .customerMandate
                                    "
                                    alt=""
                                    class="customer-signature"
                                  />
                                </viewer>
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- end table-responsive -->
                </div>

                <div class="edit-customer">
                  <p class="text-center">
                    <button
                      @click="openEditCustomer()"
                      class="btn btn-warning mt-2"
                    >
                      Edit Customer Info
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import carousel from "vue-owl-carousel2";
import moment from "moment";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import EditCustomerForm from "../../Forms/Customer/EditCustomerForm";

export default {
  name: "ViewCustomerInfoModal",
  data() {
    return {
      modalOpen: false,
      customerId: "",
      imageViewerOptions: {
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: false,
      },
      showEdit: false,
    };
  },
  props: {
    currentCustomer: {
      type: Object,
      required: true,
    },
    queryBy: {
      type: String,
      default: "customer-id",
    },
  },
  components: { EditCustomerForm, carousel },
  computed: {
    ...mapState(["customer", "loader"]),

    getUpdateAllLogs() {
      return StoreUtils.rootGetters(
        StoreUtils.getters.customer.GET_UPDATE_ALL_LOGS
      );
    },
  },
  methods: {
    openEditCustomer() {
      this.showEdit = true;
    },
    assignCustomerId() {
      this.customerId = this.currentCustomer.customerId;
    },
    // childMethod() {
    //   this.$store.dispatch("customer/upgradeLogsReadByCustomerId", {
    //     customerId: this.currentCustomer.customerId,
    //   });
    // },
    closeEditCustomer() {
      this.showEdit = false;
    },
    fetchCustomerInfoByAccountNUmber(accountNumber) {
      this.openModal();
      StoreUtils.dispatch(
        "customer/fetchCustomerInfoByAccountNumber",
        accountNumber
      );
    },
    viewMoreCustomerInfo(customerId) {
      if (this.queryBy === "customer-id") {
        this.openModal();
      }
      StoreUtils.dispatch("customer/viewMoreCustomerInfo", customerId);
      this.$store.dispatch("customer/upgradeLogsReadByCustomerId", {
        customerId: customerId,
      });
    },

    calculateAge(dob) {
      return Math.trunc(moment().diff(dob, "years", true));
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.closeEditCustomer();
    },
  },

  created() {
    this.assignCustomerId();
    // this.$store.dispatch("customer/allLogsUpgrade", { readAll: "YES" });
    // this.$store.dispatch("customer/upgradeLogsReadByCustomerId", {
    //   customerId: this.currentCustomer.customerId,
    // });
  },
};
</script>

<style scoped>
.view-customer-info-btn {
  position: relative;
  height: 20px;
  width: 20px;
  display: block;
  padding: 2px 2px;
  margin-top: 0;
  background-color: #795548;
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.view-customer-info-btn i {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}
.avatar {
  cursor: pointer;
}
</style>
