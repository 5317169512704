// Do a new CashTransaction
import CashTransactionsService from "../../services/oldServices/CashTransactionsService";
import CashTransactionService from "../../services/newServices/CashTransactionService";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
const cashTransactionService = new CashTransactionService();

const getDefaultState = () => {
  return {
    moduleLoading: false,
    moduleLoadingThree: false,
    cashTransactionHistory: []
  };
};

export const state = getDefaultState();

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_ALL_TRANSACTIONS(state, payload) {
    state.cashTransactionHistory = payload;
  }
};

export const actions = {
  cashTransaction({ commit, dispatch }, payload) {
    commit("SET_TEST", "test");

    // start loader
    commit("SET_LOADING", true);

    return CashTransactionsService.cashTransaction(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `Cash Transferred Successfully`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Transfer Declined- ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },

  // list all Transactions History

  cashTransactionsHistory(undefined, payload) {
    StoreUtils.commit("cashTransaction/SET_ALL_TRANSACTIONS", []);

    const successAction = responseData => {
      StoreUtils.commit(
        "cashTransaction/SET_ALL_TRANSACTIONS",
        responseData.data
      );
    };

    return cashTransactionService.fetchTellerTransactionHistory(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  allTransactionHistory(undefined, payload) {
    StoreUtils.commit("cashTransaction/SET_ALL_TRANSACTIONS", []);
    const successAction = responseData => {
      StoreUtils.commit(
        "cashTransaction/SET_ALL_TRANSACTIONS",
        responseData.data
      );
    };

    return cashTransactionService.fetchAllTransactionHistory(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  customerTransactions(undefined, payload) {
    StoreUtils.commit("cashTransaction/SET_ALL_TRANSACTIONS", []);
    const successAction = responseData => {
      StoreUtils.commit(
        "cashTransaction/SET_ALL_TRANSACTIONS",
        responseData.data
      );
    };

    return cashTransactionService.fetchCustomerTransactions(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
