var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createProduct.apply(null, arguments)}}},[_c('div',{staticClass:"row"},_vm._l((_vm.product.createProductFields),function(field,index){return _c('div',{key:index,staticClass:"col-sm-6"},[(
            field.templateDataType.toUpperCase() === 'STRING' ||
              field.templateDataType.toUpperCase() === 'TEXT'
          )?_c('div',[_c('BaseInput',{attrs:{"inputType":'text',"placeholder":field.templateLabel,"hasPreIcon":false,"label":field.templateLabel,"required":""},model:{value:(_vm.createProductFormBody[field.templateDescription]),callback:function ($$v) {_vm.$set(_vm.createProductFormBody, field.templateDescription, $$v)},expression:"createProductFormBody[field.templateDescription]"}})],1):_vm._e(),(
            field.templateDataType.toUpperCase() === 'NUMBER' ||
              field.templateDataType.toUpperCase() === 'INTEGER'
          )?_c('div',[_c('BaseInput',{attrs:{"inputType":'number',"placeholder":field.templateLabel,"hasPreIcon":false,"label":field.templateLabel,"required":""},model:{value:(_vm.createProductFormBody[field.templateDescription]),callback:function ($$v) {_vm.$set(_vm.createProductFormBody, field.templateDescription, $$v)},expression:"createProductFormBody[field.templateDescription]"}})],1):_vm._e(),(field.templateDataType.toUpperCase() === 'FLOAT')?_c('div',[_c('BaseInput',{attrs:{"inputType":'amount',"placeholder":field.templateLabel,"hasPreIcon":false,"label":field.templateLabel,"required":""},model:{value:(_vm.createProductFormBody[field.templateDescription]),callback:function ($$v) {_vm.$set(_vm.createProductFormBody, field.templateDescription, $$v)},expression:"createProductFormBody[field.templateDescription]"}})],1):_vm._e(),(
            field.templateDataType.toUpperCase() === 'DATETIME' ||
              field.templateDataType.toUpperCase() === 'DATE'
          )?_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(field.templateLabel))]),_c('date-picker',{attrs:{"first-day-of-week":1,"lang":"en","confirm":""},model:{value:(_vm.createProductFormBody[field.templateDescription]),callback:function ($$v) {_vm.$set(_vm.createProductFormBody, field.templateDescription, $$v)},expression:"createProductFormBody[field.templateDescription]"}})],1)]):_vm._e(),(
            field.templateDataType.toUpperCase() === 'PASSWORD' ||
              field.templateDataType.toUpperCase() === 'PASSCODE'
          )?_c('div',[_c('BaseInput',{attrs:{"inputType":'number',"placeholder":field.templateLabel,"hasPreIcon":false,"label":field.templateLabel,"required":""},model:{value:(_vm.createProductFormBody[field.templateDescription]),callback:function ($$v) {_vm.$set(_vm.createProductFormBody, field.templateDescription, $$v)},expression:"createProductFormBody[field.templateDescription]"}})],1):_vm._e(),(
            field.templateDataType.toUpperCase() === 'LIST' ||
              field.templateDataType.toUpperCase() === 'SELECT'
          )?_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(field.templateLabel))]),_c('multiselect',{attrs:{"options":field.templateList.split(','),"placeholder":field.templateLabel},model:{value:(_vm.createProductFormBody[field.templateDescription]),callback:function ($$v) {_vm.$set(_vm.createProductFormBody, field.templateDescription, $$v)},expression:"createProductFormBody[field.templateDescription]"}})],1)]):_vm._e()])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('p',{staticClass:"text-left"},[_c('span',[_c('button',{staticClass:"btn btn-secondary mr-1",on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" Back ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('p',{staticClass:"text-right"},[(_vm.product.moduleLoadingEight)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Create "+_vm._s(_vm._f("firstCaseCapital")(_vm._f("toLowercase")(_vm.product.selectedProductType.prdTemplateDestcription)))+" Product ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }