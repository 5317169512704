<template>
  <div>
    <!--        control-->
    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
      <template v-slot:button-content>
        <i class="mdi mdi-dots-horizontal font-size-18" />
      </template>

      <b-dropdown-item @click="modalOpen = true">
        <i class="fas fa-pencil-alt text-success mr-1" /> Edit Account
      </b-dropdown-item>
      <b-dropdown-item @click="openLienModal">
        <i class="fas fa-pencil-alt text-success mr-1" /> Create Lien
      </b-dropdown-item>

      <b-dropdown-item
        v-if="userBankTypeIsAgency"
        @click="openTerminalAssignmentModal"
      >
        <i class="fas fa-terminal text-primary mr-1" /> Assign Terminal
      </b-dropdown-item>
      <b-dropdown-item @click="openAltModal">
        <i class="fas fa-ban text-danger mr-1" /> Close Account
      </b-dropdown-item>
    </b-dropdown>

    <!--        Edit Customer modal-->
    <vodal
      :show="modalOpen"
      :width="440"
      :height="440"
      measure="px"
      animation="zoom"
      @hide="modalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Edit Customer Account</h4>

          <EditCustomerAccountForm
            :edit-form-body="currentCustomer"
            :show="modalOpen"
            @editSuccessful="closeModal()"
          />
        </div>
      </div>
    </vodal>
    <!--        Create Lien modal-->
    <vodal
      :show="lienModalOpen"
      :width="790"
      :height="670"
      measure="px"
      animation="zoom"
      @hide="lienModalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Create Lien</h4>

          <CreateLienForm
            :edit-form-body="currentCustomer"
            @editSuccessful="closeLienModal()"
          />
        </div>
      </div>
    </vodal>

    <!--        Assign Terminal modal-->
    <vodal
      :show="assignTerminalModalOpen"
      :width="440"
      :height="440"
      measure="px"
      animation="zoom"
      @hide="closeTerminalAssignmentModal"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Assign Terminal to Customer Account</h4>

          <AssignTerminalToCustomerAccountForm
            :current-customer="currentCustomer"
            @terminalAssignmentSuccessful="closeTerminalAssignmentModal"
          ></AssignTerminalToCustomerAccountForm>
        </div>
      </div>
    </vodal>

    <!--        Close Account modal-->
    <vodal
      :show="altModalOpen"
      :width="600"
      :height="600"
      measure="px"
      animation="zoom"
      @hide="closeAltModal"
      :closeOnClickMask="true"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Close Customer Account</h4>
          <CloseAccountCard
            :current-customer="currentCustomer"
            :current-customer-account="currentCustomerAccount"
            :current-customer-account-accruals="currentCustomerAccountAccruals"
          />
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import EditCustomerAccountForm from "../../Forms/Customer/EditCustomerAccountForm";
import CreateLienForm from "../../Forms/Customer/CreateLienForm";
import AssignTerminalToCustomerAccountForm from "@/components/Kleyton/Forms/Terminal/AssignTerminalToCustomerAccountForm";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import CloseAccountCard from "@/components/Kleyton/Cards/customer/CloseAccountCard";
export default {
  name: "EditCustomerAccountModal",
  components: {
    CreateLienForm,
    CloseAccountCard,
    AssignTerminalToCustomerAccountForm,
    EditCustomerAccountForm
  },
  data() {
    return {
      model:{
        bankCode: "",
        accountNumber: ""
      },
      modalOpen: false,
      assignTerminalModalOpen: false,
      altModalOpen: false,
      lienModalOpen: false
    };
  },
  props: {
    currentCustomer: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentCustomerAccountAccruals() {
      return StoreUtils.rootGetter(StoreUtils.getters.table.GET_ALT_TABLE_DATA);
    },
    currentCustomerAccount() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.table.GET_CURRENT_TABLE_OBJECT
      );
    },
    userBankTypeIsAgency() {
      return StoreUtils.rootGetter("user/getUserBankType") === "AGENCY";
    }
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    openLienModal() {
      this.lienModalOpen = true
      this.model.bankCode = StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_BANK_CODE),
          this.model.accountNumber = this.currentCustomer.accountNumber
          this.$store.dispatch("customer/allUserLien", this.model, {root: true
          });

    },
    closeLienModal() {
      this.lienModalOpen = false
    },
    openTerminalAssignmentModal() {
      this.assignTerminalModalOpen = true;
    },
    closeTerminalAssignmentModal() {
      this.assignTerminalModalOpen = false;
    },
    openAltModal() {
      this.altModalOpen = true;
      StoreUtils.dispatch("customer/fetchAccountAccruals", {
        accountNumber: this.currentCustomer.accountNumber
      });
    },
    closeAltModal() {
      this.altModalOpen = false;
    }
  }
};
</script>

<style scoped></style>
