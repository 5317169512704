// create a new Bank
import FundsTransferService from "../../services/oldServices/FundsTransferService";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";

const getDefaultState = () => {
  return {
    test: "test",
    moduleLoading: false,
    moduleLoadingTwo: false,
    moduleLoadingThree: false,
    moduleLoadingFour: false,
    moduleLoadingFive: true,
    moduleLoadingSix: false,
    moduleLoadingSeven: true,
    moduleLoadingEight: false,
    moduleLoadingNine: true,
    moduleLoadingTen: false,
    moduleLoadingEleven: true,
    moduleLoadingTwelve: false,
    fundsTransferStage: "0",
    cashWithdrawalStage: "0",
    cashDepositStage: "0",
    balanceEnquiryPayload: {},
    balanceEnquiryResponse: {},
    allFundsTransferCheckersQueue: [],
    allCashWithdrawalCheckersQueue: [],
    allCashDepositCheckersQueue: [],
    allCashTransactionsCheckersQueue: []
  };
};
export const state = getDefaultState();

export const getters = {
  // Whether the user is currently logged in.
  generateUUID() {
    let d = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  },
  getBalanceEnquiryPayload: state => {
    return state.balanceEnquiryPayload;
  },
  getBalanceEnquiryResponse: state => {
    return state.balanceEnquiryResponse;
  }
};
export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_LOADING_SIX(state, status) {
    state.moduleLoadingSix = status;
  },
  SET_LOADING_SEVEN(state, status) {
    state.moduleLoadingSeven = status;
  },
  SET_LOADING_EIGHT(state, status) {
    state.moduleLoadingEight = status;
  },
  SET_LOADING_NINE(state, status) {
    state.moduleLoadingNine = status;
  },
  SET_LOADING_TEN(state, status) {
    state.moduleLoadingTen = status;
  },
  SET_LOADING_ELEVEN(state, status) {
    state.moduleLoadingEleven = status;
  },
  SET_LOADING_TWELVE(state, status) {
    state.moduleLoadingTwelve = status;
  },
  SET_BALANCE_ENQUIRY_PAYLOAD(state, payload) {
    state.balanceEnquiryPayload = payload;
  },
  SET_BALANCE_ENQUIRY_RESPONSE(state, payload) {
    state.balanceEnquiryResponse = payload;
  },
  SET_FUNDS_TRANSFER_STAGE(state, stage) {
    state.fundsTransferStage = stage;
  },
  SET_CASH_WITHDRAWAL_STAGE(state, stage) {
    state.cashWithdrawalStage = stage;
  },
  SET_CASH_DEPOSIT_STAGE(state, stage) {
    state.cashDepositStage = stage;
  },
  SET_FUNDS_TRANSFER_CHECKERS_QUEUE(state, stage) {
    state.allFundsTransferCheckersQueue = stage;
  },
  SET_CASH_DEPOSIT_CHECKERS_QUEUE(state, stage) {
    state.allCashDepositCheckersQueue = stage;
  },
  SET_CASH_WITHDRAWAL_CHECKERS_QUEUE(state, stage) {
    state.allCashWithdrawalCheckersQueue = stage;
  },
  SET_CASH_TRANSACTION_CHECKERS_QUEUE(state, stage) {
    state.allCashTransactionsCheckersQueue = stage;
  }
};
export const actions = {
  checkerAuthorizeCashTransactions({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_ELEVEN", true);

    return FundsTransferService.checkerAuthorizeCashTransactions(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_ELEVEN", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success -> ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_ELEVEN", false);

        throw error;
      });
  },

  allCashTransactionsCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_TWELVE", true);

    return FundsTransferService.allCashTransactionCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_TWELVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_CASH_TRANSACTION_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_TWELVE", false);

        throw error;
      });
  },

  checkerAuthorizeCashDeposit({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_TEN", true);

    return FundsTransferService.checkerAuthorizeCashDeposit(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_TEN", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success -> ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_TEN", false);

        throw error;
      });
  },

  allCashDepositCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_NINE", true);

    return FundsTransferService.allCashDepositCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_NINE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_CASH_DEPOSIT_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_NINE", false);

        throw error;
      });
  },

  checkerAuthorizeCashWithdrawal({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_EIGHT", true);

    return FundsTransferService.checkerAuthorizeCashWithdrawal(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_EIGHT", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success -> ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_EIGHT", false);

        throw error;
      });
  },

  allCashWithdrawalCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_SEVEN", true);

    return FundsTransferService.allCashWithdrawalCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_SEVEN", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_CASH_WITHDRAWAL_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_SEVEN", false);

        throw error;
      });
  },

  checkerAuthorizeFundsTransfer({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_SIX", true);

    return FundsTransferService.checkerAuthorizeFundsTransfer(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_SIX", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success -> ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  allFundsTransferCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FIVE", true);

    return FundsTransferService.allFundsTransferCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FIVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_FUNDS_TRANSFER_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FIVE", false);

        throw error;
      });
  },

  checkStageStatus({ dispatch }) {
    dispatch("resetFundsTransferStage");
  },

  goBackToStageZero({ commit }) {
    commit("SET_BALANCE_ENQUIRY_RESPONSE", {});
    commit("SET_BALANCE_ENQUIRY_PAYLOAD", {});
    commit("SET_FUNDS_TRANSFER_STAGE", "0");
    commit("SET_CASH_WITHDRAWAL_STAGE", "0");
    commit("SET_CASH_DEPOSIT_STAGE", "0");
  },

  setFundsTransferStage({ commit }, stage) {
    commit("SET_FUNDS_TRANSFER_STAGE", stage);
  },
  setCashWithdrawalStage({ commit }, stage) {
    commit("SET_CASH_WITHDRAWAL_STAGE", stage);
  },
  setCashDepositStage({ commit }, stage) {
    commit("SET_CASH_DEPOSIT_STAGE", stage);
  },
  balanceEnquiry({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING", true);

    payload.accountBankCode = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BANK_CODE
    );
    payload.serviceBank = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BANK_CODE
    );
    payload.username = "kleyton";


    commit("SET_BALANCE_ENQUIRY_PAYLOAD", payload);

    return FundsTransferService.nameEnquiry(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        commit("SET_BALANCE_ENQUIRY_RESPONSE", responseData);

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Name Enquiry Success`
          };
          dispatch("notification/add", notification, { root: true });

          if (payload.transactionType === "funds-transfer") {
            // change transfer stage to 1
            dispatch("setFundsTransferStage", "1");
          } else if (payload.transactionType === "cash-withdrawal") {
            dispatch("setCashWithdrawalStage", "1");
          } else if (payload.transactionType === "cash-deposit") {
            dispatch("setCashDepositStage", "1");
          }
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  transferFunds({ commit, dispatch, getters, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING_TWO", true);

    dispatch("appUtilities/generateNewTransactionRef", null, { root: true });

    const balanceEnquiryResponse = getters.getBalanceEnquiryResponse;
    const balanceEnquiryPayload = getters.getBalanceEnquiryPayload;

    payload.trnMaker = rootGetters["user/getUserId"];
    payload.trnChecker = "SYSTEM";
    payload.reference = rootGetters["appUtilities/getCurrentTransactionRef"];
    payload.debitAccountBankCode = rootGetters["user/getUserBankCode"];
    payload.debitAccountBranchCode = rootGetters["user/getUserBranchCode"];

    payload.trnAmount = balanceEnquiryPayload.trnAmount;

    payload.creditAccountNumber = balanceEnquiryResponse.accountNumber;
    payload.creditAccountName = balanceEnquiryResponse.accountname.toUpperCase();
    payload.creditAccountBank = balanceEnquiryResponse.accountBankName;
    payload.creditAccountSwitchCode = balanceEnquiryResponse.accountBankCode;
    payload.creditAccountPhone = "1234567890";

    return FundsTransferService.transferFunds(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_TWO", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }

        dispatch("resetFundsTransferStage");
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_TWO", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  cashWithdrawal({ commit, dispatch, getters, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING_THREE", true);

    dispatch("appUtilities/generateNewTransactionRef", null, { root: true });

    const balanceEnquiryResponse = getters.getBalanceEnquiryResponse;
    const balanceEnquiryPayload = getters.getBalanceEnquiryPayload;

    payload.trnMaker = rootGetters["user/getUserId"];
    payload.trnChecker = "SYSTEM";
    payload.reference = rootGetters["appUtilities/getCurrentTransactionRef"];
    payload.creditAccountNumber = rootGetters["user/getUserTillAccountNumber"];
    payload.creditAccountBankCode = rootGetters["user/getUserBankCode"];
    payload.creditAccountBranchCode = rootGetters["user/getUserBranchCode"];

    payload.trnAmount = balanceEnquiryPayload.trnAmount;

    payload.debitAccountNumber = balanceEnquiryResponse.accountNumber;
    payload.debitAccountName = balanceEnquiryResponse.accountname.toUpperCase();
    payload.debitAccountBank = balanceEnquiryResponse.accountBankName;
    payload.debitAccountBankCode = balanceEnquiryResponse.accountBankCode;
    payload.debitAccountSwitchCode = balanceEnquiryResponse.accountBankCode;
    payload.debitAccountPhone = "1234567890";

    return FundsTransferService.cashWithdrawal(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }

        dispatch("resetFundsTransferStage");
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  cashDeposit({ commit, dispatch, getters, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING_FOUR", true);

    dispatch("appUtilities/generateNewTransactionRef", null, { root: true });

    const balanceEnquiryResponse = getters.getBalanceEnquiryResponse;
    const balanceEnquiryPayload = getters.getBalanceEnquiryPayload;

    payload.trnMaker = rootGetters["user/getUserId"];
    payload.trnChecker = "SYSTEM";
    payload.reference = rootGetters["appUtilities/getCurrentTransactionRef"];
    payload.debitAccountNumber = rootGetters["user/getUserTillAccountNumber"];
    payload.debitAccountBankCode = rootGetters["user/getUserBankCode"];
    payload.debitAccountBranchCode = rootGetters["user/getUserBranchCode"];

    payload.trnAmount = balanceEnquiryPayload.trnAmount;

    payload.creditAccountNumber = balanceEnquiryResponse.accountNumber;
    payload.creditAccountName = balanceEnquiryResponse.accountname.toUpperCase();
    payload.creditAccountBank = balanceEnquiryResponse.accountBankName;
    payload.creditAccountBankCode = balanceEnquiryResponse.accountBankCode;
    payload.creditAccountSwitchCode = balanceEnquiryResponse.accountBankCode;
    payload.creditAccountPhone = "1234567890";

    return FundsTransferService.cashDeposit(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FOUR", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }

        dispatch("resetFundsTransferStage");
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FOUR", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  resetFundsTransferStage({ commit }) {
    commit("SET_BALANCE_ENQUIRY_RESPONSE", {});
    commit("SET_FUNDS_TRANSFER_STAGE", "0");
    commit("SET_CASH_WITHDRAWAL_STAGE", "0");
    commit("SET_CASH_DEPOSIT_STAGE", "0");
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
