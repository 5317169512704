<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div v-if="appUtilities.moduleLoadingEight">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="createBranch">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createBranchFormBody.branchName"
                placeholder="Branch Name"
                :hasPreIcon="false"
                :label="'Branch Name'"
                :class="{
                  error: $v.createBranchFormBody.branchName.$error
                }"
                @blur="$v.createBranchFormBody.branchName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createBranchFormBody.branchName.$error">
                <p
                  v-if="!$v.createBranchFormBody.branchName.required"
                  class="form-error-message"
                >
                  Branch Name is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createBranchFormBody.branchAddress"
                placeholder="Branch Address"
                :hasPreIcon="false"
                :label="'Branch Address'"
                :class="{
                  error: $v.createBranchFormBody.branchAddress.$error
                }"
                @blur="$v.createBranchFormBody.branchAddress.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createBranchFormBody.branchAddress.$error">
                <p
                  v-if="!$v.createBranchFormBody.branchAddress.required"
                  class="form-error-message"
                >
                  Branch Address is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createBranchFormBody.branchCity"
                placeholder="Branch City"
                :hasPreIcon="false"
                :label="'Branch City'"
                :class="{
                  error: $v.createBranchFormBody.branchCity.$error
                }"
                @blur="$v.createBranchFormBody.branchCity.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createBranchFormBody.branchCity.$error">
                <p
                  v-if="!$v.createBranchFormBody.branchCity.required"
                  class="form-error-message"
                >
                  Branch City is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createBranchFormBody.branchState"
                placeholder="Branch State"
                :hasPreIcon="false"
                :label="'Branch State'"
                :class="{
                  error: $v.createBranchFormBody.branchState.$error
                }"
                @blur="$v.createBranchFormBody.branchState.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createBranchFormBody.branchState.$error">
                <p
                  v-if="!$v.createBranchFormBody.branchState.required"
                  class="form-error-message"
                >
                  Branch State is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Branch Type</label>
                <multiselect
                  v-model="createBranchFormBody.branchType"
                  :options="branchTypeOption"
                  placeholder="Branch Type"
                  @close="$v.createBranchFormBody.branchType.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.createBranchFormBody.branchType.$error">
                <p
                  v-if="!$v.createBranchFormBody.branchType.required"
                  class="form-error-message"
                >
                  Branch Type is required
                </p>
              </template>
            </div>

            <div>
              <div class="form-group">
                <label>Branch Manager</label>
                <multiselect
                  v-model="createBranchFormBody.branchManager"
                  :options="branchMangerUsersOption"
                  placeholder="Branch Manger"
                ></multiselect>
              </div>
              <!--              @close="$v.createBranchFormBody.branchManager.$touch()"-->

              <!--              <template v-if="$v.createBranchFormBody.branchManager.$error">-->
              <!--                <p-->
              <!--                  v-if="!$v.createBranchFormBody.branchManager.required"-->
              <!--                  class="form-error-message"-->
              <!--                >-->
              <!--                  Branch Manager is required-->
              <!--                </p>-->
              <!--              </template>-->
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createBranchFormBody.branchRegion"
                placeholder="Branch Region"
                :hasPreIcon="false"
                :label="'Branch Region'"
                :class="{
                  error: $v.createBranchFormBody.branchRegion.$error
                }"
                @blur="$v.createBranchFormBody.branchRegion.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createBranchFormBody.branchRegion.$error">
                <p
                  v-if="!$v.createBranchFormBody.branchRegion.required"
                  class="form-error-message"
                >
                  Branch Region is required
                </p>
              </template>
            </div>

            <div>
              <label>Country</label>
              <multiselect
                      v-model="createBranchFormBody.branchCountry"
                      :options="countryOptions"
                      @close="$v.createBranchFormBody.branchCountry.$touch()"
                      placeholder="Country"
              ></multiselect>

              <template v-if="$v.createBranchFormBody.branchCountry.$error">
                <p
                  v-if="!$v.createBranchFormBody.branchCountry.required"
                  class="form-error-message"
                >
                  Country is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <p class="text-right">
          <span v-if="branch.moduleLoadingTwo" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create Branch
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "CreateBank",
  components: {
    Multiselect
  },
  data() {
    return {
      createBranchFormBody: this.createFreshCreateBranchFormBodyObject(),
      branchTypeOption: ["Unit"]
    };
  },
  computed: {
    ...mapState(["branch", "appUtilities"]),
    ...mapGetters({
      branchMangerUsersOption: "appUtilities/getAllUsersOption",
      getBranchMangerUserIdFromOptionUtility:
        "appUtilities/getUserIdFromOptionUtility",
      countryOptions: "appUtilities/getCountryUtilityOption"
    })
  },
  validations: {
    // branchManager: { required },
    createBranchFormBody: {
      branchName: { required },
      branchAddress: { required },
      branchCity: { required },
      branchState: { required },
      branchType: { required },
      branchRegion: { required },
      branchCountry: { required }
    }
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllUsers", null, { root: true });
  },
  methods: {
    createBranch() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createBranchFormBody.branchBankCode = this.$store.getters[
          "user/getUserBankCode"
        ];
        this.createBranchFormBody.branchMaker = this.$store.getters[
          "user/getUserId"
        ];
        // this.createBranchFormBody.branchManager = this.getBranchMangerUserIdFromOptionUtility(
        //   this.createBranchFormBody.branchManager
        // );

        this.$store
          .dispatch("branch/createBranch", this.createBranchFormBody, {
            root: true
          })
          .then(() => {
            this.createBranchFormBody = {
              source: "CBA",
              requestType: "create",
              branchLocation: "0000,0000",
              branchCategory: "A",
              branchCreatedBy: StoreUtils.rootGetter(
                StoreUtils.getters.user.GET_USER_ID
              )
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshCreateBranchFormBodyObject() {
      return {
        source: "CBA",
        requestType: "create",
        branchLocation: "0000,0000",
        branchCategory: "A",
        branchCreatedBy: StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_ID
        )
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
