<template>
  <div>
    <div v-if="appUtilities.moduleLoadingSix">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="createCustomer">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerFirstName"
                placeholder="First Name"
                :hasPreIcon="false"
                :label="'First Name'"
                :class="{
                  error: $v.formBody.customerFirstName.$error,
                }"
                @blur="$v.formBody.customerFirstName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerFirstName.$error">
                <p
                  v-if="!$v.formBody.customerFirstName.required"
                  class="form-error-message"
                >
                  First Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerMiddleName"
                placeholder="Middle Name"
                :hasPreIcon="false"
                :label="'Middle Name'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerLastName"
                placeholder="Last Name"
                :hasPreIcon="false"
                :label="'Last Name'"
                :class="{
                  error: $v.formBody.customerLastName.$error,
                }"
                @blur="$v.formBody.customerLastName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerLastName.$error">
                <p
                  v-if="!$v.formBody.customerLastName.required"
                  class="form-error-message"
                >
                  Last Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerPhoneNumber"
                placeholder="Phone Number"
                :hasPreIcon="false"
                :label="'Phone Number'"
                :class="{
                  error: $v.formBody.customerPhoneNumber.$error,
                }"
                @blur="$v.formBody.customerPhoneNumber.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerPhoneNumber.$error">
                <p
                  v-if="!$v.formBody.customerPhoneNumber.required"
                  class="form-error-message"
                >
                  Phone Number is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <label>Country</label>
              <multiselect
                v-model="formBody.customerCountry"
                :options="customerCountryOptions"
                @close="$v.formBody.customerCountry.$touch()"
                placeholder="Country"
              ></multiselect>

              <template v-if="$v.formBody.customerCountry.$error">
                <p
                  v-if="!$v.formBody.customerCountry.required"
                  class="form-error-message"
                >
                  Country Code is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerAddress"
                placeholder="Customer Address"
                :hasPreIcon="false"
                :label="'Customer Address'"
                :class="{
                  error: $v.formBody.customerAddress.$error,
                }"
                @blur="$v.formBody.customerAddress.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerAddress.$error">
                <p
                  v-if="!$v.formBody.customerAddress.required"
                  class="form-error-message"
                >
                  Customer Address is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'number'"
                v-model="formBody.customerBvn"
                placeholder="Customer Bvn"
                :hasPreIcon="false"
                :label="'Customer Bvn'"
                :class="{ error: $v.formBody.customerBvn.$error }"
                @blur="$v.formBody.customerBvn.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerBvn.$error">
                <p
                  v-if="!$v.formBody.customerBvn.required"
                  class="form-error-message"
                >
                  Bvn is required
                </p>
                <p
                  v-if="!$v.formBody.customerBvn.numeric"
                  class="form-error-message"
                >
                  Bvn can only be Numbers
                </p>
                <p
                  v-if="!$v.formBody.customerBvn.minLength"
                  class="form-error-message"
                >
                  Bvn must be 11 numbers
                </p>
                <p
                  v-if="!$v.formBody.customerBvn.maxLength"
                  class="form-error-message"
                >
                  Bvn must be 11 numbers
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'number'"
                v-model="formBody.customerNin"
                placeholder="Customer NIN"
                :hasPreIcon="false"
                :label="'Customer NIN'"
                :class="{ error: $v.formBody.customerNin.$error }"
                @blur="$v.formBody.customerNin.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerNin.$error">
                <p
                  v-if="!$v.formBody.customerNin.required"
                  class="form-error-message"
                >
                  NIN is required
                </p>
                <p
                  v-if="!$v.formBody.customerNin.numeric"
                  class="form-error-message"
                >
                  NIN can only be Numbers
                </p>
                <p
                  v-if="!$v.formBody.customerNin.minLength"
                  class="form-error-message"
                >
                  NIN must be 11 numbers
                </p>
                <p
                  v-if="!$v.formBody.customerNin.maxLength"
                  class="form-error-message"
                >
                  NIN must be 11 numbers
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerEmail"
                placeholder="Email"
                :hasPreIcon="false"
                :label="'Email'"
                :class="{
                  error: $v.formBody.customerEmail.$error,
                }"
                @blur="$v.formBody.customerEmail.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerEmail.$error">
                <p
                  v-if="!$v.formBody.customerEmail.required"
                  class="form-error-message"
                >
                  Email is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Date of Birth</label>
                <date-picker
                  v-model="formBody.customerDob"
                  placeholder="Date of Birth"
                  :first-day-of-week="1"
                  lang="en"
                  confirm
                  @blur="$v.formBody.customerDob.$touch()"
                ></date-picker>
              </div>

              <template v-if="$v.formBody.customerDob.$error">
                <p
                  v-if="!$v.formBody.customerDob.required"
                  class="form-error-message"
                >
                  Date of Birth is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerState"
                placeholder="Customer State"
                :hasPreIcon="false"
                :label="'Customer State'"
                :class="{
                  error: $v.formBody.customerState.$error,
                }"
                @blur="$v.formBody.customerState.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerState.$error">
                <p
                  v-if="!$v.formBody.customerState.required"
                  class="form-error-message"
                >
                  Customer State is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <label>Gender</label>
              <multiselect
                v-model="formBody.customerGender"
                :options="genderOptions"
                placeholder="Gender"
              ></multiselect>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Account Officer</label>
                <multiselect
                  v-model="formBody.customerAccountOfficer"
                  :options="accountOfficerOption"
                  placeholder="Account Officer"
                ></multiselect>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Allow SMS</label>
                <multiselect
                  v-model="formBody.customerAllowSms"
                  :options="['YES', 'NO']"
                  placeholder="Account Officer"
                ></multiselect>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Allow Email</label>
                <multiselect
                  v-model="formBody.customerAllowEmail"
                  :options="['YES', 'NO']"
                  placeholder="Account Officer"
                ></multiselect>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Allow Push Notification</label>
                <multiselect
                  v-model="formBody.customerAllowPushNotification"
                  :options="['YES', 'NO']"
                  placeholder="Account Officer"
                ></multiselect>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="id">Customer Mandate</label>
            <input
              type="file"
              id="files2"
              name="files"
              @change="uploadFile2"
              accept="image/*"
              ref="file2"
              placeholder="Customer Mandate"
            />
          </div>

          <div class="form-group">
            <label class="id">Customer Picture</label>
            <input
              type="file"
              id="files"
              name="files"
              @change="uploadFile"
              accept="image/*"
              ref="file"
              placeholder="Customer Picture"
            />
          </div>
        </div>
        <p class="text-right pt-2">
          <span v-if="loader.actionButtonLoader" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">Submit</button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import JsonParserUtils from "../../../../utils/JsonParserUtils";
import S3Request from "@/model/requests/S3Request";

export default {
  name: "EditCustomerForm",
  data() {
    return {
      formBody: this.createFreshFormBodyObject(),
      genderOptions: ["MALE", "FEMALE"],
      selectedCustomerPicture: "Choose Image",
      selectedCustomerMandate: "Choose Image",
      base64: "",
      uploadmodel: S3Request.prototype.uploadBase64(),
      url: "",

      base642: "",
      uploadmodel2: S3Request.prototype.uploadBase64(),
      url2: "",
    };
  },
  props: {
    currentCustomer: {
      type: Object,
      required: true,
    },
  },
  components: {
    Multiselect,
    DatePicker,
  },
  computed: {
    ...mapState([
      "customer",
      "appUtilities",
      "loader",
      "appS3Upload",
      "sbucket",
    ]),
    ...mapGetters({
      accountCurrencyOptions: "appUtilities/getCurrencyUtilityOption",
      customerCountryOptions: "appUtilities/getCountryUtilityOption",
      accountOfficerOption: "appUtilities/getAllUsersOption",
      getAccountOfficerUserIdFromOptionUtility:
        "appUtilities/getUserIdFromOptionUtility",
    }),
  },
  validations: {
    formBody: {
      customerPhoneNumber: { required },
      customerBvn: {
        //required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(11),
      },
      customerNin: {
        //required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(11),
      },
      customerEmail: {},
      customerFirstName: { required },
      customerLastName: { required },
      customerDob: {},
      customerCountry: {},
      customerState: {},
      customerAddress: {},
    },
  },

  async created() {
    this.formBody = await JsonParserUtils.purifyObject(
      this.currentCustomer,
      ""
    );
    StoreUtils.dispatch("appUtilities/fetchAllUsers");
  },

  methods: {
    uploadFile() {
      let input = this.$refs.file;
      let files = input.files;
      //console.log(size);
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          this.base64 = e.target.result;
          this.uploadOfficerImage();
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      } catch (e) {
        //console.warn(e.message);
      }
    },
    async uploadOfficerImage() {
      // this.showLoader = true;
      this.uploadmodel.username = `${
        this.formBody.customerFirstName + this.formBody.customerMiddleName
      }_${Date.now()}`;
      this.uploadmodel.base64 = this.base64;
      // await this.$store.dispatch("sbucket/uploadEncodedFile", this.uploadmodel, { root: true });
      await StoreUtils.dispatch(
        StoreUtils.actions.sbucket.uploadEncodedFile,
        this.uploadmodel,
        { root: true }
      );
      this.url = this.sbucket.s3bucketResponse.url;
      // this.showLoader = false;
    },

    uploadFile2() {
      let input = this.$refs.file2;
      let files = input.files;
      //console.log(size);
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          this.base642 = e.target.result;
          this.uploadOfficerImage2();
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      } catch (e) {
        //console.warn(e.message);
      }
    },
    async uploadOfficerImage2() {
      // this.showLoader = true;
      this.uploadmodel2.username = `${
        this.formBody.customerFirstName + this.formBody.customerMiddleName
      }_${Date.now()}`;
      this.uploadmodel2.base64 = this.base642;
      // await this.$store.dispatch("sbucket/uploadEncodedFile", this.uploadmodel, { root: true });
      await StoreUtils.dispatch(
        StoreUtils.actions.sbucket.uploadEncodedFile,
        this.uploadmodel2,
        { root: true }
      );
      this.url2 = this.sbucket.s3bucketResponse.url;
      // this.showLoader = false;
    },

    updateSelectedCustomerPicture() {
      let files = document.getElementById("customerPictureUpload").files;
      this.selectedCustomerPicture = files[0].name;
    },
    updateSelectedCustomerMandate() {
      let files = document.getElementById("customerMandateUpload").files;
      this.selectedCustomerMandate = files[0].name;
    },

    async customerPictureUpload() {
      let files = document.getElementById("customerPictureUpload").files;
      if (!files.length) {
        return this.$v.formBody.customerPicture.$touch();
      } else {
        let file = files[0];
        let fileExtension = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );
        // let fileName = file.name;
        if (file.type.includes("image")) {
          let payload = {
            appAlbumName: "StagingArea",
            fileName: this.formBody.customerPhoneNumber,
            fileExtension: fileExtension,
            file: file,
          };

          return await this.$store.dispatch(
            "appS3Upload/uploadCustomerPictureToStagingArea",
            payload,
            {
              root: true,
            }
          );
        } else {
          return this.$v.formBody.customerPicture.$touch();
        }
      }
    },
    async customerMandateUpload() {
      let files = document.getElementById("customerMandateUpload").files;
      if (!files.length) {
        return this.$v.formBody.customerMandate.$touch();
      } else {
        let file = files[0];
        let fileExtension = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );
        // let fileName = file.name;
        if (file.type.includes("image")) {
          let payload = {
            appAlbumName: "StagingArea",
            fileName: this.formBody.customerPhoneNumber,
            fileExtension: fileExtension,
            file: file,
          };

          return await this.$store.dispatch(
            "appS3Upload/uploadCustomerMandateToStagingArea",
            payload,
            {
              root: true,
            }
          );
        } else {
          return this.$v.formBody.customerMandate.$touch();
        }
      }
    },

    async createCustomer() {
      this.formBody.customerCountryCode = "234";

      this.formBody.customerBranchCode =
        this.$store.getters["user/getUserBranchCode"];
      this.formBody.customerBankCode =
        this.$store.getters["user/getUserBankCode"];

      // start module loading for file upload
      this.$store.dispatch("customer/setModuleLoading", true, { root: true });

      // Set Current Customer Phone Number to be used later for file name
      this.$store.dispatch(
        "appS3Upload/setCurrentCustomerPhoneNumber",
        this.formBody.customerPhoneNumber,
        { root: true }
      );
      // assign the destination File Path to the CustomerPicture
      this.formBody.customerPicture = this.url;
      // assign the destination File Path to the CustomerMandate
      this.formBody.customerMandate = this.url2;
      // notificationModalBody
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.formBody.customerDob = moment(this.formBody.customerDob).format(
          "DD-MMM-YYYY"
        );

        if (this.formBody.customerAccountOfficer > 10) {
          this.formBody.customerAccountOfficer =
            this.getAccountOfficerUserIdFromOptionUtility(
              this.formBody.customerAccountOfficer
            );
        }

        await this.$store.dispatch("customer/editCustomer", this.formBody, {
          root: true,
        });

       await this.$emit("editSuccess");
      }
    },
    createFreshFormBodyObject() {
      return {
        // source: "web"
      };
    },
  },
  beforeDestroy() {
    this.formBody = {};
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 15px;
  margin-right: 5px;
  margin-left: 10px;
  width: 100%;
}

.form-group input {
  display: block;
  line-height: 20px;
  font-size: 12px;
  padding: 10px 16px;
  /*height: 30px;*/
  border-radius: 8px;
  color: #000000;
  border: 1px solid #e4e8ee;
  box-shadow: none;
  width: 100%;
}

.form-group input:focus {
  outline: none;
  border: 1px solid #e4e8ee;
}

.form-group input::placeholder {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
}

.form-group select {
  display: block;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.1px;
  padding: 12px 16px;
  height: 48px;
  border-radius: 5px;
  color: #000000;
  border: 1px solid #e4e8ee;
  box-shadow: none;
  width: 100%;
}

.form-group select:focus {
  outline: none;
  border: 1px solid #24405a;
}

.form-group select::placeholder {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
}
</style>

<!--<template>-->
<!--  <div>-->
<!--    <div v-if="appUtilities.moduleLoadingSix">-->
<!--      <BaseComponentLoader />-->
<!--    </div>-->
<!--    <div v-else>-->
<!--      <form @submit.prevent="createCustomer">-->
<!--        <div class="row">-->
<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <BaseInput-->
<!--                :inputType="'text'"-->
<!--                v-model="formBody.customerFirstName"-->
<!--                placeholder="First Name"-->
<!--                :hasPreIcon="false"-->
<!--                :label="'First Name'"-->
<!--                :class="{-->
<!--                  error: $v.formBody.customerFirstName.$error,-->
<!--                }"-->
<!--                @blur="$v.formBody.customerFirstName.$touch()"-->
<!--              >-->
<!--              </BaseInput>-->

<!--              <template v-if="$v.formBody.customerFirstName.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerFirstName.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  First Name is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <BaseInput-->
<!--                :inputType="'text'"-->
<!--                v-model="formBody.customerMiddleName"-->
<!--                placeholder="Middle Name"-->
<!--                :hasPreIcon="false"-->
<!--                :label="'Middle Name'"-->
<!--              >-->
<!--              </BaseInput>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <BaseInput-->
<!--                :inputType="'text'"-->
<!--                v-model="formBody.customerLastName"-->
<!--                placeholder="Last Name"-->
<!--                :hasPreIcon="false"-->
<!--                :label="'Last Name'"-->
<!--                :class="{-->
<!--                  error: $v.formBody.customerLastName.$error,-->
<!--                }"-->
<!--                @blur="$v.formBody.customerLastName.$touch()"-->
<!--              >-->
<!--              </BaseInput>-->

<!--              <template v-if="$v.formBody.customerLastName.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerLastName.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Last Name is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <BaseInput-->
<!--                :inputType="'text'"-->
<!--                v-model="formBody.customerPhoneNumber"-->
<!--                placeholder="Phone Number"-->
<!--                :hasPreIcon="false"-->
<!--                :label="'Phone Number'"-->
<!--                :class="{-->
<!--                  error: $v.formBody.customerPhoneNumber.$error,-->
<!--                }"-->
<!--                @blur="$v.formBody.customerPhoneNumber.$touch()"-->
<!--              >-->
<!--              </BaseInput>-->

<!--              <template v-if="$v.formBody.customerPhoneNumber.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerPhoneNumber.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Phone Number is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <label>Country</label>-->
<!--              <multiselect-->
<!--                v-model="formBody.customerCountry"-->
<!--                :options="customerCountryOptions"-->
<!--                @close="$v.formBody.customerCountry.$touch()"-->
<!--                placeholder="Country"-->
<!--              ></multiselect>-->

<!--              <template v-if="$v.formBody.customerCountry.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerCountry.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Country Code is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <BaseInput-->
<!--                :inputType="'text'"-->
<!--                v-model="formBody.customerAddress"-->
<!--                placeholder="Customer Address"-->
<!--                :hasPreIcon="false"-->
<!--                :label="'Customer Address'"-->
<!--                :class="{-->
<!--                  error: $v.formBody.customerAddress.$error,-->
<!--                }"-->
<!--                @blur="$v.formBody.customerAddress.$touch()"-->
<!--              >-->
<!--              </BaseInput>-->

<!--              <template v-if="$v.formBody.customerAddress.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerAddress.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Customer Address is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <BaseInput-->
<!--                :inputType="'number'"-->
<!--                v-model="formBody.customerBvn"-->
<!--                placeholder="Customer Bvn"-->
<!--                :hasPreIcon="false"-->
<!--                :label="'Customer Bvn'"-->
<!--                :class="{ error: $v.formBody.customerBvn.$error }"-->
<!--                @blur="$v.formBody.customerBvn.$touch()"-->
<!--              >-->
<!--              </BaseInput>-->

<!--              <template v-if="$v.formBody.customerBvn.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerBvn.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Bvn is required-->
<!--                </p>-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerBvn.numeric"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Bvn can only be Numbers-->
<!--                </p>-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerBvn.minLength"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Bvn must be 11 numbers-->
<!--                </p>-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerBvn.maxLength"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Bvn must be 11 numbers-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <BaseInput-->
<!--                :inputType="'text'"-->
<!--                v-model="formBody.customerEmail"-->
<!--                placeholder="Email"-->
<!--                :hasPreIcon="false"-->
<!--                :label="'Email'"-->
<!--                :class="{-->
<!--                  error: $v.formBody.customerEmail.$error,-->
<!--                }"-->
<!--                @blur="$v.formBody.customerEmail.$touch()"-->
<!--              >-->
<!--              </BaseInput>-->

<!--              <template v-if="$v.formBody.customerEmail.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerEmail.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Email is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <div class="form-group">-->
<!--                <label>Date of Birth</label>-->
<!--                <date-picker-->
<!--                  v-model="formBody.customerDob"-->
<!--                  placeholder="Date of Birth"-->
<!--                  :first-day-of-week="1"-->
<!--                  lang="en"-->
<!--                  confirm-->
<!--                  @blur="$v.formBody.customerDob.$touch()"-->
<!--                ></date-picker>-->
<!--              </div>-->

<!--              <template v-if="$v.formBody.customerDob.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerDob.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Date of Birth is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <BaseInput-->
<!--                :inputType="'text'"-->
<!--                v-model="formBody.customerState"-->
<!--                placeholder="Customer State"-->
<!--                :hasPreIcon="false"-->
<!--                :label="'Customer State'"-->
<!--                :class="{-->
<!--                  error: $v.formBody.customerState.$error,-->
<!--                }"-->
<!--                @blur="$v.formBody.customerState.$touch()"-->
<!--              >-->
<!--              </BaseInput>-->

<!--              <template v-if="$v.formBody.customerState.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.customerState.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Customer State is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <label>Gender</label>-->
<!--              <multiselect-->
<!--                v-model="formBody.customerGender"-->
<!--                :options="genderOptions"-->
<!--                placeholder="Gender"-->
<!--              ></multiselect>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <div class="form-group">-->
<!--                <label>Account Officer</label>-->
<!--                <multiselect-->
<!--                  v-model="formBody.customerAccountOfficer"-->
<!--                  :options="accountOfficerOption"-->
<!--                  placeholder="Account Officer"-->
<!--                ></multiselect>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <div class="form-group">-->
<!--                <label>Allow SMS</label>-->
<!--                <multiselect-->
<!--                  v-model="formBody.customerAllowSms"-->
<!--                  :options="['YES', 'NO']"-->
<!--                  placeholder="Account Officer"-->
<!--                ></multiselect>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <div class="form-group">-->
<!--                <label>Allow Email</label>-->
<!--                <multiselect-->
<!--                  v-model="formBody.customerAllowEmail"-->
<!--                  :options="['YES', 'NO']"-->
<!--                  placeholder="Account Officer"-->
<!--                ></multiselect>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <div class="form-group">-->
<!--                <label>Allow Push Notification</label>-->
<!--                <multiselect-->
<!--                  v-model="formBody.customerAllowPushNotification"-->
<!--                  :options="['YES', 'NO']"-->
<!--                  placeholder="Account Officer"-->
<!--                ></multiselect>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div style="margin-bottom: 10px" class="col-sm-12">-->
<!--            <FormulateInput-->
<!--              name="customerPicture"-->
<!--              type="image"-->
<!--              id="customerPictureUpload"-->
<!--              multiple="false"-->
<!--              label="Customer Picture"-->
<!--              validation="required|mime:image/jpeg,image/png,image/gif"-->
<!--              validation-name="Customer Picture"-->
<!--              placeholder="Choose Picture"-->
<!--              help="Select a png, jpg or gif to upload."-->
<!--              v-model="formBody.customerPicture"-->
<!--              @change="updateSelectedCustomerPicture"-->
<!--            />-->
<!--          </div>-->

<!--          <div style="margin-bottom: 10px" class="col-sm-12">-->
<!--            <FormulateInput-->
<!--              name="customerMandate"-->
<!--              type="image"-->
<!--              id="customerMandateUpload"-->
<!--              targetName="cff"-->
<!--              multiple="false"-->
<!--              label="Customer Mandate"-->
<!--              validation="required|mime:image/jpeg,image/png,image/gif"-->
<!--              validation-name="Mandate"-->
<!--              placeholder="Choose Mandate"-->
<!--              help="Select a png, jpg or gif to upload."-->
<!--              v-model="formBody.customerMandate"-->
<!--              @change="updateSelectedCustomerMandate"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--        <p class="text-right pt-2">-->
<!--          <span v-if="loader.actionButtonLoader" class="formLoaderHold">-->
<!--            <BaseComponentLoader />-->
<!--          </span>-->
<!--          <span v-else>-->
<!--            <button type="submit" class="btn btn-primary mr-1">Submit</button>-->
<!--          </span>-->
<!--        </p>-->
<!--      </form>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import { mapGetters, mapState } from "vuex";-->
<!--import {-->
<!--  required,-->
<!--  numeric,-->
<!--  minLength,-->
<!--  maxLength,-->
<!--} from "vuelidate/lib/validators";-->
<!--import Multiselect from "vue-multiselect";-->
<!--import moment from "moment";-->
<!--import DatePicker from "vue2-datepicker";-->
<!--import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";-->
<!--import JsonParserUtils from "../../../../utils/JsonParserUtils";-->

<!--export default {-->
<!--  name: "EditCustomerForm",-->
<!--  data() {-->
<!--    return {-->
<!--      formBody: this.createFreshFormBodyObject(),-->
<!--      genderOptions: ["MALE", "FEMALE"],-->
<!--      selectedCustomerPicture: "Choose Image",-->
<!--      selectedCustomerMandate: "Choose Image",-->
<!--    };-->
<!--  },-->
<!--  props: {-->
<!--    currentCustomer: {-->
<!--      type: Object,-->
<!--      required: true,-->
<!--    },-->
<!--  },-->
<!--  components: {-->
<!--    Multiselect,-->
<!--    DatePicker,-->
<!--  },-->
<!--  computed: {-->
<!--    ...mapState(["customer", "appUtilities", "loader", "appS3Upload"]),-->
<!--    ...mapGetters({-->
<!--      accountCurrencyOptions: "appUtilities/getCurrencyUtilityOption",-->
<!--      customerCountryOptions: "appUtilities/getCountryUtilityOption",-->
<!--      accountOfficerOption: "appUtilities/getAllUsersOption",-->
<!--      getAccountOfficerUserIdFromOptionUtility:-->
<!--        "appUtilities/getUserIdFromOptionUtility",-->
<!--    }),-->
<!--  },-->
<!--  validations: {-->
<!--    formBody: {-->
<!--      customerPhoneNumber: { required },-->
<!--      customerBvn: {-->
<!--        //required,-->
<!--        numeric,-->
<!--        minLength: minLength(10),-->
<!--        maxLength: maxLength(11),-->
<!--      },-->
<!--      customerEmail: {},-->
<!--      customerFirstName: { required },-->
<!--      customerLastName: { required },-->
<!--      customerDob: {},-->
<!--      customerCountry: {},-->
<!--      customerState: {},-->
<!--      customerAddress: {},-->
<!--      customerPicture: {},-->
<!--      customerMandate: {},-->
<!--    },-->
<!--  },-->

<!--  async created() {-->
<!--    this.formBody = await JsonParserUtils.purifyObject(-->
<!--      this.currentCustomer,-->
<!--      ""-->
<!--    );-->
<!--    StoreUtils.dispatch("appUtilities/fetchAllUsers");-->
<!--  },-->

<!--  methods: {-->
<!--    updateSelectedCustomerPicture() {-->
<!--      let files = document.getElementById("customerPictureUpload").files;-->
<!--      this.selectedCustomerPicture = files[0].name;-->
<!--    },-->
<!--    updateSelectedCustomerMandate() {-->
<!--      let files = document.getElementById("customerMandateUpload").files;-->
<!--      this.selectedCustomerMandate = files[0].name;-->
<!--    },-->
<!--    async customerPictureUpload() {-->
<!--      let files = document.getElementById("customerPictureUpload").files;-->
<!--      if (!files.length) {-->
<!--        return this.$v.createCustomerFormBody.customerPicture.$touch();-->
<!--      } else {-->
<!--        let file = files[0];-->
<!--        let fileExtension = file.name.slice(-->
<!--          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2-->
<!--        );-->
<!--        // let fileName = file.name;-->
<!--        if (file.type.includes("image")) {-->
<!--          let payload = {-->
<!--            appAlbumName: "StagingArea",-->
<!--            fileName: this.createCustomerFormBody.customerPhoneNumber,-->
<!--            fileExtension: fileExtension,-->
<!--            file: file,-->
<!--          };-->

<!--          return await this.$store.dispatch(-->
<!--            "appS3Upload/uploadCustomerPictureToStagingArea",-->
<!--            payload,-->
<!--            {-->
<!--              root: true,-->
<!--            }-->
<!--          );-->
<!--        } else {-->
<!--          return this.$v.createCustomerFormBody.customerPicture.$touch();-->
<!--        }-->
<!--      }-->
<!--    },-->
<!--    async customerMandateUpload() {-->
<!--      let files = document.getElementById("customerMandateUpload").files;-->
<!--      if (!files.length) {-->
<!--        return this.$v.createCustomerFormBody.customerMandate.$touch();-->
<!--      } else {-->
<!--        let file = files[0];-->
<!--        let fileExtension = file.name.slice(-->
<!--          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2-->
<!--        );-->
<!--        // let fileName = file.name;-->
<!--        if (file.type.includes("image")) {-->
<!--          let payload = {-->
<!--            appAlbumName: "StagingArea",-->
<!--            fileName: this.createCustomerFormBody.customerPhoneNumber,-->
<!--            fileExtension: fileExtension,-->
<!--            file: file,-->
<!--          };-->

<!--          return await this.$store.dispatch(-->
<!--            "appS3Upload/uploadCustomerMandateToStagingArea",-->
<!--            payload,-->
<!--            {-->
<!--              root: true,-->
<!--            }-->
<!--          );-->
<!--        } else {-->
<!--          return this.$v.createCustomerFormBody.customerMandate.$touch();-->
<!--        }-->
<!--      }-->
<!--    },-->
<!--    async createCustomer() {-->
<!--      // notificationModalBody-->
<!--      this.$v.$touch();-->
<!--      if (!this.$v.$invalid) {-->
<!--        this.formBody.customerDob = moment(this.formBody.customerDob).format(-->
<!--          "DD-MMM-YYYY"-->
<!--        );-->

<!--        if (this.formBody.customerAccountOfficer > 10) {-->
<!--          this.formBody.customerAccountOfficer =-->
<!--            this.getAccountOfficerUserIdFromOptionUtility(-->
<!--              this.formBody.customerAccountOfficer-->
<!--            );-->
<!--        }-->

<!--        StoreUtils.commit(-->
<!--          StoreUtils.mutations.customer.UPDATE_EDIT_CUSTOMER_FORM_BODY,-->
<!--          this.formBody-->
<!--        );-->

<!--        this.$store-->
<!--          .dispatch("customer/editCustomer", this.formBody, {-->
<!--            root: true,-->
<!--          })-->
<!--          .then((responseData) => {-->
<!--            if (responseData.responseCode === "00") {-->
<!--              this.$emit("editSuccess");-->
<!--            }-->
<!--            this.$v.$reset();-->
<!--          })-->
<!--          .catch(() => {});-->
<!--      }-->
<!--    },-->

<!--    createFreshFormBodyObject() {-->
<!--      return {-->
<!--        // source: "web"-->
<!--      };-->
<!--    },-->
<!--  },-->
<!--  beforeDestroy() {-->
<!--    this.formBody = {};-->
<!--  },-->
<!--};-->
<!--</script>-->

<!--<style scoped>-->
<!--.signatureUpload {-->
<!--  margin-top: 5px;-->
<!--}-->
<!--</style>-->
