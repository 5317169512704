// create a new Bank
import QueueService from "../../services/newServices/QueueService";
const queueService = new QueueService();
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";

const getDefaultState = () => {
  return {};
};
export const state = getDefaultState();

export const mutations = {};

export const actions = {
  fetchGeneralCheckersQueue() {
    let payload = {
      cqBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_TABLE_DATA,
        responseData.data
      );
    };

    return queueService.fetchGeneralCheckersQueue(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  authorizeGeneralCheckersQueueItem(undefined, { payload }) {
    const successAction = responseData => {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_TABLE_DATA,
        responseData.data
      );
      return responseData;
    };

    return queueService.authorizeGeneralCheckersQueueItem(
      payload,
      successAction,
      LoaderUtils.types.TABLE_ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
