<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->

    <form @submit.prevent="updateStatus">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="branchStatusUpdateFormBody.branchBankCode"
              placeholder="Branch Bank Code"
              :hasPreIcon="false"
              :label="'Branch Bank Code'"
              :class="{
                error: $v.branchStatusUpdateFormBody.branchBankCode.$error
              }"
              @blur="$v.branchStatusUpdateFormBody.branchBankCode.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.branchStatusUpdateFormBody.branchBankCode.$error"
            >
              <p
                v-if="!$v.branchStatusUpdateFormBody.branchBankCode.required"
                class="form-error-message"
              >
                Branch Bank Code is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="branchStatusUpdateFormBody.branchCode"
              placeholder="Branch Code"
              :hasPreIcon="false"
              :label="'Branch Code'"
              :class="{
                error: $v.branchStatusUpdateFormBody.branchCode.$error
              }"
              @blur="$v.branchStatusUpdateFormBody.branchCode.$touch()"
            />

            <template v-if="$v.branchStatusUpdateFormBody.branchCode.$error">
              <p
                v-if="!$v.branchStatusUpdateFormBody.branchCode.required"
                class="form-error-message"
              >
                Branch Code is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseSelect
              :type="'date'"
              :range="false"
              v-model="branchStatusUpdateFormBody.branchStatus"
              :label="'Branch Status'"
              :placeholder="'Branch Status'"
              :options="options"
              :hasPreIcon="false"
              :class="{
                error: $v.branchStatusUpdateFormBody.branchStatus.$error
              }"
              @blur="$v.branchStatusUpdateFormBody.branchStatus.$touch()"
            />
            <!--                          error-->
            <template v-if="$v.branchStatusUpdateFormBody.branchStatus.$error">
              <p
                v-if="!$v.branchStatusUpdateFormBody.branchStatus.required"
                class="form-error-message"
              >
                Branch Status is required
              </p>
            </template>
          </div>

          <div>
            <p class="text-right">
              <span v-if="branch.moduleLoadingTwo" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <br />
                <button type="submit" class="btn btn-primary mr-1">
                  Update Status
                </button>
              </span>
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "UpdateStatusForm",
  data() {
    return {
      branchStatusUpdateFormBody: this.createFreshBranchStatusUpdateFormBodyObject(),
      options: ["Active", "Inactive"]
    };
  },
  computed: {
    ...mapState(["branch"])
  },
  validations: {
    branchStatusUpdateFormBody: {
      branchBankCode: { required },
      branchCode: { required },
      branchStatus: { required }
    }
  },
  methods: {
    updateStatus() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch(
            "branch/updateBranchStatus",
            this.branchStatusUpdateFormBody,
            { root: true }
          )
          .then(() => {
            this.branchStatusUpdateFormBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshBranchStatusUpdateFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
