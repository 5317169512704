var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loader.tableLoader)?_c('div',[_c('BaseComponentLoader')],1):_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-centered mb-0 table-nowrap"},[_vm._m(0),_c('tbody',_vm._l((_vm.accountingEntry.currentAccountingEntryEntries),function(entry,index){return _c('tr',{key:entry.id},[_c('td',[_c('p',{staticClass:"font-size-14 text-truncate"},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('td',[_c('p',{staticClass:"font-size-14 text-truncate"},[_vm._v(" "+_vm._s(entry.accountNumber)+" ")])]),_c('td',[_c('p',{staticClass:"font-size-14 text-truncate"},[_vm._v(" "+_vm._s(entry.serialNumber)+" ")])]),_c('td',[_c('a',{staticClass:"action-icon text-danger",on:{"click":function($event){return _vm.showDeleteConfirmationModal(entry)}}},[_c('i',{staticClass:"mdi mdi-trash-can font-size-18"})])])])}),0)])])]),_c('div',{staticClass:"col-sm-4"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addEntry.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',[_c('BaseInput',{class:{
                    error: _vm.$v.formBody.accountNumber.$error
                  },attrs:{"inputType":"number","placeholder":"Entry Account Number","hasPreIcon":false,"label":"Account Number"},on:{"blur":function($event){return _vm.$v.formBody.accountNumber.$touch()}},model:{value:(_vm.formBody.accountNumber),callback:function ($$v) {_vm.$set(_vm.formBody, "accountNumber", $$v)},expression:"formBody.accountNumber"}}),(_vm.$v.formBody.accountNumber.$error)?[(!_vm.$v.formBody.accountNumber.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Account Number is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-12"},[_c('div',[_c('BaseInput',{class:{
                    error: _vm.$v.formBody.serialNumber.$error
                  },attrs:{"inputType":"text","placeholder":"Serial Number","hasPreIcon":false,"label":"Serial Number"},on:{"blur":function($event){return _vm.$v.formBody.serialNumber.$touch()}},model:{value:(_vm.formBody.serialNumber),callback:function ($$v) {_vm.$set(_vm.formBody, "serialNumber", $$v)},expression:"formBody.serialNumber"}}),(_vm.$v.formBody.serialNumber.$error)?[(!_vm.$v.formBody.serialNumber.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Serial Number is required ")]):_vm._e()]:_vm._e()],2)])]),_vm._m(1)])])]),_c('br'),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-right"},[(_vm.loader.actionButtonLoader)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",on:{"click":function($event){return _vm.showConfirmationModal()}}},[_vm._v(" Upload Terminals ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',[_vm._v("S/N")]),_c('th',[_vm._v("Account Number")]),_c('th',[_vm._v("Serial Number")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-right"},[_c('span',[_c('button',{staticClass:"btn btn-secondary mr-1",attrs:{"type":"submit"}},[_vm._v(" Add Terminal ")])])])
}]

export { render, staticRenderFns }