const getDefaultState = () => {
  return {
    user: {}
  };
};

export const state = getDefaultState();

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.user;
  }
};

export const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

export const actions = {
  setUsername({ commit }, payload) {
    commit("SET_USER_USERNAME", payload);
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
