<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->

    <form @submit.prevent="uploadLogo">
      <div class="row">
        <div class="col-sm-12">
          <div>
            <div class="">
              <label>Bank Logo</label>

              <div class="custom-file">
                <input
                  id="bankLogoUpload"
                  type="file"
                  placeholder="Customer Picture"
                  class="custom-file-input"
                  required
                  @change="updateSelectedBankLogo"
                />
                <label class="custom-file-label" for="bankLogoUpload">{{
                  selectedBankLogo
                }}</label>
              </div>
            </div>

            <template v-if="$v.uploadBankLogoFormBody.bankLogo.$error">
              <p
                v-if="!$v.uploadBankLogoFormBody.bankLogo.required"
                class="form-error-message"
              >
                Customer Picture is required & must be an image
              </p>
            </template>
          </div>
        </div>
      </div>

      <p class="text-right">
        <span v-if="bank.moduleLoadingFour" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Upload Logo
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "UploadBank",
  data() {
    return {
      uploadBankLogoFormBody: this.createFreshCreateBankFormBodyObject(),
      selectedBankLogo: "Choose Image"
    };
  },
  computed: {
    ...mapState(["bank"])
  },
  validations: {
    uploadBankLogoFormBody: {
      bankLogo: { required }
    }
  },
  methods: {
    updateSelectedBankLogo() {
      let files = document.getElementById("bankLogoUpload").files;
      this.selectedBankLogo = files[0].name;
    },
    async bankLogoUpload() {
      let files = document.getElementById("bankLogoUpload").files;
      if (!files.length) {
        return this.$v.uploadBankLogoFormBody.bankLogo.$touch();
      } else {
        let file = files[0];
        let fileExtension = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );
        // let fileName = file.name;
        if (file.type.includes("image")) {
          let payload = {
            appAlbumName: "StagingArea",
            fileName: this.$store.getters["user/getUserBankCode"],
            fileExtension: fileExtension,
            file: file
          };

          return await this.$store.dispatch(
            "appS3Upload/uploadBankLogoToStagingArea",
            payload,
            {
              root: true
            }
          );
        } else {
          return this.$v.uploadBankLogoFormBody.bankLogo.$touch();
        }
      }
    },

    async uploadLogo() {
      // start module loading for file upload
      this.$store.dispatch("bank/setModuleLoading", true, { root: true });

      await this.bankLogoUpload();

      // assign the destination File Path to the BankLogo
      this.uploadBankLogoFormBody.bankLogo = await this.$store.getters[
        "appS3Upload/getUploadBankLogoFinalPath"
      ];

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.uploadBankLogoFormBody.bankLogoMaker = this.$store.getters[
          "user/getUserId"
        ];
        this.uploadBankLogoFormBody.bankCode = this.$store.getters[
          "user/getUserBankCode"
        ];

        this.$store
          .dispatch("bank/uploadBankLogo", this.uploadBankLogoFormBody, {
            root: true
          })
          .then(() => {
            this.uploadBankLogoFormBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      } else {
        this.$store.dispatch("bank/setModuleLoadingFour", false, {
          root: true
        });
      }
    },
    createFreshCreateBankFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
