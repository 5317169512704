<template>
  <div class="confirmation-box mb-4">
    <p v-if="destination !== ''" class="destination text-left">
      {{ destination }}
    </p>
    <p class="text-left">
      <span class="confirmation-box-wrap">
        <ViewCustomerInfoModal
          v-if="clickable"
          :currentCustomer="account"
          queryBy="account-number"
        />
        <span
          v-else
          class="d-flex mr-3 avatar-title bg-primary rounded-circle font-size-16 real-avatar"
        >
          {{ account.accountName | getInitials }}
        </span>

        <span class="media-body chat-user-box">
          <span class="user-title m-0">{{
            account.accountName | firstCaseCapitalSpace
          }}</span>
          <span v-if="account.accountBankName !== ''" class="text-muted">
            {{ account.accountNumber }} ({{
              account.accountBankName | firstCaseCapitalSpace
            }})
          </span>
        </span>

        <span v-if="account.accountBalance" class="balance">
          ₦ {{ account.accountBalance | newAmountFormatter }}
        </span>
      </span>
    </p>
  </div>
</template>

<script>
import ViewCustomerInfoModal from "../../Modals/Customer/ViewCustomerInfoModal";
export default {
  name: "CustomerAccountCard",
  components: { ViewCustomerInfoModal },
  props: {
    account: {
      type: Object,
      required: true
    },
    destination: {
      type: String,
      default: ""
    },
    clickable: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
