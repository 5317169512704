<template>
  <div>
    <!--    <div v-if="loader.componentLoader">-->
    <!--      <BaseComponentLoader />-->
    <!--    </div>-->
    <div>
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.accountNumber"
                placeholder="Customer Account Number"
                :hasPreIcon="false"
                :label="'Account Number'"
                :class="{ error: $v.formBody.accountNumber.$error }"
                @blur="$v.formBody.accountNumber.$touch()"
              />

              <template v-if="$v.formBody.accountNumber.$error">
                <p
                  v-if="!$v.formBody.accountNumber.required"
                  class="form-error-message"
                >
                  Account Number is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'email'"
                v-model="formBody.email"
                placeholder="Email"
                :hasPreIcon="false"
                :label="'Email'"
              />
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Start Date</label>
                <date-picker
                  v-model="formBody.startDate"
                  placeholder="Start Date"
                  :first-day-of-week="1"
                  lang="en"
                  confirm
                  @blur="$v.formBody.startDate.$touch()"
                ></date-picker>
              </div>

              <template v-if="$v.formBody.startDate.$error">
                <p
                  v-if="!$v.formBody.startDate.required"
                  class="form-error-message"
                >
                  Start Date is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>End Date</label>
                <date-picker
                  v-model="formBody.endDate"
                  placeholder="End Date"
                  :first-day-of-week="1"
                  lang="en"
                  confirm
                  @blur="$v.formBody.endDate.$touch()"
                ></date-picker>
              </div>

              <template v-if="$v.formBody.endDate.$error">
                <p
                  v-if="!$v.formBody.endDate.required"
                  class="form-error-message"
                >
                  End Date is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Submit
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import DateFormatterUtils from "@/utils/DateFormaterUtils";

export default {
  name: "RequestAccountStatementForm",
  data() {
    return {
      formBody: this.freshFormBodyObject()
    };
  },
  components: {
    DatePicker
  },
  computed: {
    ...mapState(["rpt", "loader"])
  },
  validations: {
    formBody: {
      accountNumber: { required },
      startDate: { required },
      endDate: { required }
    }
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit("form/BUILD_FORM_BODY", {
          ...this.formBody,
          startDate: DateFormatterUtils.formatDate(
            this.formBody.startDate,
            "dd-MMM-yyyy"
          ),
          endDate: DateFormatterUtils.formatDate(
            this.formBody.endDate,
            "dd-MMM-yyyy"
          )
        });
        StoreUtils.dispatch("rpt/requestAccountStatement");
      }
    },

    freshFormBodyObject() {
      return {
        source: "WEB"
      };
    }
  }
};
</script>

<style scoped></style>
