<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text" label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnCode)="data">
        <p class="name-field">
          <span>{{ data.item.trnCode | toUppercase }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnStatus)="data">
        <p class="name-field">
          <span
            v-if="data.item.trnStatus.toUpperCase() === 'ACTIVE'"
            class="badge badge-pill badge-soft-success font-size-12"
            >{{ data.item.trnStatus | firstCaseCapital }}</span
          >
          <span
            v-if="data.item.trnStatus.toUpperCase() === 'INACTIVE'"
            class="badge badge-pill badge-soft-danger font-size-12"
            >{{ data.item.trnStatus | firstCaseCapital }}</span
          >
          <span
            v-if="data.item.trnStatus.toUpperCase() === 'Pending'"
            class="badge badge-pill badge-soft-warning font-size-12"
            >{{ data.item.trnStatus | firstCaseCapital }}</span
          >
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnExpense)="data">
        <p class="name-field text-center">
          <span
            v-if="data.item.trnExpense.toUpperCase() === 'YES'"
            class="badge badge-success"
          >
            {{ data.item.trnExpense | firstCaseCapital }}
          </span>
          <span v-if="data.item.trnExpense.toUpperCase() === 'NO'" class="badge badge-danger">
            {{ data.item.trnExpense | firstCaseCapital }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnIncome)="data">
        <p class="name-field text-center">
          <span
            v-if="data.item.trnIncome.toUpperCase() === 'YES'"
            class="badge badge-success"
          >
            {{ data.item.trnIncome | firstCaseCapital }}
          </span>
          <span v-if="data.item.trnIncome.toUpperCase() === 'NO'" class="badge badge-danger">
            {{ data.item.trnIncome | firstCaseCapital }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnCustomer)="data">
        <p class="name-field text-center">
          <span
            v-if="data.item.trnCustomer.toUpperCase() === 'YES'"
            class="badge badge-success"
          >
            {{ data.item.trnCustomer | firstCaseCapital }}
          </span>
          <span
            v-if="data.item.trnCustomer.toUpperCase() === 'NO'"
            class="badge badge-danger"
          >
            {{ data.item.trnCustomer | firstCaseCapital }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnCreatedAt)="data">
        <p class="name-field">
          <span>
            {{ data.item.trnCreatedAt | formatDateForTable }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnAsset)="data">
        <p class="name-field text-center">
          <span v-if="data.item.trnAsset.toUpperCase() === 'YES'" class="badge badge-success">
            {{ data.item.trnAsset | firstCaseCapital }}
          </span>
          <span v-if="data.item.trnAsset.toUpperCase() === 'NO'" class="badge badge-danger">
            {{ data.item.trnAsset | firstCaseCapital }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnLiability)="data">
        <p class="name-field text-center">
          <span
            v-if="data.item.trnLiability.toUpperCase() === 'YES'"
            class="badge badge-success"
          >
            {{ data.item.trnLiability | firstCaseCapital }}
          </span>
          <span
            v-if="data.item.trnLiability.toUpperCase() === 'NO'"
            class="badge badge-danger"
          >
            {{ data.item.trnLiability | firstCaseCapital }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnCredit)="data">
        <p class="name-field text-center">
          <span
            v-if="data.item.trnCredit.toUpperCase() === 'YES'"
            class="badge badge-success"
          >
            {{ data.item.trnCredit | firstCaseCapital }}
          </span>
          <span v-if="data.item.trnCredit.toUpperCase() === 'NO'" class="badge badge-danger">
            {{ data.item.trnCredit | firstCaseCapital }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnDebit)="data">
        <p class="name-field text-center">
          <span v-if="data.item.trnDebit.toUpperCase() === 'YES'" class="badge badge-success">
            {{ data.item.trnDebit | firstCaseCapital }}
          </span>
          <span v-if="data.item.trnDebit.toUpperCase() === 'NO'" class="badge badge-danger">
            {{ data.item.trnDebit | firstCaseCapital }}
          </span>
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AllBanksTable",

  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        { key: "trnCode", label: "Transaction Code", sortable: true },
        { key: "trnDescription", label: "Transaction Type", sortable: true },
        { key: "trnCreatedAt", label: "Created At", sortable: true },
        { key: "trnExpense", label: "Transaction Expense", sortable: true },
        { key: "trnIncome", label: "Transaction Income", sortable: true },
        { key: "trnCustomer", label: "Transaction Customer", sortable: true },
        { key: "trnAsset", label: "Transaction Asset", sortable: true },
        { key: "trnLiability", label: "Transaction Liability", sortable: true },
        { key: "trnCredit", label: "Transaction Credit", sortable: true },
        { key: "trnDebit", label: "Transaction Debit", sortable: true },
        { key: "trnMaker", label: "Transaction Maker", sortable: true },
        { key: "trnChecker", label: "Checker", sortable: true },
        { key: "trnStatus", label: "Status", sortable: true }
      ],

      json_fields: {
        "Transaction Code Id": "trnCodeId",
        "Transaction Code": "trnCode",
        "Transaction Description": "trnDescription",
        "Transaction Expense": "Expense",
        "Transaction Income": "trnInternalIncome",
        "Transaction Customer": "trnCustomer",
        "Transaction Asset": "trnAsset",
        "Transaction Liability": "trnLiability",
        "Transaction Branches": "trnBranches",
        "Transaction Office": "trnOffice",
        "Transaction Debit": "trnDebit",
        "Transaction Credit": "trnCredit",
        "Transaction Created At": "trnCreatedAt",
        "Transaction Updated At": "trnUpdatedAt",
        "Transaction Maker": "trnMaker",
        "Transaction Checker": "trnChecker",
        "Transaction Bank Code": "trnBankCode",
        "Transaction Status": "trnStatus"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.transactionCode.allTransactionCodes,
      isBusy: state => state.transactionCode.moduleLoadingTwo
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    returnItems() {
      // eslint-disable-next-line no-console
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },

  created() {
    this.$store.dispatch("transactionCode/allTransactionCodes", null, {
      root: true
    });
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
.name-field {
}
.name-field .badge {
}
</style>
