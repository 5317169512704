<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->

    <form @submit.prevent="logIn">
      <div class="row">
        <div class="col-sm-12">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="loginFormBody.userBankCode"
              placeholder="Bank Code"
              :hasPreIcon="false"
              :label="'Bank Code'"
              :class="{
                error: $v.loginFormBody.userBankCode.$error
              }"
              @blur="$v.loginFormBody.userBankCode.$touch()"
            >
            </BaseInput>

            <template v-if="$v.loginFormBody.userBankCode.$error">
              <p
                v-if="!$v.loginFormBody.userBankCode.required"
                class="form-error-message"
              >
                Bank Code is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="loginFormBody.username"
              placeholder="username"
              :hasPreIcon="false"
              :label="'username'"
              :class="{
                error: $v.loginFormBody.username.$error
              }"
              @blur="$v.loginFormBody.username.$touch()"
            />

            <template v-if="$v.loginFormBody.username.$error">
              <p
                v-if="!$v.loginFormBody.username.required"
                class="form-error-message"
              >
                username is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'password'"
              v-model="loginFormBody.userPassword"
              placeholder="Password"
              :hasPreIcon="false"
              :label="'Password'"
              :class="{
                error: $v.loginFormBody.userPassword.$error
              }"
              @blur="$v.loginFormBody.userPassword.$touch()"
            >
            </BaseInput>

            <template v-if="$v.loginFormBody.userPassword.$error">
              <p
                v-if="!$v.loginFormBody.userPassword.required"
                class="form-error-message"
              >
                Password is required
              </p>
            </template>
          </div>
        </div>
      </div>

      <p class="text-right">
        <span v-if="auth.moduleLoading" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Login
          </button>

          <!--          <a @click="removeAuth()" class="btn btn-danger mr-1">-->
          <!--            Remove Auth-->
          <!--          </a>-->
          <!--          <a @click="logAuth()" class="btn btn-secondary mr-1">-->
          <!--            Log Auth-->
          <!--          </a>-->
          <!--          <a @click="setAuth()" class="btn btn-success mr-1">-->
          <!--            Set Auth-->
          <!--          </a>-->
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "Login",
  data() {
    return {
      loginFormBody: this.createFreshLoginFormBodyObject()
    };
  },
  computed: {
    ...mapState(["auth"])
  },
  validations: {
    loginFormBody: {
      username: { required },
      userBankCode: { required },
      userPassword: { required }
    }
  },
  methods: {
    removeAuth() {
      StoreUtils.commit("user/REMOVE_USER_TOKEN");
    },
    setAuth() {
      StoreUtils.commit("user/SET_USER_TOKEN", "test");
    },
    logAuth() {
      // StoreUtils.commit("user/REMOVE_USER_TOKEN");
      //   "Token =>",
      //   StoreUtils.rootGetter("user/getUserTokenFromLocalStorage")
      // );
    },
    showNotification() {
      const notification = {
        type: "success",
        message: `I Go Crazy!!!`
      };
      this.$store.dispatch("notification/add", notification, { root: true });
    },
    logIn() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        localStorage.clear();
        this.$store
          .dispatch("auth/logIn", this.loginFormBody, { root: true })
          .then(() => {
            this.loginFormBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshLoginFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
