<template>
  <div class="table-section">
    <div>
      <h3 class="card-title mb-4">All Lien under this Account</h3>
    </div>
    <div class="row">
      <div class="col-12">
      </div>
    </div>
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
<!--              <button-->
<!--                type="button"-->
<!--                class="btn btn-secondary btn-semi-rounded mb-2 mr-2"-->
<!--              >-->
<!--                <i class="bx bxs-report"></i> &nbsp; &nbsp;-->
<!--                <download-excel-->
<!--                  class="excel-download-btn"-->
<!--                  :data="items"-->
<!--                  :fields="json_fields"-->
<!--                  worksheet="My Worksheet"-->
<!--                  name="filename.xls"-->
<!--                >-->
<!--                  Download As Excel-->
<!--                </download-excel>-->
<!--              </button>-->
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <!-- A virtual composite column -->
      <template v-slot:cell(customerBankCode)="data">
        <p class="name-field">
          <span>{{ data.item.customerBankCode }}</span>
        </p>
      </template>

      <template v-slot:cell(accountBalance)="data">
        <p class="name-field">
          <span> ₦ {{ data.item.accountBalance }} </span>
        </p>
      </template>

      <template v-slot:cell(accountLedgerBalance)="data">
        <p class="name-field">
          <span> ₦ {{ data.item.accountLedgerBalance }} </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(lienAmountCreatedAt)="data">
        <p class="name-field">
          <span>
            {{ data.item.lienAmountCreatedAt | formatDateForTable }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(accountLastAccessTimestamp)="data">
        <p class="name-field">
          <span>
            {{ data.item.accountLastAccessTimestamp | formatDateForTable }}
          </span>
        </p>
      </template>

      <template v-slot:cell(accountClass)="data">
        <p class="name-field">
          <span class="badge badge-pill badge-soft-secondary font-size-12">
            {{ data.item.accountClass }}
          </span>
        </p>
      </template>

      <template v-slot:cell(accountType)="data">
        <p class="name-field">
          <span class="badge badge-pill badge-soft-secondary font-size-12">
            {{ data.item.accountType }}
          </span>
        </p>
      </template>

      <template v-slot:cell(accountCurrency)="data">
        <p class="name-field">
          <span class="badge badge-pill badge-soft-secondary font-size-12">
            {{ data.item.accountCurrency }}
          </span>
        </p>
      </template>

      <template v-slot:cell(accountStatus)="data">
        <p class="name-field">
          <AppStatusBadge :status-value="data.item.accountStatus" />
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(action)="data">
        <p class="name-field">
          <DeleteLienModal :current-customer="data.item" />
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>
    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import DeleteLienModal from "../../Modals/Customer/DeleteLienModal";

export default {
  name: "AllCustomerLienTable",
  components: { DeleteLienModal },
  data() {
    return {
      model:{
        bankCode: "",
        accountNumber: localStorage.getItem("accNumb")
      },
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },
        { key: "lienAmountAccount", label: "Account Number", sortable: true },
        { key: "lienAmountReference", label: "Reference ID", sortable: true },
        { key: "lienAmountBlockAmount", label: "Lien Block Amount ", sortable: true },
        { key: "lienAmountUnblockAmount", label: "Lien Unblock Amount", sortable: true },
        { key: "lienAmountBankCode", label: "Bank Code", sortable: true },
        { key: "lienAmountCreatedAt", label: "Created At", sortable: true },
        { key: "action", label: "Action", sortable: false }
      ],

      json_fields: {
        accountChecker: "accountChecker",
        accountBankCode: "accountBankCode",
        accountUpdatedDate: "accountUpdatedDate",
        accountGlLevel1Code: "accountGlLevel1Code",
        accountName: "accountName",
        accountInterestRate: "accountInterestRate",
        accountType: "accountType",
        accountAllowNegativeBalance: "accountAllowNegativeBalance",
        accountCurrency: "accountCurrency",
        accountNumber: "accountNumber",
        accountLastAccessTimestamp: "accountLastAccessTimestamp",
        accountDateClosed: "accountDateClosed",
        accountGlCategory: "accountGlCategory",
        accountBranchCode: "accountBranchCode",
        accountStatus: "accountStatus",
        accountMaker: "accountMaker",
        accountId: "accountId",
        accountCreatedDate: "accountCreatedDate",
        accountCustomerId: "accountCustomerId",
        accountClass: "accountClass",
        accountLedgerBalance: "accountLedgerBalance",
        accountGlLevel2Code: "accountGlLevel2Code",
        accountBalance: "accountBalance",
        accountDateOpened: "accountDateOpened"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.customer.allLien,
      isBusy: state => state.customer.moduleLoading
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";

  },
  methods: {
    returnItems() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;
        return initials;
      } else {
        return initials.substring(0, 2);
      }
    },
    fetchTableData() {
      // const payload = {
      //   bankCode: StoreUtils.rootGetter(
      //     StoreUtils.getters.user.GET_USER_BANK_CODE
      //   ),
      //   accountNumber : localStorage.accNumber
      // };
      // this.model.bankCode = StoreUtils.rootGetter(
      //     StoreUtils.getters.user.GET_USER_BANK_CODE
      // ),
      //     this.model.accountNumber = localStorage.getItem('accNumb')
      // this.$store.dispatch("customer/allUserLien", this.model, {
      //   root: true
      // });
    }
  },
  created() {
    this.fetchTableData();
  }
};
</script>
<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
.view-more-info {
  margin-right: 2px;
  display: inline-block;
  color: #ffffff;
}
.view-more-info a {
  cursor: pointer;
}
.view-more-info a i {
  cursor: pointer;
}
.actual-name {
  margin-left: 2px;
  line-height: 15px;
}
</style>
