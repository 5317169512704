<template>
  <transition
    @before-enter="beforeEnter"
    @enter="barEnter"
    @leave="barLeave"
    :css="false"
  >
    <div v-if="pollAnswered" class="score-progress-bar IR-level-2-bg"></div>
  </transition>
</template>

<script>
import Velocity from "velocity-animate";

export default {
  name: "BasePollProgress",
  props: {
    name: String,
    pollAnswered: Boolean,
    width: {
      type: Number,
      default: 100
    }
  },
  methods: {
    //Animation by Velocity
    beforeEnter(el) {
      // we'll place our starting style here
      el.style.opacity = 0;
      el.style.width = "0%";
    },
    barEnter(el, done) {

      // style to transition to on enter

      Velocity(
        el,
        { opacity: 1, width: "100%" },
        { duration: 800, easing: [60, 10], complete: done }
      );
    },
    barLeave(el, done) {

      // style to transition away to on leave
      Velocity(
        el,
        { opacity: 0, width: "0%" },
        // { duration: 500, easing: 'easeInCubic', complete: done }
        { duration: 500, easing: [60, 10], complete: done }
      );
    }
  }
};
</script>

<style scoped></style>
