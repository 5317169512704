import Vue from "vue";

Vue.filter("getInitialsGuru", value => {
  let parts = value.split(" ");
  let initials = "";
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  if (initials.length === 2) {
    return initials;
  } else if (initials.length < 2) {
    initials = initials + initials;

    return initials;
  } else {
    return initials.substring(0, 2);
  }
});

// export default instance
