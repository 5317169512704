// create a new Bank

import ProductService from "../../services/oldServices/ProductService";
import router from "../../router";

import NewProductService from "../../services/newServices/ProductService";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
const newProductService = new NewProductService();

//utility
function objIsEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const getDefaultState = () => {
  return {
    test: "",
    productTypes: [],
    selectedProductType: {},
    dynamicProducts: [],
    createProductFields: [],
    editProductFields: [],
    moduleLoading: false,
    moduleLoadingTwo: false,
    moduleLoadingThree: true,
    moduleLoadingFour: true,
    moduleLoadingFive: true,
    moduleLoadingSix: true,
    moduleLoadingSeven: true,
    moduleLoadingEight: false,
    fields: []
  };
};
export const state = getDefaultState();

export const getters = {
  columns: state => {
    return state.fields;
  },
  getSelectedProductType: state => {
    return state.selectedProductType;
  },
  formObj: state => {
    //let fieldObject =  state.fields.map( field => { return field.templateDescription: { } })
    let result = state.fields.reduce((k, v) => {
      k[v.templateDescription] = {
        type: "string",
        minLength: 8,
        maxLength: 80,
        title: v.templateDescription,
        attrs: {
          placeholder: "Your " + v.templateDescription,
          title: "Please enter your " + v.templateDescription
        },
        ...(v.templateDataType === "LIST" && {
          enum: v.templateList.split(",")
        })
      };
      return k;
    });
    delete result.templateDescription;
    delete result.templateType;
    delete result.templateDataType;
    delete result.templateList;
    delete result.templateSequence;
    const formSchema = {
      $schema: "http://json-schema.org/draft-04/schema#",
      type: "object",
      title: "Create New Product",
      description: "Create New Product Form",
      properties: result,
      additionalProperties: false,
      required: state.fields.map(field => {
        return field.templateDescription;
      })
    };
    return formSchema;
  },
  getEditProductFields: state => {
    return state.editProductFields;
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },

  SET_COLUMNS(state, payload) {
    state.fields = payload;
  },
  SET_PRODUCT_TYPES(state, payload) {
    state.productTypes = payload;
  },
  SET_SELECTED_PRODUCT_TYPE(state, payload) {
    state.selectedProductType = payload;
  },
  SET_DYNAMIC_PRODUCTS(state, payload) {
    state.dynamicProducts = payload.map(product => {
      return { ...product, action: "" };
    });
  },
  SET_CREATE_PRODUCT_FIELDS(state, payload) {
    state.createProductFields = payload;
  },
  SET_EDIT_PRODUCT_FIELDS(state, payload) {
    state.editProductFields = payload;
  },

  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_LOADING_SIX(state, status) {
    state.moduleLoadingSix = status;
  },
  SET_LOADING_SEVEN(state, status) {
    state.moduleLoadingSeven = status;
  },
  SET_LOADING_EIGHT(state, status) {
    state.moduleLoadingEight = status;
  }
};

export const actions = {
  updateProduct({ commit, dispatch, rootGetters, getters }, payload) {
    // start loader
    commit("SET_LOADING_EIGHT", true);

    //build the real payload ** convert the form body to an Array
    let newObj = [];
    for (let [key, value] of Object.entries(payload.products)) {
      newObj.push({
        key,
        value: value,
        bankcode: rootGetters["user/getUserBankCode"],
        productname: getters.getSelectedProductType.prdTemplateCode.toUpperCase(),
        userId: rootGetters["user/getUserId"]
      });
    }

    payload.products = newObj;

    return ProductService.updateProduct(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_EIGHT", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Product Updated Successfully`
          };
          dispatch("notification/add", notification, { root: true });
          return responseData;
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_EIGHT", false);
        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  newCreateProduct({ commit, dispatch, rootGetters, getters }, payload) {
    // start loader
    commit("SET_LOADING_EIGHT", true);

    //build the real payload ** convert the form body to an Array
    let newObj = [];
    for (let [key, value] of Object.entries(payload.products)) {
      newObj.push({
        key: key,
        value: value,
        bankcode: rootGetters["user/getUserBankCode"],
        productname: getters.getSelectedProductType.prdTemplateCode.toUpperCase(),
        userId: rootGetters["user/getUserId"]
      });
    }

    payload.products = newObj;

    return ProductService.createProduct(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_EIGHT", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Product Created Successfully`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_EIGHT", false);
        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  checkIfProductTypeHasBeenSelectedView({ getters }) {
    if (
      objIsEmpty(getters.getSelectedProductType) ||
      getters.getSelectedProductType === {} ||
      getters.getSelectedProductType.prdTemplateDestcription === null
    ) {
      // redirect to select
      router.push({
        name: "product-select-type-create"
      });
    }
  },
  checkIfProductTypeHasBeenSelectedCreate({ getters }) {
    if (
      objIsEmpty(getters.getSelectedProductType) ||
      getters.getSelectedProductType === {} ||
      getters.getSelectedProductType.prdTemplateDestcription === null
    ) {
      // redirect to select
      router.push({
        name: "product-select-type-create"
      });
    }
  },
  // fetch all columns
  getAllProductFields({ commit, dispatch, rootGetters, getters }) {
    // start loader
    commit("SET_LOADING_SEVEN", true);
    let payload = {
      templateBankCode: rootGetters["user/getUserBankCode"],
      templateType: getters.getSelectedProductType.prdTemplateCode
    };

    return ProductService.allProductFields(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_SEVEN", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("SET_CREATE_PRODUCT_FIELDS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        commit("SET_LOADING_SEVEN", false);
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });
      });
  },

  fetchProductFieldsForEditing({ getters }, { productCode }) {
    // start loader
    let payload = {
      templateBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      templateType: getters.getSelectedProductType.prdTemplateCode,
      productCode
    };

    const successAction = responseData => {
      StoreUtils.commit("product/SET_EDIT_PRODUCT_FIELDS", responseData.data);
      // RouterUtils.changeRouteTo(RouterUtils.routes.journal.ADD_ENTRY_TO_BATCH);
    };

    return newProductService.fetchProductFieldsForEditing(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  readProduct({ commit, rootGetters, getters }) {
    // start loader
    commit("SET_LOADING_SIX", true);

    let payload = {
      productBankCode: rootGetters["user/getUserBankCode"],
      productType: getters.getSelectedProductType.prdTemplateCode
    };

    return ProductService.readProduct(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_SIX", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_DYNAMIC_PRODUCTS", responseData.data);
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  setSelectedProductType({ commit }, payload) {
    commit("SET_SELECTED_PRODUCT_TYPE", payload);

    if (payload.targetPage === "create-product") {
      router.push({
        name: "product-create-product"
      });
    }
    if (payload.targetPage === "view-dynamic-products") {
      router.push({
        name: "view-dynamic-products"
      });
    }
  },
  fetchProductTypes({ commit }) {
    // start loader
    commit("SET_LOADING_FIVE", true);

    let payload = {
      you: "you"
    };

    return ProductService.fetchProductTypes(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FIVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_PRODUCT_TYPES", responseData.data);
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FIVE", false);

        throw error;
      });
  },
  createProduct({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_TWO", true);

    return ProductService.createProduct(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_TWO", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_TWO", false);

        throw error;
      });
  },
  // fetch all columns
  getAllFields({ commit, dispatch, rootGetters, getters }) {
    // start loader
    commit("SET_LOADING_FOUR", true);
    let payload = {
      templateBankCode: rootGetters["user/getUserBankCode"],
      templateType: getters.getSelectedProductType.prdTemplateCode
    };

    return ProductService.allProductFields(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FOUR", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("SET_COLUMNS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        commit("SET_LOADING_FOUR", false);
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });
      });
  }
};
