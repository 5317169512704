<template>
  <div>
    <form @submit.prevent="createGlLevel2">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>GL Level 1 Code</label>
              <multiselect
                v-model="formBody.glLevel2GlLevel1Code"
                :options="glLevelOneUtilityOption"
                placeholder="GL Level 1 Code"
                @close="$v.formBody.glLevel2GlLevel1Code.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.formBody.glLevel2GlLevel1Code.$error">
              <p
                v-if="!$v.formBody.glLevel2GlLevel1Code.required"
                class="form-error-message"
              >
                GL Level 1 Code is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="formBody.glLevel2Description"
              placeholder="Description"
              :hasPreIcon="false"
              :label="'Description'"
              :class="{
                error: $v.formBody.glLevel2Description.$error
              }"
              @blur="$v.formBody.glLevel2Description.$touch()"
            >
            </BaseInput>

            <template v-if="$v.formBody.glLevel2Description.$error">
              <p
                v-if="!$v.formBody.glLevel2Description.required"
                class="form-error-message"
              >
                Description is required
              </p>
            </template>
          </div>
        </div>
      </div>
      <p class="text-right">
        <span v-if="glLevel2.moduleLoading" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Submit
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateGlLevel2Form",
  data() {
    return {
      formBody: this.createFreshCreateGLLevel2FormBodyObject(),

      options: [
        "Fixed Asset",
        "Current Asset",
        "Equity",
        "Liability",
        "Revenue",
        "Expense"
      ]
    };
  },
  created() {
    this.$store.dispatch("appUtilities/fetchUtilityGlLevelOneCodes");
  },
  computed: {
    ...mapState(["glLevel2"]),
    ...mapGetters({
      glLevelOneUtilityOption: "appUtilities/getGlLevelOneUtilityOption",
      getGlLevelOneCodeFromOption: "appUtilities/getGlLevelOneCodeFromOption"
    })
  },
  validations: {
    formBody: {
      glLevel2Description: { required },
      glLevel2GlLevel1Code: { required }
    }
  },
  methods: {
    createGlLevel2() {
      this.formBody.glLevel2BankCode = this.$store.getters[
        "user/getUserBankCode"
      ];
      this.formBody.glLevel2BranchCode = this.$store.getters[
        "user/getUserBranchCode"
      ];
      this.formBody.glLevel2GlLevel1Code = this.getGlLevelOneCodeFromOption(
        this.formBody.glLevel2GlLevel1Code
      );

      this.formBody.glLevel2Maker = this.$store.getters["user/getUserId"];

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("glLevel2/createGlLevel2", this.formBody, {
            root: true
          })
          .then(() => {
            this.formBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshCreateGLLevel2FormBodyObject() {
      return {
        // source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
