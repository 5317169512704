import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import RptService from "../../services/newServices/RptService";
const rptService = new RptService();

const getDefaultState = () => {
  return {
    reportCategories: [],
    reportTableTypes: ["table", "report"],
    tableList: [],
    reportList: [],
    reportJoinTypes: [
      "JOIN",
      "LEFT JOIN",
      "RIGHT JOIN",
      "INNER JOIN",
      "FULL JOIN",
      "CROSS JOIN"
    ],
    reportBaseTableColumns: [],
    reportCapTableColumns: [],
    queryReportTableColumns: [],
    andOrOptions: ["AND", "OR"],
    operatorOptions: ["=", "<>", ">", "<", ">=", "<=", "LIKE"]
  };
};
export const state = getDefaultState();

export const getters = {
  getReportTableTypesOption: state => {
    return state.reportTableTypes;
  },
  getReportCategoriesOption: state => {
    return state.reportCategories.map(category => {
      return `${category.departmentName}`;
    });
  },
  getTableListOption: state => {
    return state.tableList.map(table => {
      return `${table.tableName}`;
    });
  },
  getReportList: state => {
    return state.reportList;
  },
  getReportListOption: state => {
    return state.reportList.map(report => {
      return `${report.reportName} - (${report.reportDescription})`;
    });
  },
  getReportNameFromOption: state => chosenOption => {
    return state.reportList.filter(function(report) {
      return (
        `${report.reportName} - (${report.reportDescription})` === chosenOption
      );
    })[0].reportName;
  },
  getReportJoinTypesOption: state => {
    return state.reportJoinTypes;
  },
  getReportBaseTableColumnsOption: state => {
    return state.reportBaseTableColumns.map(column => {
      return `${column.columnName}`;
    });
  },
  getReportCapTableColumnsOption: state => {
    return state.reportCapTableColumns.map(column => {
      return `${column.columnName}`;
    });
  },
  getQueryReportTableColumnsOption: state => {
    return state.queryReportTableColumns.map(column => {
      return `${column.columnName}`;
    });
  },
  getAllReportTablesColumnsOption: state => {
    return [].concat.apply(
      [],
      [
        state.reportBaseTableColumns.map(column => {
          return `${column.columnName}`;
        }),
        state.reportCapTableColumns.map(column => {
          return `${column.columnName}`;
        })
      ]
    );
  },
  getAndOrOptions: state => {
    return state.andOrOptions;
  },
  getOperatorOptions: state => {
    return state.operatorOptions;
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  TEST(state, payload) {
    state.test = payload;
  },

  SET_REPORT_CATEGORIES(state, payload) {
    state.reportCategories = payload;
  },
  SET_REPORT_LIST(state, payload) {
    state.reportList = payload;
  },
  SET_TABLE_LIST(state, payload) {
    state.tableList = payload;
  },
  SET_REPORT_BASE_TABLE_COLUMNS(state, payload) {
    state.reportBaseTableColumns = payload;
  },
  SET_REPORT_CAP_TABLE_COLUMNS(state, payload) {
    state.reportCapTableColumns = payload;
  },
  SET_QUERY_REPORT_TABLE_COLUMNS(state, payload) {
    state.queryReportTableColumns = payload;
  }
};
export const actions = {
  fetchReportCapTableColumns() {
    let payload = {
      reportBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      reportName: StoreUtils.rootGetter(
        StoreUtils.getters.rpt.GET_REPORT_CAP_TABLE_NAME
      )
    };

    const successAction = responseData => {
      StoreUtils.commit(
        "rptUtilities/SET_REPORT_CAP_TABLE_COLUMNS",
        responseData.columns
      );
    };

    return rptService.fetchTableColumns(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      false
    );
  },

  fetchReportBaseTableColumns() {
    let payload = {
      reportBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      reportName: StoreUtils.rootGetter(
        StoreUtils.getters.rpt.GET_REPORT_BASE_TABLE_NAME
      )
    };

    const successAction = responseData => {
      StoreUtils.commit(
        "rptUtilities/SET_REPORT_BASE_TABLE_COLUMNS",
        responseData.columns
      );
    };

    return rptService.fetchTableColumns(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      false
    );
  },

  fetchTableList() {
    let payload = {
      reportBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.commit("rptUtilities/SET_TABLE_LIST", responseData.data);
    };

    return rptService.fetchTableList(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      false
    );
  },

  fetchReportList({ commit }, { reportCategory }) {
    commit("TEST", "test");
    let payload = {
      reportBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      readAll: "NO",
      reportCategory
    };

    const successAction = responseData => {
      if (responseData.data)
        StoreUtils.commit("rptUtilities/SET_REPORT_LIST", responseData.data);
    };

    return rptService.fetchReportList(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      false
    );
  },

  fetchReportCategories() {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.commit(
        "rptUtilities/SET_REPORT_CATEGORIES",
        responseData.data
      );
    };

    return rptService.fetchReportCategories(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      false
    );
  },

  fetchQueryReportTableColumns() {
    let payload = {
      reportBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      reportName: StoreUtils.rootGetter("rpt/getCurrentReportName")
    };

    const successAction = responseData => {
      StoreUtils.commit(
        "rptUtilities/SET_QUERY_REPORT_TABLE_COLUMNS",
        responseData.columns
      );
    };

    return rptService.fetchTableColumns(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      false
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
