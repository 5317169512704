<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(accountBankCode)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.accountBankCode }}</span>
        </p>
      </template>

      <!-- A custom formatted column -->
      <template v-slot:cell(accountBranchCode)="data">
        <p class="name-field">
          <span>{{ data.item.accountBranchCode }}</span>
        </p>
      </template>

      <!-- A custom formatted column -->
      <template v-slot:cell(accountName)="data">
        <p class="name-field">
          <span>{{ data.item.accountName }}</span>
        </p>
      </template>

      <template v-slot:cell(accountInterestRate)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.accountInterestRate }}</span>
        </p>
      </template>

      <template v-slot:cell(accountType)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.accountType }}</span>
        </p>
      </template>

      <template v-slot:cell(accountType)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.accountType }}</span>
        </p>
      </template>

      <template v-slot:cell(accountNumber)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.accountNumber }}</span>
        </p>
      </template>

      <template v-slot:cell(accountBalance)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.accountBalance }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(bankStatus)="data">
        <p class="name-field">
          <span
            v-if="data.item.bankStatus === 'Active'"
            class="badge badge-pill badge-soft-success font-size-12"
            >{{ data.item.bankStatus }}</span
          >
          <span
            v-if="data.item.bankStatus === 'Inactive'"
            class="badge badge-pill badge-soft-danger font-size-12"
            >{{ data.item.bankStatus }}</span
          >
          <span
            v-if="data.item.bankStatus === 'Pending'"
            class="badge badge-pill badge-soft-warning font-size-12"
            >{{ data.item.bankStatus }}</span
          >
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AllAccounts",

  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },
        { key: "accountId", label: "Account Id", sortable: true },
        { key: "accountBankCode", label: "Bank Code", sortable: true },
        { key: "accountBranchCode", label: "Branch Code", sortable: true },
        { key: "accountName", label: "Account Name", sortable: true },
        { key: "accountInterestRate", label: "Interest Rate", sortable: true },
        { key: "accountType", label: "Type", sortable: true },
        { key: "accountNumber", label: "Account Number", sortable: true },
        { key: "accountBalance", label: "State", sortable: true },
        { key: "accountStatus", label: "Status", sortable: true },
        { key: "action", label: "Action", sortable: false }
      ],

      json_fields: {
        accountBankCode: "accountBankCode",
        accountUpdatedDate: "accountUpdatedDate",
        accountGlLevel1Code: "accountGlLevel1Code",
        accountName: "accountName",
        accountInterestRate: "accountInterestRate",
        accountType: "accountType",
        accountAllowNegativeBalance: "accountAllowNegativeBalance",
        accountCurrency: "accountCurrency",
        accountNumber: "accountNumber",
        accountLastAccessTimestamp: "accountLastAccessTimestamp",
        accountDateClosed: "accountDateClosed",
        accountGlCategory: "accountGlCategory",
        accountBranchCode: "accountBranchCode",
        accountStatus: "accountStatus",
        accountId: "accountId",
        accountCreatedDate: "accountStatus",
        accountCustomerId: "accountCustomerId",
        accountClass: "accountClass",
        accountGlLevel2Code: "accountGlLevel2Code",
        accountBalance: "accountBalance",
        accountDateOpened: "accountDateOpened"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.customer.accounts,
      isBusy: state => state.customer.moduleLoadingThree
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    returnItems() {
      // eslint-disable-next-line no-console
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;

        return initials;
      } else {
        return initials.substring(0, 2);
      }
    }
  },

  created() {
    const payload = {
      accountBankCode: "00001",
      readAll: ""
    };

    this.$store.dispatch("customer/allAccounts", payload);
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
