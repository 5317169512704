<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Branch Checkers Queue</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <BranchCheckersQueueTable />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Branch Checkers Queue
 */


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";

export default {
  page: {
    title: "Branch Checkers Queue",
    meta: [{ name: "Branch Checkers Queue", content: window.__env.app.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Branch Checkers Queue",
      items: [
        {
          text: "Branch",
          href: "/"
        },
        {
          text: "Branch Checkers Queue",
          active: true
        }
      ]
    };
  }
};
</script>
