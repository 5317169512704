class LienResponse {
    static lienListResponse = {
        responseCode: null,
        responseMessage: null,
        data: []
    }

    static data= {
        lienAmountId: null,
        lienAmountReference: null,
        lienAmountBlockAmount: null,
        lienAmountUnblockAmount: null,
        lienAmountAccount: null,
        lienAmountBankCode: null,
        lienAmountStatus: null,
        lienAmountUpdatedAt: null,
        lienAmountCreatedAt: null
    }
}
export default LienResponse

