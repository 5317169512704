import BankService from "../../../services/oldServices/BankService";
import BranchService from "../../../services/oldServices/BranchService";
import CreateGlLevel1Service from "../../../services/oldServices/GlLevel1Service";
import CreateGlLevel2Service from "../../../services/oldServices/GlLevel2Service";
import RoleService from "../../../services/oldServices/RoleService";
import PrivilegeService from "../../../services/oldServices/PrivilegeService";
import ModuleService from "../../../services/oldServices/ModuleService";
import TransactionCodeService from "../../../services/oldServices/TransactionCodeService";
import StoreUtils from "../../../utils/BaseUtils/StoreUtils";
import ProductService from "../../../services/oldServices/ProductService";
import TellerService from "../../../services/oldServices/TellerService";
import InternalAccountService from "../../../services/oldServices/InternalAccountService";
import BankUserService from "../../../services/oldServices/BankUserService";

export default {
  setUtilityBanks({ commit, rootGetters }) {
    let payload = {
      bankCode: rootGetters["user/getUserBankCode"],
      source: "WEB"
    };
    BankService.singleBank(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          let banks = [responseData];
          commit("SET_UTILITY_BANKS", banks);
        } else {
          commit("SET_UTILITY_BANKS", []);
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityBranches({ commit, rootGetters }) {
    let payload = {
      branchBankCode: rootGetters["user/getUserBankCode"],
      source: "CBA",
      readAll: "NO"
    };
    BranchService.allBranches(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_BRANCHES", responseData.data);
          } else {
            commit("SET_UTILITY_BRANCHES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityGlLevelOneCodes({ commit, rootGetters }) {
    let payload = {
      glLevel1BankCode: rootGetters["user/getUserBankCode"],
      readAll: "YES"
    };
    CreateGlLevel1Service.allGlLevel1(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_GL_LEVEL_ONE", responseData.data);
          } else {
            commit("SET_UTILITY_GL_LEVEL_ONE", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityGlLevelTwoCodes({ commit, rootGetters }) {
    let payload = {
      glLevel2BankCode: rootGetters["user/getUserBankCode"],
      readAll: "YES"
    };
    CreateGlLevel2Service.allGlLevel2(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_GL_LEVEL_TWO", responseData.data);
          } else {
            commit("SET_UTILITY_GL_LEVEL_TWO", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityRoles({ commit, rootGetters }) {
    let payload = {
      roleBankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };
    // start loader
    commit("SET_LOADING_THREE", true);

    RoleService.allRoles(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_ROLES", responseData.data);
          } else {
            commit("SET_UTILITY_ROLES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityPrivileges({ commit }) {
    let payload = {
      readAll: "YES"
    };

    PrivilegeService.allPrivileges(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_PRIVILEGES", responseData.data);
          } else {
            commit("SET_UTILITY_PRIVILEGES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllModules({ commit }) {
    // build request body
    const payload = {
      moduleId: "",
      readAll: "YES"
    };
    commit("SET_LOADING_FOUR", true);
    return ModuleService.allModules(payload)
      .then(response => {
        commit("SET_LOADING_FOUR", false);
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // filtering the data to conform to the stats standard
          // then set it to state
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_MODULES", responseData.data);
          } else {
            commit("SET_UTILITY_MODULES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllTransactionCode({ commit, rootGetters }) {
    // build request body
    const payload = {
      trnBankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };

    // start loading
    commit("SET_LOADING", true);

    return TransactionCodeService.allTransactionCodes(payload)
      .then(response => {
        let responseData = response.data;

        // stop loading
        commit("SET_LOADING", false);

        if (responseData.responseCode === "00") {
          // filtering the data to conform to the stats standard
          // then set it to state
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_TRANSACTION_CODES", responseData.data);
          } else {
            commit("SET_UTILITY_TRANSACTION_CODES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllChargeProducts({ commit }) {
    // build request body
    const payload = {
      productBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      productType: "CH"
    };

    // start loading
    StoreUtils.dispatch(StoreUtils.actions.loader.showComponentLoader, true);

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;

        // stop loading
        StoreUtils.dispatch(
          StoreUtils.actions.loader.showComponentLoader,
          false
        );

        if (responseData.responseCode === "00") {
          // filtering the data to conform to the stats standard
          // then set it to state
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_CHARGE_PRODUCTS", responseData.data);
          } else {
            commit("SET_UTILITY_CHARGE_PRODUCTS", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllTellers({ commit, rootGetters }) {
    // build request body
    const payload = {
      tellerBankCode: rootGetters["user/getUserBankCode"],
      readAll: "No"
    };

    // start loading
    commit("SET_LOADING_TWO", true);

    return TellerService.allTellers(payload)
      .then(response => {
        let responseData = response.data;

        // stop loading
        commit("SET_LOADING_TWO", false);

        if (responseData.responseCode === "00") {
          // filtering the data to conform to the stats standard
          // then set it to state
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_TELLERS", responseData.data);
          } else {
            commit("SET_UTILITY_TELLERS", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllInternalAccounts({ commit, rootGetters }) {
    // build request body
    const payload = {
      accountBankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };

    // start loading
    commit("SET_LOADING_FIVE", true);

    return InternalAccountService.allInternalAccounts(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FIVE", false);
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_ALL_INTERNAL_ACCOUNTS", responseData.data);
          } else {
            commit("SET_ALL_INTERNAL_ACCOUNTS", []);
          }
        }
      })
      .catch(error => {
        // stop loader
        commit("SET_LOADING_FIVE", false);
        throw error;
      });
  },

  generateNewTransactionRef({ commit, rootGetters }) {
    const userId = rootGetters["user/getUserId"];
    commit("GENERATE_NEW_TRANSACTION_REF", userId);
  },

  fetchAllAccountClass({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_SIX", true);

    // build request body
    const payload = {
      productBankCode: rootGetters["user/getUserBankCode"],
      productType: "AC"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_SIX", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit(
            "SET_UTILITY_ACCOUNT_CLASS",
            responseData.data.map(accountProduct => accountProduct.Code)
          );
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  fetchAllAccountClass2({ commit }) {
    // start loading
    commit("SET_LOADING_SIX", true);

    // build request body
    const payload = {
      productBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      productType: "AC"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_SIX", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit("SET_UTILITY_ACCOUNT_CLASS_2", responseData.data);
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  fetchAllLoanProducts({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_NINE", true);

    // build request body
    const payload = {
      productBankCode: rootGetters["user/getUserBankCode"],
      productType: "LO"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_NINE", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit("SET_UTILITY_LOAN_PRODUCT", responseData.data);
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_NINE", false);

        throw error;
      });
  },

  fetchAllFundsTransferProduct({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_SEVEN", true);

    // build request body
    const payload = {
      productBankCode: rootGetters["user/getUserBankCode"],
      productType: "FT"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_SEVEN", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit(
            "SET_UTILITY_PRODUCT_CODE",
            responseData.data.map(ftProduct => ftProduct.Code)
          );
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_SEVEN", false);

        throw error;
      });
  },

  fetchAllFundsTransferProduct2({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_SEVEN", true);

    // build request body
    const payload = {
      productBankCode: rootGetters["user/getUserBankCode"],
      productType: "FT"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_SEVEN", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit("SET_UTILITY_FT_PRODUCT", responseData.data);
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_SEVEN", false);

        throw error;
      });
  },

  fetchAllUsers({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_EIGHT", true);

    // build request body
    const payload = {
      userBankCode: rootGetters["user/getUserBankCode"],
      source: "CBA",
      readAll: "NO"
    };

    return BankUserService.allUsers(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_EIGHT", false);
        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_ALL_USERS", responseData.data);
          } else {
            commit("SET_UTILITY_ALL_USERS", []);
          }
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_EIGHT", false);

        throw error;
      });
  },

  // Reset The State On Log Out
  // Update As The Modules Increase
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
