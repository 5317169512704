<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CashDepositCheckerQueueInfoModal",
  data() {
    return {
      modalOpen: false,
      rejectModalOpen: false,
      rejectVodalFormBody: {
        rejectReason: ""
      }
    };
  },
  props: {
    currentCustomer: {
      type: Object,
      required: true
    },
    currentCustomerJson: {
      type: Object,
      required: true
    }
  },
  // components: { carousel },
  computed: {
    ...mapState(["fundsTransfer", "branch"])
  },
  validations: {
    rejectVodalFormBody: {
      rejectReason: { required }
    }
  },
  methods: {
    rejectChange() {
      // -close more info modal
      this.closeModal();
      // -open reject modal
      this.openRejectModal();
    },
    rejectChangeFinal() {
      // -build reject payload
      // -dont forget to add reject reason to th reject payload
      let payload = {
        cqId: this.currentCustomer.cqId,
        cqJson: JSON.stringify(this.currentCustomerJson),
        cqType: this.currentCustomer.cqType,
        cqMode: "REJECTED",
        cqReason: this.rejectVodalFormBody.rejectReason,
        cqBankCode: this.$store.getters["user/getUserBankCode"],
        cqChecker: this.$store.getters["user/getUserId"]
      };

      this.$store.dispatch("branch/setRejectLoading", true, { root: true });
      this.$store
        .dispatch("fundsTransfer/checkerAuthorizeCashDeposit", payload, {
          root: true
        })
        .then(responseData => {
          this.$store.dispatch("branch/setRejectLoading", false, {
            root: true
          });
          if (responseData.responseCode === "00") {
            this.closeRejectModal();

            this.$emit("authorizeSuccessful", responseData);
          }
        })
        .catch(error => {
          throw error;
        });
    },
    authorizeChange() {
      let payload = {
        cqId: this.currentCustomer.cqId,
        cqJson: JSON.stringify(this.currentCustomerJson),
        cqType: this.currentCustomer.cqType,
        cqMode: "AUTHORIZE",
        cqBankCode: this.$store.getters["user/getUserBankCode"],
        cqChecker: this.$store.getters["user/getUserId"]
      };

      this.$store
        .dispatch("fundsTransfer/checkerAuthorizeCashDeposit", payload, {
          root: true
        })
        .then(responseData => {
          if (responseData.responseCode === "00") {
            this.closeModal();
            this.$emit("authorizeSuccessful", responseData);
          }
        })
        .catch(error => {
          throw error;
        });
    },
    viewMoreCustomerInfo() {
      this.openModal();
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    openRejectModal() {
      this.rejectModalOpen = true;
    },
    closeRejectModal() {
      this.rejectModalOpen = false;
    }
  }
};
</script>

<template>
  <div>
    <!--        control-->
    <a
      @click="viewMoreCustomerInfo()"
      class="view-customer-info-btn"
      v-b-tooltip.hover.right
      title="View More Info"
    >
      <i class="bx bx-info-circle"></i>
    </a>
    <!--    control END-->

    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="500"
      :height="450"
      measure="px"
      animation="zoom"
      @hide="modalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <!--          <h4 class="card-title mb-4">Customer Info</h4>-->
          <div>
            <div class="view-more-customer-info">
              <div class="view-more-customer-info-wrap">
                <div class="view-more-top">
                  <h4 class="card-title mb-4">Cash Deposit Info</h4>

                  <div class="row">
                    <div class="col-sm-6">
                      <p class="queue-title">Maker</p>
                      <p class="queue-value">
                        {{ currentCustomer.cqMaker }}
                      </p>
                    </div>

                    <div class="col-sm-6">
                      <p class="queue-title">Created At</p>
                      <p class="queue-value">
                        {{
                          currentCustomer.cqCreatedAt | formatDateRelativeToNow
                        }}
                      </p>
                    </div>

                    <div class="col-sm-6">
                      <p class="queue-title">Bank Code</p>
                      <p class="queue-value">
                        {{ currentCustomer.cqBankCode }}
                      </p>
                    </div>

                    <div class="col-sm-6">
                      <p class="queue-title">Status</p>
                      <p class="queue-value">
                        <AppStatusBadge
                          :status-value="currentCustomer.cqStatus"
                        />
                      </p>
                    </div>
                  </div>
                </div>

                <div class="view-more-base">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <tbody>
                        <tr
                          v-for="(value, key) in currentCustomerJson"
                          :key="key"
                        >
                          <td>
                            <p class="view-more-base-tag">
                              {{ key }}
                            </p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{ value }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- end table-responsive -->
                </div>

                <p
                  v-if="currentCustomer.cqStatus === 'PENDING'"
                  class="text-right"
                >
                  <span
                    v-if="fundsTransfer.moduleLoadingTen"
                    class="formLoaderHold"
                  >
                    <BaseComponentLoader />
                  </span>
                  <span v-else>
                    <span class="reject-btn mr-2">
                      <button
                        @click="rejectChange()"
                        class="btn btn-light mr-1"
                      >
                        <i
                          class="dripicons-cross font-size-16 align-middle mr-2"
                        ></i>
                        Reject
                      </button>
                    </span>
                    <span class="authorize-btn">
                      <button
                        @click="authorizeChange()"
                        class="btn btn-primary mr-1"
                      >
                        <i
                          class="dripicons-checkmark font-size-16 align-middle mr-2"
                        ></i>
                        Authorize
                      </button>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vodal>

    <vodal
      :show="rejectModalOpen"
      :width="350"
      :height="300"
      measure="px"
      animation="zoom"
      @hide="rejectModalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <!--          <h4 class="card-title mb-4">Customer Info</h4>-->
          <div>
            <div class="view-more-customer-info">
              <div class="view-more-customer-info-wrap">
                <div class="view-more-top">
                  <h4 class="card-title mb-2">Reject Reason</h4>
                </div>

                <div class="view-more-base">
                  <form @submit.prevent="rejectChangeFinal">
                    <div class="row">
                      <div class="col-sm-12">
                        <div>
                          <BaseInput
                            :inputType="'text'"
                            v-model="rejectVodalFormBody.rejectReason"
                            placeholder="Reason"
                            :hasPreIcon="false"
                            :label="'Reason'"
                            :class="{
                              error: $v.rejectVodalFormBody.rejectReason.$error
                            }"
                            @blur="$v.rejectVodalFormBody.rejectReason.$touch()"
                          >
                          </BaseInput>

                          <template
                            v-if="$v.rejectVodalFormBody.rejectReason.$error"
                          >
                            <p
                              v-if="
                                !$v.rejectVodalFormBody.rejectReason.required
                              "
                              class="form-error-message"
                            >
                              Reason is required
                            </p>
                          </template>
                        </div>
                      </div>

                      <p class="full-width text-right pr-2">
                        <span
                          v-if="branch.rejectLoading"
                          class="formLoaderHold"
                        >
                          <BaseComponentLoader />
                        </span>
                        <span v-else>
                          <button type="submit" class="btn btn-primary mr-1">
                            Reject
                          </button>
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vodal>
  </div>
</template>

<style scoped>
.view-customer-info-btn {
  position: relative;
  height: 20px;
  width: 20px;
  display: block;
  padding: 2px 2px;
  margin-top: 0;
  background-color: #795548;
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.view-customer-info-btn i {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}
p {
  color: #495057;
}
</style>
