var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createVault.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.createVaultFormBody.vaultAccountNumber.$error
              },attrs:{"inputType":'text',"placeholder":"Account Number","hasPreIcon":false,"label":'Account Number'},on:{"blur":function($event){return _vm.$v.createVaultFormBody.vaultAccountNumber.$touch()}},model:{value:(_vm.createVaultFormBody.vaultAccountNumber),callback:function ($$v) {_vm.$set(_vm.createVaultFormBody, "vaultAccountNumber", $$v)},expression:"createVaultFormBody.vaultAccountNumber"}}),(_vm.$v.createVaultFormBody.vaultAccountNumber.$error)?[(!_vm.$v.createVaultFormBody.vaultAccountNumber.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Vault Account Number is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.createVaultFormBody.tillAccountNumber.$error
              },attrs:{"range":false,"placeholder":"Vault Teller Number","label":'Vault Teller Number',"hasPreIcon":false},on:{"blur":function($event){return _vm.$v.createBankFormBody.tillAccountNumber.$touch()}},model:{value:(_vm.createVaultFormBody.tillAccountNumber),callback:function ($$v) {_vm.$set(_vm.createVaultFormBody, "tillAccountNumber", $$v)},expression:"createVaultFormBody.tillAccountNumber"}}),(_vm.$v.createVaultFormBody.tillAccountNumber.$error)?[(!_vm.$v.createVaultFormBody.tillAccountNumber.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Till Account Number is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.createVaultFormBody.amount.$error
              },attrs:{"range":false,"placeholder":"Amount","label":'Amount',"hasPreIcon":false},on:{"blur":function($event){return _vm.$v.createBankFormBody.amount.$touch()}},model:{value:(_vm.createVaultFormBody.amount),callback:function ($$v) {_vm.$set(_vm.createVaultFormBody, "amount", $$v)},expression:"createVaultFormBody.amount"}}),(_vm.$v.createVaultFormBody.amount.$error)?[(!_vm.$v.createVaultFormBody.amount.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Amount is required ")]):_vm._e()]:_vm._e()],2)])]),_c('p',{staticClass:"text-left"},[(_vm.vault.moduleLoading)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Create Vault ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }