<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submitForm">
        <div class="stage-info text-left mb-3">
          <div class="row">
            <div class="col-12">
              <h4 class="main card-title mb-4">
                <span class="no">1.</span>
                Set up a Report
              </h4>
              <p class="sub text-muted font-13">
                Set up a report however you want it. <br />
                By using one or joining any two of (tables and existing
                reports). <br />
                Set their join contract and the columns you want the report to
                contain
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.reportName"
                placeholder="Report Name"
                :hasPreIcon="false"
                :label="'Report Name'"
                :class="{ error: $v.formBody.reportName.$error }"
                @blur="$v.formBody.reportName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.reportName.$error">
                <p
                  v-if="!$v.formBody.reportName.required"
                  class="form-error-message"
                >
                  Report Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Report Category</label>
                <multiselect
                  v-model="formBody.reportCategory"
                  :options="reportCategoriesOption"
                  @close="$v.formBody.reportCategory.$touch()"
                  placeholder="Report Category"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.reportCategory.$error">
                <p
                  v-if="!$v.formBody.reportCategory.required"
                  class="form-error-message"
                >
                  Report Category is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.reportDescription"
                placeholder="Description"
                :hasPreIcon="false"
                :label="'Report Description'"
                :class="{ error: $v.formBody.reportDescription.$error }"
                @blur="$v.formBody.reportDescription.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.reportDescription.$error">
                <p
                  v-if="!$v.formBody.reportDescription.required"
                  class="form-error-message"
                >
                  Description is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <label>Report Type</label>
              <p class="counter-party-radio">
                <span>
                  <span class="custom-control custom-radio mb-3">
                    <input
                      type="radio"
                      id="customRadio1"
                      class="custom-control-input"
                      value="single-source"
                      v-model="formBody.reportType"
                    />
                    <label class="custom-control-label" for="customRadio1"
                      >Single Source</label
                    >
                  </span>
                </span>

                <span>
                  <span class="custom-control custom-radio mb-3">
                    <input
                      type="radio"
                      id="customRadio2"
                      class="custom-control-input"
                      value="join-source"
                      v-model="formBody.reportType"
                    />
                    <label class="custom-control-label" for="customRadio2"
                      >Join Source</label
                    >
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Next
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../../utils/BaseUtils/StoreUtils";
import { required } from "vuelidate/lib/validators";

export default {
  name: "BasicInfoForm",
  data() {
    return {
      formBody: this.freshFormBodyObject(),
      counterPartyStatus: "self"
    };
  },
  computed: {
    ...mapState(["rpt", "loader"]),
    reportCategoriesOption() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rptUtilities.GET_REPORT_CATEGORIES_OPTION
      );
    },
    rptStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE);
    }
  },
  validations: {
    formBody: {
      reportName: { required },
      reportCategory: { required },
      reportDescription: { required }
    }
  },
  created() {
    StoreUtils.dispatch(
      StoreUtils.actions.rptUtilities.FETCH_REPORT_CATEGORIES
    );
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.dispatch(
          StoreUtils.actions.rpt.BUILD_CREATE_REPORT_REQUEST_BODY,
          this.formBody
        );
      }
    },

    freshFormBodyObject() {
      return {
        source: "WEB"
      };
    }
  }
};
</script>

<style scoped></style>
