<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <div class="col-xl-3">
        <BranchStatsPanel />
      </div>
      <!-- end col -->
      <div class="col-xl-9">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">All TransferToVault</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <TransferToVaultForm></TransferToVaultForm>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Transfer To Vault Component
 */


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";

export default {
  page: {
    title: "Transfer To Vault",
    meta: [{ name: "Transfer To Vault", content: window.__env.app.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "TransferToVault",

      items: [
        {
          text: "TransferToVault",
          href: "/"
        },
        {
          text: "TransferToVault",
          active: true
        }
      ],
    };
  },
  methods: {}
}
</script>
