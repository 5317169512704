var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateStatus.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
              error: _vm.$v.branchStatusUpdateFormBody.branchBankCode.$error
            },attrs:{"inputType":'text',"placeholder":"Branch Bank Code","hasPreIcon":false,"label":'Branch Bank Code'},on:{"blur":function($event){return _vm.$v.branchStatusUpdateFormBody.branchBankCode.$touch()}},model:{value:(_vm.branchStatusUpdateFormBody.branchBankCode),callback:function ($$v) {_vm.$set(_vm.branchStatusUpdateFormBody, "branchBankCode", $$v)},expression:"branchStatusUpdateFormBody.branchBankCode"}}),(_vm.$v.branchStatusUpdateFormBody.branchBankCode.$error)?[(!_vm.$v.branchStatusUpdateFormBody.branchBankCode.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Bank Code is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
              error: _vm.$v.branchStatusUpdateFormBody.branchCode.$error
            },attrs:{"inputType":'text',"placeholder":"Branch Code","hasPreIcon":false,"label":'Branch Code'},on:{"blur":function($event){return _vm.$v.branchStatusUpdateFormBody.branchCode.$touch()}},model:{value:(_vm.branchStatusUpdateFormBody.branchCode),callback:function ($$v) {_vm.$set(_vm.branchStatusUpdateFormBody, "branchCode", $$v)},expression:"branchStatusUpdateFormBody.branchCode"}}),(_vm.$v.branchStatusUpdateFormBody.branchCode.$error)?[(!_vm.$v.branchStatusUpdateFormBody.branchCode.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Code is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseSelect',{class:{
              error: _vm.$v.branchStatusUpdateFormBody.branchStatus.$error
            },attrs:{"type":'date',"range":false,"label":'Branch Status',"placeholder":'Branch Status',"options":_vm.options,"hasPreIcon":false},on:{"blur":function($event){return _vm.$v.branchStatusUpdateFormBody.branchStatus.$touch()}},model:{value:(_vm.branchStatusUpdateFormBody.branchStatus),callback:function ($$v) {_vm.$set(_vm.branchStatusUpdateFormBody, "branchStatus", $$v)},expression:"branchStatusUpdateFormBody.branchStatus"}}),(_vm.$v.branchStatusUpdateFormBody.branchStatus.$error)?[(!_vm.$v.branchStatusUpdateFormBody.branchStatus.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Status is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('p',{staticClass:"text-right"},[(_vm.branch.moduleLoadingTwo)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('br'),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Update Status ")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }