<template>
  <div>
    <!--        control-->
    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
      <template v-slot:button-content>
        <i class="mdi mdi-dots-horizontal font-size-18" />
      </template>

      <b-dropdown-item @click="lienModalOpen = true">
        <i class="fas fa-pencil-alt text-success mr-1" /> Remove lien Amount
      </b-dropdown-item>
    </b-dropdown>

    <!--        Edit Customer modal-->
    <!--        Create Lien modal-->
    <vodal
      :show="lienModalOpen"
      :width="400"
      :height="400"
      measure="px"
      animation="zoom"
      @hide="lienModalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Edit Lien Amount</h4>

          <DeleteLienForm
            :edit-form-body="currentCustomer"
            @editSuccessful="closeLienModal()"
          />
        </div>
      </div>
    </vodal>

    <!--        Assign Terminal modal-->

    <!--        Close Account modal-->
  </div>
</template>

<script>
import DeleteLienForm from "../../Forms/Customer/DeleteLienForm";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
export default {
  name: "DeleteLienModal",
  components: {
    DeleteLienForm
  },
  data() {
    return {
      modalOpen: false,
      assignTerminalModalOpen: false,
      altModalOpen: false,
      lienModalOpen:false
    };
  },
  props: {
    currentCustomer: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentCustomerAccountAccruals() {
      return StoreUtils.rootGetter(StoreUtils.getters.table.GET_ALT_TABLE_DATA);
    },
    currentCustomerAccount() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.table.GET_CURRENT_TABLE_OBJECT
      );
    },
    userBankTypeIsAgency() {
      return StoreUtils.rootGetter("user/getUserBankType") === "AGENCY";
    }
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    openLienModal() {
      this.lienModalOpen = true
    },
    closeLienModal() {
      this.lienModalOpen = false
    },
    openTerminalAssignmentModal() {
      this.assignTerminalModalOpen = true;
    },
    closeTerminalAssignmentModal() {
      this.assignTerminalModalOpen = false;
    },
    openAltModal() {
      this.altModalOpen = true;
      StoreUtils.dispatch("customer/fetchAccountAccruals", {
        accountNumber: this.currentCustomer.accountNumber
      });
    },
    closeAltModal() {
      this.altModalOpen = false;
    }
  }
};
</script>

<style scoped></style>
