<template>
  <div>
    <div v-if="rptStage === 1">
      <BasicInfoForm />
    </div>
    <div v-if="rptStage === 2">
      <ReportBaseForm />
    </div>
    <div v-if="rptStage === 3">
      <ReportCapForm />
    </div>
    <div v-if="rptStage === 4">
      <JoinContractForm />
    </div>
    <div v-if="rptStage === 5">
      <ReportConditionForm />
    </div>
    <div v-if="rptStage === 6">
      <ReportColumnsForm />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import BasicInfoForm from "../../../Forms/Report/RPT/CreateReport/BasicInfoForm";
import ReportBaseForm from "../../../Forms/Report/RPT/CreateReport/ReportBaseForm";
import ReportCapForm from "../../../Forms/Report/RPT/CreateReport/ReportCapForm";
import JoinContractForm from "../../../Forms/Report/RPT/CreateReport/JoinContractForm";
import ReportConditionForm from "../../../Forms/Report/RPT/CreateReport/ReportConditionForm";
import ReportColumnsForm from "../../../Forms/Report/RPT/CreateReport/ReportColumnsForm";

export default {
  name: "CreateReportCard",
  components: {
    ReportColumnsForm,
    ReportConditionForm,
    JoinContractForm,
    ReportCapForm,
    ReportBaseForm,
    BasicInfoForm
  },
  computed: {
    ...mapState(["ft", "loader"]),
    rptStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE);
    }
  }
};
</script>

<style scoped></style>
