<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Supposed Form -->
            <div class="form-body">
              <RequestAccountStatementForm />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>

import Layout from "../../../router/layouts/main";
import PageHeader from "../../../components/AppComponents/page-header";
import RequestAccountStatementForm from "../../../components/Kleyton/Forms/Report/RPT/RequestAccountStatementForm";
export default {
  name: "RequestAccountStatement",
  page: {
    title: "Report",
    meta: [{ name: "Create Report", content: window.__env.app.description }]
  },
  components: {
    RequestAccountStatementForm,
    Layout,
    PageHeader
  },
  data() {
    return {
      name: "RequestAccountStatement",
      title: "Request Account Statement",
      items: [
        {
          text: "Account Statement",
          href: "/"
        },
        {
          text: "Request Account Statement",
          active: true
        }
      ]
    };
  }
};
</script>

<style scoped></style>
