import BankService from "../../services/oldServices/BankService";
import BranchService from "../../services/oldServices/BranchService";
import RoleService from "../../services/oldServices/RoleService";
import PrivilegeService from "../../services/oldServices/PrivilegeService";
import ModuleService from "../../services/oldServices/ModuleService";
import TransactionCodeService from "../../services/oldServices/TransactionCodeService";
import TellerService from "../../services/oldServices/TellerService";
import InternalAccountService from "../../services/oldServices/InternalAccountService";
import CreateGlLevel1Service from "../../services/oldServices/GlLevel1Service";
import CreateGlLevel2Service from "../../services/oldServices/GlLevel2Service";
import ProductService from "../../services/oldServices/ProductService";
import BankUserService from "../../services/oldServices/BankUserService";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import NewBankService from "../../services/newServices/BankService";
import AppUtils from "../../utils/BaseUtils/AppUtils";
import AppUtilitiesService from "@/services/newServices/AppUtilitiesService";

const bankService = new NewBankService();
const appUtilitiesService = new AppUtilitiesService();

const getDefaultState = () => {
  return {
    allBanks: [],
    allBranches: [],
    allRoles: [],
    allPrivileges: [],
    allModules: [],
    allTellers: [],
    allTransactionCodes: [],
    allChargeProducts: [],
    allInternalAccounts: [],
    allGlCategories: [
      {
        code: "10",
        description: "FIXED ASSETS"
      },
      {
        code: "20",
        description: "CURRENT ASSETS"
      },
      {
        code: "30",
        description: "EQUITIES"
      },
      {
        code: "40",
        description: "LIABILITIES"
      },
      {
        code: "50",
        description: "REVENUE"
      },
      {
        code: "60",
        description: "EXPENSE"
      }
    ],
    allGlLevel1: [],
    allGlLevel2: [],
    allAccountClass: [],
    allAccountClass2: [],
    allLoanProduct: [],
    allUsers: [],
    allBranchMangers: [],
    currencyOptions: ["NGN", "GBP", "USD"],
    countryOption: AppUtils.allCountriesArray(),
    accountTypeOptions: ["SAVINGS", "CURRENT"],
    productCodeOptions: [],
    ftProducts: [],
    bankList: [],
    moduleLoading: false,
    moduleLoadingTwo: true,
    moduleLoadingThree: false,
    moduleLoadingFour: false,
    moduleLoadingFive: false,
    moduleLoadingSix: true,
    moduleLoadingSeven: true,
    moduleLoadingEight: true,
    moduleLoadingNine: true,
    moduleLoadingTen: true,
    currentTransactionRef: "",
    currentCustomerAccounts: [
      {
        accountChecker: "0000000001",
        accountUpdatedDate: "2020-05-29T13:26:52.253",
        accountName: "JONES JAMES JEFFERSON",
        accountInterestRate: null,
        accountPenalty: 0,
        accountCurrency: "NGN",
        accountEndDate: null,
        accountSort: "000000001",
        accountOverdraftStatus: "NONE",
        accountOverdraft: 0,
        accountStatus: "ACTIVE",
        accountMaker: "0000000001",
        accountCreatedDate: "2020-05-29T13:26:52.253",
        accountCustomerId: "0000000001",
        accountOverdraftIssuedDate: null,
        accountDailyDebit: 10.75,
        accountLedgerBalance: 0,
        accountDateOpened: "2020-05-29T13:26:52.253",
        accountBankCode: "00004",
        accountGlLevel1Code: "4001",
        accountMonthlyCredit: 0,
        accountMonthlyDebit: 10.75,
        accountType: "CURRENT",
        accountAllowNegativeBalance: "NO",
        accountOverdraftExpiry: null,
        accountDailyCredit: 0,
        accountNumber: "0000000011",
        accountLastAccessTimestamp: "2020-05-29T13:26:52.253",
        accountDateClosed: null,
        accountGlCategory: "40",
        accountOverdraftRate: 0,
        accountBranchCode: "0001",
        accountId: 1,
        accountRate: 0,
        accountStartDate: null,
        accountClass: "CT0003",
        accountGlLevel2Code: "400102",
        accountParent: null,
        accountBalance: 2639.25,
        accountInterest: 0
      },
      {
        accountChecker: "0000000001",
        accountUpdatedDate: "2020-05-29T13:27:29.547",
        accountName: "JONES JAMES JEFFERSON",
        accountInterestRate: null,
        accountPenalty: 0,
        accountCurrency: "NGN",
        accountEndDate: null,
        accountSort: "000000002",
        accountOverdraftStatus: "NONE",
        accountOverdraft: 0,
        accountStatus: "ACTIVE",
        accountMaker: "0000000001",
        accountCreatedDate: "2020-05-29T13:27:29.547",
        accountCustomerId: "0000000001",
        accountOverdraftIssuedDate: null,
        accountDailyDebit: 0,
        accountLedgerBalance: 0,
        accountDateOpened: "2020-05-29T13:27:29.547",
        accountBankCode: "00004",
        accountGlLevel1Code: "4001",
        accountMonthlyCredit: 0,
        accountMonthlyDebit: 0,
        accountType: "SAVINGS",
        accountAllowNegativeBalance: "NO",
        accountOverdraftExpiry: null,
        accountDailyCredit: 0,
        accountNumber: "0000000022",
        accountLastAccessTimestamp: "2020-05-29T13:27:29.547",
        accountDateClosed: null,
        accountGlCategory: "40",
        accountOverdraftRate: 0,
        accountBranchCode: "0001",
        accountId: 2,
        accountRate: 0,
        accountStartDate: null,
        accountClass: "ST0002",
        accountGlLevel2Code: "400101",
        accountParent: null,
        accountBalance: 2779.07,
        accountInterest: 0
      },
      {
        accountChecker: "SYSTEM",
        accountUpdatedDate: "2021-03-06T02:33:39.960",
        accountName: "JONES JAMES JEFFERSON",
        accountInterestRate: 10,
        accountPenalty: 0,
        accountCurrency: "NGN",
        accountEndDate: "2021-03-03",
        accountSort: "200329711",
        accountOverdraftStatus: "NONE",
        accountOverdraft: 0,
        accountStatus: "ACTIVE",
        accountMaker: "SYSTEM",
        accountCreatedDate: "2021-03-01T14:05:49.040",
        accountCustomerId: "0000000001",
        accountOverdraftIssuedDate: null,
        accountDailyDebit: 0,
        accountLedgerBalance: 0,
        accountDateOpened: "2021-03-01T14:05:49.040",
        accountBankCode: "00004",
        accountGlLevel1Code: "4001",
        accountMonthlyCredit: 0,
        accountMonthlyDebit: 0,
        accountType: "SAVINGS",
        accountAllowNegativeBalance: "NO",
        accountOverdraftExpiry: null,
        accountDailyCredit: 0,
        accountNumber: "2003297111",
        accountLastAccessTimestamp: "2021-03-01T14:05:49.040",
        accountDateClosed: null,
        accountGlCategory: "40",
        accountOverdraftRate: 0,
        accountBranchCode: "0001",
        accountId: 347,
        accountRate: 10,
        accountStartDate: "2021-03-01",
        accountClass: "FFF",
        accountGlLevel2Code: "400101",
        accountParent: "0000000011",
        accountBalance: 0,
        accountInterest: 0
      }
    ],
    businessTypes: []
  };
};

let generateUUIDRef = () => {
  let d = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const state = getDefaultState();

export const getters = {
  getBusinessTypes: state => {
    return state.businessTypes.map(
      businessType => `${ businessType.value }`
    );
  },
  getCustomerAccountAccountUtilityOption: state => {
    const allAccounts = state.currentCustomerAccounts;
    return allAccounts.map(
      account => `${account.accountNumber} ( ₦${account.accountBalance} )`
    );
  },
  getCustomerAccountNumberFromOptionUtility: state => chosenOption => {
    const allAccounts = state.currentCustomerAccounts;
    let option = allAccounts.filter(function(account) {
      return (
        `${account.accountNumber} ( ₦${account.accountBalance} )` ===
        chosenOption
      );
    });
    return option[0].accountNumber;
  },

  getBankUtilityOption: state => {
    const allBanks = state.allBanks;
    return allBanks.map(bank => bank.bankName + " (" + bank.bankCode + ")");
  },
  getBankCodeFromOptionUtility: state => chosenOption => {
    const allBanks = state.allBanks;
    let opt = allBanks.filter(function(bank) {
      return bank.bankName + " (" + bank.bankCode + ")" === chosenOption;
    });

    return opt[0].bankCode;
  },
  getBankNameFromCodeUtility: state => chosenOption => {
    const allBanks = state.allBanks;

    let opt = allBanks.filter(function(bank) {
      return bank.bankCode === chosenOption;
    });

    return opt[0].bankName;
  },

  getBankLogoFromCodeUtility: state => chosenOption => {
    const allBanks = state.allBanks;

    let opt = allBanks.filter(function(bank) {
      return bank.bankCode === chosenOption;
    });

    return opt[0].bankLogo;
  },

  getBranchesUtilityOption: state => {
    const allBranches = state.allBranches;
    return allBranches.map(
      branch =>
        branch.branchName +
        " (" +
        branch.branchBankCode +
        ") - " +
        branch.branchState + " | " + branch.branchCode

    );
  },
  getBranchCodeFromOptionUtility: state => chosenOption => {
    const allBranches = state.allBranches;
    let opt = allBranches.filter(function(branch) {
      return (
        branch.branchName +
          " (" +
          branch.branchBankCode +
          ") - " +
          branch.branchState ===
        chosenOption
      );
    });

    return opt[0].branchCode;
  },

  getGlLevelOneUtilityOption: state => {
    return state.allGlLevel1.map(glCategory => {
      return `${glCategory.glLevel1Code} - (${glCategory.glLevel1Description})`;
    });
  },
  getGlLevelOneCodeFromOption: state => chosenOption => {
    return state.allGlLevel1.filter(glCategory => {
      return (
        `${glCategory.glLevel1Code} - (${glCategory.glLevel1Description})` ===
        chosenOption
      );
    })[0].glLevel1Code;
  },
  getGlCategoryCode: state => {
    const allGlLevel1 = state.allGlLevel1;
    return allGlLevel1.map(glLevel1 => glLevel1.glLevel1CategoryCode);
  },
  getGlLevelOneCodeFromOptionUtility: state => chosenOption => {
    const allGlLevel1 = state.allGlLevel1;
    let opt = allGlLevel1.filter(function(glLevel1) {
      return (
        glLevel1.glLevel1Description + " - (" + glLevel1.glLevel1Code + ")" ===
        chosenOption
      );
    });

    return opt[0].glLevel1Code;
  },

  getGlCategoryCodeOption: state => {
    return state.allGlCategories.map(glCategory => {
      return `${glCategory.code} - (${glCategory.description})`;
    });
  },
  getGlCategoryCodeFromOption: state => chosenOption => {
    return state.allGlCategories.filter(glCategory => {
      return (
        `${glCategory.code} - (${glCategory.description})` === chosenOption
      );
    })[0].code;
  },

  getGlLevelTwoUtilityOption: state => {
    const allGlLevel2 = state.allGlLevel2;
    return allGlLevel2.map(
      glLevel2 =>
        glLevel2.glLevel2Description + " - (" + glLevel2.glLevel2Code + ")"
    );
  },
  getGlLevelTwoCodeFromOptionUtility: state => chosenOption => {
    const allGlLevel2 = state.allGlLevel2;
    let opt = allGlLevel2.filter(function(glLevel2) {
      return (
        glLevel2.glLevel2Description + " - (" + glLevel2.glLevel2Code + ")" ===
        chosenOption
      );
    });
    return opt[0].glLevel2Code;
  },

  getRolesUtilityOption: state => {
    const allRoles = state.allRoles;
    return allRoles.map(role => role.roleName + " | " + role.roleId);
  },
  getRoleIdFromOptionUtility: state => chosenOption => {
    const allRoles = state.allRoles;
    let opt = allRoles.filter(function(role) {
      return role.roleName === chosenOption;
    });

    return opt[0].roleId;
  },
  getAllMainRoles: state => {
    const allRoles = state.allRoles;
    return allRoles
  },

  getPrivilegeUtilityOption: state => {
    const allPrivileges = state.allPrivileges;
    return allPrivileges.map(
      privilege =>
        privilege.privilegeType + " - " + privilege.privilegeModuleName
    );
  },
  getPrivilegeSeparatedByRoles: state => {
    const allPrivileges = state.allPrivileges;
    return allPrivileges.reduce((acc, currValue) => {
      const module = currValue.privilegeModuleName;
      let modulePrivileges;
      if (acc[module]) {
        modulePrivileges = acc[module];
        modulePrivileges.push(currValue);
      } else {
        modulePrivileges = [currValue];
      }
      return {
        ...acc,
        [module]: modulePrivileges
      };
    }, {});
  },
  getPrivilegeIdFromOptionUtility: state => chosenOption => {
    const allPrivileges = state.allPrivileges;
    let opt = allPrivileges.filter(privilege => {
      return (
        privilege.privilegeType + " - " + privilege.privilegeModuleName ===
        chosenOption
      );
    });
    return opt.privilegeId;
  },
  getPrivilegeTypeUtilityOption: () => {
    return ["Create", "Read", "Update", "Maker", "Checker"];
  },

  getModuleUtilityOption: state => {
    const allModules = state.allModules;
    return allModules.map(module => module.moduleName.toLowerCase());
  },
  getModuleIdFromOptionUtility: state => chosenOption => {
    const allModules = state.allModules;
    let matchedModule = allModules.filter(module => {
      return module.moduleName === chosenOption;
    });

    return matchedModule[0].moduleId;
  },

  getAllChargeProductUtilityOption: state => {
    const allChargeProducts = state.allChargeProducts;
    return allChargeProducts.map(
      chargeProduct =>
        "(" + chargeProduct.Code + ") " + chargeProduct.Description
    );
  },
  getChargeProductCodeFromOptionUtility: state => chosenOption => {
    const allChargeProducts = state.allChargeProducts;
    let opt = allChargeProducts.filter(function(chargeProduct) {
      return (
        "(" + chargeProduct.Code + ") " + chargeProduct.Description ===
        chosenOption
      );
    });

    return opt[0].Code;
  },

  getTransactionCodeUtilityOption: state => {
    const allTransactionCodes = state.allTransactionCodes;
    return allTransactionCodes.map(transactionCode =>
      transactionCode.trnCode.toLowerCase()
    );
  },

  getAllTransactionCodesUtilityOption: state => {
    const allTransactionCodes = state.allTransactionCodes;
    return allTransactionCodes.map(
      transactionCode =>
        "(" + transactionCode.trnCode + ") " + transactionCode.trnDescription
    );
  },
  getTransactionCodeCodeFromOptionUtility: state => chosenOption => {
    const allTransactionCodes = state.allTransactionCodes;
    let opt = allTransactionCodes.filter(function(transactionCode) {
      return (
        "(" +
          transactionCode.trnCode +
          ") " +
          transactionCode.trnDescription ===
        chosenOption
      );
    });

    return opt[0].trnCode;
  },

  getTellersUtilityOption: state => {
    const allTellers = state.allTellers;
    if (allTellers.length > 0) {
      return allTellers.map(teller => teller.tellerNumber.toLowerCase());
    } else {
      return [];
    }
  },
  getAllTellersNameUtilityOption: state => {
    const allTellers = state.allTellers;
    return allTellers.map(
      tellerUser =>
        "(" +
        tellerUser.tellerUserId +
        ") " +
        tellerUser.tellerUserFirstName +
        " " +
        tellerUser.tellerUserMiddleName +
        " " +
        tellerUser.tellerUserLastName
    );
  },
  getTellerUserIdFromOptionUtility: state => chosenOption => {
    const allTellers = state.allTellers;
    let opt = allTellers.filter(function(tellerUser) {
      return (
        "(" +
          tellerUser.tellerUserId +
          ") " +
          tellerUser.tellerUserFirstName +
          " " +
          tellerUser.tellerUserMiddleName +
          " " +
          tellerUser.tellerUserLastName ===
        chosenOption
      );
    });

    return opt[0].userId;
  },

  getInternalAccountsUtilityOption: state => {
    const allInternalAccounts = state.allInternalAccounts;
    return allInternalAccounts.map(account => {
      return `${account.accountName.toLowerCase()} - ${account.accountNumber}`;
    });
  },
  getInternalAccountsFromOptionUtility: state => chosenOption => {
    const allInternalAccounts = state.allInternalAccounts;

    let opt = allInternalAccounts.filter(function(account) {
      return (
        `${account.accountName.toLowerCase()} - ${account.accountNumber}` ===
        chosenOption
      );
    });
    return opt[0].accountNumber;
  },
  getAccountClassUtilityOption: state => {
    return state.allAccountClass;
  },
  getAccountClass2UtilityOption: state => {
    const allAccountClass = state.allAccountClass2;
    return allAccountClass
      .filter(accountClass => accountClass.Type !== "FIXED")
      .map(
        accountClass => `${accountClass.Code} - (${accountClass.Description})`
      );
  },
  getAccountClassTypeFixedUtilityOption: state => {
    const allAccountClass = state.allAccountClass2;
    return allAccountClass
      .filter(accountClass => accountClass.Type === "FIXED")
      .map(
        accountClass => `${accountClass.Code} - (${accountClass.Description})`
      );
  },
  getAccountClassOptionFromCodeUtility: state => chosenOption => {
    const allAccountClass = state.allAccountClass2;
    let opt = allAccountClass.filter(function(accountClass) {
      return `${accountClass.Code}` === chosenOption;
    });
    return opt.reduce((accumulator, currentValue) => {
      return `${accumulator}${currentValue.Code} - (${currentValue.Description})`;
    }, "");
  },
  getAccountClassFromOptionUtility: state => chosenOption => {
    const allAccountClass = state.allAccountClass2;
    let opt = allAccountClass.filter(function(accountClass) {
      return (
        `${accountClass.Code} - (${accountClass.Description})` === chosenOption
      );
    });
    return opt[0].Code;
  },
  getLoanProductUtilityOption: state => {
    const allLoanProduct = state.allLoanProduct;
    return allLoanProduct.map(loanProduct => {
      return `${loanProduct.Code} -(${loanProduct.Description})`;
    });
  },
  getLoanProductFromOptionUtility: state => chosenOption => {
    const allLoanProduct = state.allLoanProduct;
    let opt = allLoanProduct.filter(function(loanProduct) {
      return (
        `${loanProduct.Code} -(${loanProduct.Description})` === chosenOption
      );
    });
    return opt[0].Code;
  },
  getLoanProductTenureFromOptionUtility: state => chosenOption => {
    const allLoanProduct = state.allLoanProduct;
    let opt = allLoanProduct.filter(function(loanProduct) {
      return (
        `${loanProduct.Code} -(${loanProduct.Description})` === chosenOption
      );
    });
    return opt[0].MinRate;
  },
  getCurrencyUtilityOption: state => {
    return state.currencyOptions;
  },
  getCountryUtilityOption: state => {
    return state.countryOption;
  },
  getAccountTypeOption: state => {
    return state.accountTypeOptions;
  },

  getFtProductCodeUtilityOption: state => {
    return state.productCodeOptions;
  },

  getFtProductsUtilityOption2: state => {
    const allFtProducts = state.ftProducts;
    return allFtProducts.map(
      ftProduct => `${ftProduct.Code} -(${ftProduct.Description})`
    );
  },
  getFtProductCodeFromOptionUtility: state => chosenOption => {
    const allFtProducts = state.ftProducts;
    let opt = allFtProducts.filter(function(ftProduct) {
      return `${ftProduct.Code} -(${ftProduct.Description})` === chosenOption;
    });
    return opt[0].Code;
  },

  generateTransactionRef: () => {
    return generateUUIDRef();
  },
  getCurrentTransactionRef: state => {
    return state.currentTransactionRef;
  },

  getAllUsersOption: state => {
    const allUsers = state.allUsers;
    return allUsers.map(
      user =>
        "(" +
        user.userId +
        ") " +
        user.userFirstName +
        " " +
        user.userMiddleName +
        " " +
        user.userLastName
    );
  },
  getUserIdFromOptionUtility: state => chosenOption => {
    const allUsers = state.allUsers;
    let opt = allUsers.filter(function(user) {
      return (
        "(" +
          user.userId +
          ") " +
          user.userFirstName +
          " " +
          user.userMiddleName +
          " " +
          user.userLastName ===
        chosenOption || user.userId === chosenOption
      );
    });

    return opt[0].userId;
  },

  getBankListUtilityOption: state => {
    const bankList = state.bankList;
    return bankList.map(bank => `${bank.bankName} (${bank.bankCode})`);
  },
  getBankCodeFromBankListOption: state => chosenOption => {
    const bankList = state.bankList;
    let opt = bankList.filter(function(bank) {
      return `${bank.bankName} (${bank.bankCode})` === chosenOption;
    });

    return opt[0].bankCode;
  },
  getExternalBankNameFromCodeUtility: state => chosenOption => {
    const bankList = state.bankList;

    let opt = bankList.filter(function(bank) {
      return bank.bankCode === chosenOption;
    });

    return opt[0].bankName;
  }
};

export const mutations = {
  SET_BUSINESS_TYPES(state, payload) {
    state.businessTypes = payload;
  },
  SET_CURRENT_CUSTOMER_ACCOUNTS(state, payload) {
    state.currentCustomerAccounts = payload;
  },
  SET_UTILITY_BANKS(state, payload) {
    state.allBanks = payload;
  },
  SET_UTILITY_BRANCHES(state, payload) {
    state.allBranches = payload;
  },
  SET_UTILITY_ROLES(state, payload) {
    state.allRoles = payload;
  },
  SET_UTILITY_PRIVILEGES(state, payload) {
    state.allPrivileges = payload;
  },
  SET_UTILITY_MODULES(state, payload) {
    state.allModules = payload;
  },
  SET_UTILITY_TRANSACTION_CODES(state, payload) {
    state.allTransactionCodes = payload;
  },
  SET_UTILITY_CHARGE_PRODUCTS(state, payload) {
    state.allChargeProducts = payload;
  },
  SET_UTILITY_TELLERS(state, payload) {
    state.allTellers = payload;
  },
  SET_ALL_INTERNAL_ACCOUNTS(state, payload) {
    state.allInternalAccounts = payload;
  },
  SET_UTILITY_GL_LEVEL_ONE(state, payload) {
    state.allGlLevel1 = payload;
  },
  SET_UTILITY_GL_LEVEL_TWO(state, payload) {
    state.allGlLevel2 = payload;
  },
  SET_UTILITY_ACCOUNT_CLASS(state, payload) {
    state.allAccountClass = payload;
  },
  SET_UTILITY_ACCOUNT_CLASS_2(state, payload) {
    state.allAccountClass2 = payload;
  },
  SET_UTILITY_LOAN_PRODUCT(state, payload) {
    state.allLoanProduct = payload;
  },
  SET_UTILITY_PRODUCT_CODE(state, payload) {
    state.productCodeOptions = payload;
  },
  SET_UTILITY_FT_PRODUCT(state, payload) {
    state.ftProducts = payload;
  },
  SET_UTILITY_ALL_USERS(state, payload) {
    state.allUsers = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_LOADING_SIX(state, status) {
    state.moduleLoadingSix = status;
  },
  SET_LOADING_SEVEN(state, status) {
    state.moduleLoadingSeven = status;
  },
  SET_LOADING_EIGHT(state, status) {
    state.moduleLoadingEight = status;
  },
  SET_LOADING_NINE(state, status) {
    state.moduleLoadingNine = status;
  },
  SET_LOADING_TEN(state, status) {
    state.moduleLoadingTen = status;
  },

  GENERATE_NEW_TRANSACTION_REF(state, userId) {
    state.currentTransactionRef = generateUUIDRef() + "-" + userId;
  },

  SET_BANK_LIST(state, payload) {
    state.bankList = payload;
  },
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

export const actions = {
  fetchBusinessTypes() {
    let payload = {
      readAll: "YES"
    };

    const successAction = responseData => {
      // StoreUtils.commit("appUtilities/SET_USER_ACCOUNTS", responseData);
      StoreUtils.commit(
        "appUtilities/SET_BUSINESS_TYPES",
        responseData.data
      );
    };

    return appUtilitiesService.fetchBusinessTypes(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      false
    );
  },

  fetchQueryTransactionHistory(undefined, { customerId }) {
    let payload = {
      bankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      customerId
    };

    const successAction = responseData => {
      // StoreUtils.commit("appUtilities/SET_USER_ACCOUNTS", responseData);
      StoreUtils.commit(
        "appUtilities/SET_CURRENT_CUSTOMER_ACCOUNTS",
        responseData.accounts
      );
    };

    return appUtilitiesService.fetchCustomerAccountByCustomerId(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setUtilityBanks({ commit, rootGetters }) {
    let payload = {
      bankCode: rootGetters["user/getUserBankCode"],
      source: "WEB"
    };
    BankService.singleBank(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          let banks = [responseData];
          commit("SET_UTILITY_BANKS", banks);
        } else {
          commit("SET_UTILITY_BANKS", []);
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityBranches({ commit, rootGetters }) {
    let payload = {
      branchBankCode: rootGetters["user/getUserBankCode"],
      source: "CBA",
      readAll: "NO"
    };
    BranchService.allBranches(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_BRANCHES", responseData.data);
          } else {
            commit("SET_UTILITY_BRANCHES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchUtilityGlLevelOneCodes({ commit, rootGetters }) {
    let payload = {
      glLevel1BankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };
    CreateGlLevel1Service.allGlLevel1(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_GL_LEVEL_ONE", responseData.data);
          } else {
            commit("SET_UTILITY_GL_LEVEL_ONE", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityGlLevelTwoCodes({ commit, rootGetters }) {
    let payload = {
      glLevel2BankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };
    CreateGlLevel2Service.allGlLevel2(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_GL_LEVEL_TWO", responseData.data);
          } else {
            commit("SET_UTILITY_GL_LEVEL_TWO", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityRoles({ commit, rootGetters }) {
    let payload = {
      roleBankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };
    // start loader
    commit("SET_LOADING_THREE", true);

    RoleService.allRoles(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_ROLES", responseData.data);
          } else {
            commit("SET_UTILITY_ROLES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  setUtilityPrivileges({ commit }) {
    let payload = {
      readAll: "YES"
    };

    PrivilegeService.allPrivileges(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_PRIVILEGES", responseData.data);
          } else {
            commit("SET_UTILITY_PRIVILEGES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllModules({ commit }) {
    // build request body
    const payload = {
      moduleId: "",
      readAll: "YES"
    };
    commit("SET_LOADING_FOUR", true);
    return ModuleService.allModules(payload)
      .then(response => {
        commit("SET_LOADING_FOUR", false);
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // filtering the data to conform to the stats standard
          // then set it to state
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_MODULES", responseData.data);
          } else {
            commit("SET_UTILITY_MODULES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllTransactionCode({ commit, rootGetters }) {
    // build request body
    const payload = {
      trnBankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };

    // start loading
    commit("SET_LOADING", true);

    return TransactionCodeService.allTransactionCodes(payload)
      .then(response => {
        let responseData = response.data;

        // stop loading
        commit("SET_LOADING", false);

        if (responseData.responseCode === "00") {
          // filtering the data to conform to the stats standard
          // then set it to state
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_TRANSACTION_CODES", responseData.data);
          } else {
            commit("SET_UTILITY_TRANSACTION_CODES", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllChargeProducts({ commit }) {
    // build request body
    const payload = {
      productBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      productType: "CH"
    };

    // start loading
    StoreUtils.dispatch(StoreUtils.actions.loader.showComponentLoader, true);

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;

        // stop loading
        StoreUtils.dispatch(
          StoreUtils.actions.loader.showComponentLoader,
          false
        );

        if (responseData.responseCode === "00") {
          // filtering the data to conform to the stats standard
          // then set it to state
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_CHARGE_PRODUCTS", responseData.data);
          } else {
            commit("SET_UTILITY_CHARGE_PRODUCTS", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllTellers({ commit, rootGetters }) {
    // build request body
    const payload = {
      tellerBankCode: rootGetters["user/getUserBankCode"],
      readAll: "No"
    };

    // start loading
    commit("SET_LOADING_TWO", true);

    return TellerService.allTellers(payload)
      .then(response => {
        let responseData = response.data;

        // stop loading
        commit("SET_LOADING_TWO", false);

        if (responseData.responseCode === "00") {
          // filtering the data to conform to the stats standard
          // then set it to state
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_TELLERS", responseData.data);
          } else {
            commit("SET_UTILITY_TELLERS", []);
          }
        }
      })
      .catch(error => {
        throw error;
      });
  },

  fetchAllInternalAccounts({ commit, rootGetters }) {
    // build request body
    const payload = {
      accountBankCode: rootGetters["user/getUserBankCode"],
      readAll: "NO"
    };

    // start loading
    commit("SET_LOADING_FIVE", true);

    return InternalAccountService.allInternalAccounts(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FIVE", false);
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_ALL_INTERNAL_ACCOUNTS", responseData.data);
          } else {
            commit("SET_ALL_INTERNAL_ACCOUNTS", []);
          }
        }
      })
      .catch(error => {
        // stop loader
        commit("SET_LOADING_FIVE", false);
        throw error;
      });
  },

  generateNewTransactionRef({ commit, rootGetters }) {
    const userId = rootGetters["user/getUserId"];
    commit("GENERATE_NEW_TRANSACTION_REF", userId);
  },

  fetchAllAccountClass({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_SIX", true);

    // build request body
    const payload = {
      productBankCode: rootGetters["user/getUse]rBankCode"],
      productType: "AC"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_SIX", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit(
            "SET_UTILITY_ACCOUNT_CLASS",
            responseData.data.map(accountProduct => accountProduct.Code)
          );
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  fetchAllAccountClass2({ commit }) {
    // start loading
    commit("SET_LOADING_SIX", true);

    // build request body
    const payload = {
      productBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      productType: "AC"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_SIX", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit("SET_UTILITY_ACCOUNT_CLASS_2", responseData.data);
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  fetchAllLoanProducts({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_NINE", true);

    // build request body
    const payload = {
      productBankCode: rootGetters["user/getUserBankCode"],
      productType: "LO"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_NINE", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit("SET_UTILITY_LOAN_PRODUCT", responseData.data);
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_NINE", false);

        throw error;
      });
  },

  fetchAllFundsTransferProduct({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_SEVEN", true);

    // build request body
    const payload = {
      productBankCode: rootGetters["user/getUserBankCode"],
      productType: "FT"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_SEVEN", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit(
            "SET_UTILITY_PRODUCT_CODE",
            responseData.data.map(ftProduct => ftProduct.Code)
          );
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_SEVEN", false);

        throw error;
      });
  },

  fetchAllFundsTransferProduct2({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_SEVEN", true);

    // build request body
    const payload = {
      productBankCode: rootGetters["user/getUserBankCode"],
      productType: "FT"
    };

    return ProductService.readProduct(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_SEVEN", false);
        if (
          responseData.responseCode === "00" &&
          responseData.data.length > 0
        ) {
          // then set it to state
          commit("SET_UTILITY_FT_PRODUCT", responseData.data);
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_SEVEN", false);

        throw error;
      });
  },

  fetchAllUsers({ commit, rootGetters }) {
    // start loading
    commit("SET_LOADING_EIGHT", true);

    // build request body
    const payload = {
      userBankCode: rootGetters["user/getUserBankCode"],
      source: "CBA",
      readAll: "NO"
    };

    return BankUserService.allUsers(payload)
      .then(response => {
        let responseData = response.data;
        // stop loading
        commit("SET_LOADING_EIGHT", false);
        if (responseData.responseCode === "00") {
          if (responseData.hasOwnProperty("data")) {
            commit("SET_UTILITY_ALL_USERS", responseData.data);
          } else {
            commit("SET_UTILITY_ALL_USERS", []);
          }
        }
      })
      .catch(error => {
        // stop loading
        commit("SET_LOADING_EIGHT", false);

        throw error;
      });
  },

  setBankList({ commit }, payload) {
    commit("SET_BANK_LIST", payload);
  },
  fetchBankList() {
    // build request body
    const request = {
      request: "banklist"
    };
    const successAction = responseData => {
      StoreUtils.dispatch("appUtilities/setBankList", responseData.data);
    };

    return bankService.fetchBankList(
      request,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      false
    );
  },

  // Reset The State On Log Out
  // Update As The Modules Increase
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};

// ===
// Private helpers
// ===




// alter table dbo.PWB_CUSTOMERS alter column customerName varchar(200) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerBvn varchar(20) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerAge varchar(10) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerStatus varchar(10) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerMaker varchar(20) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerChecker varchar(10) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerPicture varchar(255) not null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerMandate varchar(255) not null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerGender varchar(20) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerUtilityBill varchar(255) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerIdCard varchar(255) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerHouseNo varchar(255) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerStreetName varchar(255) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerNearestBusStop varchar(255) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerLga varchar(200) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerAccountOfficer varchar(20) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerReferralCode varchar(20) null
// go
//
// alter table dbo.PWB_CUSTOMERS alter column customerReferralType varchar(20) null
// go
//
