<template>
  <div>
    <h4 class="card-title mb-4">Create New Project</h4>

    <form>
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              placeholder=""
              :hasPreIcon="false"
              :label="'Name'"
              :class="{
                error: $v.createBankFormBody.name.$error
              }"
              @blur="$v.createBankFormBody.name.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createBankFormBody.name.$error">
              <p
                v-if="!$v.createBankFormBody.name.required"
                class="form-error-message"
              >
                Name is required
              </p>
            </template>
          </div>

          <div>
            <BaseDatePicker
              :type="'date'"
              :range="false"
              v-model="createBankFormBody.date"
              :label="'Date'"
              :hasPreIcon="false"
              :class="{
                error: $v.createBankFormBody.date.$error
              }"
              @blur="$v.createBankFormBody.date.$touch()"
            />
            <!--                          error-->
            <template v-if="$v.createBankFormBody.date.$error">
              <p
                v-if="!$v.createBankFormBody.date.required"
                class="form-error-message"
              >
                Date is required
              </p>
            </template>
          </div>

          <div>
            <div class="form-group">
              <label>Country</label>
              <multiselect
                v-model="createBankFormBody.country"
                :options="options"
                placeholder="Vault Teller Number"
                @close="$v.createBankFormBody.country.$touch()"
              ></multiselect>
            </div>

            <!--                          error-->
            <template v-if="$v.createBankFormBody.country.$error">
              <p
                v-if="!$v.createBankFormBody.country.required"
                class="form-error-message"
              >
                Country is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseTextArea
              :inputType="'text'"
              v-model="createBankFormBody.message"
              placeholder=""
              :hasPreIcon="false"
              :label="'Message'"
              :class="{
                error: $v.createBankFormBody.message.$error
              }"
              @blur="$v.createBankFormBody.message.$touch()"
            >
            </BaseTextArea>

            <template v-if="$v.createBankFormBody.message.$error">
              <p
                v-if="!$v.createBankFormBody.message.required"
                class="form-error-message"
              >
                Message is required
              </p>
            </template>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary mr-1">
        Create Bank
      </button>
      <a @click="testSubmit" class="btn btn-secondary">Cancel</a>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "Sample",
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      value: null,

      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa"
      ]
    };
  },
  validations: {
    createBankFormBody: {
      date: { required },
      message: { required },
      country: { required },
      name: { required }
    }
  },
  methods: {

  }
};
</script>

<style scoped></style>
