<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->

    <div>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.serialNumber"
                placeholder="Terminal Serial No."
                :hasPreIcon="false"
                :label="'Serial Number'"
                :class="{
                  error: $v.formBody.serialNumber.$error
                }"
                @blur="$v.formBody.serialNumber.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.serialNumber.$error">
                <p
                  v-if="!$v.formBody.serialNumber.required"
                  class="form-error-message"
                >
                  Terminal Serial Number is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <p class="text-right">
          <span v-if="loader.actionButtonLoader" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Assign Terminal
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "AssignTerminalToCustomerAccountForm",
  props: {
    currentCustomer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formBody: {
        serialNumber: ""
      }
    };
  },
  computed: {
    ...mapState(["loader"])
  },
  validations: {
    formBody: {
      serialNumber: { required }
    }
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit(StoreUtils.mutations.form.BUILD_FORM_BODY, {
          ...this.formBody,
          accountNumber: this.currentCustomer.accountNumber
        });
        StoreUtils.dispatch("terminal/assignTerminalToCustomerAccount")
          .then(responseData => {
            if (responseData.responseCode === "00") {
              this.$emit("terminalAssignmentSuccessful", responseData);
            }
            this.formBody = {
              serialNumber: ""
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {
        serialNumber: ""
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
