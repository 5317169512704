import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import RptService from "../../services/newServices/RptService";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";
import router from "../../router";
import NotificationUtils from "../../utils/BaseUtils/NotificationUtils";
const rptService = new RptService();

const getDefaultState = () => {
  return {
    test: "",
    stage: 1,
    step: 1,
    stage1Request: {},
    stage2Request: {},
    stage3Request: {},
    stage4Request: {},
    stage5Request: {},
    stage6Request: {},
    allReports: [],
    viewReportType: "all",
    currentReportName: "",
    currentReport: []
  };
};
export const state = getDefaultState();

export const getters = {
  getStage: state => {
    return state.stage;
  },
  getStep: state => {
    return state.step;
  },
  getRequestForStage: state => stage => {
    switch (stage) {
      case 1:
        return state.stage1Request;
      case 2:
        return state.stage2Request;
      case 3:
        return state.stage3Request;
      case 4:
        return state.stage4Request;
      case 5:
        return state.stage5Request;
      case 6:
        return state.stage6Request;
      default:
        return;
    }
  },
  getReportBaseTableName: state => {
    let stage2 = state.stage2Request;
    return stage2.tableType === "report"
      ? StoreUtils.rootGetter(
          StoreUtils.getters.rptUtilities.GET_REPORT_NAME_FROM_OPTION,
          stage2.reportTable
        )
      : stage2.reportTable;
  },
  getReportCapTableName: state => {
    let stage3 = state.stage3Request;

    return stage3.tableType === "report"
      ? StoreUtils.rootGetter(
          StoreUtils.getters.rptUtilities.GET_REPORT_NAME_FROM_OPTION,
          stage3.reportJoinTable
        )
      : stage3.reportJoinTable;
  },
  getReportType: state => {
    return state.stage1Request.reportType;
  },
  getReportCategory: state => {
    return state.stage1Request.reportCategory;
  },
  getViewReportType: state => {
    return state.viewReportType;
  },
  getCurrentReportName: state => {
    return state.currentReportName;
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  TEST(state, payload) {
    state.test = payload;
  },
  SET_RPT_STAGE_TO(state, stage) {
    state.stage = stage;
  },
  SET_RPT_STEP_TO(state, step) {
    state.step = step;
  },
  SET_STAGE_REQUEST(state, payload) {
    switch (payload.stage) {
      case 1:
        state.stage1Request = payload;
        break;
      case 2:
        state.stage2Request = payload;
        break;
      case 3:
        state.stage3Request = payload;
        break;
      case 4:
        state.stage4Request = payload;
        break;
      case 5:
        state.stage5Request = payload;
        break;
      case 6:
        state.stage6Request = payload;
        break;
      default:
        return;
    }
  },
  SET_ALL_REPORTS(state, payload) {
    state.allReports = payload;
  },
  SET_CURRENT_REPORT_NAME(state, payload) {
    state.currentReportName = payload;
  },
  SET_CURRENT_REPORT(state, payload) {
    state.currentReport = payload;
  },
  SET_VIEW_REPORT_TYPE_TO_ALL(state) {
    state.viewReportType = "all";
  },
  SET_VIEW_REPORT_TYPE_TO_QUERY(state) {
    state.viewReportType = "query";
  }
};
export const actions = {
  requestAccountStatement() {
    let formBody = StoreUtils.rootGetter("form/getFormBody");
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      bankName: "",
      supportPhone: "",
      supportEmail: "",
      startDate: formBody.startDate,
      endDate: formBody.endDate,
      accountNumber: formBody.accountNumber,
      email: formBody.email,
      logo: "",
      color: ""
    };

    const successAction = () => {
      let notificationModalBody = {
        type: "success", //error, success, warning
        mode: "default", // default, yes-no
        title: "Success",
        message: `Your request is being processed and would be sent to ${
          formBody.email ? formBody.email : "the customers email"
        } shortly.`,
        noAction: () => {},
        noActionTitle: "",
        yesAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Okay"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    };

    return rptService.requestAccountStatement(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  goBackToStage({ commit }, stage) {
    commit("SET_RPT_STAGE_TO", stage);
  },

  buildCreateReportRequestBody({ commit }, payload) {
    switch (StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE)) {
      case 1:
        commit("SET_STAGE_REQUEST", { stage: 1, ...payload });
        commit("SET_RPT_STAGE_TO", 2);
        break;
      case 2:
        commit("SET_STAGE_REQUEST", { stage: 2, ...payload });
        StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_REPORT_TYPE) ===
        "single-source"
          ? commit("SET_RPT_STAGE_TO", 5)
          : commit("SET_RPT_STAGE_TO", 3);
        break;
      case 3:
        commit("SET_STAGE_REQUEST", { stage: 3, ...payload });
        commit("SET_RPT_STAGE_TO", 4);
        break;
      case 4:
        commit("SET_STAGE_REQUEST", { stage: 4, ...payload });
        commit("SET_RPT_STAGE_TO", 5);
        break;
      case 5:
        commit("SET_STAGE_REQUEST", { stage: 5, ...payload });
        commit("SET_RPT_STAGE_TO", 6);
        break;
      case 6:
        commit("SET_STAGE_REQUEST", { stage: 6, ...payload });
        StoreUtils.dispatch("rpt/createReport");
        break;
      default:
        return;
    }
  },

  createReport: function({ getters }) {
    let stage1 = getters.getRequestForStage(1);
    let stage4 = getters.getRequestForStage(4);
    let stage5 = getters.getRequestForStage(5);
    let stage6 = getters.getRequestForStage(6);

    let payload = {};

    if (
      StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_REPORT_TYPE) ===
      "single-source"
    ) {
      payload = {
        ...payload,
        reportName: stage1.reportName,
        reportDescription: stage1.reportDescription,
        reportOwner: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
        reportBankCode: StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BANK_CODE
        ),
        reportCategory: stage1.reportCategory,
        reportType: "CUSTOM",
        reportMaker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
        reportChecker: "",
        reportTable: StoreUtils.rootGetter(
          StoreUtils.getters.rpt.GET_REPORT_BASE_TABLE_NAME
        ),
        reportJoinType: "",
        reportJoinTable: "",
        reportJoinOn: "",
        reportCondition: stage5.reportCondition,
        reportFieldList: stage6.reportFieldList,
        requestType: "create"
      };
    } else {
      payload = {
        ...payload,
        reportName: stage1.reportName,
        reportDescription: stage1.reportDescription,
        reportOwner: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
        reportBankCode: StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BANK_CODE
        ),
        reportCategory: stage1.reportCategory,
        reportType: "CUSTOM",
        reportMaker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
        reportChecker: "",
        reportTable: StoreUtils.rootGetter(
          StoreUtils.getters.rpt.GET_REPORT_BASE_TABLE_NAME
        ),
        reportJoinType: stage4.reportJoinType,
        reportJoinTable: StoreUtils.rootGetter(
          StoreUtils.getters.rpt.GET_REPORT_CAP_TABLE_NAME
        ),
        reportJoinOn: `${stage4.reportBaseJoinColumn}=${stage4.reportCapJoinColumn}`,
        reportCondition: stage5.reportCondition,
        reportFieldList: stage6.reportFieldList,
        requestType: "create"
      };
    }

    const successAction = () => {
      StoreUtils.dispatch("rpt/resetState");
    };

    return rptService.createReport(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchAllReports({ commit }, reportCategory) {
    commit("TEST", "test");
    const payload = {
      reportBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      readAll: "NO",
      reportCategory
    };

    const successAction = responseData => {
      StoreUtils.commit("rpt/SET_ALL_REPORTS", responseData.data);
    };

    return rptService.fetchAllReports(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  viewReport({ commit }, reportName) {
    commit("SET_CURRENT_REPORT_NAME", reportName);
    commit("SET_VIEW_REPORT_TYPE_TO_ALL");

    router.push({
      name: "report-single-report"
    });
    // RouterUtils.changeRouteTo(RouterUtils.routes.rpt.VIEW_REPORT);
  },
  queryReport({ commit }, reportName) {
    commit("SET_CURRENT_REPORT_NAME", reportName);
    commit("SET_VIEW_REPORT_TYPE_TO_QUERY");
    RouterUtils.changeRouteTo(RouterUtils.routes.rpt.QUERY_REPORT);
  },

  viewReportAfterQuery() {
    RouterUtils.changeRouteTo(RouterUtils.routes.rpt.VIEW_REPORT);
  },

  buildQueryReportRequestBody({ commit }, payload) {
    switch (StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE)) {
      case 1:
        commit("SET_STAGE_REQUEST", { stage: 1, ...payload });
        commit("SET_RPT_STAGE_TO", 2);
        break;
      case 2:
        commit("SET_STAGE_REQUEST", { stage: 2, ...payload });
        StoreUtils.dispatch("rpt/fetchOneReport");
        break;
      default:
        return;
    }
  },

  fetchOneReport({ getters }) {
    const payload = {
      reportBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      reportName: getters.getCurrentReportName,
      reportFieldList:
        getters.getViewReportType === "query"
          ? getters.getRequestForStage(2).reportFieldList
          : "",
      reportCondition:
        getters.getViewReportType === "query"
          ? getters.getRequestForStage(1).reportCondition
          : "",
      readCount: "ALL"
    };

    const successAction = responseData => {
      StoreUtils.commit(
        "rpt/SET_CURRENT_REPORT",
        JSON.parse(responseData.reports)
      );
    };

    return rptService.fetchOneReport(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
