<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import RouterUtils from "../../../../../utils/BaseUtils/RouterUtils";
export default {
  name: "AccountingEntriesInfoModal",
  data() {
    return {
      modalOpen: false,
      activeTab: "slab" // slab, entries
    };
  },
  props: {
    currentAccountingEntry: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(["accountingEntry", "loader"]),
    currentSlabs() {
      return StoreUtils.rootGetter(
        "accountingEntry/getCurrentAccountingEntrySlabs"
      );
    },
    currentSlabEntries() {
      return StoreUtils.rootGetter(
        "accountingEntry/getCurrentAccountingEntryEntries"
      );
    }
  },
  methods: {
    goBack() {
      this.switchTabb("slab");
    },

    switchTabb(tab) {
      //tabb => slab; entries
      this.activeTab = tab;
      // this.fetchEntries(slabCode);
    },
    createSlab() {
      StoreUtils.commit("accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_SLAB", {
        slabCode: "",
        slabMaxAmount: "",
        editable: true
      });
      StoreUtils.dispatch(
        StoreUtils.actions.accountingEntry.SET_CURRENT_ACCOUNTING_ENTRY,
        this.currentAccountingEntry
      );
      RouterUtils.changePageTitleTo(
        RouterUtils.pageTitles.accountingEntry.CREATE
      );
      RouterUtils.changeRouteTo(
        RouterUtils.routes.accountingEntry.ASSIGN_ENTRIES
      );
    },
    editEntries() {
      StoreUtils.dispatch(
        StoreUtils.actions.accountingEntry.SET_CURRENT_ACCOUNTING_ENTRY,
        this.currentAccountingEntry
      );
      RouterUtils.changePageTitleTo(
        RouterUtils.pageTitles.accountingEntry.EDIT
      );
      RouterUtils.changeRouteTo(
        RouterUtils.routes.accountingEntry.ASSIGN_ENTRIES
      );
    },
    calculateEntryType(type) {
      if (type === "DR") {
        return `debit`;
      }
      if (type === "CR") {
        return `credit`;
      }
    },
    fetchSlabs() {
      // this.switchTabb("slabs");
      StoreUtils.dispatch("accountingEntry/fetchAccountingEntrySlabs", {
        accountingEntryCode: this.currentAccountingEntry.aeCode
      });
    },
    fetchEntries(slab) {
      this.switchTabb("entries");
      StoreUtils.commit("accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_SLAB", {
        slabCode: slab.slabCode,
        slabMaxAmount: slab.slabMaxAmount,
        editable: false
      });
      StoreUtils.dispatch(
        "accountingEntry/fetchCurrentAccountingEntrySlabEntries",
        {
          accountingEntryCode: this.currentAccountingEntry.aeCode,
          slabCode: slab.slabCode
        }
      );
    },
    openModal() {
      this.fetchSlabs();
      this.modalOpen = true;
    },
    closeModal() {
      this.switchTabb("slab");
      this.modalOpen = false;
      StoreUtils.commit(
        "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_SLABS",
        []
      );
      StoreUtils.commit(
        "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES",
        []
      );
    }
  }
};
</script>

<template>
  <div>
    <!--        control-->
    <a @click="openModal()" class="view-more-info" title="View Entries"
      ><i class="bx bx-info-circle font-size-18"
    /></a>
    <!--    control END-->

    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="500"
      :height="450"
      measure="px"
      animation="zoom"
      @hide="closeModal()"
      :closeOnClickMask="true"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <!--          <h4 class="card-title mb-4">Customer Info</h4>-->
          <div>
            <div v-if="loader.componentLoader">
              <BaseComponentLoader />
            </div>
            <div v-else>
              <div v-if="activeTab === 'slab'" class="view-more-customer-info">
                <div class="view-more-customer-info-wrap">
                  <div class="view-more-top">
                    <h4 class="card-title mb-4">
                      Select a slab
                    </h4>
                  </div>

                  <div class="view-more-base">
                    <div class="slab-list">
                      <div v-if="currentSlabs.length > 0">
                        <div
                          v-for="slab in currentSlabs"
                          :key="slab.slabId"
                          class="slab-card"
                          @click="fetchEntries(slab)"
                        >
                          <div class="r">{{ slab.slabCode }}</div>
                          <div class="l">₦ {{ slab.slabMaxAmount }}</div>
                        </div>
                      </div>

                      <p v-else class="empty-entries text-center">
                        there are no Slabs
                      </p>
                    </div>

                    <br />
                    <p class="text-center mt-1">
                      <span @click="createSlab" class="btn btn-secondary"
                        >Add New Slab</span
                      >
                    </p>
                    <!--                     end table-responsive -->
                  </div>
                </div>
              </div>
              <div
                v-if="activeTab === 'entries'"
                class="view-more-customer-info"
              >
                <div class="view-more-customer-info-wrap">
                  <div class="view-more-top">
                    <h4 class="card-title mb-4">
                      <span @click="goBack" class="back-btn">
                        <svg
                          viewBox="0 0 51 51"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#5F6367"
                            d="M25.5 0C11.4395 0 0 11.4395 0 25.5C0 39.5605 11.4395 51 25.5 51C39.5605 51 51 39.5605 51 25.5C51 11.4395 39.5605 0 25.5 0ZM40.375 26.5625C40.375 27.1498 39.8997 27.6251 39.3124 27.6251H17.4399L22.0012 32.1864C22.2004 32.3856 22.3125 32.6553 22.3125 32.9376V35.0625C22.3125 35.4921 22.0541 35.8801 21.6568 36.0441C21.2583 36.2101 20.8028 36.1178 20.4988 35.8137L10.9363 26.2512C10.5212 25.8361 10.5212 25.1637 10.9363 24.7487L20.4988 15.1862C20.7022 14.9828 20.974 14.875 21.2501 14.875C21.387 14.875 21.525 14.9009 21.6568 14.9559C22.0542 15.1199 22.3125 15.508 22.3125 15.9375V18.0625C22.3125 18.3447 22.2004 18.6145 22.0012 18.8137L17.4399 23.375H39.3124C39.8997 23.375 40.375 23.8503 40.375 24.4376V26.5625Z"
                          />
                        </svg>
                      </span>
                      {{ currentAccountingEntry.aeCode }} Entries
                    </h4>

                    <div class="row">
                      <div class="col-sm-6">
                        <p class="queue-title">Description</p>
                        <p class="queue-value">
                          {{ currentAccountingEntry.aeDescription }}
                        </p>
                      </div>

                      <div class="col-sm-6">
                        <p class="queue-title">Created At</p>
                        <p class="queue-value">
                          {{
                            currentAccountingEntry.aeCreatedAt
                              | formatDateForTable
                          }}
                        </p>
                      </div>

                      <div class="col-sm-6">
                        <p class="queue-title">Maker</p>
                        <p class="queue-value">
                          {{ currentAccountingEntry.aeMaker }}
                        </p>
                      </div>

                      <div class="col-sm-6">
                        <p class="queue-title">Updated At</p>
                        <p class="queue-value">
                          {{
                            currentAccountingEntry.aeUpdatedAt
                              | formatDateForTable
                          }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="view-more-base">
                    <div class="privilege-list">
                      <div v-if="currentSlabEntries.length > 0" class="row">
                        <div
                          v-for="entry in currentSlabEntries"
                          :key="entry.entryId"
                          class="col-sm-6"
                        >
                          <div
                            class="entry-list-item"
                            :class="calculateEntryType(entry.entryDrCr)"
                          >
                            <div class="row">
                              <div class="col-6 text-left">
                                <p>
                                  <i
                                    v-if="entry.entryDrCr === 'CR'"
                                    class="bx bx-plus font-size-16 mr-1"
                                  ></i>
                                  <i
                                    v-if="entry.entryDrCr === 'DR'"
                                    class="bx bx-minus font-size-16 mr-1"
                                  ></i>
                                  ₦ {{ entry.entryAmount }}
                                </p>
                              </div>
                              <div class="col-6 text-right">
                                <p>
                                  {{ entry.entryAccount }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p v-else class="empty-entries text-center">
                        there are no Entries...
                      </p>
                    </div>

                    <br />
                    <p class="text-center mt-1">
                      <span @click="editEntries" class="btn btn-secondary"
                        >Edit Entries</span
                      >
                    </p>
                    <!-- end table-responsive -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vodal>
  </div>
</template>

<style scoped>
.view-customer-info-btn {
  position: relative;
  height: 20px;
  width: 20px;
  display: block;
  padding: 2px 2px;
  margin-top: 0;
  background-color: #795548;
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.view-more-info {
  cursor: pointer;
}
.view-customer-info-btn i {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}
p {
  /*color: #495057;*/
}
.privilege-list-item {
  margin-bottom: 15px;
}
.privilege-list-item span {
  text-transform: capitalize;
}
.privilege-list-item span i {
  color: #556ee6;
}
.card-title {
  text-transform: capitalize;
}
.entry-list-item {
}
.entry-list-item.credit {
  color: #34c38f;
}
.entry-list-item.debit {
  color: darkred;
}
.entry-list-item p {
  margin-bottom: 10px;
}
.empty-entries {
  font-size: 14px;
  line-height: 18px;
  color: #9e9e9e;
}
</style>
