import RoleService from "../../services/oldServices/RoleService";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import NewRoleService from "../../services/newServices/RoleService";
const newRoleService = new NewRoleService();

const getDefaultState = () => {
  return {
    moduleLoading: false,
    moduleLoadingTwo: true,
    moduleLoadingThree: true,
    moduleLoadingFour: true,
    test: "",
    allRoles: [],
    currentRolePrivilege: [],
    currentRole: {}
  };
};

export const state = getDefaultState();

export const getters = {
  // Whether the user is currently logged in.
  // loggedIn(state) {
  //   return !!state.user;
  // }
  getCurrentRole: state => {
    return state.currentRole;
  }
};

export const mutations = {
  // SET_USER(state, payload) {
  //   state.user = payload;
  // },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_ALL_ROLES(state, payload) {
    state.allRoles = payload;
  },
  SET_CURRENT_ROLE_PRIVILEGE(state, payload) {
    state.currentRolePrivilege = payload;
  },

  SET_CURRENT_ROLE(state, payload) {
    state.currentRole = payload;
  },

  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

export const actions = {
  // create a new Bank
  async fetchRolePrivileges({ commit, dispatch }, payload) {
    commit("SET_LOADING_FOUR", true);
    return await RoleService.fetchRolePrivileges(payload)
      .then(response => {
        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_CURRENT_ROLE_PRIVILEGE", responseData.privileges);
          commit("SET_LOADING_FOUR", false);
        } else {
          const notification = {
            type: "error",
            message: `Error : ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        commit("SET_LOADING_FOUR", false);
        const notification = {
          type: "error",
          message: `Error : ${error}`
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },
  createRoleComplete({ commit, dispatch, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING", true);

    // append the Created by to the payload
    let userId = rootGetters["user/getUserId"];
    let userBankCode = rootGetters["user/getUserBankCode"];

    let realCreateRolePayload = {};
    realCreateRolePayload.roleName = payload.roleName;
    realCreateRolePayload.roleTrnLimit = payload.roleTrnLimit;
    realCreateRolePayload.roleMaker = userId;
    realCreateRolePayload.roleBankCode = userBankCode;

    return dispatch("realCreateRole", realCreateRolePayload)
      .then(response => {
        let responseData = response.data;

        let assignPrivilegeToRolePayload = {};
        assignPrivilegeToRolePayload.rpPrivilegeId = payload.rolePrivileges;
        assignPrivilegeToRolePayload.rpRoleId = responseData.roleId;
        assignPrivilegeToRolePayload.rpMaker = userId;
        assignPrivilegeToRolePayload.rpBankCode = userBankCode;

        if (responseData.responseCode === "00") {
          const notification = {
            type: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });

          dispatch("assignPrivilegeToRole", assignPrivilegeToRolePayload)
            .then(response => {
              // stop loader
              commit("SET_LOADING", false);

              let responseData = response.data;

              if (responseData.responseCode === "00") {
                const notification = {
                  type: "success",
                  message: `${responseData.responseMessage}`
                };
                dispatch("notification/add", notification, { root: true });
              } else {
                const notification = {
                  type: "error",
                  message: `Error : ${responseData.responseMessage}`
                };
                dispatch("notification/add", notification, { root: true });
              }
            })
            .catch(error => {
              // stop loader
              commit("SET_LOADING", false);

              const notification = {
                type: "error",
                message: `Error : ${error}`
              };
              dispatch("notification/add", notification, { root: true });

              throw error;
            });
        } else {
          const notification = {
            type: "error",
            message: `Error : ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: `Error : ${error}`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  realCreateRole({ commit }, payload) {
    commit("SET_TEST", "test");
    return RoleService.createRole(payload);
  },

  assignPrivilegeToRole({ commit }, payload) {
    commit("SET_TEST", "test");
    return RoleService.assignPrivilegeToRole(payload);
  },

  switchToEditRole(undefined, payload) {
    let request = {
      roleId: payload.roleId,
      roleBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.commit("role/SET_CURRENT_ROLE", responseData);
      RouterUtils.changeRouteTo(RouterUtils.routes.role.EDIT_ROLE_PRIVILEGE);
    };

    return newRoleService.fetchOneRole(
      request,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  updateRolePrivileges({ getters }, payload) {
    let request = {
      rpPrivilegeId: payload.rolePrivileges,
      roleTrnLimit: payload.roleTrnLimit,
      rpRoleId: getters.getCurrentRole.roleId,
      rpMaker: getters.getCurrentRole.roleMaker,
      rpBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = () => {
      RouterUtils.changeRouteTo(RouterUtils.routes.role.ALL_ROLES);
      StoreUtils.commit("role/SET_CURRENT_ROLE", {});
    };

    return newRoleService.editRolePrivileges(
      request,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // get All Roles
  allRoles(undefined, payload) {
    const successAction = responseData => {
      StoreUtils.commit("role/SET_ALL_ROLES", responseData.data);
    };

    return newRoleService.fetchAllRoles(
    payload,
        successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
