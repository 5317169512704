<template>
  <div>
    <form @submit.prevent="createTransactionCode">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createTransactionCodeFormBody.trnCode"
              placeholder="Transaction Code"
              :hasPreIcon="false"
              :label="'Transaction Code'"
              :class="{
                error: $v.createTransactionCodeFormBody.trnCode.$error
              }"
              @blur="$v.createTransactionCodeFormBody.trnCode.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createTransactionCodeFormBody.trnCode.$error">
              <p
                v-if="!$v.createTransactionCodeFormBody.trnCode.required"
                class="form-error-message"
              >
                Transaction Code is required
              </p>
            </template>
          </div>
        </div>
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createTransactionCodeFormBody.trnDescription"
              placeholder="Transaction Description"
              :hasPreIcon="false"
              :label="'Transaction Description'"
              :class="{
                error: $v.createTransactionCodeFormBody.trnDescription.$error
              }"
              @blur="$v.createTransactionCodeFormBody.trnDescription.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.createTransactionCodeFormBody.trnDescription.$error"
            >
              <p
                v-if="!$v.createTransactionCodeFormBody.trnDescription.required"
                class="form-error-message"
              >
                Transaction Code is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Transaction Expense</label>
              <multiselect
                      v-model="createTransactionCodeFormBody.trnExpense"
                      :options="noYesOption"
                      placeholder="Transaction Expense"
                      @close="$v.createTransactionCodeFormBody.trnExpense.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.createTransactionCodeFormBody.trnExpense.$error">
              <p
                v-if="!$v.createTransactionCodeFormBody.trnExpense.required"
                class="form-error-message"
              >
                Transaction Expense is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Transaction Income</label>
              <multiselect
                      v-model="createTransactionCodeFormBody.trnIncome"
                      :options="noYesOption"
                      placeholder="Transaction Income"
                      @close="$v.createTransactionCodeFormBody.trnIncome.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.createTransactionCodeFormBody.trnIncome.$error">
              <p
                v-if="!$v.createTransactionCodeFormBody.trnIncome.required"
                class="form-error-message"
              >
                Transaction Income is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Transaction Customer</label>
              <multiselect
                      v-model="createTransactionCodeFormBody.trnCustomer"
                      :options="noYesOption"
                      placeholder="Transaction Customer"
                      @close="$v.createTransactionCodeFormBody.trnCustomer.$touch()"
              ></multiselect>
            </div>

            <template
              v-if="$v.createTransactionCodeFormBody.trnCustomer.$error"
            >
              <p
                v-if="!$v.createTransactionCodeFormBody.trnCustomer.required"
                class="form-error-message"
              >
                Transaction Customer is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Transaction Asset</label>
              <multiselect
                      v-model="createTransactionCodeFormBody.trnAsset"
                      :options="noYesOption"
                      placeholder="Transaction Asset"
                      @close="$v.createTransactionCodeFormBody.trnAsset.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.createTransactionCodeFormBody.trnAsset.$error">
              <p
                v-if="!$v.createTransactionCodeFormBody.trnAsset.required"
                class="form-error-message"
              >
                Transaction Asset is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Transaction Liability</label>
              <multiselect
                      v-model="createTransactionCodeFormBody.trnLiability"
                      :options="noYesOption"
                      placeholder="Transaction Liability"
                      @close="$v.createTransactionCodeFormBody.trnLiability.$touch()"
              ></multiselect>
            </div>

            <template
              v-if="$v.createTransactionCodeFormBody.trnLiability.$error"
            >
              <p
                v-if="!$v.createTransactionCodeFormBody.trnLiability.required"
                class="form-error-message"
              >
                Transaction Liability is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Transaction Debit</label>
              <multiselect
                      v-model="createTransactionCodeFormBody.trnDebit"
                      :options="noYesOption"
                      placeholder="Transaction Debit"
                      @close="$v.createTransactionCodeFormBody.trnDebit.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.createTransactionCodeFormBody.trnDebit.$error">
              <p
                v-if="!$v.createTransactionCodeFormBody.trnDebit.required"
                class="form-error-message"
              >
                Transaction Debit is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Transaction Credit</label>
              <multiselect
                      v-model="createTransactionCodeFormBody.trnCredit"
                      :options="noYesOption"
                      placeholder="Transaction Credit"
                      @close="$v.createTransactionCodeFormBody.trnCredit.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.createTransactionCodeFormBody.trnCredit.$error">
              <p
                v-if="!$v.createTransactionCodeFormBody.trnCredit.required"
                class="form-error-message"
              >
                Transaction Credit is required
              </p>
            </template>
          </div>
        </div>
      </div>
      <p class="text-right">
        <span v-if="transactionCode.moduleLoading" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Create Transaction Code
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateTTransactionCode",
  data() {
    return {
      createTransactionCodeFormBody: this.createFreshTransactionCodeFormBodyObject(),

      options: ["Head of Teller", "Teller"],
      transactionTypeOption: [
        "Transfer Inbound",
        "Transfer Out Going",
        "Cash Deposit"
      ],
      noYesOption: ["No", "Yes"],
      trnChargeApplyOption: ["No", "Yes"],
      trnChargeTypeOption: ["Flat"],
      trnVatTypeOption: ["Flat"]
    };
  },
  computed: {
    ...mapState(["transactionCode"])
  },
  validations: {
    createTransactionCodeFormBody: {
      trnCode: { required },
      trnDescription: { required },
      trnExpense: { required },
      trnIncome: { required },
      trnCustomer: { required },
      trnAsset: { required },
      trnLiability: { required },
      trnDebit: { required },
      trnCredit: { required }
    }
  },
  methods: {
    createTransactionCode() {
      this.createTransactionCodeFormBody.trnMaker = this.$store.getters["user/getUserId"];


      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch(
            "transactionCode/createTransactionCode",
            this.createTransactionCodeFormBody,
            {
              root: true
            }
          )
          .then(() => {
            this.createTransactionCodeFormBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshTransactionCodeFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
