// create a new Bank
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import JournalService from "../../services/newServices/JournalService";
const journalService = new JournalService();

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    // moduleLoadingTwo: true,
    moduleLoadingThree: true,
    moduleLoadingFour: true,
    moduleLoadingFive: false,
    allBatches: [],
    currentBatch: {},
    currentBatchEntries: [],
    // bankStatData: {},
    journals: [],
    journalsCheckersQueue: [],
    bulkEntries: []
  };
};
export const state = getDefaultState();

export const getters = {
  getCurrentBatch: state => {
    return state.currentBatch;
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  // SET_LOADING_TWO(state, status) {
  //     state.moduleLoadingTwo = status;
  // },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_ALL_JOURNALS_CHECKERS_QUEUE(state, payload) {
    state.journalsCheckersQueue = payload;
  },
  // SET_BANK_STATS(state, payload) {
  //     state.bankStatData = payload;
  // },
  SET_ALL_JOURNALS(state, payload) {
    state.journals = payload;
  },
  SET_CURRENT_BATCH(state, payload) {
    state.currentBatch = payload;
  },
  SET_CURRENT_BATCH_NAME(state, payload) {
    state.currentBatch.batchName = payload;
  },
  SET_CURRENT_BATCH_REFERENCE(state, payload) {
    state.currentBatch.batchReference = payload;
  },
  SET_ALL_BATCHES(state, payload) {
    state.allBatches = payload;
  },
  SET_CURRENT_BATCH_ENTRIES(state, payload) {
    state.currentBatchEntries = payload;
  }
};
export const actions = {
  bulkEntriesToBatch({ commit }, { entries }) {
    commit("TEST", "TEST");
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      branchCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BRANCH_CODE
      ),
      maker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
      batchReference: StoreUtils.rootGetter(
        StoreUtils.getters.journal.GET_CURRENT_BATCH
      ).batchReference,
      requestType: "submitBulkBatchEntry",
      data: entries
    };

    const successAction = responseData => {
      StoreUtils.dispatch(
        "journal/setCurrentBatchReference",
        responseData.batchReference
      );
      RouterUtils.changeRouteTo(RouterUtils.routes.journal.ADD_ENTRY_TO_BATCH);
    };

    return journalService.bulkEntriesToBatch(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  openBatch({ dispatch }, payload) {
    dispatch("setCurrentBatchName", payload.batchName);
    payload.batchBankCode = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BANK_CODE
    );
    payload.batchMaker = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_ID
    );

    const successAction = responseData => {
      StoreUtils.dispatch(
        "journal/setCurrentBatchReference",
        responseData.batchReference
      );
      RouterUtils.changeRouteTo(RouterUtils.routes.journal.ADD_ENTRY_TO_BATCH);
    };

    return journalService.openBatch(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  addEntryToBatch({ commit }, payload) {
    commit("TEST", "TEST");
    payload.batchReference = StoreUtils.rootGetter(
      StoreUtils.getters.journal.GET_CURRENT_BATCH
    ).batchReference;

    return journalService.addEntryToBatch(
      payload,
      null,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  closeBatch({ commit }, payload) {
    commit("TEST", "TEST");

    const successAction = () => {
      StoreUtils.dispatch(StoreUtils.actions.journal.FETCH_ALL_BATCHES);
    };

    return journalService.closeBatch(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setAllBatches({ commit }, payload) {
    commit("SET_ALL_BATCHES", payload);
  },
  fetchAllBatches() {
    const payload = {
      batchBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      readAll: "YES"
    };
    const successAction = responseData => {
      StoreUtils.dispatch("journal/setAllBatches", responseData.data);
    };
    return journalService.fetchAllBatches(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setCurrentBatchEntries({ commit }, payload) {
    commit("SET_CURRENT_BATCH_ENTRIES", payload);
  },
  fetchCurrentBatchEntries() {
    const payload = {
      batchBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      batchReference: StoreUtils.rootGetter(
        StoreUtils.getters.journal.GET_CURRENT_BATCH
      ).batchReference
    };

    const successAction = responseData => {
      StoreUtils.dispatch(
        "journal/setCurrentBatchEntries",
        responseData.entries
      );
    };
    return journalService.fetchOneBatch(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setCurrentBatch({ commit }, payload) {
    commit("SET_CURRENT_BATCH", payload);
  },
  setCurrentBatchName({ commit }, payload) {
    commit("SET_CURRENT_BATCH_NAME", payload);
  },
  setCurrentBatchReference({ commit }, payload) {
    commit("SET_CURRENT_BATCH_REFERENCE", payload);
  },

  deleteBatch({ commit }, batchReference) {
    commit("TEST", "TEST");
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      status: "DELETED",
      batchReference
    };
    const successAction = () => {
      StoreUtils.dispatch(StoreUtils.actions.journal.FETCH_ALL_BATCHES);
    };

    return journalService.deleteBatch(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  deleteEntryFromCurrentBatch({ commit }, entryId) {
    commit("TEST", "TEST");

    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      status: "DELETED",
      entryId,
      batchReference: StoreUtils.rootGetter(
        StoreUtils.getters.journal.GET_CURRENT_BATCH
      ).batchReference
    };

    const successAction = () => {
      StoreUtils.dispatch(
        StoreUtils.actions.journal.FETCH_CURRENT_BATCH_ENTRIES
      );
    };

    return journalService.deleteBatchEntry(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setAllJournalsCheckersQueue({ commit }, payload) {
    commit("SET_ALL_JOURNALS_CHECKERS_QUEUE", payload);
  },
  allJournalsCheckersQueue() {
    let payload = {
      cqType: "BATCH",
      cqMode: "READ",
      cqBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.dispatch(
        "journal/setAllJournalsCheckersQueue",
        responseData.data
      );
    };
    return journalService.fetchAllBatchCheckersQueue(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  checkerAuthorizeJournals({ commit }, payload) {
    // start loader
    commit("TEST", "TEST");

    const successAction = () => {
      StoreUtils.dispatch("journal/allJournalsCheckersQueue");
    };

    return journalService.authorizeBatchChecker(
      payload,
      successAction,
      LoaderUtils.types.TABLE_ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
