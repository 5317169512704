<script>
import router from "@/router";

import AppNavBar from "../../components/AppComponents/nav-bar";
import AppSideBar from "../../components/AppComponents/side-bar";
import AppRightBar from "../../components/AppComponents/right-bar";
import AppFooter from "../../components/AppComponents/footer";
import UserInactivityWarningCard from "../../components/Kleyton/Cards/Auth/UserInactivityWarningCard";
import {mapState} from "vuex";

export default {
  components: {UserInactivityWarningCard, AppNavBar, AppSideBar, AppRightBar, AppFooter },
  data() {
    return {
      isMenuCondensed: false
    };
  },
  computed: {
    ...mapState(["auth"])
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  }
};
</script>

<template>
  <div id="layout-wrapper">
    <AppNavBar />
    <AppSideBar :is-condensed="isMenuCondensed" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <AppFooter />
      <UserInactivityWarningCard v-if="auth.appIsInactive" />
    </div>
    <AppRightBar />
  </div>
</template>
