<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>

    <textarea
      class="form-control"
      rows="5"
      v-model="textAreaValue"
      @input="updateValue"
      v-bind="$attrs"
    ></textarea>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    inputType: {
      type: String,
      default: "text"
    },
    value: [String, Number, Object],
    hasPreIcon: {
      type: Boolean,
      default: false
    },
    label: String
  },
  data() {
    return {
      textAreaValue: ""
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style scoped>
.base-input-holder {
  background-color: #ffffff;
  padding: 13px 13px 11px 13px;
  transition: ease-in-out all 0.2s;
}
.error .base-input-holder {
  padding: 13px 13px 25px 13px !important;
}
</style>
