import ReportService from "../../services/oldServices/ReportService.js";

export const namespaced = true;

//utility
function objIsEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    moduleLoadingTwo: false,
    moduleLoadingThree: true,
    moduleLoadingFour: true,
    moduleLoadingFive: true,
    moduleLoadingSix: true,
    moduleLoadingSeven: false,
    moduleLoadingEight: false,
    reportStatData: {},
    reports: [],
    singleReport: [],
    columns: [],
    jsonColumns: [],
    tableList: [],
    reportsCheckersQueue: []
  };
};
export const state = getDefaultState();

export const getters = {
  // Checks if Report stats have already been fetched
  isReportStatsFetched: state => {
    return !objIsEmpty(state.reportStatData);
  },
  columnNames: state => {
    return state.columns.map(sola => {
      return sola.columnName;
    });
  },

  reportName: state => {
    return state.reportName;
  },

  singleReportObject: state => {
    return state.singleReport;
  },

  tableList: state => {
    return state.tableList;
  },

  tableListString: state => {
    return state.tableList.map(tbl => {
      return tbl.tableName;
    });
  },

  columnObjects: state => {
    return state.columns.map(obj => {
      return {
        key: obj.columnName,
        label: obj.columnName,
        sortable: true,
        class: "text-center"
      };
    });

    //return state.columns
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_BRANCH_STATS(state, payload) {
    state.reportStatData = payload;
  },
  SET_ALL_REPORTS(state, payload) {
    state.reports = payload;
  },

  SET_SINGLE_REPORT(state, payload) {
    state.singleReport = payload;
  },
  SET_COLUMNS(state, payload) {
    state.columns = payload;
  },
  SET_ALL_SINGLE_REPORT(state, payload) {
    state.singleReport = payload;
  },

  SET_ALL_TABLE_LIST(state, payload) {
    state.tableList = payload;
  },
  SET_LOADING_SIX(state, status) {
    state.moduleLoadingSix = status;
  },
  SET_LOADING_SEVEN(state, status) {
    state.moduleLoadingSeven = status;
  },
  SET_ALL_REPORTS_CHECKERS_QUEUE(state, payload) {
    state.reportsCheckersQueue = payload;
  }
};

export const actions = {
  checkerAuthorizeReport({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_SEVEN", true);

    return ReportService.checkerAuthorizeReport(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_SEVEN", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_SEVEN", false);

        throw error;
      });
  },

  allReportsCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_SIX", true);

    return ReportService.allReportsCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_SIX", false);

        const responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_REPORTS_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  },

  // get All Reports
  allReports({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_THREE", true);

    return ReportService.allReports(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_REPORTS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // get Single Reports
  readSingleReports({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FOUƒR", true);

    return ReportService.readSingleReport(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FOUR", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_SINGLE_REPORT", responseData.reports);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FOUR", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // get Single Reports
  readColumns({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FIVE", true);

    return ReportService.readColumn(payload).then(response => {
      // stop loader
      commit("SET_LOADING_FIVE", false);

      let responseData = response.data;

      if (responseData.responseCode === "00") {
        commit("SET_COLUMNS", responseData.columns);
      } else {
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Error Loading Table | Try again`
        };
        dispatch("notification/add", notification, { root: true });
      }
    });
  },

  // get All Reports
  singleReport({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_THREE", true);

    return ReportService.singleReport(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data.reports;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_SINGLE_REPORT", responseData.data);
        } else {
          // push Notification

          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader

        commit("SET_LOADING_FIVE", false);

        commit("SET_LOADING_THREE", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  queryReport({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_THREE", true);

    return ReportService.queryReport(payload)
      .then(response => {
        commit("SET_LOADING_THREE", false);
        let responseData = response.data;
        if (responseData.responseCode == "00") {
          commit("SET_SINGLE_REPORT", responseData);
        } else {
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        commit("SET_LOADING_THREE", false);
        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  createReport({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING", true);

    return ReportService.createReport(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);
        throw error;
      });
  },

  readAllTables({ commit, dispatch }, payload) {
    //start loader
    commit("SET_LOADING_FIVE", true);

    return ReportService.readTableList(payload)
      .then(response => {
        commit("SET_LOADING_FIVE", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("SET_ALL_TABLE_LIST", responseData.data);
        } else {
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        commit("SET_LOADING_FIVE", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  }
};
