<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <div class="col-xl-3">
        <BankStatsPanel />
      </div>
      <!-- end col -->
      <div class="col-xl-9">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Upload Bank Logo</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <UploadBankLogoForm></UploadBankLogoForm>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Create Bank Component
 */

import { required } from "vuelidate/lib/validators";


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";

export default {
  page: {
    title: "Create Bank",
    meta: [{ name: "Create Bank", content: window.__env.app.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "Create Bank",
      statData: [
        {
          icon: "bx bxs-bank",
          title: "Total Banks",
          value: "23"
        },
        {
          icon: "bx bx-git-branch",
          title: "Total Branches",
          value: "203"
        },
        {
          icon: "bx bxs-business",
          title: "Total Customers",
          value: "8"
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Total Transactions",
          value: "213"
        }
      ],

      items: [
        {
          text: "Bank",
          href: "/"
        },
        {
          text: "Create Bank",
          active: true
        }
      ]
    };
  },
  validations: {
    createBankFormBody: {
      date: { required },
      message: { required },
      country: { required },
      name: { required }
    }
  },
  methods: {

  }
};
</script>
