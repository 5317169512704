const getDefaultState = () => {
  return {
    baseUrl: "https://rjmfd78pki.execute-api.us-east-1.amazonaws.com/dev/"
  };
};

export const state = getDefaultState();

export const getters = {
  getBaseUrl(state) {
    return state.baseUrl;
  }
};

export const mutations = {};

export const actions = {};
