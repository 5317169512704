<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <p style="justify-content: center">
      <span v-if="customer.moduleLoading" class="formLoaderHold">
        <BaseComponentLoader />
      </span>
    </p>
    <!-- end page title -->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4"></h4>
            <h5>User Details</h5>
            <b-row>
              <b-col>
                <p><b>First Name:</b></p>
                <p>{{ currentCustomer.firstName }}</p>
              </b-col>
              <b-col>
                <p><b>Last Name:</b></p>
                <p>{{ currentCustomer.lastName }}</p>
              </b-col>
              <b-col>
                <p><b>Middle Name:</b></p>
                <p>{{ currentCustomer.middleName }}</p>
              </b-col>
              <b-col>
                <p>
                  <b>Address:</b>
                  <span
                    class="pl-3"
                    v-if="currentCustomer.addressVerified === 'NO' && (currentCustomer.addressComment !== '' && currentCustomer.addressComment != null)"
                  >
                    <b-badge variant="danger">Declined</b-badge><br />
                    <i>reason: {{ currentCustomer.addressComment }}</i>
                  </span>
                  <span
                    class="pl-3"
                    v-else-if="currentCustomer.addressVerified === 'YES'"
                  >
                    <b-badge variant="success">Approved</b-badge>
                  </span>
                  <span class="pl-3" v-else-if="currentCustomer.address === ''">
                    <b-badge>Not Uploaded</b-badge>
                  </span>
                  <span v-else>
                    <i
                      title="Approve"
                      @click="approveAddress"
                      style="position: absolute; bottom: 2.9rem;  left: 8rem; font-size: 13px; cursor: pointer; color: #198754;"
                      class="mdi mdi-check-circle font-size-18"
                    ></i>
                    <i
                      title="Decline"
                      @click="openModal('address')"
                      style="position: absolute; bottom: 2.9rem; left: 6.5rem; font-size: 13px; cursor: pointer; color: #DC3545;"
                      class="mdi mdi-cancel font-size-18"
                    ></i>
                  </span>
                </p>
                <p>{{ currentCustomer.address }}</p>
              </b-col>
              <b-col>
                <p><b>Phone Number:</b></p>
                <p>{{ currentCustomer.phoneNumber }}</p>
              </b-col>
              <b-col>
                <p>
                  <b>Bvn:</b>
                  <span
                    class="pl-3"
                    v-if="currentCustomer.bvnVerified === 'NO' && (currentCustomer.bvnComment !== '' && currentCustomer.bvnComment!== null)"
                  >
                    <b-badge variant="danger">Declined</b-badge><br />
                    <i>reason: {{ currentCustomer.bvnComment }}</i>
                  </span>
                  <span
                    class="pl-3"
                    v-else-if="currentCustomer.bvnVerified === 'YES'"
                  >
                    <b-badge variant="success">Approved</b-badge>
                  </span>
                  <span class="pl-3" v-else-if="currentCustomer.bvn === ''">
                    <b-badge>Not Uploaded</b-badge>
                  </span>
                  <span v-else>
                    <i
                      title="Approve"
                      @click="approveBvn"
                      style="position: absolute; bottom: 2.9rem;  left: 8rem; font-size: 13px; cursor: pointer; color: #198754;"
                      class="mdi mdi-check-circle font-size-18"
                    ></i>
                    <i
                      title="Decline"
                      @click="openModal('bvn')"
                      style="position: absolute; bottom: 2.9rem; left: 6.5rem; font-size: 13px; cursor: pointer; color: #DC3545;"
                      class="mdi mdi-cancel font-size-18"
                    ></i>
                  </span>
                </p>
                <p>{{ currentCustomer.bvn }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p><b>Username:</b></p>
                <p>{{ currentCustomer.username }}</p>
              </b-col>
              <b-col>
                <p><b>Date of Birth:</b></p>
                <p>{{ currentCustomer.dob }}</p>
              </b-col>
            </b-row>

            <h5 style="padding-top:3rem ">Utility Details</h5>
            <b-row>
              <b-col>
                <p>
                  <b>Logo:</b>
                  <span  class="pl-3" v-if="currentCustomer.logoVerified === 'NO' && (currentCustomer.logoComment !== '' && currentCustomer.logoComment != null)">
                    <b-badge variant="danger" >Declined</b-badge><br>
                    <i>reason: {{ currentCustomer.logoComment }}</i>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.logoVerified === 'YES'">
                    <b-badge variant="success">Approved</b-badge>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.logo === ''">
                    <b-badge>Not Uploaded</b-badge>
                  </span>
                  <span v-else>
                    <i
                      title="Approve"
                      @click="approveLogo"
                      style="position: absolute; bottom: 2rem;  left: 9rem; font-size: 13px; cursor: pointer; color: #198754; "
                      class="mdi mdi-check-circle font-size-18"
                    ></i>
                    <i
                      title="Decline"
                      @click="openModal('logo')"
                      style="position: absolute; bottom: 2rem; left: 7.5rem; font-size: 13px; cursor: pointer; color: #DC3545;"
                      class="mdi mdi-cancel font-size-18"
                    ></i>
                  </span>
                </p>
                <p>Logo: <a target="_blank" :href="currentCustomer.logo">{{ currentCustomer.logo?'view':'No Upload' }}</a></p>
              </b-col>
              <b-col>
                <p>
                  <b>Picture:</b>
                  <span  class="pl-3" v-if="currentCustomer.pictureVerified === 'NO' && (currentCustomer.pictureComment !== '' && currentCustomer.pictureComment != null)">
                    <b-badge variant="danger" >Declined</b-badge><br>
                    <i>reason: {{ currentCustomer.pictureComment }}</i>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.pictureVerified === 'YES'">
                    <b-badge variant="success">Approved</b-badge>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.picture === ''">
                    <b-badge>Not Uploaded</b-badge>
                  </span>
                  <span v-else>
                    <i
                      title="Approve"
                      @click="approvePicture"
                      style="position: absolute; bottom: 2rem;  left: 9rem; font-size: 13px; cursor: pointer; color: #198754; "
                      class="mdi mdi-check-circle font-size-18"
                    ></i>
                    <i
                      title="Decline"
                      @click="openModal('picture')"
                      style="position: absolute; bottom: 2rem; left: 7.5rem; font-size: 13px; cursor: pointer; color: #DC3545;"
                      class="mdi mdi-cancel font-size-18"
                    ></i>
                  </span>
                </p>
                <p>Picture: <a target="_blank" :href="currentCustomer.picture">{{ currentCustomer.picture?'view':'No Upload' }}</a></p>
              </b-col>
              <b-col>
                <p>
                  <b>Signature:</b>
                  <span  class="pl-3" v-if="currentCustomer.signatureVerified === 'NO' && (currentCustomer.signatureComment !== '' && currentCustomer.signatureComment != null)">
                    <b-badge variant="danger" >Declined</b-badge><br>
                    <i>reason: {{ currentCustomer.signatureComment }}</i>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.signatureVerified === 'YES'">
                    <b-badge variant="success">Approved</b-badge>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.signature === ''">
                    <b-badge>Not Uploaded</b-badge>
                  </span>
                  <span v-else>
                    <i
                      title="Approve"
                      @click="approveSignature"
                      style="position: absolute; bottom: 2rem;  left: 9rem; font-size: 13px; cursor: pointer;color: #198754; "
                      class="mdi mdi-check-circle font-size-18"
                    ></i>
                    <i
                      title="Decline"
                      @click="openModal('sign')"
                      style="position: absolute; bottom: 2rem; left: 7.5rem; font-size: 13px; cursor: pointer; color: #DC3545;"
                      class="mdi mdi-cancel font-size-18"
                    ></i>
                  </span>
                </p>
                <p>Signature: <a target="_blank" :href="currentCustomer.signature">{{ currentCustomer.signature?'view':'No Upload' }}</a></p>
              </b-col>
              <b-col>
                <p>
                  <b>Utility Bill:</b>
                  <span  class="pl-3" v-if="currentCustomer.utilityBillVerified === 'NO' && (currentCustomer.utilityBillComment !== '' && currentCustomer.utilityBillComment != null)">
                    <b-badge variant="danger" >Declined</b-badge><br>
                    <i>reason: {{ currentCustomer.utilityBillComment }}</i>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.utilityBillVerified === 'YES'">
                    <b-badge variant="success">Approved</b-badge>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.utilityBill === ''">
                    <b-badge>Not Uploaded</b-badge>
                  </span>
                  <span v-else>
                    <i
                      title="Approve"
                      @click="approveUtilBill"
                      style="position: absolute; bottom: 2rem;  left: 9rem; font-size: 13px; cursor: pointer; color: #198754; "
                      class="mdi mdi-check-circle font-size-18"
                    ></i>
                    <i
                      title="Decline"
                      @click="openModal('utilBill')"
                      style="position: absolute; bottom: 2rem; left: 7.5rem; font-size: 13px; cursor: pointer; color: #DC3545;"
                      class="mdi mdi-cancel font-size-18"
                    ></i>
                  </span>
                </p>
                <p>Utility Bill: <a target="_blank" :href="currentCustomer.utilityBill">{{ currentCustomer.utilityBill?'view':'No Upload' }}</a></p>
              </b-col>
            </b-row>

            <h5 style="padding-top:3rem">ID Card Details</h5>
            <b-row>
              <b-col>
                <p>
                  <b>Id Card:</b>
                  <span
                      class="pl-3"
                      v-if="
                      currentCustomer.idCardVerified === 'NO' &&
                        (currentCustomer.idCardComment !== '' &&
                          currentCustomer.idCardCommen != null)
                    "
                  >
                    <b-badge variant="danger">Declined</b-badge><br />
                    <i>reason: {{ currentCustomer.idCardComment }}</i>
                  </span>
                  <span
                      class="pl-3"
                      v-else-if="currentCustomer.idCardVerified === 'YES'"
                  >
                    <b-badge variant="success">Approved</b-badge>
                  </span>
                  <span class="pl-3" v-else-if="currentCustomer.idCard === ''">
                    <b-badge>Not Uploaded</b-badge>
                  </span>
                  <span v-else>
                    <i
                        title="Approve"
                        @click="approveIdCard"
                        style="position: absolute; bottom: 2rem;  left: 9rem; font-size: 13px; cursor: pointer; color: #198754;"
                        class="mdi mdi-check-circle font-size-18"
                    ></i>
                    <i
                        title="Decline"
                        @click="openModal('idCard')"
                        style="position: absolute; bottom: 2rem; left: 7.5rem; font-size: 13px; cursor: pointer; color: #DC3545;"
                        class="mdi mdi-cancel font-size-18"
                    ></i>
                  </span>
                </p>
                <p>Id Card Type: {{ currentCustomer.idCardType }}</p>
                <p>Id Card Number: {{ currentCustomer.idCardNumber }}</p>
                <p>Id Card: <a target="_blank" :href="currentCustomer.idCard">{{ currentCustomer.idCard?'view':'No Upload' }}</a></p>
              </b-col>
            </b-row>

            <h5 style="padding-top:3rem">CAC Details</h5>
            <b-row>
              <b-col>
                <p>
                  <b>CAC Card:</b>
                  <span  class="pl-3" v-if="currentCustomer.cacVerified === 'NO' && (currentCustomer.cacComment !== '' && currentCustomer.cacComment != null)">
                    <b-badge variant="danger" >Declined</b-badge><br>
                    <i>reason: {{ currentCustomer.cacComment }}</i>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.cacVerified === 'YES'">
                    <b-badge variant="success">Approved</b-badge>
                  </span>
                  <span  class="pl-3" v-else-if="currentCustomer.cac === ''">
                    <b-badge>Not Uploaded</b-badge>
                  </span>
                  <span v-else>
                    <i
                        title="Approve"
                        @click="approveCacCard"
                        style="position: absolute; bottom: 2rem;  left: 9rem; font-size: 13px; cursor: pointer; color: #198754; "
                        class="mdi mdi-check-circle font-size-18"
                    ></i>
                    <i
                        title="Decline"
                        @click="openModal('cacCard')"
                        style="position: absolute; bottom: 2rem; left: 7.5rem; font-size: 13px; cursor: pointer; color: #DC3545;"
                        class="mdi mdi-cancel font-size-18"
                    ></i>
                  </span>
                </p>
                <p>CacNumber: {{ currentCustomer.cacNumber }}</p>
                <p>CAC: <a target="_blank" :href="currentCustomer.cac">{{ currentCustomer.cac?'view':'No Upload' }}</a></p>
              </b-col>
            </b-row>

          </div>
        </div>
      </div>
    </div>
    <vodal
      :show="modalOpen"
      :width="400"
      :height="200"
      measure="px"
      animation="zoom"
      @hide="modalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Enter your comment</h4>

          <form @submit.prevent="submitComment">
            <div class="row">
              <BaseInput
                style="width: 100%"
                :inputType="'text'"
                placeholder="Comment"
                v-model="comment"
                :hasPreIcon="false"
                :label="'Comment'"
                class=""
              >
              </BaseInput>
            </div>

            <p class="text-right">
              <span v-if="customer.moduleLoading" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Decline
                </button>
              </span>
            </p>
          </form>
        </div>
      </div>
    </vodal>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../router/layouts/main";
import BaseInput from "../../components/BaseComponents/BaseInput";
import PageHeader from "../../components/AppComponents/page-header";
import UpgradeLogsRequest from "../../model/requests/UpgradeLogsRequest";
import { mapState } from "vuex";

export default {
  page: {
    title: "",
    meta: [{ name: "", content: window.__env.app.description }]
  },
  name: "CustomerUpgradeData",
  data() {
    return {
      modalOpen: false,
      model: UpgradeLogsRequest.logsApproveRequest,
      declineModel: UpgradeLogsRequest.logsDeclineRequest,
      users: [
        {
          id: 1,
          username: "test"
        }
      ],
      // Data will be fetched from backend and populated here
      title: "",
      items: [
        {
          text: "",
          href: ""
        },
        {
          text: "",
          active: true
        }
      ],
      commentForMethod: "",
      comment: "",
      bvnComment: "",
      declineIdCardComment: "",
      declineIdNumberComment: "",
      declineIdTypeComment: "",
      declineCacComment: "",
      declineCacTypeComment: "",
      declineLogoComment: "",
      declineSignatureComment: "",
      declinePictureComment: "",
      declineUtilBillComment: "",
      declineAddressComment: ""
    };
  },
  components: {
    BaseInput,
    Layout,
    PageHeader
  },
  methods: {
    approveBvn() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "BVN";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveIdCard() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "ID_CARD";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveIdType() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "ID_CARD";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveIdNumber() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "ID_CARD";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveCacCard() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "CAC";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveCacType() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "CAC";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveLogo() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "LOGO";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approvePicture() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "PICTURE";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveSignature() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "SIGNATURE";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveUtilBill() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "UTILITY_BILL";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },
    approveAddress() {
      this.model.username = this.currentCustomer.username;
      this.model.type = "ADDRESS";
      this.$store.dispatch("customer/approveLogs", this.model, {});
    },

    openModal(method) {
      this.modalOpen = true;
      if (method === "bvn") {
        this.commentForMethod = "bvn";
        this.comment = this.bvnComment;
      } else if (method === "address") {
        this.commentForMethod = "address";
        this.comment = this.declineAddressComment;
      } else if (method === "idCard") {
        this.commentForMethod = "idCard";
        this.comment = this.declineIdCardComment;
      } else if (method === "idCardType") {
        this.commentForMethod = "idCardType";
        this.comment = this.declineIdTypeComment;
      } else if (method === "idCardNumb") {
        this.commentForMethod = "idCardNumb";
        this.comment = this.declineIdNumberComment;
      } else if (method === "cacCard") {
        this.commentForMethod = "cacCard";
        this.comment = this.declineCacComment;
      } else if (method === "cacType") {
        this.commentForMethod = "cacType";
        this.comment = this.declineCacTypeComment;
      } else if (method === "logo") {
        this.commentForMethod = "logo";
        this.comment = this.declineLogoComment;
      } else if (method === "sign") {
        this.commentForMethod = "sign";
        this.comment = this.declineSignatureComment;
      } else if (method === "picture") {
        this.commentForMethod = "picture";
        this.comment = this.declinePictureComment;
      } else if (method === "utilBill") {
        this.commentForMethod = "utilBill";
        this.comment = this.declineUtilBillComment;
      }
    },

    submitComment() {
      if (this.commentForMethod === "bvn") {
        this.bvnComment = this.comment;
      } else if (this.commentForMethod === "address") {
        this.declineAddressComment = this.comment;
      } else if (this.commentForMethod === "idCard") {
        this.declineIdCardComment = this.comment;
      } else if (this.commentForMethod === "idCardType") {
        this.declineIdTypeComment = this.comment;
      } else if (this.commentForMethod === "idCardNumb") {
        this.declineIdNumberComment = this.comment;
      } else if (this.commentForMethod === "cacCard") {
        this.declineCacComment = this.comment;
      } else if (this.commentForMethod === "cacType") {
        this.declineCacTypeComment = this.comment;
      } else if (this.commentForMethod === "logo") {
        this.declineLogoComment = this.comment;
      } else if (this.commentForMethod === "sign") {
        this.declineSignatureComment = this.comment;
      } else if (this.commentForMethod === "picture") {
        this.declinePictureComment = this.comment;
      } else if (this.commentForMethod === "utilBill") {
        this.declineUtilBillComment = this.comment;
      }

      if (this.comment !== "") {
        if (this.commentForMethod === "bvn") {
          this.declineBvn();
        } else if (this.commentForMethod === "address") {
          this.declineAddress();
        } else if (this.commentForMethod === "idCard") {
          this.declineIdCard();
        } else if (this.commentForMethod === "idCardType") {
          this.declineIdType();
        } else if (this.commentForMethod === "idCardNumb") {
          this.declineIdNumber();
        } else if (this.commentForMethod === "cacType") {
          this.declineCacType();
        } else if (this.commentForMethod === "logo") {
          this.declineLogo();
        } else if (this.commentForMethod === "sign") {
          this.declineSignature();
        } else if (this.commentForMethod === "utilBill") {
          this.declineUtilBill();
        } else if (this.commentForMethod === "picture") {
          this.declinePicture();
        } else if (this.commentForMethod === "cacCard") {
          this.declineCacCard();
        }
      }

      this.modalOpen = false;
    },
    declineBvn() {
      this.declineModel.comment = this.bvnComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "BVN";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineIdCard() {
      this.declineModel.comment = this.declineIdCardComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "ID_CARD";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineIdType() {
      this.declineModel.comment = this.declineIdTypeComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "ID_CARD";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineIdNumber() {
      this.declineModel.comment = this.declineIdNumberComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "ID_CARD";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineCacCard() {
      this.declineModel.comment = this.declineCacComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "CAC";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineCacType() {
      this.declineModel.comment = this.declineCacTypeComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "CAC";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineLogo() {
      this.declineModel.comment = this.declineLogoComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "LOGO";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineSignature() {
      this.declineModel.comment = this.declineSignatureComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "SIGNATURE";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineUtilBill() {
      this.declineModel.comment = this.declineUtilBillComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "UTILITY_BILL";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declinePicture() {
      this.declineModel.comment = this.declineUtilBillComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "PICTURE";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    },
    declineAddress() {
      this.declineModel.comment = this.declineAddressComment;
      this.declineModel.username = this.currentCustomer.username;
      this.declineModel.type = "ADDRESS";
      this.$store.dispatch("customer/declineLogs", this.declineModel, {});
    }
  },
  computed: {
    ...mapState(["loader", "appUtilities", "customer"]),
    currentCustomer() {
      return this.$route.params.currentCustomer;
    }
  }
};
</script>

<style>
.user-container {
  display: inline-block;
  width: 20%;
  padding: 10px;
}

.user-data {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.dropdown {
  display: flex;
  justify-content: space-between;
}

.logo-and-picture-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.logo-container,
.picture-container {
  flex: 1;
}
</style>
