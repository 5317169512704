<template>
  <div>
    <div v-if="appUtilities.moduleLoadingTwo">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="createVault">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Vault Account Number</label>
                <multiselect
                  v-model="createVaultFormBody.vaultAccountNumber"
                  :options="internalAccountsUtilityOption"
                  placeholder="Vault Account Number"
                  @close="$v.createVaultFormBody.vaultAccountNumber.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.createVaultFormBody.vaultAccountNumber.$error">
                <p
                  v-if="!$v.createVaultFormBody.vaultAccountNumber.required"
                  class="form-error-message"
                >
                  Vault Account Number is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Vault Teller Number</label>
                <multiselect
                  v-model="createVaultFormBody.vaultTellerNumber"
                  :options="tillTellerNumberOptions"
                  placeholder="Vault Teller Number"
                  @close="$v.createVaultFormBody.vaultTellerNumber.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.createVaultFormBody.vaultTellerNumber.$error">
                <p
                  v-if="!$v.createVaultFormBody.vaultTellerNumber.required"
                  class="form-error-message"
                >
                  Vault Teller Number is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <p class="text-left">
          <span v-if="vault.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create Vault
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateVault",
  data() {
    return {
      createVaultFormBody: this.createFreshCreateVaultFormBodyObject(),

      options: ["Head of Vault", "Vault"]
    };
  },
  computed: {
    ...mapState(["vault", "appUtilities"]),
    ...mapGetters({
      tillTellerNumberOptions: "appUtilities/getTellersUtilityOption",
      internalAccountsUtilityOption:
        "appUtilities/getInternalAccountsUtilityOption",
      getInternalAccountsFromOptionUtility:
        "appUtilities/getInternalAccountsFromOptionUtility"
    })
  },
  validations: {
    createVaultFormBody: {
      vaultAccountNumber: { required },
      vaultTellerNumber: { required }
    }
  },
  async created() {
    await this.$store.dispatch("appUtilities/fetchAllTellers", null, {
      root: true
    });
    await this.$store.dispatch("appUtilities/fetchAllInternalAccounts", null, {
      root: true
    });
  },
  methods: {
    createVault() {
      this.createVaultFormBody.vaultCurrency = "NGN";
      this.createVaultFormBody.vaultBankCode = this.$store.getters[
        "user/getUserBankCode"
      ];
      this.createVaultFormBody.vaultBranchCode = "0001";
      this.createVaultFormBody.vaultMaker = this.$store.getters[
        "user/getUserId"
      ];
      this.createVaultFormBody.vaultAccountNumber = this.getInternalAccountsFromOptionUtility(
        this.createVaultFormBody.vaultAccountNumber
      );

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("vault/createVault", this.createVaultFormBody, {
            root: true
          })
          .then(() => {
            this.createVaultFormBody = {};
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshCreateVaultFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped></style>
