<template>
  <div class="user-inactivity-warning text-center">
    <div class="hold">
      <div class="row">
        <div class="col-12">
          <p class="icon text-center">
            <span>
              <svg
                viewBox="0 0 299 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M266.666 0H32C14.327 0 0 14.327 0 32C0 49.673 14.327 64 32 64H266.667C284.34 64 298.667 49.673 298.667 32C298.667 14.327 284.339 0 266.666 0Z"
                  fill="#455A64"
                />
                <path
                  d="M266.666 469.333H32C26.109 469.333 21.333 464.557 21.333 458.666V369.386C21.332 341.699 33.284 315.36 54.122 297.13L91.946 264.021C96.376 260.138 96.819 253.399 92.936 248.969C92.628 248.618 92.297 248.287 91.946 247.979L54.122 214.87C33.284 196.64 21.332 170.301 21.333 142.614V53.334C21.333 47.443 26.109 42.667 32 42.667H266.667C272.558 42.667 277.334 47.443 277.334 53.334V142.614C277.335 170.301 265.383 196.64 244.545 214.87L206.721 247.979C202.291 251.862 201.848 258.601 205.731 263.031C206.039 263.382 206.37 263.713 206.721 264.021L244.545 297.13C265.383 315.36 277.335 341.699 277.334 369.386V458.666C277.333 464.558 272.557 469.333 266.666 469.333Z"
                  fill="#455A64"
                />
                <path
                  d="M42.666 448H255.999V369.387C255.973 347.84 246.64 327.354 230.399 313.195L192.575 280.086C179.273 268.45 177.923 248.234 189.559 234.932C190.496 233.861 191.504 232.853 192.575 231.916L230.399 198.807C246.64 184.648 255.973 164.162 255.999 142.615V64H42.666V142.613C42.692 164.16 52.025 184.646 68.266 198.805L106.09 231.914C119.386 243.557 120.726 263.774 109.084 277.07C108.153 278.133 107.153 279.133 106.09 280.064L68.266 313.195C52.025 327.354 42.692 347.84 42.666 369.387V448Z"
                  fill="#ECEFF1"
                />
                <path
                  d="M212.522 177.259C210.873 173.27 206.983 170.668 202.666 170.667H96C90.109 170.656 85.325 175.424 85.314 181.315C85.309 184.155 86.437 186.88 88.448 188.886L141.781 242.219C145.946 246.383 152.698 246.383 156.864 242.219L210.197 188.886C213.253 185.837 214.171 181.248 212.522 177.259Z"
                  fill="#FFC107"
                />
                <path
                  d="M266.666 448H32C14.327 448 0 462.327 0 480C0 497.673 14.327 512 32 512H266.667C284.34 512 298.667 497.673 298.667 480C298.667 462.327 284.339 448 266.666 448Z"
                  fill="#455A64"
                />
                <path
                  d="M156.501 333.44C152.287 329.625 145.823 329.785 141.802 333.803L42.666 432.918V448H255.999V423.893L156.501 333.44Z"
                  fill="#FFC107"
                />
              </svg>
            </span>
          </p>
          <p class="title">Are You Still There?</p>
          <p class="value">
            You would be automatically logged out in
            {{ inactivityTimeInSeconds }} seconds
          </p>
        </div>
        <div class="col-12">
          <p class="count-down text-right">
            <span>
              <circular-count-down-timer
                :initial-value="inactivityTimeInSeconds"
                :show-negatives="false"
                :step="400"
                @finish="countdownFinished"
              ></circular-count-down-timer>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import { mapState } from "vuex";

export default {
  name: "UserInactivityWarningCard",
  computed: {
    ...mapState(["auth"]),
    inactivityTimeInSeconds() {
      return this.auth.inactivityTimeInSeconds;
    }
  },
  methods: {
    countdownFinished() {
      StoreUtils.dispatch("auth/logOut");
    }
  }
};
</script>

<style scoped></style>
