

<template>
  <div>
    <div v-if="appUtilities.moduleLoadingThree">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="createUser">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Branch Code</label>
                <multiselect
                    @input = "obtainUserBranchCode"
                  v-model="createUserFormBody.branchName"
                  :options="branchOptions"
                  placeholder="Branch Code"
                  @close="$v.createUserFormBody.branchName.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.createUserFormBody.branchName.$error">
                <p
                  v-if="!$v.createUserFormBody.branchName.required"
                  class="form-error-message"
                >
                  Branch Code is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.userEmail"
                placeholder="Email"
                :hasPreIcon="false"
                :label="'User Email'"
                :class="{
                  error: $v.createUserFormBody.userEmail.$error
                }"
                @blur="$v.createUserFormBody.userEmail.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.userEmail.$error">
                <p
                  v-if="!$v.createUserFormBody.userEmail.email"
                  class="form-error-message"
                >
                  Invalid Email
                </p>
                <p
                  v-if="!$v.createUserFormBody.userEmail.required"
                  class="form-error-message"
                >
                  Email is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.userPhone"
                placeholder="Phone Number "
                :hasPreIcon="false"
                :label="'User Phone Number '"
                :class="{
                  error: $v.createUserFormBody.userPhone.$error
                }"
                @blur="$v.createUserFormBody.userPhone.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.userPhone.$error">
                <p
                  v-if="!$v.createUserFormBody.userPhone.required"
                  class="form-error-message"
                >
                  Phone Number is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.userFirstName"
                placeholder="First Name"
                :hasPreIcon="false"
                :label="'User First Name'"
                :class="{
                  error: $v.createUserFormBody.userFirstName.$error
                }"
                @blur="$v.createUserFormBody.userFirstName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.userFirstName.$error">
                <p
                  v-if="!$v.createUserFormBody.userFirstName.required"
                  class="form-error-message"
                >
                  First Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.userMiddleName"
                placeholder="Middle Name"
                :hasPreIcon="false"
                :label="'Middle Name'"
                :class="{
                  error: $v.createUserFormBody.userMiddleName.$error
                }"
                @blur="$v.createUserFormBody.userMiddleName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.userMiddleName.$error">
                <p
                  v-if="!$v.createUserFormBody.userMiddleName.required"
                  class="form-error-message"
                >
                  Middle Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.userLastName"
                placeholder="Last Name"
                :hasPreIcon="false"
                :label="'User Last Name'"
                :class="{
                  error: $v.createUserFormBody.userLastName.$error
                }"
                @blur="$v.createUserFormBody.userLastName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.userLastName.$error">
                <p
                  v-if="!$v.createUserFormBody.userLastName.required"
                  class="form-error-message"
                >
                  Last Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.addressCountry"
                placeholder="Country"
                :hasPreIcon="false"
                :label="'Country'"
                :class="{
                  error: $v.createUserFormBody.addressCountry.$error
                }"
                @blur="$v.createUserFormBody.addressCountry.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.addressCountry.$error">
                <p
                  v-if="!$v.createUserFormBody.addressCountry.required"
                  class="form-error-message"
                >
                  Country is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.addressState"
                placeholder="State"
                :hasPreIcon="false"
                :label="'State'"
                :class="{
                  error: $v.createUserFormBody.addressState.$error
                }"
                @blur="$v.createUserFormBody.addressState.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.addressState.$error">
                <p
                  v-if="!$v.createUserFormBody.addressState.required"
                  class="form-error-message"
                >
                  State is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Role Name</label>
                <multiselect
                 @input="obtainUserRoleId"
                  v-model="createUserFormBody.roleName"
                  :options="roleOptions"
                  placeholder="Role Name"
                ></multiselect>
              </div>

              <!--                          error-->
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.addressCity"
                placeholder="City"
                :hasPreIcon="false"
                :label="'City'"
                :class="{
                  error: $v.createUserFormBody.addressCity.$error
                }"
                @blur="$v.createUserFormBody.addressCity.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.addressCity.$error">
                <p
                  v-if="!$v.createUserFormBody.addressCity.required"
                  class="form-error-message"
                >
                  City is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createUserFormBody.addressStreet"
                placeholder="Street"
                :hasPreIcon="false"
                :label="'Street'"
                :class="{
                  error: $v.createUserFormBody.addressStreet.$error
                }"
                @blur="$v.createUserFormBody.addressStreet.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createUserFormBody.addressStreet.$error">
                <p
                  v-if="!$v.createUserFormBody.addressStreet.required"
                  class="form-error-message"
                >
                  Street is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>CBA Access</label>
                <multiselect
                  v-model="createUserFormBody.setPassword"
                  :options="setPasswordOptions"
                  placeholder="Should user have CBA access?"
                  @close="$v.createUserFormBody.setPassword.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.createUserFormBody.setPassword.$error">
                <p
                  v-if="!$v.createUserFormBody.setPassword.required"
                  class="form-error-message"
                >
                  Set Password is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <p class="text-right">
          <span v-if="bankUser.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create User
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "createUser",
  computed: {
    ...mapState(["bankUser", "appUtilities"]),
    ...mapGetters({
      bankOptions: "appUtilities/getBankUtilityOption",
      branchOptions: "appUtilities/getBranchesUtilityOption",
      roleOptions: "appUtilities/getRolesUtilityOption"
    })
  },
  data() {
    return {
      createUserFormBody: this.createFreshCreateUserFormBodyObject(),
      setPasswordOptions: ["YES", "NO"]
    };
  },
  validations: {
    createUserFormBody: {
      branchName: { required },
      userFirstName: { required },
      userMiddleName: { required },
      userLastName: { required },
      userEmail: { email, required },
      userPhone: { required },
      addressCountry: { required },
      addressState: { required },
      addressCity: { required },
      addressStreet: { required },
      roleName: { required },
      setPassword: { required }
    }
  },
  created() {
    this.$store.dispatch("appUtilities/setUtilityRoles", null, { root: true });
    this.$store.dispatch("appUtilities/setUtilityBranches", null, { root: true });
  },
  methods: {
    obtainUserRoleId(){
      this.createUserFormBody.userRoleId = this.createUserFormBody.roleName.split("|")[1];
    },
    obtainUserBranchCode(){
      this.createUserFormBody.userBranchCode = this.createUserFormBody.branchName.split("|")[1];
    },

    createUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createUserFormBody.userCountryCode = this.$store.getters[
            "user/getUserCountryCode"
            ];
        this.createUserFormBody.userBankCode = this.$store.getters[
            "user/getUserBankCode"
            ];

        // this.createUserFormBody.userBranchCode = this.$store.getters[
        //     "appUtilities/getBranchCodeFromOptionUtility"
        //     ](this.createUserFormBody.userBranchCode);

        // this.createUserFormBody.userRoleId = this.$store.getters[
        //   "appUtilities/getRoleIdFromOptionUtility"
        // ](this.createUserFormBody.userRoleId);

        this.$store
            .dispatch("bankUser/createUser", this.createUserFormBody, {
              root: true
            })
            .then(() => {
              this.createUserFormBody = {
                source: "web"
              };
              this.$v.$reset();
            })
            .catch(() => {});
      }
    },
    createFreshCreateUserFormBodyObject() {
      return {
        source: "web",
        userRoleId: "",
        userBranchCode: ""
      };
    }
  }
};
</script>
<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
