<script>
import { mapState } from "vuex";
export default {
  name: "RolePrivilegesInfoModal",
  data() {
    return {
      modalOpen: false
    };
  },
  props: {
    currentRole: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(["role"])
  },
  methods: {
    fetchRolePrivileges() {
      let payload = {
        roleId: this.currentRole.roleId,
        roleBankCode: this.$store.getters["user/getUserBankCode"]
      };

      this.$store.dispatch("role/fetchRolePrivileges", payload, { root: true });
    },
    openModal() {
      this.fetchRolePrivileges();
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    }
  }
};
</script>

<template>
  <div>
    <!--        control-->
    <a @click="openModal()" class="view-more-info" title="View Privilege"
      ><i class="bx bx-info-circle font-size-18"
    /></a>
    <!--    control END-->

    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="500"
      :height="450"
      measure="px"
      animation="zoom"
      @hide="modalOpen = false"
      :closeOnClickMask="true"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <!--          <h4 class="card-title mb-4">Customer Info</h4>-->
          <div>
            <div v-if="role.moduleLoadingFour">
              <BaseComponentLoader />
            </div>
            <div v-else>
              <div class="view-more-customer-info">
                <div class="view-more-customer-info-wrap">
                  <div class="view-more-top">
                    <h4 class="card-title mb-4">{{ currentRole.roleName.toLowerCase() }} Privileges</h4>

                    <div class="row">
                      <div class="col-sm-6">
                        <p class="queue-title">Maker</p>
                        <p class="queue-value">
                          {{ currentRole.roleMaker }}
                        </p>
                      </div>

                      <div class="col-sm-6">
                        <p class="queue-title">Created At</p>
                        <p class="queue-value">
                          {{ currentRole.roleCreatedAt | formatDateForTable }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="view-more-base">
                    <div class="privilege-list">
                      <div class="row">
                        <div
                          v-for="privilege in role.currentRolePrivilege"
                          :key="privilege.privilegeId"
                          class="col-sm-6"
                        >
                          <p class="privilege-list-item">
                            <span>
                              <i class="bx bx-copy-alt font-size-16 mr-1"></i>
                              {{ privilege.privilegeType.toLowerCase() }} &nbsp;
                              {{ privilege.privilegeModuleName.toLowerCase() }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- end table-responsive -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vodal>
  </div>
</template>

<style scoped>
.view-customer-info-btn {
  position: relative;
  height: 20px;
  width: 20px;
  display: block;
  padding: 2px 2px;
  margin-top: 0;
  background-color: #795548;
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.view-more-info {
  cursor: pointer;
}
.view-customer-info-btn i {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}
p {
  color: #495057;
}
.privilege-list-item {
  margin-bottom: 15px;
}
.privilege-list-item span {
  text-transform: capitalize;
}
.privilege-list-item span i {
  color: #556ee6;
}
  .card-title{
    text-transform: capitalize;
  }
</style>
