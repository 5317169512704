import BaseService from "./BaseService";
import UrlUtils from "../../utils/BaseUtils/UrlUtils";

class CashTransactionService extends BaseService {
  fetchTellerTransactionHistory(
    payload,
    successAction,
    loaderType,
    errorType,
    showSuccessMessage,
    successCondition,
    getErrorMessage
  ) {
    return this.makePostRequest(
      UrlUtils.urls.cashTransaction.FETCH_TELLER_TRANSACTION_HISTORY,
      payload,
      successAction,
      loaderType,
      errorType,
      showSuccessMessage,
      successCondition,
      getErrorMessage
    );
  }

  fetchAllTransactionHistory(
    payload,
    successAction,
    loaderType,
    errorType,
    showSuccessMessage,
    successCondition,
    getErrorMessage
  ) {
    return this.makePostRequest(
      UrlUtils.urls.cashTransaction.FETCH_ALL_TRANSACTION_HISTORY,
      payload,
      successAction,
      loaderType,
      errorType,
      showSuccessMessage,
      successCondition,
      getErrorMessage
    );
  }

  fetchCustomerTransactions(
    payload,
    successAction,
    loaderType,
    errorType,
    showSuccessMessage,
    successCondition,
    getErrorMessage
  ) {
    return this.makePostRequest(
      UrlUtils.urls.cashTransaction.FETCH_CUSTOMER_TRANSACTIONS,
      payload,
      successAction,
      loaderType,
      errorType,
      showSuccessMessage,
      successCondition,
      getErrorMessage
    );
  }
}

export default CashTransactionService;
