<template>
  <div class="run-eod-card row mb-2">
    <div class="col-12">
      <p class="title">End Of Month Report</p>
      <p class="sub">some basic info on How to Run EOM</p>
      <p class="text-left">
        <span v-if="loader.actionButtonLoader" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button @click="runEom()" class="btn btn-primary action-btn">
            Run
          </button>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import { mapState } from "vuex";

export default {
  name: "RunEomCard",
  computed: {
    ...mapState(["loader"])
  },
  methods: {
    runEom() {
      StoreUtils.dispatch(StoreUtils.actions.eom.RUN_EOM);
    }
  }
};
</script>

<style scoped>
.title {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  color: #546ee6;
}
.sub {
  margin-bottom: 10px;
}
.action-btn {
  margin-bottom: 30px;
}
</style>
