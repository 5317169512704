<template>
  <div>
    <form @submit.prevent="queryReport">
      <div class="row">
        <div class="col-sm-12">
          <div>
            <BaseSelect
              :type="'text'"
              v-model="keywordFormBody.columnName"
              :label="'Type'"
              :placeholder="'Column Name'"
              :options="options"
              :hasPreIcon="false"
              :class="{
                error: $v.keywordFormBody.queryType.$error
              }"
              @blur="$v.keywordFormBody.columnName.$touch()"
            ></BaseSelect>
            <!--                          error-->
            <template v-if="$v.keywordFormBody.columnName.$error">
              <p
                v-if="!$v.keywordFormBody.columnName.required"
                class="form-error-message"
              >
                Column Name is required
              </p>
            </template>
          </div>
          <div>
            <BaseSelect
              :type="'text'"
              v-model="keywordFormBody.queryType"
              :label="'Query Type'"
              :placeholder="'Query Type'"
              :options="options2"
              :hasPreIcon="false"
              :class="{
                error: $v.keywordFormBody.queryType.$error
              }"
              @blur="$v.keywordFormBody.queryType.$touch()"
            ></BaseSelect>
            <!--                          error-->
            <template v-if="$v.keywordFormBody.queryType.$error">
              <p
                v-if="!$v.keywordFormBody.queryType.required"
                class="form-error-message"
              >
                Query Type is required
              </p>
            </template>
          </div>
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="keywordFormBody.keyword"
              placeholder="keyword"
              :hasPreIcon="false"
              :label="'tellerMaker'"
              :class="{ error: $v.keywordFormBody.keyword.$error }"
              @blur="$v.keywordFormBody.keyword.$touch()"
            >
            </BaseInput>

            <template v-if="$v.keywordFormBody.keyword.$error">
              <p
                v-if="!$v.keywordFormBody.keyword.required"
                class="form-error-message"
              >
                keyword is required
              </p>
            </template>
          </div>
          <div>
            <BaseSelect
              :type="'text'"
              v-model="keywordFormBody.join"
              :label="'JOIN'"
              :placeholder="'JOIN'"
              :options="options3"
              :hasPreIcon="false"
              :class="{
                error: $v.keywordFormBody.join.$error
              }"
              @blur="$v.keywordFormBody.join.$touch()"
            ></BaseSelect>
            <!--                          error-->
            <template v-if="$v.keywordFormBody.join.$error">
              <p
                v-if="!$v.keywordFormBody.join.required"
                class="form-error-message"
              >
                Operator is required
              </p>
            </template>
          </div>
        </div>
      </div>
      <p class="text-left">
        <span v-if="report.moduleLoadingSix" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Query
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "QueryReportForm",
  data() {
    return {
      keywordFormBody: this.queryFreshFormBodyObject(),

      options: this.$store.getters["report/columnNames"],
      options2: ["LIKE", "="],
      options3: ["AND", "OR"]
    };
  },
  beforeCreate() {
    const columnPayload = {
      reportBankCode: this.$store.getters["user/getUserBankCode"],
      reportName: "PWB_VW_ACCOUNTS",
      reportFieldList: "accountName, accountNumber",
      reportCondition: ""
    };
    this.$store.dispatch("report/readColumns", columnPayload);
  },
  created() {},
  computed: {
    ...mapState(["report"])
  },
  validations: {
    keywordFormBody: {
      columnName: { required },
      join: {},
      keyword: { required },
      queryType: { required }
    }
  },
  methods: {
    queryReport() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("report/queryReport", this.keywordFormBody, {
            root: true
          })
          .then(() => {
            this.keywordFormBody = {};
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    queryFreshFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped></style>
