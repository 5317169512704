<template>
  <div>
    <!--    <p>formBody =>{{ formBody }}</p>-->
    <keep-alive v-if="formStage === 0">
      <AccountTypeForm />
    </keep-alive>
    <keep-alive v-if="formStage === 1">
      <PersonalAccountInfoForm />
    </keep-alive>
    <keep-alive v-if="formStage === 2">
      <BusinessAccountInfoForm />
    </keep-alive>
    <keep-alive v-if="formStage === 3">
      <AccountExtraInfoForm />
    </keep-alive>
    <keep-alive v-if="formStage === 4">
      <PersonalAccountDocUploadForm />
    </keep-alive>
  </div>
</template>

<script>
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import AccountTypeForm from "@/components/Kleyton/Forms/Customer/createCustomerAccount/AccountTypeForm";
import PersonalAccountInfoForm from "@/components/Kleyton/Forms/Customer/createCustomerAccount/PersonalAccountInfoForm";
import BusinessAccountInfoForm from "@/components/Kleyton/Forms/Customer/createCustomerAccount/BusinessAccountInfoForm";
import AccountExtraInfoForm from "@/components/Kleyton/Forms/Customer/createCustomerAccount/AccountExtraInfoForm";
import PersonalAccountDocUploadForm from "@/components/Kleyton/Forms/Customer/createCustomerAccount/PersonalAccountDocUploadForm";
export default {
  name: "CreateCustomerAccountFormCard",
  components: {
    PersonalAccountDocUploadForm,
    AccountExtraInfoForm,
    BusinessAccountInfoForm,
    PersonalAccountInfoForm,
    AccountTypeForm
  },
  created() {
    StoreUtils.commit("form/RESET_FORM");
  },
  computed: {
    formBody() {
      return StoreUtils.rootGetter("form/getFormBody");
    },
    formStage() {
      return StoreUtils.rootGetter("form/getFormStage");
    }
  }
};
</script>

<style scoped></style>
