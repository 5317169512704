class UpgradeLogsRequest {
  static logsReadRequest = {
    readAll: "YES",
  };
  static logsApproveRequest = {
    username: null,
    type: null,
  };
  static logsDeclineRequest = {
    username: null,
    type: null,
    comment: null,
  };

  static logsUpgradeLogsReadByCustomerIdRequest = {
    customerId: null,
  };

  static logsUpgradeLogsUpdateRequest = {
    id: null,
    username: null,
    customerId: null,
    firstName: null,
    lastName: null,
    middleName: null,
    dob: null,
    phoneNumber: null,
    bvn: null,
    picture: null,
    signature: null,
    utilityBill: null,
    idCard: null,
    idCardNumber: null,
    address: null,
    accountClass: null,
    detailsVerified: null,
    dobVerified: null,
    bvnVerified: null,
    pictureVerified: null,
    signatureVerified: null,
    utilityBillVerified: null,
    idCardVerified: null,
    addressVerified: null,
    comment: null,
    status: null,
  };
}
export default UpgradeLogsRequest;
