<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Supposed Form -->
            <div class="form-body">
              <CreateReportCard />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import CreateReportCard from "../../../components/Kleyton/Cards/Report/RPT/CreateReportCard";

import Layout from "../../../router/layouts/main";
import PageHeader from "../../../components/AppComponents/page-header";
export default {
  name: "CreateReport",
  page: {
    title: "Report",
    meta: [{ name: "Create Report", content: window.__env.app.description }]
  },
  components: {
    Layout,
    PageHeader,
    CreateReportCard
  },
  data() {
    return {
      name: "CreateReport",
      title: "Create Report",
      items: [
        {
          text: "Report",
          href: "/"
        },
        {
          text: "Create Report",
          active: true
        }
      ]
    };
  }
};
</script>

<style scoped></style>
