import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import TerminalService from "../../services/newServices/TerminalService";
import RouterUtils from "@/utils/BaseUtils/RouterUtils";

const terminalService = new TerminalService();

const getDefaultState = () => {
  return {};
};

export const namespaced = true;

export const state = getDefaultState();

export const getters = {};

export const mutations = {};

export const actions = {
  allTerminalsCheckersQueue() {
    let payload = {
      cqType: "BATCH_TERMINAL",
      cqMode: "READ",
      cqBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.dispatch(
        "journal/setAllJournalsCheckersQueue",
        responseData.data
      );
    };
    return terminalService.fetchAllBatchCheckersQueue(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  checkerAuthorizeTerminals(undefined, payload) {
    const successAction = () => {
      StoreUtils.dispatch("journal/allTerminalsCheckersQueue");
    };

    return terminalService.authorizeBatchChecker(
      payload,
      successAction,
      LoaderUtils.types.TABLE_ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  fetchCurrentBatchEntries() {
    const payload = {
      batchBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      batchReference: StoreUtils.rootGetter(
        StoreUtils.getters.journal.GET_CURRENT_BATCH
      ).batchReference
    };

    const successAction = responseData => {
      StoreUtils.dispatch(
        "journal/setCurrentBatchEntries",
        responseData.entries
      );
    };
    return terminalService.fetchOneBatch(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  deleteBatch(undefined, batchReference) {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      status: "DELETED",
      batchReference
    };
    const successAction = () => {
      StoreUtils.dispatch("terminal/fetchAllBatches");
    };

    return terminalService.deleteBatch(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchAllBatches() {
    const payload = {
      batchBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      readAll: "YES"
    };
    const successAction = responseData => {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_TABLE_DATA,
        responseData.data
      );
    };
    return terminalService.fetchAllBatches(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  bulkEntriesToBatch(undefined, { entries }) {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      branchCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BRANCH_CODE
      ),
      maker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
      batchReference: StoreUtils.rootGetter(
        StoreUtils.getters.journal.GET_CURRENT_BATCH
      ).batchReference,
      requestType: "submitBulkBatchEntry",
      data: entries
    };

    const successAction = responseData => {
      StoreUtils.dispatch(
        "journal/setCurrentBatchReference",
        responseData.batchReference
      );
      RouterUtils.changeRouteTo(RouterUtils.routes.terminal.ALL_BATCHES);
    };

    return terminalService.bulkEntriesToBatch(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  openTerminalBatch(undefined, payload) {
    StoreUtils.dispatch("journal/setCurrentBatchName", payload.batchName);
    payload.batchBankCode = StoreUtils.rootGetter("user/getUserBankCode");
    payload.batchMaker = StoreUtils.rootGetter("user/getUserId");

    const successAction = responseData => {
      StoreUtils.dispatch(
        "journal/setCurrentBatchReference",
        responseData.batchReference
      );
      RouterUtils.changeRouteTo(RouterUtils.routes.terminal.ADD_ENTRY_TO_BATCH);
    };

    return terminalService.openBatch(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  addEntryToBatch(undefined, payload) {
    payload.batchReference = StoreUtils.rootGetter(
      StoreUtils.getters.journal.GET_CURRENT_BATCH
    ).batchReference;

    return terminalService.addEntryToBatch(
      payload,
      null,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  closeBatch(undefined, currentData) {
    let payload = {
      batchMaker: currentData.batchChecker,
      batchName: currentData.batchName,
      batchBankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      batchReference: currentData.batchReference
    };

    const successAction = () => {
      // StoreUtils.dispatch("terminal/fetchAllBatches");
      RouterUtils.changeRouteTo(RouterUtils.routes.terminal.ALL_BATCHES);
    };

    return terminalService.closeBatch(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  reassignTerminal() {
    let payload = {
      bankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      serialNumber: StoreUtils.rootGetter("form/getFormBody").serialNumber,
      accountNumber: StoreUtils.rootGetter("form/getFormBody").accountNumber,
      source: "CBA"
    };

    const successAction = () => {};

    return terminalService.reassignTerminal(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  resetTerminalPin(undefined, { terminalId }) {
    let payload = {
      terminalId: terminalId,
      source: "CBA"
    };

    const successAction = () => {
      StoreUtils.dispatch("terminal/fetchAllAssignedTerminals");
    };

    return terminalService.resetTerminalPin(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchAllAssignedTerminals() {
    let payload = {
      bankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      source: "CBA"
    };

    const successAction = responseData => {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_TABLE_DATA,
        responseData.data
      );
    };

    return terminalService.fetchAllAssignedTerminals(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  assignTerminalToCustomerAccount() {
    let payload = {
      bankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      serialNumber: StoreUtils.rootGetter("form/getFormBody").serialNumber,
      accountNumber: StoreUtils.rootGetter("form/getFormBody").accountNumber,
      source: "CBA"
    };

    const successAction = () => {
      StoreUtils.commit(StoreUtils.mutations.form.RESET_FORM);
    };

    return terminalService.assignTerminalToCustomerAccount(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  }
};
