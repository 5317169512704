<template>
  <div class="table-section col-12">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-secondary btn-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(eomItemStatus)="data">
        <p class="name-field">
          <span
            v-if="data.item.eomItemStatus === 'DONE'"
            class="badge badge-pill badge-soft-success font-size-12"
          >
            {{ data.item.eomItemStatus }}
          </span>
          <span
            v-else
            class="badge badge-pill badge-soft-warning font-size-12"
            >{{ data.item.eomItemStatus }}</span
          >
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import RouterUtils from "../../../../../utils/BaseUtils/RouterUtils";

export default {
  name: "EomItemsTable",
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },
        { key: "eomItemTitle", label: "Title", sortable: true },
        { key: "eomItemCreatedAt", label: "Created At", sortable: true },
        { key: "eomItemUpdatedAt", label: "Updated At", sortable: true },
        { key: "eomItemStatus", label: "Status", sortable: true }
      ],
      json_fields: {
        eomItemId: "eomItemId",
        eomItemEomId: "eomItemEomId",
        eomItemBankCode: "eomItemBankCode",
        eomItemTitle: "eomItemTitle",
        eomItemStatus: "eomItemStatus",
        eomItemMaker: "eomItemMaker",
        eomItemChecker: "eomItemChecker",
        eomItemCreatedAt: "eomItemCreatedAt",
        eomItemUpdatedAt: "eomItemUpdatedAt"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.eom.currentEomItems,
      isBusy: state => state.loader.tableLoader
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    fetchTableData() {
      StoreUtils.dispatch(
        StoreUtils.actions.eom.FETCH_ONE_EOM,
        StoreUtils.rootGetter(StoreUtils.getters.eom.GET_CURRENT_EOM).eomId
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;

        return initials;
      } else {
        return initials.substring(0, 2);
      }
    },

    queryReport(reportName) {
      this.$router.push({
        name: "query-report-form",
        params: { reportName: reportName }
      });
    },
    viewReport(reportname) {
      this.$router.push({
        name: "report-single-report",
        params: { reportName: reportname, source: "viewall" }
      });
    }
  },

  created() {
    if (!StoreUtils.rootGetter(StoreUtils.getters.eom.GET_CURRENT_EOM).eomId) {
      return RouterUtils.changeRouteTo(RouterUtils.routes.eom.ALL_EOM);
    }
    this.fetchTableData();
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
