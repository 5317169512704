<template>
  <div>
    <div v-if="loader.tableLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-sm-12">
          <div class="table-responsive">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th>Name</th>
                  <th>BVN</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Account No.</th>
                  <th>Account Class</th>
                  <th>Currency</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="customer in uploadCustomers" :key="customer.id">
                  <td>
                    <p>
                      {{
                        `${customer.customerFirstName} ${customer.customerMiddleName} ${customer.customerLastName} `
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="font-size-14 text-truncate">
                      {{ customer.customerBvn }}
                    </p>
                  </td>

                  <td>
                    <p class="font-size-14 text-truncate">
                      ({{ customer.customerCountryCode }}){{
                        customer.customerPhoneNumber
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="font-size-14 text-truncate">
                      {{ customer.customerEmail }}
                    </p>
                  </td>
                  <td>
                    <p class="font-size-14 text-truncate">
                      {{ customer.accountNumber }}
                    </p>
                  </td>
                  <td>
                    <p class="font-size-14 text-truncate">
                      {{ customer.accountClass }}
                    </p>
                  </td>
                  <td>
                    <p class="font-size-14 text-truncate">
                      {{ customer.accountCurrency }}
                    </p>
                  </td>
                  <td>
                    <a
                      @click="remove(customer)"
                      class="action-icon text-danger"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div class="row">
        <div class="col-sm-12">
          <p class="text-right">
            <span v-if="loader.actionButtonLoader" class="formLoaderHold">
              <BaseComponentLoader />
            </span>
            <span v-else>
              <button
                @click="showConfirmationModal()"
                class="btn btn-primary mr-1"
              >
                Post Entries
              </button>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import NotificationUtils from "@/utils/BaseUtils/NotificationUtils";

export default {
  name: "EditCreateCustomerUploadTable",

  computed: {
    ...mapState(["accountingEntry", "loader"]),
    uploadCustomers() {
      return StoreUtils.rootGetter(
        "accountingEntry/getCurrentAccountingEntryEntries"
      );
    }
  },
  methods: {
    showConfirmationModal() {
      let notificationModalBody = {
        type: "warning", //error, success, warning
        mode: "yes-no", // default, yes-no
        title: "Confirmation",
        message: "Do you want to Create Profiles for these Accounts?",
        noAction: () => {
          StoreUtils.dispatch("customer/bulkCreateCustomer", {
            customers: StoreUtils.rootGetter(
              "accountingEntry/getCurrentAccountingEntryEntries"
            ),
            createAccount: "NO"
          });
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "No",
        yesAction: () => {
          StoreUtils.dispatch("customer/bulkCreateCustomer", {
            customers: StoreUtils.rootGetter(
              "accountingEntry/getCurrentAccountingEntryEntries"
            ),
            createAccount: "YES"
          });
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Yes"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    },
    remove(customer) {
      StoreUtils.dispatch("accountingEntry/removeEntryFromCurrent", customer);
    }
  }
};
</script>

<style scoped></style>
