import axios from "axios";
import NProgress from "nprogress";
import store from "../../store/store";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";

const apiClient = axios.create({
  baseURL: window.__env.api.baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 50000
});

apiClient.interceptors.request.use(config => {
  const token = store.getters["user/getUserTokenFromLocalStorage"];
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});
apiClient.interceptors.response.use(response => {
  NProgress.done();
  if (response.data.responseCode === "92") {
    RouterUtils.changeRouteTo("login");
  }
  return response;
});

export default {
  checkerAuthorizeBranch(requestBody) {
    return apiClient.post("/branchChecker", JSON.stringify(requestBody));
  },
  allBranchCheckersQueue(requestBody) {
    return apiClient.post("/branchChecker", JSON.stringify(requestBody));
  },
  fetchBranchStatsData(requestBody) {
    return apiClient.post("/branchRead", JSON.stringify(requestBody));
  },
  allBranches(requestBody) {
    return apiClient.post("/branchRead", JSON.stringify(requestBody));
  },
  createBranch(requestBody) {
    return apiClient.post("/branchcreate", JSON.stringify(requestBody));
  },
  editBranch(requestBody) {
    return apiClient.post("/branchUpdate", JSON.stringify(requestBody));
  },
  updateBranchStatus(requestBody) {
    return apiClient.post("/branchUpdateStatus", JSON.stringify(requestBody));
  }
};
