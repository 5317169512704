/* eslint-disable */

import Vue from "vue";




// import AllJournalTable from "../components/Kleyton/Tables/Journal/AllJournalTable";

// import AddSubAccountModal from "../components/Kleyton/Modals/Customer/AddSubAccountModal";
// import QueryReportModal from "../components/Kleyton/Modals/Report/QueryReportModal";
// import TellerService from "../services/TellerService";




//CreateTellerForm
import CreateTellerForm from "../components/Kleyton/Forms/Teller/CreateTellerForm";
Vue.component("CreateTellerForm", CreateTellerForm);


//AllTeller
import AllTellersTable from "../components/Kleyton/Tables/Teller/AllTellersTable";
Vue.component("AllTellersTable", AllTellersTable);

//AllCustomer
import AllCustomersTable from "../components/Kleyton/Tables/Customer/AllCustomersTable";
Vue.component("AllCustomersTable", AllCustomersTable);

//CreateGlLevelOne
import CreateGlLevel1Form from "../components/Kleyton/Forms/GL/CreateGlLevel1Form";
Vue.component("CreateGlLevel1Form", CreateGlLevel1Form);

//AllGlLevelOne
import AllGlLevel1Table from "../components/Kleyton/Tables/GL1/AllGlLevel1Table";
Vue.component("AllGlLevel1Table", AllGlLevel1Table);

//AllGlLevelTwo
import AllGlLevel2Table from "../components/Kleyton/Tables/GL2/AllGlLevel2Table";
Vue.component("AllGlLevel2Table", AllGlLevel2Table);

//CreateJournalForm
import CreateJournalForm from "../components/Kleyton/Forms/Journal/CreateJournalForm";
Vue.component("CreateJournalForm", CreateJournalForm);


//AllJournals
// Vue.component("AllTellersTable", AllTellersTable);

//CreateUser
import CreateUserForm from "../components/Kleyton/Forms/User/CreateUserForm";
Vue.component("CreateUserForm", CreateUserForm);

//AllUsers
import AllUsersTable from "../components/Kleyton/Tables/User/AllUsersTable";
Vue.component("AllUsersTable", AllUsersTable);


//AllRoles
import AllRolesTable from "../components/Kleyton/Tables/Role/AllRolesTable";
Vue.component("AllRolesTable", AllRolesTable);

//CreateRoleForm
// Vue.component("CreateRoleForm", CreateRoleForm);

//AllRoles
// Vue.component("AllRolesTable", AllRolesTable);


//QuerySingleReportTable
import QuerySingleReportsTable from "../components/Kleyton/Tables/Report/RPT/QueryReportTable";
Vue.component("QuerySingleReportsTable" , QuerySingleReportsTable);

//ViewSingleReport
import ViewReportsTable from "../components/Kleyton/Tables/Report/RPT/ReportTable";
Vue.component("ViewSingleReport", ViewReportsTable);

//CreatePrivilegeForm
import CreatePrivilegeForm from "../components/Kleyton/Forms/Privilege/CreatePrivilegeForm";
Vue.component("CreatePrivilegeForm",CreatePrivilegeForm);

//AllPrivileges
import AllPrivilegeTable from "../components/Kleyton/Tables/Privilege/AllPrivilegeTable";
Vue.component("AllPrivilege", AllPrivilegeTable);

//CreateQueryForm2
import QueryReportForm2 from "../components/Kleyton/Forms/Report/QueryReportForm2";
Vue.component("QueryReportForm2", QueryReportForm2);

//QueryReportForm
import QueryReportForm from "../components/Kleyton/Forms/Report/QueryReportForm";
Vue.component("QueryReportForm" , QueryReportForm);

//Create Internal Account
import CreateInternalAccountForm from "../components/Kleyton/Forms/Account/CreateInternalAccountForm";
Vue.component("CreateInternalAccountForm", CreateInternalAccountForm);

//All Internal Account
import AllInternalAccountTable from "../components/Kleyton/Tables/InternalAccount/AllInternalAccountTable";
Vue.component("AllInternalAccountTable",AllInternalAccountTable);




//add  create report
import CreateReportForm from "../components/Kleyton/Forms/Report/CreateReportForm";
Vue.component("CreateReportForm", CreateReportForm);

//add transaction code component
import CreateTransactionCodeForm from "../components/Kleyton/Forms/TransactionCode/CreateTransactionCodeForm";
Vue.component("CreateTransactionProduct", CreateTransactionCodeForm);


//add loan products
import LoanProductsForm from "../components/Kleyton/LoanProductsForm";
Vue.component("CreateProductForm", LoanProductsForm);

//add charges product
import ChargesProduct from "../components/Kleyton/ChargesProductForm";
Vue.component("CreateChargesProduct", ChargesProduct);

//Create Till
import CreateTillForm from "../components/Kleyton/Forms/Till/CreateTillForm";
Vue.component("CreateTillForm",CreateTillForm);

//All Till
import AllTillsTable from "../components/Kleyton/Tables/Till/AllTillsTable";
Vue.component("AllTillsTable",AllTillsTable);

//All Vault
import AllVaultTable from "../components/Kleyton/Tables/Vault/AllVaultTable";
Vue.component("AllVaultsTable",AllVaultTable);

//Create Vault
import CreateVaultForm from "../components/Kleyton/Forms/Vault/CreateVaultForm";
Vue.component("CreatevaultForm",CreateVaultForm);

//Read Accounts
import ReadAccountTable from "../components/Kleyton/Tables/InternalAccount/ReadAccountTable";
Vue.component("ReadAccountTable", ReadAccountTable);

//Generate Forms
import GenerateProductForm from "../components/Kleyton/Forms/Product/GenerateProductForm";
Vue.component("GenerateProductForm", GenerateProductForm);
//TransferToVault
import TransferToVaultForm from "../components/Kleyton/Forms/CashTransactions/TillTransferToVaultForm";
Vue.component("TransferToVaultForm",TransferToVaultForm);

import VaultTransferToCBNForm from "../components/Kleyton/Forms/CashTransactions/VaultTransferToCBNForm";
Vue.component("VaultTransferToCBNForm",VaultTransferToCBNForm);





