<script>
/**
 * Login sample page
 */
export default {
  page: {
    title: "Sign in",
    meta: [{ name: "description", content: window.__env.app.description }]
  }
};
</script>

<template>
  <div>
    <!--    <div class="home-btn d-none d-sm-block">-->
    <!--      <a href="/" class="text-dark">-->
    <!--        <i class="fas fa-home h2"></i>-->
    <!--      </a>-->
    <!--    </div>-->
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="bg-soft-primary">
                <div class="row">
                  <div class="col-7">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">Power Bank</h5>
                      <p>
                        Hey there seems you are a new user. please change your
                        default password first.
                      </p>
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img
                      src="@/assets/images/profile-img.png"
                      alt
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <a href="/">
                    <div class="avatar-md profile-user-wid mb-4">
                      <span class="avatar-title rounded-circle bg-light">
                        <img
                          src="@/assets/images/logo.svg"
                          alt
                          class="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </a>
                </div>
                <div class="p-2">
                  <ChangeDefaultPasswordForm />
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <p>
                Don't have an account ?
                <router-link
                  tag="a"
                  to="/auth/register-1"
                  class="font-weight-medium text-primary"
                  >Signup now</router-link
                >
              </p>
              <p>© 2020 Power Bank.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
