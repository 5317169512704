<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import { mapState } from "vuex";

export default {
  name: "AllNipInwardsTable",
  props: {
    mode: {
      type: String,
      default: "inwards"
    }
  },
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },
        // A column that needs custom formatting
        { key: "sessionId", label: "Session Id", sortable: true },
        {
          key: "destinationBankCode",
          label: "Destination Bank Code",
          sortable: true
        },
        { key: "channelCode", label: "Channel Code", sortable: true },
        { key: "accountNumber", label: "Account Number", sortable: true },
        { key: "requestDate", label: "Request Date", sortable: true },
        { key: "accountName", label: "Account Name", sortable: true },
        { key: "originatorName", label: "Originator Name", sortable: true },
        { key: "narration", label: "Narration", sortable: true },
        { key: "paymentReference", label: "Payment Reference", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
        { key: "primaryAccount", label: "primaryAccount", sortable: true },
        { key: "trnStatus", label: "Transaction Status", sortable: true },
        { key: "settlementStatus", label: "Settlement Status", sortable: true },
        { key: "flexSessionID", label: "Flex Session ID", sortable: true },
        { key: "nameEnquiryRef", label: "Name Enquiry Ref", sortable: true },
        {
          key: "beneficiaryAccountNumber",
          label: "Beneficiary Account Number",
          sortable: true
        },
        {
          key: "beneficiaryBankVerificationNumber",
          label: "Beneficiary BVN",
          sortable: true
        },
        {
          key: "originatorAccountNumber",
          label: "Originator Account Number",
          sortable: true
        },
        {
          key: "originatorBankVerificationNumber",
          label: "Originator BVN",
          sortable: true
        },
        {
          key: "originatorKYCLevel",
          label: "Originator KYC Level",
          sortable: true
        },
        {
          key: "transactionLocation",
          label: "Transaction Location",
          sortable: true
        },
        { key: "futureUse1", label: "Future Use1", sortable: true },
        { key: "futureUse2", label: "Future Use2", sortable: true },
        {
          key: "beneficiaryKYCLevel",
          label: "Beneficiary KYC Level",
          sortable: true
        },
        { key: "settledStatus", label: "Settled Status", sortable: true },
        {
          key: "settledStatusDate",
          label: "Settled Status Date",
          sortable: true
        },
        { key: "postingDate", label: "Posting Date", sortable: true },
        {
          key: "postingResponseCode",
          label: "Posting Response Code",
          sortable: true
        },
        {
          key: "postingResponseMessage",
          label: "Posting Response Message",
          sortable: true
        },
        {
          key: "notificationStatus",
          label: "Notification Status",
          sortable: true
        },
        { key: "serviceBank", label: "serviceBank", sortable: true },
        { key: "branchCode", label: "branchCode", sortable: true }
      ],

      json_fields: {
        branchCode: "branchCode",
        serviceBank: "serviceBank",
        notificationStatus: "notificationStatus",
        postingResponseMessage: "postingResponseMessage",
        postingResponseCode: "postingResponseCode",
        postingDate: "postingDate",
        settledStatusDate: "settledStatusDate",
        settledStatus: "settledStatus",
        responseDate: "responseDate",
        beneficiaryKYCLevel: "beneficiaryKYCLevel",
        futureUse2: "futureUse2",
        futureUse1: "futureUse1",
        transactionLocation: "transactionLocation",
        originatorKYCLevel: "originatorKYCLevel",
        originatorBankVerificationNumber: "originatorBankVerificationNumber",
        originatorAccountNumber: "originatorAccountNumber",
        beneficiaryBankVerificationNumber: "beneficiaryBankVerificationNumber",
        beneficiaryAccountNumber: "beneficiaryAccountNumber",
        nameEnquiryRef: "nameEnquiryRef",
        flexSessionID: "flexSessionID",
        settlementStatus: "settlementStatus",
        trnStatus: "trnStatus",
        primaryAccount: "primaryAccount",
        amount: "amount",
        sessionId: "sessionId",
        destinationBankCode: "destinationBankCode",
        channelCode: "channelCode",
        accountNumber: "accountNumber",
        requestDate: "requestDate",
        accountName: "batchBankCode",
        originatorName: "originatorName",
        narration: "narration",
        paymentReference: "paymentReference"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  methods: {
    returnItems() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },

  computed: {
    ...mapState({
      items: state => state.switchTransaction.nipInwards,
      isBusy: state => state.loader.tableLoader
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },

  created() {
    if (this.mode === "inwards") {
      StoreUtils.dispatch(
        StoreUtils.actions.switchTransaction.FETCH_ALL_NIP_INWARDS
      );
    }
    if (this.mode === "decision-box") {
      StoreUtils.dispatch(
        StoreUtils.actions.switchTransaction.FETCH_DECISION_BOX
      );
    }
  }
};
</script>

<style scoped>
.view-more-info i {
  font-weight: 600;
}
.view-more-info {
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
}
</style>
