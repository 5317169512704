import Utils from "../../../utils/Utils";

export default {
  getBankUtilityOption: state => {
    const allBanks = state.allBanks;
    return allBanks.map(bank => bank.bankName + " (" + bank.bankCode + ")");
  },
  getBankCodeFromOptionUtility: state => chosenOption => {
    const allBanks = state.allBanks;
    let opt = allBanks.filter(function(bank) {
      return bank.bankName + " (" + bank.bankCode + ")" === chosenOption;
    });

    return opt[0].bankCode;
  },
  getBankNameFromCodeUtility: state => chosenOption => {
    const allBanks = state.allBanks;

    let opt = allBanks.filter(function(bank) {
      return bank.bankCode === chosenOption;
    });

    return opt[0].bankName;
  },

  getBankLogoFromCodeUtility: state => chosenOption => {
    const allBanks = state.allBanks;

    let opt = allBanks.filter(function(bank) {
      return bank.bankCode === chosenOption;
    });

    return opt[0].bankLogo;
  },

  getBranchesUtilityOption: state => {
    const allBranches = state.allBranches;
    return allBranches.map(
      branch =>
        branch.branchName +
        " (" +
        branch.branchBankCode +
        ") - " +
        branch.branchState
    );
  },
  getBranchCodeFromOptionUtility: state => chosenOption => {
    const allBranches = state.allBranches;
    let opt = allBranches.filter(function(branch) {
      return (
        branch.branchName +
          " (" +
          branch.branchBankCode +
          ") - " +
          branch.branchState ===
        chosenOption
      );
    });

    return opt[0].branchCode;
  },

  getGlLevelOneUtilityOption: state => {
    const allGlLevel1 = state.allGlLevel1;
    return allGlLevel1.map(
      glLevel1 =>
        glLevel1.glLevel1Description + " - (" + glLevel1.glLevel1Code + ")"
    );
  },
  getGlCategoryCode: state => {
    const allGlLevel1 = state.allGlLevel1;
    return allGlLevel1.map(glLevel1 => glLevel1.glLevel1CategoryCode);
  },
  getGlLevelOneCodeFromOptionUtility: state => chosenOption => {
    const allGlLevel1 = state.allGlLevel1;
    let opt = allGlLevel1.filter(function(glLevel1) {
      return (
        glLevel1.glLevel1Description + " - (" + glLevel1.glLevel1Code + ")" ===
        chosenOption
      );
    });

    return opt[0].glLevel1Code;
  },

  getGlLevelTwoUtilityOption: state => {
    const allGlLevel2 = state.allGlLevel2;
    return allGlLevel2.map(
      glLevel2 =>
        glLevel2.glLevel2Description + " - (" + glLevel2.glLevel2Code + ")"
    );
  },
  getGlLevelTwoCodeFromOptionUtility: state => chosenOption => {
    const allGlLevel2 = state.allGlLevel2;
    let opt = allGlLevel2.filter(function(glLevel2) {
      return (
        glLevel2.glLevel2Description + " - (" + glLevel2.glLevel2Code + ")" ===
        chosenOption
      );
    });
    return opt[0].glLevel2Code;
  },

  getRolesUtilityOption: state => {
    const allRoles = state.allRoles;
    return allRoles.map(role => role.roleName);
  },
  getRoleIdFromOptionUtility: state => chosenOption => {
    const allRoles = state.allRoles;
    let opt = allRoles.filter(function(role) {
      return role.roleName === chosenOption;
    });

    return opt[0].roleId;
  },

  getPrivilegeUtilityOption: state => {
    const allPrivileges = state.allPrivileges;
    return allPrivileges.map(
      privilege =>
        privilege.privilegeType + " - " + privilege.privilegeModuleName
    );
  },
  getPrivilegeSeparatedByRoles: state => {
    const allPrivileges = state.allPrivileges;
    return allPrivileges.reduce((acc, currValue) => {
      const module = currValue.privilegeModuleName;
      let modulePrivileges;
      if (acc[module]) {
        modulePrivileges = acc[module];
        modulePrivileges.push(currValue);
      } else {
        modulePrivileges = [currValue];
      }
      return {
        ...acc,
        [module]: modulePrivileges
      };
    }, {});
  },
  getPrivilegeIdFromOptionUtility: state => chosenOption => {
    const allPrivileges = state.allPrivileges;
    let opt = allPrivileges.filter(privilege => {
      return (
        privilege.privilegeType + " - " + privilege.privilegeModuleName ===
        chosenOption
      );
    });
    return opt.privilegeId;
  },
  getPrivilegeTypeUtilityOption: () => {
    return ["Create", "Read", "Update", "Maker", "Checker"];
  },

  getModuleUtilityOption: state => {
    const allModules = state.allModules;
    return allModules.map(module => module.moduleName.toLowerCase());
  },
  getModuleIdFromOptionUtility: state => chosenOption => {
    const allModules = state.allModules;
    let matchedModule = allModules.filter(module => {
      return module.moduleName === chosenOption;
    });

    return matchedModule[0].moduleId;
  },

  getAllChargeProductUtilityOption: state => {
    const allChargeProducts = state.allChargeProducts;
    return allChargeProducts.map(
      chargeProduct =>
        "(" + chargeProduct.Code + ") " + chargeProduct.Description
    );
  },
  getChargeProductCodeFromOptionUtility: state => chosenOption => {
    const allChargeProducts = state.allChargeProducts;
    let opt = allChargeProducts.filter(function(chargeProduct) {
      return (
        "(" + chargeProduct.Code + ") " + chargeProduct.Description ===
        chosenOption
      );
    });

    return opt[0].Code;
  },

  getTransactionCodeUtilityOption: state => {
    const allTransactionCodes = state.allTransactionCodes;
    return allTransactionCodes.map(transactionCode =>
      transactionCode.trnCode.toLowerCase()
    );
  },

  getAllTransactionCodesUtilityOption: state => {
    const allTransactionCodes = state.allTransactionCodes;
    return allTransactionCodes.map(
      transactionCode =>
        "(" + transactionCode.trnCode + ") " + transactionCode.trnDescription
    );
  },
  getTransactionCodeCodeFromOptionUtility: state => chosenOption => {
    const allTransactionCodes = state.allTransactionCodes;
    let opt = allTransactionCodes.filter(function(transactionCode) {
      return (
        "(" +
          transactionCode.trnCode +
          ") " +
          transactionCode.trnDescription ===
        chosenOption
      );
    });

    return opt[0].trnCode;
  },

  getTellersUtilityOption: state => {
    const allTellers = state.allTellers;
    if (allTellers.length > 0) {
      return allTellers.map(teller => teller.tellerNumber.toLowerCase());
    } else {
      return [];
    }
  },
  getAllTellersNameUtilityOption: state => {
    const allTellers = state.allTellers;
    return allTellers.map(
      tellerUser =>
        "(" +
        tellerUser.tellerUserId +
        ") " +
        tellerUser.tellerUserFirstName +
        " " +
        tellerUser.tellerUserMiddleName +
        " " +
        tellerUser.tellerUserLastName
    );
  },
  getTellerUserIdFromOptionUtility: state => chosenOption => {
    const allTellers = state.allTellers;
    let opt = allTellers.filter(function(tellerUser) {
      return (
        "(" +
          tellerUser.tellerUserId +
          ") " +
          tellerUser.tellerUserFirstName +
          " " +
          tellerUser.tellerUserMiddleName +
          " " +
          tellerUser.tellerUserLastName ===
        chosenOption
      );
    });

    return opt[0].userId;
  },

  getInternalAccountsUtilityOption: state => {
    const allInternalAccounts = state.allInternalAccounts;
    return allInternalAccounts.map(account => {
      return `${account.accountName.toLowerCase()} - ${account.accountNumber}`;
    });
  },
  getInternalAccountsFromOptionUtility: state => chosenOption => {
    const allInternalAccounts = state.allInternalAccounts;

    let opt = allInternalAccounts.filter(function(account) {
      return (
        `${account.accountName.toLowerCase()} - ${account.accountNumber}` ===
        chosenOption
      );
    });
    return opt[0].accountNumber;
  },
  getAccountClassUtilityOption: state => {
    return state.allAccountClass;
  },
  getAccountClass2UtilityOption: state => {
    const allAccountClass = state.allAccountClass2;
    return allAccountClass.map(
      accountClass => `${accountClass.Code} -(${accountClass.Description})`
    );
  },
  getAccountClassFromOptionUtility: state => chosenOption => {
    const allAccountClass = state.allAccountClass2;
    let opt = allAccountClass.filter(function(accountClass) {
      return (
        `${accountClass.Code} -(${accountClass.Description})` === chosenOption
      );
    });
    return opt[0].Code;
  },
  getLoanProductUtilityOption: state => {
    const allLoanProduct = state.allLoanProduct;
    return allLoanProduct.map(
      loanProduct => `${loanProduct.Code} -(${loanProduct.Description})`
    );
  },
  getLoanProductFromOptionUtility: state => chosenOption => {
    const allLoanProduct = state.allLoanProduct;
    let opt = allLoanProduct.filter(function(loanProduct) {
      return (
        `${loanProduct.Code} -(${loanProduct.Description})` === chosenOption
      );
    });
    return opt[0].Code;
  },
  getCurrencyUtilityOption: state => {
    return state.currencyOptions;
  },
  getCountryUtilityOption: state => {
    return state.countryOption;
  },
  getAccountTypeOption: state => {
    return state.accountTypeOptions;
  },

  getFtProductCodeUtilityOption: state => {
    return state.productCodeOptions;
  },

  getFtProductsUtilityOption2: state => {
    const allFtProducts = state.ftProducts;
    return allFtProducts.map(
      ftProduct => `${ftProduct.Code} -(${ftProduct.Description})`
    );
  },
  getFtProductCodeFromOptionUtility: state => chosenOption => {
    const allFtProducts = state.ftProducts;
    let opt = allFtProducts.filter(function(ftProduct) {
      return `${ftProduct.Code} -(${ftProduct.Description})` === chosenOption;
    });
    return opt[0].Code;
  },

  generateTransactionRef: () => {
    return Utils.generateUUIDRef();
  },
  getCurrentTransactionRef: state => {
    return state.currentTransactionRef;
  },

  getAllUsersOption: state => {
    const allUsers = state.allUsers;
    return allUsers.map(
      user =>
        "(" +
        user.userId +
        ") " +
        user.userFirstName +
        " " +
        user.userMiddleName +
        " " +
        user.userLastName
    );
  },
  getUserIdFromOptionUtility: state => chosenOption => {
    const allUsers = state.allUsers;
    let opt = allUsers.filter(function(user) {
      return (
        "(" +
          user.userId +
          ") " +
          user.userFirstName +
          " " +
          user.userMiddleName +
          " " +
          user.userLastName ===
        chosenOption
      );
    });

    return opt[0].userId;
  },

  getBankListUtilityOption: state => {
    const bankList = state.bankList;
    return bankList.map(bank => `${bank.bankName} (${bank.bankCode})`);
  },
  getBankCodeFromBankListOption: state => chosenOption => {
    const bankList = state.bankList;
    let opt = bankList.filter(function(bank) {
      return `${bank.bankName} (${bank.bankCode})` === chosenOption;
    });

    return opt[0].bankCode;
  }
};
