<template>
  <div>
    <!--    <div v-if="appUtilities.moduleLoadingTwo">-->
    <!--      <BaseComponentLoader />-->
    <!--    </div>-->
    <div>
      <form @submit.prevent="createVault">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createVaultFormBody.vaultAccountNumber"
                placeholder="Account Number"
                :hasPreIcon="false"
                :label="'Account Number'"
                :class="{
                  error: $v.createVaultFormBody.vaultAccountNumber.$error
                }"
                @blur="$v.createVaultFormBody.vaultAccountNumber.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createVaultFormBody.vaultAccountNumber.$error">
                <p
                  v-if="!$v.createVaultFormBody.vaultAccountNumber.required"
                  class="form-error-message"
                >
                  Vault Account Number is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :range="false"
                v-model="createVaultFormBody.tillAccountNumber"
                placeholder="Vault Teller Number"
                :label="'Vault Teller Number'"
                :hasPreIcon="false"
                :class="{
                  error: $v.createVaultFormBody.tillAccountNumber.$error
                }"
                @blur="$v.createBankFormBody.tillAccountNumber.$touch()"
              />

              <template v-if="$v.createVaultFormBody.tillAccountNumber.$error">
                <p
                  v-if="!$v.createVaultFormBody.tillAccountNumber.required"
                  class="form-error-message"
                >
                  Till Account Number is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :range="false"
                v-model="createVaultFormBody.amount"
                placeholder="Amount"
                :label="'Amount'"
                :hasPreIcon="false"
                :class="{
                  error: $v.createVaultFormBody.amount.$error
                }"
                @blur="$v.createBankFormBody.amount.$touch()"
              />

              <template v-if="$v.createVaultFormBody.amount.$error">
                <p
                  v-if="!$v.createVaultFormBody.amount.required"
                  class="form-error-message"
                >
                  Amount is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <p class="text-left">
          <span v-if="vault.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create Vault
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
// import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import BaseInput from "../../../BaseComponents/BaseInput";

export default {
  name: "CreateVault",
  components: { BaseInput },
  data() {
    return {
      createVaultFormBody: this.createFreshCreateVaultFormBodyObject(),
      moduleLoadingCreated: false
      // options: ["Head of Vault", "Vault"]
    };
  },
  computed: {
    // ...mapState(["vault", "appUtilities"]),
    // ...mapGetters({
    //   vaultTellerNumberOptions: "appUtilities/getTellersUtilityOption"
    // })
  },
  validations: {
    createVaultFormBody: {
      vaultAccountNumber: { required },
      vaultTellerNumber: { required },
      amount: { required }
    }
  },
  created() {
    // this.$store.dispatch("appUtilities/fetchAllTellers", null, { root: true });
  },
  methods: {
    createVault() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        setTimeout(function() {
          this.moduleLoadingCreated = true;
          const notification = {
            type: "error",
            variant: "danger",
            message: `Transfer Successful`
          };
          this.$store.dispatch("notification/add", notification, {
            root: true
          });
        }, 30000);
        this.createVaultFormBody.vaultCurrency = "NGN";
        this.createVaultFormBody.vaultBankCode = this.$store.getters[
          "user/getUserBankCode"
        ];
        this.createVaultFormBody.vaultBranchCode = this.$store.getters[
          "user/getUserBranchCode"
        ];
        this.createVaultFormBody.vaultMaker = this.$store.getters[
          "user/getUserId"
        ];
        this.createVaultFormBody.vaultChecker = "SYSTEM";
        // this.createVaultFormBody.vaultTellerNumber = this.getTellerNumberOptions( this.createVaultFormBody.vaultTellerNumber );

        this.$store
          .dispatch("vault/createVault", this.createVaultFormBody, {
            root: true
          })
          .then(() => {
            this.createVaultFormBody = {
              source: "web"
            };
            this.$v.$reset();
          });
      }
    },
    createFreshCreateVaultFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped></style>
