<template>
  <div>
    <div v-if="ftStage === 1">
      <DebitEnquiryForm />
    </div>
    <div v-if="ftStage === 2">
      <CwFinalForm />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import DebitEnquiryForm from "../../Forms/FundsTransfer/Withdrawal/DebitEnquiryForm";
import CwFinalForm from "../../Forms/FundsTransfer/Withdrawal/CwFinalForm";

export default {
  name: "CashWithdrawalCard",
  components: { CwFinalForm, DebitEnquiryForm },
  computed: {
    ...mapState(["ft", "loader"]),
    ftStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.ft.GET_FT_STAGE);
    }
  },
  methods: {}
};
</script>

<style scoped></style>
