import store from "../../store/store";

import Login from "../../views/Auth/Login";
import ForgotPassword from "../../views/Auth/ForgotPassword";
import NotFound from "../../views/Utility/404";
import NetworkIssues from "../../views/Utility/500";

import Dashboard from "../../views/Dashboard";

// BANK
import CreateBank from "../../views/Bank/CreateBank";
import AllBanks from "../../views/Bank/AllBanks";

// Branches
import BranchCreate from "../../views/Branch/BranchCreate";
import BranchAll from "../../views/Branch/BranchAll";

// loan
import LoanViewLoans from "../../views/Loan/LoanAll";
import ChangeDefaultPassword from "../../views/Auth/ChangeDefaultPassword";
import CreateRole from "../../views/Role/CreateRole";
import AllRoles from "../../views/Role/AllRoles";

import CreateTransactionCode from "../../views/TransationCode/CreateTransactionCode";
import AllTransactionCodes from "../../views/TransationCode/AllTransactionCodes";
import TransferFunds from "../../views/FundsTransfer/TransferFunds";

import QueryReport from "../../views/Report/RPT/QueryReport.vue";
import Report from "../../views/Report/RPT/Report.vue";
import VaultTransferToCBN from "../../views/CashTransactions/VaultTransferToCBN";
import CashWithdrawal from "../../views/FundsTransfer/CashWithdrawal";
import CreateProduct from "../../views/Products/CreateProduct";
import SelectProductTypeCreate from "../../views/Products/SelectProductTypeCreate";
import UploadBankLogo from "../../views/Bank/UploadBankLogo";
import CustomersCheckersQueue from "../../views/Customer/CustomersCheckersQueue";
import CustomerSubAccountCheckersQueue from "../../views/Customer/CustomerSubAccountCheckersQueue";
import BranchCheckersQueue from "../../views/Branch/BranchCheckersQueue";
import FundsTransferCheckersQueue from "../../views/FundsTransfer/FundsTransferCheckersQueue";
import CashDepositCheckersQueue from "../../views/FundsTransfer/CashDepositCheckersQueue";
import CashWithdrawalCheckersQueue from "../../views/FundsTransfer/CashWithdrawalCheckersQueue";
import CashTransactionsCheckersQueue from "../../views/CashTransactions/CashTransactionsCheckersQueue";
import UserCheckersQueue from "../../views/User/UserCheckersQueue";
import GlLevel1CheckersQueue from "../../views/GlLevel1/GlLevel1CheckersQueue";
import GlLevel2CheckersQueue from "../../views/GlLevel2/GlLevel2CheckersQueue";
import AllCustomersAccounts from "../../views/Customer/AllCustomersAccounts";
import BookLoan from "../../views/Loan/BookLoan";
import VaultCheckersQueue from "../../views/Vault/VaultCheckersQueue";
import TillCheckersQueue from "../../views/Till/TillCheckersQueue";
import TellerCheckersQueue from "../../views/Teller/TellerCheckersQueue";
import InternalAccountCheckersQueue from "../../views/internalAccount/InternalAccountCheckersQueue";
import JournalCheckersQueue from "../../views/Journal/JournalCheckersQueue";
import ReportCheckersQueue from "../../views/Report/ReportCheckersQueue";
import TransactionCodeCheckersQueue from "../../views/TransationCode/TransactionCodeCheckersQueue";
import CreateAccountingEntry from "../../views/Products/AccountingEntry/CreateAccountingEntry";
import AssignAccountingEntry from "../../views/Products/AccountingEntry/AssignAccountingEntry";
import AllAccountingEntries from "../../views/Products/AccountingEntry/AllAccountingEntries";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import OpenBatch from "../../views/Journal/OpenBatch";
import AllBatches from "../../views/Journal/AllBatches";
import AllBatchEntries from "../../views/Journal/AllBatchEntries";
import AddEntryToBatch from "../../views/Journal/AddEntryToBatch";
import AllNipInwards from "../../views/SwitchTransaction/AllNipInwards";
import AllEod from "../../views/Report/EOD/AllEod";
import EodItems from "../../views/Report/EOD/EodItems";
import EomItems from "../../views/Report/EOM/EomItems";
import AllEom from "../../views/Report/EOM/AllEom";
import LoanAmortizationSchedule from "../../views/Loan/LoanAmortizationSchedule";
import CreateReport from "../../views/Report/RPT/CreateReport";
import AllReports from "../../views/Report/RPT/AllReports";
import EditRolePrivilege from "../../views/Role/EditRolePrivilege";
import CustomerAccountEnquiry from "../../views/FundsTransfer/CustomerAccountEnquiry";
import DecisionBox from "../../views/SwitchTransaction/DecisionBox";
import CashTransactionsHistory from "../../views/CashTransactions/CashTransactionsHistory";
import AllTransactionHistory from "../../views/CashTransactions/AllTransactionHistory";
import ManualReversal from "../../views/FundsTransfer/ManualReversal";
import EditBulkBatchEntries from "../../views/Journal/EditBulkBatchEntries";
import CustomerTransactions from "../../views/CashTransactions/CustomerTransactions";
import CreateCustomerUpload from "../../views/Customer/CreateCustomerUpload";
import CreateCustomerUploadEdit from "../../views/Customer/CreateCustomerUploadEdit";
import RequestAccountStatement from "../../views/Report/RPT/RequestAccountStatment";
import CreateFixedDeposit from "../../views/investment/fixedDeposit/CreateFixedDeposit";
import ViewFixedDeposit from "../../views/investment/fixedDeposit/ViewFixedDeposit";
import AllAssignedTerminals from "../../views/Terminal/AllAssignedTerminals";
import OpenTerminalBatch from "@/views/Terminal/OpenTerminalBatch";
import AddEntriesToJournalBatch from "@/views/Terminal/AddEntriesToJournalBatch";
import EditTerminalBulkBatchEntries from "@/views/Terminal/EditBulkBatchEntries";
import AllTerminalBatches from "@/views/Terminal/AllBatches";
import AllTerminalBatchEntries from "@/views/Terminal/AllBatchEntries";
import TerminalCheckersQueue from "@/views/Terminal/TerminalCheckersQueue";
import CreateDispute from "@/views/Dispute/CreateDispute";
import AllDisputes from "@/views/Dispute/AllDisputes";
import GeneralCheckersQueue from "@/views/queue/GeneralCheckersQueue";
import CreateAccount from "@/views/Customer/CreateAccount";
import CustomersAccountsUpgrade from "../../views/Customer/CustomersAccountsUpgrade";
import CustomerUpgradeData from "../../views/Customer/CustomerUpgradeData";

export default [
  {
    path: "/queue/general-checking-queue",
    name: "queue-general-checking-queue",
    meta: {
      authRequired: false
    },
    component: GeneralCheckersQueue
  },
  {
    path: "/dispute/all-disputes",
    name: "dispute-all-disputes",
    meta: {
      authRequired: false
    },
    component: AllDisputes
  },
  {
    path: "/dispute/create-dispute",
    name: "dispute-create-dispute",
    meta: {
      authRequired: false
    },
    component: CreateDispute
  },
  {
    path: "/terminal/batch-checkers-queue",
    name: "terminal-batch-checkers-queue",
    meta: {
      authRequired: false
    },
    component: TerminalCheckersQueue
  },
  {
    path: "/terminal/all-batch-entries",
    name: "terminal-all-batch-entries",
    meta: {
      authRequired: false
    },
    component: AllTerminalBatchEntries
  },
  {
    path: "/terminal/all-batches",
    name: "terminal-all-batches",
    meta: {
      authRequired: false
    },
    component: AllTerminalBatches
  },
  {
    path: "/terminal/edit-terminal-batch-entries",
    name: "terminal-edit-terminal-batch-entries",
    meta: {
      authRequired: false
    },
    component: EditTerminalBulkBatchEntries
  },
  {
    path: "/terminal/add-entries-to-terminal-batch",
    name: "terminal-add-entry-to-terminal-batch",
    meta: {
      authRequired: false
    },
    component: AddEntriesToJournalBatch
  },
  {
    path: "/terminal/open-terminal-batch",
    name: "terminal-open-terminal-batch",
    meta: {
      authRequired: false
    },
    component: OpenTerminalBatch
  },
  {
    path: "/terminal/all-assigned-terminals",
    name: "terminal-all-assigned-terminals",
    meta: {
      authRequired: false
    },
    component: AllAssignedTerminals
  },
  {
    path: "/investment/view-fixed-deposit",
    name: "investment-view-fixed-deposits",
    meta: {
      authRequired: false
    },
    component: ViewFixedDeposit
  },
  {
    path: "/investment/create-fixed-deposit",
    name: "investment-create-fixed-deposit",
    meta: {
      authRequired: false
    },
    component: CreateFixedDeposit
  },
  {
    path: "/customer/request-customer-account-statement",
    name: "customer-request-account-statement",
    meta: {
      authRequired: false
    },
    component: RequestAccountStatement
  },
  {
    path: "/customer/create-customer-account",
    name: "customer-create-account",
    meta: {
      authRequired: false
    },
    component: CreateAccount
  },
  {
    path: "/customer/create-customer-upload-edit",
    name: "customer-create-customer-upload-edit",
    meta: {
      authRequired: false
    },
    component: CreateCustomerUploadEdit
  },
  {
    path: "/customer/create-customer-upload",
    name: "customer-create-customer-upload",
    meta: {
      authRequired: false
    },
    component: CreateCustomerUpload
  },
  {
    path: "/transaction/manual-reversal",
    name: "edit-batch-entries",
    meta: {
      authRequired: false
    },
    component: ManualReversal
  },
  {
    path: "/transaction/manual-reversal",
    name: "funds-transfer-manual-reversal",
    meta: {
      authRequired: false
    },
    component: ManualReversal
  },
  {
    path: "/transaction/transaction-enquiry",
    name: "funds-transfer-all-transactions-history",
    meta: {
      authRequired: false
    },
    component: AllTransactionHistory
  },

  {
    path: "/transaction/teller-transaction-history",
    name: "funds-transfer-teller-transaction-history",
    meta: {
      authRequired: false
    },
    component: CashTransactionsHistory
  },

  {
    path: "/transaction/customer-transactions",
    name: "funds-transfer-customer-transactions",
    meta: {
      authRequired: false
    },
    component: CustomerTransactions
  },

  ///// Loan
  {
    path: "/loan/view-loan",
    name: "loan-view-loans",
    meta: {
      authRequired: false
    },
    component: LoanViewLoans
  },
  {
    path: "/loan/amortization-schedule",
    name: "loan-amortization-schedule",
    meta: {
      authRequired: false
    },
    component: LoanAmortizationSchedule
  },
  {
    path: "/loan/book-loan",
    name: "loan-book-loan",
    meta: {
      authRequired: false
    },
    component: BookLoan
  },

  {
    path: "/report/eom/eom-items",
    name: "all-eom-items",
    meta: {
      authRequired: false
    },
    component: EomItems
  },
  {
    path: "/report/eom/all-eom",
    name: "eom-all-eom",
    meta: {
      authRequired: false
    },
    component: AllEom
  },

  {
    path: "/report/eod/eod-items",
    name: "all-eod-items",
    meta: {
      authRequired: false
    },
    component: EodItems
  },
  {
    path: "/report/eod/all-eod",
    name: "eod-all-eod",
    meta: {
      authRequired: false
    },
    component: AllEod
  },

  {
    path: "/switch-transactions/all-nip-outward",
    name: "switch-transactions-nip-outward",
    meta: {
      authRequired: false
    },
    component: AllNipInwards
  },
  {
    path: "/switch-transactions/all-nip-inward",
    name: "switch-transactions-nip-inward",
    meta: {
      authRequired: false
    },
    component: AllNipInwards
  },
  {
    path: "/switch-transactions/decisionBox",
    name: "switch-transactions-decision-box",
    meta: {
      authRequired: false
    },
    component: DecisionBox
  },

  {
    path: "/journal/all-batch-entries",
    name: "journal-all-batch-entries",
    meta: {
      authRequired: false
    },
    component: AllBatchEntries
  },
  {
    path: "/journal/all-batches",
    name: "journal-all-entries",
    meta: {
      authRequired: false
    },
    component: AllBatches
  },
  {
    path: "/journal/edit-batch-entries",
    name: "journal-edit-batch-entries",
    meta: {
      authRequired: false
    },
    component: EditBulkBatchEntries
  },

  {
    path: "/journal/add-entry-to-batch",
    name: "journal-add-entry-to-batch",
    meta: {
      authRequired: false
    },
    component: AddEntryToBatch
  },
  {
    path: "/journal/open-batch",
    name: "journal-open-batch",
    meta: {
      authRequired: false
    },
    component: OpenBatch
  },

  {
    path: "/product/accounting-entry/all",
    name: "accounting-entry-all",
    meta: {
      authRequired: false
    },
    component: AllAccountingEntries
  },
  {
    path: "/product/accounting-entry/assign-entries",
    name: "accounting-entry-assign",
    meta: {
      authRequired: false
    },
    component: AssignAccountingEntry
  },
  {
    path: "/product/accounting-entry/create",
    name: "accounting-entry-create",
    meta: {
      authRequired: false
    },
    component: CreateAccountingEntry
  },

  {
    path: "/transaction-code/transaction-code-checkers-queue",
    name: "transaction-code-checkers-queue",
    meta: {
      authRequired: false
    },
    component: TransactionCodeCheckersQueue
  },

  {
    path: "/report/report-checkers-queue",
    name: "report-checkers-queue",
    meta: {
      authRequired: false
    },
    component: ReportCheckersQueue
  },

  {
    path: "/journal/journal-checkers-queue",
    name: "journal-checkers-queue",
    meta: {
      authRequired: false
    },
    component: JournalCheckersQueue
  },

  {
    path: "/internalAccount/internal-accounts-checkers-queue",
    name: "internal-accounts-checkers-queue",
    meta: {
      authRequired: false
    },
    component: InternalAccountCheckersQueue
  },

  {
    path: "/teller/teller-checkers-queue",
    name: "teller-checkers-queue",
    meta: {
      authRequired: false
    },
    component: TellerCheckersQueue
  },

  {
    path: "/till/till-checkers-queue",
    name: "till-checkers-queue",
    meta: {
      authRequired: false
    },
    component: TillCheckersQueue
  },

  {
    path: "/vault/vault-checkers-queue",
    name: "vault-checkers-queue",
    meta: {
      authRequired: false
    },
    component: VaultCheckersQueue
  },

  {
    path: "/customer/all-customers-accounts",
    name: "customer-all-customers-accounts",
    meta: {
      authRequired: false
    },
    component: AllCustomersAccounts
  },
  {
    path: "/customer/customers-accounts-upgrade",
    name: "customer-accounts-upgrade",
    meta: {
      authRequired: false
    },
    component: CustomersAccountsUpgrade
  },
  {
    path: "/customer/customers-upgrade-data",
    name: "customer-upgrade-data",
    meta: {
      authRequired: false
    },
    component: CustomerUpgradeData
  },


  {
    path: "/user/user-checkers-queue",
    name: "user-checkers-queue",
    meta: {
      authRequired: false
    },
    component: UserCheckersQueue
  },

  {
    path: "/glLevel2/gl2-checkers-queue",
    name: "glLevel2-checkers-queue",
    meta: {
      authRequired: false
    },
    component: GlLevel2CheckersQueue
  },

  {
    path: "/glLevel1/gl1-checkers-queue",
    name: "glLevel1-checkers-queue",
    meta: {
      authRequired: false
    },
    component: GlLevel1CheckersQueue
  },

  {
    path: "/cash-transactions/ct-checkers-queue",
    name: "funds-transfer-cash-transactions-checkers-queue",
    meta: {
      authRequired: false
    },
    component: CashTransactionsCheckersQueue
  },

  {
    path: "/funds-transfer/ft-checkers-queue",
    name: "funds-transfer-ft-checkers-queue",
    meta: {
      authRequired: false
    },
    component: FundsTransferCheckersQueue
  },

  {
    path: "/funds-transfer/cw-checkers-queue",
    name: "funds-transfer-cw-checkers-queue",
    meta: {
      authRequired: false
    },
    component: CashWithdrawalCheckersQueue
  },

  {
    path: "/funds-transfer/cd-checkers-queue",
    name: "funds-transfer-cd-checkers-queue",
    meta: {
      authRequired: false
    },
    component: CashDepositCheckersQueue
  },
  {
    path: "/funds-transfer/ct-checkers-queue",
    name: "funds-transfer-ct-checkers-queue",
    meta: {
      authRequired: false
    },
    component: CashTransactionsCheckersQueue
  },

  {
    path: "/branch/checkers-queue",
    name: "branch-checkers-queue",
    meta: {
      authRequired: false
    },
    component: BranchCheckersQueue
  },

  {
    path: "/customer/sub-account-checkers-queue",
    name: "customer-sub-account-checkers-queue",
    meta: {
      authRequired: false
    },
    component: CustomerSubAccountCheckersQueue
  },

  {
    path: "/customer/create-checkers-queue",
    name: "customer-create-checkers-queue",
    meta: {
      authRequired: false
    },
    component: CustomersCheckersQueue
  },

  {
    path: "/bank/upload-bank-logo",
    name: "bank-upload-bank-logo",
    meta: {
      authRequired: false
    },
    component: UploadBankLogo
  },
  {
    path: "/product/select-product-type",
    name: "product-select-type-create",
    meta: {
      authRequired: false
    },
    component: SelectProductTypeCreate
  },
  ///// Create Product (Dynamic Form)
  {
    path: "/product/create-product",
    name: "product-create-product",
    meta: {
      authRequired: false
    },
    component: CreateProduct
  },
  ///// Funds Transfer
  {
    path: "/fundsTransfer/customer-balance-enquiry",
    name: "funds-transfer-customer-balance-enquiry",
    meta: {
      authRequired: false
    },
    component: CustomerAccountEnquiry
  },
  {
    path: "/fundsTransfer/transfer-funds",
    name: "funds-transfer-transfer-funds",
    meta: {
      authRequired: false
    },
    component: TransferFunds
  },
  {
    path: "/fundsTransfer/cash-transactions",
    name: "funds-transfer-cash-transactions",
    meta: {
      authRequired: false
    },
    component: VaultTransferToCBN
  },
  {
    path: "/fundsTransfer/cash-withdrawals",
    name: "funds-transfer-cash-withdrawal",
    meta: {
      authRequired: false
    },
    component: CashWithdrawal
  },

  ///// Transaction Code
  {
    path: "/transactionCode/create-transaction-code",
    name: "transaction-code-create",
    meta: {
      authRequired: false
    },
    component: CreateTransactionCode
  },
  {
    path: "/transactionCode/all-transaction-code",
    name: "transaction-code-all",
    meta: {
      authRequired: false
    },
    component: AllTransactionCodes
  },
  ///// Roles & Privileges
  {
    path: "/role/create-role",
    name: "role-create-role",
    meta: {
      authRequired: false
    },
    component: CreateRole
  },
  {
    path: "/role/all-role",
    name: "role-all-role",
    meta: {
      authRequired: false
    },
    component: AllRoles
  },
  {
    path: "/role/edit-role",
    name: "role-edit-role",
    meta: {
      authRequired: false
    },
    component: EditRolePrivilege
  },

  ///// Reports
  {
    path: "/report/create",
    name: "create-report-view",
    meta: {
      authRequired: false
    },
    component: CreateReport
  },
  {
    path: "/report/view-reports",
    name: "report-view-reports",
    meta: {
      authRequired: false
    },
    component: AllReports
  },
  {
    path: "/report/single-report",
    name: "report-single-report",
    meta: {
      authRequired: false
    },
    component: Report
  },

  {
    path: "/report/query-reports",
    name: "report-query-report",
    meta: {
      authRequired: false
    },
    component: QueryReport
  },

  ///// Branches
  {
    path: "/branch/create",
    name: "branch-create",
    meta: {
      authRequired: false
    },
    component: BranchCreate
  },

  {
    path: "/branch/all",
    name: "branch-all",
    meta: {
      authRequired: false
    },
    component: BranchAll
  },

  /// Bank
  {
    path: "/bank/create",
    name: "bank-create",
    meta: {
      authRequired: false
    },
    component: CreateBank
  },
  {
    path: "/bank/all",
    name: "bank-all",
    meta: {
      authRequired: false
    },
    component: AllBanks
  },

  {
    path: "/",
    name: "dashboard",
    meta: {
      authRequired: false
    },
    component: Dashboard
  },

  //  Auth
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },

  {
    path: "/change-default-password",
    name: "change-default-password",
    component: ChangeDefaultPassword,
    meta: {
      authRequired: false
    }
  },

  {
    path: "/forgot-password",
    name: "Forgot password",
    component: ForgotPassword,
    meta: {
      authRequired: false
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: false,
      beforeResolve() {
        StoreUtils.dispatch("auth/logOut");
      }
    }
  },
  {
    path: "/404",
    name: "404",
    component: NotFound
  },
  {
    path: "/network-issue",
    name: "network-issue",
    component: NetworkIssues
  },

  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404"
  }
];
