<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-6">
          <div>
            <div class="form-group">
              <label>Slab Code</label>
              <input
                v-if="currentSlab.editable"
                v-model="currentSlabCode"
                type="text"
                placeholder="Slab Code"
                class="form-control"
                :class="{ error: slabCodeError }"
              />
              <div v-else class="default-value">
                {{ currentSlab.slabCode }}
              </div>
            </div>
            <p v-if="slabCodeError" class="form-error-message">
              Slab Code is required
            </p>
          </div>
        </div>
        <div class="col-6">
          <div>
            <div class="form-group">
              <label>Slab Maximum Amount</label>

              <input
                v-model="currentSlabMaxAmount"
                type="number"
                step="0.01"
                placeholder="Entry Amount"
                class="form-control"
                :class="{ error: slabAmountError }"
              />
            </div>

            <p v-if="slabAmountError" class="form-error-message">
              Slab Amount is required
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <div class="table-responsive">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Account Number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="entry in accountingEntry.currentAccountingEntryEntries"
                  :key="entry.id"
                >
                  <td>
                    <p>
                      <span v-if="entry.entryDrCr === 'DR'" title="Debit">
                        <svg
                          height="30"
                          width="30"
                          viewBox="0 0 44 44"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22 0C26.3512 0 30.6047 1.29028 34.2225 3.70767C37.8404 6.12506 40.6602 9.56099 42.3253 13.581C43.9905 17.6009 44.4261 22.0244 43.5773 26.292C42.7284 30.5596 40.6331 34.4796 37.5564 37.5564C34.4796 40.6331 30.5596 42.7284 26.292 43.5773C22.0244 44.4261 17.6009 43.9905 13.581 42.3253C9.56099 40.6602 6.12506 37.8404 3.70767 34.2225C1.29028 30.6047 0 26.3512 0 22C0 16.1652 2.31785 10.5695 6.44365 6.44365C10.5695 2.31785 16.1652 0 22 0V0Z"
                            fill="#AC2020"
                          />
                          <path
                            d="M16.363 29.869L27.797 18.435V23.379C27.797 23.7448 27.9423 24.0955 28.2009 24.3541C28.4595 24.6127 28.8102 24.758 29.176 24.758C29.5417 24.758 29.8925 24.6127 30.1511 24.3541C30.4097 24.0955 30.555 23.7448 30.555 23.379V15.106C30.5551 14.9249 30.5195 14.7456 30.4502 14.5783C30.381 14.411 30.2794 14.259 30.1513 14.131C30.0232 14.003 29.8711 13.9015 29.7038 13.8324C29.5364 13.7632 29.357 13.7278 29.176 13.728H20.903C20.5372 13.728 20.1865 13.8733 19.9279 14.1319C19.6693 14.3905 19.524 14.7413 19.524 15.107C19.524 15.4728 19.6693 15.8235 19.9279 16.0821C20.1865 16.3407 20.5372 16.486 20.903 16.486H25.847L14.413 27.92C14.1605 28.1799 14.0204 28.5286 14.023 28.8909C14.0256 29.2532 14.1707 29.6 14.4268 29.8561C14.683 30.1123 15.0298 30.2574 15.3921 30.26C15.7544 30.2626 16.1031 30.1225 16.363 29.87V29.869Z"
                            fill="white"
                          />
                        </svg>
                      </span>

                      <span v-if="entry.entryDrCr === 'CR'" title="Credit">
                        <svg
                          height="30"
                          width="30"
                          viewBox="0 0 44 44"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22 0C26.3512 0 30.6047 1.29028 34.2225 3.70767C37.8404 6.12506 40.6602 9.56099 42.3253 13.581C43.9905 17.6009 44.4261 22.0244 43.5773 26.292C42.7284 30.5596 40.6331 34.4796 37.5564 37.5564C34.4796 40.6331 30.5596 42.7284 26.292 43.5773C22.0244 44.4261 17.6009 43.9905 13.581 42.3253C9.56099 40.6602 6.12506 37.8404 3.70767 34.2225C1.29028 30.6047 0 26.3512 0 22C0 16.1652 2.31785 10.5695 6.44365 6.44365C10.5695 2.31785 16.1652 0 22 0V0Z"
                            fill="#1EA58B"
                          />
                          <path
                            d="M16.363 14.131L27.797 25.566V20.621C27.797 20.2553 27.9423 19.9045 28.2009 19.6459C28.4595 19.3873 28.8102 19.242 29.176 19.242C29.5417 19.242 29.8925 19.3873 30.1511 19.6459C30.4097 19.9045 30.555 20.2553 30.555 20.621V28.894C30.5551 29.0751 30.5195 29.2544 30.4503 29.4217C30.381 29.589 30.2794 29.741 30.1513 29.869C30.0232 29.997 29.8711 30.0985 29.7038 30.1677C29.5364 30.2368 29.3571 30.2723 29.176 30.272H20.903C20.5372 30.272 20.1865 30.1267 19.9279 29.8681C19.6693 29.6095 19.524 29.2587 19.524 28.893C19.524 28.5273 19.6693 28.1765 19.9279 27.9179C20.1865 27.6593 20.5372 27.514 20.903 27.514H25.847L14.413 16.081C14.2819 15.9536 14.1775 15.8015 14.1057 15.6334C14.034 15.4653 13.9963 15.2847 13.995 15.1019C13.9937 14.9191 14.0287 14.738 14.0981 14.5689C14.1674 14.3998 14.2697 14.2461 14.3989 14.1169C14.5281 13.9877 14.6818 13.8854 14.8508 13.8161C15.0199 13.7468 15.2011 13.7117 15.3839 13.713C15.5666 13.7144 15.7473 13.752 15.9154 13.8237C16.0835 13.8955 16.2356 13.9999 16.363 14.131V14.131Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p
                      v-if="
                        amountTypeOptions.filter(
                          it => it.value === entry.entryAmount
                        )[0]
                      "
                      class="font-size-14 text-truncate"
                    >
                      {{
                        amountTypeOptions.filter(
                          it => it.value === entry.entryAmount
                        )[0].name
                      }}
                    </p>
                    <p v-else class="font-size-14 text-truncate">
                      <span v-if="entry.entryAmountType === 'FLAT'">₦ </span>
                      {{ entry.entryAmount }}
                      <span v-if="entry.entryAmountType === 'PERCENT'">%</span>
                    </p>
                  </td>
                  <td>
                    <p
                      v-if="
                        accountEntryOptions.filter(
                          it => it.value === entry.entryAccount
                        )[0]
                      "
                      class="font-size-14 text-truncate"
                    >
                      {{
                        accountEntryOptions.filter(
                          it => it.value === entry.entryAccount
                        )[0].name
                      }}
                    </p>
                    <p v-else>
                      {{ entry.entryAccount }}
                    </p>
                  </td>
                  <td>
                    <a
                      @click="removeEntry(entry)"
                      class="action-icon text-danger"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-sm-4">
          <form @submit.prevent="addEntry">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Type</label>
                  <multiselect
                    v-model="formBody.entryDrCr"
                    :options="entryTypeOptions"
                    placeholder="Entry Type"
                    @close="$v.formBody.entryDrCr.$touch()"
                  ></multiselect>
                </div>

                <template v-if="$v.formBody.entryDrCr.$error">
                  <p
                    v-if="!$v.formBody.entryDrCr.required"
                    class="form-error-message"
                  >
                    Type is required
                  </p>
                </template>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label>Amount Type</label>
                  <multiselect
                    v-model="formBody.entryAmountType"
                    :options="entryAmountTypeOptions"
                    placeholder="Entry Type"
                    @close="$v.formBody.entryAmountType.$touch()"
                  ></multiselect>
                </div>

                <template v-if="$v.formBody.entryAmountType.$error">
                  <p
                    v-if="!$v.formBody.entryAmountType.required"
                    class="form-error-message"
                  >
                    Amount Type is required
                  </p>
                </template>
              </div>

              <div v-if="formBody.entryAmountType" class="col-sm-12">
                <div v-if="formBody.entryAmountType === 'PERCENT'">
                  <div class="form-group">
                    <label>Amount (%)</label>
                    <input
                      v-model="formBody.entryAmount"
                      type="text"
                      step="0.01"
                      placeholder="Entry Amount"
                      class="form-control"
                      :class="{ error: entryAmountError }"
                    />
                  </div>
                  <p v-if="entryAmountError" class="form-error-message">
                    Entry Amount is required
                  </p>
                </div>
                <div v-else>
                  <div
                    @click="togglePrincipalAmount"
                    :class="{ active: principalAmount }"
                    class="assign-default"
                  >
                    <span></span>
                    <label>Principal Amount</label>
                  </div>
                  <div class="form-group">
                    <label>Amount</label>
                    <input
                      v-show="!principalAmount"
                      v-model="formBody.entryAmount"
                      type="text"
                      placeholder="Entry Amount"
                      class="form-control"
                      :class="{ error: entryAmountError }"
                    />
                    <multiselect
                      label="name"
                      v-show="principalAmount"
                      :multiple="false"
                      v-model="entryAmount"
                      @input="assignEntryAmount"
                      :options="amountTypeOptions"
                      placeholder="Select Amount Type"
                    >
                    </multiselect>
                  </div>

                  <p v-if="entryAmountError" class="form-error-message">
                    Entry Amount is required
                  </p>
                </div>
              </div>

              <div class="col-sm-12">
                <div
                  @click="togglePrincipalAccount"
                  :class="{ active: principalAccount }"
                  class="assign-default"
                >
                  <span></span>
                  <label>Principal Account</label>
                </div>
                <div class="form-group">
                  <label>Account Number</label>
                  <input
                    v-show="!principalAccount"
                    v-model="formBody.entryAccount"
                    type="text"
                    placeholder="Entry Account"
                    class="form-control"
                    :class="{ error: entryAccountError }"
                  />
                  <multiselect
                    label="name"
                    v-show="principalAccount"
                    :multiple="false"
                    v-model="entryAccount"
                    @input="assignEntryAccount"
                    :options="accountEntryOptions"
                    placeholder="Select Amount Type"
                  >
                  </multiselect>
                </div>
              </div>
            </div>
            <p class="text-right">
              <span>
                <button type="submit" class="btn btn-secondary mr-1">
                  Add Entry
                </button>
              </span>
            </p>
          </form>
        </div>
      </div>

      <br />
      <br />
      <div class="row">
        <div class="col-sm-8">
          <p class="text-right">
            <span class="entry-total-title mr-1">Total</span>
            <span class="entry-total">
              ₦ {{ entriesFlatTotal | newAmountFormatter }}
            </span>
            <span class="divider">~</span>
            <span class="entry-total">{{ entriesPercentageTotal }}%</span>
          </p>
        </div>
        <div class="col-sm-4">
          <p
            v-if="entriesFlatTotal === 0 && entriesPercentageTotal === 0"
            class="text-right"
          >
            <span v-if="loader.actionButtonLoader" class="formLoaderHold">
              <BaseComponentLoader />
            </span>
            <span v-else>
              <button
                @click="showConfirmationModal()"
                class="btn btn-primary mr-1"
              >
                Assign Entry
              </button>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import RouterUtils from "../../../../../utils/BaseUtils/RouterUtils";
import NotificationUtils from "../../../../../utils/BaseUtils/NotificationUtils";

export default {
  name: "AssignEntriesForm",
  data() {
    return {
      entryTypeOptions: ["Debit", "Credit"],
      entryAmountTypeOptions: ["FLAT", "PERCENT"],
      principalAccount: "",
      entryAmount: {},
      entryAccount: {},
      entryAccountType: null,
      accountType: {},
      isAccountEntry: false,
      entryAccountError: false,
      accountEntryOptions: [
        { name: "Principal Account Number", value: "$ACCOUNT" },
        { name: "Principal Credit Account Number", value: "$CREDIT_ACCOUNT" },
        { name: "Principal Debit Account Number", value: "$DEBIT_ACCOUNT" },
        { name: "Account Field 1", value: "$ACCOUNT_1" },
        { name: "Account Field 2", value: "$ACCOUNT_2" },
        { name: "Account Field 3", value: "$ACCOUNT_3" },
        { name: "Account Field 4", value: "$ACCOUNT_4" },
        { name: "Account Field 5", value: "$ACCOUNT_5" },
        { name: "Account Field 6", value: "$ACCOUNT_6" },
        { name: "Account Field 7", value: "$ACCOUNT_7" },
        { name: "Account Field 8", value: "$ACCOUNT_8" },
        { name: "Account Field 9", value: "$ACCOUNT_9" },
        { name: "Account Field 10", value: "$ACCOUNT_10" }
      ],
      amountTypeOptions: [
        { name: "Principal Amount", value: "$AMOUNT" },
        { name: "Principal Credit Amount", value: "$CREDIT_AMOUNT" },
        { name: "Principal Debit Amount", value: "$DEBIT_AMOUNT" },
        { name: "Amount Field 1", value: "$AMOUNT_1" },
        { name: "Amount Field 2", value: "$AMOUNT_2" },
        { name: "Amount Field 3", value: "$AMOUNT_3" },
        { name: "Amount Field 4", value: "$AMOUNT_4" },
        { name: "Amount Field 5", value: "$AMOUNT_5" },
        { name: "Amount Field 6", value: "$AMOUNT_6" },
        { name: "Amount Field 7", value: "$AMOUNT_7" },
        { name: "Amount Field 8", value: "$AMOUNT_8" },
        { name: "Amount Field 9", value: "$AMOUNT_9" },
        { name: "Amount Field 10", value: "$AMOUNT_10" }
      ],
      principalAmount: false,
      entryAmountType: "number",
      entryAmountError: false,
      formBody: {},
      slab: {},
      slabCodeError: false,
      slabAmountError: false
    };
  },
  async created() {
    StoreUtils.commit(
      "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES",
      []
    );

    if (
      StoreUtils.rootGetter(StoreUtils.getters.router.GET_PAGE_TITLE) ===
      RouterUtils.pageTitles.accountingEntry.EDIT
    ) {
      await StoreUtils.dispatch(
        StoreUtils.actions.accountingEntry.FETCH_ACCOUNTING_ENTRY_ENTRIES,
        StoreUtils.rootGetter(
          StoreUtils.getters.accountingEntry.GET_CURRENT_ACCOUNTING_ENTRY_CODE
        )
      );
    }
    // const currentSlab = this.currentSlab;
    // this.slab = currentSlab;
  },
  computed: {
    ...mapState(["accountingEntry", "loader"]),
    entriesTotal() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.accountingEntry.GET_ENTRIES_TOTAL
      );
    },
    entriesFlatTotal() {
      return StoreUtils.rootGetter("accountingEntry/getEntriesFlatTotal");
    },
    entriesPercentageTotal() {
      return StoreUtils.rootGetter("accountingEntry/getEntriesPercentageTotal");
    },
    currentSlab() {
      return StoreUtils.rootGetter(
        "accountingEntry/getCurrentAccountingEntrySlab"
      );
    },

    currentSlabCode: {
      get() {
        return StoreUtils.rootGetter(
          "accountingEntry/getCurrentAccountingEntrySlab"
        ).slabCode;
      },
      set(value) {
        StoreUtils.commit(
          "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_SLAB_CODE",
          value
        );
      }
    },
    currentSlabMaxAmount: {
      get() {
        return StoreUtils.rootGetter(
          "accountingEntry/getCurrentAccountingEntrySlab"
        ).slabMaxAmount;
      },
      set(value) {
        StoreUtils.commit(
          "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_SLAB_MAX_AMOUNT",
          value
        );
      }
    }
  },
  validations: {
    formBody: {
      entryDrCr: { required },
      entryAmountType: { required },
      entryAccountType: { value: "" }
    }
  },
  methods: {
    resetPrincipalToggle() {
      this.principalAccount = 0;
      this.principalAmount = 0;
      this.entryAccountError = false;
      this.entryAmountError = false;
      this.entryAccountType = "number";
      this.entryAmountType = "number";
    },
    togglePrincipalAmount() {
      this.principalAmount = !this.principalAmount;
      this.entryAmount = this.entryAmountTypeOptions[0];
      this.entryAmountType = "text";
      // if (this.principalAmount) {
      //   this.entryAmountType = "text";
      // } else {
      //   this.formBody.entryAmount = "";
      //   this.entryAmountType = "number";
      // }
    },
    togglePrincipalAccount() {
      this.principalAccount = !this.principalAccount;
      this.entryAccount = this.accountEntryOptions[0];
    },
    assignEntryAmount() {
      this.formBody.entryAmount = this.entryAmount.value;
    },
    assignEntryAccount() {
      this.formBody.entryAccount = this.entryAccount.value;
    },
    getEntryDrCr(entryDrCr) {
      switch (entryDrCr) {
        case "Debit":
          return "DR";
        default:
          return "CR";
      }
    },
    showConfirmationModal() {
      let notificationModalBody = {
        type: "warning", //error, success, warning
        mode: "yes-no", // default, yes-no
        title: "Confirmation",
        message: "Are you sure you want to assign these entries?",
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "No",
        yesAction: () => {
          // StoreUtils.dispatch(
          //   StoreUtils.actions.accountingEntry.ASSIGN_ENTRIES,
          //   this.accountingEntry.currentAccountingEntryEntries
          // );
          this.assignEntries();
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Yes"
      };
      if (this.currentSlab.slabMaxAmount) {
        this.slabAmountError = false;
        NotificationUtils.showNotificationModal(notificationModalBody);
      } else {
        this.slabAmountError = true;
      }
    },
    assignEntries() {
      let payload = {
        entries: this.accountingEntry.currentAccountingEntryEntries,
        slab: this.currentSlab
      };
      StoreUtils.dispatch("accountingEntry/assignEntries", payload);
    },
    addEntry() {
      this.$v.$touch();
      if (this.formBody.entryAmount === "") {
        this.entryAmountError = true;
        return;
      }
      if (this.formBody.entryAccount === "") {
        this.entryAccountError = true;
        return;
      }
      if (!this.$v.$invalid) {
        this.formBody.entryDrCr = this.getEntryDrCr(this.formBody.entryDrCr);
        this.formBody.accountType = this.accountType.value;
        StoreUtils.dispatch(
          StoreUtils.actions.accountingEntry.ADD_ENTRY_TO_CURRENT,
          this.formBody
        )
          .then(() => {
            this.formBody = {};
            this.$v.$reset();
            this.resetPrincipalToggle();
          })
          .catch(() => {});
      }
    },
    removeEntry(entry) {
      StoreUtils.dispatch(
        StoreUtils.actions.accountingEntry.REMOVE_ENTRY_FROM_CURRENT,
        entry
      );
    }
  }
};
</script>

<style scoped>
.assign-default {
  position: absolute;
  right: 14px;
  font-weight: 600;
  display: flex;
  cursor: pointer;
}
.assign-default span {
  background-color: #74778c;
  display: flex;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.assign-default.active span {
  background-color: #546ee7;
}
.assign-default label {
  font-size: 10px;
  line-height: 14px;
  font-weight: 800 !important;
  margin-top: 4px;
  display: inline-block;
  cursor: pointer;
}
.assign-default .custom-control-label {
}
.default-value {
  margin: 0;
  font-family: inherit;
  overflow: visible;
  font-weight: 500;
  color: #9e9e9e;
  padding: 0.47rem 0.75rem;
  font-size: 14px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.94rem + 2px);
  line-height: 1.5;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
</style>
