// create a new Bank
import VaultService from "../../services/oldServices/VaultService";

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    // moduleLoadingTwo: true,
    moduleLoadingThree: true,
    moduleLoadingFour: true,
    moduleLoadingFive: false,
    createTillFormBody: {
      source: "web"
    },
    // bankStatData: {},
    vaults: [],
    vaultCheckersQueue: []
  };
};
export const state = getDefaultState();

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  // SET_LOADING_TWO(state, status) {
  //     state.moduleLoadingTwo = status;
  // },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },

  SET_ALL_VAULTS(state, payload) {
    state.vaults = payload;
  },
  SET_ALL_VAULTS_CHECKERS_QUEUE(state, payload) {
    state.vaultCheckersQueue = payload;
  }
};
export const actions = {
  checkerAuthorizeVault({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FIVE", true);

    return VaultService.checkerAuthorizeVault(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FIVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success -> ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FIVE", false);

        throw error;
      });
  },

  allVaultCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FOUR", true);

    return VaultService.allVaultCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FOUR", false);

        const responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_VAULTS_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FOUR", false);

        throw error;
      });
  },

  createVault({ commit, dispatch }, payload) {
    commit("SET_TEST", "test");

    // start loader
    commit("SET_LOADING", true);

    return VaultService.createVault(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },
  // list all tills
  allVaults({ commit, dispatch }, payload) {
    commit("SET_TEST", "test");

    // start loader
    commit("SET_LOADING_THREE", true);

    return VaultService.allVaults(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_VAULTS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        throw error;
      });
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
