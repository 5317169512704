<template>
  <div class="table-section">
    <div class="row">
      <div class="col-12">
        <SearchFixedDepositForm />
      </div>
    </div>
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-secondary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <!-- A virtual composite column -->
      <template v-slot:cell(customerBankCode)="data">
        <p class="name-field">
          <span>{{ data.item.customerBankCode }}</span>
        </p>
      </template>

      <template v-slot:cell(accountBalance)="data">
        <p class="name-field">
          <span> ₦ {{ data.item.accountBalance }} </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(accountCreatedDate)="data">
        <p class="name-field">
          <span>
            {{ data.item.accountCreatedDate | formatDateForTable }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(accountLastAccessTimestamp)="data">
        <p class="name-field">
          <span>
            {{ data.item.accountLastAccessTimestamp | formatDateForTable }}
          </span>
        </p>
      </template>

      <template v-slot:cell(accountClass)="data">
        <p class="name-field">
          <span class="badge badge-pill badge-soft-secondary font-size-12">
            {{ data.item.accountClass }}
          </span>
        </p>
      </template>

      <template v-slot:cell(accountType)="data">
        <p class="name-field">
          <span class="badge badge-pill badge-soft-secondary font-size-12">
            {{ data.item.accountType }}
          </span>
        </p>
      </template>

      <template v-slot:cell(accountCurrency)="data">
        <p class="name-field">
          <span class="badge badge-pill badge-soft-secondary font-size-12">
            {{ data.item.accountCurrency }}
          </span>
        </p>
      </template>

      <template v-slot:cell(accountTenure)="data">
        <p class="name-field">
          <span class="badge badge-pill badge-soft-secondary font-size-12">
            {{ data.item.accountTenure }} days
          </span>
        </p>
      </template>

      <template v-slot:cell(accountStatus)="data">
        <p class="name-field">
          <AppStatusBadge :status-value="data.item.accountStatus" />
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(action)="data">
        <p class="name-field">
          <AllFixedDepositsTableActionModal :current-data="data.item" />
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>
    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import SearchFixedDepositForm from "../../../Forms/Investment/FixedDeposit/SearchFixedDepositForm";
import AllFixedDepositsTableActionModal from "../../../Modals/Investment/FixedDeposit/AllFixedDepositsTableActionModal";

export default {
  name: "AllFixedDepositsTable",
  components: { AllFixedDepositsTableActionModal, SearchFixedDepositForm },
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },
        { key: "accountNumber", label: "Account No.", sortable: true },
        { key: "accountBalance", label: "Account Balance", sortable: true },

        { key: "accountName", label: "Account Name", sortable: true },
        {
          key: "accountCustomerId",
          label: "Account Customer Id",
          sortable: true
        },
        { key: "accountClass", label: "Account Class", sortable: true },
        { key: "accountStatus", label: "Account Status", sortable: true },
        { key: "accountType", label: "Account Type", sortable: true },
        { key: "accountCreatedDate", label: "Created At", sortable: true },
        { key: "accountBranchCode", label: "Branch Code", sortable: true },
        {
          key: "accountLastAccessTimestamp",
          label: "Last Access Date",
          sortable: true
        },
        { key: "accountCurrency", label: "Account Currency", sortable: true },
        { key: "accountTenure", label: "Tenure", sortable: true },
        { key: "accountGlCategory", label: " Gl Category", sortable: true },
        {
          key: "accountGlLevel1Code",
          label: "Account Gl Level 1",
          sortable: true
        },
        {
          key: "accountGlLevel2Code",
          label: "Account Gl Level 2",
          sortable: true
        },

        { key: "action", label: "Action", sortable: false }
        // { key: "action", label: "Action", sortable: false }
      ],

      json_fields: {
        accountChecker: "accountChecker",
        accountBankCode: "accountBankCode",
        accountUpdatedDate: "accountUpdatedDate",
        accountGlLevel1Code: "accountGlLevel1Code",
        accountName: "accountName",
        accountInterestRate: "accountInterestRate",
        accountType: "accountType",
        accountAllowNegativeBalance: "accountAllowNegativeBalance",
        accountCurrency: "accountCurrency",
        accountNumber: "accountNumber",
        accountLastAccessTimestamp: "accountLastAccessTimestamp",
        accountDateClosed: "accountDateClosed",
        accountGlCategory: "accountGlCategory",
        accountBranchCode: "accountBranchCode",
        accountStatus: "accountStatus",
        accountMaker: "accountMaker",
        accountId: "accountId",
        accountCreatedDate: "accountCreatedDate",
        accountCustomerId: "accountCustomerId",
        accountClass: "accountClass",
        accountLedgerBalance: "accountLedgerBalance",
        accountGlLevel2Code: "accountGlLevel2Code",
        accountBalance: "accountBalance",
        accountDateOpened: "accountDateOpened",
        accountOverdraft: "accountOverdraft",
        accountOverdraftExpiry: "accountOverdraftExpiry",
        accountOverdraftRate: "accountOverdraftRate",
        accountSort: "accountSort",
        accountOverdraftIssuedDate: "accountOverdraftIssuedDate",
        accountOverdraftStatus: "accountOverdraftStatus",
        accountParent: "accountParent",
        accountStartDate: "accountStartDate",
        accountEndDate: "accountEndDate",
        accountInterest: "accountInterest",
        accountTenure: "accountTenure",
        accountMaturedAmount: "accountMaturedAmount",
        accountMaturedInterest: "accountMaturedInterest",
        accountPenalty: "accountPenalty"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.table.tableData,
      isBusy: state => state.loader.tableLoader
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    returnItems() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;
        return initials;
      } else {
        return initials.substring(0, 2);
      }
    },
    fetchTableData() {
      const payload = {
        accountBankCode: StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BANK_CODE
        ),
        readType: "search",
        searchItem: ""
      };
      StoreUtils.dispatch("investment/fetchAllFixedDeposits", payload);
    }
  },
  created() {
    this.fetchTableData();
  }
};
</script>
<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
.view-more-info {
  margin-right: 2px;
  display: inline-block;
  color: #ffffff;
}
.view-more-info a {
  cursor: pointer;
}
.view-more-info a i {
  cursor: pointer;
}
.actual-name {
  margin-left: 2px;
  line-height: 15px;
}
</style>
