<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import PulseLoader from "../../../AppComponents/PulseLoader";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "AllLoansTable",
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        { key: "info", label: "More Info", sortable: false },
        { key: "loanProductCode", label: "Loan product", sortable: true },
        { key: "accountName", label: "Account Name", sortable: true },
        { key: "loanAccountNumber", label: "Account Number", sortable: true },
        { key: "loanAmount", label: "Amount", sortable: true },
        { key: "loanInterestRate", label: "Interest Rate", sortable: true },
        { key: "loanTenure", label: "Tenure", sortable: true },
        { key: "loanCreatedAt", label: "Created At", sortable: true },
        { key: "loanStatus", label: "Loan Status", sortable: true },
        { key: "loanMaker", label: "Maker", sortable: true },
        { key: "action", label: "Action", sortable: false }
      ],

      json_fields: {
        "Loan Bank Code": "loanBankCode",
        "account Checker": "accountChecker",
        "loan Product Code": "loanProductCode",
        "account Updated Date": "accountUpdatedDate",
        "loan Reason": "loanReason",
        "account Name": "accountName",
        "account Interest Rate": "accountInterestRate",
        "account Currency": "accountCurrency",
        "loan Created At": "loanCreatedAt",
        "loan Otp": "loanOtp",
        "account Status": "accountStatus",
        "account Maker": "accountMaker",
        "account CreatedDate": "accountCreatedDate",
        "account CustomerId": "accountCustomerId",
        "loan Status": "loanStatus",
        "loan Id": "loanId",
        "account Balance": "accountBalance",
        "account GlLevel2 Code": "accountGlLevel2Code",
        "loan Tenure": "loanTenure",
        "loan UpdatedAt": "loanUpdatedAt",
        "loan Cba Reference": "loanCbaReference",
        "account Class": "accountClass",
        "loan Account Number": "loanAccountNumber",
        "account Id": "accountId",
        "account Branch Code": "accountBranchCode",
        "loan Amount": "loanAmount",
        "account Gl Category": "accountGlCategory",
        "account Date Closed": "accountDateClosed",
        "account Last Access Timestamp": "accountLastAccessTimestamp",
        "account Number": "accountNumber",
        "loan Terminated By": "loanTerminatedBy",
        "loan Maker": "loanMaker",
        "account Allow Negative Balance": "accountAllowNegativeBalance",
        "account Type": "accountType",
        "account Gl Level1Code": "accountGlLevel1Code",
        "account Bank Code": "accountBankCode",
        "loan Interest Rate": "loanInterestRate",
        "loan Checker": "loanChecker",
        "account Date Opened": "accountDateOpened",
        "account Ledger Balance": "accountLedgerBalance"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],

      showOTPModal: false,
      activeLoanStatus: "",
      activeLoanPayload: {},
      OTPFormBody: {}
    };
  },
  components: {
    PulseLoader
  },
  computed: {
    ...mapState({
      loan: state => state.loan,
      items: state => state.loan.allLoans,
      isBusy: state => state.loan.moduleLoadingThree
    }),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  validations: {
    OTPFormBody: {
      otp: { required }
    }
  },
  methods: {
    fetchTableData() {
      const payload = {
        loanBankCode: this.$store.getters["user/getUserBankCode"]
      };

      this.$store.dispatch("loan/allLoans", payload, { root: true });
    },
    changeLoanStatusComplete() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let payload = this.activeLoanPayload;
        payload.loanAction = "COMPLETE";
        payload.loanOtp = this.OTPFormBody.otp;

        this.$store.dispatch("loan/setModuleLoadingFive", true, { root: true });
        this.$store
          .dispatch("loan/changeLoanStatus", payload, { root: true })
          .then(response => {
            this.$store.dispatch("loan/setModuleLoadingFive", false, {
              root: true
            });
            if (response.responseCode === "00") {
              this.closeOTPModal();
              this.fetchTableData();
            }
          });
      }
    },
    viewLoanSchedule(loan) {
      StoreUtils.dispatch(StoreUtils.actions.loan.VIEW_LOAN_SCHEDULE, loan);
    },
    changeLoanStatusInitiate(status, loan) {
      let payload = {
        loanId: loan.loanId,
        loanStatus: status,
        loanChecker: this.$store.getters["user/getUserId"],
        loanAction: "INITIATE",
        loanBankCode: loan.loanBankCode
      };

      this.$store
        .dispatch("loan/changeLoanStatus", payload, { root: true })
        .then(response => {
          if (response.responseCode === "00") {
            this.activeLoanPayload = payload;
            this.activeLoanStatus = status;
            this.openOTPModal();
          }
        });
    },
    openOTPModal() {
      this.showOTPModal = true;
    },
    closeOTPModal() {
      this.showOTPModal = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;

        return initials;
      } else {
        return initials.substring(0, 2);
      }
    }
  },

  created() {
    this.fetchTableData();
  }
};
</script>

<template>
  <div class="table-section">
    <vodal
      :show="showOTPModal"
      :width="350"
      :height="300"
      measure="px"
      animation="zoom"
      @hide="closeOTPModal()"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <!--          <h4 class="card-title mb-4">Customer Info</h4>-->
          <div>
            <div class="view-more-customer-info">
              <div class="view-more-customer-info-wrap">
                <div class="view-more-top">
                  <h4 class="card-title mb-2">
                    Confirm Loan
                    <span v-if="activeLoanStatus === 'APPROVED'">Approval</span>
                    <span v-if="activeLoanStatus === 'REJECTED'"
                      >Rejection</span
                    >
                    <span v-if="activeLoanStatus === 'LIQUIDATED'"
                      >Liquidation</span
                    >
                    <span v-if="activeLoanStatus === 'SETTLED'"
                      >Settlement</span
                    >
                  </h4>
                  <p class="card-title-sub">
                    Please enter the OTP sent to your mail.
                  </p>
                </div>

                <div class="view-more-base">
                  <div>
                    <form @submit.prevent="changeLoanStatusComplete">
                      <div class="row">
                        <div class="col-sm-12">
                          <div>
                            <BaseInput
                              :inputType="'password'"
                              maxlength="6"
                              v-model="OTPFormBody.otp"
                              placeholder="OTP"
                              :hasPreIcon="false"
                              :label="'OTP'"
                              :class="{
                                error: $v.OTPFormBody.otp.$error
                              }"
                              @blur="$v.OTPFormBody.otp.$touch()"
                            >
                            </BaseInput>

                            <template v-if="$v.OTPFormBody.otp.$error">
                              <p
                                v-if="!$v.OTPFormBody.otp.required"
                                class="form-error-message"
                              >
                                OTP required
                              </p>
                            </template>
                          </div>
                        </div>

                        <p class="full-width text-right pr-2">
                          <span
                            v-if="loan.moduleLoadingFive"
                            class="formLoaderHold"
                          >
                            <BaseComponentLoader />
                          </span>
                          <span v-else>
                            <button type="submit" class="btn btn-primary mr-1">
                              <span v-if="activeLoanStatus === 'APPROVED'"
                                >Approve
                              </span>
                              <span v-if="activeLoanStatus === 'REJECTED'"
                                >Reject
                              </span>
                              <span v-if="activeLoanStatus === 'LIQUIDATED'"
                                >Liquidate
                              </span>
                              <span v-if="activeLoanStatus === 'SETTLED'"
                                >Settle
                              </span>
                              Loan
                            </button>
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vodal>
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template v-slot:cell(info)="data">
        <p class="name-field">
          <TableMoreInfoModal modal-title="Loan Info" :table-data="data.item" />
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(loanAmount)="data">
        <p class="name-field">
          <span class=""> ₦{{ data.item.loanAmount | formatAmount }}</span>
        </p>
      </template>
      <!-- A virtual composite column -->
      <template v-slot:cell(loanCreatedAt)="data">
        <p class="name-field">
          <span>
            {{ data.item.loanCreatedAt | formatDateForTable }}
          </span>
        </p>
      </template>

      <template v-slot:cell(loanInterestRate)="data">
        <p class="name-field">
          <span class="">{{ data.item.loanInterestRate }}%</span>
        </p>
      </template>
      <template v-slot:cell(loanStatus)="data">
        <p class="canLiquidate">
          <span
            class="badge badge-pill font-size-12"
            :class="{
              'badge-danger': `${data.item.loanStatus}` === 'APPROVED',
              'badge-success': `${data.item.loanStatus}` !== 'APPROVED'
            }"
            >{{ data.item.loanStatus | firstCaseCapitalSpace }}</span
          >
        </p>
      </template>

      <template v-slot:cell(action)="data">
        <p class="name-field table-loading">
          <span v-if="loan.moduleLoadingFour">
            <PulseLoader :size="'10px'" color="#556ee6" />
          </span>
          <span v-else>
            <b-dropdown
              class="card-drop"
              variant="white"
              right
              toggle-class="p-0"
            >
              <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal font-size-18"></i>
              </template>

              <b-dropdown-item
                @click="changeLoanStatusInitiate('APPROVED', data.item)"
              >
                <i class="bx bx-check-double text-success mr-1"></i> Approve
              </b-dropdown-item>

              <!--              <b-dropdown-item-->
              <!--                @click="changeLoanStatusInitiate('LIQUIDATED', data.item)"-->
              <!--              >-->
              <!--                &lt;!&ndash;              <i class="bx bx-fast-forward-circle text-success mr-1"></i> Liquidate&ndash;&gt;-->
              <!--                <i class="bx bx-water text-success mr-1"></i> Liquidate-->
              <!--              </b-dropdown-item>-->

              <b-dropdown-item
                @click="changeLoanStatusInitiate('REJECTED', data.item)"
              >
                <i class="bx bx-block text-danger mr-1"></i> Reject
              </b-dropdown-item>

              <b-dropdown-item @click="viewLoanSchedule(data.item)">
                <i class="bx bx-list-ul text-danger mr-1"></i> View Loan
                Schedule
              </b-dropdown-item>
              <!--              <b-dropdown-item-->
              <!--                @click="changeLoanStatusInitiate('SETTLED', data.item)"-->
              <!--              >-->
              <!--                <i class="bx bx-reset text-danger mr-1"></i> Settle-->
              <!--              </b-dropdown-item>-->
            </b-dropdown>
          </span>
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.card-title-sub {
}
</style>
