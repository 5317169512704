import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import EodService from "../../services/newServices/EodService";
const eodService = new EodService();

const getDefaultState = () => {
  return {
    test: "",
    allEod: [],
    currentEod: {},
    currentEodItems: [],
  };
};
export const state = getDefaultState();

export const getters = {
  getCurrentEod: state => {
    return state.currentEod;
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  TEST(state, payload) {
    state.test = payload;
  },
  SET_ALL_EOD(state, payload) {
    state.allEod = payload;
  },
  SET_CURRENT_EOD(state, payload) {
    state.currentEod = payload;
  },
  SET_CURRENT_EOD_ITEMS(state, payload) {
    state.currentEodItems = payload;
  }
};
export const actions = {
  setCurrentEodItems({ commit }, payload) {
    commit("SET_CURRENT_EOD_ITEMS", payload);
  },
  setCurrentEod({ commit }, payload) {
    commit("SET_CURRENT_EOD", payload);
  },
  fetchOneEod({ commit }, eodId) {
    commit("TEST", "TEST");
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      eodId
    };

    const successAction = responseData => {
      StoreUtils.dispatch("eod/setCurrentEod", responseData);
      StoreUtils.dispatch("eod/setCurrentEodItems", responseData.data);
    };

    return eodService.fetchOneEod(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  runEod() {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      maker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID)
    };

    const successAction = responseData => {
      StoreUtils.dispatch("eod/fetchAllEod", responseData.data);
    };

    return eodService.runEod(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setAllEod({ commit }, payload) {
    commit("SET_ALL_EOD", payload);
  },
  fetchAllEod() {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.dispatch("eod/setAllEod", responseData.data);
    };

    return eodService.fetchAllEod(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
