<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
<!--      <div class="col-xl-3">-->
<!--        <BranchStatsPanel />-->
<!--      </div>-->
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Create Role</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <CreateRoleForm></CreateRoleForm>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import CreateRoleForm from "../../components/Kleyton/Forms/Role/CreateRoleForm";

export default {
  page: {
    title: "Create Role",
    meta: [{ name: "Create Role", content: window.__env.app.description }]
  },

  components: {
    CreateRoleForm,
    Layout,
    PageHeader
  },
  data() {
    return {
      name: "CreateRole",
      title: "Create Role",
      items: [
        {
          text: "Role",
          href: "/"
        },
        {
          text: "Create Role",
          active: true
        }
      ]
    };
  }
};
</script>

<style scoped></style>
