<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">End Of Month Items</h4>
            <EomInfoCard />
            <!-- Supposed Form -->
            <div class="form-body">
              <EomItemsTable />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>

import Layout from "../../../router/layouts/main";
import PageHeader from "../../../components/AppComponents/page-header";
import EomItemsTable from "../../../components/Kleyton/Tables/Report/EOM/EomItemsTable";
import EomInfoCard from "../../../components/Kleyton/Cards/Report/EOM/EomInfoCard";

export default {
  name: "EomItems",
  page: {
    title: "End Of Month Items",
    meta: [{ name: "End Of Month Items", content: window.__env.app.description }]
  },
  components: {
    EomInfoCard,
    EomItemsTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "End Of Month Items",

      items: [
        {
          text: "Report",
          href: "/"
        },
        {
          text: "End Of Month Items",
          active: true
        }
      ]
    };
  },
  methods: {}
};
</script>

<style scoped></style>
