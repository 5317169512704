import axios from "axios";
import NProgress from "nprogress";
import store from "../../store/store";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";

const apiClient = axios.create({
  baseURL: window.__env.api.baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 10000
});

apiClient.interceptors.request.use(config => {
  const token = store.getters["user/getUserTokenFromLocalStorage"];
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});
apiClient.interceptors.response.use(response => {
  NProgress.done();
  if (response.data.responseCode === "92") {
    RouterUtils.changeRouteTo("login");
  }

  return response;
});

export default {
  checkerAuthorizeCashTransaction(payload) {
    return apiClient.post("/cashTransactionChecker", JSON.stringify(payload));
  },
  allCashTransactionCheckersQueue(payload) {
    return apiClient.post("/cashTransactionChecker", JSON.stringify(payload));
  },
  checkerAuthorizeCashDeposit(payload) {
    return apiClient.post("/cashDepositChecker", JSON.stringify(payload));
  },
  allCashDepositCheckersQueue(payload) {
    return apiClient.post("/cashDepositChecker", JSON.stringify(payload));
  },
  checkerAuthorizeCashWithdrawal(payload) {
    return apiClient.post("/cashWithdrawalChecker", JSON.stringify(payload));
  },
  allCashWithdrawalCheckersQueue(payload) {
    return apiClient.post("/cashWithdrawalChecker", JSON.stringify(payload));
  },
  checkerAuthorizeFundsTransfer(payload) {
    return apiClient.post("/fundTransferChecker", JSON.stringify(payload));
  },
  allFundsTransferCheckersQueue(payload) {
    return apiClient.post("/fundTransferChecker", JSON.stringify(payload));
  },
  balanceEnquiry(payload) {
    return apiClient.post("/balanceEnquiry", JSON.stringify(payload));
  },
  nameEnquiry(payload) {
    return apiClient.post("/nameEnquiryForAll", JSON.stringify(payload));
  },
  transferFunds(payload) {
    return apiClient.post("/fundTransfer", JSON.stringify(payload));
  },
  cashTransactions(payload) {
    return apiClient.post("/cashTransaction", JSON.stringify(payload));
  },
  cashWithdrawal(payload) {
    return apiClient.post("/cashWithdrawal", JSON.stringify(payload));
  },
  cashDeposit(payload) {
    return apiClient.post("/cashDeposit", JSON.stringify(payload));
  }
};
