import CreateQueryView from "../../views/Report/CreateQueryView";
import CreateChargesView from "../../views/Products/CreateChargesView";
import CreateProductsView from "../../views/Products/CreateProductsView";
import CreateTransactionView from "../../views/Products/CreateTransactionView";
import GenerateProductsView from "../../views/Products/GenerateProductsView";
import SelectProductTypeView from "../../views/Products/SelectProductTypeView";
import ViewDynamicProducts from "../../views/Products/ViewDynamicProducts";

export default [
  ///// Query Report
  {
    path: "/query/report",
    name: "query-report-form",
    meta: {
      authRequired: false
    },
    component: CreateQueryView
  },

  {
    path: "/product/transaction-code",
    name: "transaction-code-form",
    meta: {
      authRequired: false
    },
    component: CreateTransactionView
  },

  {
    path: "/product/loan-product",
    name: "loan-product-form",
    meta: {
      authRequired: false
    },
    component: CreateProductsView
  },

  {
    path: "/product/charges-product",
    name: "charges-product-form",
    meta: {
      authRequired: false
    },
    component: CreateChargesView
  },

  {
    path: "/product/generate-product",
    name: "generate-product-form",
    meta: {
      authRequired: false
    },
    component: GenerateProductsView
  },


  //
  {
    path: "/product/select-product-type-view",
    name: "product-select-type--view",
    meta: {
      authRequired: false
    },
    component: SelectProductTypeView
  },
  {
    path: "/product/view-products",
    name: "view-dynamic-products",
    meta: {
      authRequired: false
    },
    component: ViewDynamicProducts
  }

];
