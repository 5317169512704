<template>
  <div>
    <p>{{ queriesMain }}</p>
    <form @submit.prevent="queryReport">
      <div v-for="(item, index) in queries" v-bind:key="index" class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label>Column Name</label>
            <multiselect
                    v-model="queries[index].columnName  "
                    :options="options"
                    placeholder="Column Name"
                    @change="formatCondition()"
                    @close="$v.keywordFormBody.queryType.$touch()"
            ></multiselect>
          </div>

          <template v-if="$v.keywordFormBody.columnName.$error">
            <p
              v-if="!$v.keywordFormBody.columnName.required"
              class="form-error-message"
            >
              Column Name is required
            </p>
          </template>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label>Query Type</label>
            <multiselect
                    v-model="queries[index].queryType  "
                    :options="options2"
                    placeholder="Query Type"
                    @change="formatCondition()"
                    @close="$v.keywordFormBody.queryType.$touch()"
            ></multiselect>
          </div>
          <!--                          error-->
          <template v-if="$v.keywordFormBody.queryType.$error">
            <p
              v-if="!$v.keywordFormBody.queryType.required"
              class="form-error-message"
            >
              Query Type is required
            </p>
          </template>
        </div>
        <div class="col-sm-3">
          <BaseInput
            :inputType="'text'"
            v-model="queries[index].keyword"
            placeholder="keyword"
            :hasPreIcon="false"
            :label="'Keyword'"
            @change="formatCondition()"
            :class="{ error: $v.keywordFormBody.keyword.$error }"
            @blur="$v.keywordFormBody.keyword.$touch()"
          >
          </BaseInput>

          <template v-if="$v.keywordFormBody.keyword.$error">
            <p
              v-if="!$v.keywordFormBody.keyword.required"
              class="form-error-message"
            >
              keyword is required
            </p>
          </template>
        </div>
        <div class="col-sm-3">
          <label for="join">Join</label>
          <select
            id="join"
            :type="'text'"
            v-model="queries[index].join"
            :label="'JOIN'"
            :placeholder="'JOIN'"
            class="form-control"
            @change="addMoreQuery(queries[index].join, index)"
          >
            <option value="NONE">NONE</option>
            <option value="OR">OR</option>
            <option value="AND">AND</option>
          </select>
        </div>

      </div>
      <p class="text-left">
        <span v-if="report.moduleLoadingSix" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Query
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "QueryReportForm2",
  data() {
    return {
      reportName: "",
      keywordFormBody: this.queryFreshFormBodyObject(),
      options: [],
      options2: ["LIKE", "="],
      options3: ["NONE", "AND", "OR"],
      queries: [{ columnName: "", queryType: "", keyword: "", join: "" }],
      queriesMain: ""
    };
  },
  created() {
    // const columnPayload = {
    //   reportBankCode: this.$store.getters["user/getUserBankCode"],
    //   reportName: "PWB_VW_ACCOUNTS",
    //   reportFieldList: "accountName, accountNumber",
    //   reportCondition: ""
    // }
    // this.$store.dispatch("report/readColumns", columnPayload);
  },
  computed: {
    ...mapState(["report"]),
    opt: function() {
      return this.$store.getters["report/columnNames"];
    }
  },
  mounted() {
    this.init();
  },
  validations: {
    keywordFormBody: {
      columnName: {},
      keyword: {},
      queryType: {}
    }
  },
  methods: {
    submitQuery() {
    },
    addMoreQuery(_item, _index) {
      //this.formatCondition()
      let index = _index + 1;

      if (_item !== "NONE") {
        if (this.queries.length <= index) {
          this.queries.push({
            columnName: "",
            queryType: "",
            keyword: "",
            join: ""
          });
        }
      } else {
        this.queries.splice(index, this.queries.length - index);
      }
      this.formatCondition();
    },
    formatCondition() {
      let query = "";
      // eslint-disable-next-line no-unused-vars
      let join = "";

      for (let i = 0; i < this.queries.length; i++) {
        if (this.queries[i].queryType === "LIKE") {
          if (this.queries[i].join === "NONE") join = "";
          else join = this.queries[i].join;
          query =
            query +
            this.queries[i].columnName +
            " " +
            this.queries[i].queryType +
            " '%" +
            this.queries[i].keyword +
            "%' " +
            join +
            " ";
        } else {
          if (this.queries[i].join === "NONE") join = "";
          else join = this.queries[i].join;
          query =
            query +
            this.queries[i].columnName +
            " " +
            this.queries[i].queryType +
            " '" +
            this.queries[i].keyword +
            "' " +
            join +
            " ";
        }
      }
      this.queriesMain = query;
    },
    async init() {
      this.reportName = this.$route.params.reportName;

      const columnPayload = {
        reportBankCode: this.$store.getters["user/getUserBankCode"],
        reportName: this.reportName
      };
      await this.$store.dispatch("report/readColumns", columnPayload);
      this.options = this.$store.getters["report/columnNames"];
    },
    queryReport() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$router
          .push({
            name: "report-single-report",
            params: {
              reportName: this.reportName,
              reportCondition: this.queriesMain,
              source: "query"
            }
          })
          .then(() => {
            this.keywordFormBody = {};
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    queryFreshFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped></style>
