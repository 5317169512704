<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submitForm">
        <div class="stage-info text-left mb-3">
          <div class="row">
            <div class="col-12">
              <h4 class="main card-title mb-4">
                <span v-if="reportType === 'single-source'" class="no">4.</span>
                <span v-else class="no">6.</span>
                Set up Report Columns
              </h4>
              <p class="sub">
                Decide the columns from the <code>Start Table</code> &
                <code>Join Table</code> that will feature in the Report.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div>
              <div
                @click="selectAllColumns"
                :class="{ active: allColumnsSelected }"
                class="assign-default"
              >
                <span></span>
                <label>Select All Columns</label>
              </div>
              <b-form-group v-if="!allColumnsSelected" label="Report Columns">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="formBody.reportFieldList"
                  :options="allReportColumnsOption"
                  name="flavour"
                ></b-form-checkbox-group>
              </b-form-group>
              <div v-else class="form-group">
                <label>Report Columns</label>
                <span class="all-column-selected">All Columns Selected</span>
              </div>
              <template v-if="$v.formBody.reportFieldList.$error">
                <p
                  v-if="!$v.formBody.reportFieldList.required"
                  class="form-error-message"
                >
                  Please select a Column
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button
                  @click="goBackTo(rptStage - 1)"
                  class="btn btn-secondary mr-1"
                >
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Finish
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../../utils/BaseUtils/StoreUtils";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ReportColumnsForm",
  data() {
    return {
      formBody: this.freshFormBodyObject(),
      allColumnsSelected: false
    };
  },
  computed: {
    ...mapState(["rpt", "loader"]),
    reportType() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_REPORT_TYPE);
    },
    allReportColumnsOption() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rptUtilities.GET_ALL_REPORT_TABLES_COLUMNS_OPTION
      );
    },
    rptStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE);
    }
  },
  validations: {
    formBody: {
      reportFieldList: { required }
    }
  },
  methods: {
    selectAllColumns() {
      this.allColumnsSelected = this.allColumnsSelected === false;
    },
    goBackTo(rptStage) {
      StoreUtils.dispatch(StoreUtils.actions.rpt.GO_BACK_TO_STAGE, rptStage);
    },
    submitForm() {
      let payload = {
        reportFieldList: this.allColumnsSelected
          ? ""
          : this.formBody.reportFieldList.join(", ")
      };

      StoreUtils.dispatch(
        StoreUtils.actions.rpt.BUILD_CREATE_REPORT_REQUEST_BODY,
        payload
      );
    },
    freshFormBodyObject() {
      return {
        source: "CBA",
        reportFieldList: []
      };
    }
  }
};
</script>

<style scoped>
.assign-default {
  position: absolute;
  right: 14px;
  font-weight: 600;
  display: flex;
  cursor: pointer;
}
.assign-default span {
  background-color: #74778c;
  display: flex;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.assign-default.active span {
  background-color: #546ee7;
}
.assign-default label {
  font-size: 10px;
  line-height: 14px;
  font-weight: 800 !important;
  margin-top: 4px;
  display: inline-block;
  cursor: pointer;
}
.assign-default .custom-control-label {
}
.default-value {
  margin: 0;
  font-family: inherit;
  overflow: visible;
  font-weight: 500;
  color: #9e9e9e;
  padding: 0.47rem 0.75rem;
  font-size: 14px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.94rem + 2px);
  line-height: 1.5;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
</style>
