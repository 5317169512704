<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
<!--            <h4 class="card-title mb-4">Query Report</h4>-->
            <!-- Supposed Form -->
            <div class="form-body">
              <QueryReportCard />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Create Bank Component
 */

import Layout from "../../../router/layouts/main";
import PageHeader from "../../../components/AppComponents/page-header";
import QueryReportCard from "../../../components/Kleyton/Cards/Report/RPT/QueryReportCard";

export default {
  name: "QueryReport",
  page: {
    title: "Create Bank",
    meta: [{ name: "Create Bank", content: window.__env.app.description }]
  },
  components: {
    QueryReportCard,
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "Query Report",

      items: [
        {
          text: "Report ",
          href: "/"
        },
        {
          text: "Query Report",
          active: true
        }
      ]
    };
  },
  methods: {}
};
</script>
