<template>
  <div>
    <h4 class="card-title mb-4">Create New Transaction Type</h4>

    <form>
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Transaction Code'"
              :hasPreIcon="false"
              :label="'Transaction Code'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Transaction Description'"
              :hasPreIcon="false"
              :label="'Transaction Description'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Expenses'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Income'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Customer'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Expenses'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Asset'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Liability'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseSelect
              :type="'date'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Branches'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseSelect
              :type="'date'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Office'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseSelect
              :type="'date'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Transaction Credit'"
              :options="options"
              :hasPreIcon="false"
            />
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary mr-1">
        Create Transaction Type
      </button>
      <a @click="testSubmit" class="btn btn-secondary">Cancel</a>
    </form>
  </div>
</template>

<script>
export default {
  name: "TransactionCodeForm",
  data() {
    return {
      createBankFormBody: {
        source: "CBA",
        country: "",
        name: ""
      },
      value: null,

      options: ["Yes", "No"]
    };
  },

  methods: {}
};
</script>

<style scoped></style>
