import { render, staticRenderFns } from "./CustomersCheckersQueue.vue?vue&type=template&id=7053fae2&"
import script from "./CustomersCheckersQueue.vue?vue&type=script&lang=js&"
export * from "./CustomersCheckersQueue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports