<template>
  <div class="document-upload-form">
    <FormulateForm @submit="submit">
      <div class="top">
        <label class="doc-input-label">
          <span class="decoy">
            <FormulateInput
              name="documentUrl"
              type="image"
              multiple="false"
              :label="currentDoc.documentTypeName.toLowerCase()"
              validation="required|mime:image/jpeg,image/png,image/gif,application/pdf"
              :validation-name="currentDoc.documentTypeName.toLowerCase()"
              placeholder="Choose document"
              :label‑position="false"
            />
          </span>
          <span class="real">
            <img
              src="https://assets-base.s3.amazonaws.com/img/powerbank/imgg.png"
              alt=""
            />
            <span class="txt">Click to Upload File</span>
            <FormulateInput type="submit" class="upload-btn" label="Submit" />
          </span>
        </label>
      </div>
      <div class="base">
        <div class="base">
          <p class="title">{{ currentDoc.documentTypeName }}</p>
          <p class="sub">{{ currentDoc.documentStatus }}</p>
        </div>

      </div>
    </FormulateForm>
  </div>
</template>

<script>
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import { mapState } from "vuex";

export default {
  name: "UploadCustomerDocumentForm",
  props: {
    currentDoc: {
      type: Object,
      required: true
    },
    currentCustomer: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(["loader"])
  },
  methods: {
    submit(data) {
      StoreUtils.commit("form/BUILD_FORM_BODY", {
        ...data,
        currentDoc: this.currentDoc,
        currentCustomer: this.currentCustomer
      });
      StoreUtils.dispatch("customer/uploadCustomerAccountDocument");
    }
  }
};
</script>

<style scoped></style>
