<template>
  <div>
    <!--        <p>formBody => {{ formBody }}</p>-->
    <!--        <p>currentUser => {{currentUser}}</p>-->
    <div>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.userFirstName"
                placeholder="First Name"
                :hasPreIcon="false"
                :label="'First Name'"
                :class="{
                  error: $v.formBody.userFirstName.$error
                }"
                @blur="$v.formBody.userFirstName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.userFirstName.$error">
                <p
                  v-if="!$v.formBody.userFirstName.required"
                  class="form-error-message"
                >
                  First Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.userMiddleName"
                placeholder="Middle Name"
                :hasPreIcon="false"
                :label="'Middle Name'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.userLastName"
                placeholder="Last Name"
                :hasPreIcon="false"
                :label="'Last Name'"
                :class="{
                  error: $v.formBody.userLastName.$error
                }"
                @blur="$v.formBody.userLastName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.userLastName.$error">
                <p
                  v-if="!$v.formBody.userLastName.required"
                  class="form-error-message"
                >
                  Last Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <label>User Role</label>
              <multiselect
                  @input="obtainUserRoleId"
                  v-model="formBody.roleName"
                  :options="roleOptions"
                  placeholder="Role Name"
              ></multiselect>


            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.addressLineOne"
                placeholder="Address Line One"
                :hasPreIcon="false"
                :label="'Address Line One'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.addressLineTwo"
                placeholder="Address Line Two"
                :hasPreIcon="false"
                :label="'Address Line Two'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.addressStreet"
                placeholder="Street"
                :hasPreIcon="false"
                :label="'Street'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.addressCity"
                placeholder="City"
                :hasPreIcon="false"
                :label="'City'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.addressState"
                placeholder="State"
                :hasPreIcon="false"
                :label="'State'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.addressCountry"
                placeholder="Country"
                :hasPreIcon="false"
                :label="'Country'"
              >
              </BaseInput>
            </div>
          </div>
        </div>
        <p class="text-right pt-2">
          <span v-if="loader.actionButtonLoader" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Submit
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import JsonParserUtils from "@/utils/JsonParserUtils";

export default {
  name: "EditUserForm",
  data() {
    return {
      markov: "",
      formBody: this.createFreshFormBodyObject(),
      genderOptions: ["MALE", "FEMALE"],
      statusOptions: ["ACTIVE", "PENDING", "INACTIVE"]
    };
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({customer: state => state.customer,
                loader:  state => state.loader,
      role: state => state.role,
      mainRoles:state => state.role.allRoles
    }),
    ...mapGetters({ roleOptions: "appUtilities/getRolesUtilityOption",
    proRole: "appUtilities/getAllMainRoles" ,
    amaRole: "appUtilities/getRoleIdFromOptionUtility" })
  },
  validations: {
    formBody: {
      userFirstName: { required },
      userLastName: { required }
    }
  },
  mounted() {
    // this.setFormBody();
  },
  created() {
    this.setFormBody();
    this.$store.dispatch("appUtilities/setUtilityRoles", null, { root: true });

  },
  methods: {

    setFormBody() {
      this.formBody = JsonParserUtils.purifyObject(this.currentUser, "");
    },
    submit() {
        StoreUtils.dispatch("bankUser/editUser", this.formBody);
    },
    createFreshFormBodyObject() {
      return {
        // source: "web"
      };
    },

    obtainUserRoleId(){
      this.formBody.userRoleId = this.formBody.roleName.split("|")[1];
    }
  }
};
</script>

<style scoped></style>
