<template>
  <div class="row">
    <div class="col-12">
      <p
        v-if="journal.currentBatch.batchStatus !== 'CLOSED'"
        class="text-right mt-3"
      >
        <span v-if="loader.tableLoader" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button @click="closeCurrentBatch()" class="btn btn-secondary">
            Close Batch
          </button>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotificationUtils from "../../../../utils/BaseUtils/NotificationUtils";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "CloseBatchCard",
  computed: {
    ...mapState(["journal", "loader"])
  },
  methods: {
    closeCurrentBatch() {
      let notificationModalBody = {
        type: "warning", //error, success, warning
        mode: "yes-no", // default, yes-no
        title: "Confirmation",
        message: "Are you sure you want to Close this Batch?",
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "No",
        yesAction: () => {
          StoreUtils.dispatch(
            StoreUtils.actions.journal.CLOSE_BATCH,
            this.journal.currentBatch
          );
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Yes"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    }
  }
};
</script>

<style scoped></style>
