<template>
  <div>
    <div class="stage-one">
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'number'"
                v-model="formBody.account"
                placeholder="Account Number"
                :hasPreIcon="false"
                :label="'Account Number'"
                :class="{
                  error: $v.formBody.account.$error
                }"
                @blur="$v.formBody.account.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.account.$error">
                <p
                  v-if="!$v.formBody.account.required"
                  class="form-error-message"
                >
                  Account Number is required
                </p>

                <p
                  v-if="!$v.formBody.account.numeric"
                  class="form-error-message"
                >
                  Account Number has to be a Number
                </p>
              </template>
            </div>
          </div>
        </div>
        <p class="text-right">
          <span v-if="loader.actionButtonLoader" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Check Account
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { numeric, required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "AccountEnquiryForm",
  data() {
    return {
      formBody: this.freshFormBodyObject()
    };
  },
  computed: {
    ...mapState(["ft", "loader"])
  },
  validations: {
    formBody: {
      account: { required, numeric }
    }
  },
  created() {
    StoreUtils.dispatch("ft/resetState");
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit("form/BUILD_FORM_BODY", this.formBody);

        StoreUtils.dispatch(StoreUtils.actions.ft.BALANCE_ENQUIRY_INTERNAL, {
          accountNumber: this.formBody.account
        });
      }
    },

    freshFormBodyObject() {
      return {
        source: "WEB"
      };
    }
  }
};
</script>

<style scoped></style>
