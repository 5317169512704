<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->

    <form @submit.prevent="confirmDefaultPassword">
      <div class="row">
        <div class="col-sm-12">
          <div>
            <BaseInput
              :inputType="'password'"
              v-model="changeDefaultPasswordFormBody.userOldPassword"
              placeholder="Old Password"
              :hasPreIcon="false"
              :label="'Old Password'"
              :class="{
                error: $v.changeDefaultPasswordFormBody.userOldPassword.$error
              }"
              @blur="$v.changeDefaultPasswordFormBody.userOldPassword.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.changeDefaultPasswordFormBody.userOldPassword.$error"
            >
              <p
                v-if="
                  !$v.changeDefaultPasswordFormBody.userOldPassword.required
                "
                class="form-error-message"
              >
                Old Password is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'password'"
              v-model="changeDefaultPasswordFormBody.userPassword"
              placeholder="New Password"
              :hasPreIcon="false"
              :label="'New Password'"
              :class="{
                error: $v.changeDefaultPasswordFormBody.userPassword.$error
              }"
              @blur="$v.changeDefaultPasswordFormBody.userPassword.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.changeDefaultPasswordFormBody.userPassword.$error"
            >
              <p
                v-if="!$v.changeDefaultPasswordFormBody.userPassword.required"
                class="form-error-message"
              >
                New Password is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'password'"
              v-model="changeDefaultPasswordFormBody.userPasswordConfirmation"
              placeholder="Confirm New Password"
              :hasPreIcon="false"
              :label="'Confirm New Password'"
              :class="{
                error:
                  $v.changeDefaultPasswordFormBody.userPasswordConfirmation
                    .$error
              }"
              @blur="
                $v.changeDefaultPasswordFormBody.userPasswordConfirmation.$touch()
              "
            >
            </BaseInput>

            <template
              v-if="
                $v.changeDefaultPasswordFormBody.userPasswordConfirmation.$error
              "
            >
              <p
                v-if="
                  !$v.changeDefaultPasswordFormBody.userPasswordConfirmation
                    .required
                "
                class="form-error-message"
              >
                Confirm New Password is required
              </p>

              <p
                v-if="
                  !$v.changeDefaultPasswordFormBody.userPasswordConfirmation
                    .sameAsPassword
                "
                class="form-error-message"
              >
                Confirm Password Mismatch
              </p>
            </template>
          </div>
        </div>
      </div>

      <!--      <p class="text-right">-->
      <span v-if="auth.moduleLoadingTwo" class="formLoaderHold">
        <BaseComponentLoader />
      </span>
      <span v-else>
        <button type="submit" class="btn btn-primary mr-1">
          Confirm
        </button>
      </span>
      <!--      </p>-->
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "Login",
  data() {
    return {
      changeDefaultPasswordFormBody: this.createFreshLoginFormBodyObject()
    };
  },
  computed: {
    ...mapState(["auth"])
  },
  validations: {
    changeDefaultPasswordFormBody: {
      userOldPassword: { required },
      userPassword: { required },
      userPasswordConfirmation: {
        required,
        sameAsPassword: sameAs("userPassword")
      }
    }
  },
  methods: {
    confirmDefaultPassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch(
            "auth/changeDefaultPassword",
            this.changeDefaultPasswordFormBody,
            { root: true }
          )
          .then(() => {
            this.changeDefaultPasswordFormBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshLoginFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
