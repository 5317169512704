import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import InvestmentService from "../../services/newServices/InvestmentService";
import Utils from "../../utils/Utils";

const investmentService = new InvestmentService();

let compareFixedDepositeDateAsc = (a, b) => {
  if (a.accountUpdatedDate < b.accountUpdatedDate) return 1;
  if (a.accountUpdatedDate > b.accountUpdatedDate) return -1;
  return 0;
};


const getDefaultState = () => {
  return {};
};

export const namespaced = true;

export const state = getDefaultState();

export const getters = {};

export const mutations = {};

export const actions = {
  liquidateFixedDeposit() {
    let payload = {
      debitAccount: StoreUtils.rootGetter(
        StoreUtils.getters.table.GET_CURRENT_TABLE_OBJECT
      ).accountNumber,
      creditAccount: StoreUtils.rootGetter(
        StoreUtils.getters.table.GET_CURRENT_TABLE_OBJECT
      ).accountParent,
      maker: StoreUtils.rootGetter("user/getUserId"),
      reference: `${Utils.generateUUIDRef()}-${StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_ID
      )}`,
      amount: StoreUtils.rootGetter("form/getFormBody").amount,
      bankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      ftProductCode: StoreUtils.rootGetter("form/getFormBody").ftProductCode,
      source: "CBA"
    };

    const successAction = () => {};

    return investmentService.liquidateFixedDeposit(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  topUpFixedDeposit() {
    let payload = {
      debitAccount:
        StoreUtils.rootGetter("form/getFormBody").debitAccount.length > 11
          ? StoreUtils.rootGetter(
              "appUtilities/getCustomerAccountNumberFromOptionUtility",
              StoreUtils.rootGetter("form/getFormBody").debitAccount
            )
          : StoreUtils.rootGetter("form/getFormBody").debitAccount,
      creditAccount: StoreUtils.rootGetter(
        StoreUtils.getters.table.GET_CURRENT_TABLE_OBJECT
      ).accountNumber,
      maker: StoreUtils.rootGetter("user/getUserId"),
      reference: `${Utils.generateUUIDRef()}-${StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_ID
      )}`,
      amount: StoreUtils.rootGetter("form/getFormBody").amount,
      bankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      ftProductCode: StoreUtils.rootGetter("form/getFormBody").ftProductCode,
      source: "CBA"
    };

    const successAction = () => {};

    return investmentService.topUpFixedDeposit(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  fetchAllFixedDeposits(undefined, payload) {
    const successAction = responseData => {
      StoreUtils.commit(
        "table/SET_TABLE_DATA",
        responseData.data.sort(compareFixedDepositeDateAsc)
      );
    };

    return investmentService.fetchAllFixedDeposits(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  createFixedDeposit() {
    let payload = {
      creditAccountNumber: StoreUtils.rootGetter("form/getFormBody").account,
      account: StoreUtils.rootGetter("form/getFormBody").account,
      accountClass: StoreUtils.rootGetter(
        "appUtilities/getAccountClassFromOptionUtility",
        StoreUtils.rootGetter("form/getFormBody").accountClass
      ),
      maker: StoreUtils.rootGetter("user/getUserId"),
      reference: `${Utils.generateUUIDRef()}-${StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_ID
      )}`,
      tenure: StoreUtils.rootGetter("form/getFormBody").tenure,
      amount: StoreUtils.rootGetter("form/getFormBody").amount,
      rate: StoreUtils.rootGetter("form/getFormBody").rate,
      penalty: StoreUtils.rootGetter("form/getFormBody").penalty,
      investmentType: "fixed-deposit",
      bankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      ftProductCode: StoreUtils.rootGetter("form/getFormBody").ftProductCode,
      source: "CBA"
    };

    const successAction = () => {
      StoreUtils.dispatch("ft/resetState");
    };

    return investmentService.createFixedDeposit(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  }
};
