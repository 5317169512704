<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submitForm">
        <div class="stage-info text-left mb-3">
          <div class="row">
            <div class="col-12">
              <h4 class="main card-title mb-4">
                <span class="no">4.</span>
                Report Join Contract
              </h4>
              <p class="sub">
                Set up how you want the report to be joined.<br />
                Decide on which columns should be joined and the type of join
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Join Type</label>
                <multiselect
                  v-model="formBody.reportJoinType"
                  :options="reportJoinTypesOption"
                  @close="$v.formBody.reportJoinType.$touch()"
                  placeholder="Start Join Type"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.reportJoinType.$error">
                <p
                  v-if="!$v.formBody.reportJoinType.required"
                  class="form-error-message"
                >
                  Report Join Type is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <p class="form-title"><code>Join Tables On</code></p>
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>{{ reportBaseTableName }} Columns</label>
                <multiselect
                  v-model="formBody.reportBaseJoinColumn"
                  :options="reportBaseColumnsOption"
                  @close="$v.formBody.reportBaseJoinColumn.$touch()"
                  placeholder="Select Join Column"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.reportBaseJoinColumn.$error">
                <p
                  v-if="!$v.formBody.reportBaseJoinColumn.required"
                  class="form-error-message"
                >
                  Please select a column to join on
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>{{ reportCapTableName }} Columns</label>
                <multiselect
                  v-model="formBody.reportCapJoinColumn"
                  :options="reportCapColumnsOption"
                  @close="$v.formBody.reportCapJoinColumn.$touch()"
                  placeholder="Select Join Column"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.reportCapJoinColumn.$error">
                <p
                  v-if="!$v.formBody.reportCapJoinColumn.required"
                  class="form-error-message"
                >
                  Please select a column to join on
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button
                  @click="goBackTo(rptStage - 1)"
                  class="btn btn-secondary mr-1"
                >
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Next
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../../utils/BaseUtils/StoreUtils";
import { required } from "vuelidate/lib/validators";

export default {
  name: "JoinContractForm",
  data() {
    return {
      formBody: this.freshFormBodyObject()
    };
  },
  computed: {
    ...mapState(["rpt", "loader", "rptUtilities"]),
    reportBaseTableName() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rpt.GET_REPORT_BASE_TABLE_NAME
      );
    },
    reportCapTableName() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rpt.GET_REPORT_CAP_TABLE_NAME
      );
    },
    reportJoinTypesOption() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rptUtilities.GET_REPORT_JOIN_TYPES_OPTION
      );
    },
    reportBaseColumnsOption() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rptUtilities.GET_REPORT_BASE_TABLE_COLUMNS_OPTION
      );
    },
    reportCapColumnsOption() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rptUtilities.GET_REPORT_CAP_TABLE_COLUMNS_OPTION
      );
    },
    rptStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE);
    }
  },
  validations: {
    formBody: {
      reportJoinType: { required },
      reportBaseJoinColumn: { required },
      reportCapJoinColumn: { required }
    }
  },
  created() {
    StoreUtils.dispatch(
      StoreUtils.actions.rptUtilities.FETCH_REPORT_BASE_TABLE_COLUMNS
    );
    StoreUtils.dispatch(
      StoreUtils.actions.rptUtilities.FETCH_REPORT_CAP_TABLE_COLUMNS
    );
  },
  methods: {
    goBackTo(rptStage) {
      StoreUtils.dispatch(StoreUtils.actions.rpt.GO_BACK_TO_STAGE, rptStage);
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.dispatch(
          StoreUtils.actions.rpt.BUILD_CREATE_REPORT_REQUEST_BODY,
          this.formBody
        );
      }
    },

    freshFormBodyObject() {
      return {
        source: "WEB"
      };
    }
  }
};
</script>

<style scoped></style>
