const getDefaultState = () => {
  return {
    currentUser: {
      s3Key: {
        accessKey: "",
        secretKey: ""
      }
    },
    currentUserId: "",
    currentUserBankCode: "",
    currentUserBranchCode: "",
    username: "",
    userInputtedBankCode: "",
    userToken: 0,
    userRole: {},
    userPrivileges: {}
  };
};

export const state = getDefaultState();

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },

  // returns the current user.
  getUser(state) {
    return state.currentUser;
  },
  getUserTokenFromLocalStorage(state) {
    return (localStorage.getItem("authToken") + state.userToken)
      .toString()
      .slice(0, -1);
    // return localStorage.getItem("authToken");
  },
  getUserTokenFromState(state) {
    return state.userToken;
  },
  getUserUsername(state) {
    return state.username;
  },
  getUserInputtedBankCode(state) {
    return state.userInputtedBankCode;
  },
  getUserCountryCode() {
    return "234";
  },
  getUserLastLogon(state) {
    return state.currentUser.userLastLogin;
  },
  getUserId(state) {
    if (state.currentUserId === "") {
      return localStorage.getItem("kleyton.user.id");
    } else {
      return state.currentUserId;
    }
  },
  getUserBankCode(state) {
    if (
      state.currentUserBankCode === "" ||
      state.currentUserBankCode === null
    ) {
      return localStorage.getItem("kleyton.user.bankCode");
    } else {
      return state.currentUserBankCode;
    }
  },
  getUserBranchCode(state) {
    if (state.currentUserBranchCode === "") {
      return localStorage.getItem("kleyton.user.branchCode");
    } else {
      return state.currentUserBranchCode;
    }
  },
  getUserTillAccountNumber(state) {
    return state.currentUser.userTillAccountNumber;
  },
  getUserVaultAccountNumber(state) {
    return state.currentUser.userVaultAccountNumber;
  },
  getUserDepartment(state) {
    //   "currentUser storage =>",
    //   localStorage.getItem("kleyton.user.userDepartment")
    // );
    if (
      state.currentUserBankCode === "" ||
      state.currentUserBankCode === null
    ) {
      return localStorage.getItem("kleyton.user.userDepartment");
    } else {
      return state.currentUser.userDepartment;
    }
  },
  getUserBankType(state) {
    return state.currentUser.bankType;
  },
  getS3Key: state => {
    return state.currentUser.s3Key;
  },
  getIdentityPoolId: state => {
    return state.currentUser.IdentityPoolId;
  }
};

export const mutations = {
  SET_USER(state, newValue) {
    state.currentUser = newValue;
  },
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());

    saveToLocalStorage("kleyton.user.id", "");
    saveToLocalStorage("kleyton.user.bankCode", "");
    saveToLocalStorage("kleyton.user.branchCode", "");
    saveToLocalStorage("authToken", "");
  },
  SET_USER_USERNAME(state, payload) {
    state.username = payload;
  },
  SET_USER_INPUTTED_BANK_CODE(state, payload) {
    state.userInputtedBankCode = payload;
  },
  SET_CURRENT_USER(state, payload) {
    state.currentUser = { ...state.currentUser, ...payload };
  },
  SET_CURRENT_USER_ID(state, payload) {
    state.currentUserId = payload;
    saveToLocalStorage("kleyton.user.id", payload);
  },
  SET_CURRENT_USER_BANK_CODE(state, payload) {
    state.currentUserBankCode = payload;
    saveToLocalStorage("kleyton.user.bankCode", payload);
  },
  SET_CURRENT_USER_BRANCH_CODE(state, payload) {
    state.currentUserBranchCode = payload;
    saveToLocalStorage("kleyton.user.branchCode", payload);
  },
  SET_USER_TOKEN(state, payload) {
    state.userToken += 1;
    // state.userToken = payload;
    saveToLocalStorage("authToken", payload);
  },
  REMOVE_USER_TOKEN(state) {
    state.userToken += 1;

    removeFromLocalStorage("authToken");
  },
  SET_USER_ROLE(state, payload) {
    state.userRole = payload;
  },
  SET_USER_PRIVILEGES(state, payload) {
    state.userPrivileges = payload;
  }
};

export const actions = {
  setUsername({ commit }, payload) {
    commit("SET_USER_USERNAME", payload);
  },
  setUserInputtedBankCode({ commit }, payload) {
    commit("SET_USER_INPUTTED_BANK_CODE", payload);
  },
  setCurrentUser({ commit }, payload) {
    commit("SET_CURRENT_USER", payload);
  },
  setUserToken({ commit }, payload) {
    commit("SET_USER_TOKEN", payload);
  },
  setUserIdBankCodeBranchCode({ commit }, payload) {
    commit("SET_CURRENT_USER_ID", payload.userId);
    commit("SET_CURRENT_USER_BANK_CODE", payload.bankCode);
    commit("SET_CURRENT_USER_BRANCH_CODE", payload.branchCode);
  },
  setRole({ commit }, payload) {
    commit("SET_USER_ROLE", payload);
  },
  setPrivileges({ commit }, payload) {
    commit("SET_USER_PRIVILEGES", payload);
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};

// ===
// Private helpers
// ===

function saveToLocalStorage(key, data) {
  localStorage.setItem(key, data);
}
function removeFromLocalStorage(key) {
  localStorage.removeItem(key);
}
