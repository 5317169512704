import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
// import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import * as VueGoogleMaps from "vue2-google-maps";
import VueMask from "v-mask";
import VueRouter from "vue-router";
import ElementUI from "element-ui";
import locale from "element-ui/src/mixins/locale";
import vco from "v-click-outside";
import router from "./router/index";
import Viewer from "v-viewer";

import "@/design/index.scss";
import VueSweetalert2 from "vue-sweetalert2";
import store from "./store/store";

import App from "./App.vue";

import Vodal from "vodal";
// include animation styles
import "vodal/common.css";
import "vodal/rotate.css";

// import AmCharts from "amcharts3";
// import AmSerial from "amcharts3/amcharts/serial";
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

import "./filter/filter.js";
import "./filter/guru-filter.js";

import "./component-register/lucas-component-register";
import "./component-register/guru-component-register";

import { ClientTable, Event } from "vue-tables-2";
import JsonExcel from "vue-json-excel";
import Multiselect from "vue-multiselect";
import "viewerjs/dist/viewer.css";
import CircularCountDownTimer from "vue-circular-count-down-timer";
import IdleVue from "idle-vue";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import VueFormulate from "@braid/vue-formulate";
import S3FileUpload from "@/utils/BaseUtils/S3FileUpload.js";
import Utils from "@/utils/Utils";

Vue.component(Vodal.name, Vodal);
// Vue.use(AmCharts);
// Vue.use(AmSerial);

Vue.use(Datetime);

Vue.use(ClientTable);
Vue.use(Event);

Vue.component("downloadExcel", JsonExcel);
Vue.component("Multiselect", Multiselect);

Vue.use(Viewer);

Vue.use(VueRouter);
Vue.use(vco);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(ElementUI, { locale });
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});
// Vue.component("apexchart", VueApexCharts);

Vue.use(CircularCountDownTimer);

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 840000 // 14 Minutes
});

Vue.use(VueFormulate, {
  library: {
    autocomplete: {
      classification: "text",
      component: "AppAutocompleteInput"
    }
  },
  uploader: async function(file, progress, error) {
    return await S3FileUpload.uploadFileToS3BucketAsync(
      file,
      S3FileUpload.params.albums.STAGING,
      progress,
      error,
      `${Utils.generateNineRandomNumbers()}`,
      S3FileUpload.params.albums.ACCOUNT_DOCUMENTS
    );
  }
});

new Vue({
  router,
  store,
  onIdle() {
    this.$store.dispatch("auth/handleUserInactivity");
  },
  onActive() {
    StoreUtils.commit("auth/SET_APP_INACTIVITY", false);
  },
  render: h => h(App)
}).$mount("#app");
