<template>
  <div v-if="product.moduleLoadingFour">
    <BaseComponentLoader />
  </div>
  <div v-else>
    <ActualGenerateProductForm v-if="schema != null" :theSchema="schema" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ActualGenerateProductForm from "../Product/ActualGenerateProductForm";

export default {
  name: "GenerateProductForm",
  data() {
    return {
      model: {}
    };
  },
  computed: {
    ...mapState(["product"]),
    ...mapGetters({
      schema: "product/formObj"
    })
  },
  components: {
    ActualGenerateProductForm
  },

  created() {
    this.$store.dispatch("product/getAllFields", null, { root: true });
    // this.schema = this.$store.getters[""];
    //this.$store.getters["product/formObj"]
  }
};
</script>

<style scoped>
@import "https://unpkg.com/element-ui@2.13.0/lib/theme-chalk/index.css";

.form {
  text-align: left;
  width: 600px;
  margin: auto;
}

.el-input__inner {
}

h2 {
  font-size: 1.7em;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.2em;
}

h2 + small {
  display: block;
  text-align: center;
  margin-bottom: 1.2em;
}

small {
  line-height: 20px;
  display: block;
}

.el-alert {
  margin-bottom: 15px;
}
</style>
