import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import AccountingEntryService from "../../services/newServices/AccountingEntryService";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";

const accountingEntryService = new AccountingEntryService();

const getDefaultState = () => {
  return {
    accountingEntries: [],
    currentAccountingEntry: {},
    currentAccountingEntryEntries: [],
    currentAccountingEntrySlabs: [],
    currentAccountingEntrySlabEntries: [],
    nextId: 1,
    test: "",
    currentSlab: {
      slabCode: "SlabOne",
      slabMaxAmount: "99999999999999999",
      editable: false
    }
  };
};

export const namespaced = true;

export const state = getDefaultState();

export const getters = {
  getCurrentAccountingEntrySlabEntries: state => {
    return state.currentAccountingEntrySlabEntries;
  },
  getCurrentAccountingEntrySlabs: state => {
    return state.currentAccountingEntrySlabs;
  },
  getCurrentAccountingEntrySlab: state => {
    return state.currentSlab;
  },
  getCurrentAccountingEntry: state => {
    return state.currentAccountingEntry;
  },
  getCurrentAccountingEntryEntries: state => {
    return state.currentAccountingEntryEntries;
  },
  getCurrentAccountingEntryCode: state => {
    return state.currentAccountingEntry.aeCode;
  },
  getEntriesFlatTotal: state => {
    const allEntries = state.currentAccountingEntryEntries;
    if (allEntries.length > 0) {
      return allEntries
        .filter(entry => entry.entryAmountType === "FLAT")
        .reduce((acc, currValue) => {
          let amtString = currValue.entryAmount.replace("$AMOUNT", "1").replace("_", "*")
          let amt = eval(amtString);
          if (currValue.entryDrCr === "DR")
            return parseFloat(acc) - parseFloat(amt);
          if (currValue.entryDrCr === "CR")
            return parseFloat(acc) + parseFloat(amt);
        }, 0);
    } else {
      return 0;
    }
  },
  getEntriesPercentageTotal: state => {
    const allEntries = state.currentAccountingEntryEntries;
    if (allEntries.length > 0) {
      return allEntries
        .filter(entry => entry.entryAmountType === "PERCENT")
        .reduce((acc, currValue) => {
          if (currValue.entryDrCr === "DR") {
            if (currValue.entryAmount === "$AMOUNT") {
              return parseFloat(acc);
            } else {
              return parseFloat(acc) - parseFloat(currValue.entryAmount);
            }
          }
          if (currValue.entryDrCr === "CR") {
            if (currValue.entryAmount === "$AMOUNT") {
              return parseFloat(acc);
            } else {
              return parseFloat(acc) + parseFloat(currValue.entryAmount);
            }
          }
        }, 0);
    } else {
      return 0;
    }
  },
  getEntriesTotal: state => {
    const allEntries = state.currentAccountingEntryEntries;
    if (allEntries.length > 0) {
      return allEntries.reduce((acc, currValue) => {
        if (currValue.entryDrCr === "DR") {
          if (currValue.entryAmount === "$AMOUNT") {
            return parseFloat(acc);
          } else {
            return parseFloat(acc) - parseFloat(currValue.entryAmount);
          }
        }
        if (currValue.entryDrCr === "CR") {
          if (currValue.entryAmount === "$AMOUNT") {
            return parseFloat(acc);
          } else {
            return parseFloat(acc) + parseFloat(currValue.entryAmount);
          }
        }
      }, 0);
    } else {
      return 0;
    }
  },

  getBulkEntriesTotal: state => {
    const allEntries = state.currentAccountingEntryEntries;
    if (allEntries.length > 0) {
      return allEntries.reduce((acc, currValue) => {
        if (currValue.drCr === "DR") {
          return Number(acc) - Number(currValue.amount);
        }
        if (currValue.drCr === "CR") {
          return Number(acc) + Number(currValue.amount);
        }
      }, 0);
    } else {
      return 0;
    }
  }
};

export const mutations = {
  REMOVE_ENTRY_FROM_CURRENT(state, payload) {
    state.currentAccountingEntryEntries = state.currentAccountingEntryEntries.filter(
      entry => entry.id !== payload.id
    );
  },
  ADD_ENTRY_TO_CURRENT(state, payload) {
    payload.id = state.nextId;
    state.nextId++;
    state.currentAccountingEntryEntries.push(payload);
  },

  SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES(state, payload) {
    state.currentAccountingEntryEntries = payload.map(entry => {
      entry.id = state.nextId;
      state.nextId++;
      return entry;
    });
  },
  SET_CURRENT_ACCOUNTING_ENTRY(state, payload) {
    state.currentAccountingEntry = payload;
  },
  SET_ACCOUNTING_ENTRIES(state, payload) {
    state.accountingEntries = payload;
  },
  TEST(state, payload) {
    state.test = payload;
  },
  RESET_NEXT_ID(state) {
    state.nextId = 1;
  },
  REMOVE_ACCOUNTING_ENTRY(state, aeCode) {
    state.accountingEntries = state.accountingEntries.filter(
      accountingEntry => accountingEntry.aeCode !== aeCode
    );
  },
  SET_CURRENT_ACCOUNTING_ENTRY_SLAB(state, payload) {
    state.currentSlab = payload;
  },
  SET_CURRENT_ACCOUNTING_ENTRY_SLAB_CODE(state, payload) {
    state.currentSlab.slabCode = payload;
  },
  SET_CURRENT_ACCOUNTING_ENTRY_SLAB_MAX_AMOUNT(state, payload) {
    state.currentSlab.slabMaxAmount = payload;
  },
  SET_CURRENT_ACCOUNTING_ENTRY_SLABS(state, payload) {
    state.currentAccountingEntrySlabs = payload;
  },
  SET_CURRENT_ACCOUNTING_ENTRY_SLAB_ENTRIES(state, payload) {
    state.currentAccountingEntrySlabEntries = payload;
  }
};

export const actions = {
  resetNextId({ commit }) {
    commit("RESET_NEXT_ID");
  },
  removeEntryFromCurrent({ commit }, payload) {
    commit("REMOVE_ENTRY_FROM_CURRENT", payload);
  },
  addEntryToCurrent({ commit }, payload) {
    commit("ADD_ENTRY_TO_CURRENT", payload);
  },
  setCurrentAccountingEntryEntries({ commit }, payload) {
    commit("SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES", payload);
  },

  fetchAccountingEntrySlabs(undefined, { accountingEntryCode }) {
    let payload = {
      slabAeCode: accountingEntryCode,
      slabBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };

    const successAction = responseData => {
      StoreUtils.commit(
        "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_SLABS",
        responseData.data
      );
    };
    return accountingEntryService.fetchSlabs(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchCurrentAccountingEntrySlabEntries(
    undefined,
    { accountingEntryCode, slabCode }
  ) {
    let payload = {
      slabBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      slabAeCode: accountingEntryCode,
      slabCode: slabCode
    };

    const successAction = responseData => {
      StoreUtils.commit(
        "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES",
        responseData.entries
      );
    };
    return accountingEntryService.fetchSlabEntries(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchCurrentAccountingEntryEntries({ commit }, accountingEntryCode) {
    commit("TEST", "test");
    let payload = {
      aeBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      aeCode: accountingEntryCode
    };

    const successAction = responseData => {
      StoreUtils.dispatch(
        StoreUtils.actions.accountingEntry.SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES,
        responseData.entries
      );
    };
    return accountingEntryService.fetchOne(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  assignEntries({ getters }, { entries, slab }) {
    let payload = {
      entryAeCode: getters.getCurrentAccountingEntryCode,
      entryBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      entryMaker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
      slabCode: slab.slabCode,
      slabMaxAmount: slab.slabMaxAmount,
      entries: entries
    };

    const successAction = () => {
      StoreUtils.dispatch(StoreUtils.actions.accountingEntry.RESET_NEXT_ID);
      StoreUtils.dispatch(
        StoreUtils.actions.accountingEntry.SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES,
        []
      );
      RouterUtils.changeRouteTo(
        RouterUtils.routes.products.SELECT_PRODUCT_TO_CREATE
      );
    };

    return accountingEntryService.assignEntries(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  create({ commit }, payload) {
    commit("TEST", "test");

    payload.aeBankCode = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_BANK_CODE
    );
    payload.aeMaker = StoreUtils.rootGetter(
      StoreUtils.getters.user.GET_USER_ID
    );
    StoreUtils.dispatch(
      StoreUtils.actions.accountingEntry.SET_CURRENT_ACCOUNTING_ENTRY,
      payload
    );

    const successAction = () => {
      RouterUtils.changeRouteTo(
        RouterUtils.routes.accountingEntry.ASSIGN_ENTRIES
      );
      RouterUtils.changePageTitleTo(
        RouterUtils.pageTitles.accountingEntry.CREATE
      );
      StoreUtils.commit("accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_SLAB", {
        slabCode: "SlabOne",
        slabMaxAmount: "99999999999999999",
        editable: false
      });
    };

    return accountingEntryService.create(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  setCurrentAccountingEntry({ commit }, payload) {
    commit("SET_CURRENT_ACCOUNTING_ENTRY", payload);
  },

  setAccountingEntries({ commit }, payload) {
    commit("SET_ACCOUNTING_ENTRIES", payload);
  },
  fetchAccountingEntries() {
    const payload = {
      aeBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };
    const successAction = responseData => {
      StoreUtils.dispatch(
        StoreUtils.actions.accountingEntry.SET_ACCOUNTING_ENTRIES,
        responseData.data
      );
    };
    return accountingEntryService.fetchAll(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  removeAccountingEntry({ commit }, aeCode) {
    commit("REMOVE_ACCOUNTING_ENTRY", aeCode);
  },
  delete(aeCode) {
    const payload = {
      aeBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      aeCode: aeCode
    };

    const successAction = () => {};
    return accountingEntryService.delete(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  }
};
