var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.logIn.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',[_c('BaseInput',{class:{
              error: _vm.$v.loginFormBody.userBankCode.$error
            },attrs:{"inputType":'text',"placeholder":"Bank Code","hasPreIcon":false,"label":'Bank Code'},on:{"blur":function($event){return _vm.$v.loginFormBody.userBankCode.$touch()}},model:{value:(_vm.loginFormBody.userBankCode),callback:function ($$v) {_vm.$set(_vm.loginFormBody, "userBankCode", $$v)},expression:"loginFormBody.userBankCode"}}),(_vm.$v.loginFormBody.userBankCode.$error)?[(!_vm.$v.loginFormBody.userBankCode.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Bank Code is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
              error: _vm.$v.loginFormBody.username.$error
            },attrs:{"inputType":'text',"placeholder":"username","hasPreIcon":false,"label":'username'},on:{"blur":function($event){return _vm.$v.loginFormBody.username.$touch()}},model:{value:(_vm.loginFormBody.username),callback:function ($$v) {_vm.$set(_vm.loginFormBody, "username", $$v)},expression:"loginFormBody.username"}}),(_vm.$v.loginFormBody.username.$error)?[(!_vm.$v.loginFormBody.username.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" username is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
              error: _vm.$v.loginFormBody.userPassword.$error
            },attrs:{"inputType":'password',"placeholder":"Password","hasPreIcon":false,"label":'Password'},on:{"blur":function($event){return _vm.$v.loginFormBody.userPassword.$touch()}},model:{value:(_vm.loginFormBody.userPassword),callback:function ($$v) {_vm.$set(_vm.loginFormBody, "userPassword", $$v)},expression:"loginFormBody.userPassword"}}),(_vm.$v.loginFormBody.userPassword.$error)?[(!_vm.$v.loginFormBody.userPassword.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Password is required ")]):_vm._e()]:_vm._e()],2)])]),_c('p',{staticClass:"text-right"},[(_vm.auth.moduleLoading)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Login ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }