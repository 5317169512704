<template>
  <div>
    <div class="view-more-top">
      <div class="row">
        <div class="col-sm-6">
          <p class="queue-title">Account Number {{show}}</p>
          <p class="queue-value">
            {{ editFormBody.accountNumber }}
          </p>
        </div>

        <div class="col-sm-6">
          <p class="queue-title">Account Name</p>
          <p class="queue-value">
            {{ editFormBody.accountName }}
          </p>
        </div>
      </div>
    </div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div v-if="appUtilities.moduleLoadingSix">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Customer ID</label>
                <b-input type="text" v-model="formBody.accountCustomerId" />
              </div>

              <template v-if="$v.formBody.accountCustomerId.$error">
                <p
                  v-if="!$v.formBody.accountCustomerId.required"
                  class="form-error-message"
                >
                  Customer ID is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Account Name</label>
                <b-input type="text" v-model="formBody.accountName" />
              </div>

              <template v-if="$v.formBody.accountName.$error">
                <p
                  v-if="!$v.formBody.accountName.required"
                  class="form-error-message"
                >
                  Account Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Account Status</label>
                <multiselect
                  v-model="formBody.accountStatus"
                  :options="statusOptions"
                  placeholder="Account Status"
                  @close="$v.formBody.accountStatus.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.formBody.accountStatus.$error">
                <p
                  v-if="!$v.formBody.accountStatus.required"
                  class="form-error-message"
                >
                  Account Status is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Account Class</label>
                <multiselect
                  v-model="formBody.accountClass"
                  :options="accountClassOptions"
                  placeholder="Account Class"
                  @close="$v.formBody.accountClass.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.formBody.accountClass.$error">
                <p
                  v-if="!$v.formBody.accountClass.required"
                  class="form-error-message"
                >
                  Account Class is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Allow Credit</label>
                <multiselect
                  v-model="formBody.accountAllowCredit"
                  :options="yesNoOptions"
                  placeholder="Allow Credit"
                  @close="$v.formBody.accountAllowCredit.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.formBody.accountAllowCredit.$error">
                <p
                  v-if="!$v.formBody.accountAllowCredit.required"
                  class="form-error-message"
                >
                  Allow Credit is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Allow Debit</label>
                <multiselect
                  v-model="formBody.accountAllowDebit"
                  :options="yesNoOptions"
                  placeholder="Allow Debit"
                  @close="$v.formBody.accountAllowDebit.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.formBody.accountAllowDebit.$error">
                <p
                  v-if="!$v.formBody.accountAllowDebit.required"
                  class="form-error-message"
                >
                  Allow Debit is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <p class="text-right">
          <span v-if="loader.actionButtonLoader" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Update
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "EditCustomerAccountForm",
  props: {
    editFormBody: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      statusOptions: ["ACTIVE", "CLOSED"],
      yesNoOptions: ["Y", "N"],
      formBody: {
        accountStatus: "",
        accountCustomerId: "",
        accountName: "",
        accountClass: "",
        accountAllowDebit: "",
        accountAllowCredit: ""
      }
    };
  },
  computed: {
    ...mapState(["loader", "appUtilities"]),
    ...mapGetters({
      accountClassOptionsFromCode:
        "appUtilities/getAccountClassOptionFromCodeUtility",
      getAccountClassFromOptionUtility:
        "appUtilities/getAccountClassFromOptionUtility"
    }),
    accountClassOptions() {
      return StoreUtils.rootGetter(
        "appUtilities/getAccountClass2UtilityOption"
      ).filter(accountClass => accountClass.Type !== "FIXED");
    }
  },
  validations: {
    formBody: {
      accountStatus: { required },
      accountCustomerId: { required },
      accountClass: { required },
      accountName: { required },
      accountAllowDebit: { required },
      accountAllowCredit: { required }
    }
  },
  // async created() {
  //
  // },
  mounted() {},
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.editFormBody.accountStatus = this.formBody.accountStatus;
        this.editFormBody.accountName = this.formBody.accountName;
        this.editFormBody.accountCustomerId = this.formBody.accountCustomerId;
        this.editFormBody.accountAllowCredit = this.formBody.accountAllowCredit;
        this.editFormBody.accountAllowDebit = this.formBody.accountAllowDebit;
        this.editFormBody.accountClass = this.getAccountClassFromOptionUtility(
          this.formBody.accountClass
        );
        this.$store
          .dispatch("customer/editCustomerAccount", this.editFormBody, {
            root: true
          })
          .then(responseData => {
            if (responseData.responseCode === "00") {
              this.$emit("editSuccessful", responseData);
            }
            // this.formBody = {
            //   accountStatus: "",
            //   accountClass: "",
            //   accountCustomerId: "",
            //   accountName: ""
            // };
            //this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {
        accountStatus: "",
        accountClass: ""
      };
    }
  },
  watch: {
    async show() {
      if (this.show){
        await this.$store.dispatch("appUtilities/fetchAllAccountClass2", null, {
          root: true
        });
        this.formBody.accountStatus = await this.editFormBody.accountStatus;
        this.formBody.accountCustomerId = await this.editFormBody.accountCustomerId;
        this.formBody.accountName = await this.editFormBody.accountName;
        this.formBody.accountAllowCredit = await this.editFormBody.accountAllowCredit;
        this.formBody.accountAllowDebit = await this.editFormBody.accountAllowDebit;
        this.formBody.accountClass = await this.accountClassOptionsFromCode(
            this.editFormBody.accountClass
        );
      }
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
