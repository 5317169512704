/* eslint-disable prettier/prettier */
// create a new Bank
import CustomerService from "../../services/oldServices/CustomerService";
import NewCustomerService from "../../services/newServices/CustomerService";
const newCustomerService = new NewCustomerService();
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import LienResponse from "../../model/response/LienResponse";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import RouterUtils from "@/utils/BaseUtils/RouterUtils";
import DateFormaterUtils from "@/utils/DateFormaterUtils";
import S3FileUpload from "@/utils/BaseUtils/S3FileUpload";
import moment from "moment";
import UpgradeLogsResponse from "../../model/response/UpgradeLogsResponse";

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    // moduleLoadingTwo: true,
    moduleLoadingThree: true,
    upgradeLogsLoader: false,
    moduleLoadingFour: false,
    moduleLoadingFive: true,
    moduleLoadingSix: true,
    moduleLoadingSeven: false,
    moduleLoadingEight: true,
    moduleLoadingNine: false,
    moduleLoadingTen: true,
    createCustomerFormBody: {
      // source: "web"
    },
    customers: [],
    customerAccounts: [],
    accounts: [],
    allLogs: UpgradeLogsResponse.logsReadResponse.data,
    upgradeLogsReadByCustomerId: UpgradeLogsResponse.logsUpgradeLogsReadByCustomerIdResponse.data,
    currentCustomerMoreInfo: {},
    allCreateCustomersCheckersQueue: [],
    allCustomerSubAccountsCheckersQueue: [],
    allCustomersSearchResults: [],
    allCustomerAccountsSearchResults: [],
    currentCustomerAccountDocuments: [],
    lienLoading: false,
    allLien: LienResponse.lienListResponse.data,
    editCustomerFormBody: null,
    upgradeLogsUpdate: ""
  };
};
export const state = getDefaultState();

export const getters = {
  getCurrentCustomerAccountDocuments: (state) => {
    return state.currentCustomerAccountDocuments;
  },
  getEditCustomerFormBody: (state) => {
    return state.editCustomerFormBody;
  },
  getUpdateAllLogs: (state) => {
    return state.allLogs
  },
  getUpgradeLogsReadByCustomerId: (state) => {
    return state.upgradeLogsReadByCustomerId
  },
  getUpgradeLogsUpdate: (state) => {
    return state.upgradeLogsUpdate
  },
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  // SET_LOADING_TWO(state, status) {
  //     state.moduleLoadingTwo = status;
  // },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_LOADING_SIX(state, status) {
    state.moduleLoadingSix = status;
  },
  SetLogsModal(state, status) {
    state.upgradeLogsLoader = status;
  },
  SET_LOADING_SEVEN(state, status) {
    state.moduleLoadingSeven = status;
  },
  SET_LOADING_EIGHT(state, status) {
    state.moduleLoadingEight = status;
  },
  SET_LOADING_NINE(state, status) {
    state.moduleLoadingNine = status;
  },
  SET_LOADING_TEN(state, status) {
    state.moduleLoadingTen = status;
  },
  // SET_BANK_STATS(state, payload) {
  //     state.bankStatData = payload;
  // },
  SET_ALL_CUSTOMERS(state, payload) {
    state.customers = payload;
  },
  SET_ALL_CUSTOMERS_ACCOUNTS(state, payload) {
    state.customerAccounts = payload;
  },
  SET_ALL_ACCOUNTS(state, payload) {
    state.accounts = payload;
  },
  SET_CURRENT_CUSTOMER_MORE_INFO(state, payload) {
    state.currentCustomerMoreInfo = payload;
  },
  SET_ALL_CREATE_CUSTOMERS_CHECKERS_QUEUE(state, payload) {
    state.allCreateCustomersCheckersQueue = payload;
  },
  SET_ALL_CUSTOMER_SUB_ACCOUNTS_CHECKERS_QUEUE(state, payload) {
    state.allCustomerSubAccountsCheckersQueue = payload;
  },
  SET_ALL_CUSTOMERS_SEARCH_RESULT(state, payload) {
    state.allCustomersSearchResults = payload;
  },
  SET_ALL_CUSTOMER_ACCOUNTS_SEARCH_RESULT(state, payload) {
    state.allCustomerAccountsSearchResults = payload;
  },
  SET_CURRENT_CUSTOMER_ACCOUNT_DOCUMENTS(state, payload) {
    state.currentCustomerAccountDocuments = payload;
  },
  updateAllLien: (state, payload) => {
    state.allLien = payload;
  },
  updateAllLogs: (state, payload) => {
    state.allLogs = payload;
  },
  updateUpgradeLogsReadByCustomerId: (state, payload) => {
    state.upgradeLogsReadByCustomerId = payload;
  },
  updateUpgradeLogsUpdate: (state, payload) => {
    state.upgradeLogsUpdate = payload;
  },
  updateEditCustomerFormBody(state, payload){
    state.editCustomerFormBody = payload
  },
};

export const actions = {
  closeCustomerAccount(undefined, { accountNumber }) {
    let payload = {
      accountNumber,
      accountBankCode: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      status: "CLOSED",
      accountMaker: StoreUtils.rootGetters(StoreUtils.getters.user.GET_USER_ID),
    };

    const successAction = () => {};

    return newCustomerService.closeCustomerAccount(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON
    );
  },

  liquidateAccountAccruals(undefined, { accountNumber }) {
    let payload = {
      accountNumber,
      accountBankCode: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      accountMaker: StoreUtils.rootGetters(StoreUtils.getters.user.GET_USER_ID),
    };

    const successAction = () => {
      StoreUtils.dispatch("customer/fetchAccountAccruals", { accountNumber });
    };

    return newCustomerService.liquidateAccountAccruals(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON
    );
  },

  fetchAccountAccruals(undefined, { accountNumber }) {
    let payload = {
      accountNumber,
      accountBankCode: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
    };

    const successAction = (responseData) => {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_ALT_TABLE_DATA,
        responseData.accruals
      );
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_CURRENT_TABLE_OBJECT,
        responseData
      );
    };

    return newCustomerService.fetchAccountAccruals(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT
    );
  },

  deleteCustomerAccountDocument(
    undefined,
    { documentType, documentUrl, documentOwner, documentGroup }
  ) {
    let payload = {
      documentType,
      documentUrl,
      documentOwner,
      documentMaker: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_ID
      ),
      documentBankCode: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
    };

    const successAction = () => {
      StoreUtils.dispatch("customer/fetchCustomerAccountDocuments", {
        documentOwner,
        documentGroup,
      });
    };

    return newCustomerService.deleteCustomerAccountDocument(
      payload,
      successAction,
      LoaderUtils.types.UPLOAD
    );
  },
  approveCustomerAccountDocument(
    undefined,
    { documentType, documentUrl, documentOwner, documentGroup }
  ) {
    let payload = {
      documentType,
      documentUrl,
      documentOwner,
      documentMaker: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_ID
      ),
      documentBankCode: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
    };

    const successAction = () => {
      StoreUtils.dispatch("customer/fetchCustomerAccountDocuments", {
        documentOwner,
        documentGroup,
      });
    };

    return newCustomerService.approveCustomerAccountDocument(
      payload,
      successAction,
      LoaderUtils.types.UPLOAD
    );
  },
  uploadCustomerAccountDocument() {
    const formBody = StoreUtils.rootGetters("form/getFormBody");
    const document = formBody.documentUrl[0][0];

    let payload = {
      documentType: formBody.currentDoc.documentType,
      documentUrl: document.finalUrl,
      documentGroup: formBody.currentCustomer.customerType,
      documentFormat: "IMAGE",
      documentOwner: formBody.currentCustomer.customerId,
      documentMaker: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_ID
      ),
      documentBankCode: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
    };

    const successAction = () => {
      S3FileUpload.moveFileInS3Bucket(
        document.Key,
        document.finalName,
        document.finalAlbum
      );
      StoreUtils.dispatch("customer/fetchCustomerAccountDocuments", {
        documentOwner: formBody.currentCustomer.customerId,
        documentGroup: formBody.currentCustomer.customerType,
      });
    };

    return newCustomerService.uploadCustomerAccountDocument(
      payload,
      successAction,
      LoaderUtils.types.UPLOAD
    );
  },
  fetchCustomerAccountDocuments(undefined, { documentOwner, documentGroup }) {
    let payload = {
      documentOwner,
      documentGroup,
      documentBankCode: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
    };

    const successAction = (responseData) => {
      StoreUtils.commit(
        "customer/SET_CURRENT_CUSTOMER_ACCOUNT_DOCUMENTS",
        responseData.data
      );
    };

    return newCustomerService.fetchCustomerAccountDocuments(
      payload,
      successAction,
      LoaderUtils.types.UPLOAD
    );
  },

  createCustomerAccount() {
    let formBody = StoreUtils.rootGetter(StoreUtils.getters.form.GET_FORM_BODY);

    const customerPicture = formBody.customerPicture
      ? formBody.customerPicture[0][0]
      : { finalUrl: "" };
    const customerMandate = formBody.customerMandate
      ? formBody.customerMandate[0][0]
      : { finalUrl: "" };

    let payload = {
      customerAccountOfficer: StoreUtils.rootGetters(
        "appUtilities/getUserIdFromOptionUtility",
        formBody.customerAccountOfficer
      ),
      customerPicture: customerPicture.finalUrl,
      customerMandate: customerMandate.finalUrl,
      customerPhoneNumber: formBody.customerPhoneNumber,
      customerBvn: formBody.customerBvn,
      customerAge: DateFormaterUtils.calculateDifferenceInYears(
        formBody.customerDob
      ),
      customerEmail: formBody.customerEmail,
      customerBusinessName: formBody.customerBusinessName,
      customerFirstName: formBody.customerFirstName,
      customerMiddleName: formBody.customerMiddleName,
      customerLastName: formBody.customerLastName,
      customerDob: moment(formBody.customerDob).format("DD-MMM-YYYY"),
      customerCountryCode: "234",
      customerAddress: formBody.customerAddress,
      customerCity: formBody.customerCity,
      customerState: formBody.customerState,
      customerCountry: formBody.customerCountry,
      accountType: formBody.accountType,
      customerType:
        formBody.customerType === "PERSONAL"
          ? "CUSTOMER"
          : formBody.customerType,
      accountClass: StoreUtils.rootGetters(
        "appUtilities/getAccountClassFromOptionUtility",
        formBody.accountClass
      ),
      accountCurrency: formBody.accountCurrency,
      customerBranchCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BRANCH_CODE
      ),
      customerBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      customerIncorporationNumber: formBody.customerIncorporationNumber,
      customerIncorporationDate: moment(
        formBody.customerIncorporationDate
      ).format("DD-MMM-YYYY"),
      customerCompanyType: formBody.customerCompanyType,
      customerMaker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
      customerOtherPhoneNumber: formBody.customerOtherPhoneNumber,
      customerNextOfKinName: formBody.customerNextOfKinName,
      customerNextOfKinPhoneNumber: formBody.customerNextOfKinPhoneNumber,
      customerNextOfKinAddress: formBody.customerNextOfKinAddress,
    };

    const successAction = () => {
      if (formBody.customerType === "PERSONAL") {
        S3FileUpload.moveFileInS3Bucket(
          customerPicture.Key,
          customerPicture.finalName,
          customerPicture.finalAlbum
        );
        S3FileUpload.moveFileInS3Bucket(
          customerMandate.Key,
          customerMandate.finalName,
          customerMandate.finalAlbum
        );
      }
      StoreUtils.commit(StoreUtils.mutations.form.RESET_FORM);

      RouterUtils.changeRouteTo(
        RouterUtils.routes.queue.GENERAL_CHECKERS_QUEUE
      );
    };

    return newCustomerService.createCustomerAccount(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON
    );
  },

  bulkCreateCustomer(undefined, { customers, createAccount }) {
    let payload = {
      bankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      createCustomer: createAccount,
      maker: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
      requestType: "bulkUpload",
      data: customers,
    };

    const successAction = () => {
      RouterUtils.changeRouteTo(
        RouterUtils.routes.customer.CreateCustomerUpload
      );
    };

    return newCustomerService.bulkCreateCustomer(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },


  editCustomer(undefined, payload) {
    let request = {
      customerBvn: payload.customerBvn,
      customerPhoneNumber: payload.customerPhoneNumber,
      customerCountryCode: "234",
      customerMaker: payload.customerMaker,
      customerFirstName: payload.customerFirstName,
      customerMiddleName: payload.customerMiddleName,
      customerLastName: payload.customerLastName,
      customerAddress: payload.customerAddress,
      customerDob: payload.customerDob,
      customerEmail: payload.customerEmail,
      customerState: payload.customerState,
      customerCountry: payload.customerCountry,
      customerGender: payload.customerGender,
      customerId: payload.customerId,
      customerPicture: payload.customerPicture,
      customerMandate: payload.customerMandate,
      customerBankCode: payload.customerBankCode,
      customerAllowSms: payload.customerAllowSms,
      customerAllowEmail: payload.customerAllowEmail,
      customerNin: payload.customerNin,
      customerAllowPushNotification: payload.customerAllowPushNotification,
      customerAccountOfficer: StoreUtils.rootGetter(
          "appUtilities/getUserIdFromOptionUtility",
          payload.customerAccountOfficer
      ),
      auditUser: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID)
    };

    const successAction = () => {
      StoreUtils.dispatch("customer/allCustomers", {
        customerBankCode: StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_BANK_CODE
        )
      });
      StoreUtils.commit(StoreUtils.mutations.form.RESET_FORM);
    };

    return newCustomerService.editCustomerInfo(
        request,
        successAction,
        LoaderUtils.types.ACTION_BUTTON,
        ErrorUtils.types.SLIDE,
        true
    );
  },

  searchAllCustomerAccountsBy(undefined, searchItem) {
    let payload = {
      customerBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      readType: "search",
      searchItem,
    };

    const successAction = (responseData) => {
      StoreUtils.commit(
        "customer/SET_ALL_CUSTOMER_ACCOUNTS_SEARCH_RESULT",
        responseData.data
      );
    };

    return newCustomerService.searchAllCustomers(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  searchAllCustomersBy(undefined, searchItem) {
    let payload = {
      customerBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      readType: "search",
      searchItem,
    };

    const successAction = (responseData) => {
      StoreUtils.commit(
        "customer/SET_ALL_CUSTOMERS_SEARCH_RESULT",
        responseData.data
      );
    };

    return newCustomerService.searchAllCustomers(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchCustomerInfoByAccountNumber(undefined, accountNumber) {
    let payload = {
      accountBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      accountNumber,
    };

    const successAction = (responseData) => {
      StoreUtils.dispatch(
        "customer/viewMoreCustomerInfo",
        responseData.accountCustomerId
      );
    };

    return newCustomerService.fetchCustomerInfoByAccountNumber(
      payload,
      successAction,
      LoaderUtils.types.TABLE_ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  viewMoreCustomerInfo({ commit }, customerId) {
    // start loader
    commit("SET_LOADING_FIVE", true);

    let payload = {
      customerBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      customerId,
    };
    return CustomerService.viewMoreCustomerInfo(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING_FIVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          commit("SET_CURRENT_CUSTOMER_MORE_INFO", responseData);
        } else {
          commit("SET_CURRENT_CUSTOMER_MORE_INFO", {});
        }
        return responseData;
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING_FIVE", false);
        commit("SET_CURRENT_CUSTOMER_MORE_INFO", {});

        throw error;
      });
  },

  // eslint-disable-next-line no-unused-vars
  editCustomerAccount({ commit }, payload) {
    // start loader
    // commit("SET_LOADING_TEN", true);
    let request = {
      accountType: payload.accountType,
      accountClass: payload.accountClass,
      accountNumber: payload.accountNumber,
      accountName: payload.accountName,
      accountStatus: payload.accountStatus,
      accountBankCode: payload.accountBankCode,
      accountMaker: payload.accountMaker,
      accountCustomerId: payload.accountCustomerId,
      accountAllowCredit: payload.accountAllowCredit,
      accountAllowDebit: payload.accountAllowDebit,
      auditUser: StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_ID),
    };

    const successAction = () => {
      //StoreUtils.dispatch("customer/allCustomersAccounts");
    };

    return newCustomerService.editCustomerAccount(
      request,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  allCustomersAccounts({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_TEN", true);

    return CustomerService.allCustomersAccounts(payload)
      .then((response) => {
        commit("SET_LOADING_TEN", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_CUSTOMERS_ACCOUNTS", responseData.data);
        } else {
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        commit("SET_LOADING_TEN", false);

        throw error;
      });
  },

  allCustomersSubAccountCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_EIGHT", true);

    return CustomerService.allCustomersSubAccountsCheckersQueue(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING_EIGHT", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit(
            "SET_ALL_CUSTOMER_SUB_ACCOUNTS_CHECKERS_QUEUE",
            responseData.data
          );
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING_EIGHT", false);

        throw error;
      });
  },

  checkerAuthorizeCustomerSubAccount({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_NINE", true);

    return CustomerService.checkerAuthorizeCustomerSubAccount(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING_NINE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success -> ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING_NINE", false);

        throw error;
      });
  },

  allCreateCustomersCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_SIX", true);

    return CustomerService.allCreateCustomersCheckersQueue(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING_SIX", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("SET_ALL_CREATE_CUSTOMERS_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING_SIX", false);

        throw error;
      });
  },

  checkerAuthorizeCustomerCreate({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_SEVEN", true);

    return CustomerService.checkerAuthorizeCustomerCreate(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING_SEVEN", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success - ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING_SEVEN", false);

        throw error;
      });
  },

  setModuleLoading({ commit }, payload) {
    commit("SET_LOADING", payload);
  },

  addSubAccount({ commit, dispatch, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING_FOUR", true);

    // set account maker;
    payload.accountMaker = rootGetters["user/getUserId"];

    return CustomerService.addSubAccount(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING_FOUR", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING_FOUR", false);

        throw error;
      });
  },

  createCustomer({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    payload.customerType = "CUSTOMER";

    return CustomerService.createCustomer(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          // moved saved files to permanent location
          dispatch("appS3Upload/moveCustomerPictureToFinalLocation", null, {
            root: true,
          });
          dispatch("appS3Upload/moveCustomerMandateToFinalLocation", null, {
            root: true,
          });

          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // delete saved files from staging area
          dispatch("appS3Upload/deleteCustomerPictureFromStagingArea", null, {
            root: true,
          });
          dispatch("appS3Upload/deleteCustomerMandateFromStagingArea", null, {
            root: true,
          });

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },
  createLien({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    payload.customerType = "CUSTOMER";

    return CustomerService.createLien(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          const npayload = {
            accountNumber: payload.accountNumber,
            bankCode: payload.bankCode,
          };
          dispatch("allUserLien", npayload);

          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // delete saved files from staging area

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },
  approveLogs({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return CustomerService.approveUpgradeLogs(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // delete saved files from staging area

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },
  removeLien({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return CustomerService.removeLien(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          const npayload = {
            accountNumber: payload.accountNumber,
            bankCode: payload.bankCode,
          };
          dispatch("allUserLien", npayload);
          // moved saved files to permanent location

          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // delete saved files from staging area

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },

  declineLogs({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return CustomerService.declineUpgradeLogs(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;
        if (responseData.responseCode === "00") {
          // moved saved files to permanent location

          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // delete saved files from staging area

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },

  // list all Customers
  allCustomers({ commit, dispatch }, payload) {
    commit("SET_TEST", "test");

    // start loader
    commit("SET_LOADING_THREE", true);

    return CustomerService.allCustomers(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_CUSTOMERS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        throw error;
      });
  },

  // list all Customers
  allAccounts({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_THREE", true);

    return CustomerService.allAccounts(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_ACCOUNTS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        throw error;
      });
  },
  allUserLien({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING", true);
    return CustomerService.allLien(payload)
      .then((response) => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("updateAllLien", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },

  allLogsUpgrade({ commit, dispatch }, payload) {
    // start loader
    commit("SetLogsModal", true);
    return CustomerService.viewUpgradeLogs(payload)
      .then((response) => {
        // stop loader
        commit("SetLogsModal", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("updateAllLogs", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`,
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch((error) => {
        //stop loader
        commit("SetLogsModal", false);

        throw error;
      });
  },


  upgradeLogsReadByCustomerId({ commit }, payload) {
    // start loader
    commit("SET_LOADING_FIVE", true);


    return CustomerService.viewUpgradeLogsReadByCustomerId(payload)
        .then((response) => {
          // stop loader
          commit("SET_LOADING_FIVE", false);

          let responseData = response.data;

          if (responseData.responseCode === "00") {
            // push Notification
            commit("updateUpgradeLogsReadByCustomerId", responseData);
          } else {
            commit("updateUpgradeLogsReadByCustomerId", {});
          }
          return responseData;
        })
        .catch((error) => {
          //stop loader
          commit("SET_LOADING_FIVE", false);
          commit("updateUpgradeLogsReadByCustomerId", {});

          throw error;
        });
  },

  upgradeLogsUpdate({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return CustomerService.upgradeLogsUpdate(payload)
        .then((response) => {
          // stop loader
          commit("SET_LOADING", false);

          let responseData = response.data;
          if (responseData.responseCode === "00") {
            commit("updateUpgradeLogsUpdate", responseData);
            // push Notification
            const notification = {
              type: "success",
              variant: "success",
              message: `${responseData.responseMessage}`,
            };
            dispatch("notification/add", notification, { root: true });

          } else {
            // delete saved files from staging area

            // push Notification
            const notification = {
              type: "error",
              variant: "danger",
              message: `Error - ${responseData.responseMessage}`,
            };
            dispatch("notification/add", notification, { root: true });
          }
        })
        .catch((error) => {
          //stop loader
          commit("SET_LOADING", false);

          throw error;
        });
  },


  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};
