<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text" label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <p>To be removed {{ checkedNames }}</p>
    <p>{{ createQueryType }}</p>
    <p>{{ getData }}</p>
    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template v-slot:cell(columnName)="data">
        <p class="name-field">
          <span>{{ data.item.columnName }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(check)="data">
        <p class="name-field">
          <span>
            <b-form-checkbox-group
              switch
              v-model="checkedNames"
              @change="computeValue()"
            >
              <b-form-checkbox
                key="data.item.columnName"
                :value="data.item.columnName"
              ></b-form-checkbox>
            </b-form-checkbox-group>
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(queryType)="data">
        <p class="name-field">
          <span>
            <div>
              <select
                name="LeaveType"
                placeholder="Query Type"
                class="form-control"
                @change="onChange($event.target.value)"
              >
                <option v-for="item in options2" :value="item" :key="item">{{
                  item
                }}</option>
              </select>
            </div>
          </span>
        </p>
      </template>

      <template v-slot:cell(keyWord)="data">
        <p class="name-field">
          <span>
            <BaseInput
              type="text"
              :inputType="'text'"
              v-model="keyword"
              placeholder="Keyword"
              :hasPreIcon="false"
            >
            </BaseInput>
          </span>
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "QueryReportTable",
  data() {
    return {
      finalValue: "",
      createQueryType: [],
      options2: ["Like", "="],
      selectedValue: "Like",
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        { key: "columnName", label: "Column Name", sortable: true },
        // { key: "reportID", label: "Report ID", sortable: true },
        { key: "check", label: "Check", sortable: true },
        { key: "queryType", label: "Query Type", sortable: true },
        { key: "keyWord", label: "Keyword", sortable: true }
      ],
      json_fields: {
        "Report Bank Code": "reportBankCode",
        "Report ID": "reportID",
        "Report Location": "reportLocation",
        "Report Category": "reportCategory",
        "Report Manager": "reportManager",
        "Report Address": "reportAddress",
        "Report Name": "reportName",
        "Report Region": "reportRegion",
        "Report Updated At": "reportUpdatedAt",
        "Report Country": "reportCountry",
        "Report Code": "reportCode",
        "Report Transaction Limit": "reportTransactionLimit",
        "Report City": "reportCity",
        "Report State": "reportState",
        "Report Status": "reportStatus",
        "Report Created At": "reportCreatedAt",
        "Report Created By": "reportCreatedBy"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      list_check: [],
      checkedNames: [],
      getData: []
    };
  },
  computed: {
    ...mapState({
      items: state => state.report.columns,
      isBusy: state => state.report.moduleLoadingFive
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  validations: {
    createQueryType: {
      queryType: { required }
    }
  },
  created() {
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    returnItems() {
    },
    computeValue() {
      this.finalValue = "hhhh";
    },
    onChange(event) {
      this.selectedValue = event;

      let list_split = event.split("_");
      let identifier = list_split[0];
      if (this.list_check.includes(identifier)) {
        this.list_check.unshift(identifier);
        this.createQueryType.forEach(item => {
          if (item.includes(identifier)) {
            const index = this.createQueryType.indexOf(item);
            this.createQueryType.splice(index, 1);
            this.createQueryType.push(event);
          }
        });
      } else {
        this.list_check.push(identifier);
        this.createQueryType.push(event);
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
