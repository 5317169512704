<template>
  <div>
    <div class="view-more-top">
      <div class="row">
        <div class="col-sm-6">
          <p class="queue-title">Account Number</p>
          <p class="queue-value">
            {{ editFormBody.accountNumber }}
          </p>
        </div>

        <div class="col-sm-6">
          <p class="queue-title">Account Name</p>
          <p class="queue-value">
            {{ editFormBody.accountName }}
          </p>
        </div>
      </div>
    </div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div v-if="customer.moduleLoading">
      <BaseComponentLoader />
    </div>
    <div>
      <form @submit.prevent="submit">
        <div class="row">

          <BaseInput
              @input="formatAmount"
              :inputType="'text'"
              placeholder="Amount"
              v-model="formBody.amount"
              :hasPreIcon="false"
              :label="'Amount'"
              class=""
          >
          </BaseInput>

          <div style="padding-top: 1.5rem; justify-content: start; display: flex; padding-left: 1rem">
            <p class="text-right">
          <span v-if="customer.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
              <span v-else>
            <button  type="submit" class="btn btn-primary mr-2">
              Create Lien Amount
            </button>
          </span>
            </p>
          </div>

        </div>


      </form>
    </div>
    <div class="form-body">
      <AllCustomerLienTable></AllCustomerLienTable>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BaseInput from "../../../BaseComponents/BaseInput";
import AllCustomerLienTable from "../../Tables/Customer/AllCustomerLienTable";

export default {
  name: "CreateLienForm",
  components: { AllCustomerLienTable, BaseInput },
  props: {
    editFormBody: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formBody: {
        amount: "",
        accountNumber: "",
        bankCode: "",
        reference: ""
      }
    };
  },
  computed: {
    ...mapState(["loader", "appUtilities", "customer"]),
    ...mapGetters({
      accountClassOptionsFromCode:
        "appUtilities/getAccountClassOptionFromCodeUtility",
      getAccountClassFromOptionUtility:
        "appUtilities/getAccountClassFromOptionUtility"
    }),
  },
  validations: {
    formBody: {
    }
  },
  async created() {
    await this.$store.dispatch("appUtilities/fetchAllAccountClass2", null, {
      root: true
    });
    this.formBody.accountStatus = await this.editFormBody.accountStatus;
    this.formBody.accountClass = await this.accountClassOptionsFromCode(
      this.editFormBody.accountClass
    );
  },
  mounted() {},
  methods: {
    formatAmount() {
      // Remove any existing commas from the amount
      const cleanedAmount = this.formBody.amount.replace(/,/g, '');
      // Format the amount with comma separators
      const formattedAmount = cleanedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // Update the amount with the formatted version
      this.formBody.amount = formattedAmount;
    },

    submit() {
      const amountWithoutCommas = this.formBody.amount.replace(/,/g, '');
      this.formBody.amount = amountWithoutCommas
        this.formBody.accountNumber = this.editFormBody.accountNumber;
        this.formBody.reference = Math.floor(10000000 + Math.random() * 90000000)
      this.formBody.bankCode = localStorage.getItem("kleyton.user.bankCode");
      this.$store.dispatch("customer/createLien", this.formBody, {root: true})
          .then(responseData => {
            if (responseData.responseCode === "00") {
              this.$emit("editSuccessful", responseData);
            }
            this.formBody = {
              amount: "",
              accountNumber: "",
              bankCode: "",
              reference: ""
            };
            this.$v.$reset();
          }) .catch(() => {});
    },
    createFreshFormBodyObject() {
      return {
        accountStatus: "",
        accountClass: ""
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
