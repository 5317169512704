<template>
  <div>
    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
      @activate-tab="resetFt"
    >
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">Internal Transfer</span>
        </template>

        <div class="tab-main-content">
          <code class="mb-4">
            Transfer Funds to
            {{ getBankNameFromCodeUtility(user.currentUser.userBankCode) }}
          </code>
          <br />
          <br />
          <br />

          <div class="tab-form">
            <div v-if="appUtilities.moduleLoading">
              <BaseComponentLoader />
            </div>
            <div v-else>
              <FundTransferInternalCard />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="far fa-user"></i>
          </span>
          <span class="d-none d-sm-inline-block">External Transfer</span>
        </template>

        <div class="tab-main-content">
          <code class="mb-4">
            Transfer funds to other banks
          </code>
          <br />
          <br />
          <br />

          <div class="tab-form">
            <FundTransferExternalCard />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FundTransferExternalCard from "../../Cards/FT/FundTransferExternalCard";
import FundTransferInternalCard from "../../Cards/FT/FundTransferInternalCard";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
export default {
  name: "FundsTransferTab",
  components: { FundTransferInternalCard, FundTransferExternalCard },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user", "appUtilities", "fundsTransfer"]),
    ...mapGetters({
      getBankNameFromCodeUtility: "appUtilities/getBankNameFromCodeUtility"
    })
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllTransactionCode", null, {
      root: true
    });
  },
  methods: {
    resetFt() {
      StoreUtils.dispatch(StoreUtils.actions.ft.RESET);
    }
  },
  beforeDestroy() {
    this.resetFt();
  }
};
</script>

<style scoped></style>
