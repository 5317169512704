<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
<!--      <div class="col-xl-3">-->
<!--        <BranchStatsPanel />-->
<!--      </div>-->
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">View Loans</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <AllLoansTable></AllLoansTable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Create Bank Component
 */


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";

export default {
  page: {
    title: "View Loans",
    meta: [{ name: "View Loans", content: window.__env.app.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "View Loans",

      items: [
        {
          text: "Loan",
          href: "/"
        },
        {
          text: "View Loans",
          active: true
        }
      ]
    };
  },
  methods: {}
};
</script>
