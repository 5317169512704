<template>
  <div>
    <div v-if="appUtilities.moduleLoadingFive">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="createTill">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Till Account Number</label>
                <multiselect
                  v-model="createTillFormBody.tillAccountNumber"
                  :options="internalAccountsUtilityOption"
                  placeholder="Till Account Number"
                  @close="$v.createTillFormBody.tillAccountNumber.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.createTillFormBody.tillAccountNumber.$error">
                <p
                  v-if="!$v.createTillFormBody.tillAccountNumber.required"
                  class="form-error-message"
                >
                  Till Account Number is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Till Teller Number</label>
                <multiselect
                  v-model="createTillFormBody.tillTellerNumber"
                  :options="tillTellerNumberOptions"
                  placeholder="Till Teller Number"
                  @close="$v.createTillFormBody.tillTellerNumber.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.createTillFormBody.tillTellerNumber.$error">
                <p
                  v-if="!$v.createTillFormBody.tillTellerNumber.required"
                  class="form-error-message"
                >
                  Till Teller Number is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <p class="text-left">
          <span v-if="till.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create Till
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateTillForm",
  data() {
    return {
      createTillFormBody: this.createFreshCreateTillFormBodyObject(),

      options: ["Head of Till", "Till"]
    };
  },
  computed: {
    ...mapState(["till", "appUtilities"]),
    ...mapGetters({
      tillTellerNumberOptions: "appUtilities/getTellersUtilityOption",
      internalAccountsUtilityOption:
        "appUtilities/getInternalAccountsUtilityOption",
      getInternalAccountsFromOptionUtility:
        "appUtilities/getInternalAccountsFromOptionUtility"
    })
  },
  validations: {
    createTillFormBody: {
      tillAccountNumber: { required },
      tillTellerNumber: { required }
    }
  },
  async created() {
    await this.$store.dispatch("appUtilities/fetchAllTellers", null, {
      root: true
    });
    await this.$store.dispatch("appUtilities/fetchAllInternalAccounts", null, {
      root: true
    });
  },
  methods: {
    createTill() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createTillFormBody.tillCurrency = "NGN";
        this.createTillFormBody.tillBankCode = this.$store.getters[
          "user/getUserBankCode"
        ];
        this.createTillFormBody.tillBranchCode = this.$store.getters[
          "user/getUserBranchCode"
        ];
        this.createTillFormBody.tillMaker = this.$store.getters[
          "user/getUserId"
        ];
        this.createTillFormBody.tillChecker = "SYSTEM";
        this.createTillFormBody.tillAccountNumber = this.getInternalAccountsFromOptionUtility(this.createTillFormBody.tillAccountNumber);
        // this.createTillFormBody.tillTellerNumber = this.getTellerNumberOptions( this.createTillFormBody.tillTellerNumber );

        this.$store
          .dispatch("till/createTill", this.createTillFormBody, { root: true })
          .then(() => {
            this.createTillFormBody = {
              source: "web"
            };
            this.$v.$reset();
          });
      }
    },
    createFreshCreateTillFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
