var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.appUtilities.moduleLoadingEight)?_c('div',[_c('BaseComponentLoader')],1):_vm._e(),_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.editBranch.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.editBranchFormBody.branchName.$error
              },attrs:{"inputType":'text',"placeholder":"Bank Name","hasPreIcon":false,"label":'Bank Name'},on:{"blur":function($event){return _vm.$v.editBranchFormBody.branchName.$touch()}},model:{value:(_vm.editBranchFormBody.branchName),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchName", $$v)},expression:"editBranchFormBody.branchName"}}),(_vm.$v.editBranchFormBody.branchName.$error)?[(!_vm.$v.editBranchFormBody.branchName.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Name is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.editBranchFormBody.branchAddress.$error
              },attrs:{"inputType":'text',"placeholder":"Branch Address","hasPreIcon":false,"label":'Branch Address'},on:{"blur":function($event){return _vm.$v.editBranchFormBody.branchAddress.$touch()}},model:{value:(_vm.editBranchFormBody.branchAddress),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchAddress", $$v)},expression:"editBranchFormBody.branchAddress"}}),(_vm.$v.editBranchFormBody.branchAddress.$error)?[(!_vm.$v.editBranchFormBody.branchAddress.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Address is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.editBranchFormBody.branchTransactionLimit.$error
              },attrs:{"inputType":'text',"placeholder":"Branch Transaction Limit","hasPreIcon":false,"label":'Branch Transaction Limit'},on:{"blur":function($event){return _vm.$v.editBranchFormBody.branchTransactionLimit.$touch()}},model:{value:(_vm.editBranchFormBody.branchTransactionLimit),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchTransactionLimit", $$v)},expression:"editBranchFormBody.branchTransactionLimit"}}),(_vm.$v.editBranchFormBody.branchTransactionLimit.$error)?[(!_vm.$v.editBranchFormBody.branchTransactionLimit.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Transaction Limit is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.editBranchFormBody.branchCity.$error
              },attrs:{"inputType":'text',"placeholder":"Branch City","hasPreIcon":false,"label":'Branch City'},on:{"blur":function($event){return _vm.$v.editBranchFormBody.branchCity.$touch()}},model:{value:(_vm.editBranchFormBody.branchCity),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchCity", $$v)},expression:"editBranchFormBody.branchCity"}}),(_vm.$v.editBranchFormBody.branchCity.$error)?[(!_vm.$v.editBranchFormBody.branchCity.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch City is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.editBranchFormBody.branchState.$error
              },attrs:{"inputType":'text',"placeholder":"Branch State","hasPreIcon":false,"label":'Branch State'},on:{"blur":function($event){return _vm.$v.editBranchFormBody.branchState.$touch()}},model:{value:(_vm.editBranchFormBody.branchState),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchState", $$v)},expression:"editBranchFormBody.branchState"}}),(_vm.$v.editBranchFormBody.branchState.$error)?[(!_vm.$v.editBranchFormBody.branchState.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch State is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.editBranchFormBody.branchType.$error
              },attrs:{"inputType":'text',"placeholder":"Branch Type","hasPreIcon":false,"label":'Branch Type'},on:{"blur":function($event){return _vm.$v.editBranchFormBody.branchType.$touch()}},model:{value:(_vm.editBranchFormBody.branchType),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchType", $$v)},expression:"editBranchFormBody.branchType"}}),(_vm.$v.editBranchFormBody.branchType.$error)?[(!_vm.$v.editBranchFormBody.branchType.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Type is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Branch Manager")]),_c('multiselect',{attrs:{"options":_vm.branchMangerUsersOption,"placeholder":"Branch Manger"},on:{"close":function($event){return _vm.$v.editBranchFormBody.branchManager.$touch()}},model:{value:(_vm.editBranchFormBody.branchManager),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchManager", $$v)},expression:"editBranchFormBody.branchManager"}})],1),(_vm.$v.editBranchFormBody.branchManager.$error)?[(!_vm.$v.editBranchFormBody.branchManager.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Manager is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.editBranchFormBody.branchRegion.$error
              },attrs:{"inputType":'text',"placeholder":"Branch Region","hasPreIcon":false,"label":'Branch Region'},on:{"blur":function($event){return _vm.$v.editBranchFormBody.branchRegion.$touch()}},model:{value:(_vm.editBranchFormBody.branchRegion),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchRegion", $$v)},expression:"editBranchFormBody.branchRegion"}}),(_vm.$v.editBranchFormBody.branchRegion.$error)?[(!_vm.$v.editBranchFormBody.branchRegion.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Branch Region is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.editBranchFormBody.branchCountry.$error
              },attrs:{"inputType":'text',"placeholder":"Country","hasPreIcon":false,"label":'Country'},on:{"blur":function($event){return _vm.$v.editBranchFormBody.branchCountry.$touch()}},model:{value:(_vm.editBranchFormBody.branchCountry),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchCountry", $$v)},expression:"editBranchFormBody.branchCountry"}}),(_vm.$v.editBranchFormBody.branchCountry.$error)?[(!_vm.$v.editBranchFormBody.branchCountry.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Country is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Branch Status")]),_c('multiselect',{attrs:{"options":_vm.branchStatusOption,"placeholder":"Branch Status"},on:{"close":function($event){return _vm.$v.editBranchFormBody.branchStatus.$touch()}},model:{value:(_vm.editBranchFormBody.branchStatus),callback:function ($$v) {_vm.$set(_vm.editBranchFormBody, "branchStatus", $$v)},expression:"editBranchFormBody.branchStatus"}})],1),(_vm.$v.editBranchFormBody.branchStatus.$error)?[(!_vm.$v.editBranchFormBody.branchStatus.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Status is required ")]):_vm._e()]:_vm._e()],2)])]),_c('p',{staticClass:"text-right"},[(_vm.branch.moduleLoadingFour)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Update ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }