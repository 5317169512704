<template>
  <div>
    <div v-if="appUtilities.moduleLoadingSix">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="addSubAccount">
        <!--      <p>{{customer}}</p>-->
        <div class="row">
          <div class="col-12">
            <div>
              <div class="form-group">
                <label>Account Class</label>
                <multiselect
                  v-model="formBody.accountClass"
                  :options="accountClassOptions"
                  @close="$v.formBody.accountClass.$touch()"
                  placeholder="Account Class"
                ></multiselect>
              </div>
              <!--                          error-->
              <template v-if="$v.formBody.accountClass.$error">
                <p
                  v-if="!$v.formBody.accountClass.required"
                  class="form-error-message"
                >
                  Account Class is required
                </p>
              </template>
            </div>

            <div>
              <div class="form-group">
                <label>Account Currency</label>
                <multiselect
                  v-model="formBody.accountCurrency"
                  :options="accountCurrencyOptions"
                  @close="$v.formBody.accountCurrency.$touch()"
                  placeholder="Account Currency"
                ></multiselect>
              </div>

              <!--                          error-->
              <template v-if="$v.formBody.accountCurrency.$error">
                <p
                  v-if="!$v.formBody.accountCurrency.required"
                  class="form-error-message"
                >
                  Account Currency is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <div>
          <p class="text-right">
            <span v-if="customer.moduleLoadingFour" class="formLoaderHold">
              <BaseComponentLoader />
            </span>
            <span v-else>
              <button type="submit" class="btn btn-primary mr-1">
                Add Sub Account
              </button>
            </span>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "AddSubAccountForm",
  data() {
    return {
      formBody: this.createFreshFormBodyObject()
    };
  },
  props: {
    currentCustomer: {
      type: Object,
      required: true
    }
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapState(["customer", "appUtilities"]),
    ...mapGetters({
      getAccountClassFromOptionUtility:
        "appUtilities/getAccountClassFromOptionUtility",
      accountCurrencyOptions: "appUtilities/getCurrencyUtilityOption"
    }),
    accountClassOptions() {
      return StoreUtils.rootGetter(
        "appUtilities/getAccountClass2UtilityOption"
      ).filter(accountClass => accountClass.Type !== "FIXED");
    }
  },
  validations: {
    formBody: {
      accountClass: { required },
      accountCurrency: { required }
    }
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllAccountClass2", null, {
      root: true
    });
  },
  methods: {
    addSubAccount() {
      this.formBody.accountCustomerId = this.currentCustomer.customerId;
      this.formBody.accountBankCode = this.currentCustomer.customerBankCode;
      this.formBody.accountBranchCode = this.currentCustomer.customerBranchCode;
      this.formBody.accountClass = this.getAccountClassFromOptionUtility(
        this.formBody.accountClass
      );
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("customer/addSubAccount", this.formBody, {
            root: true
          })
          .then(response => {
            if (response.responseCode === "00") {
              this.formBody = {
                source: "web"
              };
              this.$v.$reset();
              this.$emit("close-add-sub-account-modal", response);
            }
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
