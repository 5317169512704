import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import DisputeService from "../../services/newServices/DisputeService";

const disputeService = new DisputeService();

let compareDisputeUpdatedDateAsc = (a, b) => {
  if (a.disputeUpdatedAt < b.disputeUpdatedAt) return 1;
  if (a.disputeUpdatedAt > b.disputeUpdatedAt) return -1;
  return 0;
};

const getDefaultState = () => {
  return {
    currentDisputeStatus: ""
  };
};

export const namespaced = true;

export const state = getDefaultState();

export const getters = {
  getCurrentDisputeStatus: state => {
    return state.currentDisputeStatus;
  }
};

export const mutations = {
  SET_CURRENT_DISPUTE_STATUS(state, payload) {
    state.currentDisputeStatus = payload;
  }
};

export const actions = {
  updateDisputeStatus(undefined, { newDisputeStatus }) {
    let payload = {
      disputeBankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      disputeRemark: StoreUtils.rootGetter("form/getFormBody").disputeRemark,
      disputeId: StoreUtils.rootGetter("table/getCurrentTableObject").disputeId,
      disputeStatus:
        newDisputeStatus === "" ? "CLOSED" : newDisputeStatus.toUpperCase(),
      source: "CBA"
    };

    const successAction = () => {
      StoreUtils.dispatch("dispute/fetchAllDisputes");
      StoreUtils.commit(StoreUtils.mutations.form.RESET_FORM);
    };

    return disputeService.updateDisputeStatus(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },
  reopenDispute() {
    let payload = {
      disputeBankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      disputeRemark: StoreUtils.rootGetter("form/getFormBody").disputeRemark,
      disputeId: StoreUtils.rootGetter("table/getCurrentTableObject").disputeId,
      source: "CBA"
    };

    const successAction = responseData => {
      StoreUtils.commit(StoreUtils.mutations.form.RESET_FORM);
      StoreUtils.dispatch("dispute/fetchAllDisputes");
      return responseData;
    };

    return disputeService.reopenDispute(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchOneDispute(undefined, { disputeId }) {
    let payload = {
      disputeBankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      disputeId,
      source: "CBA"
    };

    const successAction = responseData => {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_CURRENT_TABLE_OBJECT,
        responseData
      );
    };

    return disputeService.fetchOneDispute(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  fetchAllDisputes() {
    let payload = {
      disputeBankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      readAll: "NO",
      source: "CBA"
    };

    const successAction = responseData => {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_TABLE_DATA,
        responseData.data.sort(compareDisputeUpdatedDateAsc)
      );
    };

    return disputeService.fetchAllDisputes(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      ErrorUtils.types.SLIDE,
      true
    );
  },

  createDispute() {
    let payload = {
      disputeBankCode: StoreUtils.rootGetter("user/getUserBankCode"),
      disputeMaker: StoreUtils.rootGetter("user/getUserId"),
      disputeComment: StoreUtils.rootGetter("form/getFormBody").disputeComment,
      disputeIssueType: StoreUtils.rootGetter("form/getFormBody")
        .disputeIssueType,
      disputeRemark: StoreUtils.rootGetter("form/getFormBody").disputeRemark,
      disputeSessionId: StoreUtils.rootGetter("form/getFormBody")
        .disputeSessionId,
      disputeBearer: StoreUtils.rootGetter("form/getFormBody").disputeBearer,
      disputeTrnType: StoreUtils.rootGetter("form/getFormBody").disputeTrnType,
      source: "CBA"
    };

    const successAction = () => {
      StoreUtils.commit(StoreUtils.mutations.form.RESET_FORM);
    };

    return disputeService.createDispute(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON,
      ErrorUtils.types.SLIDE,
      true
    );
  }
};
