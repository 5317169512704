<template>
  <div class="table-section col-12">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                class="btn btn-sm query-btn btn-semi-rounded btn-outline-secondary"
                @click="queryReport()"
              >
                Query This Report
              </button>
            </span>
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i>
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
    >
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import RouterUtils from "../../../../../utils/BaseUtils/RouterUtils";
import router from "../../../../../router";

export default {
  name: "ReportTable",

  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [10, 15, 20],
      sortDirection: "asc",
      filter: null,
      columns: [],
      items: [],
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [],
      json_fields: {},
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      rpt: state => state.rpt,
      isBusy: state => state.loader.tableLoader
    }),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    currentReportName() {
      return StoreUtils.rootGetter("rpt/getCurrentReportName");
    }
  },
  mounted() {},
  created() {
    this.fetchTableData();
  },

  methods: {
    async fetchTableData() {
      if (StoreUtils.rootGetter("rpt/getCurrentReportName") === "") {
        await router.push({
          name: RouterUtils.routes.rpt.VIEW_ALL_REPORTS
        });
      } else {
        await StoreUtils.dispatch("rpt/fetchOneReport");
        this.items = this.rpt.currentReport;
        // Set the initial number of items
        this.totalRows = this.items.length;
        this.filter = " ";
      }
    },
    queryReport() {
      StoreUtils.dispatch("rpt/queryReport", this.currentReportName);
    }
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
