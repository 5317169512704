/* eslint-disable */

import Vue from "vue";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";


// BASE COMPONENT SET-UP
const requireComponent = require.context(
  "../components/BaseComponents",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});
// BASE COMPONENT SET-UP END



// AppAccessDenied
 import AppAccessDenied from "../components/AppComponents/AppLoading.vue";
 Vue.component("AppAccessDenied", AppAccessDenied);

// AppLoading
 import AppLoading from "../components/AppComponents/AppLoading.vue";
 Vue.component("AppLoading", AppLoading);
 import AppStatusBadge from "../components/AppComponents/AppStatusBadge.vue";
 Vue.component("AppStatusBadge", AppStatusBadge);



 // Notification
 import NotificationContainer from "../components/BaseComponents/BaseNotification/NotificationContainer.vue";
 Vue.component("NotificationContainer", NotificationContainer);

 // StatPanel
 import SampleStatsPanel from "../components/Kleyton/StatsPanel/SampleStatsPanel";
 Vue.component("SampleStatsPanel", SampleStatsPanel);
 import BankStatsPanel from "../components/Kleyton/StatsPanel/Bank/BankStatsPanel";
 Vue.component("BankStatsPanel", BankStatsPanel);
 import BranchStatsPanel from "../components/Kleyton/StatsPanel/Branch/BranchStatsPanel";
 Vue.component("BranchStatsPanel", BranchStatsPanel);

// Auth
// import PowerTopUpForm from "./components/Template/forms/PowerTopUpForm";
// Vue.component("PowerTopUpForm", PowerTopUpForm);
// import BuyPowerForm from "./components/Template/forms/BuyPowerForm";
// Vue.component("BuyPowerForm", BuyPowerForm);

//Forms
import SampleForm from "../components/Kleyton/Forms/SampleForm";
Vue.component("SampleForm", SampleForm);
 // --> Auth
import ChangeDefaultPasswordForm from "../components/Kleyton/Forms/Auth/ChangeDefaultPasswordForm";
Vue.component("ChangeDefaultPasswordForm", ChangeDefaultPasswordForm);

import CreateBankForm from "../components/Kleyton/Forms/Bank/CreateBankForm";
Vue.component("CreateBankForm", CreateBankForm);
import EditBankForm from "../components/Kleyton/Forms/Bank/EditBankForm";
Vue.component("EditBankForm", EditBankForm);
import CreateBranchForm from "../components/Kleyton/Forms/Branch/CreateBranchForm";
Vue.component("CreateBranchForm", CreateBranchForm);
import BranchUpdateStatusForm from "../components/Kleyton/Forms/Branch/UpdateStatusForm";
Vue.component("BranchUpdateStatusForm", BranchUpdateStatusForm);

import CreateTransactionCodeForm from "../components/Kleyton/Forms/TransactionCode/CreateTransactionCodeForm";
Vue.component("CreateTransactionCodeForm", CreateTransactionCodeForm);

import UploadBankLogoForm from "../components/Kleyton/Forms/Bank/UploadBankLogoForm";
Vue.component("UploadBankLogoForm", UploadBankLogoForm);

import LoginForm from "../components/Kleyton/Forms/Auth/LoginForm";
Vue.component("LoginForm", LoginForm);

import BookLoanForm from "../components/Kleyton/Forms/Loan/BookLoanForm";
Vue.component("BookLoanForm", BookLoanForm);



//Tabs
import CashWithdrawalTab from "../components/Kleyton/Tabs/FT/CashWithdrawalTab";
Vue.component("CashWithdrawalTab", CashWithdrawalTab);

//Tables
import SampleTable from "../components/Kleyton/Tables/SampleTable";
Vue.component("SampleTable", SampleTable);
import SampleTableTwo from "../components/Kleyton/Tables/SampleTableTwo";
Vue.component("SampleTableTwo", SampleTableTwo);

import AllBanksTable from "../components/Kleyton/Tables/Bank/AllBanksTable";
Vue.component("AllBanksTable", AllBanksTable);

import AllTransactionCodeTable from "../components/Kleyton/Tables/TransactionCode/AllTransactionCodeTable";
Vue.component("AllTransactionCodeTable", AllTransactionCodeTable);


// import AllBranchTable from "../components/Kleyton/Tables/Branch/AllBranchTable";
// Vue.component("AllBranchTable", AllBranchTable);

// import AllReportsTable from "../components/Kleyton/Tables/Report/AllReportsTable";
// Vue.component("AllReportsTable", AllReportsTable);

import AllLoansTable from "../components/Kleyton/Tables/Loan/AllLoansTable";
Vue.component("AllLoansTable", AllLoansTable);

import AllCustomerAccountsTable from "../components/Kleyton/Tables/Customer/AllCustomerAccountsTable";
Vue.component("AllCustomerAccountsTable", AllCustomerAccountsTable);

import CustomersCheckersQueueTable from "../components/Kleyton/Tables/Customer/CustomersCheckersQueueTable";
Vue.component("CustomersCheckersQueueTable", CustomersCheckersQueueTable);

import CustomerSubAccountCheckersQueueTable from "../components/Kleyton/Tables/Customer/CustomerSubAccountCheckersQueueTable.vue";
Vue.component("CustomerSubAccountCheckersQueueTable", CustomerSubAccountCheckersQueueTable);


import BranchCheckersQueueTable from "../components/Kleyton/Tables/Branch/BranchCheckersQueueTable";
Vue.component("BranchCheckersQueueTable", BranchCheckersQueueTable);

import FundsTransferCheckersQueueTable from "../components/Kleyton/Tables/FundsTransfer/FundsTransferCheckersQueueTable";
Vue.component("FundsTransferCheckersQueueTable", FundsTransferCheckersQueueTable);

import CashWithdrawalCheckersQueueTable from "../components/Kleyton/Tables/FundsTransfer/CashWithdrawalCheckersQueueTable";
Vue.component("CashWithdrawalCheckersQueueTable", CashWithdrawalCheckersQueueTable);

// import CashDepositCheckersQueueTable from "../components/Kleyton/Tables/FundsTransfer/CashDepositCheckersQueueTable";
// Vue.component("CashDepositCheckersQueueTable", CashDepositCheckersQueueTable);

// import CashTransactionsCheckersQueueTable from "../components/Kleyton/Tables/FundsTransfer/CashTransactionsCheckersQueueTable";
// Vue.component("CashTransactionsCheckersQueueTable", CashTransactionsCheckersQueueTable);

import GlLevel1CheckersQueueTable from "../components/Kleyton/Tables/GL1/GlLevel1CheckersQueueTable";
Vue.component("GlLevel1CheckersQueueTable", GlLevel1CheckersQueueTable);


import UsersCheckersQueueTable from "../components/Kleyton/Tables/User/UsersCheckersQueueTable";
Vue.component("UsersCheckersQueueTable", UsersCheckersQueueTable);

import VaultCheckersQueueTable from "../components/Kleyton/Tables/Vault/VaultCheckersQueueTable";
Vue.component("VaultCheckersQueueTable", VaultCheckersQueueTable);

import TillCheckersQueueTable from "../components/Kleyton/Tables/Till/TillCheckersQueueTable";
Vue.component("TillCheckersQueueTable", TillCheckersQueueTable);

import TellerCheckersQueueTable from "../components/Kleyton/Tables/Teller/TellerCheckersQueueTable";
Vue.component("TellerCheckersQueueTable", TellerCheckersQueueTable);

import InternalAccountsCheckersQueueTable from "../components/Kleyton/Tables/InternalAccount/InternalAccountCheckersQueueTable";
Vue.component("InternalAccountsCheckersQueueTable", InternalAccountsCheckersQueueTable);

import JournalCheckersQueueTable from "../components/Kleyton/Tables/Journal/JournalCheckersQueueTable";
Vue.component("JournalCheckersQueueTable", JournalCheckersQueueTable);

import ReportCheckersQueueTable from "../components/Kleyton/Tables/Report/ReportCheckersQueueTable";
Vue.component("ReportCheckersQueueTable", ReportCheckersQueueTable);

import TransactionCodeCheckersQueueTable from "../components/Kleyton/Tables/TransactionCode/TransactionCodeCheckersQueueTable";
Vue.component("TransactionCodeCheckersQueueTable", TransactionCodeCheckersQueueTable);

// Modals
import TransactionCodeCheckersQueueInfoModal from "../components/Kleyton/Modals/TransactionCode/TransactionCodeCheckersQueueInfoModal";
Vue.component("TransactionCodeCheckersQueueInfoModal", TransactionCodeCheckersQueueInfoModal);

import ReportCheckersQueueInfoModal from "../components/Kleyton/Modals/Report/ReportCheckersQueueInfoModal";
Vue.component("ReportCheckersQueueInfoModal", ReportCheckersQueueInfoModal);

import JournalCheckersQueueInfoModal from "../components/Kleyton/Modals/Journal/JournalCheckersQueueInfoModal";
Vue.component("JournalCheckersQueueInfoModal", JournalCheckersQueueInfoModal);

import InternalAccountsCheckersQueueInfoModal from "../components/Kleyton/Modals/InternalAccount/InternalAccountsCheckersQueueInfoModal";
Vue.component("InternalAccountsCheckersQueueInfoModal", InternalAccountsCheckersQueueInfoModal);

import TellerCheckersQueueInfoModal from "../components/Kleyton/Modals/Teller/TellerCheckersQueueInfoModal";
Vue.component("TellerCheckersQueueInfoModal", TellerCheckersQueueInfoModal);

import TillCheckersQueueInfoModal from "../components/Kleyton/Modals/Till/TillCheckersQueueInfoModal";
Vue.component("TillCheckersQueueInfoModal", TillCheckersQueueInfoModal);

import VaultCheckersQueueInfoModal from "../components/Kleyton/Modals/Vault/VaultCheckersQueueInfoModal";
Vue.component("VaultCheckersQueueInfoModal", VaultCheckersQueueInfoModal);

import UsersCheckersQueueInfoModal from "../components/Kleyton/Modals/User/UsersCheckersQueueInfoModal";
Vue.component("UsersCheckersQueueInfoModal", UsersCheckersQueueInfoModal);


import GlLevel1CheckersQueueInfoModal from "../components/Kleyton/Modals/GL1/GlLevel1CheckersQueueInfoModal";
Vue.component("GlLevel1CheckersQueueInfoModal", GlLevel1CheckersQueueInfoModal);

import CashDepositCheckerQueueInfoModal from "../components/Kleyton/Modals/FundsTransfer/CashDepositCheckerQueueInfoModal";
Vue.component("CashDepositCheckerQueueInfoModal", CashDepositCheckerQueueInfoModal);

import CashTransactionsCheckerQueueInfoModal from "../components/Kleyton/Modals/FundsTransfer/CashTransactionsCheckerQueueInfoModal";
Vue.component("CashTransactionsCheckerQueueInfoModal", CashTransactionsCheckerQueueInfoModal);

import CashWithdrawalCheckerQueueInfoModal from "../components/Kleyton/Modals/FundsTransfer/CashWithdrawalCheckerQueueInfoModal";
Vue.component("CashWithdrawalCheckerQueueInfoModal", CashWithdrawalCheckerQueueInfoModal);

import FundsTransferCheckerQueueInfoModal from "../components/Kleyton/Modals/FundsTransfer/FundsTransferCheckerQueueInfoModal";
Vue.component("FundsTransferCheckerQueueInfoModal", FundsTransferCheckerQueueInfoModal);

import BranchCheckerQueueInfoModal from "../components/Kleyton/Modals/Branch/BranchCheckerQueueInfoModal";
Vue.component("BranchCheckerQueueInfoModal", BranchCheckerQueueInfoModal);


import ViewCustomerCheckersQueueInfoModal from "../components/Kleyton/Modals/Customer/ViewCustomerCheckersQueueInfoModal";
Vue.component("ViewCustomerCheckersQueueInfoModal", ViewCustomerCheckersQueueInfoModal);


import ViewCustomerSubAccountCheckerQueueInfoModal from "../components/Kleyton/Modals/Customer/ViewCustomerSubAccountCheckerQueueInfoModal";
Vue.component("ViewCustomerSubAccountCheckerQueueInfoModal", ViewCustomerSubAccountCheckerQueueInfoModal);

import EditCustomerModal from "../components/Kleyton/Modals/Customer/EditCustomerModal";
Vue.component("EditCustomerModal", EditCustomerModal);

import TableMoreInfoModal from "../components/Kleyton/Modals/Utility/TableMoreInfoModal";
Vue.component("TableMoreInfoModal", TableMoreInfoModal);

import RolePrivilegesInfoModal from "../components/Kleyton/Modals/Role/RolePrivilegesInfoModal";
Vue.component("RolePrivilegesInfoModal", RolePrivilegesInfoModal);



// DashboardCharts
import SampleLineChart from "../components/Kleyton/DashboardCharts/LineChart/SampleLineChart.vue";
Vue.component("SampleLineChart", SampleLineChart);
import TransactionVolumeLineChart from "../components/Kleyton/DashboardCharts/LineChart/TransactionVolumeLineChart.vue";
Vue.component("TransactionVolumeLineChart", TransactionVolumeLineChart);

import SamplePieChart from "../components/Kleyton/DashboardCharts/PieChart/SamplePieChart.vue";
Vue.component("SamplePieChart", SamplePieChart);
import TransactionVolumePieChart from "../components/Kleyton/DashboardCharts/PieChart/TransactionVolumePieChart.vue";
Vue.component("TransactionVolumePieChart", TransactionVolumePieChart);

import SampleBarChart from "../components/Kleyton/DashboardCharts/BarChart/SampleBarChart.vue";
Vue.component("SampleBarChart", SampleBarChart);
import SuccessFailTransactionBarChart from "../components/Kleyton/DashboardCharts/BarChart/SuccessFailTransactionBarChart.vue";
Vue.component("SuccessFailTransactionBarChart", SuccessFailTransactionBarChart);



import ApexDashboardCharts from "../components/Kleyton/DashboardCharts/apex";
Vue.component("ApexDashboardCharts", ApexDashboardCharts);
import ChartistDashboardCharts from "../components/Kleyton/DashboardCharts/chartist";
Vue.component("ChartistDashboardCharts", ChartistDashboardCharts);



//select product type
import SelectProductTypeList from "../components/Kleyton/Lists/SelectProductTypeList";
Vue.component("SelectProductTypeList", SelectProductTypeList);

//Create Product Form (Dynamic Form)
import CreateProductDynamicForm from "../components/Kleyton/Forms/Product/CreateProductDynamicForm.vue";
import CashWithdrawal from "../views/FundsTransfer/CashWithdrawal";
Vue.component("CreateProductDynamicForm", CreateProductDynamicForm);



