<template>
  <div>
    <form @submit.prevent="createReport">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createReportFormBody.reportName"
              placeholder="Report Name"
              :hasPreIcon="false"
              :label="'Report Name'"
              :class="{ error: $v.createReportFormBody.reportName.$error }"
              @blur="$v.createReportFormBody.reportName.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createReportFormBody.reportName.$error">
              <p
                v-if="!$v.createReportFormBody.reportName.required"
                class="form-error-message"
              >
                Report name is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createReportFormBody.reportDescription"
              :placeholder="'Report Description'"
              :hasPreIcon="false"
              :label="'Description'"
              :class="{
                error: $v.createReportFormBody.reportDescription.$error
              }"
              @blur="$v.createReportFormBody.reportDescription.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createReportFormBody.reportDescription.$error">
              <p
                v-if="!$v.createReportFormBody.reportDescription.required"
                class="form-error-message"
              >
                Report Description is required
              </p>
            </template>
          </div>

          <div>
            <div class="form-group">
              <label>Report Type</label>
              <multiselect
                v-model="createReportFormBody.reportType"
                :options="options"
                placeholder="Report Type"
                @close="$v.createReportFormBody.reportType.$touch()"
              ></multiselect>
            </div>

            <!--                          error-->
            <template v-if="$v.createReportFormBody.reportType.$error">
              <p
                v-if="!$v.createReportFormBody.reportType.required"
                class="form-error-message"
              >
                Report type is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Report Category</label>
              <multiselect
                v-model="createReportFormBody.reportCategory"
                :options="categories"
                placeholder="Report Category"
                @close="$v.createReportFormBody.reportCategory.$touch()"
              ></multiselect>
            </div>

            <!--                          error-->
            <template v-if="$v.createReportFormBody.reportCategory.$error">
              <p
                v-if="!$v.createReportFormBody.reportCategory.required"
                class="form-error-message"
              >
                Report Category is required
              </p>
            </template>
          </div>

          <div>
            <div class="form-group">
              <label>Report Table</label>
              <multiselect
                v-model="createReportFormBody.reportTable"
                :options="tables"
                placeholder="Report Table"
                @close="$v.createReportFormBody.reportTable.$touch()"
              ></multiselect>
            </div>
            <!--                          error-->
            <template v-if="$v.createReportFormBody.reportTable.$error">
              <p
                v-if="!$v.createReportFormBody.reportTable.required"
                class="form-error-message"
              >
                Report Table is required
              </p>
            </template>
          </div>
        </div>
      </div>
      <p class="text-left">
        <span v-if="report.moduleLoading" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Create Report
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateReportForm",
  data() {
    return {
      createReportFormBody: this.createFreshCreateReportFormBodyObject(),
      tables: [],
      columnitem: [],
      categories: ["FINANCE", "INSURANCE", "LIABILITY"],
      options: ["Management Report", "Monthly Report", "Regulatory Report"]
    };
  },
  computed: {
    ...mapState(["report"])
  },
  validations: {
    createReportFormBody: {
      reportName: { required },
      reportDescription: { required },
      reportCategory: { required },
      reportType: { required },
      reportTable: { required }
    }
  },
  methods: {
    initTableList: async function() {
      const payload = {
        reportBankCode: this.$store.getters["user/getUserBankCode"]
      };
      await this.$store.dispatch("report/readAllTables", payload);
      this.tables = this.$store.getters["report/tableListString"];
    },

    createReport() {
      this.createReportFormBody.reportName =
        "RPT_" + this.createReportFormBody.reportName;
      this.createReportFormBody.reportBankCode = this.$store.getters["user/getUserBankCode"];
      this.createReportFormBody.reportFieldList = "";
      this.createReportFormBody.reportCondition = "";
      this.createReportFormBody.reportOwner = this.$store.getters["user/getUserId"];
      this.createReportFormBody.reportMaker = this.$store.getters["user/getUserId"];
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("report/createReport", this.createReportFormBody, {
            root: true
          })
          .then(() => {
            this.createReportFormBody = {};
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshCreateReportFormBodyObject() {
      return {};
    }
  },
  created() {
    this.initTableList();
  }
};
</script>

<style scoped></style>
