import { render, staticRenderFns } from "./BaseS3FileUpload.vue?vue&type=template&id=66b0a7cf&scoped=true&"
import script from "./BaseS3FileUpload.vue?vue&type=script&lang=js&"
export * from "./BaseS3FileUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b0a7cf",
  null
  
)

export default component.exports