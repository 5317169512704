<template>
  <div class="">
    <label>{{ label }}</label>

    <div class="custom-file">
      <input
        id="fileUpload"
        type="file"
        :placeholder="placeholder"
        class="custom-file-input"
      />
      <label class="custom-file-label" for="fileUpload">Choose file</label>
    </div>
  </div>
</template>

<script>
import AWS from "aws-sdk";

const albumBucketName = "lumbex-vue-s3-file-uploads";
const bucketRegion = "us-west-2";
const IdentityPoolId = "us-west-2:169e15d7-e800-4cd9-a1c4-57f3bfaa6609";

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId
  })
});

// const s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: { Bucket: albumBucketName }
// });

export default {
  name: "BaseS3FileUpload",
  props: {
    placeholder: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    appAlbumName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      uploadResponse: "00",
      temi: "sanni temi"
    };
  },
  methods: {
    async uploadPhoto(fileName) {
      // let payload = {
      //     albumName: "CustomersPhotos", // CustomersPhotos or CustomersMandate
      //     fileName: "lucasLumbex"
      // }

      let files = document.getElementById("fileUpload").files;
      if (!files.length) {
        return;
      }
      let file = files[0];
      let fileExtension = file.name.slice(
        ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      // let fileName = file.name;
      // eslint-disable-next-line no-empty
      if (file.type.includes("image")) {
        // eslint-disable-next-line no-empty
      } else {

      }


      let albumPhotosKey = encodeURIComponent(this.appAlbumName) + "/";

      let photoKey = albumPhotosKey + fileName + "." + fileExtension;

      let upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: albumBucketName,
          Key: photoKey,
          Body: file,
          ACL: "public-read"
        }
      });

      let promise = upload.promise();
      // promise();

       promise.then(
        function(data) {

          this.$store.dispatch("customer/setCustomerPicture", data, { root: true });

          return data;
        },
        function(err) {
          return err;
        }
      );
    }
  }
};
</script>

<style scoped></style>
