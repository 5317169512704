<template>
  <div>
    <!--        control-->
    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
      <template v-slot:button-content>
        <i class="mdi mdi-dots-horizontal font-size-18" />
      </template>

      <b-dropdown-item
        v-if="
          currentData.disputeStatus === 'CLOSED' ||
            currentData.disputeStatus === 'DECLINED'
        "
        @click="openAltModal(currentData, 'REOPEN')"
      >
        <i class="fas fa-folder-open text-warning mr-1" /> Reopen
      </b-dropdown-item>

      <b-dropdown-item
        v-if="currentData.disputeStatus === 'PENDING'"
        @click="openAltModal(currentData, 'CLOSED')"
      >
        <i class="fas fa-check text-success mr-1" /> Resolve
      </b-dropdown-item>

      <b-dropdown-item
        v-if="currentData.disputeStatus === 'PENDING'"
        @click="openAltModal(currentData, 'DECLINED')"
      >
        <i class="fas fa-ban text-danger mr-1" /> Decline
      </b-dropdown-item>
    </b-dropdown>

    <!--    UPDATE DISPUTE STATUS MODAL-->
    <vodal
      :show="altModal"
      :width="440"
      :height="440"
      measure="px"
      animation="zoom"
      @hide="closeAltModal"
      :closeOnClickMask="true"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ modalTitle }}</h4>

          <!--         TODO UPDATE DISPUTE STATUS FORM-->
          <UpdateDisputeStatusForm
            :current-data="currentData"
            @statusUpdateSuccessful="closeAltModal"
          ></UpdateDisputeStatusForm>
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import UpdateDisputeStatusForm from "@/components/Kleyton/Forms/Dispute/UpdateDisputeStatusForm";
export default {
  name: "AllDisputesTableActionModal",
  components: { UpdateDisputeStatusForm },
  data() {
    return {
      modalOpen: false,
      altModal: false,
      currentDispute: {},
      modalTitle: ""
    };
  },
  props: {
    currentData: {
      type: Object,
      required: true
    }
  },
  created() {
    this.currentDispute = this.currentData;
  },
  methods: {
    openAltModal(data, newDisputeStatus) {
      StoreUtils.commit("dispute/SET_CURRENT_DISPUTE_STATUS", newDisputeStatus);

      this.modalTitle =
        newDisputeStatus === "CLOSED"
          ? "Resolve this dispute"
          : newDisputeStatus === "DECLINED"
          ? "Decline this dispute"
          : "Reopen this dispute";

      StoreUtils.commit(
        StoreUtils.mutations.table.SET_CURRENT_TABLE_OBJECT,
        data
      );
      this.altModal = true;
    },
    closeAltModal() {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_CURRENT_TABLE_OBJECT,
        {}
      );
      this.altModal = false;
    }
  }
};
</script>

<style scoped></style>
