<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="row">
          <div
            v-for="(field, index) in editProductFields"
            :key="index"
            class="col-12"
          >
            <div
              v-if="
                field.templateDataType.toUpperCase() === 'STRING' ||
                  field.templateDataType.toUpperCase() === 'TEXT'
              "
            >
              <BaseInput
                :inputType="'text'"
                v-model="formBody[field.templateDescription]"
                :placeholder="field.templateLabel"
                :hasPreIcon="false"
                :label="field.templateLabel"
                :disabled="field.templateDisabled === 'YES'"
                required
              />
            </div>

            <div
              v-if="
                field.templateDataType.toUpperCase() === 'NUMBER' ||
                  field.templateDataType.toUpperCase() === 'INTEGER'
              "
            >
              <BaseInput
                :inputType="'number'"
                v-model="formBody[field.templateDescription]"
                :placeholder="field.templateLabel"
                :hasPreIcon="false"
                :label="field.templateLabel"
                :disabled="field.templateDisabled === 'YES'"
                required
              />
            </div>

            <div v-if="field.templateDataType.toUpperCase() === 'FLOAT'">
              <BaseInput
                :inputType="'amount'"
                v-model="formBody[field.templateDescription]"
                :placeholder="field.templateLabel"
                :hasPreIcon="false"
                :label="field.templateLabel"
                :disabled="field.templateDisabled === 'YES'"
                required
              />
            </div>

            <div
              v-if="
                field.templateDataType.toUpperCase() === 'DATETIME' ||
                  field.templateDataType.toUpperCase() === 'DATE'
              "
            >
              <!--            date selected-->
              <div class="form-group">
                <label>{{ field.templateLabel }}</label>
                <date-picker
                  v-model="formBody[field.templateDescription]"
                  :first-day-of-week="1"
                  :disabled="field.templateDisabled === 'YES'"
                  lang="en"
                  confirm
                ></date-picker>
              </div>
            </div>
            <div
              v-if="
                field.templateDataType.toUpperCase() === 'PASSWORD' ||
                  field.templateDataType.toUpperCase() === 'PASSCODE'
              "
            >
              <BaseInput
                :inputType="'number'"
                v-model="formBody[field.templateDescription]"
                :placeholder="field.templateLabel"
                :hasPreIcon="false"
                :label="field.templateLabel"
                :disabled="field.templateDisabled === 'YES'"
                required
              />
            </div>

            <div
              v-if="
                field.templateDataType.toUpperCase() === 'LIST' ||
                  field.templateDataType.toUpperCase() === 'SELECT'
              "
            >
              <div class="form-group">
                <label>{{ field.templateLabel }}</label>
                <multiselect
                  v-model="formBody[field.templateDescription]"
                  :options="field.templateList.split(',')"
                  :placeholder="field.templateLabel"
                  :disabled="field.templateDisabled === 'YES'"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="text-right">
              <span v-if="product.moduleLoadingEight" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Update
                  {{
                    product.selectedProductType.prdTemplateDestcription
                      | toLowercase
                      | firstCaseCapital
                  }}
                  Product
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "EditProductForm",
  props: {
    currentValue: {
      type: Object,
      required: true
    },
    productCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formBody: {}
    };
  },
  computed: {
    ...mapState(["product", "appUtilities", "loader"]),
    ...mapGetters({
      branchMangerUsersOption: "appUtilities/getAllUsersOption",
      getBranchMangerUserIdFromOptionUtility:
        "appUtilities/getUserIdFromOptionUtility"
    }),
    editProductFields() {
      return StoreUtils.rootGetter("product/getEditProductFields");
    }
  },
  components: {
    Multiselect,
    DatePicker
  },
  validations: {
    editBranchFormBody: {
      branchName: { required },
      branchAddress: { required },
      branchCity: { required },
      branchState: { required },
      branchType: { required },
      branchManager: { required },
      branchRegion: { required },
      branchStatus: { required },
      branchTransactionLimit: { required },
      branchCountry: { required }
    }
  },
  created() {
    this.fetchEditProductFields();
  },
  mounted() {
  },
  methods: {
    fetchEditProductFields() {
      StoreUtils.dispatch("product/fetchProductFieldsForEditing", {
        productCode: this.productCode
      }).then(responseData => {
        this.formBody = responseData.data.reduce(
          (accumulator, currentValue) => {
            return {
              ...accumulator,
              [currentValue.templateDescription]: currentValue.templateValue
            };
          },
          {}
        );
      });
    },
    submit() {
      let payload = {
        requestType: "update",
        products: this.formBody
      };

      StoreUtils.dispatch("product/updateProduct", payload)
        .then(responseData => {
          if (responseData.responseCode === "00") {
            this.$emit("editSuccessful", responseData);
          }
          this.formBody = {};
          this.$v.$reset();
        })
        .catch(() => {});
    },
    editFreshEditBranchFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
