 <template>
  <div>
    <!--    <button @click="sola">test Getter</button>-->
    <form @submit.prevent="submit">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Issue Type</label>
              <multiselect
                v-model="formBody.disputeIssueType"
                :options="disputeIssueTypeOptions"
                placeholder="Gl Category"
                @close="$v.formBody.disputeIssueType.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.formBody.disputeIssueType.$error">
              <p
                v-if="!$v.formBody.disputeIssueType.required"
                class="form-error-message"
              >
                Issue Type is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="formBody.disputeComment"
              placeholder="Comment"
              :hasPreIcon="false"
              :label="'Comment'"
              :class="{
                error: $v.formBody.disputeComment.$error
              }"
              @blur="$v.formBody.disputeComment.$touch()"
            >
            </BaseInput>

            <template v-if="$v.formBody.disputeComment.$error">
              <p
                v-if="!$v.formBody.disputeComment.required"
                class="form-error-message"
              >
                Comment is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Transaction Type</label>
              <multiselect
                v-model="formBody.disputeTrnType"
                :options="disputeTrnTypeOptions"
                placeholder="Transaction Type"
                @close="$v.formBody.disputeTrnType.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.formBody.disputeTrnType.$error">
              <p
                v-if="!$v.formBody.disputeTrnType.required"
                class="form-error-message"
              >
                Transaction Type is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="formBody.disputeSessionId"
              placeholder="Session Id"
              :hasPreIcon="false"
              :label="'Session Id'"
              :class="{
                error: $v.formBody.disputeSessionId.$error
              }"
              @blur="$v.formBody.disputeSessionId.$touch()"
            >
            </BaseInput>

            <template v-if="$v.formBody.disputeSessionId.$error">
              <p
                v-if="!$v.formBody.disputeSessionId.required"
                class="form-error-message"
              >
                Session Id is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Dispute Bearer</label>
              <multiselect
                v-model="formBody.disputeBearer"
                :options="disputeBearerOptions"
                placeholder="Bearer"
                @close="$v.formBody.disputeBearer.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.formBody.disputeBearer.$error">
              <p
                v-if="!$v.formBody.disputeBearer.required"
                class="form-error-message"
              >
                Bearer is required
              </p>
            </template>
          </div>
        </div>

      </div>
      <p class="text-right">
        <span v-if="loader.actionButtonLoader" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Create Dispute
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "CreateDisputeForm",
  data() {
    return {
      disputeIssueTypeOptions: [
        "Pending Settlement",
        "Settlement Reversal",
        "*Other*"
      ],
      disputeTrnTypeOptions: ["CARD", "INWARD", "OUTWARD"],
      disputeBearerOptions: ["BANK", "POWERBANK"],
      formBody: this.createFreshFormBodyObject()
    };
  },
  computed: {
    ...mapState(["loader"])
  },
  validations: {
    formBody: {
      disputeComment: { required },
      disputeIssueType: { required },
      disputeSessionId: { required },
      disputeBearer: { required },
      disputeTrnType: { required }
    }
  },
  methods: {
    // sola(){
    // },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit(
          StoreUtils.mutations.form.BUILD_FORM_BODY,
          this.formBody
        );
        StoreUtils.dispatch("dispute/createDispute", this.formBody)
          .then(() => {
            this.formBody = {};
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped></style>
