<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-10">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.searchItem"
                placeholder="Customer Name, Account, etc.."
                :hasPreIcon="false"
                :label="'Search Customer'"
                :class="{
                  error: $v.formBody.searchItem.$error
                }"
                @blur="$v.formBody.searchItem.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.searchItem.$error">
                <p
                  v-if="!$v.formBody.searchItem.required"
                  class="form-error-message"
                >
                  Type something to Search for
                </p>
              </template>
            </div>
          </div>
          <div class="col-2">
            <p class="text-right">
              <span v-if="customer.moduleLoadingThree" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else class="d-block">
                <button class="btn btn-primary btn-semi-rounded table-search-btn">
                  <img
                    src="https://assets-base.s3.amazonaws.com/img/powerbank/search.png"
                    alt="search"
                  />
                  Search
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "SearchCustomerForm",
  data() {
    return {
      formBody: {
        searchItem: ""
      }
    };
  },
  computed: {
    ...mapState(["loader", "customer"])
  },
  validations: {
    formBody: {
      searchItem: { required }
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let payload = {
          customerBankCode: StoreUtils.rootGetter(
            StoreUtils.getters.user.GET_USER_BANK_CODE
          ),
          readType: "search",
          searchItem: this.formBody.searchItem
        };

        this.$store.dispatch("customer/allCustomers", payload, {
          root: true
        });
      }
    },
    createFreshFormBodyObject() {
      return {
        searchItem: ""
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
