<template>
  <div class="form-group">
    <!--    <label class="control-label">Category</label>-->
    <label v-if="label">{{ label }}</label>
    <multiselect
      v-model="selectedOption"
      :options="options"
      @input="updateValue"
      :trackBy="'label'"
      :placeholder="placeholder"
      :allowEmpty="false"
      :value="value"
      :close-on-select="true"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number]
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      selectedOption: ""
    };
  },
  methods: {
    updateValue() {
      this.$emit("input", this.selectedOption);
    }
  }
};
</script>

<style scoped></style>
