<template>
  <div>
    <!--        control-->
    <button
      @click="openModal()"
      type="button"
      class="btn btn-outline-pink btn-sm table-action-lg"
    >
      Edit Customer
    </button>
    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="400"
      :height="440"
      measure="px"
      animation="zoom"
      @hide="modalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Add Sub Account</h4>

          <AddSubAccountForm :currentCustomer="currentCustomer" @close-add-sub-account-modal="closeAddSubAccountModal()" />
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
export default {
  name: "AddSubAccountModal",
  data() {
    return {
      modalOpen: false
    };
  },
  props: {
    currentCustomer: {
      type: Object,
      required: true
    }
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeAddSubAccountModal() {
      this.modalOpen = false;
    }
  }
};
</script>

<style scoped></style>
