<template>
  <div class="row">
    <div class="col-sm-6">
      <p class="queue-title">Batch Name</p>
      <p class="queue-value">
        {{ journal.currentBatch.batchName }}
      </p>
    </div>

    <div class="col-sm-6">
      <p class="queue-title">Batch Reference</p>
      <p class="queue-value">
        {{ journal.currentBatch.batchReference }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BatchInfoCard",
  computed: {
    ...mapState(["journal"])
  }
};
</script>

<style scoped>
.queue-title {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  margin-top: 20px;
  color: #9e9e9e;
}

.queue-value {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 35px;
  color: #2a3042;
}
</style>
