<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template v-slot:cell(terminalStatus)="data">
        <app-status-badge
          :status-value="data.item.terminalStatus"
        ></app-status-badge>
      </template>

      <!-- A custom formatted column -->
      <template v-slot:cell(customerName)="data">
        <p class="name-field customer-name">
          <span class="actual-name"
            >{{
              `${data.item.customerFirstName} ${data.item.customerMiddleName} ${data.item.customerLastName}`
            }}
          </span>
        </p>
      </template>

      <template v-slot:cell(action)="data"> </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "AllAssignedTerminalsTable",
  components: {},
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        { key: "terminalId", label: "Terminal Id", sortable: true },
        { key: "terminalSerial", label: "Serial", sortable: true },
        { key: "terminalStatus", label: "Status", sortable: true },
        {
          key: "terminalAccountNumber",
          label: "Terminal AccountNumber",
          sortable: true
        },
        { key: "customerName", label: "Customer Name", sortable: true },
        { key: "customerEmail", label: "Customer Email", sortable: true },
        { key: "customerPhoneNumber", label: "Customer Phone", sortable: true },
        { key: "terminalCreatedAt", label: "Created At", sortable: true },
        { key: "terminalUpdatedAt", label: "Updated At", sortable: true },
        { key: "terminalTimeout", label: "Timeout", sortable: true },
        { key: "terminalAddress", label: "Address", sortable: true },
      ],

      json_fields: {
        termId: "termId",
        terminalId: "terminalId",
        terminalSerial: "terminalSerial",
        terminalAddress: "terminalAddress",
        terminalTimeout: "terminalTimeout",
        terminalCardAcceptorId: "terminalCardAcceptorId",
        terminalBankCode: "terminalBankCode",
        terminalStatus: "terminalStatus",
        terminalCustomerId: "terminalCustomerId",
        terminalAccountNumber: "terminalAccountNumber",
        customerEmail: "customerEmail",
        customerFirstName: "customerFirstName",
        customerMiddleName: "customerMiddleName",
        customerLastName: "customerLastName",
        customerPhoneNumber: "customerPhoneNumber",
        terminalCreatedAt: "terminalCreatedAt",
        terminalUpdatedAt: "terminalUpdatedAt"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.table.tableData,
      isBusy: state => state.loader.tableLoader
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    fetchTableData() {
      StoreUtils.dispatch("terminal/fetchAllAssignedTerminals");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  created() {
    this.fetchTableData();
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
