<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Dashboard</h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Welcome to Power Bank</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-3">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  <!--                  <h5 class="text-primary">Power Bank</h5>-->

                  <!--                  <a @click="logAuth()" class="btn btn-secondary mr-1">-->
                  <!--                    Log Auth-->
                  <!--                  </a>-->

                  <h4 class="card-title mt-2 mb-4">
                    {{
                      getBankNameFromIdUtility(user.currentUser.userBankCode)
                    }}
                    ({{ user.currentUser.userBranchName }})
                  </h4>
                  <!--                  <p class="card-title-desc">-->
                  <!--                    {{ user.currentUser.userBankCode }}-->
                  <!--                  </p>-->
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  :src="getBankLogoFromIdUtility(user.currentUser.userBankCode)"
                  class="dash-bank-logo img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="avatar-md profile-user-wid mb-4">
                  <div class="avatar-md img-thumbnail">
                    <span class="avatar-title rounded-circle">{{
                      (user.currentUser.userFirstName +
                        " " +
                        user.currentUser.userLastName)
                        | firstCaseCapital
                        | getInitials
                    }}</span>
                  </div>
                </div>
                <h5 class="font-size-15 text-truncate">
                  {{
                    (user.currentUser.userFirstName +
                      " " +
                      user.currentUser.userLastName)
                      | firstCaseCapital
                  }}
                </h5>
                <p class="text-muted mb-2 text-truncate">
                  {{ user.currentUser.role.roleName }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="card">-->
        <!--          <div class="card-body">-->
        <!--            <h4 class="card-title mb-4">Total Balance</h4>-->
        <!--            <div class="row">-->
        <!--              <div class="col-sm-12">-->
        <!--                <h3>₦34,252</h3>-->
        <!--                <p class="text-muted">-->
        <!--                  <span class="text-success mr-2">-->
        <!--                    12% <i class="mdi mdi-arrow-up" />-->
        <!--                  </span>-->
        <!--                  From previous yesterday-->
        <!--                </p>-->
        <!--                <div class="mt-4 mb-2">-->
        <!--                  <a href="javascript: void(0);" class="btn btn-primary btn-sm">-->
        <!--                    View Transactions <i class="mdi mdi-arrow-right ml-1"-->
        <!--                  /></a>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Transaction Volume</h4>
            <!-- Line with Data Labels chart -->
            <SampleLineChart />
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Transaction Count</h4>
            <!-- Line with Data Labels chart -->
            <SampleLineChart :height="450" />
          </div>
        </div>
      </div>

<!--      <div class="col-3">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <TransactionVolumePieChart :height="330" />-->
<!--            <p class="card-title-sub mt-2 mb-0">Yearly Transaction Volume</p>-->
<!--          </div>-->
<!--        </div>-->

<!--&lt;!&ndash;        <div class="card">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="card-body">&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash;            <h4 class="card-title mb-4">Yearly Transaction Count</h4>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            <TransactionVolumePieChart :height="330" />&ndash;&gt;-->
<!--&lt;!&ndash;            <p class="card-title-sub mt-2 mb-0">Yearly Transaction Count</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Success - Failed Transaction Count</h4>
            <!-- Line with Data Labels chart -->
            <SuccessFailTransactionBarChart :height="450" />
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="row">-->
    <!--      <div class="col-12">-->
    <!--        <ApexDashboardCharts />-->
    <!--      </div>-->
    <!--      &lt;!&ndash; end col &ndash;&gt;-->
    <!--      <div class="col-12">-->
    <!--        <ChartistDashboardCharts />-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../router/layouts/main";
import { mapState, mapGetters } from "vuex";
// import {linewithDataChart} from "../components/Kleyton/DashboardCharts/data-apex";

const lineChartData = {
  chartOptions: {
    chart: {
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ["#556ee6", "#34c38f"],
    dataLabels: {
      enabled: true
    },
    stroke: {
      width: [3, 3],
      curve: "straight"
    },
    title: {
      text: "inflow from cash and teller",
      align: "left"
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2
      },
      borderColor: "#f1f1f1"
    },
    markers: {
      style: "inverted",
      size: 6
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month"
      }
    },
    yaxis: {
      title: {
        text: "Temperature"
      },
      min: 5,
      max: 40
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false
            }
          },
          legend: {
            show: false
          }
        }
      }
    ]
  },
  series: [
    {
      name: "Cash",
      data: [26, 24, 32, 36, 33, 31, 33]
    },
    {
      name: "Teller",
      data: [14, 11, 16, 12, 17, 13, 12]
    }
  ]
};

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: window.__env.app.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Dashboard",
      statData: [
        {
          icon: "bx bxs-bank",
          title: "Total Banks",
          value: "23"
        },
        {
          icon: "bx bx-git-branch",
          title: "Total Branches",
          value: "203"
        },
        {
          icon: "bx bxs-business",
          title: "Total Customers",
          value: "8"
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Total Transactions",
          value: "213"
        }
      ],

      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6
        }
      ],
      linewithDataChart: lineChartData
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      getBankNameFromIdUtility: "appUtilities/getBankNameFromCodeUtility",
      getBankLogoFromIdUtility: "appUtilities/getBankLogoFromCodeUtility"
    })
  },
  methods: {
    logAuth() {
      //   "Token =>",
      //   StoreUtils.rootGetter("user/getUserTokenFromLocalStorage")
      // );
    }
    // getBankFromIdUtility(bankCode) {
    //   return this.$store.getters["appUtilities/getBankNameFromCodeUtility"](
    //     bankCode
    //   );
    // }
  }
};
</script>

<style scoped>
.card-title-sub {
  font-weight: 700;
  color: #757575;
}
.dash-bank-logo {
  margin: 5px 5px 5px 5px;
  height: 70px;
  width: auto;
}
</style>
