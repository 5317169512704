<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submitForm">
        <div class="stage-info text-left mb-3">
          <div class="row">
            <div class="col-12">
              <h4 class="main card-title mb-4">
                <span class="no">2.</span>
                Report Start Table
              </h4>
              <p class="sub">
                <code>Report Start Table</code> refers to the table you want to
                stat the report creation from. <br />
                You can choose a Table or an existing Report.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Table</label>
                <multiselect
                  v-model="formBody.tableType"
                  :options="reportTableTypesOption"
                  @close="$v.formBody.tableType.$touch()"
                  placeholder="Start Table Type"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.tableType.$error">
                <p
                  v-if="!$v.formBody.tableType.required"
                  class="form-error-message"
                >
                  Table Type is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div v-if="formBody.tableType.toLowerCase() === 'table'">
              <div class="form-group">
                <label>Start Table</label>
                <multiselect
                  v-model="formBody.reportTable"
                  :options="tableListOption"
                  @close="$v.formBody.reportTable.$touch()"
                  placeholder="Select Table"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.reportTable.$error">
                <p
                  v-if="!$v.formBody.reportTable.required"
                  class="form-error-message"
                >
                  Please select a Table
                </p>
              </template>
            </div>

            <div v-if="formBody.tableType.toLowerCase() === 'report'">
              <div class="form-group">
                <label>Start Table</label>
                <multiselect
                  v-model="formBody.reportTable"
                  :options="reportListOption"
                  @close="$v.formBody.reportTable.$touch()"
                  placeholder="Select Report"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.reportTable.$error">
                <p
                  v-if="!$v.formBody.reportTable.required"
                  class="form-error-message"
                >
                  Please select a Report
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button
                  @click="goBackTo(rptStage - 1)"
                  class="btn btn-secondary mr-1"
                >
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Next
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../../utils/BaseUtils/StoreUtils";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ReportBaseForm",
  data() {
    return {
      formBody: this.freshFormBodyObject(),
      counterPartyStatus: "self"
    };
  },
  computed: {
    ...mapState(["rpt", "loader"]),
    reportTableTypesOption() {
      return StoreUtils.rootGetter("rptUtilities/getReportTableTypesOption");
    },
    tableListOption() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rptUtilities.GET_TABLE_LIST_OPTION
      );
    },
    reportListOption() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.rptUtilities.GET_REPORT_LIST_OPTION
      );
    },
    rptStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE);
    },
    reportList() {
      return StoreUtils.rootGetter("rptUtilities/getReportList");
    },
    reportCategory() {
      return StoreUtils.rootGetter("rpt/getReportCategory");
    }
  },
  validations: {
    formBody: {
      tableType: { required },
      reportTable: { required }
    }
  },
  created() {
    StoreUtils.dispatch(StoreUtils.actions.rptUtilities.FETCH_REPORT_LIST, {
      reportCategory: this.reportCategory
    });
    StoreUtils.dispatch(StoreUtils.actions.rptUtilities.FETCH_TABLE_LIST);
  },
  methods: {
    goBackTo(rptStage) {
      StoreUtils.dispatch(StoreUtils.actions.rpt.GO_BACK_TO_STAGE, rptStage);
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.dispatch(
          StoreUtils.actions.rpt.BUILD_CREATE_REPORT_REQUEST_BODY,
          this.formBody
        );
      }
    },

    freshFormBodyObject() {
      return {
        source: "CBA",
        tableType: ""
      };
    }
  }
};
</script>

<style scoped></style>
