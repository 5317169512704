<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model.trim="formBody.customerBusinessName"
                placeholder="Business Name"
                :hasPreIcon="false"
                :label="'Business Name'"
                :class="{
                  error: $v.formBody.customerBusinessName.$error
                }"
                @blur="trimTheBusinessName"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerBusinessName.$error">
                <p
                  v-if="!$v.formBody.customerBusinessName.required"
                  class="form-error-message"
                >
                  Business Name is required.
                </p>
                <!--                <p-->
                <!--                  v-if="!$v.formBody.customerBusinessName.twoWords"-->
                <!--                  class="form-error-message"-->
                <!--                >-->
                <!--                  Business Name must be at least two words.-->
                <!--                </p>-->
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <label>Company Type</label>
              <multiselect
                v-model="formBody.customerCompanyType"
                :options="customerCompanyTypeOptions"
                placeholder="Company Type"
                @close="$v.formBody.customerCompanyType.$touch()"
              ></multiselect>

              <template v-if="$v.formBody.customerCompanyType.$error">
                <p
                  v-if="!$v.formBody.customerCompanyType.required"
                  class="form-error-message"
                >
                  Company type is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Date of Incorporation</label>
                <date-picker
                  v-model="formBody.customerIncorporationDate"
                  placeholder="Date of Incorporation"
                  :first-day-of-week="1"
                  lang="en"
                  confirm
                ></date-picker>
              </div>

              <template v-if="$v.formBody.customerIncorporationDate.$error">
                <p
                  v-if="!$v.formBody.customerIncorporationDate.required"
                  class="form-error-message"
                >
                  Date of Incorporation is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerIncorporationNumber"
                placeholder="Incorporation Number"
                :hasPreIcon="false"
                :label="'Incorporation Number'"
                :class="{
                  error: $v.formBody.customerIncorporationNumber.$error
                }"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerIncorporationNumber.$error">
                <p
                  v-if="!$v.formBody.customerIncorporationNumber.required"
                  class="form-error-message"
                >
                  Incorporation Number is required
                </p>
                <p
                  v-if="!$v.formBody.customerIncorporationNumber.alphaNum"
                  class="form-error-message"
                >
                  Incorporation Number can only be Numbers and Alphabets
                </p>
                <!--                <p-->
                <!--                  v-if="!$v.formBody.customerIncorporationNumber.minLength"-->
                <!--                  class="form-error-message"-->
                <!--                >-->
                <!--                  Incorporation Number must be 12 numbers minimum-->
                <!--                </p>-->
                <!--                <p-->
                <!--                  v-if="!$v.formBody.customerIncorporationNumber.maxLength"-->
                <!--                  class="form-error-message"-->
                <!--                >-->
                <!--                  Incorporation Number must be 12 numbers maximum-->
                <!--                </p>-->
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'email'"
                v-model="formBody.customerEmail"
                placeholder="Email"
                :hasPreIcon="false"
                :label="'Email'"
                :class="{
                  error: $v.formBody.customerEmail.$error
                }"
                @blur="$v.formBody.customerEmail.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerEmail.$error">
                <p
                  v-if="!$v.formBody.customerEmail.required"
                  class="form-error-message"
                >
                  Email is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Currency</label>
                <multiselect
                  v-model="formBody.accountCurrency"
                  :options="accountCurrencyOptions"
                  @close="$v.formBody.accountCurrency.$touch()"
                  placeholder="Account Currency"
                ></multiselect>
              </div>
              <!--                          error-->
              <template v-if="$v.formBody.accountCurrency.$error">
                <p
                  v-if="!$v.formBody.accountCurrency.required"
                  class="form-error-message"
                >
                  Account Currency is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button @click="goBack" class="btn btn-secondary mr-1">
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span>
                <button type="submit" class="btn btn-primary mr-1">
                  NEXT
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { alphaNum, required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
// const twoWords = helpers.regex("alpha", /^[a-z]+(?: [a-z]+)+$/);

export default {
  name: "CreateCustomerAccountSecondStageForm",
  data() {
    return {
      // customerCompanyTypeOptions: ["FINTECH", "OTHERS"],

      formBody: this.createFreshFormBodyObject()
    };
  },
  components: {
    Multiselect,
    DatePicker
  },
  computed: {
    ...mapState(["appUtilities", "loader"]),
    ...mapGetters({
      accountCurrencyOptions: "appUtilities/getCurrencyUtilityOption"
    }),
    currentFormBody() {
      return StoreUtils.rootGetter("form/getFormBody");
    },
    customerCompanyTypeOptions() {
      return StoreUtils.rootGetter("appUtilities/getBusinessTypes");
    }
  },
  validations: {
    formBody: {
      customerBusinessName: { required },
      customerCompanyType: { required },
      customerIncorporationNumber: {
        required,
        alphaNum
      },
      customerIncorporationDate: { required },
      customerEmail: { required },
      accountCurrency: { required }
    }
  },

  created() {
    StoreUtils.dispatch("appUtilities/fetchBusinessTypes");
  },

  methods: {
    trimTheBusinessName() {
      this.formBody.customerBusinessName.trim();
      this.$v.formBody.customerBusinessName.$touch();
    },
    goBack() {
      StoreUtils.commit("form/SET_FORM_STAGE_TO", 0);
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit("form/BUILD_FORM_BODY", this.formBody);
        StoreUtils.commit("form/SET_FORM_STAGE_TO", 3);
      }
    },
    createFreshFormBodyObject() {
      return {
        customerBvn: "",
        customerDob: "",
        customerBusinessName: "",
        customerIncorporationNumber: "",
        customerIncorporationDate: "",
        customerCompanyType: ""
      };
    }
  }
};
</script>

<style scoped></style>
