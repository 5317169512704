<template>
  <div class="row">
    <div v-for="stat of statData" :key="stat.icon" class="col-12">
      <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
    </div>
  </div>
</template>

<script>
import Stat from "../../../components/widgets/stat";

export default {
  name: "SampleStatsPanel",
  props: {
    statData: {
      type: Array,
      required: true
    }
  },
  components: {
    Stat
  }
};
</script>

<style scoped></style>
