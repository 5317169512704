import BaseService from "./BaseService";
import UrlUtils from "../../utils/BaseUtils/UrlUtils";

class SwitchTransactionService extends BaseService {
  fetchAllNipInwards(
    payload,
    successAction,
    loaderType,
    errorType,
    showSuccessMessage,
    successCondition,
    getErrorMessage
  ) {
    return this.makePostRequest(
      UrlUtils.urls.switchTransaction.FETCH_ALL_NIP_INWARDS,
      payload,
      successAction,
      loaderType,
      errorType,
      showSuccessMessage,
      successCondition,
      getErrorMessage
    );
  }

}

export default SwitchTransactionService;
