<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  mounted() {
    this.renderChart(
      {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        datasets: [
          {
            label: "Successful Transactions",
            backgroundColor: "rgba(90, 193, 142, 0.8)",
            borderColor: "rgba(90, 193, 142, 0.8)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(90, 193, 142, 0.9)",
            hoverBorderColor: "rgba(90, 193, 142, 0.9)",
            data: [65, 59, 81, 45, 56, 80, 50, 20, 56, 80, 50, 20]
          },
          {
            label: "Failed Transactions",
            backgroundColor: "rgba(255, 70, 70, 0.8)",
            borderColor: "rgba(255, 70, 7, 0.8)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255, 70, 70, 0.9)",
            hoverBorderColor: "rgba(255, 70, 70, 0.9)",
            data: [65, 59, 81, 45, 65, 59, 81, 45, 56, 56, 80, 50]
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(166, 176, 207, 0.1)"
              },
              barPercentage: 0.9
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(166, 176, 207, 0.1)"
              }
            }
          ]
        }
      }
    );
  }
};
</script>
