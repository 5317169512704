<template>
  <div>
    <!--    <button @click="sola">test Getter</button>-->
    <form @submit.prevent="submit">
      <div class="row">
        <div class="col-sm-12">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="formBody.disputeRemark"
              :hasPreIcon="false"
              :label="'Add remark'"
              :class="{
                error: $v.formBody.disputeRemark.$error
              }"
              @blur="$v.formBody.disputeRemark.$touch()"
            >
            </BaseInput>

            <template v-if="$v.formBody.disputeRemark.$error">
              <p
                v-if="!$v.formBody.disputeRemark.required"
                class="form-error-message"
              >
                Remark is required
              </p>
            </template>
          </div>
        </div>
      </div>
      <p class="text-right">
        <span v-if="loader.actionButtonLoader" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Submit
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
// import RouterUtils from "@/utils/BaseUtils/RouterUtils";

export default {
  name: "UpdateDisputeStatusForm",
  props: {
    currentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formBody: {
        disputeRemark: ""
      }
    };
  },
  computed: {
    ...mapState(["loader"]),
    newDisputeStatus() {
      return StoreUtils.rootGetter("dispute/getCurrentDisputeStatus");
    },
    currentId() {
      return StoreUtils.rootGetter("table/getCurrentTableObject").disputeId;
    }
  },
  validations: {
    formBody: {
      disputeRemark: { required }
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit(StoreUtils.mutations.form.BUILD_FORM_BODY, {
          ...this.formBody
        });
        if (this.newDisputeStatus === "REOPEN") {
          StoreUtils.dispatch("dispute/reopenDispute")

            .then(responseData => {
              if (responseData.responseCode === "00") {
                this.$emit("statusUpdateSuccessful", responseData);
              }
              this.$v.$reset();
            })
            .catch(() => {});
        } else {
          StoreUtils.dispatch("dispute/updateDisputeStatus", {
            newDisputeStatus: this.newDisputeStatus
          })

            .then(responseData => {
              if (responseData.responseCode === "00") {
                this.$emit("statusUpdateSuccessful", responseData);
              }
              this.$v.$reset();
            })
            .catch(() => {});
        }
      }
    },
    createFreshFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped></style>
