<template>
  <div>
    <form @submit.prevent="submit()">
      <div class="row">
        <div class="col-sm-12 mb-3">
          <div>
            <div class="">
              <label>CSV File</label>

              <div class="custom-file">
                <input
                  id="csvFile"
                  type="file"
                  placeholder="Customer Picture"
                  class="custom-file-input"
                  required
                  @change="updateSelectedFile"
                />
                <label class="custom-file-label" for="csvFile">
                  {{ selectedFile }}</label
                >
              </div>
            </div>

            <template v-if="$v.formBody.csvFile.$error">
              <p
                v-if="!$v.formBody.csvFile.required"
                class="form-error-message"
              >
                A CSV File is required.
              </p>
            </template>
          </div>
        </div>
      </div>

      <p class="text-right">
        <span v-if="loader.actionButtonLoader" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Upload CSV
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import AppUtils from "../../../../utils/BaseUtils/AppUtils";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../../../utils/BaseUtils/LoaderUtils";
import RouterUtils from "../../../../utils/BaseUtils/RouterUtils";

export default {
  name: "BulkUploadForm",
  data() {
    return {
      formBody: this.createFreshFormBodyObject(),
      selectedFile: "Choose CSV file"
    };
  },
  computed: {
    ...mapState(["loader"])
  },
  validations: {
    formBody: {
      csvFile: { required }
    }
  },
  created() {
    StoreUtils.commit(
      "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES",
      []
    );
  },
  methods: {
    updateSelectedFile() {
      let files = document.getElementById("csvFile").files;
      this.selectedFile = files[0].name;
      this.formBody.csvFile = files[0].name;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let files = document.getElementById("csvFile").files;
        LoaderUtils.showLoading(true, LoaderUtils.types.TABLE);
        let doneAction = csvJson => {
          LoaderUtils.showLoading(false, LoaderUtils.types.TABLE);
          StoreUtils.commit(
            "accountingEntry/SET_CURRENT_ACCOUNTING_ENTRY_ENTRIES",
            csvJson
          );
        };
        let utilsResponse = AppUtils.convertCsvToJson(files, doneAction);

        if (utilsResponse.responseCode === "00") {
          RouterUtils.changeRouteTo(
            RouterUtils.routes.journal.EDIT_BATCH_ENTRIES
          );
        }
      }
    },
    createFreshFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
