<template>
  <div>
    <!--        control-->
    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
      <template v-slot:button-content>
        <i class="mdi mdi-dots-horizontal font-size-18" />
      </template>

      <b-dropdown-item @click="openModal(currentData)">
        <i class="fas fa-money-bill text-success mr-1" /> Top Up
      </b-dropdown-item>
      <b-dropdown-item @click="openLiquidateModal(currentData)">
        <i class="fas fa-water text-success mr-1" /> Liquidate
      </b-dropdown-item>
    </b-dropdown>
    <!--        Top Up modal-->
    <vodal
      :show="modalOpen"
      :width="440"
      :height="440"
      measure="px"
      animation="zoom"
      @hide="modalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Top Up Fixed Deposit</h4>

          <TopUpFixedForm
            :currentData="currentData"
            @topUpSuccessful="closeModal"
          />
        </div>
      </div>
    </vodal>

    <!--        Liquidate modal-->
    <vodal
      :show="liquidateModalOpen"
      :width="440"
      :height="440"
      measure="px"
      animation="zoom"
      @hide="liquidateModalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Liquidate Fixed Deposit</h4>

          <LiquidateFixedInvestmentForm
            @liquidateSuccessful="closeLiquidateModal"
          />
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import TopUpFixedForm from "@/components/Kleyton/Forms/Investment/FixedDeposit/TopUpFixedForm";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import LiquidateFixedInvestmentForm from "@/components/Kleyton/Forms/Investment/FixedDeposit/LiquidateFixedInvestmentForm";
export default {
  name: "AllFixedDepositsTableActionModal",
  components: { LiquidateFixedInvestmentForm, TopUpFixedForm },
  data() {
    return {
      modalOpen: false,
      liquidateModalOpen: false
    };
  },
  props: {
    currentData: {
      type: Object,
      required: true
    }
  },
  created() {
    StoreUtils.dispatch("appUtilities/fetchAllFundsTransferProduct");
  },
  methods: {
    openModal(data) {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_CURRENT_TABLE_OBJECT,
        data
      );
      StoreUtils.dispatch("appUtilities/fetchQueryTransactionHistory", {
        customerId: this.currentData.accountCustomerId
      });
      this.modalOpen = true;
    },
    closeModal() {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_CURRENT_TABLE_OBJECT,
        {}
      );

      this.modalOpen = false;
    },
    openLiquidateModal(data) {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_CURRENT_TABLE_OBJECT,
        data
      );
      this.liquidateModalOpen = true;
    },
    closeLiquidateModal() {
      StoreUtils.commit(
        StoreUtils.mutations.table.SET_CURRENT_TABLE_OBJECT,
        {}
      );

      this.liquidateModalOpen = false;
    }
  }
};
</script>

<style scoped></style>
