<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <BatchInfoCard />
            <!-- Supposed Form -->
            <div class="form-body">
              <AllBatchEntriesTable />
            </div>

            <CloseBatchCard />
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import AllBatchEntriesTable from "../../components/Kleyton/Tables/Journal/AllBatchEntriesTable";
import BatchInfoCard from "../../components/Kleyton/Cards/Journal/BatchInfoCard";
import CloseBatchCard from "../../components/Kleyton/Cards/Journal/CloseBatchCard";

export default {
  name: "AllBatchEntries",
  page: {
    title: "All Batch Entries",
    meta: [{ name: "All Batch Entries", content: window.__env.app.description }]
  },
  components: {
    CloseBatchCard,
    BatchInfoCard,
    AllBatchEntriesTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "All Batch Entries",

      items: [
        {
          text: "Batch Entries",
          href: "/"
        },
        {
          text: "All Batch Entries",
          active: true
        }
      ]
    };
  },
  methods: {}
};
</script>

<style scoped></style>
