<template>
  <div class="table-section">
    <div class="row">
      <div class="col-12">
        <SearchCustomerForm />
      </div>
    </div>
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Filter"
              :inputType="'text'"
              v-model="filter"
              placeholder="Filter through result..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-secondary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <!-- A virtual composite column -->
      <template v-slot:cell(customerBankCode)="data">
        <p class="name-field">
          <span>{{ data.item.customerBankCode }}</span>
        </p>
      </template>
      <!-- A custom formatted column -->
      <template v-slot:cell(customerBranchCode)="data">
        <p class="name-field">
          <span>{{ data.item.customerBranchCode }}</span>
        </p>
      </template>
      <!-- A custom formatted column -->
      <template v-slot:cell(customerName)="data">
        <p class="name-field customer-name">
          <span class="view-more-info" >
            <ViewCustomerInfoModal :currentCustomer="data.item" ref="childRef" />
          </span>
          <span class="actual-name">{{ data.item.customerName }}</span>
        </p>
      </template>
      <template v-slot:cell(customerEmail)="data">
        <p class="name-field">
          <span>{{ data.item.customerEmail }}</span>
        </p>
      </template>
      <template v-slot:cell(customerPhoneNumber)="data">
        <p class="name-field">
          <span>{{ data.item.customerPhoneNumber }}</span>
        </p>
      </template>
      <template v-slot:cell(action)="data">
        <p class="name-field">
          <!--          <AddSubAccountModal :currentCustomer="data.item" />-->
          <AllCustomerTableActionModal :currentCustomer="data.item" />
        </p>
      </template>
      <!-- A virtual composite column -->
      <template v-slot:cell(customerStatus)="data">
        <p class="name-field">
          <span
            v-if="data.item.customerStatus === 'ACTIVE'"
            class="badge badge-pill badge-soft-success font-size-12"
            >{{ data.item.customerStatus }}</span
          >
          <span
            v-if="data.item.customerStatus === 'INACTIVE'"
            class="badge badge-pill badge-soft-danger font-size-12"
            >{{ data.item.customerStatus }}</span
          >
          <span
            v-if="data.item.customerStatus === 'PENDING'"
            class="badge badge-pill badge-soft-warning font-size-12"
            >{{ data.item.customerStatus }}</span
          >
        </p>
      </template>
      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>
    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ViewCustomerInfoModal from "../../Modals/Customer/ViewCustomerInfoModal";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import SearchCustomerForm from "../../Forms/Customer/SearchCustomerForm";
import AllCustomerTableActionModal from "@/components/Kleyton/Modals/Customer/AllCustomerTableActionModal";
export default {
  name: "AllCustomerTable",
  components: {
    AllCustomerTableActionModal,
    SearchCustomerForm,
    ViewCustomerInfoModal,
  },
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },
        { key: "customerId", label: "CustomerId", sortable: true },
        { key: "customerBranchCode", label: "Branchcode", sortable: true },
        // {key: "customerId", label: "Customer Id", sortable: true},
        { key: "customerName", label: "Name", sortable: true },
        { key: "customerEmail", label: "Email", sortable: true },
        { key: "customerPhoneNumber", label: "Phonenumber", sortable: true },
        { key: "customerBvn", label: "BVN", sortable: true },
        // {key: "customerAddress", label: "Address", sortable: true},
        { key: "customerState", label: "State", sortable: true },
        { key: "customerCountry", label: "Country", sortable: true },
        { key: "customerStatus", label: "Status", sortable: true },
        { key: "action", label: "Action", sortable: false },
        // { key: "action", label: "Action", sortable: false }
      ],
      json_fields: {
        customerAddress: "customerAddress",
        customerAge: "customerAge",
        customerState: "customerState",
        customerPushId: "customerPushId",
        customerUpdatedDate: "customerUpdatedDate",
        customerBankCode: "customerBankCode",
        customerDeviceId: "customerDeviceId",
        customerName: "customerName",
        customerCountry: "customerCountry",
        customerMiddleName: "customerMiddleName",
        customerStatus: "customerStatus",
        customerCountryAddress: "customerCountryAddress",
        customerFirstName: "customerFirstName",
        customerEmail: "customerEmail",
        custId: "custId",
        customerId: "customerId",
        customerDob: "customerDob",
        customerLastName: "customerLastName",
        customerBranchId: "customerBranchId",
        customerBvn: "customerBvn",
        customerPhoneNumber: "customerPhoneNumber",
        customerCreatedDate: "customerCreatedDate",
        customerBranchCode: "customerBranchCode"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.customer.customers,
      isBusy: state => state.customer.moduleLoadingThree
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    change() {
      //console.log("law");
    },
    callChildMethod() {
      this.$refs.childRef.childMethod();
    },
    returnItems() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;
        return initials;
      } else {
        return initials.substring(0, 2);
      }
    },
    queryReport() {
      StoreUtils.dispatch("rpt/queryReport", this.currentReportName);
    }
  },
  created() {
    const payload = {
      customerBankCode: this.$store.getters["user/getUserBankCode"]
    };
    this.$store.dispatch("customer/allCustomers", payload, { root: true });
  }
};
</script>
<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
.view-more-info {
  margin-right: 2px;
  display: inline-block;
  color: #ffffff;
}
.view-more-info a {
  cursor: pointer;
}
.view-more-info a i {
  cursor: pointer;
}
.actual-name {
  margin-left: 2px;
  line-height: 15px;
}
</style>
