<template>
  <div>
    <div v-if="bank.moduleLoadingTwo">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <SampleStatsPanel :statData="bank.bankStatData" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BankStatsPanel",
  computed: {
    ...mapState(["bank"])
  },
  created() {
    this.fetchBankStatsData();
  },
  methods: {
    fetchBankStatsData() {
      this.$store.dispatch("bank/fetchBankStatsPanelData", null, {
        root: false
      });
    }
  }
};
</script>

<style scoped></style>
