<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">All Terminal Batches</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <AllBatchesTable />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Create Bank Component
 */

import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import AllBatchesTable from "../../components/Kleyton/Tables/Terminal/AllBatchesTable";

export default {
  page: {
    title: "All Terminal Batches",
    meta: [{ name: "Terminal", content: window.__env.app.description }]
  },
  components: {
    AllBatchesTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "All Terminal Batchess",

      items: [
        {
          text: "Terminal",
          href: "/"
        },
        {
          text: "All Batches",
          active: true
        }
      ]
    };
  },
  methods: {}
};
</script>
