import BankUserService from "../../services/oldServices/BankUserService";
import NewBankUserService from "../../services/newServices/BankUserService";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import NotificationUtils from "../../utils/BaseUtils/NotificationUtils";
const newBankUserService = new NewBankUserService();

const getDefaultState = () => {
  return {
    users: [],
    moduleLoading: false,
    moduleLoadingTwo: true,
    moduleLoadingThree: true,
    moduleLoadingFour: true,
    moduleLoadingFive: false,
    allUsersCheckersQueue: []
  };
};

export const state = getDefaultState();

export const getters = {
  // returns the current user.
  getUsers(state) {
    return state.users;
  }
};

export const mutations = {
  // SET_USER_USERNAME(state, payload) {
  //   state.users = payload;
  // },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_ALL_USERS(state, payload) {
    state.users = payload;
  },
  SET_USERS_CHECKERS_QUEUE(state, payload) {
    state.allUsersCheckersQueue = payload;
  },

  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

export const actions = {
  editUser(undefined, payload) {
    const successAction = () => {
      let fetchPayload = {
        userBankCode: StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BANK_CODE
        ),
        source: "CBA",
        readAll: "YES"
      };
      StoreUtils.dispatch("bankUser/allUsers", fetchPayload);
    };
    return newBankUserService.editUser(
      { ...payload, userCountryCode: "234" },
      successAction,
      LoaderUtils.types.ACTION_BUTTON
    );
  },

  resetUserPassword(undefined, { username }) {
    let payload = {
      username,
      userBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      userCountryCode: "234",
      source: "WEB",
      auditUser: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };
    const successAction = responseData => {
      let notificationModalBody = {
        type: NotificationUtils.type.SUCCESS, //error, success, warning
        mode: NotificationUtils.mode.YES, // default, yes-no
        title: "Success",
        message: responseData.responseMessage,
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "Close",
        yesAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Close"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    };
    return newBankUserService.resetUserPassword(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING
    );
  },

  resetUserPin(undefined, { username }) {
    let payload = {
      username,
      resetType: "PIN",
      userBankCode: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      ),
      userCountryCode: "234",
      source: "WEB",
      auditUser: StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_BANK_CODE
      )
    };
    const successAction = responseData => {
      let notificationModalBody = {
        type: NotificationUtils.type.SUCCESS, //error, success, warning
        mode: NotificationUtils.mode.YES, // default, yes-no
        title: "Success",
        message: responseData.responseMessage,
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "Close",
        yesAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Close"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    };
    return newBankUserService.resetUserPassword(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING
    );
  },

  checkerAuthorizeBankUser({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FIVE", true);

    return BankUserService.checkerAuthorizeBankUser(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FIVE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            message: `Success -> ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
        return responseData;
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FIVE", false);

        throw error;
      });
  },

  allUsersCheckersQueue({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_FOUR", true);

    return BankUserService.allBankUsersCheckersQueue(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FOUR", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_USERS_CHECKERS_QUEUE", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FOUR", false);

        throw error;
      });
  },

  createUser({ commit, rootGetters, dispatch }, payload) {
    // commit("SET_USER_USERNAME", payload);

    // start loader
    commit("SET_LOADING", true);

    // append the Created by to the payload
    payload.userMaker = rootGetters["user/getUserId"];

    return BankUserService.createUser(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `User Created Successfully`
          };
          dispatch("notification/add", notification, { root: true });
        } else {

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  // get All Users
  allUsers({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING_THREE", true);


    return BankUserService.allUsers(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_USERS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - Network Error :Try Again`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
