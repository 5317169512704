<template>
  <Layout>
    <!-- start page title -->
    <PageHeader
      :title="
        `Create ${product.selectedProductType.prdTemplateDestcription.toLowerCase()} Product`
      "
      :items="[
        ...items,
        {
          ...pageHeaderItem,
          text: `Create ${product.selectedProductType.prdTemplateDestcription.toLowerCase()} Product`
        }
      ]"
    />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <!-- Supposed Form -->
            <div class="form-body">
              <div v-if="product.moduleLoadingSeven">
                <BaseComponentLoader />
              </div>
              <div v-else>
                <CreateProductDynamicForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Create QueryView Component
 */

import { mapState } from "vuex";


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";

export default {
  page: {
    title: "Create Charges Products",
    meta: [{ name: "Create Charges View", content: window.__env.app.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "Create Product Type",

      items: [
        {
          text: "Product",
          href: "/product/select-product-type"
        }
      ],
      pageHeaderItem: {
        active: true
      }
    };
  },
  computed: {
    ...mapState(["product"])
  },
  created() {
    this.$store.dispatch("product/getAllProductFields", null, { root: true });
  },
  methods: {}
};
</script>

<style scoped></style>
