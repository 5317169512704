<template>
  <Layout>
    <!-- start page title -->
    <PageHeader
      :title="
        `View ${product.selectedProductType.prdTemplateDestcription.toLowerCase()} Product`
      "
      :items="[
        ...items,
        {
          ...pageHeaderItem,
          text: `View ${product.selectedProductType.prdTemplateDestcription.toLowerCase()} Product`
        }
      ]"
    />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <!-- Supposed Form -->
            <div class="form-body">
              <ViewDynamicProductsTable />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Create QueryView Component
 */

import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import ViewDynamicProductsTable from "../../components/Kleyton/Tables/Product/ViewDynamicProductsTable";

export default {
  page: {
    title: "View Products",
    meta: [{ name: "Create Charges View", content: window.__env.app.description }]
  },
  components: {
    ViewDynamicProductsTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "Create Product Type",

      items: [
        {
          text: "Product",
          href: "/product/select-product-type-view"
        }
      ],
      pageHeaderItem: {
        active: true
      }
    };
  },
  computed: {
    ...mapState(["product"])
  },
  validations: {
    createQueryFormBody: {
      date: { required },
      message: { required },
      country: { required },
      name: { required }
    }
  },
  methods: {}
};
</script>

<style scoped>
.idpp {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
