<template>
  <div>
    <h4 class="card-title mb-4">Create New Loan Products</h4>

    <form>
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Code'"
              :hasPreIcon="false"
              :label="'Loan Code'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Description'"
              :hasPreIcon="false"
              :label="'Description'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Start Date'"
              :hasPreIcon="false"
              :label="'Start Date'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan End Date'"
              :hasPreIcon="false"
              :label="'End Date'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Moratorium'"
              :hasPreIcon="false"
              :label="'Moratorium'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Interest Tyoe'"
              :options="interesttype"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Allowed'"
              :hasPreIcon="false"
              :label="'Allowed'"
            >
            </BaseInput>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Max Rate'"
              :hasPreIcon="false"
              :label="'Max Rate'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Min Rate'"
              :hasPreIcon="false"
              :label="'Minimum Rate'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Penalty'"
              :hasPreIcon="false"
              :label="'Penalty'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Max Amount'"
              :hasPreIcon="false"
              :label="'Max Amount'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Min Amount'"
              :hasPreIcon="false"
              :label="'Min Amount'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Description'"
              :hasPreIcon="false"
              :label="'Description'"
            >
            </BaseInput>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary mr-1">
        Create Transaction Type
      </button>
      <a @click="testSubmit" class="btn btn-secondary">Cancel</a>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoanProductsForm",
  data() {
    return {
      createBankFormBody: {
        source: "CBA",
        country: "",
        name: ""
      },
      value: null,
      interesttype: ["Reducing Balance", "Amortization"],
      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa"
      ]
    };
  },

  methods: {}
};
</script>

<style scoped></style>
