import axios from "axios";
import NProgress from "nprogress";
import store from "../../store/store";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";
// import Vue from "vue";

const apiClient = axios.create({
  baseURL: window.__env.api.baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",

    "Content-Type": "application/json"

    //"Access-Control-Allow-Methods": "POST"
  },
  timeout: 50000
});

apiClient.interceptors.request.use(config => {
  const token = store.getters["user/getUserTokenFromLocalStorage"];
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});

apiClient.interceptors.response.use(response => {
  NProgress.done();
  if (response.data.responseCode === "92") {
    RouterUtils.changeRouteTo("login");
  }
  return response;
});

export default {
  validateUserToken(payload) {
    return apiClient.post("/validateUserToken", JSON.stringify(payload));
  }
};
