<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div v-if="appUtilities.moduleLoadingSeven">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <div
                @click="liquidateAll"
                :class="{ active: principalAmount === 1 }"
                class="assign-default"
              >
                <span></span>
                <label>Liquidate All</label>
              </div>
              <div class="form-group">
                <label>Amount (₦)</label>

                <input
                  v-show="principalAmount !== 1"
                  v-model="formBody.amount"
                  :type="entryAmountType"
                  step="0.01"
                  placeholder="Entry Amount"
                  class="form-control"
                  :class="{ error: amountError }"
                  :disabled:="principalAmount === 1"
                />
                <div v-show="principalAmount === 1" class="default-value">
                  Liquidate All
                </div>
              </div>

              <p v-if="amountError" class="form-error-message">
                Amount is required
              </p>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>FT Product</label>
                <multiselect
                  v-model="formBody.ftProductCode"
                  :options="ftProductCodeUtilityOption"
                  @close="$v.formBody.ftProductCode.$touch()"
                  placeholder="FT Product"
                ></multiselect>
              </div>
              <template v-if="$v.formBody.ftProductCode.$error">
                <p
                  v-if="!$v.formBody.ftProductCode.required"
                  class="form-error-message"
                >
                  FT Product is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <p class="text-right">
          <span v-if="loader.actionButtonLoader" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Liquidate
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { numeric, required } from "vuelidate/lib/validators";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "LiquidateFixedInvestmentForm",
  props: {},
  data() {
    return {
      formBody: {
        amount: "",
        ftProductCode: ""
      },
      principalAmount: 0,
      entryAmountType: "number",
      amountError: false
    };
  },
  computed: {
    ...mapState(["loader", "appUtilities"]),
    ...mapGetters({
      ftProductCodeUtilityOption: "appUtilities/getFtProductCodeUtilityOption"
    })
  },
  validations: {
    formBody: {
      amount: { required, numeric },
      ftProductCode: { required }
    }
  },
  created() {
    // StoreUtils.dispatch("appUtilities/fetchAllFundsTransferProduct");
  },
  mounted() {},
  methods: {
    liquidateAll() {
      this.principalAmount = (this.principalAmount + 1) % 2;
      if (this.principalAmount === 1) {
        this.formBody.amount = "0";
      } else {
        this.formBody.amount = "";
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.formBody.amount === "") {
          this.amountError = true;
          return;
        }
        StoreUtils.commit(
          StoreUtils.mutations.form.BUILD_FORM_BODY,
          this.formBody
        );
        StoreUtils.dispatch("investment/liquidateFixedDeposit")
          .then(responseData => {
            if (responseData.responseCode === "00") {
              this.$emit("liquidateSuccessful", responseData);
            }
            this.formBody = {
              amount: "",
              ftProductCode: ""
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {
        accountStatus: "",
        accountClass: ""
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
