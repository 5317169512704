import AuthService from "../../services/oldServices/AuthService";
import TokenValidateService from "../../services/oldServices/TokenValidateService";
import router from "../../router";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";


const getDefaultState = () => {
  return {
    appLoading: true,
    moduleLoading: false,
    moduleLoadingTwo: false,
    moduleLoadingThree: false,
    inactivityTimeInSeconds: 5,
    appIsInactive: false
  };
};

export const state = getDefaultState();

export const getters = {};

export const mutations = {
  SET_APP_LOADING(state, status) {
    state.appLoading = status;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_APP_INACTIVITY(state, status) {
    state.appIsInactive = status;
  },
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

export const actions = {
  // // This is automatically run in `src/state/store.js` when the app
  // // starts, along with any other actions named `init` in other modules.
  // // eslint-disable-next-line no-unused-vars
  init({ dispatch }) {
    dispatch("validate");
  },
  resetState({ commit }) {
    commit("RESET_STATE");
  },

  // Logs in the current user.
  logIn({ commit, rootGetters, dispatch }, payload) {
    payload.userCountryCode = rootGetters["user/getUserCountryCode"];
    // start loader
    commit("SET_LOADING", true);

    dispatch("user/setUsername", payload.username, { root: true });
    dispatch("user/setUserInputtedBankCode", payload.userBankCode, {
      root: true
    });

    return AuthService.login(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        dispatch("handleLoginResponse", responseData);
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        // push Notification
        const notification = {
          type: "error",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  // Logs in the current user.
  changeDefaultPassword({ commit, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING_TWO", true);

    payload.username = rootGetters["user/getUserUsername"];
    payload.userBankCode = rootGetters["user/getUserInputtedBankCode"];
    payload.userCountryCode = rootGetters["user/getUserCountryCode"];
    // append userOldPassword from the form
    // payload.userOldPassword = rootGetters.getUserCountryCode

    return AuthService.changeDefaultPassword(payload)
      .then(() => {
        // stop loader
        commit("SET_LOADING_TWO", false);

        router.push({ name: "login" });
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_TWO", false);

        throw error;
      });
  },

  // Logs out the current user.
  handleUserInactivity() {
    let currentPath = router.history.current.path;
    if (window.__env.api.baseUrl.includes("prod")) {
      if (
        !currentPath.includes("login") ||
        !currentPath.includes("change-default-password") ||
        !currentPath.includes("forgot-password")
      ) {
        StoreUtils.commit("auth/SET_APP_INACTIVITY", true);
      }
    }
  },

  logOut({ commit, dispatch, rootGetters }) {
    // start App loader
    commit("SET_APP_LOADING", true);
    StoreUtils.commit("user/REMOVE_USER_TOKEN", "");

    //   "Token =>",
    //   StoreUtils.rootGetter("user/getUserTokenFromLocalStorage")
    // );

    let username = rootGetters["user/getUserUsername"];

    let payload = {
      username: username,
      userBankCode: rootGetters["user/getUserBankCode"],
      userCountryCode: rootGetters["user/getUserCountryCode"],
      source: "WEB"
    };

    StoreUtils.commit("user/REMOVE_USER_TOKEN", "loggedOut");

    dispatch("resetAllTheState");

    router.push({
      name: "login"
    });
    return AuthService.logout(payload)
      .then(() => {
        // stop App loader
        commit("SET_APP_LOADING", false);
        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Logout Successful`
        };
        this.$forceUpdate();
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        // stop App loader
        commit("SET_APP_LOADING", false);
        throw error;
      });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  fakeValidate({ commit, rootGetters, dispatch }) {
    let userToken = rootGetters["user/getUserTokenFromState"];

    //start App Loading
    if (userToken === "") {
      router.push({
        name: "login"
      });
    }
  },

  validate({ commit, rootGetters, dispatch }) {
    let userToken = rootGetters["user/getUserTokenFromLocalStorage"];
    //start App Loading
    commit("SET_APP_LOADING", true);

    if (userToken === "") {
      //stop App Loading
      commit("SET_APP_LOADING", false);

      router.push({
        name: "login"
      });
    } else {
      let payload = {
        token: ""
      };
      TokenValidateService.validateUserToken(payload)
        .then(response => {
          // stop App Loading
          commit("SET_APP_LOADING", false);

          let responseData = response.data;

          if (responseData.responseCode === "00") {
            responseData.userToken = userToken;
            dispatch("saveLoginData", responseData).then(() => {
              // call all app Utilities
              dispatch("setAppUtilities");
            });
          } else {
            // push Notification
            const notification = {
              type: "error",
              variant: "danger",
              message: `Authentication Expired. Login Again`
            };
            dispatch("notification/add", notification, { root: true });

            try {
              router.push({
                name: "login"
              });
            } catch (error) {
              throw error;
            }
          }
        })
        .catch(error => {
          //stop loader
          commit("SET_APP_LOADING", false);
          router.push({
            name: "login"
          });
          throw error;
        });
    }
  },

  setAppUtilities({ dispatch }) {
    dispatch("appUtilities/setUtilityBanks", null, { root: true });
    dispatch("appUtilities/setUtilityBranches", null, { root: true });
    dispatch("appUtilities/setUtilityRoles", null, { root: true });
    dispatch("appUtilities/setUtilityPrivileges", null, { root: true });
  },

  saveLoginData({ dispatch }, responseData) {
    let userIdBankCodeBranchCode = {
      userId: responseData.userId,
      bankCode: responseData.userBankCode,
      branchCode: responseData.userBranchCode
    };

    StoreUtils.commit("user/SET_CURRENT_USER", responseData);
    dispatch("user/setUsername", responseData.username, { root: true });
    StoreUtils.commit("user/SET_USER_TOKEN", responseData.userToken);
    dispatch("user/setUserToken", responseData.userToken, { root: true });
    dispatch("user/setUserIdBankCodeBranchCode", userIdBankCodeBranchCode, {
      root: true
    });
    dispatch("user/setRole", responseData.role, { root: true });
    dispatch("user/setPrivileges", responseData.privileges, {
      root: true
    });
  },

  // handle login response handleLoginResponse
  handleLoginResponse({ dispatch }, responseData) {
    if (responseData.responseCode === "00") {
      dispatch("saveLoginData", responseData).then(() => {
        // call all app Utilities
        dispatch("setAppUtilities");

        // push Notification
        const notification = {
          type: "success",
          variant: "success",
          message: `Login Successful`
        };
        dispatch("notification/add", notification, { root: true });

        router.push({
          name: "dashboard"
        });
      });
    } else if (responseData.responseCode === "01") {
      // push Notification
      const notification = {
        type: "warning",
        variant: "warning",
        message: `New User please change default password`
      };
      dispatch("notification/add", notification, { root: true });

      router.push({
        name: "change-default-password"
      });
    } else {
      // push Notification
      const notification = {
        type: "error",
        variant: "danger",
        message: `Error - ${responseData.responseMessage}`
      };
      dispatch("notification/add", notification, { root: true });
    }
  },

  // Reset The State On Log Out
  // Update As The Modules Increase
  resetAllTheState({ dispatch }) {
    // this is where i delete Auth Token from local storage
    dispatch("user/setUserToken", "", { root: true });
    StoreUtils.commit("user/REMOVE_USER_TOKEN");
    // this is where i reset Base state
    dispatch("resetState", null);
    //reset Module state here
    dispatch("bank/resetState", null, { root: true });
    dispatch("branch/resetState", null, { root: true });
    dispatch("bankUser/resetState", null, { root: true });
    dispatch("customer/resetState", null, { root: true });
    dispatch("glLevel1/resetState", null, { root: true });
    dispatch("glLevel2/resetState", null, { root: true });
    dispatch("journal/resetState", null, { root: true });
    dispatch("loan/resetState", null, { root: true });
    dispatch("report/resetState", null, { root: true });
    dispatch("teller/resetState", null, { root: true });
    dispatch("user/resetState", null, { root: true });
  }
};

// ===
// Private helpers
// ===
