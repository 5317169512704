<template>
  <div>
    <h4 class="card-title mb-4">Create New Charge Product</h4>

    <form>
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Charge Code'"
              :hasPreIcon="false"
              :label="'Charge Code'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Charge Description'"
              :hasPreIcon="false"
              :label="'Description'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Charge Transaction Code'"
              :hasPreIcon="false"
              :label="'Transaction Code'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Amount Type'"
              :options="amounttype"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Loan Allowed'"
              :hasPreIcon="false"
              :label="'Allowed'"
            >
            </BaseInput>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Charge Amount'"
              :hasPreIcon="false"
              :label="'Amount'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createBankFormBody.name"
              :placeholder="'Charge Credit Amount'"
              :hasPreIcon="false"
              :label="'Credit Amount'"
            >
            </BaseInput>
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Charge Vatable'"
              :options="yesno"
              :hasPreIcon="false"
            />
          </div>

          <div>
            <BaseSelect
              :type="'text'"
              :range="false"
              v-model="createBankFormBody.country"
              :label="'Charge Frequency'"
              :options="frequency"
              :hasPreIcon="false"
            />
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary mr-1">
        Create Transaction Type
      </button>
      <a @click="testSubmit" class="btn btn-secondary">Cancel</a>
    </form>
  </div>
</template>

<script>
export default {
  name: "ChargesProductForm",
  data() {
    return {
      createBankFormBody: {
        source: "CBA",
        country: "",
        name: ""
      },
      value: null,
      frequency: ["Per Transaction", "End Of Day"],
      yesno: ["Yes", "No"],
      amounttype: ["Absolute", "Percentage"],
      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa"
      ]
    };
  },

  methods: {

  }
};
</script>

<style scoped></style>
