<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";

export default {
  components: {},
  mounted: function() {
    document.body.setAttribute("data-sidebar", "dark");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  computed: {
    bankIsInfinity() {
      return (
        StoreUtils.rootGetter(StoreUtils.getters.user.GET_USER_BANK_CODE) ===
        "00002"
      );
    },
    userBankTypeIsAgency() {
      return StoreUtils.rootGetter("user/getUserBankType") === "AGENCY";
    }
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <!--      <li class="menu-title">Home</li>-->
      <li>
        <router-link
          tag="a"
          :to="{ name: 'dashboard' }"
          class="side-nav-link-ref"
        >
          <i class="bx bx-home-circle"></i>
          <span>Dashboard</span>
        </router-link>
      </li>

      <li class="menu-title">User Management</li>
      <!--      Bank-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bx-target-lock"></i>
          <span>Role</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'role-create-role' }"
              class="side-nav-link-ref"
              >Create Role</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'role-all-role' }"
              class="side-nav-link-ref"
              >All Roles</router-link
            >
          </li>
        </ul>
      </li>
      <!--      Bank-->
      <!--      <li>-->
      <!--        <a href="javascript: void(0);" class="has-arrow">-->
      <!--          <i class="bx bxs-bank"></i>-->
      <!--          <span>Privilege</span>-->
      <!--        </a>-->
      <!--        <ul class="sub-menu" aria-expanded="false">-->
      <!--          <li>-->
      <!--            <router-link-->
      <!--              tag="a"-->
      <!--              :to="{ name: 'role-create-privilege' }"-->
      <!--              class="side-nav-link-ref"-->
      <!--              >Create Privilege</router-link-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <router-link-->
      <!--              tag="a"-->
      <!--              :to="{ name: 'role-all-privileges' }"-->
      <!--              class="side-nav-link-ref"-->
      <!--              >All Privileges</router-link-->
      <!--            >-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->

      <!--      Bank-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bx-user"></i>
          <span>User</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'user-create-user' }"
              class="side-nav-link-ref"
              >Create User</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'user-all-users' }"
              class="side-nav-link-ref"
              >All Users</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'user-checkers-queue' }"
              class="side-nav-link-ref"
              >Users Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>

      <li class="menu-title">MAINTENANCE</li>
      <!--      Bank-->
      <!--      <li>-->
      <!--        <a href="javascript: void(0);" class="has-arrow">-->
      <!--          <i class="bx bxs-bank"></i>-->
      <!--          <span>Bank</span>-->
      <!--        </a>-->
      <!--        <ul class="sub-menu" aria-expanded="false">-->
      <!--          <li>-->
      <!--            <router-link-->
      <!--              tag="a"-->
      <!--              :to="{ name: 'bank-create' }"-->
      <!--              class="side-nav-link-ref">-->
      <!--              Create Bank-->
      <!--            </router-link>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <router-link-->
      <!--              tag="a"-->
      <!--              :to="{ name: 'bank-all' }"-->
      <!--              class="side-nav-link-ref">-->
      <!--              All Banks-->
      <!--            </router-link>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->

      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bxs-bank"></i>
          <span>Internal Accounts</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'bank-create-internal-account' }"
              class="side-nav-link-ref"
              >Create Internal Account</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'bank-internal-account' }"
              class="side-nav-link-ref"
              >All Internal Account</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'internal-accounts-checkers-queue' }"
              class="side-nav-link-ref"
              >Internal Accounts Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bxs-bank"></i>
          <span>Bank</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'bank-upload-bank-logo' }"
              class="side-nav-link-ref"
              >Upload Logo</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Branches-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bx-git-branch"></i>
          <span>Branch</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'branch-create' }"
              class="side-nav-link-ref"
              >Create Branch</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'branch-all' }"
              class="side-nav-link-ref"
              >All Branch</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'branch-checkers-queue' }"
              class="side-nav-link-ref"
              >Branch Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Customer-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="mdi mdi-window-shutter"></i>
          <span>Customer</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'customer-create-account' }"
              class="side-nav-link-ref"
              >Create Customer</router-link
            >
          </li>
<!--          <li>-->
<!--            <router-link-->
<!--              tag="a"-->
<!--              :to="{ name: 'customer-create-customer' }"-->
<!--              class="side-nav-link-ref"-->
<!--              >Create Customer</router-link-->
<!--            >-->
<!--          </li>-->
          <li>
            <router-link
              tag="a"
              :to="{ name: 'customer-all-customers' }"
              class="side-nav-link-ref"
              >View Customer</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'customer-all-customers-accounts' }"
              class="side-nav-link-ref"
              >View Customer Accounts</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'customer-accounts-upgrade' }"
              class="side-nav-link-ref"
              > Customer Accounts Upgrade</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'customer-create-checkers-queue' }"
              class="side-nav-link-ref"
              >Customer Checkers Queue</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'customer-sub-account-checkers-queue' }"
              class="side-nav-link-ref"
              >Customer Sub Account Checkers Queue</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'customer-create-customer-upload' }"
              class="side-nav-link-ref"
              >Create Customer Upload</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'customer-request-account-statement' }"
              class="side-nav-link-ref"
              >Request Account Statement</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Transaction Code-->
      <!--      <li>-->
      <!--        <a href="javascript: void(0);" class="has-arrow">-->
      <!--          <i class="bx bx-money"></i>-->
      <!--          <span>Transaction Code</span>-->
      <!--        </a>-->
      <!--        <ul class="sub-menu" aria-expanded="false">-->
      <!--          <li>-->
      <!--            <router-link-->
      <!--              tag="a"-->
      <!--              :to="{ name: 'transaction-code-all' }"-->
      <!--              class="side-nav-link-ref"-->
      <!--              >All Transaction Code</router-link-->
      <!--            >-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <router-link-->
      <!--              tag="a"-->
      <!--              :to="{ name: 'transaction-code-create' }"-->
      <!--              class="side-nav-link-ref"-->
      <!--              >Create Transaction Code</router-link-->
      <!--            >-->
      <!--          </li>-->

      <!--          <li>-->
      <!--            <router-link-->
      <!--              tag="a"-->
      <!--              :to="{ name: 'transaction-code-checkers-queue' }"-->
      <!--              class="side-nav-link-ref"-->
      <!--              >Transaction Code Checkers Queue</router-link-->
      <!--            >-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->

      <li class="menu-title">OPERATIONS</li>

      <!--      Reports-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bxs-report"></i>
          <span>Reports</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'report-view-reports' }"
              class="side-nav-link-ref"
              >View Reports</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'create-report-view' }"
              class="side-nav-link-ref"
              >Create Report</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'report-checkers-queue' }"
              class="side-nav-link-ref"
              >Report Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Loan Processing-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="fas fa-coins"></i>
          <span>Loans</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'loan-book-loan' }"
              class="side-nav-link-ref"
              >Book Loan</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'loan-view-loans' }"
              class="side-nav-link-ref"
              >View Loans</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Systems Management-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bxs-report"></i>
          <span>System Maintenance</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'eod-all-eod' }"
              class="side-nav-link-ref"
              >EOD Run</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'eom-all-eom' }"
              class="side-nav-link-ref"
              >EOM Run</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Terminal Management-->
      <!--      <li v-if="userBankTypeIsAgency">-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bxs-report"></i>
          <span>Terminals</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'terminal-open-terminal-batch' }"
              class="side-nav-link-ref"
              >Open Terminal Batch</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'terminal-all-assigned-terminals' }"
              class="side-nav-link-ref"
              >All Assigned Terminals</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'terminal-all-batches' }"
              class="side-nav-link-ref"
              >All Terminal Batches</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'terminal-batch-checkers-queue' }"
              class="side-nav-link-ref"
              >Terminal Batch Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Dispute-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bxs-report"></i>
          <span>Disputes</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'dispute-create-dispute' }"
              class="side-nav-link-ref"
              >Create Dispute</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'dispute-all-disputes' }"
              class="side-nav-link-ref"
              >All Disputes</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Queues-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bxs-report"></i>
          <span>Queues</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'queue-general-checking-queue' }"
              class="side-nav-link-ref"
              >General Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>

      <li class="menu-title">PRODUCTS</li>
      <!--      Bank-->

      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="fas fa-coins"></i>
          <span>Product</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'product-select-type-create' }"
              class="side-nav-link-ref"
            >
              Create Product
            </router-link>
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'product-select-type--view' }"
              class="side-nav-link-ref"
            >
              View Products
            </router-link>
          </li>
        </ul>
      </li>

      <li class="menu-title">CASH AND TELLERS</li>

      <!--      Switch Transactions-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="dripicons-blog"></i>
          <span>Switch Transactions</span>
        </a>

        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'switch-transactions-nip-inward' }"
              class="side-nav-link-ref"
              >NIP Inwards</router-link
            >
          </li>

          <li v-if="bankIsInfinity">
            <router-link
              tag="a"
              :to="{ name: 'switch-transactions-decision-box' }"
              class="side-nav-link-ref"
              >Decision Box</router-link
            >
          </li>
        </ul>
      </li>
      <!--      Transactions-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="dripicons-blog"></i>
          <span>Transaction</span>
        </a>

        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-customer-balance-enquiry' }"
              class="side-nav-link-ref"
              >Customer Enquiry</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-customer-transactions' }"
              class="side-nav-link-ref"
              >Customer Transactions</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-all-transactions-history' }"
              class="side-nav-link-ref"
              >Transaction Enquiry</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-transfer-funds' }"
              class="side-nav-link-ref"
              >Transfer Funds</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-ft-checkers-queue' }"
              class="side-nav-link-ref"
              >Funds Transfer Checkers Queue</router-link
            >
          </li>
          <li>
            <!--            <router-link-->
            <!--              tag="a"-->
            <!--              :to="{ name: 'funds-transfer-cash-transactions' }"-->
            <!--              class="side-nav-link-ref"-->
            <!--              >Cash Transactions</router-link-->
            <!--            >-->
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-cash-transactions' }"
              class="side-nav-link-ref"
              >GL Funds Transfer</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-cash-withdrawal' }"
              class="side-nav-link-ref"
            >
              Withdrawals & Deposit
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-cw-checkers-queue' }"
              class="side-nav-link-ref"
            >
              Cash Withdrawal Checkers Queue
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-cd-checkers-queue' }"
              class="side-nav-link-ref"
            >
              Cash Deposit Checkers Queue
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-ct-checkers-queue' }"
              class="side-nav-link-ref"
            >
              Cash Transactions Checkers Queue
            </router-link>
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-teller-transaction-history' }"
              class="side-nav-link-ref"
            >
              Transactions History
            </router-link>
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'funds-transfer-manual-reversal' }"
              class="side-nav-link-ref"
            >
              Manual Reversal
            </router-link>
          </li>
        </ul>
      </li>

      <!--      Teller-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="dripicons-blog"></i>
          <span>Teller</span>
        </a>

        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'teller-create-teller' }"
              class="side-nav-link-ref"
              >Create Teller</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'teller-all-teller' }"
              class="side-nav-link-ref"
              >List Tellers</router-link
            >
          </li>

          <li>
            <router-link
              tag="a"
              :to="{ name: 'teller-checkers-queue' }"
              class="side-nav-link-ref"
              >Teller Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Till-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="mdi mdi-window-shutter"></i>
          <span>Till</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'till-create-till' }"
              class="side-nav-link-ref"
              >Create Till</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'till-view-till' }"
              class="side-nav-link-ref"
              >View Till</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'till-checkers-queue' }"
              class="side-nav-link-ref"
              >Till Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Vault-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="mdi mdi-safe"></i>
          <span>Vault</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'vault-create-vault' }"
              class="side-nav-link-ref"
              >Create Vault</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'vault-view-vault' }"
              class="side-nav-link-ref"
              >View Vault</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'vault-checkers-queue' }"
              class="side-nav-link-ref"
              >Vault Checker Queue</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Charts Of Accounts-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="mdi mdi-chart-bar"></i>
          <span>Charts Of Accounts</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'gllevel1-all-glLevel1' }"
              class="side-nav-link-ref"
              >View Level 1 GL</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'gllevel1-create-glLevel1' }"
              class="side-nav-link-ref"
              >Create Level 1 GL</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'glLevel1-checkers-queue' }"
              class="side-nav-link-ref"
              >Level 1 GL Checkers Queue</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'glLevel2-all-glLevel2' }"
              class="side-nav-link-ref"
              >View Level 2 GL</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'glLevel2-create-glLevel2' }"
              class="side-nav-link-ref"
              >Create Level 2 GL</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'glLevel2-checkers-queue' }"
              class="side-nav-link-ref"
              >Level 2 GL Checkers Queue</router-link
            >
          </li>
        </ul>
      </li>

      <!--      Journal-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="mdi mdi-window-shutter"></i>
          <span>Journal</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'journal-open-batch' }"
              class="side-nav-link-ref"
              >Open Batch</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'journal-all-entries' }"
              class="side-nav-link-ref"
              >View Batches</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'journal-checkers-queue' }"
              class="side-nav-link-ref"
              >Journals Checkers Queue</router-link
            >
          </li>
          <!--          <li>-->
          <!--            <router-link tag="a" :to="{ name: 'till-list-transactions'}" class="side-nav-link-ref">List Transactions</router-link>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <router-link tag="a" :to="{ name: 'till-transfer-to-vault'}" class="side-nav-link-ref">Transfer to Vault</router-link>-->
          <!--          </li>-->
        </ul>
      </li>

      <!--      Teller-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="mdi mdi-printer-check"></i>
          <span>Teller</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'loans-' }"
              class="side-nav-link-ref"
              >View</router-link
            >
          </li>
        </ul>
      </li>

      <li class="menu-title">INVESTMENT</li>

      <!--      Fixed Deposit-->
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="mdi mdi-download-multiple"></i>
          <span>Fixed Deposit</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              :to="{ name: 'investment-create-fixed-deposit' }"
              class="side-nav-link-ref"
              >Create Fixed Deposit</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              :to="{ name: 'investment-view-fixed-deposits' }"
              class="side-nav-link-ref"
              >View Fixed Deposits</router-link
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
