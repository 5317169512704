<template>
  <div>
    <!--    <button @click="sola">test Getter</button>-->
    <form @submit.prevent="createJournal">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Gl Category</label>
              <multiselect
                v-model="createJournalFormBody.journalGlCategory"
                :options="options2"
                placeholder="Gl Category"
                @close="$v.createJournalFormBody.journalGlCategory.$touch()"
              ></multiselect>
            </div>

            <template v-if="$v.createJournalFormBody.journalGlCategory.$error">
              <p
                v-if="!$v.createJournalFormBody.journalGlCategory.required"
                class="form-error-message"
              >
                GL Category is required
              </p>
            </template>
          </div>

          <div>
            <div class="form-group">
              <label>GL Level 1 Code</label>
              <multiselect
                v-model="createJournalFormBody.journalGlLevel1Code"
                :options="glLevelOneUtilityOption"
                placeholder="GL Level 1 Code"
                @close="$v.createJournalFormBody.journalGlLevel1Code.$touch()"
              ></multiselect>
            </div>

            <template
              v-if="$v.createJournalFormBody.journalGlLevel1Code.$error"
            >
              <p
                v-if="!$v.createJournalFormBody.journalGlLevel1Code.required"
                class="form-error-message"
              >
                GL Level 1 Code is required
              </p>
            </template>
          </div>

          <div>
            <div class="form-group">
              <label>GL Level 2 Code</label>
              <multiselect
                v-model="createJournalFormBody.journalGlLevel2Code"
                :options="glLevelTwoUtilityOption"
                placeholder="GL Level 2 Code"
                @close="$v.createJournalFormBody.journalGlLevel2Code.$touch()"
              ></multiselect>
            </div>

            <template
              v-if="$v.createJournalFormBody.journalGlLevel2Code.$error"
            >
              <p
                v-if="!$v.createJournalFormBody.journalGlLevel2Code.required"
                class="form-error-message"
              >
                GL Level 2 Code is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalCreditCustomerId"
              placeholder="Credit CustomerId"
              :hasPreIcon="false"
              :label="'Credit CustomerId'"
              :class="{
                error: $v.createJournalFormBody.journalCreditCustomerId.$error
              }"
              @blur="$v.createJournalFormBody.journalCreditCustomerId.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.createJournalFormBody.journalCreditCustomerId.$error"
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalCreditCustomerId.required
                "
                class="form-error-message"
              >
                Credit CustomerId is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalDebitCustomerId"
              placeholder="Debit CustomerId"
              :hasPreIcon="false"
              :label="'Debit CustomerId'"
              :class="{
                error: $v.createJournalFormBody.journalDebitCustomerId.$error
              }"
              @blur="$v.createJournalFormBody.journalDebitCustomerId.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.createJournalFormBody.journalDebitCustomerId.$error"
            >
              <p
                v-if="!$v.createJournalFormBody.journalDebitCustomerId.required"
                class="form-error-message"
              >
                Debit CustomerId is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalCreditAccountNumber"
              placeholder="Credit Account Number"
              :hasPreIcon="false"
              :label="'Credit Account Number'"
              :class="{
                error:
                  $v.createJournalFormBody.journalCreditAccountNumber.$error
              }"
              @blur="
                $v.createJournalFormBody.journalCreditAccountNumber.$touch()
              "
            >
            </BaseInput>

            <template
              v-if="$v.createJournalFormBody.journalCreditAccountNumber.$error"
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalCreditAccountNumber.required
                "
                class="form-error-message"
              >
                Credit Account Number is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalDebitAccountNumber"
              placeholder="Debit Account Number"
              :hasPreIcon="false"
              :label="'Debit Account Number'"
              :class="{
                error: $v.createJournalFormBody.journalDebitAccountNumber.$error
              }"
              @blur="
                $v.createJournalFormBody.journalDebitAccountNumber.$touch()
              "
            >
            </BaseInput>

            <template
              v-if="$v.createJournalFormBody.journalDebitAccountNumber.$error"
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalDebitAccountNumber.required
                "
                class="form-error-message"
              >
                Debit Account Number is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalCounterPartyAccountName"
              placeholder="CounterParty AccountName"
              :hasPreIcon="false"
              :label="'CounterParty AccountName'"
              :class="{
                error:
                  $v.createJournalFormBody.journalCounterPartyAccountName.$error
              }"
              @blur="
                $v.createJournalFormBody.journalCounterPartyAccountName.$touch()
              "
            >
            </BaseInput>

            <template
              v-if="
                $v.createJournalFormBody.journalCounterPartyAccountName.$error
              "
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalCounterPartyAccountName
                    .required
                "
                class="form-error-message"
              >
                CounterParty AccountName is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalCounterPartyAccountNumber"
              placeholder="CounterParty AccountNumber"
              :hasPreIcon="false"
              :label="'CounterParty AccountNumber'"
              :class="{
                error:
                  $v.createJournalFormBody.journalCounterPartyAccountNumber
                    .$error
              }"
              @blur="
                $v.createJournalFormBody.journalCounterPartyAccountNumber.$touch()
              "
            >
            </BaseInput>

            <template
              v-if="
                $v.createJournalFormBody.journalCounterPartyAccountNumber.$error
              "
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalCounterPartyAccountNumber
                    .required
                "
                class="form-error-message"
              >
                CounterParty AccountNumber is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalNarration"
              placeholder="Narration"
              :hasPreIcon="false"
              :label="'Narration'"
              :class="{
                error: $v.createJournalFormBody.journalNarration.$error
              }"
              @blur="$v.createJournalFormBody.journalNarration.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createJournalFormBody.journalNarration.$error">
              <p
                v-if="!$v.createJournalFormBody.journalNarration.required"
                class="form-error-message"
              >
                Narration is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalContractReference"
              placeholder="Contract Reference"
              :hasPreIcon="false"
              :label="'Contract Reference'"
              :class="{
                error: $v.createJournalFormBody.journalContractReference.$error
              }"
              @blur="$v.createJournalFormBody.journalContractReference.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.createJournalFormBody.journalContractReference.$error"
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalContractReference.required
                "
                class="form-error-message"
              >
                Contract Reference is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalContractType"
              placeholder="Contract Type"
              :hasPreIcon="false"
              :label="'Contract Type'"
              :class="{
                error: $v.createJournalFormBody.journalContractType.$error
              }"
              @blur="$v.createJournalFormBody.journalContractType.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.createJournalFormBody.journalContractType.$error"
            >
              <p
                v-if="!$v.createJournalFormBody.journalContractType.required"
                class="form-error-message"
              >
                Contract Type is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalAmount"
              placeholder="Amount"
              :hasPreIcon="false"
              :label="'Amount'"
              :class="{ error: $v.createJournalFormBody.journalAmount.$error }"
              @blur="$v.createJournalFormBody.journalAmount.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createJournalFormBody.journalAmount.$error">
              <p
                v-if="!$v.createJournalFormBody.journalAmount.required"
                class="form-error-message"
              >
                Amount is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalCounterPartySwitchCode"
              placeholder="CounterParty SwitchCode"
              :hasPreIcon="false"
              :label="'CounterParty SwitchCode'"
              :class="{
                error:
                  $v.createJournalFormBody.journalCounterPartySwitchCode.$error
              }"
              @blur="
                $v.createJournalFormBody.journalCounterPartySwitchCode.$touch()
              "
            >
            </BaseInput>

            <template
              v-if="
                $v.createJournalFormBody.journalCounterPartySwitchCode.$error
              "
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalCounterPartySwitchCode
                    .required
                "
                class="form-error-message"
              >
                CounterParty SwitchCode is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalCounterPartyBank"
              placeholder="CounterParty Bank"
              :hasPreIcon="false"
              :label="'CounterParty Bank'"
              :class="{
                error: $v.createJournalFormBody.journalCounterPartyBank.$error
              }"
              @blur="$v.createJournalFormBody.journalCounterPartyBank.$touch()"
            >
            </BaseInput>

            <template
              v-if="$v.createJournalFormBody.journalCounterPartyBank.$error"
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalCounterPartyBank.required
                "
                class="form-error-message"
              >
                CounterParty Bank is required
              </p>
            </template>
          </div>

          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createJournalFormBody.journalCreatedBy"
              placeholder="Creator"
              :hasPreIcon="false"
              :label="'Creator'"
              :class="{
                error: $v.createJournalFormBody.journalCreatedBy.$error
              }"
              @blur="$v.createJournalFormBody.journalCreatedBy.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createJournalFormBody.journalCreatedBy.$error">
              <p
                v-if="!$v.createJournalFormBody.journalCreatedBy.required"
                class="form-error-message"
              >
                Creator
              </p>
            </template>
          </div>

          <div>
            <div class="form-group">
              <label>Debit Account Type</label>
              <multiselect
                      v-model="createJournalFormBody.journalDebitAccountType"
                      :options="options"
                      placeholder="Debit Account Type"
                      @close="$v.createJournalFormBody.journalDebitAccountType.$touch()"
              ></multiselect>
            </div>

            <!--                          error-->
            <template
              v-if="$v.createJournalFormBody.journalDebitAccountType.$error"
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalDebitAccountType.required
                "
                class="form-error-message"
              >
                Debit Account Type is required
              </p>
            </template>
          </div>

          <div>
            <div class="form-group">
              <label>Credit Account Type</label>
              <multiselect
                      v-model="createJournalFormBody.journalCreditAccountType"
                      :options="options"
                      placeholder="Credit Account Type"
                      @close="$v.createJournalFormBody.journalCreditAccountType.$touch()"
              ></multiselect>
            </div>
            <!--                          error-->
            <template
              v-if="$v.createJournalFormBody.journalCreditAccountType.$error"
            >
              <p
                v-if="
                  !$v.createJournalFormBody.journalCreditAccountType.required
                "
                class="form-error-message"
              >
                Credit Account Type is required
              </p>
            </template>
          </div>
        </div>
      </div>
      <p class="text-right">
        <span v-if="journal.moduleLoading" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Create Journal
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateJournal",
  data() {
    return {
      options: ["Savings", "Current"],
      options2: ["Equity", "Fixed Asset", "Current Asset", "Revenue"],
      createJournalFormBody: this.createFreshcreateJournalFormBodyObject()
    };
  },
  created() {
    this.$store.dispatch("appUtilities/setUtilityGlLevelOneCodes");
    this.$store.dispatch("appUtilities/setUtilityGlLevelTwoCodes");
  },
  computed: {
    ...mapState(["journal"]),
    ...mapGetters({
      glLevelOneUtilityOption: "appUtilities/getGlLevelOneUtilityOption",
      glLevelTwoUtilityOption: "appUtilities/getGlLevelTwoUtilityOption",
      glLevelOneCode: "appUtilities/getGlLevelOneCodeFromOptionUtility",
      glLevelTwoCode: "appUtilities/getGlLevelTwoCodeFromOptionUtility"
    })
  },
  validations: {
    createJournalFormBody: {
      journalGlCategory: { required },
      journalGlLevel1Code: { required },
      journalGlLevel2Code: { required },
      journalCreditCustomerId: { required },
      journalDebitCustomerId: { required },
      journalCreditAccountNumber: { required },
      journalDebitAccountNumber: { required },
      journalCounterPartyBank: { required },
      journalCounterPartyAccountName: { required },
      journalCounterPartyAccountNumber: { required },
      journalCounterPartySwitchCode: { required },
      journalNarration: { required },
      journalCreatedBy: { required },
      journalContractReference: { required },
      journalAmount: { required },
      journalContractType: { required },
      journalCreditAccountType: { required },
      journalDebitAccountType: { required }
    }
  },
  methods: {
    // sola(){
    // },
    createJournal() {
      this.createJournalFormBody.journalBranchCode = this.$store.getters[
        "user/getUserBranchCode"
      ];
      this.createJournalFormBody.journalBankCode = this.$store.getters[
        "user/getUserBankCode"
      ];
      this.createJournalFormBody.journalGlLevel1Code = this.glLevelOneCode(
        this.createJournalFormBody.journalGlLevel1Code
      );
      this.createJournalFormBody.journalGlLevel2Code = this.glLevelTwoCode(
        this.createJournalFormBody.journalGlLevel2Code
      );
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("journal/createJournal", this.createJournalFormBody, {
            root: true
          })
          .then(() => {
            this.createJournalFormBody = {
              // source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshcreateJournalFormBodyObject() {
      return {
        // source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
