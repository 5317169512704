<template>
  <div id="app">
    <NotificationContainer />
    <AppLoading v-if="auth.appLoading" />
    <AppLoading v-if="loader.blockingLoader" />
    <RouterView v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "./utils/BaseUtils/StoreUtils";

export default {
  name: "app",
  page: {
    // All sub-component titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${window.__env.app.title}` : window.__env.app.title;
    }
  },
  computed: {
    ...mapState(["auth", "loader"])
  },
  created() {
    this.$store.dispatch("auth/init", null, { root: true });
  },
  mounted() {
    window.onbeforeunload = () => {
      let storage = window.localStorage;
      storage.clear();
      StoreUtils.dispatch("auth/logOut");
    };
  }
};
</script>
