<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Decision Box</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <AllNipInwardsTable mode="decision-box" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import AllNipInwardsTable from "../../components/Kleyton/Tables/SwitchTransaction/AllNipInwardsTable";

export default {
  name: "DecisionBox",
  page: {
    title: "Decision Box",
    meta: [{ name: "Decision Box", content: window.__env.app.description }]
  },
  components: {
    AllNipInwardsTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Switch Transactions",

      items: [
        {
          text: "Switch Transactions",
          href: "/"
        },
        {
          text: "NIP Inwards",
          active: true
        }
      ]
    };
  }
};
</script>

<style scoped></style>
