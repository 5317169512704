<template>
  <div>
    <!--    <p>{{ createRoleFormBody.rolePrivileges }}</p>-->
    <form @submit.prevent="createRole">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="createRoleFormBody.roleName"
              placeholder="Role Name"
              :hasPreIcon="false"
              :label="'Role Name'"
              :class="{ error: $v.createRoleFormBody.roleName.$error }"
              @blur="$v.createRoleFormBody.roleName.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createRoleFormBody.roleName.$error">
              <p
                v-if="!$v.createRoleFormBody.roleName.required"
                class="form-error-message"
              >
                Role Name is required
              </p>
            </template>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'amount'"
              v-model="createRoleFormBody.roleTrnLimit"
              placeholder="Transaction Limit"
              :hasPreIcon="false"
              :label="'Limit'"
              min="0.000001"
              :class="{ error: $v.createRoleFormBody.roleTrnLimit.$error }"
              @blur="$v.createRoleFormBody.roleTrnLimit.$touch()"
            >
            </BaseInput>

            <template v-if="$v.createRoleFormBody.roleTrnLimit.$error">
              <p
                v-if="!$v.createRoleFormBody.roleTrnLimit.required"
                class="form-error-message"
              >
                Transaction Limit is required
              </p>

              <p
                v-if="!$v.createRoleFormBody.roleTrnLimit.mustBeGreaterThanZero"
                class="form-error-message"
              >
                Transaction Limit must be greater than 0
              </p>
            </template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <p class="card-title-desc mt-3 mb-0">
            <code>Assign Privileges to role</code>
          </p>
          <div>
            <div
              v-for="(value, key) in getPrivilegeSeparatedByRoles"
              :key="key"
              class="row"
            >
              <div class="col-sm-12">
                <p class="module-title font-size-14 mt-3 mb-3">
                  {{ key | firstCaseCapitalSpace }}
                </p>
              </div>

              <div class="col-sm-12">
                <b-form-checkbox-group
                  switch
                  v-model="createRoleFormBody.rolePrivileges"
                >
                  <b-form-checkbox
                    v-for="privilege in value"
                    :key="privilege.privilegeId"
                    :value="privilege.privilegeId"
                  >
                    <span
                      v-if="
                        privilege.privilegeType.toLowerCase() === 'checker' ||
                          privilege.privilegeType.toLowerCase() === 'maker'
                      "
                    >
                      {{
                        (privilege.privilegeModuleName +
                          " " +
                          privilege.privilegeType)
                          | firstCaseCapitalSpace
                      }}
                    </span>
                    <span v-else>
                      {{
                        (privilege.privilegeType +
                          " " +
                          privilege.privilegeModuleName)
                          | firstCaseCapitalSpace
                      }}
                    </span>
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <template v-if="$v.createRoleFormBody.rolePrivileges.$error">
                  <p
                    v-if="!$v.createRoleFormBody.rolePrivileges.required"
                    class="form-error-message"
                  >
                    Role Privileges is required
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="text-right">
        <span v-if="role.moduleLoading" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Create Role
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

const mustBeGreaterThanZero = value => value > 0;

export default {
  name: "CreateRoleForm",
  data() {
    return {
      createRoleFormBody: this.createFreshCreateRoleFormBodyObject(),
      customError: false
    };
  },
  computed: {
    ...mapState(["role"]),
    ...mapGetters({
      getPrivilegeSeparatedByRoles: "appUtilities/getPrivilegeSeparatedByRoles"
    })
  },
  validations: {
    createRoleFormBody: {
      roleName: { required },
      roleTrnLimit: { required, mustBeGreaterThanZero },
      rolePrivileges: { required }
    }
  },
  methods: {
    showCustomError() {
      this.customError = true;
    },
    createRole() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.createRoleFormBody.rolePrivileges.length < 1) {
          this.showCustomError();
        } else {
          this.createRoleFormBody.roleMaker = this.$store.getters[
            "user/getUserId"
          ];

          this.createRoleFormBody.rolePrivileges = this.createRoleFormBody.rolePrivileges.map(
            str => ({ privilegeId: str })
          );

          this.$store
            .dispatch("role/createRoleComplete", this.createRoleFormBody, {
              root: true
            })
            .then(() => {
              this.createRoleFormBody = {
                source: "CBA",
                rolePrivileges: []
              };
              this.$v.$reset();
            })
            .catch(() => {});
        }
      }
    },
    createFreshCreateRoleFormBodyObject() {
      return {
        source: "CBA",
        rolePrivileges: []
        // rolePrivileges: [ "0000000002", "0000000006", "0000000014", "0000000019", "0000000024", "0000000028" ]
      };
    }
  }
};
</script>

<style scoped>
.module-title {
  font-size: 12px !important;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 5px !important;
  margin-top: 30px !important;
  color: #556ee6;
}
/*.form-error-message {*/
/*  margin-top: 0;*/
/*  padding-left: 10px;*/
/*}*/
</style>
