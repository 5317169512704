class UpgradeLogsResponse {
  static logsReadResponse = {
    responseCode: null,
    responseMessage: null,
    data: [
      {
        id: null,
        username: null,
        customerId: null,
        firstName: null,
        lastName: null,
        middleName: null,
        dob: null,
        phoneNumber: null,
        bvn: null,
        picture: null,
        signature: null,
        utilityBill: null,
        idCardType: null,
        idCard: null,
        idCardNumber: null,
        cacNumber: null,
        cac: null,
        logo: null,
        logoVerified: null,
        address: null,
        detailsVerified: null,
        dobVerified: null,
        bvnVerified: null,
        pictureVerified: null,
        utilityBillVerified: null,
        signatureVerified: null,
        addressVerified: null,
        idCardVerified: null,
        cacVerified: null,
        accountClass: null,
        comment: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        page: null,
        pageSize: null,
      },
    ],
  };
  static logsApproveResponse = {
    accountNumber: null,
    bankCode: null,
  };
  static logsDeclineResponse = {
    accountNumber: null,
    bankCode: null,
  };

  static logsUpgradeLogsReadByCustomerIdResponse = {
    responseCode: null,
    responseMessage: null,
    data: {
      id: null,
      username: null,
      customerId: null,
      firstName: null,
      lastName: null,
      middleName: null,
      dob: null,
      phoneNumber: null,
      bvn: null,
      picture: null,
      signature: null,
      utilityBill: null,
      idCardType: null,
      idCard: null,
      idCardNumber: null,
      cacNumber: null,
      cac: null,
      logo: null,
      logoVerified: null,
      address: null,
      detailsVerified: null,
      dobVerified: null,
      bvnVerified: null,
      pictureVerified: null,
      utilityBillVerified: null,
      signatureVerified: null,
      addressVerified: null,
      idCardVerified: null,
      cacVerified: null,
      accountClass: null,
      comment: null,
      createdAt: null,
      updatedAt: null,
      status: null,
      page: null,
      pageSize: null,
    },
  };
}
export default UpgradeLogsResponse;
