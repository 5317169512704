<template>
  <div>
    <div class="form-group">
      <label for="date">{{ label }}</label>
      <DatePicker
        id="date"
        v-model="date"
        :type="type"
        :range="range"
        :append-to-body="true"
        @input="updateValueDate"
        v-bind="$attrs"
        lang="en"
        confirm
      />
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "date"
    },
    range: {
      type: Boolean,
      default: false
    },
    label: String
  },
  components: {
    DatePicker
  },
  data() {
    return {
      date: ""
    };
  },
  methods: {
    getRandomId() {
      return (
        Math.round(Math.random() * 50).toString() +
        Math.round(Math.random() * 10).toString() +
        Math.round(Math.random() * 50).toString()
      );
    },
    updateValue(event) {
      this.$emit("input", event.target.value);
    },
    updateValueDate() {
      if (this.date !== null) {
        this.$emit("input", this.date);
      }
    }
  }
};
</script>

<style scoped>
.base-input-holder {
  background-color: #ffffff;
  padding: 13px 13px 11px 13px;
  transition: ease-in-out all 0.2s;
}
.error .base-input-holder {
  padding: 13px 13px 25px 13px !important;
}
</style>
