<template>
  <div>
    <div v-if="loader.tableLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-sm-8">
          <div class="table-responsive">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th>S/N</th>
                  <th>Account Number</th>
                  <th>Serial Number</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(entry,
                  index) in accountingEntry.currentAccountingEntryEntries"
                  :key="entry.id"
                >
                  <td>
                    <p class="font-size-14 text-truncate">
                      {{ index + 1 }}
                    </p>
                  </td>
                  <td>
                    <p class="font-size-14 text-truncate">
                      {{ entry.accountNumber }}
                    </p>
                  </td>
                  <td>
                    <p class="font-size-14 text-truncate">
                      {{ entry.serialNumber }}
                    </p>
                  </td>
                  <td>
                    <a
                      @click="showDeleteConfirmationModal(entry)"
                      class="action-icon text-danger"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-sm-4">
          <form @submit.prevent="addEntry">
            <div class="row">
              <div class="col-sm-12">
                <div>
                  <BaseInput
                    inputType="number"
                    v-model="formBody.accountNumber"
                    placeholder="Entry Account Number"
                    :hasPreIcon="false"
                    label="Account Number"
                    :class="{
                      error: $v.formBody.accountNumber.$error
                    }"
                    @blur="$v.formBody.accountNumber.$touch()"
                  >
                  </BaseInput>

                  <template v-if="$v.formBody.accountNumber.$error">
                    <p
                      v-if="!$v.formBody.accountNumber.required"
                      class="form-error-message"
                    >
                      Account Number is required
                    </p>
                  </template>
                </div>
              </div>

              <div class="col-sm-12">
                <div>
                  <BaseInput
                    inputType="text"
                    v-model="formBody.serialNumber"
                    placeholder="Serial Number"
                    :hasPreIcon="false"
                    label="Serial Number"
                    :class="{
                      error: $v.formBody.serialNumber.$error
                    }"
                    @blur="$v.formBody.serialNumber.$touch()"
                  >
                  </BaseInput>

                  <template v-if="$v.formBody.serialNumber.$error">
                    <p
                      v-if="!$v.formBody.serialNumber.required"
                      class="form-error-message"
                    >
                      Serial Number is required
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <p class="text-right">
              <span>
                <button type="submit" class="btn btn-secondary mr-1">
                  Add Terminal
                </button>
              </span>
            </p>
          </form>
        </div>
      </div>

      <br />
      <br />
      <div class="row">
        <div class="col-12">
          <p class="text-right">
            <span v-if="loader.actionButtonLoader" class="formLoaderHold">
              <BaseComponentLoader />
            </span>
            <span v-else>
              <button
                @click="showConfirmationModal()"
                class="btn btn-primary mr-1"
              >
                Upload Terminals
              </button>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
// import RouterUtils from "../../../../utils/BaseUtils/RouterUtils";
import NotificationUtils from "../../../../utils/BaseUtils/NotificationUtils";

export default {
  name: "EditTerminalBulkEntriesForm",
  data() {
    return {
      entryTypeOptions: ["Debit", "Credit"],
      formBody: {
        accountNumber: "",
        serialNumber: ""
      }
    };
  },
  async created() {
    // if (
    //   StoreUtils.rootGetter(StoreUtils.getters.router.GET_PAGE_TITLE) ===
    //   RouterUtils.pageTitles.accountingEntry.EDIT
    // ) {
    //   await StoreUtils.dispatch(
    //     StoreUtils.actions.accountingEntry.FETCH_ACCOUNTING_ENTRY_ENTRIES,
    //     StoreUtils.rootGetter(
    //       StoreUtils.getters.accountingEntry.GET_CURRENT_ACCOUNTING_ENTRY_CODE
    //     )
    //   );
    // }
  },
  computed: {
    ...mapState(["accountingEntry", "loader"]),
    entriesTotal() {
      return StoreUtils.rootGetter("accountingEntry/getBulkEntriesTotal");
    }
  },
  validations: {
    formBody: {
      accountNumber: { required },
      serialNumber: { required }
    }
  },
  methods: {
    showDeleteConfirmationModal(entry) {
      let notificationModalBody = {
        type: "warning", //error, success, warning
        mode: "yes-no", // default, yes-no
        title: "Confirmation",
        message: `Are you sure you want to delete this terminal -> ${entry.serialNumber}?`,
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "No",
        yesAction: () => {
          this.removeEntry(entry);
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Yes"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    },
    showConfirmationModal() {
      let notificationModalBody = {
        type: "warning", //error, success, warning
        mode: "yes-no", // default, yes-no
        title: "Confirmation",
        message: "Are you sure you are done adding terminals?",
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "No",
        yesAction: () => {
          StoreUtils.dispatch("terminal/bulkEntriesToBatch", {
            entries: this.accountingEntry.currentAccountingEntryEntries
          });
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Yes"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    },
    addEntry() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.dispatch(
          StoreUtils.actions.accountingEntry.ADD_ENTRY_TO_CURRENT,
          this.formBody
        )
          .then(() => {
            this.formBody = {
              accountNumber: "",
              serialNumber: ""
            };
            this.$v.$reset();
            this.resetPrincipalToggle();
          })
          .catch(() => {});
      }
    },
    removeEntry(entry) {
      StoreUtils.dispatch("accountingEntry/removeEntryFromCurrent", entry);
    }
  }
};
</script>

<style scoped>
.entry-total-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}
.entry-total {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}
.entry-total span {
}
.assign-default {
  position: absolute;
  right: 14px;
  font-weight: 600;
  display: flex;
  cursor: pointer;
}
.assign-default span {
  background-color: #74778c;
  display: flex;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.assign-default.active span {
  background-color: #546ee7;
}
.assign-default label {
  font-size: 10px;
  line-height: 14px;
  font-weight: 800 !important;
  margin-top: 4px;
  display: inline-block;
  cursor: pointer;
}
.assign-default .custom-control-label {
}
.default-value {
  margin: 0;
  font-family: inherit;
  overflow: visible;
  font-weight: 500;
  color: #9e9e9e;
  padding: 0.47rem 0.75rem;
  font-size: 14px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.94rem + 2px);
  line-height: 1.5;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
</style>
