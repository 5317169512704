<template>
  <div>
    <div v-if="appUtilities.moduleLoadingSix">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <label>Customer Type</label>
              <multiselect
                v-model="formBody.customerType"
                :options="customerTypeOptions"
                @close="$v.formBody.customerType.$touch()"
                placeholder="Customer Type"
              ></multiselect>

              <template v-if="$v.formBody.customerType.$error">
                <p
                  v-if="!$v.formBody.customerType.required"
                  class="form-error-message"
                >
                  Customer type is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Class</label>
                <multiselect
                  v-model="formBody.accountClass"
                  :options="accountClassOptions"
                  @close="$v.formBody.accountClass.$touch()"
                  placeholder="Account Class"
                ></multiselect>
              </div>
              <!--                          error-->
              <template v-if="$v.formBody.accountClass.$error">
                <p
                  v-if="!$v.formBody.accountClass.required"
                  class="form-error-message"
                >
                  Account Class is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Type</label>
                <multiselect
                  v-model="formBody.accountType"
                  :options="accountTypeOptions"
                  @close="$v.formBody.accountType.$touch()"
                  placeholder="Account Type"
                ></multiselect>
              </div>
              <!--                          error-->
              <template v-if="$v.formBody.accountType.$error">
                <p
                  v-if="!$v.formBody.accountType.required"
                  class="form-error-message"
                >
                  Account Type is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Officer</label>
                <multiselect
                  v-model="formBody.customerAccountOfficer"
                  :options="accountOfficerOption"
                  placeholder="Account Officer"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
        <p class="text-right">
          <span>
            <button type="submit" class="btn btn-primary mr-1">
              NEXT
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "AccountTypeForm",
  data() {
    return {
      customerTypeOptions: ["BUSINESS", "PERSONAL", "AGENT", "AGGREGATOR"],
      formBody: this.createFreshCreateCustomerFormBodyObject()
    };
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapState(["customer", "appUtilities", "appS3Upload"]),
    ...mapGetters({
      getAccountClassFromOptionUtility:
        "appUtilities/getAccountClassFromOptionUtility",
      accountTypeOptions: "appUtilities/getAccountTypeOption",
      accountOfficerOption: "appUtilities/getAllUsersOption"
    }),
    accountClassOptions() {
      return StoreUtils.rootGetter(
        "appUtilities/getAccountClass2UtilityOption"
      ).filter(accountClass => accountClass.Type !== "FIXED");
    }
  },
  validations: {
    formBody: {
      customerType: { required },
      accountClass: { required },
      accountType: { required }
    }
  },

  created() {
    StoreUtils.commit("form/SET_FORM_STAGE_TO", 0);
    StoreUtils.dispatch("appUtilities/fetchAllAccountClass2");
    StoreUtils.dispatch("appUtilities/fetchAllUsers");
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit("form/BUILD_FORM_BODY", this.formBody);
        if (this.formBody.customerType === "BUSINESS") {
          StoreUtils.commit("form/SET_FORM_STAGE_TO", 2);
        } else {
          StoreUtils.commit("form/SET_FORM_STAGE_TO", 1);
        }
      }
    },
    createFreshCreateCustomerFormBodyObject() {
      return {
        // source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
