<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div v-if="loader.moduleLoadingSeven">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <div
                @click="liquidateAll"
                :class="{ active: principalAmount === 1 }"
                class="assign-default"
              >
                <span></span>
                <label>Principal Account</label>
              </div>
              <div class="form-group">
                <label>Debit Account</label>

                <div v-if="principalAmount !== 1">
                  <multiselect
                    v-model="formBody.debitAccount"
                    :options="customerAccountOptions"
                    placeholder="Debit Account"
                    :class="{ error: amountError }"
                    :disabled:="principalAmount === 1"
                  ></multiselect>
                </div>

                <div v-else class="default-value">
                  Principal Account - ({{ formBody.debitAccount }})
                </div>
              </div>

              <p v-if="amountError" class="form-error-message">
                Debit Account is required
              </p>
            </div>
          </div>
          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'amount'"
                v-model="formBody.amount"
                placeholder="Amount"
                :hasPreIcon="false"
                :label="'Amount'"
                step="0.01"
                :class="{
                  error: $v.formBody.amount.$error
                }"
                @blur="$v.formBody.amount.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.amount.$error">
                <p
                  v-if="!$v.formBody.amount.required"
                  class="form-error-message"
                >
                  Amount is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>FT Product</label>
                <multiselect
                  v-model="formBody.ftProductCode"
                  :options="ftProductCodeUtilityOption"
                  @close="$v.formBody.ftProductCode.$touch()"
                  placeholder="FT Product"
                ></multiselect>
              </div>

              <template v-if="$v.formBody.ftProductCode.$error">
                <p
                  v-if="!$v.formBody.ftProductCode.required"
                  class="form-error-message"
                >
                  FT Product is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <p class="text-right">
          <span v-if="loader.actionButtonLoader" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Top Up
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { numeric, required } from "vuelidate/lib/validators";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "TopUpFixedForm",
  props: {
    currentData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      principalAmount: 0,
      amountError: false,
      formBody: {
        debitAccount: "",
        amount: "",
        ftProductCode: ""
      }
      // customerAccountOptions: ["ress", "ssss"]
    };
  },
  computed: {
    ...mapState(["loader", "appUtilities"]),
    ...mapGetters({
      // customerAccountOptions:
      //   "appUtilities/getCustomerAccountAccountUtilityOption",
      ftProductCodeUtilityOption: "appUtilities/getFtProductCodeUtilityOption"
    }),
    tableDataObject() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.table.GET_CURRENT_TABLE_OBJECT
      );
    },
    customerAccountOptions() {
      return StoreUtils.rootGetter(
        "appUtilities/getCustomerAccountAccountUtilityOption"
      );
    }
  },
  validations: {
    formBody: {
      debitAccount: { required },
      amount: { required, numeric },
      ftProductCode: { required }
    }
  },
  // created() {
  //   StoreUtils.dispatch("appUtilities/fetchAllFundsTransferProduct");
  //   StoreUtils.dispatch("appUtilities/fetchQueryTransactionHistory", {
  //     customerId: this.currentData.accountCustomerId
  //   });
  // },
  mounted() {},
  methods: {
    liquidateAll() {
      this.principalAmount = (this.principalAmount + 1) % 2;
      if (this.principalAmount === 1) {
        this.formBody.debitAccount = this.currentData.accountParent;
      } else {
        this.formBody.debitAccount = "";
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.commit(
          StoreUtils.mutations.form.BUILD_FORM_BODY,
          this.formBody
        );
        StoreUtils.dispatch("investment/topUpFixedDeposit")
          .then(responseData => {
            if (responseData.responseCode === "00") {
              this.$emit("topUpSuccessful", responseData);
            }
            this.formBody = {
              debitAccount: "",
              amount: "",
              ftProductCode: ""
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {
        accountStatus: "",
        accountClass: ""
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
