var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.confirmDefaultPassword.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',[_c('BaseInput',{class:{
              error: _vm.$v.changeDefaultPasswordFormBody.userOldPassword.$error
            },attrs:{"inputType":'password',"placeholder":"Old Password","hasPreIcon":false,"label":'Old Password'},on:{"blur":function($event){return _vm.$v.changeDefaultPasswordFormBody.userOldPassword.$touch()}},model:{value:(_vm.changeDefaultPasswordFormBody.userOldPassword),callback:function ($$v) {_vm.$set(_vm.changeDefaultPasswordFormBody, "userOldPassword", $$v)},expression:"changeDefaultPasswordFormBody.userOldPassword"}}),(_vm.$v.changeDefaultPasswordFormBody.userOldPassword.$error)?[(
                !_vm.$v.changeDefaultPasswordFormBody.userOldPassword.required
              )?_c('p',{staticClass:"form-error-message"},[_vm._v(" Old Password is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
              error: _vm.$v.changeDefaultPasswordFormBody.userPassword.$error
            },attrs:{"inputType":'password',"placeholder":"New Password","hasPreIcon":false,"label":'New Password'},on:{"blur":function($event){return _vm.$v.changeDefaultPasswordFormBody.userPassword.$touch()}},model:{value:(_vm.changeDefaultPasswordFormBody.userPassword),callback:function ($$v) {_vm.$set(_vm.changeDefaultPasswordFormBody, "userPassword", $$v)},expression:"changeDefaultPasswordFormBody.userPassword"}}),(_vm.$v.changeDefaultPasswordFormBody.userPassword.$error)?[(!_vm.$v.changeDefaultPasswordFormBody.userPassword.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" New Password is required ")]):_vm._e()]:_vm._e()],2),_c('div',[_c('BaseInput',{class:{
              error:
                _vm.$v.changeDefaultPasswordFormBody.userPasswordConfirmation
                  .$error
            },attrs:{"inputType":'password',"placeholder":"Confirm New Password","hasPreIcon":false,"label":'Confirm New Password'},on:{"blur":function($event){return _vm.$v.changeDefaultPasswordFormBody.userPasswordConfirmation.$touch()}},model:{value:(_vm.changeDefaultPasswordFormBody.userPasswordConfirmation),callback:function ($$v) {_vm.$set(_vm.changeDefaultPasswordFormBody, "userPasswordConfirmation", $$v)},expression:"changeDefaultPasswordFormBody.userPasswordConfirmation"}}),(
              _vm.$v.changeDefaultPasswordFormBody.userPasswordConfirmation.$error
            )?[(
                !_vm.$v.changeDefaultPasswordFormBody.userPasswordConfirmation
                  .required
              )?_c('p',{staticClass:"form-error-message"},[_vm._v(" Confirm New Password is required ")]):_vm._e(),(
                !_vm.$v.changeDefaultPasswordFormBody.userPasswordConfirmation
                  .sameAsPassword
              )?_c('p',{staticClass:"form-error-message"},[_vm._v(" Confirm Password Mismatch ")]):_vm._e()]:_vm._e()],2)])]),(_vm.auth.moduleLoadingTwo)?_c('span',{staticClass:"formLoaderHold"},[_c('BaseComponentLoader')],1):_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Confirm ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }