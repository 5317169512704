<template>
  <div class="alpha" id="print-area">
    <div class="section-1">
      <!--      <span class="text-1">Transaction Receipt</span>-->
      <div class="section-1-part-1">
        <span class="text-2">Fund Transfer</span>
        <span class="text-3">
          <i
            style="color: #db657b"
            class="bx bx-right-top-arrow-circle"
            v-show="selectedItem.item.trnDrCr === 'DR'"
          ></i>
          <i
            style="color: #12b76a"
            class="bx bx-right-down-arrow-circle"
            v-show="selectedItem.item.trnDrCr === 'CR'"
          ></i>
          &#8358;{{ selectedItem.item.trnAmount }}
        </span>
      </div>

      <div class="section-1-part-2">
        <img src="@/assets/images/CoreBank-Logo-1.png" alt="logo" class="logo" />
      </div>
    </div>

    <hr class="line-1" />

    <div class="section-2">
      <div class="section-2-part-1">
        <span class="text-4" v-show="selectedItem.item.trnDrCr === 'DR'"
          >Debit</span
        >
        <span class="text-4" v-show="selectedItem.item.trnDrCr === 'CR'"
          >Credit</span
        >
      </div>

      <div class="section-2-part-2">
        <span class="text-5">{{ selectedItem.item.accountName }}</span>
        <span class="text-6">{{ selectedItem.item.accountNumber }}</span>
      </div>
    </div>

    <hr class="line-1" />

    <div class="section-2">
      <div class="section-2-part-1">
        <span class="text-4">Counterparty</span>
      </div>

      <div class="section-2-part-2">
        <span class="text-5">{{
          selectedItem.item.counterPartyAccountNumber
        }}</span>
        <span class="text-6">{{
          selectedItem.item.counterPartyAccountName
        }}</span>
      </div>
    </div>

    <hr class="line-1" />

    <div class="section-2">
      <div class="section-2-part-1">
        <span class="text-4">Counterparty Bank</span>
      </div>

      <div class="section-2-part-2">
        <span class="text-5">{{
          selectedItem.item.trnCounterPartyBankName
        }}</span>
      </div>
    </div>

    <hr class="line-1" />

    <div class="section-2">
      <div class="section-2-part-1">
        <span class="text-4">Date</span>
      </div>

      <div class="section-2-part-2">
        <span class="text-5">{{ selectedItem.item.trnDate }}</span>
      </div>
    </div>

    <hr class="line-1" />

    <div class="section-2">
      <div class="section-2-part-1">
        <span class="text-4">Channel/Service</span>
      </div>

      <div class="section-2-part-2">
        <span class="text-5">{{
          selectedItem.item.trnCounterPartyService
        }}</span>
      </div>
    </div>

    <hr class="line-1" />

    <div class="section-2">
      <div class="section-2-part-1">
        <span class="text-4">Narration</span>
      </div>

      <div class="section-2-part-2">
        <span class="text-5">{{ selectedItem.item.journalNarration }}</span>
      </div>
    </div>

    <hr class="line-1" />

    <div class="section-2">
      <div class="section-2-part-1">
        <span class="text-4">Source</span>
      </div>

      <div class="section-2-part-2">
        <span class="text-5">{{ selectedItem.item.source }}</span>
      </div>
    </div>

    <p
      class="button btn btn-primary btn-semi-rounded table-search-btn"
      @click="printPDF"
    >
      Print Receipt
    </p>
  </div>
</template>

<script>
import { jsPDF } from "jspdf"; // Import jsPDF
export default {
  name: "PrintReceiptModal",
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  methods: {
    printPDF() {
      const printButton = document.querySelector(".button"); // Select the button
      printButton.style.display = "none"; // Hide the print button

      const doc = new jsPDF();
      const printArea = document.getElementById("print-area").innerHTML;

      doc.html(printArea, {
        callback: function (doc) {
          doc.save("transaction-receipt.pdf");
          printButton.style.display = "block"; // Show the button again after saving the PDF
        },
        x: 10,
        y: 10,
        width: 180,
        windowWidth: 650,
      });
    },
  },
};
</script>

<style scoped>
.section-1 {
  display: flex;
  justify-content: space-between;
}

.section-1-part-1{
  display: flex;
  flex-direction: column;
}

.section-2 {
  display: flex;
  justify-content: space-between;
}

.section-2-part-2 {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.bx {
  font-size: 20px;
  padding-right: 3px;
}

.text-3 {
  display: flex;
  font-size: 15px;
  font-weight: bold;
}

.text-2 {
  font-size: 15px;
  font-weight: bold;
}

.button {
  float: right;
  margin-top: 10%;
  margin-bottom: 0;
  padding-bottom: 0;
  background-color: #0f4faa;
}

.logo{
  width: 170px;
}
</style>
