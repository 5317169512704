<template>
  <div>
    <BatchInfoCard />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Type</label>
            <multiselect
              v-model="formBody.drCr"
              :options="entryTypeOptions"
              placeholder="Entry Type"
              @close="$v.formBody.drCr.$touch()"
            ></multiselect>
          </div>

          <template v-if="$v.formBody.drCr.$error">
            <p v-if="!$v.formBody.drCr.required" class="form-error-message">
              Type is required
            </p>
          </template>
        </div>

        <div class="col-sm-6">
          <div>
            <!--            <div-->
            <!--              @click="togglePrincipalAmount"-->
            <!--              :class="{ active: principalAmount === 1 }"-->
            <!--              class="assign-default"-->
            <!--            >-->
            <!--              <span></span>-->
            <!--              <label>Principal Amount</label>-->
            <!--            </div>-->

            <div class="form-group">
              <label>Amount</label>

              <input
                v-show="principalAmount !== 1"
                v-model="formBody.amount"
                :type="entryAmountType"
                step="0.01"
                placeholder="Entry Amount"
                class="form-control"
                :class="{ error: entryAmountError }"
                :disabled:="principalAmount === 1"
              />
              <!--              <div v-show="principalAmount == 1" class="default-value">-->
              <!--                Principal Amount-->
              <!--              </div>-->
            </div>

            <p v-if="entryAmountError" class="form-error-message">
              Entry Amount is required
            </p>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Account No </label>

              <input
                v-show="principalAccount !== 1"
                v-model="formBody.accountNumber"
                :type="entryAccountType"
                placeholder="Entry Account"
                class="form-control"
                :class="{ error: entryAccountError }"
                :disabled:="principalAccount === 1"
              />
            </div>

            <p v-if="entryAccountError" class="form-error-message">
              Entry Account is required
            </p>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Narration</label>

              <textarea
                :value="formBody.narration"
                class="form-control"
                placeholder="Narration"
                rows="1"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <BulkUploadModal />
        </div>
        <div class="col-sm-6">
          <p class="text-right">
            <span v-if="loader.actionButtonLoader" class="formLoaderHold">
              <BaseComponentLoader />
            </span>
            <span v-else>
              <button type="submit" class="btn btn-primary mr-1">
                Add Entry To Batch
              </button>
            </span>
          </p>
        </div>
      </div>
      <p class="text-left">
        <a
          href="https://assets-base.s3.amazonaws.com/csv/powerbank/sample-batch-entries.csv"
          target="_blank"
          class="sample-file-download "
        >
          <span class="fas fa-file-download"></span>
          sample-batch-entries.csv
        </a>
      </p>
    </form>
  </div>
</template>

<script>
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import BatchInfoCard from "../../Cards/Journal/BatchInfoCard";
import BulkUploadModal from "../../Modals/Journal/BulkUploadModal";

export default {
  name: "AddEntryToBatchForm",
  components: { BulkUploadModal, BatchInfoCard },
  data() {
    return {
      entryTypeOptions: ["Debit", "Credit"],
      principalAccount: 0,
      entryAccountType: "number",
      entryAccountError: false,
      principalAmount: 0,
      entryAmountType: "number",
      entryAmountError: false,
      formBody: {}
    };
  },
  computed: {
    ...mapState(["journal", "loader"])
  },
  validations: {
    formBody: {
      drCr: { required }
    }
  },
  methods: {
    resetPrincipalToggle() {
      this.principalAccount = 0;
      this.principalAmount = 0;
      this.entryAccountError = false;
      this.entryAmountError = false;
      this.entryAccountType = "number";
      this.entryAmountType = "number";
    },
    togglePrincipalAmount() {
      this.principalAmount = (this.principalAmount + 1) % 2;

      if (this.principalAmount === 1) {
        this.entryAmountType = "text";
        this.formBody.amount = "$AMOUNT";
      } else {
        this.formBody.amount = "";
        this.entryAmountType = "number";
      }
    },
    togglePrincipalAccount() {
      this.principalAccount = (this.principalAccount + 1) % 2;

      if (this.principalAccount === 1) {
        this.entryAccountType = "text";
        this.formBody.entryAccount = "$ACCOUNT";
      } else {
        this.formBody.entryAccount = "";
        this.entryAccountType = "number";
      }
    },
    getEntryDrCr(entryDrCr) {
      switch (entryDrCr) {
        case "Debit":
          return "DR";
        default:
          return "CR";
      }
    },
    submitForm() {
      this.$v.$touch();
      if (this.formBody.amount === "") {
        this.entryAmountError = true;
        return;
      }
      if (this.formBody.accountNumber === "") {
        this.entryAccountError = true;
        return;
      }
      if (!this.$v.$invalid) {
        this.formBody.drCr = this.getEntryDrCr(this.formBody.drCr);
        this.formBody.narration = "";
        this.formBody.bankCode = StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BANK_CODE
        );
        this.formBody.branchCode = StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BRANCH_CODE
        );
        this.formBody.maker = StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_ID
        );
        this.formBody.makerBranchCode = StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BRANCH_CODE
        );
        this.formBody.makerBankCode = StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BANK_CODE
        );

        StoreUtils.dispatch(
          StoreUtils.actions.journal.ADD_ENTRY_TO_BATCH,
          this.formBody
        )
          .then(() => {
            this.formBody = {};
            this.$v.$reset();
            this.resetPrincipalToggle();
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style scoped>
.assign-default {
  position: absolute;
  right: 14px;
  font-weight: 600;
  display: flex;
  cursor: pointer;
}
.assign-default span {
  background-color: #74778c;
  display: flex;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.assign-default.active span {
  background-color: #546ee7;
}
.assign-default label {
  font-size: 10px;
  line-height: 14px;
  font-weight: 800 !important;
  margin-top: 4px;
  display: inline-block;
  cursor: pointer;
}
.assign-default .custom-control-label {
}
.default-value {
  margin: 0;
  font-family: inherit;
  overflow: visible;
  font-weight: 500;
  color: #9e9e9e;
  padding: 0.47rem 0.75rem;
  font-size: 14px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.94rem + 2px);
  line-height: 1.5;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
</style>
