<template>
  <div>
    <div v-if="appUtilities.moduleLoadingEight">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="createTeller">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Teller Type</label>
                <multiselect
                  v-model="createTellerFormBody.tellerType"
                  :options="options"
                  placeholder="Teller Type"
                  @close="$v.createTellerFormBody.tellerType.$touch()"
                ></multiselect>
              </div>

              <!--                          error-->
              <template v-if="$v.createTellerFormBody.tellerType.$error">
                <p
                  v-if="!$v.createTellerFormBody.tellerType.required"
                  class="form-error-message"
                >
                  Teller Type is required
                </p>
              </template>
            </div>
            <div>
              <BaseInput
                :type="'text'"
                v-model="createTellerFormBody.tellerDescription"
                :label="'Description'"
                :placeholder="'Teller Description'"
                :hasPreIcon="false"
                :class="{
                  error: $v.createTellerFormBody.tellerDescription.$error
                }"
                @blur="$v.createTellerFormBody.tellerDescription.$touch()"
              >
              </BaseInput>
              <!--                          error-->
              <template v-if="$v.createTellerFormBody.tellerDescription.$error">
                <p
                  v-if="!$v.createTellerFormBody.tellerDescription.required"
                  class="form-error-message"
                >
                  Teller Description is required
                </p>
              </template>
            </div>
            <div>
              <div class="form-group">
                <label>Teller User</label>
                <multiselect
                  v-model="createTellerFormBody.tellerUserId"
                  :options="allTellerUsersOption"
                  @close="$v.createTellerFormBody.tellerUserId.$touch()"
                  placeholder="Teller User"
                ></multiselect>
              </div>

              <!--                          error-->
              <template v-if="$v.createTellerFormBody.tellerUserId.$error">
                <p
                  v-if="!$v.createTellerFormBody.tellerUserId.required"
                  class="form-error-message"
                >
                  Teller User is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <p class="text-left">
          <span v-if="teller.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create Teller
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "CreateTeller",
  data() {
    return {
      createTellerFormBody: this.createFreshCreateTellerFormBodyObject(),
      options: ["Head of Teller", "Teller"]
    };
  },
  computed: {
    ...mapState(["teller", "appUtilities"]),
    ...mapGetters({
      allTellerUsersOption: "appUtilities/getAllUsersOption",
      getTellerUserIdFromOptionUtility:
        "appUtilities/getUserIdFromOptionUtility"
    })
  },
  validations: {
    createTellerFormBody: {
      tellerType: { required },
      tellerDescription: { required },
      tellerUserId: { required }
    }
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllUsers", null, { root: true });
  },
  methods: {
    createTeller() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createTellerFormBody.tellerChecker = "System";
        this.createTellerFormBody.tellerBankCode = this.$store.getters[
          "user/getUserBankCode"
        ];
        this.createTellerFormBody.tellerBranchCode = this.$store.getters[
          "user/getUserBranchCode"
        ];
        this.createTellerFormBody.tellerMaker = this.$store.getters[
          "user/getUserId"
        ];
        this.createTellerFormBody.tellerUserId = this.getTellerUserIdFromOptionUtility(
          this.createTellerFormBody.tellerUserId
        );
        this.$store
          .dispatch("teller/createTeller", this.createTellerFormBody, {
            root: true
          })
          .then(() => {
            this.createTellerFormBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshCreateTellerFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>
<style scoped></style>
