import axios from "axios";
import LoaderUtils from "../../utils/BaseUtils/LoaderUtils";
import NotificationUtils from "../../utils/BaseUtils/NotificationUtils";
import ErrorUtils from "../../utils/BaseUtils/ErrorUtils";
import StoreUtils from "../../utils/BaseUtils/StoreUtils";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";

class BaseService {
  apiClient = axios.create({
    baseURL: window.__env.api.baseUrl,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    timeout: 50000
  });

  s3ApiClient = axios.create({
    baseURL: window.__env.api.uploadS3BaseUrl,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });



static basePayload() {
    return {
      source: "WEB"
    };
  }

  constructor() {
    this.initAPIClient();
  }

  initAPIClient() {
    this.apiClient.interceptors.request.use(config => {
      const token = StoreUtils.rootGetter(
        StoreUtils.getters.user.GET_USER_TOKEN
      );
      if (token !== null) {
        config.headers.Authorization = token;
      }
      return config;
    });
    this.apiClient.interceptors.response.use(response => {
      if (response.data.responseCode === "92") {
        RouterUtils.changeRouteTo("login");
      }
      return response;
    });
    this.s3ApiClient.interceptors.request.use((config) => {
      // config.headers.Authorization = StoreUtils.rootGetters(StoreUtils.getters.auth.getToken);
      return config;
    });
  }

  async makePostRequest(
    url,
    payload,
    successAction = () => {},
    loaderType = LoaderUtils.types.BLOCKING,
    errorType = ErrorUtils.types.SLIDE,
    showSuccessMessage = true,
    successCondition = response => {
      return response.responseCode === "00";
    },
    getErrorMessage = response => {
      return response.responseMessage;
    }
  ) {

    let loader = this.validateLoaderType(loaderType);
    LoaderUtils.showLoading(true, loader);
    let apiResponse = await this.apiClient.post(url, JSON.stringify(payload));
    LoaderUtils.showLoading(false, loader);
    if (successCondition(apiResponse.data)) {
      if (showSuccessMessage) {
        NotificationUtils.addNotificationSlide(
          apiResponse.data.responseMessage,
          NotificationUtils.type.SUCCESS
        );
      }
      successAction(apiResponse.data);
    } else {
      ErrorUtils.showApiCallError(getErrorMessage(apiResponse.data), errorType);
    }
    return apiResponse.data;
  }

  validateLoaderType(type) {
    if (type === undefined || type === "") {
      return LoaderUtils.types.BLOCKING;
    } else {
      return type;
    }
  }
}

export default BaseService;
