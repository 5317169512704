<template>
  <div>
    <span class="table-action">
      <a @click="openModal()" title="View more"
        ><i class="bx bx-info-circle"
      /></a>
    </span>

    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="500"
      :height="450"
      measure="px"
      animation="zoom"
      @hide="modalOpen = false"
      :closeOnClickMask="true"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <!--          <h4 class="card-title mb-4">Customer Info</h4>-->
          <div>
            <div class="view-more-customer-info">
              <div class="view-more-customer-info-wrap">
                <div class="view-more-top">
                  <h4 class="card-title mb-2">{{ modalTitle }}</h4>
                </div>

                <div class="view-more-base mb-0">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <tbody>
                        <tr v-for="(value, key) in tableData" :key="key">
                          <td>
                            <p class="view-more-base-tag">
                              {{ key }}
                            </p>
                          </td>
                          <td>
                            <p class="view-more-base-value">
                              {{ value }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- end table-responsive -->
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
export default {
  name: "ViewCustomerCheckersQueueInfoModal",
  data() {
    return {
      modalOpen: false,
      rejectVodalFormBody: {}
    };
  },
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    tableData: {
      type: Object,
      required: true
    }
  },
  computed: {
    filteredTableData() {
      return Object.keys(this.tableData).forEach(
        key => this.tableData[key] == null && delete this.tableData[key]
      );
    }
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    }
  }
};
</script>

<style scoped>
.view-customer-info-btn {
  position: relative;
  height: 20px;
  width: 20px;
  display: block;
  padding: 2px 2px;
  margin-top: 0;
  background-color: #795548;
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.view-customer-info-btn i {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}
p {
  color: #495057;
}
</style>
