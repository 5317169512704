<script>
import { mapState } from "vuex";
export default {
  name: "TransactionCodeCheckersQueueTable",
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },
        { key: "cqMaker", label: "Maker", sortable: true },
        { key: "cqName", label: "Name", sortable: true },
        { key: "cqUpdateAt", label: "Update At", sortable: true },
        { key: "cqCreatedAt", label: "Created At", sortable: true },
        { key: "cqStatus", label: "Status", sortable: true },
        { key: "cqType", label: "Type", sortable: true },
        { key: "cqBankCode", label: "Bank Code", sortable: true },
        { key: "action", label: "Action", sortable: true }
      ],
      json_fields: {
        cqMaker: "Maker",
        cqName: "Name",
        cqUpdateAt: "UpdateAt",
        cqChecker: "Checker",
        cqCreatedAt: "CreatedAt",
        cqStatus: "Status",
        cqType: "Type",
        cqBankCode: "BankCode",
        cqJson: "Details"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.transactionCode.transactionCodesCheckersQueue,
      isBusy: state => state.transactionCode.moduleLoadingFour
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  created() {
    this.fetchTableData();
  },
  methods: {
    fetchTableData() {
      const payload = {
        cqType: "TRNCODE",
        cqMode: "READ",
        cqBankCode: this.$store.getters["user/getUserBankCode"]
      };

      this.$store.dispatch(
        "transactionCode/allTransactionCodesCheckersQueue",
        payload,
        {
          root: true
        }
      );
    },
    authorizeSuccessful() {
      //refresh the table
      this.fetchTableData();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;
        return initials;
      } else {
        return initials.substring(0, 2);
      }
    }
  }
};
</script>
<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(cqStatus)="data">
        <p class="name-field">
          <AppStatusBadge :status-value="data.item.cqStatus" />
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(cqUpdateAt)="data">
        <p class="name-field">
          <span>
            {{ data.item.cqUpdateAt | formatDateForTable }}
          </span>
        </p>
      </template>

      <template v-slot:cell(cqCreatedAt)="data">
        <p class="name-field">
          <span>
            {{ data.item.cqCreatedAt | formatDateForTable }}
          </span>
        </p>
      </template>

      <template v-slot:cell(action)="data">
        <p class="name-field customer-name">
          <span class="view-more-info">
            <TransactionCodeCheckersQueueInfoModal
              @authorizeSuccessful="authorizeSuccessful"
              :currentCustomer="data.item"
              :currentCustomerJson="JSON.parse(data.item.cqJson)"
            />
          </span>
        </p>
      </template>
      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>
    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>
<style scoped>
.view-more-info {
  margin-right: 2px;
  display: inline-block;
  color: #ffffff;
}
.view-more-info p {
  color: #1e2b37;
}
.view-more-info a {
  cursor: pointer;
}
.view-more-info a i {
  cursor: pointer;
}
.actual-name {
  margin-left: 2px;
  line-height: 15px;
}
</style>
