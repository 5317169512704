<template>
  <div>
    <div>
      <el-card class="form">
        <form-schema ref="formSchema" :schema="theTheSchema" v-model="model">
          <el-button type="primary" @click="submit">Subscribe</el-button>
          <el-button type="reset">Reset</el-button>
        </form-schema>
      </el-card>
    </div>
  </div>
</template>

<script>
import FormSchema from "vue-json-schema";
import { Option } from "element-ui";

FormSchema.setComponent("form", "el-form", ({ vm }) => {
  // vm is the FormSchema VM

  const labelWidth = "120px";
  const model = vm.data;
  const rules = {};

  vm.fields.forEach(field => {
    const type =
      field.schemaType === "array" && field.type === "radio"
        ? "string"
        : field.schemaType;
    const required = field.required;
    const message = field.title;
    const trigger = ["radio", "checkbox", "select"].includes(field.type)
      ? "change"
      : "blur";

    // http://element.eleme.io/#/en-US/component/form#validation
    rules[field.name] = { type, required, message, trigger };
  });

  // returning the form props
  return { labelWidth, rules, model };
});

// http://element.eleme.io/#/en-US/component/form#validation
FormSchema.setComponent("label", "el-form-item", ({ field }) => ({
  prop: field.name
}));

FormSchema.setComponent("email", "el-input");
FormSchema.setComponent("text", "el-input");
FormSchema.setComponent("textarea", "el-input");
FormSchema.setComponent("checkbox", "el-checkbox");
FormSchema.setComponent("switch", "el-switch");
FormSchema.setComponent("radio", "el-radio");
FormSchema.setComponent("select", "el-select");

// You can also use the component object
FormSchema.setComponent("option", Option);

// By default `<h1/>` is used to render the form title.
// To override this, use the `title` type:
FormSchema.setComponent("title", "h2");

// By default `<p/>` is used to render the form title.
// To override this, use the `description` type:
FormSchema.setComponent("description", "small");

// By default `<div/>` is used to render the message error.
// To override this, use the `error` type:
FormSchema.setComponent("error", "el-alert", ({ vm }) => ({
  type: "error",
  title: vm.error
}));

export default {
  name: "GenerateProductForm",
  props: {
    theSchema: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      schema: {},
      model: {}
    };
  },

  computed: {
    theTheSchema() {
      return this.theSchema;
    }
  },

  methods: {
    testSubmit() {},

    processModel() {
      var newObj = [];
      for (let [key, value] of Object.entries(this.model)) {
        newObj.push({ key: key, value: value });
      }
    },

    initFormFields: async function() {
      const payload = {
        templateBankCode: "00001",
        templateType: "TRANCODE"
      };
      await this.$store.dispatch("product/getAllFields", payload);
      this.schema = this.$store.getters["product/formObj"];
    },

    submit: function() {
      this.$refs.formSchema.form().validate(valid => {
        if (valid) {
          // this.model contains the valid data according your JSON Schema.
          // You can submit your model to the server here
          const payload = {
            requestType: "create",
            products: this.model
          };
          this.$store.dispatch("product/createProduct", payload);
          this.processModel();
          this.$refs.formSchema.clearErrorMessage();
        } else {
          this.$refs.formSchema.setErrorMessage(
            "Please fill out the required fields"
          );
          return false;
        }
      });
    }
  },
  components: { FormSchema }
};
</script>

<style scoped>
@import "https://unpkg.com/element-ui@2.13.0/lib/theme-chalk/index.css";

.form {
  text-align: left;
  width: 600px;
  margin: auto;
}

.el-input__inner {
}

h2 {
  font-size: 1.7em;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.2em;
}

h2 + small {
  display: block;
  text-align: center;
  margin-bottom: 1.2em;
}

small {
  line-height: 20px;
  display: block;
}

.el-alert {
  margin-bottom: 15px;
}
</style>
