<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(bankCode)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.bankCode }}</span>
        </p>
      </template>

      <!-- A custom formatted column -->
      <template v-slot:cell(bankName)="data">
        <p class="name-field">
          <span class="avatar">{{ data.item.bankName | getInitials }}</span>
          <span>{{ data.item.bankName | firstCaseCapitalSpace }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(bankNIBSSCode)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.bankNIBSSCode }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(bankStatus)="data">
        <p class="name-field">
          <span
            v-if="data.item.bankStatus === 'ACTIVE'"
            class="badge badge-pill badge-soft-success font-size-12"
            >{{ data.item.bankStatus }}</span
          >
          <span
            v-if="data.item.bankStatus === 'INACTIVE'"
            class="badge badge-pill badge-soft-danger font-size-12"
            >{{ data.item.bankStatus }}</span
          >
          <span
            v-if="data.item.bankStatus === 'PENDING'"
            class="badge badge-pill badge-soft-warning font-size-12"
            >{{ data.item.bankStatus }}</span
          >
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(action)="data">
        <p class="name-field">
          <b-dropdown
            class="card-drop"
            variant="white"
            right
            toggle-class="p-0"
          >
            <template v-slot:button-content>
              <i class="mdi mdi-dots-horizontal font-size-18" />
            </template>

            <b-dropdown-item @click="showEditForm(data.item)">
              <i class="fas fa-pencil-alt text-success mr-1" /> Edit
            </b-dropdown-item>

            <!--            Edit Table Modal-->
            <!--            <EditBankForm :dataItem="data.item" />-->

            <!--            <b-dropdown-item>-->
            <!--              <i class="fas fa-trash-alt text-danger mr-1"></i> Delete-->
            <!--            </b-dropdown-item>-->
          </b-dropdown>
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <!--      <template v-slot:cell()="data">-->
      <!--        <i>{{ data.value }}</i>-->
      <!--      </template>-->
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!--    // edit form-->
    <vodal
      :show="editModalOpen"
      :width="500"
      :height="450"
      measure="px"
      animation="zoom"
      @hide="editModalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Edit Bank</h4>

          <EditBankForm
            :editData="currentEditDataItem"
            @close-edit-modal="closeEditModal()"
          />
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditBankForm from "../../Forms/Bank/EditBankForm";

export default {
  name: "AllBanksTable",
  components: {
    EditBankForm
  },
  data() {
    return {
      editModalOpen: false,
      currentEditDataItem: {},
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        { key: "bankCode", label: "Bankcode", sortable: true },
        { key: "bankName", label: "Name", sortable: true },
        { key: "bankNIBSSCode", label: "NIBSS Code", sortable: true },
        { key: "bankCity", label: "City", sortable: true },
        { key: "bankState", label: "State", sortable: true },
        { key: "bankStatus", label: "Status", sortable: true },
        { key: "action", label: "Actions", sortable: true }
      ],

      json_fields: {
        "Bank Code": "bankCode",
        "Updated At": "bankUpdatedAt",
        "Bank State": "bankState",
        "Transaction Limit": "bankTransactionLimit",
        "Created By": "bankCreatedBy",
        "Bank Name": "bankName",
        "Bank Source": "bankSource",
        "Bank Address": "bankAddress",
        "Bank City": "bankCity",
        "Bank Country": "bankCountry",
        "Bank ID": "bankID",
        "Bank Status": "bankStatus",
        "Created At": "bankCreatedAt",
        "NIBSS Code": "bankNIBSSCode"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.bank.banks,
      isBusy: state => state.bank.moduleLoadingThree
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    closeEditModal() {
      this.editModalOpen = false;
    },
    showEditForm(_item) {
      this.currentEditDataItem = _item;

      //show the edit bank form
      this.editModalOpen = true;
    },
    returnItems() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInitials(_name) {
      let parts = _name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      if (initials.length === 2) {
        return initials;
      } else if (initials.length < 2) {
        initials = initials + initials;

        return initials;
      } else {
        return initials.substring(0, 2);
      }
    }
  },

  created() {
    const payload = {
      Action: "Read All",
      source: "CBA",
      readAll: ""
    };

    this.$store.dispatch("bank/allBanks", payload);
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
