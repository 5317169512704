<template>
  <div class="table-section col-12">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i>
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
    >
      <!-- A virtual composite column -->
      <template v-slot:cell(action)="data">
        <p class="name-field">
          <b-dropdown
            class="card-drop"
            variant="white"
            right
            toggle-class="p-0"
          >
            <template v-slot:button-content>
              <i class="mdi mdi-dots-horizontal font-size-18" />
            </template>

            <b-dropdown-item @click="showEditForm(data.item)">
              <i class="fas fa-pencil-alt text-success mr-1" /> Edit
            </b-dropdown-item>
          </b-dropdown>
        </p>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <vodal
      :show="editModalOpen"
      :width="500"
      :height="450"
      measure="px"
      animation="zoom"
      @hide="editModalOpen = false"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Edit Product</h4>

          <EditProductForm
            v-if="currentEditDataItem.Code"
            :product-code="currentEditDataItem.Code"
            :current-value="currentEditDataItem"
            @editSuccessful="closeEditModal"
          />
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import EditProductForm from "../../Forms/Product/EditProductForm";

export default {
  name: "ViewDynamicProductsTable",
  components: { EditProductForm },
  data() {
    return {
      currentEditDataItem: {},
      editModalOpen: false,
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [10, 15, 20],
      sortDirection: "asc",
      filter: null,
      columns: [],
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [],

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.product.dynamicProducts,
      isBusy: state => state.product.moduleLoadingSix
    }),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  created() {
    this.fetchTableData();
    this.filter = " ";
  },

  methods: {
    editSuccessful() {
      // hide the edit modal
      this.editModalOpen = false;

      //refresh the table
      this.fetchTableData();
    },
    showEditForm(item) {
      this.currentEditDataItem = item;

      //show the edit bank form
      this.editModalOpen = true;
    },
    closeEditModal() {
      this.currentEditDataItem = {};
      this.fetchTableData();

      //show the edit bank form
      this.editModalOpen = false;
    },
    async fetchTableData() {
      await StoreUtils.dispatch("product/readProduct");
      // this.items = this.rpt.currentReport;
      // Set the initial number of items
      this.totalRows = this.items.length;
      this.filter = " ";
    }
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
