<template>
  <div>
    <div v-if="ftStage === 1">
      <AccountEnquiryForm />
    </div>
    <div v-if="ftStage === 2">
      <FixedDepositFinalForm />
    </div>
  </div>
</template>

<script>
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import AccountEnquiryForm from "../../../Forms/Investment/FixedDeposit/AccountEnquiryForm";
import FixedDepositFinalForm from "../../../Forms/Investment/FixedDeposit/FixedDepositFinalForm";

export default {
  name: "CreateFixedDepositCard",
  components: { FixedDepositFinalForm, AccountEnquiryForm },
  computed: {
    ftStage() {
      return StoreUtils.rootGetters(StoreUtils.getters.ft.GET_FT_STAGE);
    }
  },
  methods: {}
};
</script>

<style scoped></style>
