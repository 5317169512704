import BankService from "../../services/oldServices/BankService.js";

export const namespaced = true;

//utility
function objIsEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    moduleLoadingTwo: true,
    moduleLoadingThree: true,
    moduleLoadingFour: false,
    createBankFormBody: {
      source: "web"
    },
    bankStatData: {},
    banks: []
  };
};
export const state = getDefaultState();

export const getters = {
  // Checks if bank stats have already been fetched
  isBankStatsFetched: state => {
    return !objIsEmpty(state.bankStatData);
  }
};

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_BANK_STATS(state, payload) {
    state.bankStatData = payload;
  },
  SET_ALL_BANKS(state, payload) {
    state.banks = payload;
  }
};

export const actions = {
  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  },

  // fetch Bank Stats Panel
  fetchBankStatsPanelData({ commit, dispatch, getters, rootGetters }) {
    let isBankStatsFetched = getters.isBankStatsFetched;

    if (isBankStatsFetched) {
      return false;
    } else {
      // start loader
      commit("SET_LOADING_TWO", true);

      // build request body
      const payload = {
        branchBankCode: rootGetters["user/getUserBankCode"],
        source: "CBA",
        readAll: "NO"
      };
      return BankService.fetchBankStatsData(payload)
        .then(response => {
          // stop loader
          commit("SET_LOADING_TWO", false);

          let responseData = response.data;

          if (responseData.responseCode === "00") {
            // filtering the data to conform to the stats standard
            // then set it to state
            const totalBanks = responseData.totalBanks,
              totalTransactions = responseData.totalTransactions,
              totalCustomers = responseData.totalCustomers,
              totalBranches = responseData.totalBranches;

            const statData = [
              {
                icon: "bx bxs-bank",
                title: "Total Banks",
                value: totalBanks
              },
              {
                icon: "bx bx-git-branch",
                title: "Total Branches",
                value: totalBranches
              },
              {
                icon: "bx bxs-business",
                title: "Total Customers",
                value: totalCustomers
              },
              {
                icon: "bx bx-purchase-tag-alt",
                title: "Total Transactions",
                value: totalTransactions
              }
            ];

            commit("SET_BANK_STATS", statData);
          } else {
            // push Notification
            const notification = {
              type: "error",
              variant: "danger",
              message: `Error - ${responseData.responseMessage}`
            };
            dispatch("notification/add", notification, { root: true });
          }
        })
        .catch(error => {
          //stop loader
          commit("SET_LOADING", false);

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Something went wrong: Try Again`
          };
          dispatch("notification/add", notification, { root: true });

          throw error;
        });
    }
  },

  // create a new Bank
  createBank({ commit, dispatch, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING", true);

    // append the Created by to the payload
    payload.bankCreatedBy = rootGetters["user/getUserId"];


    return BankService.createBank(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `Bank Created Successfully`
          };
          dispatch("notification/add", notification, { root: true });
        } else {

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },

  // create a new Bank
  allBanks({ commit, dispatch }, payload) {
    commit("SET_TEST", "test");

    // start loader
    commit("SET_LOADING_THREE", true);

    return BankService.allBanks(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_BANKS", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        throw error;
      });
  },

  // create a new Bank
  editBank({ commit, dispatch }, payload) {
    // start loader
    commit("SET_LOADING", true);

    // // append the Created by to the payload
    // payload.bankCreatedBy = rootGetters["user/getUserId"];


    return BankService.editBank(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `Bank Updated Successfully`
          };
          dispatch("notification/add", notification, { root: true });
        } else {

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        // push Notification
        const notification = {
          type: "error",
          variant: "danger",
          message: `Error - ${error}`
        };
        dispatch("notification/add", notification, { root: true });

        throw error;
      });
  },

  setModuleLoadingFour({ commit }, payload) {
    commit("SET_LOADING_FOUR", payload);
  },
  // upload Bank Logo
  uploadBankLogo({ commit, dispatch, rootGetters }, payload) {
    // start loader
    commit("SET_LOADING_FOUR", true);

    // append the Created by to the payload
    payload.bankCreatedBy = rootGetters["user/getUserId"];


    return BankService.uploadBankLogo(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_FOUR", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // moved saved files to permanent location
          dispatch("appS3Upload/moveBankLogoToFinalLocation", null, { root: true });

          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `Bank Created Successfully`
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          // delete saved files from staging area
          dispatch("appS3Upload/deleteBankLogoFromStagingArea", null, { root: true });

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_FOUR", false);

        throw error;
      });
  }
};

// ===
// Private helpers
// ===
