<template>
  <span
    class="badge badge-pill font-size-12"
    :class="{
      'badge-danger':
        `${statusValue}` === 'REJECTED' || `${statusValue}` === 'DECLINED',
      'badge-soft-danger':
        `${statusValue}` === 'INACTIVE',
      'badge-warning': `${statusValue}` === 'PENDING',
      'badge-success': `${statusValue}` === 'AUTHORIZED' || `${statusValue}` === 'RESOLVED',
      'badge-soft-success': `${statusValue}` === 'ACTIVE',
      'badge-soft-primary': `${statusValue}` === 'NEW'
    }"
    >{{ statusValue | firstCaseCapitalSpace }}</span
  >
</template>

<script>
export default {
  name: "AppLoading",
  props: {
    statusValue: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
/* generated by https://loading.io/ */
</style>
