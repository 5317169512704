<template>
  <div>
    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
      @activate-tab="resetFt"
    >
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">Cash Withdrawals</span>
        </template>

        <div class="tab-main-content">
          <code class="mb-4">
            Fill to process cash Withdrawals
          </code>
          <br />
          <br />
          <br />

          <div class="tab-form">
            <div v-if="appUtilities.moduleLoading">
              <BaseComponentLoader />
            </div>
            <div v-else>
              <div v-if="userTillAccountNumber === '' && userVaultAccountNumber === ''">
                <code class="mb-4">
                  You dont have access rights to perform Cash Withdrawal
                </code>
              </div>
              <div v-else>
                <CashWithdrawalCard />
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="far fa-user"></i>
          </span>
          <span class="d-none d-sm-inline-block">Cash Deposit</span>
        </template>

        <div class="tab-main-content">
          <code class="mb-4">
            Fill to process Cash Deposit
          </code>

          <br />
          <br />
          <br />

          <div class="tab-form">
            <div v-if="appUtilities.moduleLoading">
              <BaseComponentLoader />
            </div>
            <div v-else>
              <div v-if="userTillAccountNumber === '' && userVaultAccountNumber === ''">
                <code class="mb-4">
                  You dont have access rights to perform Cash Deposit
                </code>
              </div>
              <div v-else>
                <CashDepositCard />
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import CashWithdrawalCard from "../../Cards/FT/CashWithdrawalCard";
import CashDepositCard from "../../Cards/FT/CashDepositCard";
export default {
  name: "CashWithdrawalTab",
  components: { CashDepositCard, CashWithdrawalCard },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user", "appUtilities", "fundsTransfer"]),
    ...mapGetters({
      getBankNameFromCodeUtility: "appUtilities/getBankNameFromCodeUtility",
      userTillAccountNumber: "user/getUserTillAccountNumber",
      userVaultAccountNumber: "user/getUserVaultAccountNumber"
    })
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllTransactionCode", null, {
      root: true
    });
  },
  methods: {
    resetFt() {
      StoreUtils.dispatch(StoreUtils.actions.ft.RESET);
    }
  },
  beforeDestroy() {
    this.resetFt();
  }
};
</script>

<style scoped></style>
