<template>
  <div>
    <div v-if="internalAccount.isModuleLoadingFour === false">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="createInternalAccount">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createInternalAccountFormBody.accountName"
                placeholder="Account Name"
                :hasPreIcon="false"
                :label="'Account Name'"
                :class="{
                  error: $v.createInternalAccountFormBody.accountName.$error
                }"
                @blur="$v.createInternalAccountFormBody.accountName.$touch()"
              >
              </BaseInput>

              <template
                v-if="$v.createInternalAccountFormBody.accountName.$error"
              >
                <p
                  v-if="!$v.createInternalAccountFormBody.accountName.required"
                  class="form-error-message"
                >
                  Account Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Type</label>
                <multiselect
                  v-model="createInternalAccountFormBody.accountType"
                  :options="options"
                  placeholder="Account Type"
                  @close="$v.createInternalAccountFormBody.accountType.$touch()"
                ></multiselect>
              </div>

              <template
                v-if="$v.createInternalAccountFormBody.accountType.$error"
              >
                <p
                  v-if="!$v.createInternalAccountFormBody.accountType.required"
                  class="form-error-message"
                >
                  Account Type is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Class</label>
                <multiselect
                  v-model="createInternalAccountFormBody.accountClass"
                  :options="accountClassOptions"
                  @close="
                    $v.createInternalAccountFormBody.accountClass.$touch()
                  "
                  placeholder="Account Class"
                ></multiselect>
              </div>

              <template
                v-if="$v.createInternalAccountFormBody.accountClass.$error"
              >
                <p
                  v-if="!$v.createInternalAccountFormBody.accountClass.required"
                  class="form-error-message"
                >
                  Account Class is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="text-right">
              <span v-if="internalAccount.moduleLoading" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Create Internal Account
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateInternalAccount",
  data() {
    return {
      createInternalAccountFormBody: this.createFreshInternalAccountFormBodyObject(),

      options2: ["YES", "NO"]
    };
  },
  computed: {
    ...mapState(["glLevel2", "internalAccount"]),
    ...mapGetters({
      options: "glLevel2/isGlLevel2SpecificToAllBanks",
      accountClassOptions: "appUtilities/getAccountClass2UtilityOption",
      getAccountClassFromOptionUtility:
        "appUtilities/getAccountClassFromOptionUtility"
    })
  },
  validations: {
    createInternalAccountFormBody: {
      accountName: { required },
      accountType: { required },
      accountClass: { required },
      accountCurrency: { required }
    }
  },
  async created() {
    const payload = {
      glLevel2BankCode: this.$store.getters["user/getUserBankCode"],
      readAll: "NO"
    };
    await this.$store.dispatch("glLevel2/allGlLevel2SpecificToBanks", payload, {
      root: true
    });
    await this.$store.dispatch("appUtilities/fetchAllAccountClass2", null, {
      root: true
    });
  },
  methods: {
    createInternalAccount() {
      this.createInternalAccountFormBody.accountBankCode = this.$store.getters[
        "user/getUserBankCode"
      ];
      this.createInternalAccountFormBody.accountMaker = this.$store.getters[
        "user/getUserId"
      ];
      this.createInternalAccountFormBody.accountCustomerId = this.$store.getters[
        "user/getUserId"
      ];
      this.createInternalAccountFormBody.accountBranchCode = this.$store.getters[
        "user/getUserBranchCode"
      ];
      this.createInternalAccountFormBody.accountCurrency = "NGN";
      this.createInternalAccountFormBody.accountClass = this.getAccountClassFromOptionUtility(
        this.createInternalAccountFormBody.accountClass
      );

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch(
            "internalAccount/createInternalAccount",
            this.createInternalAccountFormBody,
            {
              root: true
            }
          )
          .then(() => {
            this.createInternalAccountFormBody = {};
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshInternalAccountFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped></style>
