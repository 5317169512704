// create a new Privilege

import PrivilegeService from "../../services/oldServices/PrivilegeService";

const getDefaultState = () => {
  return {
    test: "",
    moduleLoading: false,
    // moduleLoadingTwo: true,
    moduleLoadingThree: true,
    createPrivilegesFormBody: {
      source: "web"
    },
    // bankStatData: {},
    privileges: []
  };
};
export const state = getDefaultState();

export const mutations = {
  // RESET State
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  // SET_LOADING_TWO(state, status) {
  //     state.moduleLoadingTwo = status;
  // },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },

  SET_ALL_PRIVILEGES(state, payload) {
    state.privileges = payload;
  }
};
export const actions = {
  createPrivilege({ commit, dispatch }, payload) {
    commit("SET_TEST", "test");

    // start loader
    commit("SET_LOADING", true);

    return PrivilegeService.createPrivilege(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          // push Notification
          const notification = {
            type: "success",
            variant: "success",
            message: `${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        } else {

          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - ${responseData.responseMessage}`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING", false);

        throw error;
      });
  },
  // list all tellers
  allPrivileges({ commit, dispatch }, payload) {
    commit("SET_TEST", "test");

    // start loader
    commit("SET_LOADING_THREE", true);

    return PrivilegeService.allPrivileges(payload)
      .then(response => {
        // stop loader
        commit("SET_LOADING_THREE", false);

        let responseData = response.data;

        if (responseData.responseCode === "00") {
          commit("SET_ALL_PRIVILEGES", responseData.data);
        } else {
          // push Notification
          const notification = {
            type: "error",
            variant: "danger",
            message: `Error - Error Loading Table | Try again`
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .catch(error => {
        //stop loader
        commit("SET_LOADING_THREE", false);

        throw error;
      });
  },

  // Reset State
  resetState({ commit }) {
    commit("RESET_STATE");
  }
};
