<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-sm-12">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="formBody.batchName"
              placeholder="Batch Name"
              :hasPreIcon="false"
              :label="'Batch Name'"
              :class="{
                error: $v.formBody.batchName.$error
              }"
              @blur="$v.formBody.batchName.$touch()"
            >
            </BaseInput>

            <template v-if="$v.formBody.batchName.$error">
              <p
                v-if="!$v.formBody.batchName.required"
                class="form-error-message"
              >
                Batch Name is required
              </p>
            </template>
          </div>
        </div>
      </div>
      <p class="text-right">
        <span v-if="loader.actionButtonLoader" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Open Batch
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "OpenTerminalBatchForm",
  data() {
    return {
      formBody: this.createFreshFormBodyObject()
    };
  },
  computed: {
    ...mapState(["loader"])
  },
  validations: {
    formBody: {
      batchName: { required }
    }
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.dispatch("terminal/openTerminalBatch", this.formBody)
          .then(() => {
            this.formBody = {
              // source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
