<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-6">
            <div>
              <div class="form-group">
                <label>From</label>
                <date-picker
                  v-model="formBody.startDate"
                  placeholder="Start Date"
                  :first-day-of-week="1"
                  lang="en"
                  confirm
                ></date-picker>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div>
              <div class="form-group">
                <label>To</label>
                <date-picker
                  v-model="formBody.endDate"
                  placeholder="End Date"
                  :first-day-of-week="1"
                  lang="en"
                  confirm
                ></date-picker>
              </div>
            </div>
          </div>

          <div class="col-10">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.searchItem"
                placeholder="Account Number or Reference"
                :hasPreIcon="false"
                :label="'Search Keyword'"
              >
              </BaseInput>
            </div>
          </div>
          <div class="col-2">
            <p class="text-right">
              <span v-if="loader.tableLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else class="d-block">
                <button
                  class="btn btn-primary btn-semi-rounded table-search-btn"
                >
                  <img
                    src="https://assets-base.s3.amazonaws.com/img/powerbank/search.png"
                    alt="search"
                  />
                  Search
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>

    <div class="search-result-info">
      <p class="text-center">
        <span v-if="searched">
          {{ formBody.startDate | dateFormatForTable }} -
          {{ formBody.endDate | dateFormatForTable }}
          <span v-if="formBody.searchItem !== ''">
            =>"{{ formBody.searchItem }}"</span
          >
        </span>
        <span v-else>Recent Transactions</span>

        <span v-if="resultCount > 0" class="sub">
          {{ resultCount }} records</span
        >
        <span v-else class="sub"> 0 record</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  name: "SearchTransactionHistoryForm",
  props: {
    mode: {
      type: String,
      default: "teller-transaction-history"
    },
    resultCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      formBody: {
        searchItem: "",
        startDate: "",
        endDate: ""
      },
      searched: false
    };
  },
  components: {
    DatePicker
  },
  computed: {
    ...mapState(["loader"])
  },
  methods: {
    submit() {
      this.searched = true;
      let payload = {
        bankCode: StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BANK_CODE
        ),
        readType: "search",
        searchItem: this.formBody.searchItem,
        startDate:
          this.formBody.startDate !== ""
            ? moment(this.formBody.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          this.formBody.endDate !== ""
            ? moment(this.formBody.endDate).format("YYYY-MM-DD")
            : ""
      };

      if (this.mode === "teller-transaction-history") {
        payload.maker = StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_ID
        );

        StoreUtils.dispatch("cashTransaction/cashTransactionsHistory", payload);
      }

      if (this.mode === "all-transaction-history") {
        this.$store.dispatch("cashTransaction/allTransactionHistory", payload, {
          root: true
        });
      }

      if (this.mode === "customer-transactions") {
        this.$store.dispatch("cashTransaction/customerTransactions", payload, {
          root: true
        });
      }
    },
    createFreshFormBodyObject() {
      return {
        searchItem: ""
      };
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
