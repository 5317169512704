<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Create Loan Products </h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <charges-product-form></charges-product-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Create QueryView Component
 */

import { required } from "vuelidate/lib/validators";


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import ChargesProductForm from "../../components/Kleyton/ChargesProductForm";

export default {
  page: {
    title: "Create Charges Products",
    meta: [{ name: "Create Charges View", content: window.__env.app.description }]
  },
  components: {
    ChargesProductForm,
    Layout,
    PageHeader
  },
  data() {
    return {
      createBankFormBody: {
        source: "web"
      },
      title: "Create Charges View",
      statData: [
        {
          icon: "bx bxs-bank",
          title: "Total Banks",
          value: "23"
        },
        {
          icon: "bx bx-git-branch",
          title: "Total Branches",
          value: "203"
        },
        {
          icon: "bx bxs-business",
          title: "Total Customers",
          value: "8"
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Total Transactions",
          value: "213"
        }
      ],

      items: [
        {
          text: "Bank",
          href: "/"
        },
        {
          text: "Create QueryView",
          active: true
        }
      ]
    };
  },
  validations: {
    createQueryFormBody: {
      date: { required },
      message: { required },
      country: { required },
      name: { required }
    }
  },
  methods: {

  }
};
</script>
