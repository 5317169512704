<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
<!--      <div class="col-xl-3">-->
<!--        <BranchStatsPanel />-->
<!--      </div>-->
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Book Loan</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <BookLoanForm></BookLoanForm>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Book Loan Component
 */


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";

export default {
  page: {
    title: "Book Loan",
    meta: [{ name: "Book Loan", content: window.__env.app.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Book Loan",
      items: [
        {
          text: "Loan",
          href: "/"
        },
        {
          text: "Book Loan",
          active: true
        }
      ]
    };
  }
};
</script>
