<template>
  <div>
    <div v-if="branch.moduleLoading">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <SampleStatsPanel :statData="branch.branchStatData" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BranchStatsPanel",
  computed: {
    ...mapState(["branch"])
  },
  created() {
    this.fetchBranchStatsData();
  },
  methods: {
    fetchBranchStatsData() {
      this.$store.dispatch("branch/fetchBranchStatsPanelData", null, {
        root: false
      });
    }
  }
};
</script>

<style scoped></style>
