<template>
  <div class="table-section col-12">
    <div class="table-top">
<!--      <p>{{ user.currentUser.userDepartment }}</p>-->
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(reportName)="data">
        <p class="name-field">
          <span>
            <a
              @click="viewReport(data.item.reportName)"
              class="view-customer-info-btn"
              v-b-tooltip.hover.right
              title="View Report"
            >
              <i class="bx bx-info-circle"></i>
            </a>
          </span>
          <span>{{ data.item.reportName }}</span>
        </p>
      </template>
      <!-- A virtual composite column -->
      <template v-slot:cell(reportStatus)="data">
        <p class="name-field">
          <span
            v-if="data.item.reportStatus === 'ACTIVE'"
            class="badge badge-pill badge-soft-success font-size-12"
            >{{ data.item.reportStatus }}</span
          >
          <span
            v-if="data.item.reportStatus === 'INACTIVE'"
            class="badge badge-pill badge-soft-danger font-size-12"
            >{{ data.item.reportStatus }}</span
          >
          <span
            v-if="data.item.reportStatus === 'PENDING'"
            class="badge badge-pill badge-soft-warning font-size-12"
            >{{ data.item.reportStatus }}</span
          >
        </p>
      </template>

      <template v-slot:cell(action)="data">
        <span
          ><button
            class="btn btn-sm btn-outline-secondary"
            @click="queryReport(data.item.reportName)"
          >
            Query Report
          </button></span
        >
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "AllReportsTable",
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        // { key: "reportID", label: "Report ID", sortable: true },
        { key: "reportName", label: "Name", sortable: true },
        { key: "reportDescription", label: "Description", sortable: true },
        { key: "reportOwner", label: "Owner", sortable: true },
        { key: "reportCategory", label: "Category", sortable: true },
        { key: "reportStatus", label: "Status", sortable: true },
        { key: "action", label: "Action", sortable: true }
      ],
      json_fields: {
        reportType: "reportType",
        reportId: "reportId",
        reportName: "reportName",
        reportDescription: "reportDescription",
        reportOwner: "reportOwner",
        reportBankCode: "reportBankCode",
        reportCategory: "reportCategory",
        reportStatus: "reportStatus",
        reportCreatedAt: "reportCreatedAt",
        reportUpdatedAt: "reportUpdatedAt"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.rpt.allReports,
      isBusy: state => state.loader.tableLoader,
      user: state => state.user
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    returnItems() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    queryReport(reportName) {
      StoreUtils.dispatch("rpt/queryReport", reportName);
    },

    viewReport(reportName) {
      StoreUtils.dispatch("rpt/viewReport", reportName);
    },

    async fetchTableData() {
      await StoreUtils.dispatch(
        StoreUtils.actions.rpt.FETCH_ALL_REPORTS,
        this.user.currentUser.userDepartment
      );
    }
  },

  created() {
    this.fetchTableData();
  }
};
</script>

<style scoped></style>
