var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loader.componentLoader)?_c('div',[_c('BaseComponentLoader')],1):_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.formBody.customerBusinessName.$error
              },attrs:{"inputType":'text',"placeholder":"Business Name","hasPreIcon":false,"label":'Business Name'},on:{"blur":_vm.trimTheBusinessName},model:{value:(_vm.formBody.customerBusinessName),callback:function ($$v) {_vm.$set(_vm.formBody, "customerBusinessName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formBody.customerBusinessName"}}),(_vm.$v.formBody.customerBusinessName.$error)?[(!_vm.$v.formBody.customerBusinessName.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Business Name is required. ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('label',[_vm._v("Company Type")]),_c('multiselect',{attrs:{"options":_vm.customerCompanyTypeOptions,"placeholder":"Company Type"},on:{"close":function($event){return _vm.$v.formBody.customerCompanyType.$touch()}},model:{value:(_vm.formBody.customerCompanyType),callback:function ($$v) {_vm.$set(_vm.formBody, "customerCompanyType", $$v)},expression:"formBody.customerCompanyType"}}),(_vm.$v.formBody.customerCompanyType.$error)?[(!_vm.$v.formBody.customerCompanyType.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Company type is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Date of Incorporation")]),_c('date-picker',{attrs:{"placeholder":"Date of Incorporation","first-day-of-week":1,"lang":"en","confirm":""},model:{value:(_vm.formBody.customerIncorporationDate),callback:function ($$v) {_vm.$set(_vm.formBody, "customerIncorporationDate", $$v)},expression:"formBody.customerIncorporationDate"}})],1),(_vm.$v.formBody.customerIncorporationDate.$error)?[(!_vm.$v.formBody.customerIncorporationDate.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Date of Incorporation is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.formBody.customerIncorporationNumber.$error
              },attrs:{"inputType":'text',"placeholder":"Incorporation Number","hasPreIcon":false,"label":'Incorporation Number'},model:{value:(_vm.formBody.customerIncorporationNumber),callback:function ($$v) {_vm.$set(_vm.formBody, "customerIncorporationNumber", $$v)},expression:"formBody.customerIncorporationNumber"}}),(_vm.$v.formBody.customerIncorporationNumber.$error)?[(!_vm.$v.formBody.customerIncorporationNumber.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Incorporation Number is required ")]):_vm._e(),(!_vm.$v.formBody.customerIncorporationNumber.alphaNum)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Incorporation Number can only be Numbers and Alphabets ")]):_vm._e()]:_vm._e()],2)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('BaseInput',{class:{
                error: _vm.$v.formBody.customerEmail.$error
              },attrs:{"inputType":'email',"placeholder":"Email","hasPreIcon":false,"label":'Email'},on:{"blur":function($event){return _vm.$v.formBody.customerEmail.$touch()}},model:{value:(_vm.formBody.customerEmail),callback:function ($$v) {_vm.$set(_vm.formBody, "customerEmail", $$v)},expression:"formBody.customerEmail"}}),(_vm.$v.formBody.customerEmail.$error)?[(!_vm.$v.formBody.customerEmail.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Email is required ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-sm-6"},[_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Account Currency")]),_c('multiselect',{attrs:{"options":_vm.accountCurrencyOptions,"placeholder":"Account Currency"},on:{"close":function($event){return _vm.$v.formBody.accountCurrency.$touch()}},model:{value:(_vm.formBody.accountCurrency),callback:function ($$v) {_vm.$set(_vm.formBody, "accountCurrency", $$v)},expression:"formBody.accountCurrency"}})],1),(_vm.$v.formBody.accountCurrency.$error)?[(!_vm.$v.formBody.accountCurrency.required)?_c('p',{staticClass:"form-error-message"},[_vm._v(" Account Currency is required ")]):_vm._e()]:_vm._e()],2)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('p',{staticClass:"text-left"},[_c('span',[_c('button',{staticClass:"btn btn-secondary mr-1",on:{"click":_vm.goBack}},[_vm._v(" Back ")])])])]),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('p',{staticClass:"text-right"},[_c('span',[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" NEXT ")])])])])
}]

export { render, staticRenderFns }