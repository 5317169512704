<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->

    <form @submit.prevent="createProduct">
      <div class="row">
        <div
          v-for="(field, index) in product.createProductFields"
          :key="index"
          class="col-sm-6"
        >
          <div
            v-if="
              field.templateDataType.toUpperCase() === 'STRING' ||
                field.templateDataType.toUpperCase() === 'TEXT'
            "
          >
            <BaseInput
              :inputType="'text'"
              v-model="createProductFormBody[field.templateDescription]"
              :placeholder="field.templateLabel"
              :hasPreIcon="false"
              :label="field.templateLabel"
              required
            />
          </div>

          <div
            v-if="
              field.templateDataType.toUpperCase() === 'NUMBER' ||
                field.templateDataType.toUpperCase() === 'INTEGER'
            "
          >
            <BaseInput
              :inputType="'number'"
              v-model="createProductFormBody[field.templateDescription]"
              :placeholder="field.templateLabel"
              :hasPreIcon="false"
              :label="field.templateLabel"
              required
            />
          </div>

          <div v-if="field.templateDataType.toUpperCase() === 'FLOAT'">
            <BaseInput
              :inputType="'amount'"
              v-model="createProductFormBody[field.templateDescription]"
              :placeholder="field.templateLabel"
              :hasPreIcon="false"
              :label="field.templateLabel"
              required
            />
          </div>

          <div
            v-if="
              field.templateDataType.toUpperCase() === 'DATETIME' ||
                field.templateDataType.toUpperCase() === 'DATE'
            "
          >
            <!--            date selected-->
            <div class="form-group">
              <label>{{ field.templateLabel }}</label>
              <date-picker
                v-model="createProductFormBody[field.templateDescription]"
                :first-day-of-week="1"
                lang="en"
                confirm
              ></date-picker>
            </div>
          </div>
          <div
            v-if="
              field.templateDataType.toUpperCase() === 'PASSWORD' ||
                field.templateDataType.toUpperCase() === 'PASSCODE'
            "
          >
            <BaseInput
              :inputType="'number'"
              v-model="createProductFormBody[field.templateDescription]"
              :placeholder="field.templateLabel"
              :hasPreIcon="false"
              :label="field.templateLabel"
              required
            />
          </div>

          <div
            v-if="
              field.templateDataType.toUpperCase() === 'LIST' ||
                field.templateDataType.toUpperCase() === 'SELECT'
            "
          >
            <div class="form-group">
              <label>{{ field.templateLabel }}</label>
              <multiselect
                v-model="createProductFormBody[field.templateDescription]"
                :options="field.templateList.split(',')"
                :placeholder="field.templateLabel"
              ></multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <p class="text-left">
            <span>
              <button @click="goBack()" class="btn btn-secondary mr-1">
                Back
              </button>
            </span>
          </p>
        </div>
        <div class="col-sm-6">
          <p class="text-right">
            <span v-if="product.moduleLoadingEight" class="formLoaderHold">
              <BaseComponentLoader />
            </span>
            <span v-else>
              <button type="submit" class="btn btn-primary mr-1">
                Create
                {{
                  product.selectedProductType.prdTemplateDestcription
                    | toLowercase
                    | firstCaseCapital
                }}
                Product
              </button>
            </span>
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "CreateProductForm",
  data() {
    return {
      model: {},
      createProductFormBody: {
        source: "WEB"
      }
    };
  },
  components: {
    Multiselect,
    DatePicker
  },
  computed: {
    ...mapState(["product"])
  },
  created() {
    this.$store.dispatch(
      "product/checkIfProductTypeHasBeenSelectedCreate",
      null,
      { root: true }
    );
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "product-select-type-create"
      });
    },
    createProduct() {
      let payload = {
        requestType: "create",
        products: this.createProductFormBody
      };

      StoreUtils.dispatch("product/newCreateProduct", payload).then(() => {
        this.createProductFormBody = {
          source: "WEB"
        };
      });
    }
  }
};
</script>

<style scoped></style>
