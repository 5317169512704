<template>
  <div>
    <!--        control-->
    <button @click="openModal()" class="btn btn-secondary mr-1">
      <i class="bx bx-upload"></i>
      Bulk Upload
    </button>
    <!--    control END-->

    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="500"
      :height="450"
      measure="px"
      animation="zoom"
      @hide="closeModal"
      :closeOnClickMask="false"
      className="app-modal"
    >
      <div class="card">
        <div class="card-body">
          <p class="card-title mb-4">Bulk Upload</p>

          <div class="row">
            <div class="col-12">
              <BulkUploadForm />
            </div>
          </div>
        </div>
      </div>
    </vodal>
  </div>
</template>

<script>
import BulkUploadForm from "../../Forms/Journal/BulkUploadForm";

export default {
  name: "BulkUploadModal",
  components: { BulkUploadForm },
  data() {
    return {
      modalOpen: false
    };
  },
  // components: { carousel },
  methods: {
    closeModal() {
      this.modalOpen = false;
    },
    openModal() {
      this.modalOpen = true;
    }
  }
};
</script>

<style scoped></style>
