<template>
  <div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div v-if="appUtilities.moduleLoadingEight">
      <BaseComponentLoader />
    </div>
    <div>
      <form @submit.prevent="editBranch">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="editBranchFormBody.branchName"
                placeholder="Bank Name"
                :hasPreIcon="false"
                :label="'Bank Name'"
                :class="{
                  error: $v.editBranchFormBody.branchName.$error
                }"
                @blur="$v.editBranchFormBody.branchName.$touch()"
              >
              </BaseInput>

              <template v-if="$v.editBranchFormBody.branchName.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchName.required"
                  class="form-error-message"
                >
                  Branch Name is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="editBranchFormBody.branchAddress"
                placeholder="Branch Address"
                :hasPreIcon="false"
                :label="'Branch Address'"
                :class="{
                  error: $v.editBranchFormBody.branchAddress.$error
                }"
                @blur="$v.editBranchFormBody.branchAddress.$touch()"
              >
              </BaseInput>

              <template v-if="$v.editBranchFormBody.branchAddress.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchAddress.required"
                  class="form-error-message"
                >
                  Branch Address is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="editBranchFormBody.branchTransactionLimit"
                placeholder="Branch Transaction Limit"
                :hasPreIcon="false"
                :label="'Branch Transaction Limit'"
                :class="{
                  error: $v.editBranchFormBody.branchTransactionLimit.$error
                }"
                @blur="$v.editBranchFormBody.branchTransactionLimit.$touch()"
              >
              </BaseInput>

              <template
                v-if="$v.editBranchFormBody.branchTransactionLimit.$error"
              >
                <p
                  v-if="!$v.editBranchFormBody.branchTransactionLimit.required"
                  class="form-error-message"
                >
                  Branch Transaction Limit is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="editBranchFormBody.branchCity"
                placeholder="Branch City"
                :hasPreIcon="false"
                :label="'Branch City'"
                :class="{
                  error: $v.editBranchFormBody.branchCity.$error
                }"
                @blur="$v.editBranchFormBody.branchCity.$touch()"
              >
              </BaseInput>

              <template v-if="$v.editBranchFormBody.branchCity.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchCity.required"
                  class="form-error-message"
                >
                  Branch City is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="editBranchFormBody.branchState"
                placeholder="Branch State"
                :hasPreIcon="false"
                :label="'Branch State'"
                :class="{
                  error: $v.editBranchFormBody.branchState.$error
                }"
                @blur="$v.editBranchFormBody.branchState.$touch()"
              >
              </BaseInput>

              <template v-if="$v.editBranchFormBody.branchState.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchState.required"
                  class="form-error-message"
                >
                  Branch State is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="editBranchFormBody.branchType"
                placeholder="Branch Type"
                :hasPreIcon="false"
                :label="'Branch Type'"
                :class="{
                  error: $v.editBranchFormBody.branchType.$error
                }"
                @blur="$v.editBranchFormBody.branchType.$touch()"
              >
              </BaseInput>

              <template v-if="$v.editBranchFormBody.branchType.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchType.required"
                  class="form-error-message"
                >
                  Branch Type is required
                </p>
              </template>
            </div>

            <div>
              <div class="form-group">
                <label>Branch Manager</label>
                <multiselect
                  v-model="editBranchFormBody.branchManager"
                  :options="branchMangerUsersOption"
                  placeholder="Branch Manger"
                  @close="$v.editBranchFormBody.branchManager.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.editBranchFormBody.branchManager.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchManager.required"
                  class="form-error-message"
                >
                  Branch Manager is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="editBranchFormBody.branchRegion"
                placeholder="Branch Region"
                :hasPreIcon="false"
                :label="'Branch Region'"
                :class="{
                  error: $v.editBranchFormBody.branchRegion.$error
                }"
                @blur="$v.editBranchFormBody.branchRegion.$touch()"
              >
              </BaseInput>

              <template v-if="$v.editBranchFormBody.branchRegion.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchRegion.required"
                  class="form-error-message"
                >
                  Branch Region is required
                </p>
              </template>
            </div>

            <div>
              <BaseInput
                :inputType="'text'"
                v-model="editBranchFormBody.branchCountry"
                placeholder="Country"
                :hasPreIcon="false"
                :label="'Country'"
                :class="{
                  error: $v.editBranchFormBody.branchCountry.$error
                }"
                @blur="$v.editBranchFormBody.branchCountry.$touch()"
              >
              </BaseInput>

              <template v-if="$v.editBranchFormBody.branchCountry.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchCountry.required"
                  class="form-error-message"
                >
                  Country is required
                </p>
              </template>
            </div>

            <div>
              <div class="form-group">
                <label>Branch Status</label>
                <multiselect
                  v-model="editBranchFormBody.branchStatus"
                  :options="branchStatusOption"
                  placeholder="Branch Status"
                  @close="$v.editBranchFormBody.branchStatus.$touch()"
                ></multiselect>
              </div>

              <template v-if="$v.editBranchFormBody.branchStatus.$error">
                <p
                  v-if="!$v.editBranchFormBody.branchStatus.required"
                  class="form-error-message"
                >
                  Status is required
                </p>
              </template>
            </div>
          </div>
        </div>

        <p class="text-right">
          <span v-if="branch.moduleLoadingFour" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Update
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditBranchForm",
  props: {
    editBranchFormBody: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      branchStatusOption: ["ACTIVE", "INACTIVE"]
    };
  },
  computed: {
    ...mapState(["branch", "appUtilities"]),
    ...mapGetters({
      branchMangerUsersOption: "appUtilities/getAllUsersOption",
      getBranchMangerUserIdFromOptionUtility:
        "appUtilities/getUserIdFromOptionUtility"
    })
  },
  validations: {
    editBranchFormBody: {
      branchName: { required },
      branchAddress: { required },
      branchCity: { required },
      branchState: { required },
      branchType: { required },
      branchManager: { required },
      branchRegion: { required },
      branchStatus: { required },
      branchTransactionLimit: { required },
      branchCountry: { required }
    }
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllUsers", null, { root: true });
  },
  methods: {
    editBranch() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.editBranchFormBody.branchManager = this.getBranchMangerUserIdFromOptionUtility(
          this.editBranchFormBody.branchManager
        );

        this.$store
          .dispatch("branch/editBranch", this.editBranchFormBody, {
            root: true
          })
          .then(responseData => {
            if (responseData.responseCode === "00") {
              this.$emit("editSuccessful", responseData);
            }
            this.editBranchFormBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    editFreshEditBranchFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
