<script>
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import RouterUtils from "@/utils/BaseUtils/RouterUtils";

/**
 * Nav-bar Component
 */
export default {
  methods: {
    logOut() {
      StoreUtils.commit("user/REMOVE_USER_TOKEN");
      RouterUtils.changeRouteTo("logout");
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logi logo-sm">
              Power Bank
              <!--              <img src="@/assets/images/logo.svg" alt height="22" />-->
            </span>
            <span class="logi logo-lg">
              Power Bank
              <!--              <img src="@/assets/images/logo-dark.png" alt height="17" />-->
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logi logo-sm">
              Power Bank
              <!--              <img src="@/assets/images/logo-light.svg" alt height="22" />-->
            </span>
            <span class="logi logo-lg">
              Power Bank
              <!--              <img src="@/assets/images/logo-light.png" alt height="19" />-->
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <a
            @click="logOut()"
            class="logout-btn btn header-item noti-icon right-bar-toggle toggle-right"
          >
            <i class="bx bx-log-out-circle toggle-right"></i>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.logi {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #f3f3f5;
  letter-spacing: 1px;
}
.logout-btn {
}
.logout-btn i {
}
</style>
