var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"badge badge-pill font-size-12",class:{
    'badge-danger':
      `${_vm.statusValue}` === 'REJECTED' || `${_vm.statusValue}` === 'DECLINED',
    'badge-soft-danger':
      `${_vm.statusValue}` === 'INACTIVE',
    'badge-warning': `${_vm.statusValue}` === 'PENDING',
    'badge-success': `${_vm.statusValue}` === 'AUTHORIZED' || `${_vm.statusValue}` === 'RESOLVED',
    'badge-soft-success': `${_vm.statusValue}` === 'ACTIVE',
    'badge-soft-primary': `${_vm.statusValue}` === 'NEW'
  }},[_vm._v(_vm._s(_vm._f("firstCaseCapitalSpace")(_vm.statusValue)))])
}
var staticRenderFns = []

export { render, staticRenderFns }