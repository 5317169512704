<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Cash Withdrawal</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <CashWithdrawalTab />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";

export default {
  page: {
    title: "Cash Withdrawal",
    meta: [{ name: "Cash Withdrawal", content: window.__env.app.description }]
  },

  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      name: "CashWithdrawal",
      title: "Cash Withdrawal",
      items: [
        {
          text: "Cash Withdrawal",
          href: "/"
        },
        {
          text: "Cash Withdrawal",
          active: true
        }
      ]
    };
  }
};
</script>

<style scoped></style>
