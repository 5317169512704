<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template v-slot:cell(roleBankCode)="data">
        <p class="name-field">
          <span>{{ data.item.roleBankCode }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(roleBranchCode)="data">
        <p class="name-field">
          <span class="bold">{{ data.item.roleBranchCode }}</span>
        </p>
      </template>

      <!-- A custom formatted column -->
      <template v-slot:cell(roleId)="data">
        <p class="name-field">
          <span>{{ data.item.roleId }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(roleName)="data">
        <p class="name-field">
          <span>{{ data.item.roleName | toLowercase }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(roleChecker)="data">
        <p class="name-field">
          <span>{{ data.item.roleChecker }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(roleMaker)="data">
        <p class="name-field">
          <span>{{ data.item.roleMaker }}</span>
        </p>
      </template>

      <template v-slot:cell(roleStatus)="data">
        <p class="name-field">
          <AppStatusBadge :status-value="data.item.roleStatus" />
        </p>
      </template>

      <template v-slot:cell(privileges)="data">
        <p class="name-field">
          <RolePrivilegesInfoModal :current-role="data.item" />
        </p>
      </template>

      <template v-slot:cell(roleTrnLimit)="data">
        <p class="name-field">
          ₦{{data.item.roleTrnLimit | newAmountFormatter}}
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell(action)="data">
        <p class="name-field">
          <span>
            <b-dropdown
              class="card-drop"
              variant="white"
              right
              toggle-class="p-0"
            >
              <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal font-size-18" />
              </template>

              <b-dropdown-item @click="editRole(data.item)">
                <i class="fas fa-pencil-alt text-success mr-1" /> Edit
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </p>
      </template>
    </b-table>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";

export default {
  name: "AllRolesTable",

  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        { key: "roleId", label: "ID", sortable: true },
        { key: "roleName", label: "Name", sortable: true },
        { key: "roleMaker", label: "Creator", sortable: true },
        // { key: "roleChecker", label: "Approver", sortable: true },
        // { key: "roleBranchCode", label: "Code", sortable: true },
        { key: "roleStatus", label: "Status", sortable: true },
        { key: "privileges", label: "Privileges", sortable: false },
        { key: "roleTrnLimit", label: "Transaction Limit", sortable: false },
        { key: "action", label: "Action", sortable: false }
      ],

      json_fields: {
        "Role ID": "roleId",
        "Role Name": "roleName",
        "Role Creator": "roleMaker",
        "Role Checker": "roleChecker",
        "Branch Code": "roleBranchCode",
        "Bank Code": "roleBankCode",
        "Role Status": "roleStatus"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.role.allRoles,
      isBusy: state => state.loader.tableLoader,
      loader: state => state.loader
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    returnItems() {},
    editRole(role) {
      StoreUtils.dispatch("role/switchToEditRole", role);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  created() {
    const payload = {
      roleBankCode: this.$store.getters["user/getUserBankCode"],
      readAll: "NO"
    };
    this.$store.dispatch("role/allRoles", payload, { root: true });
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
