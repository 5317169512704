<template>
  <div>
    <div v-if="appUtilities.moduleLoadingFive"></div>
    <div v-else>
      <form @submit.prevent="vaultTransferToCBN()">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Credit Account Number</label>
                <multiselect
                  v-model="cashTransactionFormBody.creditAccountNumber"
                  :options="internalAccountsUtilityOption"
                  placeholder="Credit Account Number"
                  @input="
                    $v.cashTransactionFormBody.creditAccountNumber.$touch()
                  "
                ></multiselect>
              </div>

              <template
                v-if="$v.cashTransactionFormBody.creditAccountNumber.$error"
              >
                <p
                  v-if="
                    !$v.cashTransactionFormBody.creditAccountNumber.required
                  "
                  class="form-error-message"
                >
                  Credit Account Number is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Debit Account Number</label>
                <multiselect
                  v-model="cashTransactionFormBody.debitAccountNumber"
                  :options="internalAccountsUtilityOption"
                  placeholder="Debit Account Number"
                  @input="
                    $v.cashTransactionFormBody.debitAccountNumber.$touch()
                  "
                ></multiselect>
              </div>

              <template
                v-if="$v.cashTransactionFormBody.debitAccountNumber.$error"
              >
                <p
                  v-if="!$v.cashTransactionFormBody.debitAccountNumber.required"
                  class="form-error-message"
                >
                  Debit Account Number is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>FT Product</label>
                <multiselect
                  v-model="cashTransactionFormBody.ftProductCode"
                  :options="ftProductCodeUtilityOption"
                  @close="$v.cashTransactionFormBody.ftProductCode.$touch()"
                  placeholder="FT Product"
                ></multiselect>
              </div>
              <template v-if="$v.cashTransactionFormBody.ftProductCode.$error">
                <p
                  v-if="!$v.cashTransactionFormBody.ftProductCode.required"
                  class="form-error-message"
                >
                  FT Product is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                  @input="formatAmount"
                  :inputType="'text'"
                v-model="cashTransactionFormBody.trnAmount"
                placeholder="Amount"
                :hasPreIcon="false"
                :label="'Amount'"
                :class="{
                  error: $v.cashTransactionFormBody.trnAmount.$error
                }"
                @blur="$v.cashTransactionFormBody.trnAmount.$touch()"
              >
              </BaseInput>

              <template v-if="$v.cashTransactionFormBody.trnAmount.$error">
                <p
                  v-if="!$v.cashTransactionFormBody.trnAmount.required"
                  class="form-error-message"
                >
                  Amount is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="cashTransactionFormBody.trnNarration"
                placeholder="Narration"
                :hasPreIcon="false"
                :label="'Narration'"
                :class="{
                  error: $v.cashTransactionFormBody.trnNarration.$error
                }"
                @blur="$v.cashTransactionFormBody.trnNarration.$touch()"
              >
              </BaseInput>

              <template v-if="$v.cashTransactionFormBody.trnNarration.$error">
                <p
                  v-if="!$v.cashTransactionFormBody.trnNarration.required"
                  class="form-error-message"
                >
                  Narration is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'date'"
                v-model="cashTransactionFormBody.trnDate"
                placeholder="Narration"
                :hasPreIcon="false"
                :label="'Transaction Date'"
                :class="{}"
              >
              </BaseInput>
            </div>
          </div>

        </div>
        <p class="text-left">
          <span v-if="cashTransaction.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Transfer
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import GeneralUtils from "../../../../utils/BaseUtils/GeneralUtils";

export default {
  name: "VaultTransferToCBN",
  data() {
    return {
      cashTransactionFormBody: this.createFreshcashTransactionFormBodyObject()
    };
  },
  computed: {
    ...mapState(["cashTransaction", "appUtilities"]),
    ...mapGetters({
      internalAccountsUtilityOption:
        "appUtilities/getInternalAccountsUtilityOption",
      transactionCodesList: "transactionCode/getTransactionsCodes",
      ftProductCodeUtilityOption: "appUtilities/getFtProductsUtilityOption2",
      getFtProductCodeFromOptionUtility:
        "appUtilities/getFtProductCodeFromOptionUtility"
    })
  },
  async created() {
    await this.$store.dispatch("appUtilities/fetchAllInternalAccounts", null, {
      root: true
    });
    await this.$store.dispatch(
      "appUtilities/fetchAllFundsTransferProduct2",
      null,
      {
        root: true
      }
    );
    await this.$store.dispatch("transactionCode/allTransactionCodes", null, {
      root: true
    });
  },
  validations: {
    cashTransactionFormBody: {
      creditAccountNumber: { required },
      debitAccountNumber: { required },
      ftProductCode: { required },
      trnAmount: { required },
      trnNarration: { required }
    }
  },
  methods: {
    formatAmount() {
      // Remove any existing commas from the amount
      const cleanedAmount = this.cashTransactionFormBody.trnAmount.replace(/,/g, '');
      // Format the amount with comma separators
      const formattedAmount = cleanedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // Update the amount with the formatted version
      this.cashTransactionFormBody.trnAmount = formattedAmount;
    },
    vaultTransferToCBN() {
      const amountWithoutCommas = this.cashTransactionFormBody.trnAmount.replace(/,/g, '');
      this.cashTransactionFormBody.trnAmount = amountWithoutCommas

      this.cashTransactionFormBody.ftProductCode = this.getFtProductCodeFromOptionUtility(
        this.cashTransactionFormBody.ftProductCode
      );
      this.cashTransactionFormBody.creditAccountNumber = this.cashTransactionFormBody.creditAccountNumber.split(
        " - "
      )[1];
      this.cashTransactionFormBody.debitAccountNumber = this.cashTransactionFormBody.debitAccountNumber.split(
        " - "
      )[1];
      this.cashTransactionFormBody.debitAccountBankCode = this.$store.getters[
        "user/getUserBankCode"
      ];
      this.cashTransactionFormBody.debitAccountBranchCode = this.$store.getters[
        "user/getUserBranchCode"
      ];
      this.cashTransactionFormBody.trnMaker = this.$store.getters[
        "user/getUserId"
      ];
      this.cashTransactionFormBody.trnChecker = "SYSTEM";
      this.cashTransactionFormBody.reference = GeneralUtils.generateUUIDRef();
      this.cashTransactionFormBody.trnMaker = this.$store.getters[
        "user/getUserId"
      ];

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch(
            "cashTransaction/cashTransaction",
            this.cashTransactionFormBody,
            {
              root: true
            }
          )
          .then(() => {
            this.cashTransactionFormBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshcashTransactionFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
