<template>
  <span>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    name: String,
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  },
  data() {
    return {
      taaaa: ""
    };
  }
};
</script>

<style scoped></style>
