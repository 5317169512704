<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Gl Level 2 Checkers Queue</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <GlLevel2CheckersQueueTable />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Update Branch Status
 */


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import GlLevel2CheckersQueueTable from "../../components/Kleyton/Tables/GL2/GlLevel2CheckersQueueTable";

export default {
  page: {
    title: "GL Level 2 Checkers Queue",
    meta: [{ name: "GL Level 2 Checkers Queue", content: window.__env.app.description }]
  },
  components: {
    GlLevel2CheckersQueueTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "GL Level 2 Checkers Queue",
      items: [
        {
          text: "GL Level 2",
          href: "/"
        },
        {
          text: "GL Level 2 Checkers Queue",
          active: true
        }
      ]
    };
  }
};
</script>
