<template>
  <div>
    <form @submit.prevent="createGlLevel1">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <BaseInput
              :inputType="'text'"
              v-model="formBody.glLevel1Description"
              placeholder="Description"
              :hasPreIcon="false"
              :label="'Description'"
              :class="{
                error: $v.formBody.glLevel1Description.$error
              }"
              @blur="$v.formBody.glLevel1Description.$touch()"
            >
            </BaseInput>

            <template v-if="$v.formBody.glLevel1Description.$error">
              <p
                v-if="!$v.formBody.glLevel1Description.required"
                class="form-error-message"
              >
                Description is required
              </p>
            </template>
          </div>
        </div>
        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <label>Category Code</label>
              <multiselect
                v-model="formBody.glLevel1CategoryCode"
                :options="glCategoryCode"
                placeholder="Category Code"
                @close="$v.formBody.glLevel1CategoryCode.$touch()"
              ></multiselect>
            </div>
            <!--                          error-->
            <template v-if="$v.formBody.glLevel1CategoryCode.$error">
              <p
                v-if="!$v.formBody.glLevel1CategoryCode.required"
                class="form-error-message"
              >
                Category is required
              </p>
            </template>
          </div>
        </div>
      </div>
      <p class="text-right">
        <span v-if="glLevel1.moduleLoading" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Submit
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateGLLevel1",
  data() {
    return {
      formBody: this.createFreshFormBodyObject(),

      options: [
        "Fixed Asset",
        "Current Asset",
        "Equity",
        "Liability",
        "Revenue",
        "Expense"
      ]
    };
  },
  computed: {
    ...mapState(["glLevel1"]),
    ...mapGetters({
      glCategoryCode: "appUtilities/getGlCategoryCodeOption",
      getGlCategoryCodeFromOption: "appUtilities/getGlCategoryCodeFromOption"
    })
  },
  validations: {
    formBody: {
      glLevel1Description: { required },
      glLevel1CategoryCode: { required }
    }
  },
  methods: {
    createGlLevel1() {
      this.formBody.glLevel1BankCode = this.$store.getters[
        "user/getUserBankCode"
      ];
      this.formBody.glLevel1BranchCode = this.$store.getters[
        "user/getUserBranchCode"
      ];
      this.formBody.glLevel1Maker = this.$store.getters["user/getUserId"];
      this.formBody.glLevel1CategoryCode = this.getGlCategoryCodeFromOption(
        this.formBody.glLevel1CategoryCode
      );
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("glLevel1/createGlLevel1", this.formBody, {
            root: true
          })
          .then(() => {
            this.formBody = {
              source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshFormBodyObject() {
      return {
        source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
