<template>
  <div class="table-section">
    <div class="row">
      <div class="col-12">
        <SearchTransactionHistoryForm
          :mode="mode"
          :resultCount="items.length"
        />
      </div>
    </div>

    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Filter"
              :inputType="'text'"
              v-model="filter"
              placeholder="Filter through result..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-secondary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  worksheet="My Worksheet"
                  name="transactions.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnDate)="data">
        <p class="name-field">
          <span>
            {{ data.item.trnDate | formatDateForTable }}
          </span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(trnAmount)="data">
        <p class="name-field">
          <span> ₦{{ data.item.trnAmount | newAmountFormatter }} </span>
        </p>
      </template>

      <template v-slot:cell(action)="data">
        <!--        control-->
        <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
          <template v-slot:button-content>
            <i class="mdi mdi-dots-horizontal font-size-18" />
          </template>

          <b-dropdown-item @click="openModal(data)">
            <i class="fas fa-print text-success mr-1" /> Print Receipt
          </b-dropdown-item>
        </b-dropdown>

        <!--        Edit Customer modal-->
        <vodal
          :show="modalOpen"
          :width="450"
          :height="630"
          measure="px"
          animation="zoom"
          @hide="modalOpen = false"
          :closeOnClickMask="false"
          className="app-modal"
        >
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">print Receipt</h4>
              <print-receipt-modal :selected-item="selectedItem" />
            </div>
          </div>
        </vodal>
      </template>
    </b-table>

    <b-row style="display: flex; justify-content: space-evenly">
      <b-col sm="5" md="3" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>

      <b-col sm="5" md="2" class="my-1">
        <button class="load-more-button" @click="loadMoreTransactions">
          Load More
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SearchTransactionHistoryForm from "../../Forms/TransactionHistory/SearchTransactionHistoryForm";
import PrintReceiptModal from "@/components/Kleyton/Modals/Transaction/PrintReceiptModal.vue";

export default {
  name: "TransactionHistoryTable",
  props: {
    mode: {
      type: String,
      default: "teller-transaction-history",
    },
  },
  components: { PrintReceiptModal, SearchTransactionHistoryForm },
  data() {
    return {
      selectedItem: null,
      modalOpen: false,
      sortBy: "date",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, "all"],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      currentPage2: 1, // Initial page
      pageSize: 100, // Page size is fixed
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },
        { key: "trnAmount", label: "Transaction amount", sortable: true },
        {
          key: "accountName",
          label: "Account Name",
          sortable: true,
        },
        {
          key: "accountNumber",
          label: "Account Number",
          sortable: true,
        },
        {
          key: "trnAmount",
          label: "Amount",
          sortable: true,
        },
        {
          key: "trnContractReference",
          label: "Contract Reference",
          sortable: true,
        },
        {
          key: "trnCounterPartyBankName",
          label: "Counterparty Bank Name",
          sortable: true,
        },
        {
          key: "trnCounterPartyService",
          label: "Counterparty Service",
          sortable: true,
        },
        {
          key: "trnCounterpartyBankCode",
          label: "Counterparty Bank Code",
          sortable: true,
        },
        {
          key: "trnDate",
          label: "Date",
          sortable: true,
        },
        {
          key: "trnDrCr",
          label: "DrCr",
          sortable: true,
        },
        {
          key: "trnDate",
          label: "Transaction Date",
          sortable: true,
        },
        {
          key: "trnNarration",
          label: "Transaction Narration",
          sortable: true,
        },
        {
          key: "source",
          label: "Source",
          sortable: true,
        },
        {
          key: "maker",
          label: "Maker",
          sortable: true,
        },
        { key: "action", label: "Action", sortable: false },
      ],

      json_fields: {
        trnAmount: "Amount",
        trnCounterPartyService: "Counter Party Service",
        trnDate: "Date",
        trnCounterpartyBankCode: "Counterparty BankCode",
        trnEventDate: "Event Date",
        trnContractReference: "Contract Reference",
        logourl: "logourl",
        trnPaymentReference: "Payment Reference",
        trnDrCr: "Dr/Cr",
        accountNumber: "account Number",
        accountName: "account Name",
        trnCounterPartyBankName: "CounterParty Bank Name",
        ftCounterPartySwitchCode: "ft CounterParty Switch Code",
        trnNarration: "Narration",
        creditAccountNumber: "Credit Account Number",
        debitAccountNumber: "Debit Account Number",
        source: "Source",
        maker: "Maker",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.cashTransaction.cashTransactionHistory,
      isBusy: (state) => state.loader.tableLoader,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.filter = " ";
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openModal(data) {
      this.selectedItem = data;
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    loadMoreTransactions() {
      this.currentPage2 += 1; // Increment the page number before the API call

      const payload = {
        bankCode: this.$store.getters["user/getUserBankCode"],
        page: this.currentPage2, // Use the incremented page number
        pageSize: this.pageSize,
      };

      // Dispatch the API call
      this.$store.dispatch("cashTransaction/customerTransactions", payload, {
        root: true,
      });
    },
  },

  created() {
    if (this.mode === "teller-transaction-history") {
      const payload = {
        bankCode: this.$store.getters["user/getUserBankCode"],
        maker: this.$store.getters["user/getUserId"],
      };

      this.$store.dispatch("cashTransaction/cashTransactionsHistory", payload, {
        root: true,
      });
    }

    if (this.mode === "all-transaction-history") {
      const payload = {
        bankCode: this.$store.getters["user/getUserBankCode"],
      };

      this.$store.dispatch("cashTransaction/allTransactionHistory", payload, {
        root: true,
      });
    }

    if (this.mode === "customer-transactions") {
      const payload = {
        bankCode: this.$store.getters["user/getUserBankCode"],
        page: 1,
        pageSize: 100,
      };

      this.$store.dispatch("cashTransaction/customerTransactions", payload, {
        root: true,
      });
    }
  },
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
.load-more-button {
  border: 1px solid #556ee6;
  color: #fff;
  background-color: #556ee6;
  border-radius: 0.25rem;
  padding: 5px 40px;
}
</style>
