<script>
import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import TerminalCheckersQueueTable from "@/components/Kleyton/Tables/Terminal/TerminalCheckersQueueTable";

export default {
  page: {
    title: "Terminal Checkers Queue",
    meta: [{ name: "Terminal Checkers Queue", content: window.__env.app.description }]
  },
  components: {
    TerminalCheckersQueueTable,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Terminal Checkers Queue",
      items: [
        {
          text: "Terminal",
          href: "/"
        },
        {
          text: "Terminal Checkers Queue",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Terminal Checkers Queue</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <TerminalCheckersQueueTable />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
