<template>
  <div>
    <div v-if="appUtilities.moduleLoadingFour" class="section-loader">
      <BaseComponentLoader></BaseComponentLoader>
    </div>
    <div v-else>
      <form @submit.prevent="createPrivilege">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Privilege Type</label>
                <multiselect
                        v-model="createPrivilegeFormBody.privilegeType"
                        :options="options"
                        placeholder="Privilege Type"
                        @close="$v.createPrivilegeFormBody.privilegeType.$touch()"
                ></multiselect>
              </div>

              <!--                          error-->
              <template v-if="$v.createPrivilegeFormBody.privilegeType.$error">
                <p
                  v-if="!$v.createPrivilegeFormBody.privilegeType.required"
                  class="form-error-message"
                >
                  Privilege Type is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Privilege Module Name</label>
                <multiselect
                        v-model="createPrivilegeFormBody.privilegeModuleName"
                        :options="options2"
                        placeholder="Privilege Module Name"
                        @close="$v.createPrivilegeFormBody.privilegeModuleName.$touch()"
                ></multiselect>
              </div>

              <!--                          error-->
              <template
                      v-if="$v.createPrivilegeFormBody.privilegeModuleName.$error"
              >
                <p
                        v-if="
                    !$v.createPrivilegeFormBody.privilegeModuleName.required
                  "
                        class="form-error-message"
                >
                  Privilege Module Name is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <p class="text-right">
          <span v-if="privilege.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create Privilege
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreatePrivilege",
  data() {
    return {
      createPrivilegeFormBody: this.createFreshCreatePrivilegeFormBodyObject(),
      options: this.$store.getters[
        "appUtilities/getPrivilegeTypeUtilityOption"
      ],
      options2: this.$store.getters["appUtilities/getModuleUtilityOption"]
    };
  },
  created() {
    this.$store.dispatch("appUtilities/fetchAllModules");
  },
  computed: {
    ...mapState(["privilege", "appUtilities"])
  },
  validations: {
    createPrivilegeFormBody: {
      privilegeModuleName: { required },
      privilegeType: { required }
    }
  },
  methods: {
    createPrivilege() {
      this.createPrivilegeFormBody.privilegeType = this.createPrivilegeFormBody.privilegeType.toUpperCase();
      this.createPrivilegeFormBody.privilegeMaker = this.$store.getters[
        "user/getUserId"
      ];
      this.createPrivilegeFormBody.privilegeModuleId = this.$store.getters[
        "appUtilities/getModuleIdFromOptionUtility"
      ](this.createPrivilegeFormBody.privilegeModuleName.toUpperCase());
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("privilege/createPrivilege", this.createPrivilegeFormBody, {
            root: true
          })
          .then(() => {
            this.createPrivilegeFormBody = {};
            this.$v.$reset();
          })
          .catch(() => {});
      }
    },
    createFreshCreatePrivilegeFormBodyObject() {
      return {};
    }
  }
};
</script>

<style scoped>
.section-loader {
}
</style>
