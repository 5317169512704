import Vue from "vue";
import moment from "moment";
import { format, formatRelative } from "date-fns";

Vue.filter("formatDateRel", value => {
  return formatRelative(new Date(value), new Date());
});

Vue.filter("formatAmount", function (value) {
  if (value == null) return "0.00";
  value = parseFloat(value).toFixed(2);
  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
});

Vue.filter("formatDateForTable", value => {
  return format(new Date(value), "dd-MMM-yyyy HH:mm:ss");
});

Vue.filter("getInitials", value => {
  let parts = value.split(" ");
  let initials = "";
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  if (initials.length === 2) {
    return initials;
  } else if (initials.length < 2) {
    initials = initials + initials;

    return initials;
  } else {
    return initials.substring(0, 2);
  }
});

Vue.filter("trimLastReturn", value => {
  if (!value) return "";
  return value.replace(/^\s+|\s+$/g, "");
});

Vue.filter("moneyFormat", value => {
  if (!value) return "";
  return value.toLocaleString();
});

Vue.filter("formatDateRelatively", value => {
  if (value) {
    let diff = moment(value).diff(moment(), "milliseconds");
    let duration = moment.duration(diff);

    if (duration.days() === 0 && duration.months() === 0) {
      return "Today";
    } else if (duration.days() === -1 && duration.months() === 0) {
      return "Yesterday";
    } else {
      // return moment(String(value)).format('Do LL')
      return moment(String(value)).format("DD-MMM-YYYY");
    }
  }
});

Vue.filter("formatTimestamp", value => {
  if (value) {
    let prefix = "";
    const timeDiff = Math.round(
      (new Date().getTime() - new Date(value).getTime()) / 60000
    );
    if (timeDiff < 1) {
      // less than one minute ago
      prefix = "just now...";
    } else if (timeDiff < 60 && timeDiff > 1) {
      // less than sixty minutes ago
      prefix = `${timeDiff} minutes ago`;
    } else if (timeDiff < 24 * 60 && timeDiff > 60) {
      // less than 24 hours ago
      prefix = `${Math.round(timeDiff / 60)} hours ago`;
    } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
      // less than 7 days ago
      prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
    } else {
      prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
      // prefix = `${new Date(value)}`;
    }
    return prefix;
  }
});

Vue.filter("dateFormatForTable", value => {
  if (value) {
    return moment(value, "llll").format("ll");
  }
});

Vue.filter("getDateTimeStamp", value => {
  if (value) {
    let incomingDate = moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSZ");
    let todayDate = moment();
    let formattedDateTime;

    // IF date === today (return time)
    if (parseInt(todayDate.diff(incomingDate, "days")) === 0) {
      // return Time
      formattedDateTime = incomingDate.format("HH:mm");
    }
    // ELSE IF date === yesterday (return 'yesterday')
    else if (parseInt(todayDate.diff(incomingDate, "days")) === 1) {
      formattedDateTime = "yesterday";
    }
    // IF date is in this week (return day :[Sunday])
    else if (
      parseInt(todayDate.diff(incomingDate, "days")) > 1 &&
      parseInt(todayDate.diff(incomingDate, "days")) <= 6
    ) {
      formattedDateTime = incomingDate.format("dddd");
    }
    // Else (return date :[12/11/2019]
    else {
      formattedDateTime = incomingDate.format("MMM Do YYYY");
    }

    return formattedDateTime;
  }
});

Vue.filter("getTimeFromDate", value => {
  if (value) {
    let incomingDate = moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSZ");
    let formattedDateTime;

    // return Time
    formattedDateTime = incomingDate.format("HH:mm");

    return formattedDateTime;
  }
});

Vue.filter("formatDateRelativeToNow", value => {
  if (value) {
    const augDate = moment(value);
    const sepDate = moment();

    return augDate.from(sepDate);
  }
});

Vue.filter("newAmountFormatter", value => {
  if (value && value !== 0 && value !== "") {
    value = value.toString().replace(/,/g, "");

    let outgoingAmount = parseFloat(value)
      .toFixed(2)
      .toString();
    let indexOfThePoint = outgoingAmount.indexOf(".");

    String.prototype.insert = function(index, string) {
      if (index > 0)
        return (
          this.substring(0, index) + string + this.substring(index, this.length)
        );
      return string + this;
    };
    let answer = outgoingAmount;
    let count = 0;
    for (let i = indexOfThePoint; i >= 1; i--) {
      if (count === 3) {
        count = 1;
        answer = answer.insert(i, ",");
      } else {
        count++;
      }
    }
    return answer;
  } else {
    return "0.00";
  }
});

Vue.filter("firstNameFromFullName", value => {
  if (!value) return "";
  value = value.toString();
  return value
    .split(" ")
    .slice(0, -1)
    .join(" ")
    .toLowerCase();
});

Vue.filter("toUppercase", value => {
  if (!value) return "";
  value = value.toUpperCase();
  return value;
});

Vue.filter("toLowercase", value => {
  if (!value) return "";
  value = value.toLowerCase();
  return value;
});

Vue.filter("firstCaseCapital", value => {
  if (!value) return "";
  value = value.charAt(0).toUpperCase() + value.slice(1);
  return value;
});
Vue.filter("firstCaseCapitalSpace", value => {
  if (!value) return "";
  value = value.toLowerCase();
  if (!value.includes(" ")) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    const titleCase = str => {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    };

    value = titleCase(value);
  }

  return value;
});

Vue.filter("camelCaseToSentenceCaseCapital", value => {
  if (!value) return "";

  // value = value
  //   .replace(/([A-Z])/g, function(match) {
  //     return " " + match;
  //   })
  //   .toLowerCase();
  // adding space between strings
  // value = value.replace(/([A-Z])/g, " $1");

  // converting first character to uppercase and join it to the final string
  // value = value.charAt(0).toUpperCase() + value.slice(1);

  return value;
});

Vue.filter("moneyToDecimal", value => {
  if (!value) return "";
  // value = value.charAt(0).toUpperCase() + value.slice(1);
  value = parseFloat(value).toFixed(2);
  return value;
});

// export default instance
