<template>
  <div>
    <div>
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-12">
            <CustomerAccountCard
              :account="ft.stageOneResponse"
              destination="from"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'number'"
                v-model="formBody.accountNumber"
                placeholder="Credit Account Number"
                :hasPreIcon="false"
                :label="'Credit Account Number'"
                :class="{
                  error: $v.formBody.accountNumber.$error
                }"
                @blur="$v.formBody.accountNumber.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.accountNumber.$error">
                <p
                  v-if="!$v.formBody.accountNumber.required"
                  class="form-error-message"
                >
                  Credit Account Number is required
                </p>

                <p
                  v-if="!$v.formBody.accountNumber.numeric"
                  class="form-error-message"
                >
                  Credit Account Number has to be a Number
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button
                  @click="goBackToStageOne"
                  class="btn btn-secondary mr-1"
                >
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Check Account
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { numeric, required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import CustomerAccountCard from "../../../Cards/FT/CustomerAccountCard";

export default {
  name: "CreditEnquiryForm",
  components: { CustomerAccountCard },
  data() {
    return {
      formBody: this.freshFormBodyObject()
    };
  },
  computed: {
    ...mapState(["ft", "loader"]),
    bankListOptions() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.appUtilities.GET_BANK_LIST_UTILITY_OPTION
      );
    },
    getBankCodeFromBankListOption() {
      return StoreUtils.rootGetter(
        StoreUtils.getters.appUtilities.GET_BANK_CODE_FROM_BANK_LIST_OPTION
      );
    }
  },
  validations: {
    formBody: {
      accountNumber: { required, numeric }
    }
  },
  methods: {
    goBackToStageOne() {
      StoreUtils.dispatch(StoreUtils.actions.ft.GO_BACK_TO_STAGE_ONE);
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        StoreUtils.dispatch(
          StoreUtils.actions.ft.BALANCE_ENQUIRY_INTERNAL,
          this.formBody
        );
      }
    },

    freshFormBodyObject() {
      return {
        source: "WEB"
      };
    }
  }
};
</script>

<style scoped></style>
