<template>
  <div>
    <div class="view-more-top">
      <div class="row">
        <div class="col-sm-6">
          <p class="queue-title">Account Number</p>
          <p class="queue-value">
            {{ editFormBody.lienAmountAccount }}
          </p>
        </div>

        <div class="col-sm-6">
          <p class="queue-title">Reference</p>
          <p class="queue-value">
            {{ editFormBody.lienAmountReference }}
          </p>
        </div>
      </div>
    </div>
    <!--    <h6 class="card-title mb-4 font-size-10">Add a New Bank</h6>-->
    <div v-if="appUtilities.moduleLoadingSix">
      <BaseComponentLoader />
    </div>
    <div>
      <form @submit.prevent="submit">
        <div class="row">
<!--          <div class="col-sm-12">-->
<!--            <div>-->
<!--              <div class="form-group">-->
<!--                <label>Account Status</label>-->
<!--                <multiselect-->
<!--                  v-model="formBody.accountStatus"-->
<!--                  :options="statusOptions"-->
<!--                  placeholder="Account Status"-->
<!--                  @close="$v.formBody.accountStatus.$touch()"-->
<!--                ></multiselect>-->
<!--              </div>-->

<!--              <template v-if="$v.formBody.accountStatus.$error">-->
<!--                <p-->
<!--                  v-if="!$v.formBody.accountStatus.required"-->
<!--                  class="form-error-message"-->
<!--                >-->
<!--                  Account Status is required-->
<!--                </p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->

          <BaseInput
              style="width: 100%"
              @input="formatAmount"
              :inputType="'text'"
            placeholder="Amount"
            v-model="formBody.amount"
            :hasPreIcon="false"
            :label="'Amount'"
            class=""
          >
          </BaseInput>


        </div>

        <p class="text-right">
          <span v-if="customer.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
                Remove Lien Amount
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BaseInput from "../../../BaseComponents/BaseInput";

export default {
  name: "DeleteLienForm",
  components: { BaseInput},
  props: {
    editFormBody: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formBody: {
        amount: "",
        accountNumber: "",
        bankCode: "",
        reference:""
      }
    };
  },
  computed: {
    ...mapState(["loader", "appUtilities", "customer"]),
    ...mapGetters({
      accountClassOptionsFromCode:
        "appUtilities/getAccountClassOptionFromCodeUtility",
      getAccountClassFromOptionUtility:
        "appUtilities/getAccountClassFromOptionUtility"
    }),
  },
  validations: {
  },
   created() {
     this.$store.dispatch("appUtilities/fetchAllAccountClass2", null, {
      root: true
    });
    this.formBody.accountStatus =  this.editFormBody.accountStatus;
    this.formBody.accountClass =  this.accountClassOptionsFromCode(
      this.editFormBody.accountClass
    );
  },
  mounted() {},
  methods: {
    formatAmount() {
      // Remove any existing commas from the amount
      const cleanedAmount = this.formBody.amount.replace(/,/g, '');
      // Format the amount with comma separators
      const formattedAmount = cleanedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // Update the amount with the formatted version
      this.formBody.amount = formattedAmount;
    },

    submit() {
      const amountWithoutCommas = this.formBody.amount.replace(/,/g, '');
      this.formBody.amount = amountWithoutCommas
        this.formBody.accountNumber = this.editFormBody.lienAmountAccount;
        this.formBody.reference = this.editFormBody.lienAmountReference
        this.formBody.bankCode = this.editFormBody.lienAmountBankCode
      this.$store.dispatch("customer/removeLien", this.formBody, {root: true})
          .then(responseData => {
        if (responseData.responseCode === "00") {
          this.$emit("editSuccessful", responseData);
        }
          })
    },

  }
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
