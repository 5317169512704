<template>
  <div>
    <button
      v-if="btnType === 'formBtn'"
      v-on="$listeners"
      :class="buttonClass"
      v-bind="$attrs"
    >
      <slot />

      <span v-if="hasIcon" class="btn-icon">
        <slot name="hasIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fill="white"
              d="M7.4 13.8C7 14.1 7 14.7 7.4 15.1 7.7 15.4 8.3 15.4 8.6 15.1L15.1 8.6C15.4 8.3 15.4 7.7 15.1 7.4L8.6 0.9C8.3 0.6 7.7 0.6 7.4 0.9 7 1.3 7 1.9 7.4 2.2L12.2 7.1 1.6 7.1C1.1 7.1 0.7 7.5 0.7 8 0.7 8.5 1.1 8.9 1.6 8.9L12.2 8.9 7.4 13.8Z"
            />
          </svg>
        </slot>
      </span>
    </button>

    <button
      v-if="btnType === 'appBtn'"
      v-on="$listeners"
      :class="buttonClass"
      v-bind="$attrs"
    >
      <slot />

      <span v-if="hasIcon">
        <slot name="hasIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fill="white"
              d="M7.4 13.8C7 14.1 7 14.7 7.4 15.1 7.7 15.4 8.3 15.4 8.6 15.1L15.1 8.6C15.4 8.3 15.4 7.7 15.1 7.4L8.6 0.9C8.3 0.6 7.7 0.6 7.4 0.9 7 1.3 7 1.9 7.4 2.2L12.2 7.1 1.6 7.1C1.1 7.1 0.7 7.5 0.7 8 0.7 8.5 1.1 8.9 1.6 8.9L12.2 8.9 7.4 13.8Z"
            />
          </svg>
        </slot>
      </span>
    </button>

    <a
      v-else-if="btnType === 'anchor'"
      v-on="$listeners"
      :class="buttonClass"
      class="button"
    >
      <slot />
    </a>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String
    },
    btnType: {
      type: String,
      default: "formBtn"
    },
    hasIcon: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
