var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-section"},[_c('div',{staticClass:"table-top"},[_c('b-row',[_c('b-col',{staticClass:"my-1",attrs:{"md":"4","lg":"4"}},[_c('p',{staticClass:"table-filter text-left"},[_c('BaseInput',{attrs:{"type":"text","label":"Search","inputType":'text',"placeholder":"Search something...","hasPreIcon":false},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)]),_c('b-col',{staticClass:"my-1",attrs:{"md":"8","lg":"8"}},[_c('p',{staticClass:"table-action text-right"},[_c('span',{staticClass:"excel-download-btn-hold"},[_c('button',{staticClass:"btn btn-primary btn-semi-rounded mb-2 mr-2",attrs:{"type":"button"}},[_c('i',{staticClass:"bx bxs-report"}),_vm._v("     "),_c('download-excel',{staticClass:"excel-download-btn",attrs:{"data":_vm.items,"fields":_vm.json_fields,"worksheet":"My Worksheet","name":"filename.xls"}},[_vm._v(" Download As Excel ")])],1)])])])],1)],1),_c('b-table',{attrs:{"striped":"","hover":"","fields":_vm.fields,"items":_vm.items,"busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-icon-left":"","responsive":"sm","current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filterIncludedFields":_vm.filterOn,"sort-direction":_vm.sortDirection},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(disputeStatus)",fn:function(data){return [_c('app-status-badge',{attrs:{"status-value":data.item.disputeStatus
            ? data.item.disputeStatus === 'CLOSED'
              ? 'RESOLVED'
              : data.item.disputeStatus === 'DECLINED'
              ? 'DECLINED'
              : data.item.disputeStatus
            : 'unknown'}})]}},{key:"cell(action)",fn:function(data){return [_c('p',{staticClass:"name-field"},[_c('AllDisputesTableActionModal',{attrs:{"current-data":data.item}})],1)]}},{key:"cell()",fn:function(data){return [_c('i',[_vm._v(_vm._s(data.value))])]}}])}),_c('b-row',[_c('b-col',{staticClass:"my-1",attrs:{"sm":"5","md":"4"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Per page","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm","label-for":"perPageSelect"}},[_c('b-form-select',{attrs:{"id":"perPageSelect","size":"sm","options":_vm.pageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"sm":"7","md":"8"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }