<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  mounted() {
    this.renderChart(
      {
        labels: ["Card Transactions", "NIP", "Intra Bank"],
        datasets: [
          {
            data: [300, 180, 110],
            backgroundColor: ["#4BA3F4", "#29C48D", "#F66866"],
            hoverBackgroundColor: ["#4BA3F4", "#29C48D", "#F66866"],
            hoverBorderColor: "#fff"
          }
        ]
      },
      {
        maintainAspectRatio: true,
        responsive: true
      }
    );
  }
};
</script>
