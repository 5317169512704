<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submitForm">
        <div class="stage-info text-left mb-3">
          <div class="row">
            <div class="col-12">
              <h4 class="main card-title mb-4">
                <span class="no">1.</span>
                Query Report Conditions
              </h4>
              <p class="sub">
                Decide the conditions for the columns of the Report.<br />
                <code>{{ reportCondition }}</code>
              </p>
            </div>
          </div>
        </div>

        <div class="row table-form-title">
          <!--          <div class="col-1">&nbsp;</div>-->
          <div class="col-1"><label>And/Or</label></div>
          <div class="col-4"><label>Column</label></div>
          <div class="col-3"><label>Operator</label></div>
          <div class="col-4"><label>Keyword</label></div>
        </div>

        <div
          v-for="(queryBlock, index) in formBody"
          :key="queryBlock.id"
          class="row"
        >
          <div class="col-1">
            <div>
              <div v-if="queryBlock.id === 0" class="form-group">
                <span class="faded-smoke"></span>
              </div>
              <div v-else class="form-group">
                <multiselect
                  v-model="formBody[index].andOr"
                  :options="andOrOptions"
                  placeholder="And/Or"
                  @change="formatCondition()"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div>
              <div class="form-group">
                <multiselect
                  v-model="formBody[index].columnName"
                  :options="allReportColumnsOption"
                  placeholder="Select Column"
                  @change="formatCondition()"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div>
              <div class="form-group">
                <multiselect
                  v-model="formBody[index].operator"
                  :options="operatorOptions"
                  placeholder="Select Operator"
                  @change="formatCondition()"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-4">
            <BaseInput
              :inputType="'text'"
              v-model="formBody[index].keyword"
              placeholder="keyword"
              :hasPreIcon="false"
              @change="formatCondition()"
            >
            </BaseInput>
          </div>
        </div>
        <p class="add-new-query-clause text-right">
          <span>
            <a @click="addNewQueryClause()" class="btn btn-secondary mr-1">
              Add New Clause
            </a>
          </span>
        </p>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button @click="$router.go(-1)" class="btn btn-light mr-1">
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Next
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../../utils/BaseUtils/StoreUtils";
import NotificationUtils from "../../../../../../utils/BaseUtils/NotificationUtils";

export default {
  name: "QueryConditions",
  data() {
    return {
      formBody: [
        {
          id: 0,
          columnName: "",
          operator: "",
          keyword: "",
          andOr: ""
        }
      ],
      reportCondition: "",
      andOrOptions: ["AND", "OR"],
      operatorOptions: ["=", "<>", ">", "<", ">=", "<=", "LIKE"]
    };
  },
  computed: {
    ...mapState(["rpt", "loader"]),

    allReportColumnsOption() {
      return StoreUtils.rootGetter(
        "rptUtilities/getQueryReportTableColumnsOption"
      );
    },
    rptStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE);
    }
  },
  created() {
    StoreUtils.dispatch("rptUtilities/fetchQueryReportTableColumns");
  },
  methods: {
    addNewQueryClause() {
      this.formatCondition();

      this.formBody.push({
        id: this.formBody.length,
        columnName: "",
        operator: "",
        keyword: "",
        andOr: ""
      });
    },
    deleteQueryClause(queryBlockToDelete) {
      this.formBody = this.formBody.filter(
        queryBlock => queryBlock.id !== queryBlockToDelete.id
      );
    },
    formatCondition() {
      this.reportCondition = this.formBody
        .reduce((accumulator, currentObject) => {
          switch (currentObject.operator) {
            case "LIKE":
              return `${accumulator} ${currentObject.andOr} ${currentObject.columnName} ${currentObject.operator} '%${currentObject.keyword}%'`;
            default:
              return `${accumulator} ${currentObject.andOr} ${currentObject.columnName} ${currentObject.operator} '${currentObject.keyword}'`;
          }
        }, "")
        .trim();
    },

    goBackTo(rptStage) {
      StoreUtils.dispatch(StoreUtils.actions.rpt.GO_BACK_TO_STAGE, rptStage);
    },
    submitForm() {
      this.formatCondition();

      let payload = {
        reportCondition:
          this.reportCondition === "''" ? "" : this.reportCondition
      };

      let notificationModalBody = {
        type: "warning", //error, success, warning
        mode: "yes-no", // default, yes-no
        title: "Confirmation",
        message:
          payload.reportCondition !== ""
            ? `Condition: ${payload.reportCondition}`
            : `Proceed with No Conditions?`,
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "Cancel",
        yesAction: () => {
          StoreUtils.dispatch(
            StoreUtils.actions.rpt.BUILD_QUERY_REPORT_REQUEST_BODY,
            payload
          );
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Proceed"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    }
  }
};
</script>

<style scoped></style>
