<template>
  <div>
    <div v-if="loader.componentLoader">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="submitForm">
        <div class="stage-info text-left mb-3">
          <div class="row">
            <div class="col-12">
              <h4 class="main card-title mb-4">
                <span class="no">2.</span>
                Set up Report Columns
              </h4>
              <p class="sub">
                Decide the columns that will feature in the Queried Report.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div>
              <div
                @click="selectAllColumns"
                :class="{ active: allColumnsSelected }"
                class="assign-default"
              >
                <span></span>
                <label>Select All Columns</label>
              </div>
              <b-form-group v-if="!allColumnsSelected" label="Report Columns">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="formBody.reportFieldList"
                  :options="allReportColumnsOption"
                  name="flavour"
                ></b-form-checkbox-group>
              </b-form-group>
              <div v-else class="form-group">
                <label>Report Columns</label>
                <span class="all-column-selected">All Columns Selected</span>
              </div>
              <template v-if="$v.formBody.reportFieldList.$error">
                <p
                  v-if="!$v.formBody.reportFieldList.required"
                  class="form-error-message"
                >
                  Please select a Column
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button
                  @click="goBackTo(rptStage - 1)"
                  class="btn btn-secondary mr-1"
                >
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button type="submit" class="btn btn-primary mr-1">
                  Query
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../../utils/BaseUtils/StoreUtils";
import { required } from "vuelidate/lib/validators";
import RouterUtils from "../../../../../../utils/BaseUtils/RouterUtils";

export default {
  name: "QueryColumnsForm",
  data() {
    return {
      formBody: this.freshFormBodyObject(),
      allColumnsSelected: false
    };
  },
  computed: {
    ...mapState(["rpt", "loader"]),
    reportType() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_REPORT_TYPE);
    },
    allReportColumnsOption() {
      return StoreUtils.rootGetter(
        "rptUtilities/getQueryReportTableColumnsOption"
      );
    },
    rptStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE);
    }
  },
  validations: {
    formBody: {
      reportFieldList: { required }
    }
  },
  methods: {
    selectAllColumns() {
      this.allColumnsSelected = this.allColumnsSelected === false;
    },
    goBackTo(rptStage) {
      StoreUtils.dispatch(StoreUtils.actions.rpt.GO_BACK_TO_STAGE, rptStage);
    },
    submitForm() {
      let payload = {
        reportFieldList: this.allColumnsSelected
          ? ""
          : this.formBody.reportFieldList.join(", ")
      };

      StoreUtils.dispatch(
        StoreUtils.actions.rpt.BUILD_QUERY_REPORT_REQUEST_BODY,
        payload
      );

      RouterUtils.changeRouteTo(RouterUtils.routes.rpt.VIEW_REPORT);
    },
    freshFormBodyObject() {
      return {
        source: "CBA",
        reportFieldList: []
      };
    }
  }
};
</script>

<style scoped></style>
