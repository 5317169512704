<template>
  <Layout>
    <!-- start page title -->
    <PageHeader :title="title" :items="items" />
    <!-- end page title -->

    <div class="row">
      <!--      <div class="col-xl-3">-->
      <!--        <BranchStatsPanel />-->
      <!--      </div>-->
      <!-- end col -->
      <div class="col-12 col-sm-12 col-md-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">All Customers Accounts Upgrade</h4>
            <!-- Supposed Form -->
            <div class="form-body">
              <AllLogsUpgradeTable></AllLogsUpgradeTable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
/**
 * Create Customer Component
 */


import Layout from "../../router/layouts/main";
import PageHeader from "../../components/AppComponents/page-header";
import AllLogsUpgradeTable from "../../components/Kleyton/Tables/Customer/AllLogsUpgradeTable";

export default {
  page: {
    title: "Customers Accounts Upgrade",
    meta: [{ name: "All Customer Accounts", content: window.__env.app.description }]
  },
  components: {
    Layout,
    PageHeader,
    AllLogsUpgradeTable

  },
  data() {
    return {
      title: "Customers Accounts Upgrade",

      items: [
        {
          text: "Customer",
          href: "/"
        },
        {
          text: "Customer Accounts Upgrade",
          active: true
        }
      ]
    };
  },
  methods: {}
};
</script>
