<template>
  <div>
    <div v-if="appUtilities.moduleLoadingSix">
      <BaseComponentLoader />
    </div>
    <div v-else>
      <form @submit.prevent="createCustomer">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="">
                <label>Customer Picture</label>

                <div class="custom-file">
                  <input
                    id="customerPictureUpload"
                    type="file"
                    placeholder="Customer Picture"
                    class="custom-file-input"
                    required
                    @change="updateSelectedCustomerPicture()"
                  />
                  <label
                    class="custom-file-label"
                    for="customerPictureUpload"
                    >{{ selectedCustomerPicture }}</label
                  >
                </div>
              </div>

              <template v-if="$v.createCustomerFormBody.customerPicture.$error">
                <p
                  v-if="!$v.createCustomerFormBody.customerPicture.required"
                  class="form-error-message"
                >
                  Customer Picture is required & must be an image
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createCustomerFormBody.customerFirstName"
                placeholder="First Name"
                :hasPreIcon="false"
                :label="'First Name'"
                :class="{
                  error: $v.createCustomerFormBody.customerFirstName.$error
                }"
                @blur="$v.createCustomerFormBody.customerFirstName.$touch()"
              >
              </BaseInput>

              <template
                v-if="$v.createCustomerFormBody.customerFirstName.$error"
              >
                <p
                  v-if="!$v.createCustomerFormBody.customerFirstName.required"
                  class="form-error-message"
                >
                  First Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createCustomerFormBody.customerMiddleName"
                placeholder="Middle Name"
                :hasPreIcon="false"
                :label="'Middle Name'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createCustomerFormBody.customerLastName"
                placeholder="Last Name"
                :hasPreIcon="false"
                :label="'Last Name'"
                :class="{
                  error: $v.createCustomerFormBody.customerLastName.$error
                }"
                @blur="$v.createCustomerFormBody.customerLastName.$touch()"
              >
              </BaseInput>

              <template
                v-if="$v.createCustomerFormBody.customerLastName.$error"
              >
                <p
                  v-if="!$v.createCustomerFormBody.customerLastName.required"
                  class="form-error-message"
                >
                  Last Name is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createCustomerFormBody.customerPhoneNumber"
                placeholder="Phone Number"
                :hasPreIcon="false"
                :label="'Phone Number'"
                :class="{
                  error: $v.createCustomerFormBody.customerPhoneNumber.$error
                }"
                @blur="$v.createCustomerFormBody.customerPhoneNumber.$touch()"
              >
              </BaseInput>

              <template
                v-if="$v.createCustomerFormBody.customerPhoneNumber.$error"
              >
                <p
                  v-if="!$v.createCustomerFormBody.customerPhoneNumber.required"
                  class="form-error-message"
                >
                  Phone Number is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <label>Country</label>
              <multiselect
                v-model="createCustomerFormBody.customerCountry"
                :options="customerCountryOptions"
                @close="$v.createCustomerFormBody.customerCountry.$touch()"
                placeholder="Country"
              ></multiselect>

              <template v-if="$v.createCustomerFormBody.customerCountry.$error">
                <p
                  v-if="!$v.createCustomerFormBody.customerCountry.required"
                  class="form-error-message"
                >
                  Country Code is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createCustomerFormBody.customerAddress"
                placeholder="Customer Address"
                :hasPreIcon="false"
                :label="'Customer Address'"
                :class="{
                  error: $v.createCustomerFormBody.customerAddress.$error
                }"
                @blur="$v.createCustomerFormBody.customerAddress.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createCustomerFormBody.customerAddress.$error">
                <p
                  v-if="!$v.createCustomerFormBody.customerAddress.required"
                  class="form-error-message"
                >
                  Customer Address is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'number'"
                v-model="createCustomerFormBody.customerBvn"
                placeholder="Customer Bvn"
                :hasPreIcon="false"
                :label="'Customer Bvn'"
                :class="{ error: $v.createCustomerFormBody.customerBvn.$error }"
                @blur="$v.createCustomerFormBody.customerBvn.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createCustomerFormBody.customerBvn.$error">
                <p
                  v-if="!$v.createCustomerFormBody.customerBvn.required"
                  class="form-error-message"
                >
                  Bvn is required
                </p>
                <p
                  v-if="!$v.createCustomerFormBody.customerBvn.numeric"
                  class="form-error-message"
                >
                  Bvn can only be Numbers
                </p>
                <p
                  v-if="!$v.createCustomerFormBody.customerBvn.minLength"
                  class="form-error-message"
                >
                  Bvn must be 11 numbers
                </p>
                <p
                  v-if="!$v.createCustomerFormBody.customerBvn.maxLength"
                  class="form-error-message"
                >
                  Bvn must be 11 numbers
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'email'"
                v-model="createCustomerFormBody.customerEmail"
                placeholder="Email"
                :hasPreIcon="false"
                :label="'Email'"
                :class="{
                  error: $v.createCustomerFormBody.customerEmail.$error
                }"
                @blur="$v.createCustomerFormBody.customerEmail.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createCustomerFormBody.customerEmail.$error">
                <p
                  v-if="!$v.createCustomerFormBody.customerEmail.required"
                  class="form-error-message"
                >
                  Email is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Date of Birth</label>
                <date-picker
                  v-model="createCustomerFormBody.customerDob"
                  placeholder="Date of Birth"
                  :first-day-of-week="1"
                  lang="en"
                  confirm
                  @blur="$v.createCustomerFormBody.customerDob.$touch()"
                ></date-picker>
              </div>

              <template v-if="$v.createCustomerFormBody.customerDob.$error">
                <p
                  v-if="!$v.createCustomerFormBody.customerDob.required"
                  class="form-error-message"
                >
                  Date of Birth is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="createCustomerFormBody.customerState"
                placeholder="Customer State"
                :hasPreIcon="false"
                :label="'Customer State'"
                :class="{
                  error: $v.createCustomerFormBody.customerState.$error
                }"
                @blur="$v.createCustomerFormBody.customerState.$touch()"
              >
              </BaseInput>

              <template v-if="$v.createCustomerFormBody.customerState.$error">
                <p
                  v-if="!$v.createCustomerFormBody.customerState.required"
                  class="form-error-message"
                >
                  Customer State is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Class</label>
                <multiselect
                  v-model="createCustomerFormBody.accountClass"
                  :options="accountClassOptions"
                  @close="$v.createCustomerFormBody.accountClass.$touch()"
                  placeholder="Account Class"
                ></multiselect>
              </div>
              <!--                          error-->
              <template v-if="$v.createCustomerFormBody.accountClass.$error">
                <p
                  v-if="!$v.createCustomerFormBody.accountClass.required"
                  class="form-error-message"
                >
                  Account Class is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="form-group">
                <label>Account Currency</label>
                <multiselect
                  v-model="createCustomerFormBody.accountCurrency"
                  :options="accountCurrencyOptions"
                  @close="$v.createCustomerFormBody.accountCurrency.$touch()"
                  placeholder="Account Currency"
                ></multiselect>
              </div>
              <!--                          error-->
              <template v-if="$v.createCustomerFormBody.accountCurrency.$error">
                <p
                  v-if="!$v.createCustomerFormBody.accountCurrency.required"
                  class="form-error-message"
                >
                  Account Currency is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <div class="">
                <label>Customer Mandate</label>

                <div class="custom-file">
                  <input
                    id="customerMandateUpload"
                    type="file"
                    placeholder="Customer Mandate"
                    class="custom-file-input"
                    required
                    @change="updateSelectedCustomerMandate()"
                  />
                  <label
                    class="custom-file-label"
                    for="customerMandateUpload"
                    >{{ selectedCustomerMandate }}</label
                  >
                </div>
              </div>

              <template v-if="$v.createCustomerFormBody.customerMandate.$error">
                <p
                  v-if="!$v.createCustomerFormBody.customerMandate.required"
                  class="form-error-message"
                >
                  Customer Mandate is required & must be an image
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label>Account Officer</label>
                <multiselect
                  v-model="createCustomerFormBody.customerAccountOfficer"
                  :options="accountOfficerOption"
                  placeholder="Account Officer"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
        <p class="text-right">
          <span v-if="customer.moduleLoading" class="formLoaderHold">
            <BaseComponentLoader />
          </span>
          <span v-else>
            <button type="submit" class="btn btn-primary mr-1">
              Create Customer
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  required,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "CreateCustomerForm",
  data() {
    return {
      createCustomerFormBody: this.createFreshcreateCustomerFormBodyObject(),
      selectedCustomerPicture: "Choose Image",
      selectedCustomerMandate: "Choose Image"
    };
  },
  components: {
    Multiselect,
    DatePicker
  },
  computed: {
    ...mapState(["customer", "appUtilities", "appS3Upload"]),
    ...mapGetters({
      getAccountClassFromOptionUtility:
        "appUtilities/getAccountClassFromOptionUtility",
      accountCurrencyOptions: "appUtilities/getCurrencyUtilityOption",
      customerCountryOptions: "appUtilities/getCountryUtilityOption",
      accountOfficerOption: "appUtilities/getAllUsersOption",
      getAccountOfficerUserIdFromOptionUtility:
        "appUtilities/getUserIdFromOptionUtility"
    }),
    accountClassOptions() {
      return StoreUtils.rootGetter(
        "appUtilities/getAccountClass2UtilityOption"
      ).filter(accountClass => accountClass.Type !== "FIXED");
    }
  },
  validations: {
    createCustomerFormBody: {
      customerPicture: { required },
      customerMandate: { required },
      customerPhoneNumber: { required },
      customerBvn: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11)
      },
      customerEmail: { required },
      customerFirstName: { required },
      customerLastName: { required },
      customerDob: { required },
      customerCountry: { required },
      customerState: { required },
      customerAddress: { required },
      accountClass: { required },
      accountCurrency: { required }
    }
  },

  created() {
    this.$store.dispatch("appUtilities/fetchAllAccountClass2", null, {
      root: true
    });
    this.$store.dispatch("appUtilities/fetchAllUsers", null, { root: true });
  },

  methods: {
    updateSelectedCustomerPicture() {
      let files = document.getElementById("customerPictureUpload").files;
      this.selectedCustomerPicture = files[0].name;
    },

    updateSelectedCustomerMandate() {
      let files = document.getElementById("customerMandateUpload").files;
      this.selectedCustomerMandate = files[0].name;
    },

    async customerPictureUpload() {
      let files = document.getElementById("customerPictureUpload").files;
      if (!files.length) {
        return this.$v.createCustomerFormBody.customerPicture.$touch();
      } else {
        let file = files[0];
        let fileExtension = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );
        // let fileName = file.name;
        if (file.type.includes("image")) {
          let payload = {
            appAlbumName: "StagingArea",
            fileName: this.createCustomerFormBody.customerPhoneNumber,
            fileExtension: fileExtension,
            file: file
          };

          return await this.$store.dispatch(
            "appS3Upload/uploadCustomerPictureToStagingArea",
            payload,
            {
              root: true
            }
          );
        } else {
          return this.$v.createCustomerFormBody.customerPicture.$touch();
        }
      }
    },
    async customerMandateUpload() {
      let files = document.getElementById("customerMandateUpload").files;
      if (!files.length) {
        return this.$v.createCustomerFormBody.customerMandate.$touch();
      } else {
        let file = files[0];
        let fileExtension = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );
        // let fileName = file.name;
        if (file.type.includes("image")) {
          let payload = {
            appAlbumName: "StagingArea",
            fileName: this.createCustomerFormBody.customerPhoneNumber,
            fileExtension: fileExtension,
            file: file
          };

          return await this.$store.dispatch(
            "appS3Upload/uploadCustomerMandateToStagingArea",
            payload,
            {
              root: true
            }
          );
        } else {
          return this.$v.createCustomerFormBody.customerMandate.$touch();
        }
      }
    },

    async createCustomer() {
      this.createCustomerFormBody.customerCountryCode = "234";
      this.createCustomerFormBody.customerBranchCode = this.$store.getters[
        "user/getUserBranchCode"
      ];
      this.createCustomerFormBody.customerBankCode = this.$store.getters[
        "user/getUserBankCode"
      ];

      // start module loading for file upload
      this.$store.dispatch("customer/setModuleLoading", true, { root: true });

      await this.customerPictureUpload();
      await this.customerMandateUpload();

      // Set Current Customer Phone Number to be used later for file name
      this.$store.dispatch(
        "appS3Upload/setCurrentCustomerPhoneNumber",
        this.createCustomerFormBody.customerPhoneNumber,
        { root: true }
      );
      // assign the destination File Path to the CustomerPicture
      this.createCustomerFormBody.customerPicture = await this.$store.getters[
        "appS3Upload/getCurrentCustomerPictureFinalPath"
      ];
      // assign the destination File Path to the CustomerMandate
      this.createCustomerFormBody.customerMandate = await this.$store.getters[
        "appS3Upload/getCurrentCustomerMandateFinalPath"
      ];

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createCustomerFormBody.customerMaker = this.$store.getters[
          "user/getUserId"
        ];
        this.createCustomerFormBody.customerDob = moment(
          this.createCustomerFormBody.customerDob
        ).format("DD-MMM-YYYY");
        this.createCustomerFormBody.customerAccountOfficer = this.getAccountOfficerUserIdFromOptionUtility(
          this.createCustomerFormBody.customerAccountOfficer
        );
        this.createCustomerFormBody.accountClass = this.getAccountClassFromOptionUtility(
          this.createCustomerFormBody.accountClass
        );

        this.$store
          .dispatch("customer/createCustomer", this.createCustomerFormBody, {
            root: true
          })
          .then(() => {
            this.createCustomerFormBody = {
              // source: "web"
            };
            this.$v.$reset();
          })
          .catch(() => {});
      } else {
        this.$store.dispatch("customer/setModuleLoading", false, {
          root: true
        });
      }
    },
    createFreshcreateCustomerFormBodyObject() {
      return {
        // source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
