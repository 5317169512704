<template>
  <div>
    <div>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'number'"
                v-model="formBody.customerPhoneNumber"
                placeholder="Phone Number"
                :hasPreIcon="false"
                :label="'Phone Number'"
                :class="{
                  error: $v.formBody.customerPhoneNumber.$error
                }"
                @blur="$v.formBody.customerPhoneNumber.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerPhoneNumber.$error">
                <p
                  v-if="!$v.formBody.customerPhoneNumber.required"
                  class="form-error-message"
                >
                  Phone Number is required
                </p>
              </template>
            </div>
          </div>

          <div
            v-if="currentFormBody.customerType === 'PERSONAL'"
            class="col-sm-6"
          >
            <div>
              <BaseInput
                :inputType="'number'"
                v-model="formBody.customerOtherPhoneNumber"
                placeholder="Alternative Phone Number"
                :hasPreIcon="false"
                :label="'Alternative Phone Number'"
              >
              </BaseInput>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerAddress"
                placeholder="Customer Address"
                :hasPreIcon="false"
                :label="'Customer Address'"
                :class="{
                  error: $v.formBody.customerAddress.$error
                }"
                @blur="$v.formBody.customerAddress.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerAddress.$error">
                <p
                  v-if="!$v.formBody.customerAddress.required"
                  class="form-error-message"
                >
                  Customer Address is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerCity"
                placeholder="Customer City"
                :hasPreIcon="false"
                :label="'Customer City'"
                :class="{
                  error: $v.formBody.customerCity.$error
                }"
                @blur="$v.formBody.customerCity.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerCity.$error">
                <p
                  v-if="!$v.formBody.customerCity.required"
                  class="form-error-message"
                >
                  Customer City is required
                </p>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerState"
                placeholder="Customer State"
                :hasPreIcon="false"
                :label="'Customer State'"
                :class="{
                  error: $v.formBody.customerState.$error
                }"
                @blur="$v.formBody.customerState.$touch()"
              >
              </BaseInput>

              <template v-if="$v.formBody.customerState.$error">
                <p
                  v-if="!$v.formBody.customerState.required"
                  class="form-error-message"
                >
                  Customer State is required
                </p>
              </template>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <label>Country</label>
              <multiselect
                v-model="formBody.customerCountry"
                :options="customerCountryOptions"
                @close="$v.formBody.customerCountry.$touch()"
                placeholder="Country"
              ></multiselect>

              <template v-if="$v.formBody.customerCountry.$error">
                <p
                  v-if="!$v.formBody.customerCountry.required"
                  class="form-error-message"
                >
                  Country Code is required
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="row" v-if="currentFormBody.customerType === 'PERSONAL'">
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerNextOfKinName"
                placeholder="Name of Next of Kin"
                :hasPreIcon="false"
                :label="'Next of Kin (Full Name)'"
              >
              </BaseInput>
            </div>
          </div>
          <div class="col-sm-6">
            <div>
              <BaseInput
                :inputType="'number'"
                v-model="formBody.customerNextOfKinPhoneNumber"
                placeholder="Phone Number of Next of Kin"
                :hasPreIcon="false"
                :label="'Next of Kin (Phone Number)'"
              >
              </BaseInput>
            </div>
          </div>
          <div class="col-sm-12">
            <div>
              <BaseInput
                :inputType="'text'"
                v-model="formBody.customerNextOfKinAddress"
                placeholder="Address of Next of Kin"
                :hasPreIcon="false"
                :label="'Next of Kin (Address)'"
              >
              </BaseInput>
            </div>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-sm-6">
            <p class="text-left">
              <span>
                <button @click="goBack" class="btn btn-secondary mr-1">
                  Back
                </button>
              </span>
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-right">
              <span v-if="loader.actionButtonLoader" class="formLoaderHold">
                <BaseComponentLoader />
              </span>
              <span v-else>
                <button
                  v-if="currentFormBody.customerType === 'BUSINESS'"
                  type="submit"
                  class="btn btn-primary mr-1"
                >
                  SUBMIT
                </button>
                <button
                  v-if="currentFormBody.customerType === 'PERSONAL'"
                  type="submit"
                  class="btn btn-primary mr-1"
                >
                  NEXT
                </button>
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export default {
  name: "AccountExtraInfoForm",
  data() {
    return {
      formBody: this.createFreshcreateCustomerFormBodyObject()
    };
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapState(["loader", "appUtilities"]),
    ...mapGetters({
      customerCountryOptions: "appUtilities/getCountryUtilityOption",
      accountCurrencyOptions: "appUtilities/getCurrencyUtilityOption"
    }),
    currentFormBody() {
      return StoreUtils.rootGetter("form/getFormBody");
    }
  },
  validations: {
    formBody: {
      customerPhoneNumber: { required },
      customerAddress: { required },
      customerCity: { required },
      customerState: { required },
      customerCountry: { required }
    }
  },
  methods: {
    goBack() {
      if (this.currentFormBody.customerType === "BUSINESS") {
        StoreUtils.commit("form/SET_FORM_STAGE_TO", 2);
      } else {
        StoreUtils.commit("form/SET_FORM_STAGE_TO", 1);
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.currentFormBody.customerType === "BUSINESS") {
          StoreUtils.commit("form/BUILD_FORM_BODY", this.formBody);
          StoreUtils.dispatch("customer/createCustomerAccount");
        }
        if (this.currentFormBody.customerType === "PERSONAL") {
          StoreUtils.commit("form/BUILD_FORM_BODY", this.formBody);
          StoreUtils.commit("form/SET_FORM_STAGE_TO", 4);
        }
      }
    },
    createFreshcreateCustomerFormBodyObject() {
      return {
        // source: "web"
      };
    }
  }
};
</script>

<style scoped></style>
