<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>

    <input
      v-if="inputType === 'amount'"
      :value="value"
      @input="updateValue"
      v-bind="$attrs"
      type="number"
      step="any"
      class="form-control"
      :class="{ 'has-pre-icon': hasPreIcon }"
    />
    <input
      v-else
      :value="value"
      @input="updateValue"
      v-bind="$attrs"
      :type="inputType"
      class="form-control"
      :class="{ 'has-pre-icon': hasPreIcon }"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    inputType: {
      type: String,
      default: "text"
    },
    value: [String, Number, Object],
    hasPreIcon: {
      type: Boolean,
      default: false
    },
    label: String
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style scoped>
.base-input-holder {
  background-color: #ffffff;
  padding: 13px 13px 11px 13px;
  transition: ease-in-out all 0.2s;
}
.error .base-input-holder {
  padding: 13px 13px 25px 13px !important;
}
</style>
