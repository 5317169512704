import BaseService from "./BaseService";
import UrlUtils from "../../utils/BaseUtils/UrlUtils";

class BankUserService extends BaseService {
  editUser(
    payload,
    successAction,
    loaderType,
    errorType,
    showSuccessMessage,
    successCondition,
    getErrorMessage
  ) {
    return this.makePostRequest(
      UrlUtils.urls.bankUser.EDIT_USER,
      payload,
      successAction,
      loaderType,
      errorType,
      showSuccessMessage,
      successCondition,
      getErrorMessage
    );
  }
  resetUserPassword(
    payload,
    successAction,
    loaderType,
    errorType,
    showSuccessMessage,
    successCondition,
    getErrorMessage
  ) {
    return this.makePostRequest(
      UrlUtils.urls.bankUser.RESET_USER_PASSWORD,
      payload,
      successAction,
      loaderType,
      errorType,
      showSuccessMessage,
      successCondition,
      getErrorMessage
    );
  }
}

export default BankUserService;
