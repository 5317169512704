<template>
  <div class="row mb-2">
    <div class="col-sm-6">
      <p class="queue-title">Title</p>
      <p class="queue-value">
        {{ eod.currentEod.eodTitle }}
      </p>
    </div>

    <div class="col-sm-6 text-right">
      <p class="queue-title">Maker</p>
      <p class="queue-value">
        {{ eod.currentEod.eodMaker }}
      </p>
    </div>

    <div class="col-sm-6">
      <p class="queue-title">Percentage Done</p>
      <p class="queue-value">
        {{ eod.currentEod.eodPercent }}
      </p>
    </div>

    <div class="col-sm-6 text-right">
      <p class="queue-title">Status</p>
      <p class="queue-value">
        <span
          v-if="eod.currentEod.eodStatus === 'DONE'"
          class="badge badge-pill badge-soft-success font-size-12"
        >
          {{ eod.currentEod.eodStatus }}
        </span>
        <span v-else class="badge badge-pill badge-soft-warning font-size-12">{{
          eod.currentEod.eodStatus
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EodInfoCard",
  computed: {
    ...mapState(["eod"])
  }
};
</script>

<style scoped>
.queue-title {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  margin-top: 20px;
  color: #9e9e9e;
}

.queue-value {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2a3042;
}
</style>
