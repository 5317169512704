var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":`View ${_vm.product.selectedProductType.prdTemplateDestcription.toLowerCase()} Product`,"items":[
      ..._vm.items,
      {
        ..._vm.pageHeaderItem,
        text: `View ${_vm.product.selectedProductType.prdTemplateDestcription.toLowerCase()} Product`
      }
    ]}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-body"},[_c('ViewDynamicProductsTable')],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }