<template>
  <div>
    <!--    <p>{{ createRoleFormBody.rolePrivileges }}</p>-->
    <div class="row">
      <div class="col-sm-6">
        <p class="queue-title">Role</p>
        <p class="queue-value">
          {{ currentRole.roleName }}
        </p>
      </div>

      <div class="col-sm-6 text-right">
        <p class="queue-title">Role Maker</p>
        <p class="queue-value">
          {{ currentRole.roleMaker }}
        </p>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="row">
        <div class="col-xl-12">
          <p class="card-title-desc mt-3 mb-0">
            <code>Edit Role Privileges</code>
          </p>
          <div>
            <div class="row">
              <div class="col-12">
                <BaseInput
                  :inputType="'amount'"
                  v-model="formBody.roleTrnLimit"
                  placeholder="Transaction Limit"
                  :hasPreIcon="false"
                  :label="'Limit'"
                  :class="{ error: $v.formBody.roleTrnLimit.$error }"
                  @blur="$v.formBody.roleTrnLimit.$touch()"
                >
                </BaseInput>

                <template v-if="$v.formBody.roleTrnLimit.$error">
                  <p
                    v-if="!$v.formBody.roleTrnLimit.required"
                    class="form-error-message"
                  >
                    Transaction Limit is required
                  </p>

                  <p
                    v-if="!$v.formBody.roleTrnLimit.mustBeGreaterThanZero"
                    class="form-error-message"
                  >
                    Transaction Limit must be greater than 0
                  </p>
                </template>
              </div>
            </div>

            <div
              v-for="(value, key) in getPrivilegeSeparatedByRoles"
              :key="key"
              class="row"
            >
              <div class="col-sm-12">
                <p class="module-title font-size-14 mt-3 mb-3">
                  {{ key | firstCaseCapitalSpace }}
                </p>
              </div>

              <div class="col-sm-12">
                <b-form-checkbox-group switch v-model="formBody.rolePrivileges">
                  <b-form-checkbox
                    v-for="privilege in value"
                    :key="privilege.privilegeId"
                    :value="privilege.privilegeId"
                  >
                    <span
                      v-if="
                        privilege.privilegeType.toLowerCase() === 'checker' ||
                          privilege.privilegeType.toLowerCase() === 'maker'
                      "
                    >
                      {{
                        (privilege.privilegeModuleName +
                          " " +
                          privilege.privilegeType)
                          | firstCaseCapitalSpace
                      }}
                    </span>
                    <span v-else>
                      {{
                        (privilege.privilegeType +
                          " " +
                          privilege.privilegeModuleName)
                          | firstCaseCapitalSpace
                      }}
                    </span>
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <template v-if="$v.formBody.rolePrivileges.$error">
                  <p
                    v-if="!$v.formBody.rolePrivileges.required"
                    class="form-error-message"
                  >
                    Role Privileges is required
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="text-right">
        <span v-if="loader.actionButtonLoader" class="formLoaderHold">
          <BaseComponentLoader />
        </span>
        <span v-else>
          <button type="submit" class="btn btn-primary mr-1">
            Create Role
          </button>
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import Utils from "../../../../utils/Utils";
import RouterUtils from "../../../../utils/BaseUtils/RouterUtils";

export default {
  name: "EditRolePrivilegeForm",
  data() {
    return {
      formBody: this.createFreshFormBodyObject(),
      customError: false
    };
  },
  computed: {
    ...mapState(["role", "loader"]),
    ...mapGetters({
      getPrivilegeSeparatedByRoles: "appUtilities/getPrivilegeSeparatedByRoles"
    }),
    currentRole() {
      return StoreUtils.rootGetter("role/getCurrentRole");
    }
  },
  created() {
    if (Utils.objIsEmpty(this.currentRole)) {
      RouterUtils.changeRouteTo(RouterUtils.routes.role.ALL_ROLES);
    } else {
      this.formBody.rolePrivileges = this.currentRole.privileges.reduce(
        (accumulator, currentPrivilege) => {
          return [...accumulator, currentPrivilege.privilegeId];
        },
        []
      );
      this.formBody.roleTrnLimit = this.currentRole.roleTrnLimit;
    }
  },
  validations: {
    formBody: {
      rolePrivileges: { required },
      roleTrnLimit: { required }
    }
  },
  methods: {
    showCustomError() {
      this.customError = true;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let payload = {
          rolePrivileges: this.formBody.rolePrivileges.map(str => ({
            privilegeId: str
          })),
          roleTrnLimit: this.formBody.roleTrnLimit
        };

        StoreUtils.dispatch("role/updateRolePrivileges", payload);
      }
    },
    createFreshFormBodyObject() {
      return {
        source: "CBA",
        rolePrivileges: []
        // rolePrivileges: [ "0000000002", "0000000006", "0000000014", "0000000019", "0000000024", "0000000028" ]
      };
    }
  }
};
</script>

<style scoped>
.module-title {
  font-size: 12px !important;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 5px !important;
  margin-top: 30px !important;
  color: #556ee6;
}
.queue-title {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  margin-top: 20px;
  color: #9e9e9e;
}

.queue-value {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2a3042;
}
</style>
