<template>
  <div>
    <div v-if="ftStage === 1">
      <CreditEnquiryForm />
    </div>
    <div v-if="ftStage === 2">
      <CdFinalForm />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import CreditEnquiryForm from "../../Forms/FundsTransfer/Deposit/CreditEnquiryForm";
import CdFinalForm from "../../Forms/FundsTransfer/Deposit/CdFinalForm";

export default {
  name: "CashDepositCard",
  components: { CdFinalForm, CreditEnquiryForm },
  computed: {
    ...mapState(["ft", "loader"]),
    ftStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.ft.GET_FT_STAGE);
    }
  },
  methods: {}
};
</script>

<style scoped></style>
