<template>
  <div class="table-section">
    <div class="table-top">
      <b-row>
        <b-col md="4" lg="4" class="my-1">
          <p class="table-filter text-left">
            <BaseInput
              type="text"
              label="Search"
              :inputType="'text'"
              v-model="filter"
              placeholder="Search something..."
              :hasPreIcon="false"
            >
            </BaseInput>
          </p>
        </b-col>
        <b-col md="8" lg="8" class="my-1">
          <p class="table-action text-right">
            <span class="excel-download-btn-hold">
              <button
                type="button"
                class="btn btn-primary btn-semi-rounded mb-2 mr-2"
              >
                <i class="bx bxs-report"></i> &nbsp; &nbsp;
                <download-excel
                  class="excel-download-btn"
                  :data="items"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="filename.xls"
                >
                  Download As Excel
                </download-excel>
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(name)="data">
        <p class="name-field">
          <span>{{
            `${data.item.userFirstName} ${data.item.userMiddleName} ${data.item.userLastName}`
              | toLowercase
          }}</span>
        </p>
      </template>

      <template v-slot:cell(roleName)="data">
        <p class="name-field">
          <span>{{ data.item.roleName | toLowercase }}</span>
        </p>
      </template>

      <template v-slot:cell(userEmail)="data">
        <p class="name-field">
          <span>{{ data.item.userEmail }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(userPhone)="data">
        <p class="name-field">
          <span>{{ data.item.userPhone }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(username)="data">
        <p class="name-field">
          <span>{{ data.item.username }}</span>
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(userStatus)="data">
        <p class="name-field">
          <AppStatusBadge :status-value="data.item.userStatus" />
        </p>
      </template>

      <!-- A virtual composite column -->
      <template v-slot:cell(action)="data">
        <p class="name-field">
          <b-dropdown
            class="card-drop"
            variant="white"
            right
            toggle-class="p-0"
          >
            <template v-slot:button-content>
              <i class="mdi mdi-dots-horizontal font-size-18" />
            </template>

            <b-dropdown-item @click="resetPassword(data.item)">
              <i class="fas fa-refresh text-warning mr-1" /> Reset Password
            </b-dropdown-item>
            <b-dropdown-item @click="editUser(data.item)">
              <i class="fas fa-edit text-warning mr-1" /> Edit User
            </b-dropdown-item>
          </b-dropdown>
        </p>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template v-slot:cell()="data">
        <i>{{ data.value }}</i>
      </template>
    </b-table>

    <!--        actual modal-->
    <vodal
      :show="modalOpen"
      :width="450"
      :height="550"
      measure="px"
      animation="zoom"
      @hide="closeModal()"
      :closeOnClickMask="true"
      className="app-modal"
    >
      <div>
        <div class="card-body">
          <div>
            <div v-if="modalOpen" class="more-info-box">
              <p class="title">Edit User</p>
              <EditUserForm :currentUser="currentUser" />
            </div>
          </div>
        </div>
      </div>
    </vodal>

    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../utils/BaseUtils/StoreUtils";
import NotificationUtils from "../../../../utils/BaseUtils/NotificationUtils";
import EditUserForm from "@/components/Kleyton/Forms/User/EditUserForm";

export default {
  name: "AllUsersTable",
  components: { EditUserForm },
  data() {
    return {
      modalOpen: false,
      currentUser: {},
      sortBy: "userUpdatedAt",
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "S/N", sortable: true, class: "text-center" },

        // A column that needs custom formatting
        { key: "userId", label: "Id", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "roleName", label: "Role Name", sortable: true },
        { key: "userEmail", label: "Email", sortable: true },
        { key: "userPhone", label: "PhoneNumber", sortable: true },
        { key: "username", label: "Username", sortable: true },
        { key: "userStatus", label: "Status", sortable: true },
        { key: "action", label: "Action", sortable: false }
      ],

      json_fields: {
        "Bank Code": "userBankCode",
        "Branch Code": "userBranchCode",
        "Created At": "userCreatedAt",
        "role Name": "roleName",
        Email: "userEmail",
        "First Name": "userFirstName",
        "user Id": "userId",
        "Last Name": "userLastName",
        "Middle Name": "userMiddleName",
        Phone: "userPhone",
        "Role Id": "userRoleId",
        Status: "userStatus",
        username: "username"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState({
      items: state => state.bankUser.users,
      isBusy: state => state.bankUser.moduleLoadingThree
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  created() {
    this.fetchTableData();
    this.filter = " ";
  },
  methods: {
    editUser(data) {
      this.currentUser = data;
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    async fetchTableData() {
      const payload = {
        userBankCode: StoreUtils.rootGetter(
          StoreUtils.getters.user.GET_USER_BANK_CODE
        ),
        source: "CBA",
        readAll: "YES"
      };

      await StoreUtils.dispatch("bankUser/allUsers", payload);
      // Set the initial number of items
      this.totalRows = this.items.length;
      this.filter = " ";
    },
    resetPassword(data) {
      let notificationModalBody = {
        type: "warning", //error, success, warning
        mode: "yes-no", // default, yes-no
        title: "Confirmation",
        message: "Are you sure you want to Reset this user's Password?",
        noAction: () => {
          NotificationUtils.closeNotificationModal();
        },
        noActionTitle: "No",
        yesAction: () => {
          StoreUtils.dispatch("bankUser/resetUserPassword", {
            username: data.username
          });
          NotificationUtils.closeNotificationModal();
        },
        yesActionTitle: "Yes"
      };

      NotificationUtils.showNotificationModal(notificationModalBody);
    },
    returnItems() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style scoped>
/*.VueTables__date-filter {*/
/*  border: 1px solid #ccc;*/
/*  padding: 6px;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
</style>
