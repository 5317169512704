<template>
  <div>
    <div v-if="rptStage === 1">
      <QueryConditions />
    </div>
    <div v-if="rptStage === 2">
      <QueryColumns />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../../../utils/BaseUtils/StoreUtils";
import QueryConditions from "../../../Forms/Report/RPT/QueryReport/QueryConditionsForm";
import QueryColumns from "../../../Forms/Report/RPT/QueryReport/QueryColumnsForm";
import RouterUtils from "../../../../../utils/BaseUtils/RouterUtils";

export default {
  name: "QueryReportCard",
  components: {
    QueryColumns,
    QueryConditions
  },
  computed: {
    ...mapState(["ft", "loader"]),
    rptStage() {
      return StoreUtils.rootGetter(StoreUtils.getters.rpt.GET_STAGE);
    }
  },
  created() {
    this.resetRptStage();
    this.check();
  },
  methods: {
    check() {
      if (StoreUtils.rootGetter("rpt/getCurrentReportName") === "") {
        RouterUtils.changeRouteTo(RouterUtils.routes.rpt.VIEW_ALL_REPORTS);
      }
    },
    resetRptStage() {
      StoreUtils.commit("rpt/SET_RPT_STAGE_TO", 1);
    }
  }
};
</script>

<style scoped></style>
