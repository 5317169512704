<template>
  <div>
    <FormulateForm @submit="submit">
      <div class="row">
        <div class="col-6 mb-3">
          <FormulateInput
            name="customerPicture"
            type="image"
            multiple="false"
            label="Customer Picture"
            validation="required|mime:image/jpeg,image/png,image/gif"
            validation-name="Customer Picture"
            placeholder="Choose Picture"
          />
        </div>
        <div class="col-6 mb-3">
          <FormulateInput
            name="customerMandate"
            type="image"
            targetName="cff"
            multiple="false"
            label="Customer Mandate"
            validation="required|mime:image/jpeg,image/png,image/gif"
            validation-name="Mandate"
            placeholder="Choose Mandate"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <p class="text-left">
            <span>
              <button @click="goBack" class="btn btn-secondary mr-1">
                Back
              </button>
            </span>
          </p>
        </div>
        <div class="col-sm-6">
          <p class="text-right">
            <span v-if="loader.actionButtonLoader" class="formLoaderHold">
              <BaseComponentLoader />
            </span>
            <span v-else>
              <FormulateInput type="submit" label="Submit" />
            </span>
          </p>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import { mapState } from "vuex";

export default {
  name: "CreatePersonalAccountFinalStageForm",
  computed: {
    ...mapState(["loader", "appUtilities"])
  },
  data() {
    return {
      formBody: {
        customerPicture: "",
        customerMandate: ""
      }
    };
  },
  methods: {
    goBack() {
      StoreUtils.commit("form/DECREASE_FORM_STAGE_BY_ONE");
    },
    submit(data) {
      StoreUtils.commit("form/BUILD_FORM_BODY", data);
      StoreUtils.dispatch("customer/createCustomerAccount");
    }
  }
};
</script>

<style scoped></style>
