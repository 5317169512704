import axios from "axios";
import NProgress from "nprogress";
import store from "../../store/store";
import RouterUtils from "../../utils/BaseUtils/RouterUtils";

const apiClient = axios.create({
  baseURL: window.__env.api.baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 10000
});
const tempClient = axios.create({
  baseURL: window.__env.api.tbaseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 10000
});

apiClient.interceptors.request.use(config => {
  const token = store.getters["user/getUserTokenFromLocalStorage"];
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});
apiClient.interceptors.response.use(response => {
  NProgress.done();  if (response.data.responseCode === "92") {RouterUtils.changeRouteTo("login");}

  return response;
});

// Temp service


tempClient.interceptors.request.use(config => {
  const token = store.getters["user/getUserTokenFromLocalStorage"];
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});

tempClient.interceptors.response.use(response => {
  NProgress.done();  if (response.data.responseCode === "92") {RouterUtils.changeRouteTo("login");}

  return response;
});

export default {
  checkerAuthorizeCustomerSubAccount(requestBody) {
    return apiClient.post("/accountChecker", JSON.stringify(requestBody));
  },
  allCustomersSubAccountsCheckersQueue(requestBody) {
    return apiClient.post("/accountChecker", JSON.stringify(requestBody));
  },
  checkerAuthorizeCustomerCreate(requestBody) {
    return apiClient.post("/customerChecker", JSON.stringify(requestBody));
  },
  allCreateCustomersCheckersQueue(requestBody) {
    return apiClient.post("/customerChecker", JSON.stringify(requestBody));
  },
  allCustomersAccounts(requestBody) {
    return apiClient.post("/accountRead", JSON.stringify(requestBody));
  },
  allCustomers(requestBody) {
    return apiClient.post("/customerRead", JSON.stringify(requestBody));
  },
  createCustomer(requestBody) {
    return apiClient.post("/customerCreate", JSON.stringify(requestBody));
  },
  addSubAccount(requestBody) {
    return apiClient.post("/accountCreate", JSON.stringify(requestBody));
  },
  allAccounts(requestBody) {
    return apiClient.post("/accountRead", JSON.stringify(requestBody));
  },
  createLien(requestBody) {
    return apiClient.post("/account/lien-amount", JSON.stringify(requestBody));
  },
  removeLien(requestBody) {
    return apiClient.post("/account/remove-lien-amount", JSON.stringify(requestBody));
  },
  allLien(requestBody) {
    return apiClient.post("/account/lien-list", JSON.stringify(requestBody));
  },
  viewMoreCustomerInfo(requestBody) {
    return apiClient.post("/customerReadSingle", JSON.stringify(requestBody));
  },
  viewUpgradeLogs(requestBody) {
    return tempClient.post("/upgrade-logs/read", JSON.stringify(requestBody));
  },
  viewUpgradeLogsReadByCustomerId(requestBody) {
    return tempClient.post("/upgrade-logs/read-by-customerid", JSON.stringify(requestBody));
  },
  upgradeLogsUpdate(requestBody) {
    return tempClient.post("/upgrade-logs/update", JSON.stringify(requestBody));
  },
  declineUpgradeLogs(requestBody) {
    return tempClient.post("/upgrade-logs/decline", JSON.stringify(requestBody));
  },
  approveUpgradeLogs(requestBody) {
    return tempClient.post("/upgrade-logs/approve", JSON.stringify(requestBody));
  }
};
