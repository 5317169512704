import Utils from "../../../utils/Utils";

export default {
  SET_UTILITY_BANKS(state, payload) {
    state.allBanks = payload;
  },
  SET_UTILITY_BRANCHES(state, payload) {
    state.allBranches = payload;
  },
  SET_UTILITY_ROLES(state, payload) {
    state.allRoles = payload;
  },
  SET_UTILITY_PRIVILEGES(state, payload) {
    state.allPrivileges = payload;
  },
  SET_UTILITY_MODULES(state, payload) {
    state.allModules = payload;
  },
  SET_UTILITY_TRANSACTION_CODES(state, payload) {
    state.allTransactionCodes = payload;
  },
  SET_UTILITY_CHARGE_PRODUCTS(state, payload) {
    state.allChargeProducts = payload;
  },
  SET_UTILITY_TELLERS(state, payload) {
    state.allTellers = payload;
  },
  SET_ALL_INTERNAL_ACCOUNTS(state, payload) {
    state.allInternalAccounts = payload;
  },
  SET_UTILITY_GL_LEVEL_ONE(state, payload) {
    state.allGlLevel1 = payload;
  },
  SET_UTILITY_GL_LEVEL_TWO(state, payload) {
    state.allGlLevel2 = payload;
  },
  SET_UTILITY_ACCOUNT_CLASS(state, payload) {
    state.allAccountClass = payload;
  },
  SET_UTILITY_ACCOUNT_CLASS_2(state, payload) {
    state.allAccountClass2 = payload;
  },
  SET_UTILITY_LOAN_PRODUCT(state, payload) {
    state.allLoanProduct = payload;
  },
  SET_UTILITY_PRODUCT_CODE(state, payload) {
    state.productCodeOptions = payload;
  },
  SET_UTILITY_FT_PRODUCT(state, payload) {
    state.ftProducts = payload;
  },
  SET_UTILITY_ALL_USERS(state, payload) {
    state.allUsers = payload;
  },
  SET_LOADING(state, status) {
    state.moduleLoading = status;
  },
  SET_LOADING_TWO(state, status) {
    state.moduleLoadingTwo = status;
  },
  SET_LOADING_THREE(state, status) {
    state.moduleLoadingThree = status;
  },
  SET_LOADING_FOUR(state, status) {
    state.moduleLoadingFour = status;
  },
  SET_LOADING_FIVE(state, status) {
    state.moduleLoadingFive = status;
  },
  SET_LOADING_SIX(state, status) {
    state.moduleLoadingSix = status;
  },
  SET_LOADING_SEVEN(state, status) {
    state.moduleLoadingSeven = status;
  },
  SET_LOADING_EIGHT(state, status) {
    state.moduleLoadingEight = status;
  },
  SET_LOADING_NINE(state, status) {
    state.moduleLoadingNine = status;
  },
  SET_LOADING_TEN(state, status) {
    state.moduleLoadingTen = status;
  },

  GENERATE_NEW_TRANSACTION_REF(state, userId) {
    state.currentTransactionRef = Utils.generateUUIDRef() + "-" + userId;
  }
};
